import * as io from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface TextbookModule {
  id: string;
  editionId: string;
  modulePath: string[];
  modulePathDisplayName: string;
  editionKey: string;
  categoryPath: string[];
  juridikaPublishedAt: Date | null;
  titles: string[];
  formats: Array<{
    contentType: string;
    displayName: string;
  }>;
  contributions: Array<ModuleContribution>;
  tags: Array<ModuleTag>;
  startPageName: string | null;
  endPageName: string | null;
  wordCount: number | null;
  bitsJuridikaUuid: string | null;

  // Computed/helper fields
  fullTitle: string;
  pageRange: string;
  isPublishedAtJuridika: boolean;
}

export interface ModuleTag {
  path: string[];
  name: string;
  displayName: string;
}

export interface ModuleContribution {
  contributorId: string;
  role: string;
}

export interface PartialTextbookModule {
  contributions?: Array<ModuleContribution>;
}

// Runtime type!!!
export const ApiTypeV = io.type({
  id: io.string,
  editionId: io.string,
  modulePath: io.array(io.string),
  editionKey: io.string,
  categoryPath: io.array(io.string),
  juridikaPublishedAt: io.union([io.string, io.null]),
  titles: io.array(io.string),
  formats: io.array(
    io.type({
      contentType: io.string,
    })
  ),
  contributions: io.array(
    io.type({
      contributorId: io.string,
      role: io.string,
    })
  ),
  tags: io.array(
    io.type({
      path: io.array(io.string),
      name: io.string,
    })
  ),
  startPageName: io.union([io.string, io.null]),
  endPageName: io.union([io.string, io.null]),
  wordCount: io.union([io.number, io.null]),
  bitsJuridikaUuid: io.union([io.string, io.null]),
});
type ApiType = io.TypeOf<typeof ApiTypeV>;

interface ApiOutgoingType {}

export const toApiMapper = (edition: TextbookModule): ApiOutgoingType => ({});

export interface ApiPartialOutgoingType {
  contributions?: Array<ModuleContribution>;
}

export const toApiPartialMapper = (partialModule: PartialTextbookModule): ApiPartialOutgoingType =>
  partialModule;

export const apiModuleTagToReactAdmin = ({
  path,
  name,
}: {
  path: Array<string>;
  name: string;
}) => ({
  path,
  name,
  displayName: path.join('.').replace(/\./g, ' → ').replace(/_/g, ' '),
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<TextbookModule> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/textbookModules`,
    halListName: 'adminTextbookModuleList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (doc: ApiType): TextbookModule => ({
      id: doc.id,
      editionId: doc.editionId,
      modulePath: doc.modulePath,
      modulePathDisplayName: doc.modulePath.join('.'),
      editionKey: doc.editionKey,
      categoryPath: doc.categoryPath,
      juridikaPublishedAt: doc.juridikaPublishedAt ? new Date(doc.juridikaPublishedAt) : null,
      titles: doc.titles,
      formats: doc.formats.map(({ contentType }) => ({
        contentType,
        displayName: contentType.split('/')[1],
      })),
      contributions: doc.contributions,
      tags: doc.tags.map(apiModuleTagToReactAdmin),
      startPageName: doc.startPageName,
      endPageName: doc.endPageName,
      wordCount: doc.wordCount,
      bitsJuridikaUuid: doc.bitsJuridikaUuid,

      fullTitle: doc.titles.join(' — '),
      pageRange:
        doc.startPageName && doc.endPageName ? `${doc.startPageName}-${doc.endPageName}` : '',
      isPublishedAtJuridika: !!doc.juridikaPublishedAt,
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      editionId: 'editionId',
    },
    filterParams: {
      parentModulePath: (parentModulePath) => ({ parentModulePath }),
      editionId: (editionId) => ({ editionId }),
    },
    sortFields: [],
  });
