import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceManyField,
  Datagrid,
  TextField,
  Record,
} from 'react-admin';

import DateField from '../DateField';
import DateTimeField from '../DateTimeField';

const onEditionRowClick = (id: string | number, basePath: string, record: Record) => {
  const filter = {
    publicationId: record.publicationId,
    editionQuery: `${record.editionYear}/${record.editionSequence}`,
  };
  return `/journalArticles?filter=${JSON.stringify(filter)}`;
};

export const JournalPublicationShow: React.FC<{}> = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="fullTitle" />
      <ReferenceManyField
        label="Utgaver"
        reference="journalEditions"
        target="publicationId"
        fullWidth
      >
        <Datagrid rowClick={onEditionRowClick}>
          <TextField label="År" source="editionYear" />
          <TextField label="Utgave" source="editionSequence" />

          <DateField source="originallyPublishedAt" />

          <DateTimeField source="juridikaPublishedAt" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);
