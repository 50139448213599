import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  Labeled,
  SimpleFormIterator,
  email,
  ArrayInput,
  Create,
} from 'react-admin';

import { Contributor, createEmptyContributor } from '../../dataProvider/contributorsProvider';

import singleLineDocumentSpec from '../../slate/documentSpecs/singleLineSpec';

import SlateTextInput from '../SlateTextInput';
import { CodeInput } from '../CodeInput';
import { BreadcrumbTitle } from '../BreadcrumbTitle';

const ContributorForm: React.FC<{ initialValues?: Contributor }> = (props) => (
  <SimpleForm variant="standard" margin="normal" {...props}>
    {!props.initialValues && <TextInput disabled source="slug" />}

    <TextInput source="givenName" />
    <TextInput source="surname" />

    <BooleanInput source="featured" />
    <Labeled key="occupation" label="resources.contributors.fields.occupation" fullWidth>
      <SlateTextInput
        source="occupation"
        documentSpec={singleLineDocumentSpec}
        singlelineStyle={true}
      />
    </Labeled>
    <Labeled key="degree" label="resources.contributors.fields.degree" fullWidth>
      <SlateTextInput
        source="degree"
        documentSpec={singleLineDocumentSpec}
        singlelineStyle={true}
      />
    </Labeled>
    <TextInput source="emailAddress" type="email" validate={email()} allowEmpty fullWidth />
    <TextInput source="homepageUrl" type="url" allowEmpty fullWidth />

    {!props.initialValues && (
      <ArrayInput
        source="slugMaps"
        label="slug maps: Under importering av tidsskrifter vil forfattere med stavemåte ekvivalent med følgende slugs tilordnes denne bidragsyteren"
      >
        <SimpleFormIterator>
          <CodeInput label="slug" source="slug" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    )}
  </SimpleForm>
);

export const ContributorCreate: React.FC = (props) => (
  <Create
    title={<BreadcrumbTitle resourceName="bidragsyter" recordName={(record) => ''} />}
    {...props}
  >
    <ContributorForm initialValues={createEmptyContributor()} />
  </Create>
);

export const ContributorEdit: React.FC = (props) => (
  <Edit
    title={<BreadcrumbTitle resourceName="bidragsyter" recordName={(record) => record.fullname} />}
    {...props}
  >
    <ContributorForm />
  </Edit>
);
