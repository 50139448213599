import * as React from 'react';
import * as Slate from 'slate';

import * as tags from '../../tags';
import { getAboveByType, getNextSiblingNodes, isBlockAboveEmpty } from '../../utils/query';
import { insertNodeAfterAboveByType } from '../../utils/transforms';
import { Plugin } from '../types';
import BoxButton from './BoxButton';
import IFrameDialog from './IFrameDialog';

const key = 'box-plugin';

const requiredChildrenTypes = [
  tags.BLOCK_HEADING_TWO,
  tags.BLOCK_HEADING_THREE,
  tags.BLOCK_HEADING_FOUR,
  tags.BLOCK_HEADING_FIVE,
  tags.BLOCK_PARAGRAPH,
  tags.BLOCK_BULLETED_LIST,
  tags.BLOCK_NUMBERED_LIST,
];

export interface Config {
  boxTypes: string[];
  useDefaultInsertEditorBreakInTypes?: string[];
}

export default (config: Config): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return (
          <BoxButton
            key={key}
            editor={editor}
            types={config.boxTypes}
            openDialog={(editorSelectionPath: Slate.Path) => setPluginState(editorSelectionPath)}
          />
        );
      },
    },
    dialog: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return (
          <IFrameDialog
            editor={editor}
            editorSelectionPath={pluginState}
            onClose={() => setPluginState(null)}
          />
        );
      },
    },
    withEditor: (editor) => {
      const { normalizeNode, isVoid, insertBreak } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node, path] = entry;

        if (node.type === tags.BLOCK_FACTS) {
          for (const [child, childPath] of Slate.Node.children(node, [])) {
            if (!requiredChildrenTypes.includes(child.type as string)) {
              const paragraph = {
                type: tags.BLOCK_LEAD_PARAGRAPH,
              };
              const options = {
                at: [...path, ...childPath],
              };

              if (Slate.Element.isElement(child)) {
                Slate.Transforms.setNodes(editor, paragraph, options);
              } else {
                Slate.Transforms.wrapNodes(
                  editor,
                  { ...paragraph, children: [{ text: '' }] },
                  options
                );
              }
            }
          }
        }

        normalizeNode(entry);
      };

      editor.insertBreak = () => {
        // Do not override break in types defined in useDefaultInsertEditorBreakInTypes
        if (
          config.useDefaultInsertEditorBreakInTypes &&
          getAboveByType(editor, config.useDefaultInsertEditorBreakInTypes)
        ) {
          insertBreak();
          return;
        }

        const factsNodeEntry = getAboveByType(editor, tags.BLOCK_FACTS);

        if (
          !factsNodeEntry ||
          factsNodeEntry[0].children.length === 1 ||
          !isBlockAboveEmpty(editor)
        ) {
          insertBreak();
          return;
        }

        const selection = editor.selection?.focus;
        if (selection) {
          // Allow user to put multiple empty paragraphs
          const nextNodes = getNextSiblingNodes(factsNodeEntry, selection.path);
          if (nextNodes.length > 0) {
            insertBreak();
            return;
          }

          Slate.Transforms.removeNodes(editor, { at: selection });
          insertNodeAfterAboveByType(
            editor,
            {
              type: tags.BLOCK_PARAGRAPH,
              children: [{ text: '' }],
            },
            tags.BLOCK_FACTS
          );
        }
      };

      editor.isVoid = (element: Slate.Element) =>
        isVoid(element) || element.type === tags.BLOCK_IFRAME;

      return editor;
    },
  };
};
