import * as React from 'react';
import * as Slate from 'slate';

import InsertLinkIcon from '@material-ui/icons/InsertLink';

import * as tags from '../../../slate/tags';
import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';

const wrapLink = (editor: Slate.Editor, hrefAttribute: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  if (!selection) return;
  const isCollapsed = Slate.Range.isCollapsed(selection);
  if (isCollapsed) return;

  Slate.Transforms.wrapNodes(
    editor,
    {
      type: tags.INLINE_LINK,
      [hrefAttribute]: 'https://',
      children: [],
    },
    { split: true }
  );
  Slate.Transforms.collapse(editor, { edge: 'end' });
};

const isLinkActive = (editor: Slate.Editor) => {
  const [link] = Slate.Editor.nodes(editor, { match: (n) => n.type === tags.INLINE_LINK });
  return !!link;
};

const unwrapLink = (editor: Slate.Editor) => {
  Slate.Transforms.unwrapNodes(editor, { match: (n) => n.type === tags.INLINE_LINK });
};

const LinkButton: React.FC<{ editor: Slate.Editor; hrefAttribute: string }> = (props) => {
  return React.createElement(SlateToolbarButton, {
    isActive: isLinkActive(props.editor),
    Icon: InsertLinkIcon,
    message: 'slate.contextMenu.link',
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      // Insert empty link, a link popup will ask user for input instead
      wrapLink(props.editor, props.hrefAttribute);
    },
  });
};

export default LinkButton;
