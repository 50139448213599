import {
  InjectorActionTypes,
  InjectionSuccess,
  InjectionFailed,
  FetchInjectedJournalStarted,
  FetchInjectedJournalSuccess,
  FetchInjectedJournalFailed,
  FetchInjectResultsStarted,
  FetchInjectResultsSuccess,
  FetchInjectResultsFailed,
  InjectionStarted,
} from './injectorActions';
import { LinkedFileTypes, Matches } from '../../models/LinkInjectorModels';

type ActionTypes =
  | InjectionStarted
  | InjectionSuccess
  | InjectionFailed
  | FetchInjectedJournalStarted
  | FetchInjectedJournalSuccess
  | FetchInjectedJournalFailed
  | FetchInjectResultsStarted
  | FetchInjectResultsSuccess
  | FetchInjectResultsFailed;

export type InjectorState = {
  injectingIds: string[];
  injectedDocuments: {
    [id: string]: {
      loading: boolean;
      content?: {
        data: string;
        type: LinkedFileTypes;
      };
    };
  };
  injectResults: {
    [id: string]: {
      loading: boolean;
      matches?: Matches;
    };
  };
};

const defaultState: InjectorState = {
  injectingIds: [],
  injectedDocuments: {},
  injectResults: {},
};

export default (state: InjectorState = defaultState, action: ActionTypes): InjectorState => {
  switch (action.type) {
    case InjectorActionTypes.INJECTOR_STARTED:
      return {
        ...state,
        injectingIds: [...state.injectingIds, ...action.ids],
      };
    case InjectorActionTypes.INJECTOR_SUCCESS:
      return {
        ...state,
        injectingIds: state.injectingIds.filter((id) => !action.ids.includes(id)),
      };
    case InjectorActionTypes.INJECTOR_FAILURE:
      return {
        ...state,
        injectingIds: state.injectingIds.filter((id) => !action.ids.includes(id)),
      };

    case InjectorActionTypes.FETCH_INJECTED_JOURNAL_STARTED:
      return {
        ...state,
        injectedDocuments: {
          ...state.injectedDocuments,
          [action.id]: {
            ...state.injectedDocuments[action.id],
            loading: true,
          },
        },
      };
    case InjectorActionTypes.FETCH_INJECTED_JOURNAL_SUCCESS:
      return {
        ...state,
        injectedDocuments: {
          ...state.injectedDocuments,
          [action.id]: {
            loading: false,
            content: action.content,
          },
        },
      };
    case InjectorActionTypes.FETCH_INJECTED_JOURNAL_FAILURE:
      return {
        ...state,
        injectedDocuments: {
          ...state.injectedDocuments,
          [action.id]: {
            ...state.injectedDocuments[action.id],
            loading: false,
          },
        },
      };

    case InjectorActionTypes.FETCH_INJECT_RESULT_STARTED:
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [action.id]: {
            loading: true,
          },
        },
      };

    case InjectorActionTypes.FETCH_INJECT_RESULT_SUCCESS:
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [action.id]: {
            loading: false,
            matches: action.matches,
          },
        },
      };

    case InjectorActionTypes.FETCH_INJECT_RESULT_FAILED:
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [action.id]: {
            loading: false,
          },
        },
      };

    default:
      return state;
  }
};
