import React, { FC } from 'react';
import {
  AutocompleteInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  SimpleForm,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const NIKDocumentCreate: FC = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Opprett et NIK dokument</h1>
          <p>
            Her oppretter du et nytt dokument av en problemstillings utgave. Når du oppretter
            dokumentet vil den lagres som en <em>DRAFT</em> i listen over dokumenter. Finn den igjen
            i listen og trykk på <em>REDIGER</em> for å endre dokumentets innhold.
          </p>
        </div>
        <ReferenceInput
          source="publicationId"
          label="Tilknytt Problemstilling"
          reference="nikPublications"
          allowEmpty={false}
          validate={[required()]}
          options={{
            fullWidth: true,
          }}
        >
          <AutocompleteInput
            optionValue="id"
            optionText="title"
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }: any) => {
            return (
              <ReferenceInput
                label="Tilknytt Utgave"
                source="editionsId"
                reference="nikEditions"
                validate={[required()]}
                filter={{ publicationId: formData.publicationId }}
                options={{
                  fullWidth: true,
                }}
              >
                <AutocompleteInput
                  optionValue="id"
                  optionText="name"
                  allowEmpty={false}
                  options={{
                    fullWidth: true,
                  }}
                />
              </ReferenceInput>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default NIKDocumentCreate;
