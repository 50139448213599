import React from 'react';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishIcon from '@material-ui/icons/Publish';
import { Button, SimpleForm, AutocompleteArrayInput, useGetList, Identifier } from 'react-admin';

import { Tag } from '../../dataProvider/tagsProvider';

import { useTagChipStyles, useDeleteChipStyles } from '../../components/StyledChipFields';

export interface FormType {
  add: Array<string>;
  remove: Array<string>;
}

const TagDialog: React.FC<{
  onClose: React.ReactEventHandler<{}>;
  isSubmitting: boolean;
  addChoices: Array<{ id: Identifier; name: string }>;
  removeChoices: Array<{ id: Identifier; name: string }>;
}> = ({ onClose, isSubmitting, addChoices, removeChoices }) => {
  const form = useForm();
  const tagChipClassNames = useTagChipStyles();
  const deleteChipClassNames = useDeleteChipStyles();

  return (
    <Dialog fullWidth open={true} onClose={onClose} aria-label="Publish">
      <DialogTitle>Tag</DialogTitle>
      <DialogContent>
        <AutocompleteArrayInput
          classes={tagChipClassNames}
          source="add"
          label="Legg til"
          choices={addChoices}
          helperText="Tagger som skal legges til dokument(ene)"
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          classes={deleteChipClassNames}
          source="remove"
          label="Fjern"
          choices={removeChoices}
          helperText="Tagger som skal fjernes fra dokument(ene)"
          variant="outlined"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={onClose}>
          <CancelIcon />
        </Button>
        <Button label="Oppdater" disabled={isSubmitting} onClick={() => form.submit()}>
          <PublishIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TagDialogForm: React.FC<{
  onSubmit: (form: FormType) => void;
  onClose: () => void;
  tagPrefixes: Array<string>;
  tagPathsOnSelectedIds: Set<Identifier>;
  isSubmitting: boolean;
}> = ({ onSubmit, onClose, tagPrefixes, tagPathsOnSelectedIds, isSubmitting }) => {
  const { ids, data, loading } = useGetList<Tag>(
    'tags',
    { page: 1, perPage: 1000 },
    undefined, // sort
    { tagPrefixes } // filter
  );

  if (!data || loading) return null;

  const addChoices = ids.map((id) => ({
    id,
    name: data[id].displayName,
  }));
  const removeChoices = addChoices.filter(({ id }) => tagPathsOnSelectedIds.has(id));

  return (
    <SimpleForm
      variant="standard"
      margin="normal"
      initialValues={{ add: [], remove: [] }}
      save={(data: FormType) => onSubmit(data)}
      toolbar={<></>}
    >
      <TagDialog
        onClose={onClose}
        isSubmitting={isSubmitting}
        addChoices={addChoices}
        removeChoices={removeChoices}
      />
    </SimpleForm>
  );
};

export const TagDialogFormWrapper: React.FC<{
  isOpen: boolean;
  onSubmit: (form: FormType) => void;
  onClose: () => void;
  tagPrefixes: Array<string>;
  getTagPathsOnSelectedIds: () => Set<Identifier>;
  isSubmitting: boolean;
}> = ({ isOpen, onSubmit, onClose, tagPrefixes, getTagPathsOnSelectedIds, isSubmitting }) => {
  if (!isOpen) return null;

  return (
    <TagDialogForm
      tagPrefixes={tagPrefixes}
      tagPathsOnSelectedIds={getTagPathsOnSelectedIds()}
      onSubmit={onSubmit}
      onClose={onClose}
      isSubmitting={isSubmitting}
    />
  );
};
