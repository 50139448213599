import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import * as simpleXml from '../../util/simpleXml';

import { Stack, stackToPath, UiContext } from './BitsDebugUtil';

const useStyles = makeStyles({
  interaction: {
    border: 'dashed #0000 1px',
  },
  interactionSelected: {
    border: 'dashed black 1px',
  },
  interactionHeader: {
    fontSize: '0.9rem',
    color: '#aaa',
    fontFamily: 'monospace',
    display: 'flex',
  },
  expandButton: {
    border: 'none',
    fontSize: 0,
    padding: 0,
    cursor: 'pointer',
  },
  expandButtonIcon: {
    fontSize: '1rem',
  },
  breadcrumbLeafAttributes: {
    color: '#405d27',
  },
});

const BreadCrumbs: React.FC<{ stack: Stack }> = ({ stack }) => {
  const classes = useStyles();
  const getPath = (stack: Stack | null): string | null => {
    if (!stack) return null;
    const segment = stack.element.tagName;

    const parentPath = getPath(stack.stack);

    if (parentPath) {
      return `${parentPath} → ${segment}`;
    } else {
      return segment;
    }
  };

  const path = getPath(stack);

  const topElement = stack.element;
  const attribs = Object.keys(topElement.attributes).reduce((agg, key) => {
    return [...agg, `${key}="${topElement.attributes[key]}"`];
  }, [] as string[]);

  return (
    <span>
      {path ?? ''}
      {'  '}
      <span className={classes.breadcrumbLeafAttributes}>{attribs.join(' ')}</span>
    </span>
  );
};

export const HighLevelElementInteraction: React.FC<{
  stack: Stack;
}> = ({ stack, children }) => {
  const classes = useStyles();
  const { expanded } = stack.element;
  const ref = React.useRef<HTMLDivElement>(null);
  const uiContext = React.useContext(UiContext);

  const isSelected = !!ref.current && ref.current === uiContext.selectedHighLevelElement?.div;

  const toggleExpand = () => {
    const path = stackToPath(stack);
    if (expanded) {
      uiContext.applyViewMutation(simpleXml.collapse(path));
    } else {
      uiContext.applyViewMutation(simpleXml.expand(path));
    }
  };

  return (
    <div
      ref={ref}
      className={isSelected ? classes.interactionSelected : classes.interaction}
      onClick={(event) => {
        event.stopPropagation();
        uiContext.setSelectedHighLevelElement({ div: ref.current, stack });
      }}
    >
      <div className={classes.interactionHeader}>
        <button className={classes.expandButton} onClick={() => toggleExpand()}>
          {expanded ? (
            <ArrowDropDown
              className={classes.expandButtonIcon}
              color="secondary"
              viewBox="2 2 20 20"
            />
          ) : (
            <ArrowRight
              className={classes.expandButtonIcon}
              color="secondary"
              viewBox="2 2 20 20"
            />
          )}
        </button>
        <BreadCrumbs stack={stack} />
      </div>
      {expanded && children}
    </div>
  );
};

export const LowLevelElementInteraction: React.FC<{
  stack: Stack;
}> = ({ stack, children }) => {
  const classes = useStyles();
  const ref = React.useRef<HTMLDivElement>(null);
  const uiContext = React.useContext(UiContext);

  const isSelected = !!ref.current && ref.current === uiContext.selectedLowLevelElement?.div;

  return (
    <div
      ref={ref}
      className={isSelected ? classes.interactionSelected : classes.interaction}
      onClick={(event) => {
        event.stopPropagation();
        uiContext.setSelectedLowLevelElement({ div: ref.current, stack });
      }}
    >
      {children}
    </div>
  );
};
