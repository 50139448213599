import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  ChipField,
  ReferenceField,
  TextField,
  RichTextField,
  EditButton,
  ReferenceInput,
  TextInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  RefreshButton,
  ArrayField,
  SingleFieldList,
  useListContext,
} from 'react-admin';

import { NoticeSnapshot } from '../../dataProvider/noticeSnapshotsProvider';

import DateTimeField from '../DateTimeField';
import ModifyTagsButton from '../documentTags/ModifyTagsButton';
import { TagChipField } from '../StyledChipFields';

import { NoticeSnapshotExpandShow } from './NoticeSnapshotDetails';

const NoticeSnapshotFilter: React.FC<any> = (props) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput label="resources.pageSnapshots.actions.filterByTitle" source="q" alwaysOn />
    <ReferenceInput
      label="resources.noticeSnapshots.fields.revision.contentCategoryId"
      source="revision.contentCategoryId"
      reference="contentCategories"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

// TODO: Proper typings when it's possible to do in ts and react-admin
const Actions: React.FC<any> = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <RefreshButton />
    {/* Add your custom actions */}
  </TopToolbar>
);

const NoticeSnapshotBulkActionButtons = (props: any) => {
  const { data } = useListContext<NoticeSnapshot>();

  return (
    <React.Fragment>
      <ModifyTagsButton
        tagResource="staticDocumentTags"
        getTagPathsOnSelectedIds={() => {
          const set: Set<string> = new Set();
          if (props.selectedIds) {
            props.selectedIds.forEach((id: string) => {
              data[id]?.revision?.tags?.forEach((tag: { id: string; name: string }) => {
                set.add(tag.id);
              });
            });
          }
          return set;
        }}
      />
    </React.Fragment>
  );
};

export const NoticeSnapshotList: React.FC<any> = (props) => (
  <List
    {...props}
    filters={<NoticeSnapshotFilter />}
    actions={<Actions />}
    bulkActionButtons={<NoticeSnapshotBulkActionButtons />}
    perPage={25}
  >
    <Datagrid expand={<NoticeSnapshotExpandShow />}>
      <RichTextField source="revision.title" sortable={false} />

      <ReferenceField
        source="revision.contentCategoryId"
        reference="contentCategories"
        sortable={false}
      >
        <ChipField source="name" />
      </ReferenceField>

      <ArrayField source="revision.tags">
        <SingleFieldList>
          <TagChipField source="displayName" />
        </SingleFieldList>
      </ArrayField>

      <DateTimeField source="lastPublishedAt" sortable={false} />

      <ReferenceField source="currentEditorId" reference="editorAccounts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>

      <EditButton onClick={(e: React.MouseEvent) => e.stopPropagation()} />
    </Datagrid>
  </List>
);
