import * as t from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface ContentCategory {
  id: string;
  semanticKey: string;
  name: string;
}

const ApiIncomingTypeV = t.type({
  id: t.string,
  semanticKey: t.string,
  name: t.string,
});
type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  semanticKey: string;
  name: string;
}

export const toApiMapper = (category: ContentCategory): ApiOutgoingType => ({
  semanticKey: category.semanticKey,
  name: category.name,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<ContentCategory> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/contentCategories`,
    halListName: 'adminContentCategoryList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (x) => x,
    toApiMapper: toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {
      semanticKey: (semanticKey) => ({ semanticKey }),
    },
    sortFields: [],
  });
