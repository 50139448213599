import * as React from 'react';
import { connect } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CropIcon from '@material-ui/icons/Crop';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import Tooltip from '@material-ui/core/Tooltip';
import { useDropzone } from 'react-dropzone';

import { CustomState } from '../../state/customState';
import { uploadStaticImage } from '../../state/staticFiles/staticFilesActions';
import { StaticFileState } from '../../state/staticFiles/staticFilesReducer';

import AttributeDialog from './components/AttributeDialog';

import '../../styles/slateImage.css';

const hoverIconStyle = {
  width: '100%',
  height: '100%',
};

const iconStyle = {
  width: '100px',
  height: '100px',
};

export interface ImageValue {
  src: string | null;
  fileid: string | null;
  alt: string | null;
  title: string | null;
}

export interface OwnProps {
  uuid: string;
  value: ImageValue;
  onChange: (value: ImageValue) => void;
  onClear: () => void;
}

interface ReduxProps {
  staticFileState: StaticFileState | null;
}

interface DispatchProps {
  upload: typeof uploadStaticImage.request;
}

type Props = OwnProps & ReduxProps & DispatchProps;

const ImageEditorInternal: React.FC<Props> = (props: Props) => {
  const { uuid, value, staticFileState, onChange, onClear, upload } = props;
  const stateFileId = staticFileState && staticFileState.fileid;
  const uploadStatus = staticFileState ? staticFileState.status : 'unknown';

  const [showAttributeDialog, setShowAttributeDialog] = React.useState(false);

  React.useEffect(() => {
    if (staticFileState && staticFileState.url !== value.src) {
      onChange({
        ...value,
        src: staticFileState && staticFileState.url,
        fileid: staticFileState && staticFileState.fileid,
      });
    }
  }, [stateFileId, uploadStatus, onChange, staticFileState, value]);

  const handleDropAccepted = React.useCallback(
    (accepted: File[]) => {
      if (accepted) {
        upload({ uuid, file: accepted[0] });
      }
    },
    [upload, uuid]
  );

  const handleDropRejected = React.useCallback((rejected: File[]) => {
    alert('Noe gikk galt. Sjekk at ikke størrelsen på bildet overskrider grensen på 1MB.');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    maxSize: 1000000,
    noClick: showAttributeDialog,
  });

  const UploadStatus: React.FC<{}> = () => {
    switch (uploadStatus) {
      case 'uploading':
        return <div>Uploading...</div>;
      case 'error':
        return (
          <>
            <div>Upload error</div>
            <div>Maximum upload file size: 1 MB.</div>
          </>
        );
      default:
        return (
          <>
            <div>Upload a photo</div>
            <div>Maximum upload file size: 1 MB.</div>
          </>
        );
    }
  };

  const renderDeleteTooltip = () => (
    <Tooltip title="Slett">
      <Fab
        size="small"
        color="inherit"
        aria-label="slett"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClear();
        }}
      >
        <DeleteIcon />
      </Fab>
    </Tooltip>
  );

  return value.src ? (
    <div className="ImageDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <img src={value.src || undefined} alt={value.alt || ''} />
      <div className="ImageHoverWrapper">
        <div className="ImageHoverMenu">
          <div className="ImageHoverUploadIcon">
            <AddAPhotoIcon color="secondary" style={hoverIconStyle} />
          </div>
          <div className="ImageHoverElement ImageHoverElementTopRight">{renderDeleteTooltip()}</div>
          <div className="ImageHoverElement ImageHoverElementBottomLeft">
            <Tooltip title="Crop">
              <Fab color="inherit" aria-label="Yo" size="small">
                <CropIcon />
              </Fab>
            </Tooltip>
          </div>
          <div className="ImageHoverElement ImageHoverElementBottomRight ImageHoverAttributes">
            <div className="ImageHoverFields">
              <TextField disabled={true} label="alt" value={value.alt || ''} />
              <br />
              <TextField disabled={true} label="title" value={value.title || ''} />
            </div>
            <Tooltip title="Attributter">
              <Fab
                size="small"
                color="inherit"
                aria-label="Yo"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setShowAttributeDialog(true);
                }}
              >
                <SubtitlesIcon />
              </Fab>
            </Tooltip>
          </div>
        </div>
        {showAttributeDialog && (
          <AttributeDialog
            alt={value.alt || ''}
            title={value.title || ''}
            onCancel={() => setShowAttributeDialog(false)}
            onConfirm={(attributes: { [key: string]: string }) => {
              setShowAttributeDialog(false);
              onChange({
                ...value,
                alt: attributes['alt'] || null,
                title: attributes['title'] || null,
              });
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="ImageDropzone" {...getRootProps()}>
      <input {...getInputProps()} />
      <AddAPhotoIcon style={iconStyle} />
      <UploadStatus />
      <div className="ImageHoverElement ImageHoverElementTopRight">{renderDeleteTooltip()}</div>
    </div>
  );
};

const mapStateToProps = (state: CustomState, ownProps: OwnProps): ReduxProps => ({
  staticFileState: state.staticFiles[ownProps.uuid] || null,
});

const mapDispatchToProps: DispatchProps = {
  upload: uploadStaticImage.request,
};

export const ImageEditor = connect(mapStateToProps, mapDispatchToProps)(ImageEditorInternal);
