import { CREATE, DELETE, GET_LIST, GET_ONE } from 'react-admin';

import * as Types from '../types';

import * as constants from '../../state/legalDocuments/legalDocumentConstants';
import { LovbackendService } from './lovbackendService';

type ActionTypes = Types.ReactAdminApiType | typeof constants.GENERATE_AS_COPY;
export const XOPUS_SOURCE_SYSTEM = 'Xopus';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  legalDocumentApiProvider(urls.lovEditorBackend, type, params);

const legalDocumentApiProvider = (
  baseApiUrl: string,
  type: ActionTypes,
  params: Types.ReactAdminApiParams
): Promise<any> => {
  const backend = new LovbackendService(baseApiUrl);
  switch (type) {
    case GET_LIST:
      return backend.getDocumentPage(params);
    case GET_ONE:
      return backend.getLegalDocument(params.id!!);
    case CREATE:
      return backend.createDocument(params);
    case constants.GENERATE_AS_COPY:
      return backend.generateAsCopy(params);
    case DELETE:
      return backend.deleteDraft(params);
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
