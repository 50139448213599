import * as Slate from 'slate';
import * as tags from '../../tags';
import { getAboveByType } from '../../utils/query';
import { createEmptyTable, createEmptyTableCellNode, createEmptyTableRow } from './utils';

export const insertEmptyTable = (editor: Slate.Editor) => {
  if (!getAboveByType(editor, tags.BLOCK_TABLE)) {
    Slate.Transforms.insertNodes(editor, createEmptyTable(true));
  }
};

export const insertTableRow = (editor: Slate.Editor) => {
  const currentRowEntry = getAboveByType(editor, tags.BLOCK_TABLE_ROW);

  if (currentRowEntry) {
    const [currentRowNode, currentRowPath] = currentRowEntry;
    Slate.Transforms.insertNodes(editor, createEmptyTableRow(currentRowNode.children.length), {
      at: Slate.Path.next(currentRowPath),
      select: true,
    });
  }
};

export const insertTableColumn = (editor: Slate.Editor) => {
  const currentCellEntry = getAboveByType(editor, [
    tags.BLOCK_TABLE_HEADER_CELL,
    tags.BLOCK_TABLE_CELL,
  ]);
  const currentTableBodyEntry = getAboveByType(editor, tags.BLOCK_TABLE_BODY);

  if (currentCellEntry && currentTableBodyEntry) {
    const nextCellPath = Slate.Path.next(currentCellEntry[1]);
    const newCellPath = nextCellPath.slice();
    const replacePathPos = newCellPath.length - 2;
    const currentRowIdx = nextCellPath[replacePathPos];

    currentTableBodyEntry[0].children.forEach((row, rowIdx) => {
      newCellPath[replacePathPos] = rowIdx;

      const isHeader = (row as Slate.Element).children[0].type === tags.BLOCK_TABLE_HEADER_CELL;
      Slate.Transforms.insertNodes(editor, createEmptyTableCellNode(isHeader), {
        at: newCellPath,
        select: rowIdx === currentRowIdx,
      });
    });
  }
};

export const deleteTableColumn = (editor: Slate.Editor) => {
  if (getAboveByType(editor, tags.BLOCK_TABLE)) {
    const currentCellItem = getAboveByType(editor, [
      tags.BLOCK_TABLE_CELL,
      tags.BLOCK_TABLE_HEADER_CELL,
    ]);
    const currentRowEntry = getAboveByType(editor, tags.BLOCK_TABLE_ROW);
    const currentTableBodyEntry = getAboveByType(editor, tags.BLOCK_TABLE_BODY);

    if (
      currentCellItem &&
      currentRowEntry &&
      currentTableBodyEntry &&
      // Cannot delete the last cell
      currentRowEntry[0].children.length > 1
    ) {
      const currentCellPath = currentCellItem[1];
      const pathToDelete = currentCellPath.slice();
      const replacePathPos = pathToDelete.length - 2;

      currentTableBodyEntry[0].children.forEach((row, rowIdx) => {
        pathToDelete[replacePathPos] = rowIdx;

        Slate.Transforms.removeNodes(editor, {
          at: pathToDelete,
        });
      });
    }
  }
};

export const deleteTableRow = (editor: Slate.Editor) => {
  if (getAboveByType(editor, tags.BLOCK_TABLE)) {
    const currentTableBodyEntry = getAboveByType(editor, tags.BLOCK_TABLE_BODY);
    const currentRowEntry = getAboveByType(editor, tags.BLOCK_TABLE_ROW);
    if (
      currentRowEntry &&
      currentTableBodyEntry &&
      // Cannot delete the last row
      currentTableBodyEntry[0].children.length > 1
    ) {
      Slate.Transforms.removeNodes(editor, {
        at: currentRowEntry[1],
      });
    }
  }
};
