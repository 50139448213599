import { CircularProgress, Paper } from '@material-ui/core';
import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Button, useUpdate, useNotify, useGetOne } from 'react-admin';
import {
  LegalChange,
  LegalExpressionIdentifier,
  LegalWork,
  LegalWorkIdentifier,
} from '../../../state/legalDocuments/types';
import { TocItem } from '../../../state/expressions/types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { LegalChangeEdit } from './LegalChangeEdit';
import { Edit, Delete, Save, Add, Assistant, OpenInNew } from '@material-ui/icons';
import {
  uriToWorkIdentifier,
  expressionIdentifierToUri,
  workIdentifierToLovdataPath,
  workIdentifierToUri,
  formatLegalReference,
} from '../../../util/legalIdentifierUtils';
import { ChangeEvent, ReactElement, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LegalChangesSuggest } from './LegalChangeSuggest';

export const getExternalLink = (text: string, href: string): ReactElement => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {text} <OpenInNew style={{ fontSize: '1rem' }} />
    </a>
  );
};

export const getLovdataProLink = (identifier: LegalWorkIdentifier): ReactElement => {
  return getExternalLink(
    `lovdata.no/pro${workIdentifierToLovdataPath(identifier)}`,
    `https://lovdata.no/pro${workIdentifierToLovdataPath(identifier)}`
  );
};

const flattenToc = (
  items: TocItem[],
  padding: string,
  accumulator: { id: string; name: string }[]
) => {
  items.forEach((item) => {
    accumulator.push({ id: item.eId, name: padding + item.heading });
    flattenToc(item.children, `    ${padding}`, accumulator);
  });
};

export const LegalChangeList: React.FC<RouteChildrenProps> = (props) => {
  const identifier = props.match!!.params as LegalExpressionIdentifier;
  const workUri = workIdentifierToUri(identifier);
  const expressionUri = expressionIdentifierToUri(identifier);

  const notify = useNotify();

  const [fragmentList, setFragmentList] = useState<{ id: string; name: string }[]>([]);
  const [work, setWork] = useState<LegalWork | undefined>(undefined);
  const [fragmentLabelMap, setFragmentLabelMap] = useState<Map<string, string>>(new Map());

  const [pristine, setPristine] = useState<boolean>(true);
  const [isChangesLoading, setIsChangesLoading] = useState<boolean>(true);
  const [legalChanges, setLegalChanges] = useState<LegalChange[]>([]);

  const [currentModalChange, setCurrentModalChange] = React.useState<LegalChange | null>(null);
  const [currentModalIndex, setCurrentModalIndex] = React.useState<number>(-1);

  const isWorkLoading = useGetOne('legalWorks', workUri, {
    onSuccess: (val: { data: LegalWork }) => {
      setWork(val.data);
    },
    onFailure: (err) => fail(err),
  }).loading;

  const isTocLoading = useGetOne('legalToc', expressionUri, {
    onSuccess: (val) => {
      const fragments: { id: string; name: string }[] = [];
      flattenToc(val.data.toc, '', fragments);
      setFragmentList(fragments);

      const map = new Map<string, string>();
      fragments.forEach((m) => map.set(m.id, m.name));
      setFragmentLabelMap(map);
    },
    onFailure: (err) => fail(err),
  }).loading;

  useGetOne('legalChanges', expressionUri, {
    onSuccess: (val: { data: { changes: LegalChange[] } }) => {
      setLegalChanges(val.data.changes);
      setIsChangesLoading(false);
    },
    onFailure: (err) => fail(err),
  });

  const [updateCall] = useUpdate(
    'legalChanges',
    expressionUri,
    legalChanges,
    { id: expressionUri },
    {
      onSuccess: (val) => {
        setLegalChanges(val.data.changes);
        setIsChangesLoading(false);
        notify('Lagret!', 'success');
      },
      onFailure: (err) => fail(err),
    }
  );

  const fail = (err: Error) => {
    notify(`Noe gikk galt: ${err}`, 'error');
    setIsChangesLoading(false);
  };

  const updateChange = (index: number, change: LegalChange) => {
    setPristine(false);
    setLegalChanges((prev) => {
      const n = prev.slice();

      if (index < 0) n.push(change);
      else {
        n[index] = change;
      }
      return n;
    });
    closeModal();
  };

  const save = () => {
    updateCall();
    setIsChangesLoading(true);
    setPristine(true);
  };

  const removeChange = (index: number) => {
    setPristine(false);
    setLegalChanges((prev) => {
      const n = prev.slice();
      n.splice(index, 1);
      return n;
    });
  };

  const closeModal = () => {
    setCurrentModalChange(null);
    setCurrentModalIndex(-1);
  };

  const openModal = (index: number, change: LegalChange) => {
    setCurrentModalChange(change);
    setCurrentModalIndex(index);
  };

  const [suggestOn, setSuggestOn] = useState<boolean>(false);
  const suggest = () => {
    setSuggestOn(!suggestOn);
  };

  const applySuggestionCallback = (legalChanges: LegalChange[]) => {
    setLegalChanges(legalChanges);
    setPristine(false);
  };

  const navigate = (languageDate: string | undefined) => {
    if (languageDate) {
      setSuggestOn(false);
      props.history.push(`/legalDocuments${workUri}/${languageDate}/changes`);
    }
  };

  return (
    <>
      <Paper style={{ margin: '1rem', padding: '2rem' }}>
        {isChangesLoading || isTocLoading || isWorkLoading ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <>
            <h2>Endringshistorikk</h2>
            <h4>
              {formatLegalReference(identifier)}{' '}
              {work?.expressions[0]?.manifestations[0]?.shortTitle}
            </h4>
            <div>
              <p>{getLovdataProLink(identifier)}</p>
              <br />
              <p>
                <strong>Utrykk: </strong>
                <select
                  onChange={(v: ChangeEvent) => navigate((v.target as HTMLSelectElement).value)}
                  value={identifier.languageCode + '@' + identifier.inForceDate}
                >
                  {work?.expressions
                    .map((v) => v.languageCode + '@' + v.inForceDate)
                    .map((v) => (
                      <option onChange={() => navigate(v)} key={v} value={v}>
                        {v}
                      </option>
                    ))}
                </select>
              </p>
            </div>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Endret av</TableCell>
                    <TableCell>Lovdata</TableCell>
                    <TableCell>Endringer</TableCell>
                    <TableCell> </TableCell>
                    <TableCell> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {legalChanges.map((change, index) => {
                    return (
                      <TableRow key={change.subjectUri}>
                        <TableCell>
                          {change.subjectUri ? (
                            formatLegalReference(uriToWorkIdentifier(change.subjectUri))
                          ) : (
                            <strong>OBS! Ikke definert</strong>
                          )}
                        </TableCell>
                        <TableCell>
                          {change.subjectUri &&
                            getLovdataProLink(uriToWorkIdentifier(change.subjectUri))}
                        </TableCell>
                        <TableCell>
                          {change.fragments
                            .map((eId) => fragmentLabelMap.get(eId) || eId)
                            .join(', ')}
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ float: 'right' }}
                            startIcon={<Edit />}
                            label="Rediger"
                            onClick={() => openModal(index, change)}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ float: 'right' }}
                            startIcon={<Delete />}
                            label="Slett"
                            onClick={() => removeChange(index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
                <Button
                  startIcon={<Add />}
                  label="Legg til endring"
                  variant="contained"
                  onClick={() => openModal(-1, { subjectUri: null, fragments: [] })}
                />
                <Button
                  disabled={pristine}
                  startIcon={<Save />}
                  label="Lagre"
                  onClick={() => save()}
                  variant="contained"
                />
              </div>
            </div>

            <Dialog
              fullWidth
              maxWidth="lg"
              open={currentModalChange !== null}
              onClose={closeModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Endring</DialogTitle>
              <DialogContent>
                <LegalChangeEdit
                  possibleFragments={fragmentList}
                  change={currentModalChange}
                  updateChange={(change: LegalChange) => {
                    updateChange(currentModalIndex, change);
                  }}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </Paper>
      {!isTocLoading && !isWorkLoading && (
        <Paper style={{ margin: '1rem', padding: '2rem' }}>
          <Button
            style={{ margin: '1rem' }}
            startIcon={<Assistant />}
            label={suggestOn ? 'Skjul' : 'Foreslå endringer'}
            onClick={() => suggest()}
            variant="contained"
          />
          {suggestOn && (
            <LegalChangesSuggest
              uri={expressionUri}
              labels={fragmentLabelMap}
              applySuggestionCallback={applySuggestionCallback}
            />
          )}
        </Paper>
      )}
    </>
  );
};
