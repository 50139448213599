import { HastNode, HastElementNode } from '@universitetsforlaget/hast';
import { HypertextRuleSets } from '../slate/serializer/rules';

interface NodeStack {
  node: HastElementNode;
  position: number;
  parent: NodeStack | null;
}

const appendOptional = <T>(list: T[], item: T | null): T[] => (item ? list.concat([item]) : list);

const prevNode = (position: number, stack: NodeStack): HastNode | null => {
  if (position === 0) {
    return null;
  } else {
    return stack.node.children![position - 1];
  }
};

const nextNode = (position: number, stack: NodeStack): HastNode | null => {
  if (position === stack.node.children!.length - 1) {
    return null;
  } else {
    return stack.node.children![position + 1];
  }
};

const isBlock = (node: HastNode, ruleSets: HypertextRuleSets): boolean => {
  return (
    node.type === 'element' &&
    (node.tagName === 'fragment' ||
      Object.keys(ruleSets.blocks).some((ruleTagName) => node.tagName === ruleTagName))
  );
};

const stripWhitespaceStack = (
  node: HastNode,
  position: number,
  stack: NodeStack,
  ruleSets: HypertextRuleSets
): HastNode[] => {
  const retainWhitespaceAfter = (node: HastNode | null): boolean => {
    if (!node) {
      return false;
    } else if (node.type === 'text') {
      // whitespace already retained before.
      return false;
    } else if (isBlock(node, ruleSets)) {
      return false;
    } else {
      return true;
    }
  };

  const retainWhitespaceBefore = (node: HastNode | null): boolean => {
    if (!node) {
      return false;
    } else if (node.type === 'text') {
      // Retain whitespace if the next text node does NOT start with whitespace
      return /^\S/.test(node.value);
    } else if (isBlock(node, ruleSets)) {
      return false;
    } else {
      return true;
    }
  };

  if (node.type === 'text') {
    const originalValue = node.value;
    let trimmedValue = originalValue.replace(/\s+/g, ' ').trim();

    if (trimmedValue.length === 0) {
      return [];
    }

    const prev = prevNode(position, stack);
    const next = nextNode(position, stack);

    if (/^\s/.test(originalValue) && retainWhitespaceAfter(prev)) {
      trimmedValue = ` ${trimmedValue}`;
    }
    if (/\s$/.test(originalValue) && retainWhitespaceBefore(next)) {
      trimmedValue = `${trimmedValue} `;
    }

    return [
      {
        type: 'text',
        value: trimmedValue,
      },
    ];
  } else if (node.children) {
    const children = node.children
      .map((child, index) => {
        return stripWhitespaceStack(
          child,
          index,
          {
            node,
            position,
            parent: stack,
          },
          ruleSets
        );
      })
      .reduce((agg, children) => agg.concat(children), []);
    return [
      {
        ...node,
        children,
      },
    ];
  } else {
    return [node];
  }
};

export const normalizeWhitespace = (
  root: HastElementNode,
  ruleSets: HypertextRuleSets
): HastElementNode => {
  return {
    ...root,
    children: root
      .children!.map((node, index) =>
        stripWhitespaceStack(
          node,
          index,
          {
            node: root,
            position: 0,
            parent: null,
          },
          ruleSets
        )
      )
      .reduce((agg, children) => agg.concat(children), []),
  };
};

export const matchElement = (
  node: HastNode,
  tagName: string,
  className?: Array<string>
): boolean => {
  if (node.type === 'element' && node.tagName === tagName) {
    if (className === undefined) {
      return true;
    }

    if (className.length > 0) {
      if (!node.properties || !node.properties.className) {
        return false;
      }
      return className.join(' ') === node.properties.className.join(' ');
    } else {
      return (
        !node.properties || !node.properties.className || node.properties.className.length === 0
      );
    }
  }
  return false;
};

/** Function for unwrapping a parent. Returns the new child */
export type UnwrapFn = (
  unwrappedParent: HastElementNode,
  child: HastNode,
  childIndex: number,
  childCount: number
) => HastNode;

export interface NormalizationConfig {
  ruleSets: HypertextRuleSets;
  unwrap: (node: HastElementNode) => UnwrapFn | null;
  rewrapAs: (node: HastElementNode) => HastElementNode | null;
  remove: (node: HastElementNode) => boolean;
  blockWrapperElement?: string;
  blockWrapperProperties?: {
    [key: string]: string | number | boolean;
  };
}

/** Unwrap a child from its parent; and do not touch its properties */
export const unwrapIdentity: UnwrapFn = (unwrappedParent, child) => child;

export const unwrapAppendClassNamesToChild = (classNames: string[]): UnwrapFn => (
  unwrappedParent,
  child
) => {
  if (child.type !== 'element') return child;

  const properties = child.properties || {};
  return {
    ...child,
    properties: {
      ...properties,
      className: (properties.className || []).concat(classNames),
    },
  };
};

const normalizeChildren = (
  children: HastNode[],
  parent: HastElementNode,
  config: NormalizationConfig
): HastNode[] => {
  const computedChildren = children
    .map((child) => normalizeNode(child, parent, config))
    .reduce((agg, nodes) => agg.concat(nodes), []);

  const applyBlockWrapping = (
    index: number,
    agg: HastElementNode[],
    wrapper: HastElementNode | null
  ): HastElementNode[] => {
    if (index === computedChildren.length) {
      return appendOptional(agg, wrapper);
    }

    const child = computedChildren[index];
    if (child.type === 'element' && isBlock(child, config.ruleSets)) {
      return applyBlockWrapping(index + 1, appendOptional(agg, wrapper).concat([child]), null);
    } else {
      return applyBlockWrapping(index + 1, agg, {
        type: 'element',
        tagName: config.blockWrapperElement || 'p',
        properties: config.blockWrapperProperties || {},
        children: appendOptional(wrapper ? wrapper.children! : [], child),
      });
    }
  };

  if (isBlock(parent, config.ruleSets)) {
    if (computedChildren.some((child) => isBlock(child, config.ruleSets))) {
      return applyBlockWrapping(0, [], null);
    }
  }

  return computedChildren;
};

const normalizeNode = (
  node: HastNode,
  parent: HastElementNode,
  config: NormalizationConfig
): HastNode[] => {
  if (node.type === 'text') {
    // No processing on text level
    return [node];
  }

  if (config.remove(node)) {
    return [];
  }

  const unwrapFn = config.unwrap(node);

  if (unwrapFn) {
    const childCount = node.children ? node.children.length : 0;
    return normalizeChildren(
      (node.children || []).map((child, i) => unwrapFn(node, child, i, childCount)),
      parent,
      config
    );
  }

  const wrapper = config.rewrapAs(node) || node;

  return [
    {
      ...wrapper,
      children: normalizeChildren(node.children || [], wrapper, config),
    },
  ];
};

export const normalizeFragment = (
  fragment: HastElementNode,
  config: NormalizationConfig
): HastElementNode => {
  return {
    ...fragment,
    children: normalizeChildren(fragment.children || [], fragment, config),
  };
};

const unwrapChildren = (
  nodes: HastNode[],
  unwrap: (node: HastElementNode) => boolean
): HastNode[] => {
  return nodes.reduce(
    (acc, node) => [
      ...acc,
      ...(node.type === 'element'
        ? unwrap(node)
          ? unwrapChildren(node.children || [], unwrap)
          : [{ ...node, children: unwrapChildren(node.children || [], unwrap) }]
        : [node]),
    ],
    [] as HastNode[]
  );
};

export const unwrapFragment = (
  fragment: HastElementNode,
  unwrap: (node: HastElementNode) => boolean
): HastElementNode => {
  return {
    ...fragment,
    children: unwrapChildren(fragment.children || [], unwrap),
  };
};
