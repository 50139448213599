import React from 'react';
import {
  Show,
  Datagrid,
  SimpleShowLayout,
  ArrayField,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  TextField,
  Button,
  Labeled,
} from 'react-admin';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';

import { PageSnapshot } from '../../dataProvider/pageSnapshotsProvider';
import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';
import { createPrefilledNoticeSnapshotFromPageSnapshot } from '../../dataProvider/prefill';

import PublishRevisionButton from '../../containers/PublishRevision/PublishRevisionButton';

import { MutateCurrentEditorButton } from '../staticContentCommon/MutateCurrentEditorButton';
import DateTimeField from '../DateTimeField';

const CreateNoticeButton: React.FC<{
  record?: PageSnapshot;
}> = ({ record: pageSnapshot }) => (
  <Button
    label="Opprett notis"
    component={Link}
    color="primary"
    to={{
      pathname: '/noticeSnapshots/create',
      state: {
        record: createPrefilledNoticeSnapshotFromPageSnapshot(pageSnapshot!!),
      },
    }}
  >
    <AddIcon />
  </Button>
);

const PublishPageRevisionButton: React.FC<{
  snapshotSlug: string | null;
  record?: StaticDocumentRevision;
}> = ({ record, snapshotSlug }) =>
  record ? (
    <PublishRevisionButton
      snapshotInfo={{
        snapshotType: 'page',
        slug: snapshotSlug,
      }}
      revision={record}
      dialogLabel="Publiser side-revisjon"
    />
  ) : null;

const PageRevisions: React.FC<{
  record?: PageSnapshot;
}> = ({ record, ...props }) => (
  <Labeled label="Revisjoner" {...props}>
    <ReferenceManyField
      {...props}
      record={record}
      reference="revisions"
      target="documentId"
      fullWidth={false}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <DateTimeField source="createdAt" />
        <ReferenceField source="createdById" reference="editorAccounts">
          <TextField source="name" />
        </ReferenceField>

        <DateTimeField source="publishedAt" />
        <PublishPageRevisionButton snapshotSlug={record!!.slug} />
      </Datagrid>
    </ReferenceManyField>
  </Labeled>
);

export const PageSnapshotExpandShow: React.FC<any> = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="revision.title" />
      <ReferenceField source="currentEditorId" reference="editorAccounts">
        <TextField source="name" />
      </ReferenceField>
      <MutateCurrentEditorButton />

      <TextField source="slug" />

      <ArrayField source="revision.contributions">
        <Datagrid>
          <ReferenceField
            source="contributorId"
            reference="contributors"
            label="resources.pageSnapshots.fields.revision.contributions.contributorId"
          >
            <TextField source="fullname" />
          </ReferenceField>
          <TextField
            source="role"
            label="resources.pageSnapshots.fields.revision.contributions.role"
          />
        </Datagrid>
      </ArrayField>

      <PageRevisions />

      <Labeled label="Notiser">
        <ReferenceManyField
          addLabel={false}
          reference="noticeSnapshots"
          target="linkedPage"
          sort={{
            field: 'lastPublishedAt',
            order: 'DESC',
          }}
        >
          <Datagrid rowClick="show">
            <RichTextField source="revision.title" />
            <DateTimeField source="lastPublishedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Labeled>
      <CreateNoticeButton />
    </SimpleShowLayout>
  </Show>
);
