import * as React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  Labeled,
  required,
} from 'react-admin';

import {
  EmbeddedSnapshot,
  createEmptyEmbeddedSnapshot,
} from '../../dataProvider/embeddedSnapshotsProvider';
import embeddedDocumentSpec from '../../slate/documentSpecs/embeddedDocumentSpec';
import singleLineDocumentSpec, {
  DefaultHast as SingleLineDefaultHast,
} from '../../slate/documentSpecs/singleLineSpec';

import { DocumentLockedMessage } from '../staticContentCommon/DocumentLockedMessage';
import SlateTextInput from '../SlateTextInput';
import { SlateTextField } from '../SlateTextField';

const isMutable = (record: EmbeddedSnapshot | undefined) => {
  if (!record) return true;
  return record.currentEditorIsCurrentUser !== false;
};

const EmbeddedSnapshotForm: React.FC<{
  initialValues?: EmbeddedSnapshot;
  record?: EmbeddedSnapshot;
  redirect?: boolean;
}> = ({ redirect = true, record, ...props }) => {
  const disabled = !isMutable(record);

  return (
    <SimpleForm
      {...props}
      variant="standard"
      margin="normal"
      record={record}
      redirect={redirect}
      sanitizeEmptyValues={false}
    >
      {disabled && <DocumentLockedMessage />}

      {record && record.foreignType === 'contributor_bio' && (
        <ReferenceField source="foreignId" reference="contributors" fullWidth={true}>
          <Labeled label="Info om bidragsyter">
            <SimpleShowLayout>
              <TextField source="fullname" />
              <Labeled key="occupation" label="resources.contributors.fields.occupation" fullWidth>
                <SlateTextField
                  source="occupation"
                  documentSpec={singleLineDocumentSpec}
                  defaultValue={SingleLineDefaultHast()}
                  singlelineStyle={true}
                />
              </Labeled>
              <Labeled key="degree" label="resources.contributors.fields.degree" fullWidth>
                <SlateTextField
                  source="degree"
                  documentSpec={singleLineDocumentSpec}
                  defaultValue={SingleLineDefaultHast()}
                  singlelineStyle={true}
                />
              </Labeled>
            </SimpleShowLayout>
          </Labeled>
        </ReferenceField>
      )}

      {record && record.foreignType === 'literature_publication_review' && (
        <Labeled label="Utgivelse-id (fremmed-id)">
          <TextField source="foreignId" />
        </Labeled>
      )}

      {record && record.foreignType === 'literature_edition_review' && (
        <ReferenceField source="foreignId" reference="textbookEditions" fullWidth={true}>
          <Labeled label="Info om utgave">
            <SimpleShowLayout>
              <TextField source="mainTitle" />
              <TextField source="name" />
            </SimpleShowLayout>
          </Labeled>
        </ReferenceField>
      )}

      <TextInput
        multiline
        source="revision.title"
        disabled={disabled}
        validate={required('Må ha en beskrivende tittel (for søk i CMS)')}
      />

      <Labeled key="revision.hast" label="HTML-innhold" fullWidth>
        <SlateTextInput
          source="revision.hast"
          documentSpec={embeddedDocumentSpec}
          disabled={disabled}
        />
      </Labeled>
    </SimpleForm>
  );
};

export const EmbeddedSnapshotCreate: React.FC<any> = (props) => (
  <Create {...props} title={<span>Ny omtale</span>}>
    <EmbeddedSnapshotForm initialValues={createEmptyEmbeddedSnapshot()} />
  </Create>
);

const EditTitle: React.FC<{ record?: EmbeddedSnapshot }> = ({ record: snapshot }) => (
  <span>Revider omtale for {`"${snapshot!!.revision.title}"`}</span>
);

export const EmbeddedSnapshotEdit: React.FC<any> = (props) => (
  <Edit undoable={false} title={<EditTitle />} {...props}>
    <EmbeddedSnapshotForm />
  </Edit>
);
