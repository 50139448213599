import React from 'react';
import {
  Show,
  Datagrid,
  SimpleShowLayout,
  ArrayField,
  ReferenceField,
  ReferenceManyField,
  TextField,
  Labeled,
} from 'react-admin';

import { PageSnapshot } from '../../dataProvider/pageSnapshotsProvider';
import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';

import PublishRevisionButton from '../../containers/PublishRevision/PublishRevisionButton';

import { MutateCurrentEditorButton } from '../staticContentCommon/MutateCurrentEditorButton';
import DateTimeField from '../DateTimeField';

const PublishPageRevisionButton: React.FC<{
  snapshotSlug: string | null;
  record?: StaticDocumentRevision;
}> = ({ record, snapshotSlug }) =>
  record ? (
    <PublishRevisionButton
      snapshotInfo={{
        snapshotType: 'page',
        slug: snapshotSlug,
      }}
      revision={record}
      dialogLabel="Publiser side-revisjon"
    />
  ) : null;

const PageRevisions: React.FC<{
  record?: PageSnapshot;
}> = ({ record, ...props }) => (
  <Labeled label="Revisjoner" {...props}>
    <ReferenceManyField
      {...props}
      record={record}
      reference="revisions"
      target="documentId"
      fullWidth={false}
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <DateTimeField source="createdAt" />
        <ReferenceField source="createdById" reference="editorAccounts">
          <TextField source="name" />
        </ReferenceField>

        <DateTimeField source="publishedAt" />
        <PublishPageRevisionButton snapshotSlug={record!!.slug} />
      </Datagrid>
    </ReferenceManyField>
  </Labeled>
);

export const ContractSnapshotExpandShow: React.FC<any> = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="revision.title" />
      <ReferenceField source="currentEditorId" reference="editorAccounts">
        <TextField source="name" />
      </ReferenceField>
      <MutateCurrentEditorButton />

      <TextField source="slug" />

      <ArrayField source="revision.contributions">
        <Datagrid>
          <ReferenceField
            source="contributorId"
            reference="contributors"
            label="resources.pageSnapshots.fields.revision.contributions.contributorId"
          >
            <TextField source="fullname" />
          </ReferenceField>
          <TextField
            source="role"
            label="resources.pageSnapshots.fields.revision.contributions.role"
          />
        </Datagrid>
      </ArrayField>

      <PageRevisions />
    </SimpleShowLayout>
  </Show>
);
