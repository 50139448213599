import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DeleteButton, EditButton, CreateButton } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PublishIcon from '@material-ui/icons/Publish';
import AddIcon from '@material-ui/icons/Add';
import URI from 'urijs';

import { PropsWithExpressionAndVersion } from './ExpressionsTable';
import * as legalDocumentActions from '../../../state/legalDocuments/legalDocumentActions';
import * as expressionActions from '../../../state/expressions/expressionActions';

export const GenerateAsCopyButton = (props: PropsWithExpressionAndVersion) => {
  const { basePath, work, expression, manifestation } = props;
  const url = URI(basePath ?? '')
    .segment(expression.uri)
    .segment('create')
    .search({ status: manifestation.publicationStatus });

  return (
    <CreateButton
      label="resources.legalDocuments.actions.generateAsCopy"
      to={{
        pathname: url.pathname(),
        search: url.search(),
        state: {
          work,
          expression,
          manifestation,
        },
      }}
    />
  );
};

export const CreateDraftButton: React.FC<PropsWithExpressionAndVersion> = (props) => {
  const dispatch = useDispatch();
  const { currentUserName, expression, record: document, manifestation } = props;

  if (expression.manifestations.length > 1 || manifestation.publicationStatus === 'DRAFT') {
    return null;
  }

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!document) return;

    dispatch(
      legalDocumentActions.generateAsCopy({
        id: document.id,
        data: {
          copyFromWorkUri: document.work!!.uri,
          copyFromInForceDate: expression.inForceDate,
          copyFromLanguageCode: expression.languageCode,
          copyFromPublicationStatus: manifestation.publicationStatus,
          newInForceDate: expression.inForceDate,
          editor: currentUserName,
        },
      })
    );
  };

  return (
    <Button label="resources.legalDocuments.actions.createDraft" onClick={handleClick}>
      <AddIcon />
    </Button>
  );
};

export const EditExpressionButton = (props: PropsWithExpressionAndVersion) => {
  const {
    basePath,
    expression,
    manifestation: { publicationStatus },
  } = props;
  const isDraft = publicationStatus === 'DRAFT';
  const action = isDraft ? 'editDraft' : 'viewPublished';

  return (
    <EditButton
      label={`resources.legalDocuments.actions.${action}`}
      to={URI(basePath ?? '')
        .segment(expression.uri)
        .search({ publicationStatus })
        .toString()}
      icon={isDraft ? <EditIcon /> : <VisibilityIcon />}
    />
  );
};

export const DeleteExpressionButton = ({
  currentUserName,
  expression,
  manifestation,
  resource,
}: PropsWithExpressionAndVersion) => {
  if (manifestation.publicationStatus === 'PUBLISHED') return null;

  return (
    <DeleteButton
      redirect={window.location.pathname}
      resource={resource}
      record={{
        id: expression.uri,
        status: manifestation.publicationStatus,
        editor: currentUserName,
      }}
    />
  );
};

export const PublishDraftButton = (props: PropsWithExpressionAndVersion) => {
  const dispatch = useDispatch();
  const { currentUserName, expression, manifestation } = props;

  if (manifestation.publicationStatus !== 'DRAFT') {
    return null;
  }

  const successCallback = () => {};

  const handleClick = (e: SyntheticEvent) => {
    e.preventDefault();

    dispatch(
      expressionActions.publish(expression.uri, {
        editor: currentUserName,
        successCallback,
      })
    );
  };

  return (
    <Button label="resources.legalDocuments.actions.publish" onClick={handleClick}>
      <PublishIcon />
    </Button>
  );
};
