import * as React from 'react';
import produce from 'immer';
import * as hast from '@universitetsforlaget/hast';

export const TEXT = Symbol('TEXT');
export const ELEMENT = Symbol('ELEMENT');

export interface TextModel {
  type: typeof TEXT;
  value: string;
}

export interface ElementModel {
  type: typeof ELEMENT;
  tagName: string;
  attributes: hast.HastProperties;
  children: Model[];
  expanded: boolean;
  fake?: boolean;
}

export type Model = TextModel | ElementModel;
export type Mutation<T> = (model: T) => void;
export type ModelMutation = Mutation<Model>;

export const INDEX = Symbol();
export const MATCHELEMENT = Symbol();

export interface IndexPathSegment {
  type: typeof INDEX;
  value: number;
}

interface MatchElementPathSegment {
  type: typeof MATCHELEMENT;
  predicate: (element: ElementModel) => boolean;
}

export type PathSegment = IndexPathSegment | MatchElementPathSegment;
export type Path = PathSegment[];

export interface DeserializationConfig {
  initialExpandedLevels?: number;
}

export const matchElement = (tagName: string): PathSegment => ({
  type: MATCHELEMENT,
  predicate: (model) => model.tagName === tagName,
});

export const matchIndex = (index: number): PathSegment => ({
  type: INDEX,
  value: index,
});

/**
 * Select all nodes matching Path
 */
export function* select(model: Model, path: Path, level = 0): Generator<Model> {
  if (level === path.length) {
    yield model;
    return;
  }
  if (model.type === TEXT) return;

  const segment = path[level];

  switch (segment.type) {
    case INDEX:
      if (segment.value < model.children.length) {
        yield* select(model.children[segment.value], path, level + 1);
      }
      break;
    case MATCHELEMENT:
      if (model.type === ELEMENT && segment.predicate(model)) yield model;
      for (const child of model.children) {
        if (child.type === ELEMENT && segment.predicate(child)) {
          yield* select(child, path, level + 1);
        }
      }
      break;
  }
}

export function* selectElements(model: Model, path: Path): Generator<ElementModel> {
  for (const node of select(model, path)) {
    if (node.type === ELEMENT) {
      yield node;
    }
  }
}

export function count(model: Model, path: Path): number {
  let counter = 0;
  // eslint-disable-next-line
  for (const _node of select(model, path)) {
    counter += 1;
  }
  return counter;
}

/**
 * Remove all nodes matching path and return them
 */
export function* remove(model: Model, path: Path): Generator<Model> {
  const parentPath = path.slice(0, path.length - 1);
  const lastSegment = path[path.length - 1];

  for (const parentNode of select(model, parentPath)) {
    if (parentNode.type === TEXT) continue;

    switch (lastSegment.type) {
      case INDEX: {
        const element = parentNode.children.splice(lastSegment.value, 1);
        if (element.length > 0) {
          yield element[0];
        }
        break;
      }
      case MATCHELEMENT: {
        const children = parentNode.children;
        let i = 0;
        while (i < children.length) {
          const child = children[i];
          if (child.type === TEXT || !lastSegment.predicate(child)) {
            i += 1;
            continue;
          }

          const elements = children.splice(i, 1);
          yield elements[0];
          // Don't increment i :)
        }
      }
    }
  }
}

export function prependChildren(model: Model, path: Path, nodes: Model[]) {
  let done = false;
  for (const parent of select(model, path)) {
    if (done) return;
    if (parent.type !== ELEMENT) return;

    parent.children.unshift(...nodes);
    done = true;
  }
}

/**
 * Insert nodes at the given path
 */
export function insert(model: Model, path: Path, nodes: Model[]) {
  const leafSegment = path[path.length - 1];
  if (leafSegment.type === INDEX) {
    return insertChildren(model, path, nodes, leafSegment.value);
  }
}

export function insertChildren(model: Model, path: Path, nodes: Model[], location: number) {
  const parentPath = path.slice(0, path.length - 1);
  const lastSegment = path[path.length - 1];

  switch (lastSegment.type) {
    case INDEX: {
      for (const parentNode of select(model, parentPath)) {
        if (parentNode.type === TEXT) continue;
        parentNode.children.splice(location, 0, ...nodes);
      }
      break;
    }
    case MATCHELEMENT: {
      for (const node of select(model, path)) {
        if (node.type === ELEMENT) {
          node.children.splice(location, 0, ...nodes);
        }
      }
      break;
    }
  }
}

export function appendChildren(model: Model, path: Path, nodes: Model[]) {
  let done = false;
  for (const parent of select(model, path)) {
    if (done) return;
    if (parent.type !== ELEMENT) return;

    for (const node of nodes) {
      parent.children.push(node);
    }
    done = true;
  }
}

export const expand = (path: Path): Mutation<Model> => (model) => {
  for (const element of selectElements(model, path)) {
    element.expanded = true;
  }
};

export const collapse = (path: Path): Mutation<Model> => (model) => {
  for (const element of selectElements(model, path)) {
    element.expanded = false;
  }
};

export const createElement = (
  tagName: string,
  attributes: hast.HastProperties,
  ...children: Array<Model | undefined>
): ElementModel => ({
  type: ELEMENT,
  tagName,
  attributes: attributes || {},
  children: children.filter((child): child is Model => child !== undefined),
  expanded: true,
});

export const createText = (value: string): Model => ({
  type: TEXT,
  value,
});

export const fromJsonML = (json: any, config: DeserializationConfig = {}): Model => {
  const collapseLevel = config.initialExpandedLevels || 2;

  const deserializeJsonML = (json: any, level: number): Model => {
    if (typeof json === 'string') {
      return createText(json);
    }

    if (Array.isArray(json)) {
      const tagName = json[0];

      if (typeof tagName !== 'string') {
        throw new Error('Expected an element tagName in first position: ' + JSON.stringify(json));
      }

      let firstChildIndex = 1;
      let attributes = {};

      if (typeof json[1] !== 'string' && !Array.isArray(json[1]) && json.length > 1) {
        attributes = json[1];
        firstChildIndex = 2;
      }

      const children: Array<Model> = [];
      for (let i = firstChildIndex; i < json.length; i += 1) {
        children.push(deserializeJsonML(json[i], level + 1));
      }

      return {
        type: ELEMENT,
        tagName,
        attributes,
        children,
        expanded: level !== collapseLevel,
      };
    }

    throw new Error('Unparseable JsonML: ' + JSON.stringify(json));
  };

  return deserializeJsonML(json, 0);
};

export const debugAsMarkup = (node: Model, indentation = 0): string => {
  const indent = (): string => {
    let str = '';
    for (let i = 0; i < indentation; i += 1) {
      str += '  ';
    }
    return str;
  };

  if (node.type === TEXT) return `${indent()}${node.value}\n`;

  const { tagName, attributes, children } = node;

  const attribStr = Object.keys(attributes)
    .reduce((agg, key) => [...agg, ` ${key}="${attributes[key]}"`], [] as string[])
    .join('');

  if (children.length === 0) {
    return `${indent()}<${tagName} ${attribStr} />\n`;
  }

  return `${indent()}<${tagName} ${attribStr}>\n${children
    .map((child) => debugAsMarkup(child, indentation + 1))
    .join('')}${indent()}</${tagName}>\n`;
};

/**
 * Simple hook for implementing editors using Immer
 */
export const useXmlModel = (initialModel: Model): any => {
  const [model, setModel] = React.useState(initialModel);
  const applyMutation = React.useCallback((mutation: ModelMutation) => {
    setModel(produce(mutation));
  }, []);
  return [model, applyMutation];
};
