import { BackendUrls } from '../../dataProvider/types';

export const idmlFormatUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/customIdml`;

export const bitsBundleUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/bitsBundle`;

export const pdfUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/pdf`;

export const pdfOutlineUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/pdfOutline`;

export const pdfToolRunUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/pdfTool/run`;

export const pdfToolLogUrl = (backendUrls: BackendUrls, processId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${processId}/pdfTool/log`;

export const bitsFormatUrl = (backendUrls: BackendUrls, bitsFormatId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionBitsFormats/${bitsFormatId}/format`;

export const bitsFormatUploadUrl = (
  backendUrls: BackendUrls,
  processId: string,
  description: string
) => {
  const query = new URLSearchParams({ processId, description }).toString();

  return `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionBitsFormats/format?${query}`;
};

export const attachmentFormatUrl = (backendUrls: BackendUrls, attachmentId: string) =>
  `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionAttachments/${attachmentId}/format`;
