import { CREATE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders, requestHeaders } from './apiUtil';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  NIKContributorDetailsProvider(urls.innholdBackend, type, params);

interface ContributorDetails {
  id: string;
  contributorId: string;
  shortBio: string;
  title: string;
}

const NIKContributorDetailsProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => {
  const contentAPI = baseApiUrl;
  switch (type) {
    case GET_MANY:
    case GET_LIST: {
      const response = fetch(`${contentAPI}/admin/v1/contributor`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((contributors: ContributorDetails[]) => {
          return { data: contributors, total: 1 };
        });
      return response;
    }
    case GET_ONE: {
      const response = fetch(`${contentAPI}/admin/v1/contributor/${params.id}`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((contributor) => {
          return { data: { id: contributor.id, ...contributor }, total: 1 };
        });
      return response;
    }

    case GET_MANY_REFERENCE: {
      const response = fetch(`${contentAPI}/admin/v1/contributor`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((details) => {
          const contributorDetailIds = details.filter(
            (detail: any) => detail.contributorId === params.id
          );
          return { data: contributorDetailIds, total: 1 };
        });
      return response;
    }

    case CREATE: {
      const response = fetch(`${contentAPI}/admin/v1/contributor`, {
        method: 'POST',
        ...requestHeaders(),
        body: JSON.stringify({
          id: params.data.contributorId,
          shortBio: params.data.shortBio,
          title: params.data.title,
        }),
      })
        .then((res) => res.json())
        .then((contributor) => {
          return { data: { id: contributor.id, ...contributor }, total: 1 };
        });
      return response;
    }

    // Not implementet i API yet

    case UPDATE: {
      const response = fetch(`${contentAPI}/admin/v1/contributor/${params.data.id}`, {
        method: 'PUT',
        ...requestHeaders(),
        body: JSON.stringify({
          shortBio: params.data.shortBio,
          title: params.data.title,
        }),
      })
        .then((res) => res.json())
        .then((contributor) => {
          return { data: { id: contributor.id, ...contributor }, total: 1 };
        });
      return response;
    }

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
