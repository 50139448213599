// @ts-ignore
import * as Types from '../types';

/**
 * Observer for automatically updating data.
 *
 * @param dataProvider The data provider which react-admin uses to fetch data
 * @param observeResource Name of the resource to observe
 * @param intervalMs Refresh interval in ms
 */
const observeRequest = (
  dataProvider: any,
  observeResource: string,
  intervalMs: number,
  shouldFetch: (type: string, resource: string, params: Types.ReactAdminApiParams) => boolean
) => (type: string, resource: string, params: Types.ReactAdminApiParams) => {
  let fetching = false;
  if (resource === observeResource) {
    return {
      subscribe(observer: any) {
        let intervalId: NodeJS.Timeout | undefined = setInterval(() => {
          if (!shouldFetch(type, resource, params) || fetching) return;
          fetching = true;
          dataProvider(type, resource, params)
            .then((results: any) => observer.next(results))
            .then(() => {
              fetching = false;
            })
            .catch((error: any) => observer.error(error));
        }, intervalMs);

        const subscription = {
          unsubscribe() {
            if (intervalId) {
              // Clean up after ourselves
              clearInterval(intervalId);
              intervalId = undefined;
              // Notify the saga that we cleaned up everything
              observer.complete();
            }
          },
        };

        return subscription;
      },
    };
  }
};

export default (
  dataProvider: any,
  resource: string,
  interval: number,
  shouldFetch: (type: string, resource: string, params: Types.ReactAdminApiParams) => boolean
) => observeRequest(dataProvider, resource, interval, shouldFetch);
