import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Stack } from './BitsDebugUtil';
import { LowLevelElementInteraction } from './BitsDebugUI';

const useStyles = makeStyles({
  petit: { fontSize: '0.8rem' },
  inlineFootnoteBox: {
    display: 'block',
    backgroundColor: '#f0efef',
    margin: '.75rem 0',
    padding: '.5rem',
  },
});

export const ParagraphTypography: React.FC<{ stack: Stack }> = ({ children, stack }) => {
  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography paragraph>{children}</Typography>
    </LowLevelElementInteraction>
  );
};

export const PetitParagraphTypography: React.FC<{ stack: Stack }> = ({ children, stack }) => {
  const classes = useStyles();

  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography paragraph className={classes.petit}>
        {children}
      </Typography>
    </LowLevelElementInteraction>
  );
};

export const HeadingTypography: React.FC<{ stack: Stack; level: number }> = ({
  children,
  stack,
  level,
}) => {
  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography variant={`h${level}` as any}>{children}</Typography>
    </LowLevelElementInteraction>
  );
};

export const InlineFootnoteDisplayTypography: React.FC<{ stack: Stack }> = ({
  children,
  stack,
}) => {
  const classes = useStyles();

  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography className={classes.inlineFootnoteBox}>{children}</Typography>
    </LowLevelElementInteraction>
  );
};

export const MarginaliaFootnoteDisplayTypography: React.FC<{ stack: Stack }> = ({
  children,
  stack,
}) => {
  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography>{children}</Typography>
    </LowLevelElementInteraction>
  );
};

export const ParagraphHeadingTypography: React.FC<{ stack: Stack }> = ({ children, stack }) => {
  return (
    <LowLevelElementInteraction stack={stack}>
      <Typography>{children}</Typography>
    </LowLevelElementInteraction>
  );
};
