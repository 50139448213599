import h from 'hastscript';

import { DocumentSpec } from '../documentSpec';
import * as tags from '../tags';
import FormatPlugin from '../plugins/FormatPlugin';
import LinkPlugin from '../plugins/LinkPlugin';
import HeadingPlugin from '../plugins/HeadingPlugin';
import QuotePlugin from '../plugins/QuotePlugin';
import ListPlugin from '../plugins/ListPlugin';
import ParagraphPlugin from '../plugins/ParagraphPlugin';
import TablePlugin from '../plugins/TablePlugin';
import SoftBreakPlugin from '../plugins/SoftBreakPlugin';
import EmptyTextHighlighterPlugin from '../plugins/EmptyTextHighlighterPlugin';
import BoxOutlinePlugin from '../plugins/BoxOutlinePlugin';

export default DocumentSpec.createSpec({
  plugins: [
    FormatPlugin(),
    LinkPlugin(),
    HeadingPlugin([
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
      tags.BLOCK_LEAD_PARAGRAPH,
    ]),
    QuotePlugin(),
    ListPlugin(),
    ParagraphPlugin(),
    TablePlugin(),
    SoftBreakPlugin({
      activeInTypes: [
        tags.BLOCK_PARAGRAPH,
        tags.BLOCK_LEAD_PARAGRAPH,
        tags.BLOCK_QUOTE,
        tags.BLOCK_QUOTE_DISPOSITION,
        tags.BLOCK_FIGCAPTION,
      ],
    }),
    EmptyTextHighlighterPlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
    BoxOutlinePlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_BULLETED_LIST,
      tags.BLOCK_LIST_ITEM,
      tags.BLOCK_ALPHA_LIST,
      tags.BLOCK_NUMBERED_LIST,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
  ],
  hypertextRules: {
    blocks: {
      fragment: [],
      article: [
        {
          type: tags.BLOCK_ARTICLE,
        },
      ],
      header: [
        {
          type: tags.BLOCK_HEADER,
        },
      ],
      section: [
        {
          type: tags.BLOCK_SECTION,
        },
      ],
      p: [
        {
          type: tags.BLOCK_LEAD_PARAGRAPH,
        },
        {
          type: tags.BLOCK_PARAGRAPH,
        },
      ],
      ul: [
        {
          type: tags.BLOCK_REFERENCE_LIST,
        },
        {
          type: tags.BLOCK_BULLETED_LIST,
        },
      ],
      ol: [
        {
          type: tags.BLOCK_ALPHA_LIST,
        },
        {
          type: tags.BLOCK_NUMBERED_LIST,
        },
      ],
      li: [{ type: tags.BLOCK_LIST_ITEM }],
      div: [
        {
          type: tags.BLOCK_LIST_ITEM_CONTENT,
        },
        {
          type: tags.BLOCK_ASIDE,
        },
      ],
      h1: [{ type: tags.BLOCK_HEADING_ONE }],
      h2: [{ type: tags.BLOCK_HEADING_TWO }],
      h3: [{ type: tags.BLOCK_HEADING_THREE }],
      h4: [{ type: tags.BLOCK_HEADING_FOUR }],
      h5: [{ type: tags.BLOCK_HEADING_FIVE }],
      h6: [{ type: tags.BLOCK_HEADING_SIX }],
      table: [{ type: tags.BLOCK_TABLE }],
      thead: [{ type: tags.BLOCK_TABLE_HEADER }],
      tbody: [{ type: tags.BLOCK_TABLE_BODY }],
      tr: [{ type: tags.BLOCK_TABLE_ROW }],
      th: [{ type: tags.BLOCK_TABLE_HEADER_CELL }],
      td: [{ type: tags.BLOCK_TABLE_CELL }],
    },
    inlines: {
      a: [
        {
          type: tags.INLINE_LINK,
          optionalData: ['href'],
        },
      ],
    },
    marks: {
      strong: [{ type: tags.MARK_BOLD }],
      em: [{ type: tags.MARK_ITALIC }],
      u: [{ type: tags.MARK_UNDERLINE }],
      s: [{ type: tags.MARK_STRIKETHROUGH }],
      cite: [{ type: tags.MARK_CITE }],
      b: [{ reimportHtmlTagAs: 'strong' }],
      i: [{ reimportHtmlTagAs: 'em' }],
    },
  },
  serializationFormat: 'hast',
});

export const DefaultHast = () => h('fragment', {}, h('p', ' '));
