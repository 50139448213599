import * as React from 'react';
import * as SlateReact from 'slate-react';
import * as Slate from 'slate';
import Tooltip from '@material-ui/core/Tooltip';
import { Plugin } from './types';

const Highlighted: React.FC<{ children: React.ReactElement; style?: React.CSSProperties }> = ({
  children,
  ...props
}) => {
  // Styles must be destructed last so next plugin can override background
  return (
    <Tooltip
      arrow
      title="Mangler tekst"
      placement="left"
      children={React.cloneElement(children, {
        ...props,
        style: { background: 'rgb(250 249 252)', ...props.style },
      })}
    />
  );
};

export default (types: string[]): Plugin => {
  return {
    key: 'empty-text-highlighter-plugin',
    renderElement: (renderProps: SlateReact.RenderElementProps, element) => {
      if (!types.includes(renderProps.element.type as string)) return element;

      const textNodes = Array.from(
        Slate.Node.descendants(renderProps.element, { pass: (node) => Slate.Text.isText(node) })
      );
      const hasText =
        textNodes
          .map(([node]) => node.text)
          .join('')
          .trim().length > 0;
      if (hasText) return element;

      return <Highlighted>{element}</Highlighted>;
    },
  };
};
