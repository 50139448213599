import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import BEMHelper from 'react-bem-helper';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Commentary } from '../../state/commentary/types';
import { GroupedCommentMeta, TocItem } from '../../state/expressions/types';
import { Draft, Published } from './aside/CommentaryMeta';

const classes = new BEMHelper({
  name: 'commentariesEdit',
});

const CommentaryInfo: React.FC<{
  activeFragment?: TocItem;
  commentary: Commentary;
  fragmentMeta?: GroupedCommentMeta;
  handleCreateAsCopy(): void;
}> = ({ activeFragment, commentary, fragmentMeta, handleCreateAsCopy }) => {
  const translate = useTranslate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (Object.keys(commentary).length === 0) return null;

  return (
    <aside {...classes('asideRight', { isCollapsed })}>
      <IconButton
        aria-label={isCollapsed ? 'Open' : 'Close'}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>

      <div {...classes('asideRightWrapper')}>
        {activeFragment && commentary.fragmentId && (
          <React.Fragment>
            <h2
              {...classes('activeFragment', {
                isDraft: isCollapsed && !commentary.isPublished,
                isPublished: isCollapsed && commentary.isPublished,
              })}
            >
              {activeFragment.heading}
            </h2>
            <div {...classes('infoHeader', { isCollapsed })}>
              {commentary.isPublished ? (
                <div {...classes('notice', 'isPublished')}>
                  <p>{translate('resources.commentaries.notices.is_published')}</p>
                  <p>
                    <button {...classes('createAsCopyAction')} onClick={handleCreateAsCopy}>
                      {translate('resources.commentaries.actions.create_as_copy')}
                    </button>
                  </p>
                </div>
              ) : (
                <div {...classes('notice', 'isDraft')}>
                  <p>{translate('resources.commentaries.notices.is_draft')}</p>
                </div>
              )}
            </div>
            {!isCollapsed && fragmentMeta && (
              <div {...classes('versionsInfo')}>
                <Draft draft={fragmentMeta.draft} />
                <Published published={fragmentMeta.published} />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </aside>
  );
};

export default CommentaryInfo;
