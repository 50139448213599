import * as hast from '@universitetsforlaget/hast';

const isNotNull = <TValue>(value: TValue | null): value is TValue => {
  return value !== null;
};

export const hastToString = (node: hast.HastNode): string => {
  if (node.type === 'text') {
    return node.value;
  } else if (node.children) {
    return node.children.map(hastToString).join('');
  }

  return '';
};

export const containsAnyText = (node: hast.HastNode): boolean => {
  if (node.type === 'text') {
    return node.value ? node.value.length > 0 : false;
  } else if (node.children && node.children.length > 0) {
    return node.children.some(containsAnyText);
  } else {
    return false;
  }
};

export const singleLineHastFromNullableFragment = (
  value?: hast.HastElementNode
): hast.HastElementNode => {
  if (!value) {
    return {
      type: 'element',
      tagName: 'fragment',
      children: [],
    };
  } else {
    return value;
  }
};

export const hastToNullableFragment = (node: hast.HastNode | null): hast.HastElementNode | null => {
  if (!node || !containsAnyText(node)) {
    return null;
  }
  if (node.type === 'text') {
    return hast.createFragment(node);
  }
  return node;
};

export const filterOutFromDocument = (
  node: hast.HastNode,
  matcher: (node: hast.HastNode) => boolean
): hast.HastNode | null => {
  if (matcher(node)) {
    return null;
  }

  if (node.type === 'text') {
    return node;
  }

  if (node.children) {
    const filteredChildren = node.children
      .map((child) => filterOutFromDocument(child, matcher))
      .filter(isNotNull);

    if (filteredChildren.length === 0) {
      const { children, ...rest } = node;
      return rest;
    }

    return {
      ...node,
      children: filteredChildren,
    };
  }

  return node;
};

export const findFirstNode = (
  root: hast.HastNode,
  matcher: (node: hast.HastNode) => boolean
): hast.HastNode | null => {
  if (matcher(root)) {
    return root;
  }

  if (root.type === 'element' && root.children) {
    for (const child of root.children) {
      const result = findFirstNode(child, matcher);
      if (result) return result;
    }
  }

  return null;
};

export const elementNodeOrNull = (node: hast.HastNode | null): hast.HastElementNode | null => {
  return !node || node.type === 'element' ? node : null;
};

export const elementNodeOrError = (node: hast.HastNode | null): hast.HastElementNode => {
  if (!node || node.type !== 'element') {
    throw new Error('node is not an element');
  }
  return node;
};

export const createFragmentIfNotNull = (
  node: hast.HastNode | null
): hast.HastElementNode | null => {
  if (!node) return null;

  return hast.createFragment(node);
};
