import * as t from 'io-ts';
import { halJsonCrudApiProvider } from './apiUtil';
import * as Types from './types';

import { Contact } from '../components/customers/utils/customerContact';
import uuidv4 from 'uuid/v4';

export interface EditedCustomer {
  id: string;
  customerNumber: string;
  name: string;
  contact: Contact;
  accountManager: string | null;
  subscriptionStatus: string | null;
  subscriptionPackageId: string | null;
  subscriptionExpiration: string | null;
  potentialUsers: number | null;
  numberOfUsers: number | null;
  products: (string | null)[];
  registrationCode: string | null;
  signupCode: string | null;
  marketCategoryId: string | null;
}

export interface Customer extends EditedCustomer {
  deletionInDays: number | null;
}

const defaultTrialDate = () => {
  const trialDate = new Date();
  trialDate.setDate(trialDate.getDate() + 7);
  return trialDate.toISOString().split('T')[0];
};

export const createEmptyCustomer = (): Customer => ({
  id: '',
  customerNumber: '',
  name: '',
  accountManager: null,
  contact: {
    id: '',
    name: '',
    email: '',
    phone: '',
    notify: false,
  },
  subscriptionStatus: null,
  subscriptionPackageId: null,

  subscriptionExpiration: defaultTrialDate(),
  deletionInDays: null,
  potentialUsers: 0,
  numberOfUsers: null,
  products: [],
  registrationCode: '',
  signupCode: uuidv4(),
  marketCategoryId: null,
});

const ApiIncomingTypeV = t.type({
  id: t.string,
  customerNumber: t.string,
  name: t.string,
  accountManager: t.union([t.string, t.null]),
  contact: t.type({
    id: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    notify: t.boolean,
  }),
  subscriptionStatus: t.union([t.string, t.null]),
  subscriptionPackageId: t.union([t.string, t.null]),
  subscriptionExpiration: t.union([t.string, t.null]),
  potentialUsers: t.union([t.number, t.null]),
  numberOfUsers: t.number,
  products: t.array(t.union([t.string, t.null])),
  registrationCode: t.union([t.string, t.null]),
  signupCode: t.union([t.string, t.null]),
  marketCategoryId: t.union([t.string, t.null]),
  deletionInDays: t.union([t.number, t.null]),
});

type ApiType = t.TypeOf<typeof ApiIncomingTypeV>;

const ApiOutgoingTypeV = t.type({
  id: t.string,
  customerNumber: t.string,
  name: t.string,
  accountManager: t.union([t.string, t.null]),
  contact: t.type({
    id: t.union([t.string, t.null]),
    name: t.union([t.string, t.null]),
    email: t.union([t.string, t.null]),
    phone: t.union([t.string, t.null]),
    notify: t.boolean,
  }),
  subscriptionStatus: t.union([t.string, t.null]),
  subscriptionPackageId: t.union([t.string, t.null]),
  subscriptionExpiration: t.union([t.string, t.null]),
  potentialUsers: t.union([t.number, t.null]),
  numberOfUsers: t.null,
  products: t.array(t.union([t.string, t.null])),
  registrationCode: t.union([t.string, t.null]),
  signupCode: t.union([t.string, t.null]),
  marketCategoryId: t.union([t.string, t.null]),
});

type ApiOutgoingType = t.TypeOf<typeof ApiOutgoingTypeV>;

export const toApiMapper = (customer: EditedCustomer): ApiOutgoingType => ({
  id: customer.id,
  customerNumber: customer.customerNumber,
  name: customer.name,
  contact: customer.contact,
  accountManager: customer.accountManager || null,
  subscriptionStatus: customer.subscriptionStatus || null,
  subscriptionPackageId: customer.subscriptionPackageId || null,
  subscriptionExpiration: customer.subscriptionExpiration || null,
  potentialUsers: customer.potentialUsers || null,
  products: customer.products ? customer.products.map((id) => id) : [],
  registrationCode: customer.registrationCode || null,
  numberOfUsers: null,
  signupCode: customer.signupCode || null,
  marketCategoryId: customer.marketCategoryId || null,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Customer> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaSubscriptionBackend}/admin/v1/customers`,
    halListName: 'customerDtoV1List',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiCustomer: ApiType): Customer => ({
      id: apiCustomer.id,
      customerNumber: apiCustomer.customerNumber,
      name: apiCustomer.name,
      contact: apiCustomer.contact,
      accountManager: apiCustomer.accountManager,
      subscriptionStatus: apiCustomer.subscriptionStatus,
      subscriptionPackageId: apiCustomer.subscriptionPackageId,
      subscriptionExpiration: apiCustomer.subscriptionExpiration,
      potentialUsers: apiCustomer.potentialUsers,
      numberOfUsers: apiCustomer.numberOfUsers,
      products: apiCustomer.products.map((id) => id),
      registrationCode: apiCustomer.registrationCode,
      signupCode: apiCustomer.signupCode,
      marketCategoryId: apiCustomer.marketCategoryId,
      deletionInDays: apiCustomer.deletionInDays,
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      customer: {
        subscriptionStatus: (id) => ({ subscriptionStatus: id }),
        accountManager: (id) => ({ accountManager: id }),
        subscriptionPackageId: (id) => ({ subscriptionPackage: id }),
        marketCategoryId: (id) => ({ marketCategory: id }),
      },
      emptySubscriptionPackageId: () => ({ subscriptionPackage: 'null' }),
      emptyAccountManager: () => ({ accountManager: 'null' }),
      emptyMarketCategoryId: () => ({ marketCategory: 'null' }),
      scheduledDeletionBefore: (date) => ({ scheduledDeletionBefore: date }),
    },
    sortFields: [],
  });
