import { LinearProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import { Button, SelectInput, SimpleForm, required } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { CustomState } from '../../state/customState';
import { unPublishTextbookEdition } from '../../state/publishing/publishingActions';

export const UnPublishEditionButton: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector<CustomState, boolean>(
    (state) => state.publishing[id]?.status === 'uploading'
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleSubmit = () => {
    dispatch(
      unPublishTextbookEdition.request({
        id,
      })
    );
    setDialogOpen(false);
  };

  return (
    <>
      {isSubmitting && <LinearProgress color="secondary" />}
      <Button label="Avpubliser..." onClick={() => setDialogOpen(true)}>
        <CancelIcon />
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-label="Avpubliser"
        fullWidth
      >
        <SimpleForm variant="standard" save={() => handleSubmit()}>
          <DialogTitle>
            ADVARSEL: Er du sikker på at du vil avpublisere utgave med alle dokumenter?
          </DialogTitle>
          <DialogContent>
            <SelectInput
              source="verifyUnPublish"
              label="Vennligst verifiser avpublisering"
              validate={required()}
              choices={[{ id: true, name: 'Ja, jeg ønsker å avpublisere utgave umiddelbart!' }]}
              fullWidth
            />
          </DialogContent>
        </SimpleForm>
      </Dialog>
    </>
  );
};
