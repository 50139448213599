import React from 'react';
import { List, Datagrid, TextField, EditButton, BooleanField } from 'react-admin';

const ContributorList: React.FC<{}> = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="slug" />
      <TextField source="name" />
      <BooleanField source="memberOfForleggerforeningen" />
      <EditButton />
    </Datagrid>
  </List>
);

export default ContributorList;
