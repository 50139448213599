import * as Slate from 'slate';

import * as tags from '../../tags';
import { getAboveByType } from '../../utils/query';

export const isLastRow = (editor: Slate.Editor): boolean => {
  const tableBodyEntry = getAboveByType(editor, tags.BLOCK_TABLE_BODY);
  if (!tableBodyEntry) return false;

  const rowEntry = getAboveByType(editor, tags.BLOCK_TABLE_ROW);
  if (!rowEntry) return false;

  const [tableNode] = tableBodyEntry;
  const [, rowPath] = rowEntry;

  const currentRowIdx = rowPath[rowPath.length - 1];
  return currentRowIdx === tableNode.children.length - 1;
};
