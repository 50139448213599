import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../dataProvider/apiUtil';

/**
 * Button for uploading any file using form-data, the file passed as the 'file' field.
 */
export const UploadFileButton: React.FC<{
  label: string;
  icon: React.ReactElement;
  method: string;
  url: string;
}> = (props) => {
  const inputFile = React.useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const openFile = () => {
    inputFile.current?.click();
  };

  const uploadCustomIdml = async (fileList: FileList) => {
    const file = fileList.item(0);
    if (file != null) {
      const formData = new FormData();
      formData.append('file', file);

      dispatch(fetchStart());

      try {
        const response = await fetch(props.url, {
          method: props.method,
          headers: juridikaHeaders(),
          body: formData,
        });
        if (!response.ok) {
          throw await response.text();
        }
        await response.text();
      } catch (error) {
        notify(`Problem uploading: ${error}`, 'error');
      }

      dispatch(fetchEnd());
      refresh();
    }
  };

  return (
    <Button label={props.label} color="primary" onClick={openFile}>
      <>
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={(e) => e.target.files && uploadCustomIdml(e.target.files)}
          style={{ display: 'none' }}
        />
        {props.icon}
      </>
    </Button>
  );
};

export default UploadFileButton;
