import * as React from 'react';
import {
  Create,
  SelectInput,
  SimpleForm,
  NumberInput,
  Toolbar,
  required,
  CreateProps,
} from 'react-admin';
import { parse } from 'query-string';
import SaveButtonWithConfirmation from '../SaveButtonWithConfirmation';

interface StripeSubscriptionPriceCreateProps {
  location: any;
  currentUserName: string;
}

const CustomToolbar: React.FC<{ redirect: boolean | string }> = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButtonWithConfirmation
        {...props}
        dialogContent={`
          Er du sikker på at du ønsker å endre prisen på denne pakken?
          Den oppdaterte prisen vil umiddelbart reflekteres på juridika.no idet du lagrer.
          Nye brukere vil kun kunne abonnere med denne prisen.
          Eksisterende kunder beholder samme pris som før.
          Dette kan styres via Stripe dashboardet.
        `}
      />
    </Toolbar>
  );
};

const StripeSubscriptionPriceCreate: React.FC<CreateProps> = (props) => {
  const { source } = parse(props?.location?.search ?? '');
  const { productId } = source ? JSON.parse(source as string) : false;
  const redirect = productId ? `/stripeSubscriptionProducts/${productId}/show` : false;

  return (
    <Create {...props} title="Oppdater pris">
      <SimpleForm
        initialValues={{ currency: 'NOK' }}
        redirect={redirect}
        toolbar={<CustomToolbar redirect={redirect} />}
      >
        <SelectInput
          label="resources.stripeSubscriptionPrices.fields.pricingInterval"
          source="pricingInterval"
          validate={required()}
          choices={[
            { id: 'MONTHLY', name: 'Månedlig' },
            { id: 'YEARLY', name: 'Årlig' },
          ]}
        />
        <NumberInput label="Pris (inkl. mva)" source="price" validate={required()} />
        <SelectInput
          label="resources.stripeSubscriptionPrices.fields.currency"
          source="currency"
          validate={required()}
          choices={[
            { id: 'NOK', name: 'NOK' },
            // { id: 'EUR', name: 'EUR' },
            // { id: 'USD', name: 'USD' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default StripeSubscriptionPriceCreate;
