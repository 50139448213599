import { CREATE, GET_LIST, GET_ONE, GET_MANY } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders, requestHeaders } from './apiUtil';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  NIKPublicationsApiProvider(urls.innholdBackend, type, params);

const NIKPublicationsApiProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => {
  const contentAPI = baseApiUrl;

  switch (type) {
    case GET_LIST: {
      const response = fetch(`${contentAPI}/admin/v1/publication`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((publications) => {
          const sortedPublications = publications.sort((a: any, b: any) =>
            a.title.localeCompare(b.title)
          );
          return { data: sortedPublications, total: 1 };
        });
      return response;
    }

    case GET_MANY: {
      const response = fetch(`${contentAPI}/admin/v1/publication`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((publications) => {
          const publicationsById = publications.filter((publication: any) =>
            params.ids!!.includes(publication.id)
          );
          return { data: publicationsById, total: 1 };
        });
      return response;
    }

    case GET_ONE: {
      const response = fetch(`${contentAPI}/admin/v1/publication/${params.id}`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((publication) => {
          return { data: { id: publication.id, ...publication }, total: 1 };
        });
      return response;
    }

    case CREATE: {
      const response = fetch(`${contentAPI}/admin/v1/publication`, {
        method: 'POST',
        ...requestHeaders(),
        body: JSON.stringify({
          title: params.data.title,
          description: params.data.description || '',
          slug: params.data.slug,
          publisherId: params.data.publisherId,
          primaryTag: params.data.primaryTag,
          tags: [],
        }),
      })
        .then((res) => res.json())
        .then((publication) => {
          return { data: { id: publication.id, ...publication }, total: 1 };
        });
      return response;
    }

    case 'UPDATE': {
      const response = fetch(`${contentAPI}/admin/v1/publication/${params.id}`, {
        method: 'PUT',
        ...requestHeaders(),
        body: JSON.stringify({
          title: params.data.title,
          description: params.data.description || '',
          slug: params.data.slug,
          publisherId: params.data.publisherId,
          primaryTag: params.data.primaryTag,
          tags: [],
        }),
      })
        .then((res) => res.json())
        .then((publication) => {
          return { data: publication, total: 1 };
        });
      return response;
    }

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
