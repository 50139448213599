import React from 'react';
import { Edit } from 'react-admin';

import { CustomerForm } from './components/CustomerForm';
import { Customer } from '../../dataProvider/customersProvider';

const Title: React.FC<{
  record?: Customer;
}> = ({ record: customer }) => (
  <span>Endre detaljer for kunde {customer && `"${customer.name}"`} </span>
);

const CustomerEdit = (props: any) => (
  <Edit title={<Title />} {...props}>
    <CustomerForm />
  </Edit>
);

export default CustomerEdit;
