import * as React from 'react';
import { useTranslate } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

interface SlateToolbarButtonProps {
  isActive: boolean;
  Icon: React.ComponentType;
  message: string;
  onMouseDown: (event: React.MouseEvent<HTMLElement>) => void;
}

const SlateToolbarButton: React.FC<SlateToolbarButtonProps> = ({
  isActive,
  Icon,
  message,
  onMouseDown,
  ...rest
}) => {
  const translate = useTranslate();
  const translatedMessage = translate(message) || '';

  return (
    <Tooltip title={translatedMessage}>
      <IconButton
        color={isActive ? 'inherit' : 'secondary'}
        aria-label={translatedMessage}
        onMouseDown={onMouseDown}
        {...rest}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  );
};

export default SlateToolbarButton;
