import * as Types from './types';

import * as StaticDocumentRevisionsProvider from './staticDocumentRevisionsProvider';
import * as NoticeSnapshotsProvider from './noticeSnapshotsProvider';
import * as PageSnapshotsProvider from './pageSnapshotsProvider';
import * as ContractSnapshotsProvider from './contractSnapshotsProvider';
import * as EmbeddedSnapshotsProvider from './embeddedSnapshotsProvider';
import * as EditorAccountsProvider from './editorAccountsProvider';
import * as FeedsProvider from './feedsProvider';
import * as ContentCategoriesProvider from './contentCategoriesProvider';
import * as ContributorsProvider from './contributorsProvider';
import * as TextbookPublicationsProvider from './textbookPublicationsProvider';
import * as TextbookEditionsProvider from './textbookEditionsProvider';
import * as TextbookModulesProvider from './textbookModulesProvider';
import * as LegalDocumentsProvider from './lovbackend/legalDocumentsProvider';
import * as LegalWorksProvider from './lovbackend/legalWorksProvider';
import * as LegalChangesProvider from './lovbackend/legalChangesProvider';
import * as LegalChangesSuggestProvider from './lovbackend/legalChangesSuggestProvider';
import * as LegalRelationsProvider from './lovbackend/legalRelationsProvider';
import * as ImageApi from './imageAPI';
import * as JournalPublicationsProvider from './journalPublicationsProvider';
import * as JournalArticlesProvider from './journalArticlesProvider';
import * as JournalEditionsProvider from './journalEditionsProvider';
import * as LinkedJournalsProvider from './linkedJournalsProvider';
import * as PublishersProvider from './publishersProvider';
import * as LiteratureConversionProcessesProvider from './literatureConversionProcessesProvider';
import * as LiteratureConversionBitsFormatsProvider from './literatureConversionBitsFormatsProvider';
import * as LiteratureConversionAttachmentsProvider from './literatureConversionAttachmentsProvider';
import * as LiteratureDocumentTagsProvider from './literatureDocumentTagsProvider';
import * as LegalWorkTagsProvider from './legalWorkTagsProvider';
import * as CustomersProvider from './customersProvider';
import * as StaticDocumentTagsProvider from './staticDocumentTagsProvider';
import * as SubscriptionStatusProvider from './subscriptionStatusProvider';
import * as SubscriptionPackageProvider from './subscriptionPackageProvider';
import * as AccountManagersProvider from './accountManagerProvider';
import * as ProductsProvider from './customerProductsProvider';
import * as MarketCategoriesProvider from './marketCategoriesProvider';
import * as TagsProvider from './tagsProvider';
import * as stripeSubscriptionProductsProvider from './stripeSubscriptionProductsProvider';
import * as stripeSubscriptionPricesProvider from './stripeSubscriptionPricesProvider';
import * as usersProvider from './usersProvider';
import * as userMigrationProvider from './userMigrationProvider';
import { legalTocProvider } from './lovbackend/legalTocProvider';
import * as NIKDocumentsProvider from './NIKDocumentsProvider';
import * as NIKPublicationsProvider from './NIKPublicationsProvider';
import * as NIKEditionsProvider from './NIKEditionsProvider';
import * as NIKContributorDetailsProvider from './NIKContributorDetailsProvider';

// todo: try to simplify
export interface RootProvider {
  backendUrls: Types.BackendUrls;

  revisions: ReturnType<typeof StaticDocumentRevisionsProvider.createProvider>;
  noticeSnapshots: ReturnType<typeof NoticeSnapshotsProvider.createProvider>;
  feeds: ReturnType<typeof FeedsProvider.createProvider>;
  pageSnapshots: ReturnType<typeof PageSnapshotsProvider.createProvider>;
  contractSnapshots: ReturnType<typeof ContractSnapshotsProvider.createProvider>;
  embeddedSnapshots: ReturnType<typeof EmbeddedSnapshotsProvider.createProvider>;
  contentCategories: ReturnType<typeof ContentCategoriesProvider.createProvider>;
  editorAccounts: ReturnType<typeof EditorAccountsProvider.createProvider>;
  contributors: ReturnType<typeof ContributorsProvider.createProvider>;
  literatureDocumentTags: ReturnType<typeof LiteratureDocumentTagsProvider.createProvider>;
  textbookPublications: ReturnType<typeof TextbookPublicationsProvider.createProvider>;
  textbookEditions: ReturnType<typeof TextbookEditionsProvider.createProvider>;
  textbookModules: ReturnType<typeof TextbookModulesProvider.createProvider>;
  journalArticles: ReturnType<typeof JournalArticlesProvider.createProvider>;
  journalPublications: ReturnType<typeof JournalPublicationsProvider.createProvider>;
  journalEditions: ReturnType<typeof JournalEditionsProvider.createProvider>;
  linkedJournals: ReturnType<typeof LinkedJournalsProvider.createProvider>;
  publishers: ReturnType<typeof PublishersProvider.createProvider>;
  literatureConversionProcesses: ReturnType<
    typeof LiteratureConversionProcessesProvider.createProvider
  >;
  literatureConversionBitsFormats: ReturnType<
    typeof LiteratureConversionBitsFormatsProvider.createProvider
  >;
  literatureConversionAttachments: ReturnType<
    typeof LiteratureConversionAttachmentsProvider.createProvider
  >;
  customers: ReturnType<typeof CustomersProvider.createProvider>;
  products: ReturnType<typeof ProductsProvider.createProvider>;
  accountManagers: ReturnType<typeof AccountManagersProvider.createProvider>;
  subscriptionStatus: ReturnType<typeof SubscriptionStatusProvider.createProvider>;
  subscriptionPackage: ReturnType<typeof SubscriptionPackageProvider.createProvider>;
  marketCategory: ReturnType<typeof SubscriptionPackageProvider.createProvider>;
  legalDocuments: ReturnType<typeof LegalDocumentsProvider.createProvider>;
  legalWorkTags: ReturnType<typeof LegalWorkTagsProvider.createProvider>;
  legalWorks: ReturnType<typeof LegalWorksProvider.createProvider>;
  legalChanges: ReturnType<typeof LegalChangesProvider.createProvider>;
  legalChangesSuggest: ReturnType<typeof LegalChangesSuggestProvider.createProvider>;
  legalRelations: ReturnType<typeof LegalRelationsProvider.createProvider>;
  legalToc: ReturnType<typeof legalTocProvider>;
  images: ImageApi.ImageAPI;
  tags: ReturnType<typeof TagsProvider.createProvider>;
  staticDocumentTags: ReturnType<typeof StaticDocumentTagsProvider.createProvider>;
  stripeSubscriptionProducts: ReturnType<typeof stripeSubscriptionProductsProvider.createProvider>;
  stripeSubscriptionPrices: ReturnType<typeof stripeSubscriptionPricesProvider.createProvider>;
  users: ReturnType<typeof usersProvider.createProvider>;
  userMigrations: ReturnType<typeof userMigrationProvider.createProvider>;
  nikDocuments: ReturnType<typeof NIKDocumentsProvider.createProvider>;
  nikPublications: ReturnType<typeof NIKPublicationsProvider.createProvider>;
  nikEditions: ReturnType<typeof NIKEditionsProvider.createProvider>;
  nikContributorDetails: ReturnType<typeof NIKContributorDetailsProvider.createProvider>;
}

export const createRootProvider = (urls: Types.BackendUrls): RootProvider => ({
  backendUrls: urls,

  revisions: StaticDocumentRevisionsProvider.createProvider(urls),
  noticeSnapshots: NoticeSnapshotsProvider.createProvider(urls),
  feeds: FeedsProvider.createProvider(urls),
  pageSnapshots: PageSnapshotsProvider.createProvider(urls),
  contractSnapshots: ContractSnapshotsProvider.createProvider(urls),
  embeddedSnapshots: EmbeddedSnapshotsProvider.createProvider(urls),
  contentCategories: ContentCategoriesProvider.createProvider(urls),
  literatureDocumentTags: LiteratureDocumentTagsProvider.createProvider(urls),
  editorAccounts: EditorAccountsProvider.createProvider(urls),
  contributors: ContributorsProvider.createProvider(urls),
  textbookPublications: TextbookPublicationsProvider.createProvider(urls),
  textbookEditions: TextbookEditionsProvider.createProvider(urls),
  textbookModules: TextbookModulesProvider.createProvider(urls),
  journalArticles: JournalArticlesProvider.createProvider(urls),
  journalEditions: JournalEditionsProvider.createProvider(urls),
  journalPublications: JournalPublicationsProvider.createProvider(urls),
  linkedJournals: LinkedJournalsProvider.createProvider(urls),
  publishers: PublishersProvider.createProvider(urls),
  literatureConversionProcesses: LiteratureConversionProcessesProvider.createProvider(urls),
  literatureConversionBitsFormats: LiteratureConversionBitsFormatsProvider.createProvider(urls),
  literatureConversionAttachments: LiteratureConversionAttachmentsProvider.createProvider(urls),
  customers: CustomersProvider.createProvider(urls),
  products: ProductsProvider.createProvider(urls),
  accountManagers: AccountManagersProvider.createProvider(urls),
  subscriptionStatus: SubscriptionStatusProvider.createProvider(urls),
  subscriptionPackage: SubscriptionPackageProvider.createProvider(urls),
  marketCategory: MarketCategoriesProvider.createProvider(urls),
  legalDocuments: LegalDocumentsProvider.createProvider(urls),
  legalWorkTags: LegalWorkTagsProvider.createProvider(urls),
  legalWorks: LegalWorksProvider.createProvider(urls),
  legalChanges: LegalChangesProvider.createProvider(urls),
  legalChangesSuggest: LegalChangesSuggestProvider.createProvider(urls),
  legalRelations: LegalRelationsProvider.createProvider(urls),
  legalToc: legalTocProvider(urls),
  tags: TagsProvider.createProvider(urls),
  staticDocumentTags: StaticDocumentTagsProvider.createProvider(urls),
  stripeSubscriptionProducts: stripeSubscriptionProductsProvider.createProvider(urls),
  stripeSubscriptionPrices: stripeSubscriptionPricesProvider.createProvider(urls),
  users: usersProvider.createProvider(urls),
  userMigrations: userMigrationProvider.createProvider(urls),
  nikDocuments: NIKDocumentsProvider.createProvider(urls),
  nikPublications: NIKPublicationsProvider.createProvider(urls),
  nikEditions: NIKEditionsProvider.createProvider(urls),
  nikContributorDetails: NIKContributorDetailsProvider.createProvider(urls),

  // Special stuff that's not purely react-admin providers:
  images: new ImageApi.ImageAPI(urls),
});

export const createReactAdminDataProvider = (
  urls: Types.BackendUrls
): Types.ReactAdminDataProvider => {
  const root = createRootProvider(urls);

  return (type, resource, params) => {
    switch (resource) {
      case 'revisions':
        return root.revisions(type, params);
      case 'noticeSnapshots':
        return root.noticeSnapshots(type, params);
      case 'feeds':
        return root.feeds(type, params);
      case 'pageSnapshots':
        return root.pageSnapshots(type, params);
      case 'contractSnapshots':
        return root.contractSnapshots(type, params);
      case 'embeddedSnapshots':
        return root.embeddedSnapshots(type, params);
      case 'contentCategories':
        return root.contentCategories(type, params);
      case 'literatureDocumentTags':
        return root.literatureDocumentTags(type, params);
      case 'editorAccounts':
        return root.editorAccounts(type, params);
      case 'contributors':
        return root.contributors(type, params);
      case 'textbookPublications':
        return root.textbookPublications(type, params);
      case 'textbookEditions':
        return root.textbookEditions(type, params);
      case 'textbookModules':
        return root.textbookModules(type, params);
      case 'legalDocuments':
        return root.legalDocuments(type, params);
      case 'legalWorks':
        return root.legalWorks(type, params);
      case 'legalChanges':
        return root.legalChanges(type, params);
      case 'legalChangesSuggest':
        return root.legalChangesSuggest(type, params);
      case 'legalRelations':
        return root.legalRelations(type, params);
      case 'legalWorkTags':
        return root.legalWorkTags(type, params);
      case 'legalToc':
        return root.legalToc(type, params);
      case 'journalArticles':
        return root.journalArticles(type, params);
      case 'journalEditions':
        return root.journalEditions(type, params);
      case 'journalPublications':
        return root.journalPublications(type, params);
      case 'linkedJournals':
        return root.linkedJournals(type, resource, params);
      case 'publishers':
        return root.publishers(type, params);
      case 'literatureConversionProcesses':
        return root.literatureConversionProcesses(type, params);
      case 'literatureConversionBitsFormats':
        return root.literatureConversionBitsFormats(type, params);
      case 'literatureConversionAttachments':
        return root.literatureConversionAttachments(type, params);
      case 'customers':
        return root.customers(type, params);
      case 'subscriptionStatus':
        return root.subscriptionStatus(type, params);
      case 'subscriptionPackage':
        return root.subscriptionPackage(type, params);
      case 'accountManagers':
        return root.accountManagers(type, params);
      case 'products':
        return root.products(type, params);
      case 'marketCategory':
        return root.marketCategory(type, params);
      case 'tags':
        return root.tags(type, params);
      case 'staticDocumentTags':
        return root.staticDocumentTags(type, params);
      case 'stripeSubscriptionProducts':
        return root.stripeSubscriptionProducts(type, resource, params);
      case 'stripeSubscriptionPrices':
        return root.stripeSubscriptionPrices(type, resource, params);
      case 'users':
        return root.users(type, params);
      case 'userMigrations':
        return root.userMigrations(type, params);
      case 'nikDocuments':
        return root.nikDocuments(type, params);
      case 'nikPublications':
        return root.nikPublications(type, params);
      case 'nikEditions':
        return root.nikEditions(type, params);
      case 'nikContributorDetails':
        return root.nikContributorDetails(type, params);
      default:
        return Promise.reject();
    }
  };
};
