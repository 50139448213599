import * as Slate from 'slate';
import { tryGetNode } from '../../utils/query';

import { Plugin } from '../types';
import { Rule } from './types';
import { sortRulesByParentFirstAscending } from './utils';

const filterChildren = (editor: Slate.Editor, parent: Slate.Node, rule: Rule) => {
  if (!Slate.Element.isElement(parent)) return;
  const { limitChildrenCount, strictChildenTypes } = rule;

  if (strictChildenTypes) {
    const children = Array.from(Slate.Node.children(editor, rule.at, { reverse: true }));
    for (const [child, path] of children) {
      if (!strictChildenTypes.includes(child.type as string)) {
        Slate.Transforms.removeNodes(editor, { at: path });
      }
    }
  }

  if (limitChildrenCount) {
    const children = Array.from(Slate.Node.children(editor, rule.at, { reverse: true }));
    for (const [, path] of children) {
      if (path[path.length - 1] < limitChildrenCount) break;
      Slate.Transforms.removeNodes(editor, { at: path });
    }
  }
};

const handleExistingNode = (editor: Slate.Editor, rule: Rule, node: Slate.Node) => {
  const { overridePath, match, at } = rule;

  const matchElement = typeof match === 'object';
  const desiredType = matchElement ? (match as Slate.Element).type : match;

  if (overridePath === false || node.type === desiredType) {
    filterChildren(editor, node, rule);
    return;
  }

  const existingText = Slate.Editor.string(editor, at);
  Slate.Transforms.removeNodes(editor, { at });
  Slate.Transforms.insertNodes(
    editor,
    matchElement
      ? ({ children: [{ text: existingText }], ...(match as {}) } as Slate.Element)
      : { type: match, children: [{ text: existingText }] },
    { at, select: false }
  );
};

const handleMissingNode = (editor: Slate.Editor, rule: Rule, onFail?: () => void) => {
  const { alwaysPresent, match, at } = rule;
  if (alwaysPresent === false) return;

  const matchElement = typeof match === 'object';
  const node = matchElement
    ? ({ children: [{ text: '' }], ...(match as {}) } as Slate.Element)
    : { type: match, children: [{ text: '' }] };

  try {
    Slate.Transforms.insertNodes(editor, node, { at, select: false });
  } catch (err) {
    onFail?.();
  }
};

const normalizeByRule = (editor: Slate.Editor, rule: Rule, allRules: Rule[]): Slate.Path[] => {
  const node = tryGetNode(editor, rule.at);
  const pathsHandled = [rule.at];

  if (node) {
    handleExistingNode(editor, rule, node);
  } else {
    handleMissingNode(editor, rule, () => {
      const parentRule = allRules.find(
        ({ at }) => at.toString() === rule.at.slice(0, -1).toString()
      );

      if (parentRule) {
        // Try to fix parent
        const ancestorPathsHandled = normalizeByRule(editor, parentRule, allRules);
        pathsHandled.push(...ancestorPathsHandled);

        // Retry child
        handleMissingNode(editor, rule);
      }
    });
  }

  return pathsHandled;
};

const hasMaybeLayoutChanged = (editor: Slate.Editor) => {
  // Selection and text operations does not change the layout
  return editor.operations.find((operation) => Slate.Operation.isNodeOperation(operation));
};

export default (rules: Rule[]): Plugin => {
  return {
    key: 'forced-layout-plugin',
    withEditor: (editor) => {
      const { normalizeNode } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [, currentPath] = entry;

        // Other normalizations might modify the layout so do them first
        normalizeNode(entry);

        if (currentPath.length > 0 || !hasMaybeLayoutChanged(editor)) return;

        // Rules are only applied for root
        const sortedRules = sortRulesByParentFirstAscending(rules);
        const appliedRulesAtPaths: Slate.Path[] = [];
        for (const rule of sortedRules) {
          // No need to apply rule twice as we might have handled it recursively
          if (!appliedRulesAtPaths.find((path) => path.toString() === rule.at.toString())) {
            const pathsHandled = normalizeByRule(editor, rule, sortedRules);
            appliedRulesAtPaths.push(...pathsHandled);
          }
        }
      };

      return editor;
    },
  };
};
