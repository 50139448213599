import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '15px',
  },
  developerMark: {
    backgroundColor: '#00ffe7',
  },
  contentCreatorMark: {
    backgroundColor: '#ff8ff3cf',
  },
});

const ReadmePanel = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <section>
        <h2>Test Ny Slate Editor</h2>
        <mark className={classes.developerMark}>For utviklere</mark>
        <p>
          Her testes teksteditor til nytt innholdskonsept. Editoren er basert på Slate.js. Vi
          trenger å ha noe til innholdsprodusentene raskt og har derfor ikke tid til å gjøre en
          ordentlig migrering av eksisterende Slate kode, derfor prøver vi å kjøre to versjoner av
          Slate samtidig i prosjektet. Vi kjører den oppdaterte versjonen av Slate under aliaset{' '}
          <code>new-slate</code>.
        </p>
        <ul>
          <li>"new-slate": "npm:slate@0.101.5",</li>
          <li>"new-slate-history": "npm:slate-history@0.100.0",</li>
          <li>"new-slate-react": "npm:slate-react@0.101.6",</li>
        </ul>
      </section>
      <section>
        <h2>Bruksanvisning</h2>
        <mark className={classes.contentCreatorMark}>For innholdsteam</mark>
        <ul>
          <li>Anbefaler å bruke en stor skjerm slik at man har god plass til begge panelene.</li>
          <li>
            For å få en <em>live preview</em> av hvordan endringene dine vil se ut på Juridika.no
            kan du bruke <em>PREVIEW</em>-tabben.
          </li>
          <li>Sørg for at dokumentet ditt starter med en overskrift</li>
          <li>
            <em>RAW</em>-tabben brukes for å se dataene i de forskjellige stegene den går gjennom
            før den lagres i databasen. Dette er et godt verktøy for utviklerne når de skal
            feilsøke.
          </li>
          <li>
            Kenneth har laget et skjema så det blir litt mer tydelig hva som trengs for å publisere
            problemstillinger:{' '}
            <a
              href="https://aschehoug.sharepoint.com/:x:/s/Juridika969/EbScrrF40chJjayx8uVnx3cBXnx6XKd9tl5-l1kFy-wVFw?e=1mrDws"
              target="_blank"
              rel="noopener noreferrer"
            >
              sharepoint skjema
            </a>
          </li>
        </ul>
      </section>
      <section>
        <h2>Gjøreliste</h2>

        <form>
          <input readOnly type="checkbox" id="todo1" name="todo1" value="Todo1" checked />
          <label htmlFor="todo1">Hente inn riktig dokument når man er i preview</label>
          <br />
          <input readOnly type="checkbox" id="todo2" name="todo2" value="Todo2" />
          <label htmlFor="todo2">Bedre støtte for angring</label>
          <br />
          <input readOnly type="checkbox" id="todo3" name="todo3" value="Todo3" />
          <label htmlFor="todo3">Telle antall ord i teksten</label>
          <br />
        </form>
      </section>
    </div>
  );
};

export default ReadmePanel;
