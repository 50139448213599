import React from 'react';

export const SlateInputContext = React.createContext({
  shouldDisplaySlateInputContextMenu: (): boolean => false,
  setIsFocused: (focused: boolean) => {},
  setActiveToolbarItemVisible: (visible: boolean) => {},
});

export const SlateInputContextProvider: React.FC<{}> = ({ children }) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [activeToolbarItemVisible, setActiveToolbarItemVisible] = React.useState(false);

  return (
    <SlateInputContext.Provider
      value={{
        shouldDisplaySlateInputContextMenu: () => isFocused || activeToolbarItemVisible,
        setIsFocused,
        setActiveToolbarItemVisible,
      }}
    >
      {children}
    </SlateInputContext.Provider>
  );
};
