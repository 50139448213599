import React, { FC } from 'react';
import Select from 'react-select';
import styles from './SearchableSelect.module.css';

export interface Option {
  value: string;
  label: string;
}

const SearchableSelect: FC<{
  selectedOption: Option | null;
  options: Option[];
  onChange: (selectedOption: Option | null) => void;
  placeholder?: string;
}> = ({ selectedOption, options, onChange, placeholder }) => {
  return (
    <div className={styles.selectWrapper}>
      <Select
        value={selectedOption}
        options={options}
        onChange={onChange}
        placeholder={placeholder || 'Velg...'}
        isLoading={options.length === 0}
      />
    </div>
  );
};

export default SearchableSelect;
