import React from 'react';
import { TextField } from '@material-ui/core';
import { InputProps, useInput, useTranslate } from 'ra-core';

const isValidSlug = (slug?: string): boolean => !!slug && slug.search(/[^-0-9a-zæøåäöü]/) === -1;

export const useGeneratedSlugState = (): {
  generatedSlug: string;
  setGeneratedSlug: React.Dispatch<React.SetStateAction<string>>;
  generateSlug(e: React.ChangeEvent<HTMLInputElement>): void;
} => {
  const [generatedSlug, setGeneratedSlug] = React.useState('');

  const generateSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const slug = inputValue.split(' ').join('-').toLocaleLowerCase();

    setGeneratedSlug(slug);
  };

  return { generatedSlug, setGeneratedSlug, generateSlug };
};

const SlugInput: React.FC<InputProps> = (props) => {
  const translate = useTranslate();

  const { value, onChange } = props;
  const { input, isRequired } = useInput(props);

  const checkForErrors = () => {
    if (!value) return translate('ra.validation.required');

    if (!isValidSlug(value)) return translate('ra.validation.slugFormat');
  };

  const error = checkForErrors();

  return (
    <TextField
      label={translate('resources.contractSnapshots.fields.slug')}
      margin="normal"
      fullWidth
      error={!!error}
      helperText={error}
      required={isRequired}
      {...input}
      value={value}
      onChange={onChange}
    />
  );
};

export default SlugInput;
