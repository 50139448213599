import * as React from 'react';
import * as Slate from 'slate';
import debounce from 'lodash.debounce';
import { HastElementNode } from '@universitetsforlaget/hast';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import SlateEditor from './SlateEditor';
import CmsContextMenu from '../CmsContextMenu';
import { SlateInputContext } from '../SlateInput/SlateInputContextProvider';
import { DocumentSpec } from '../../slate/documentSpec';
import { Plugin } from '../../slate/plugins/types';
import { SlateHelpDialog } from './SlateHelpDialog';

// Styles
import '../../styles/slateEditor.css';
import '../../styles/slateFonts.css';

export interface SlateInputProps {
  readOnly: boolean;
  value: HastElementNode | string;
  onChange: (value: HastElementNode | string) => void;
  documentSpec: DocumentSpec;
  spellCheck?: boolean;
}

const SERIALIZATION_DELAY = 1000;

const SlateInput: React.SFC<SlateInputProps> = ({
  readOnly,
  value,
  onChange,
  documentSpec,
  spellCheck = false,
}) => {
  const [slateValue, setSlateValue] = React.useState<Slate.Node[]>();
  const [slateEditor, setSlateEditor] = React.useState<Slate.Editor>();
  const [slateEditorFocused, setSlateEditorFocused] = React.useState<boolean>(false);
  const [slateEditorChanged, setSlateEditorChanged] = React.useState<boolean>(false);
  const [helpDialogVisible, setHelpDialogVisible] = React.useState<boolean>(false);
  const [pluginState, setPluginState] = React.useState<{ [key: string]: any }>({});
  const { shouldDisplaySlateInputContextMenu } = React.useContext(SlateInputContext);

  React.useEffect(() => {
    if (slateEditor) {
      const processedNodes = documentSpec.plugins.reduce((acc, plugin) => {
        return plugin.preProcessNodesAfterDeserialization?.(acc) ?? acc;
      }, documentSpec.deserialize(value));
      setSlateValue(processedNodes);
    }
    // Do not add 'value' here as pre-processing is only done initially when we first render the editor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slateEditor, documentSpec]);

  const handleChange = (value: Slate.Node[]) => {
    setSlateEditorChanged(!slateEditorChanged);
    setSlateValue(value);
    debouncedCallbackOnChange(value);
  };

  const handleFocusChange = (isFocused: boolean) => {
    setSlateEditorFocused(isFocused);
  };

  const setEditor = React.useCallback((editor: Slate.Editor) => {
    setSlateEditor(editor);
  }, []);

  const handlePluginState = (key: string, state: any) => {
    setPluginState({
      [key]: state,
    });
  };

  const debouncedCallbackOnChange = debounce((value: Slate.Node[]) => {
    const processedNodes = documentSpec.plugins.reduce((acc, plugin) => {
      return plugin.postProcessNodesBeforeSerialization?.(acc) ?? acc;
    }, value);
    onChange(documentSpec.serialize(processedNodes) as HastElementNode);
  }, SERIALIZATION_DELAY);

  const pluginSetStateFunctions = documentSpec.plugins.map((plugin: Plugin) => {
    return (state: any) => {
      handlePluginState(plugin.key, state);
    };
  });

  const renderPluginToolbar = () => {
    if (!slateEditor) return null;

    if (slateEditorFocused || shouldDisplaySlateInputContextMenu()) {
      return (
        <CmsContextMenu>
          {documentSpec.plugins.map((plugin, index) =>
            plugin.toolbar?.render(
              slateEditor,
              pluginState[plugin.key],
              pluginSetStateFunctions[index],
              slateEditorChanged,
              slateEditorFocused
            )
          )}
          <Tooltip title="Hjelp">
            <IconButton
              color="secondary"
              aria-label="Hjelp"
              onMouseDown={() => setHelpDialogVisible(true)}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </CmsContextMenu>
      );
    }

    return null;
  };

  const renderPluginDialog = () => {
    if (!slateEditor) return null;

    return (
      <>
        {documentSpec.plugins.map((plugin, index) =>
          plugin.dialog?.render(
            slateEditor,
            pluginState[plugin.key],
            pluginSetStateFunctions[index],
            slateEditorChanged,
            slateEditorFocused
          )
        )}

        <SlateHelpDialog open={helpDialogVisible} onClose={() => setHelpDialogVisible(false)} />
      </>
    );
  };

  const renderPluginPopup = () => {
    if (!slateEditor) return null;

    return (
      <>
        {documentSpec.plugins.map((plugin, index) =>
          plugin.popup?.render(
            slateEditor,
            pluginState[plugin.key],
            pluginSetStateFunctions[index],
            slateEditorChanged,
            slateEditorFocused
          )
        )}
      </>
    );
  };

  return (
    <>
      <SlateEditor
        value={slateValue}
        documentSpec={documentSpec}
        readOnly={readOnly}
        onChange={handleChange}
        onFocusChange={handleFocusChange}
        setEditor={setEditor}
        spellCheck={spellCheck}
      />
      {renderPluginToolbar()}
      {renderPluginDialog()}
      {renderPluginPopup()}
    </>
  );
};

export default SlateInput;
