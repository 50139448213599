import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import CodeIcon from '@material-ui/icons/Code';

import { convertToIndexWithParsing } from '../../../util/simpleBits';

import { describeNode, Stack, stackToPath, UiContext } from '../BitsDebugUtil';

export const BitsDebugToolbarConvertContextMenu: React.FC<{ stack?: Stack }> = ({ stack }) => {
  const { applyDocumentMutation } = React.useContext(UiContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const anchor = anchorEl ? null : event.currentTarget;

    setAnchorEl(anchor);
  };

  const title = stack ? `Konverter ${describeNode(stack.element)}...` : 'Konverter...';
  const path = stackToPath(stack ?? null);

  return (
    <>
      <Tooltip title={title}>
        <IconButton aria-label={title} disabled={!stack} color="inherit" onClick={toggleMenu}>
          <CodeIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        disableAutoFocus={true}
        disableAutoFocusItem={true}
        anchorEl={anchorEl}
        open={open}
        onClose={toggleMenu}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            applyDocumentMutation(convertToIndexWithParsing(path));
          }}
        >
          Konverter til index
        </MenuItem>
        {/*
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            applyDocumentMutation(convertToRefList(path));
          }}
        >
          Konverter til ref-list
        </MenuItem>
        */}
      </Menu>
    </>
  );
};
