import * as Slate from 'slate';

export type Matcher = (
  node: Slate.Node,
  index: number,
  followingNodes: Slate.Node[],
  matchers: Matchers
) => boolean;

export interface Matchers {
  [rule: string]: Matcher;
}

export const matchType = (
  type: string,
  node: Slate.Node,
  index: number,
  array: Slate.Node[],
  rootMatchers: Matchers
): boolean => {
  return node.type === type;
};

export const matchIndex = (
  indexToMatch: number,
  node: Slate.Node,
  index: number,
  array: Slate.Node[],
  rootMatchers: Matchers
): boolean => {
  if (indexToMatch < 0) {
    return index - array.length === indexToMatch;
  }
  return index === indexToMatch;
};

export const matchPositionBefore = (
  ruleName: string,
  node: Slate.Node,
  index: number,
  array: Slate.Node[],
  matchers: Matchers
): boolean => {
  if (index >= array.length - 1) return false;
  const rule = matchers[ruleName];
  if (!rule) throw Error(`Could not find rule with name ${ruleName}`);
  return rule(array[index + 1], index + 1, array, matchers);
};

export const matchPositionAfter = (
  ruleName: string,
  node: Slate.Node,
  index: number,
  array: Slate.Node[],
  matchers: Matchers
): boolean => {
  if (index === 0) return false;
  const rule = matchers[ruleName];
  if (!rule) throw Error(`Could not find rule with name ${ruleName}`);
  return rule(array[index - 1], index - 1, array, matchers);
};

export class ChildSelector {
  private readonly allMatchers: Matcher[];
  private selectedIndexes: Set<number>;

  constructor(private readonly nodes: Slate.Node[], readonly matchers: Matchers) {
    this.allMatchers = Object.keys(this.matchers).map((key) => this.matchers[key]);
    this.selectedIndexes = new Set();
  }

  select = (ruleName: string): Slate.Node[] => {
    const matcher = this.getMatcher(ruleName);
    const nodes = this.nodes.filter((node, index, array) => {
      const matched = matcher(node, index, array, this.matchers);
      if (matched) this.selectedIndexes.add(index);
      return matched;
    });

    return nodes;
  };

  selectFirst = (ruleName: string): Slate.Node | undefined => {
    const matcher = this.getMatcher(ruleName);
    const node = this.nodes.find((node, index, array) => {
      const matched = matcher(node, index, array, this.matchers);
      if (matched) this.selectedIndexes.add(index);
      return matched;
    });

    return node;
  };

  rest = (): Slate.Node[] => {
    const nodes = this.nodes.filter((node, index, array) => !this.selectedIndexes.has(index));

    return nodes;
  };

  private getMatcher = (ruleName: string) => {
    const matcher = this.matchers[ruleName];
    if (!matcher) throw Error(`Could not find matcher for rule with name ${ruleName}`);
    return matcher;
  };
}
