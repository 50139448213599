import React from 'react';
import {
  SimpleForm,
  DateInput,
  SelectInput,
  required,
  TextInput,
  Edit,
  NumberInput,
  ShowProps,
  useEditContext,
} from 'react-admin';

import {
  ACT_COUNTRIES,
  ACT_SUBTYPES,
  DOCUMENT_TYPES,
  LegalWork,
  SOURCE_SYSTEMS,
} from '../../../state/legalDocuments/types';
import { getDescriptiveWorkTitle } from '../../../util/legalIdentifierUtils';

export const LegalWorkEdit: React.FC<ShowProps> = (props: ShowProps) => {
  return (
    <Edit {...props} mutationMode="pessimistic">
      <LegalWorkEditForm {...props} />
    </Edit>
  );
};

const LegalWorkEditForm: React.FC<ShowProps> = (props: any) => {
  const { record, loading } = useEditContext();
  if (loading) return null;
  const work: LegalWork = (record!! as unknown) as LegalWork;

  return (
    <SimpleForm variant="standard" margin="normal" {...props}>
      <h2>{getDescriptiveWorkTitle(work)}</h2>
      <h3>ID-metadata</h3>
      <SelectInput
        label="resources.legalDocuments.fields.work.documentType"
        source="documentType"
        choices={DOCUMENT_TYPES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.countryCode"
        source="countryCode"
        choices={ACT_COUNTRIES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.subtype"
        source="subtype"
        choices={ACT_SUBTYPES}
        validate={required()}
      />
      <DateInput
        label="resources.legalDocuments.fields.work.date"
        source="date"
        validate={required()}
      />
      <NumberInput
        min="0"
        step="1"
        label="resources.legalDocuments.fields.work.seq"
        source="seq"
        validate={required()}
      />
      <h3>Andre felter</h3>
      <TextInput
        min="0"
        step="1"
        label="resources.legalDocuments.fields.work.listingTitle"
        source="listingTitle"
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.activeSourceSystem"
        source="activeSourceSystem"
        choices={SOURCE_SYSTEMS}
        validate={required()}
      />
      <DateInput
        label="resources.legalDocuments.fields.work.noLongerInForceDate"
        source="noLongerInForceDate"
      />
    </SimpleForm>
  );
};
