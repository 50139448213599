import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Button, withStyles, CircularProgress } from '@material-ui/core';
import { Link } from '@material-ui/icons';

import ActExpressionToc from '../../components/commentariesLinking/ActExpressionToc/ActExpressionToc';
import * as commentaryLinkingActions from '../../state/commentaryLinking/commentaryLinkingActions';
import * as expressionActions from '../../state/expressions/expressionActions';
import * as commentaryActions from '../../state/commentary/commentaryActions';

// Types
import { CustomState } from '../../state/customState';
import { LegalExpressionIdentifier, VersionStatus } from '../../state/legalDocuments/types';
import { convertActExprIdToKey, expressionIdentifierToUri } from '../../util/legalIdentifierUtils';
import { CommentaryLinkingStatus } from '../../state/commentaryLinking/types';
import { Toc, CommentaryMetadataForAllExpressionFragments } from '../../state/expressions/types';
import InjectorResultsDialog from '../../components/commentariesLinking/InjectorResultsDialog';
import { LinkInjectorResults } from '../../models/LinkInjectorModels';

interface FragmentSelectProps {
  exprId: LegalExpressionIdentifier;
  actStatus: VersionStatus;
  toc?: Toc;
  classes?: any;
  currentLoggedUser?: string;
  linkStatuses?: { fragments: CommentaryLinkingStatus };
  commentaryMeta?: CommentaryMetadataForAllExpressionFragments;
  allInjectorResults?: {
    [exprIdKeyFragment: string]: {
      results: LinkInjectorResults;
      error: string | null;
      loading: boolean;
    };
  };
}

type selectedIdsState = {
  eId: string;
  injectCommentBody: boolean;
  injectCuratedSources: boolean;
}[];

const styles = () => ({
  injectButton: {
    // Throws error if not casted, see: https://github.com/Microsoft/TypeScript/issues/11465
    position: 'fixed' as 'fixed',
    right: 10,
    bottom: 5,
  },
});

const FragmentSelect: React.FC<FragmentSelectProps & DispatchProp> = (props) => {
  const [selectedIds, setSelectedIds] = React.useState<selectedIdsState>([]);
  const [showingInjectResultForFragment, setShowingInjectResultForFragment] = React.useState<
    string | null
  >(null);
  const exprIdKey = convertActExprIdToKey(props.exprId);

  React.useEffect(() => {
    props.dispatch(commentaryActions.getCommentaryMeta(expressionIdentifierToUri(props.exprId)));
    props.dispatch(
      expressionActions.generateExpressionToc({
        uri: expressionIdentifierToUri(props.exprId),
        publicationStatus: props.actStatus,
      })
    );

    const intervalId = setInterval(updateStatuses, 2000);
    return () => {
      clearInterval(intervalId);
    };
    // TODO: Fix exhaustive deps lint error. Bug in link injector at the time of writing this, so hard to verify correct behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStatuses = () => {
    props.dispatch(commentaryLinkingActions.getCommentInjectStatuses(props.exprId));
  };

  if (!props.currentLoggedUser || !props.toc || !props.linkStatuses || !props.commentaryMeta)
    return <CircularProgress />;

  const injectLinks = () => {
    setSelectedIds([]);
    selectedIds
      .filter((id) => id.injectCommentBody || id.injectCuratedSources)
      .forEach((id) => {
        props.dispatch(
          commentaryLinkingActions.injectComment(
            props.exprId,
            id.eId,
            id.injectCommentBody,
            id.injectCuratedSources,
            // @ts-ignore because it does not understand that we have check for undefined above
            props.currentLoggedUser
          )
        );
      });
  };

  const onShowInjectResults = (fragmentId: string) => {
    props.dispatch(commentaryLinkingActions.getCommentInjectResult(props.exprId, fragmentId));
    setShowingInjectResultForFragment(fragmentId);
  };

  const onCloseShowInjectResults = () => setShowingInjectResultForFragment(null);

  const onSelectFragmentCheckbox = (fragmentId: string, isCuratedSources: boolean) => {
    const previousSelectedId = selectedIds.find((id) => id.eId === fragmentId);
    const field = isCuratedSources ? 'injectCuratedSources' : 'injectCommentBody';

    if (previousSelectedId) {
      const updatedSelectedId = {
        ...previousSelectedId,
        ...{ [field]: !previousSelectedId[field] },
      };
      setSelectedIds(selectedIds.map((id) => (id.eId === fragmentId ? updatedSelectedId : id)));
    } else {
      setSelectedIds([
        ...selectedIds,
        {
          eId: fragmentId,
          injectCommentBody: !isCuratedSources,
          injectCuratedSources: isCuratedSources,
        },
      ]);
    }
  };

  const renderInjectResultsDialog = () => {
    const exprFragmentIdKey = exprIdKey + '@' + showingInjectResultForFragment;
    if (
      !showingInjectResultForFragment ||
      !props.allInjectorResults ||
      !props.allInjectorResults[exprFragmentIdKey]
    )
      return null;
    return (
      <InjectorResultsDialog
        title={exprFragmentIdKey}
        injectorResults={
          props.allInjectorResults ? props.allInjectorResults[exprFragmentIdKey].results : null
        }
        onClose={onCloseShowInjectResults}
      />
    );
  };

  return (
    <React.Fragment>
      <ActExpressionToc
        toc={props.toc}
        selectedFragmentIds={selectedIds}
        fragmentsLinkingStatuses={props.linkStatuses.fragments}
        commentaryMeta={props.commentaryMeta || {}}
        title={exprIdKey}
        onShowInjectResults={onShowInjectResults}
        onSelectId={onSelectFragmentCheckbox}
      />

      <Button
        color="primary"
        variant="contained"
        className={props.classes.injectButton}
        onClick={() => injectLinks()}
        disabled={!selectedIds.find((id) => id.injectCommentBody || id.injectCuratedSources)}
      >
        <Link />
        Start lenking
      </Button>

      {renderInjectResultsDialog()}
    </React.Fragment>
  );
};

const mapState = (state: CustomState, ownProps: FragmentSelectProps) => {
  const exprIdKey = convertActExprIdToKey(ownProps.exprId);
  const exprUri = expressionIdentifierToUri(ownProps.exprId);
  return {
    linkStatuses: state.commentariesLinking.statuses[exprIdKey],
    toc: state.expressions.tocs[exprUri],
    currentLoggedUser: state.currentUser ? state.currentUser.name : undefined,
    commentaryMeta: state.expressions.commentaryMeta[exprUri],
    allInjectorResults: state.commentariesLinking.injectResults,
  };
};

export default connect(mapState)(
  withStyles(styles)(
    React.memo(FragmentSelect, (prevProps, nextProps) => {
      // shouldComponentUpdate
      return JSON.stringify(prevProps) === JSON.stringify(nextProps);
    })
  )
);
