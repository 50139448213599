import React from 'react';
import { Admin, DataProvider, Resource, TranslationMessages } from 'react-admin';
import { Route } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import WebIcon from '@material-ui/icons/Web';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PersonIcon from '@material-ui/icons/Person';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import LabelIcon from '@material-ui/icons/Label';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import GavelIcon from '@material-ui/icons/Gavel';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TransformIcon from '@material-ui/icons/Transform';
import SchoolIcon from '@material-ui/icons/School';
import { NoticeSnapshotList } from './components/noticeSnapshots/NoticeSnapshotList';
import {
  NoticeSnapshotCreate,
  NoticeSnapshotEdit,
} from './components/noticeSnapshots/NoticeSnapshotForm';
import { PageSnapshotList } from './components/pageSnapshots/PageSnapshotList';
import { PageSnapshotCreate, PageSnapshotEdit } from './components/pageSnapshots/PageSnapshotForm';
import { ContractSnapshotList } from './components/contractSnapshots/ContractSnapshotList';
import {
  ContractSnapshotCreate,
  ContractSnapshotEdit,
} from './components/contractSnapshots/ContractSnapshotForm';
import { EmbeddedSnapshotList } from './components/embeddedSnapshots/EmbeddedSnapshotList';
import {
  EmbeddedSnapshotCreate,
  EmbeddedSnapshotEdit,
} from './components/embeddedSnapshots/EmbeddedSnapshotForm';
import ContributorList from './components/contributors/ContributorList';
import { ContributorShow } from './components/contributors/ContributorDetails';
import { ContributorCreate, ContributorEdit } from './components/contributors/ContributorForm';
import TextbookPublicationList from './components/textbookPublications/TextbookPublicationList';
import TextbookPublicationShow from './components/textbookPublications/TextbookPublicationShow';
import {
  TextbookPublicationEdit,
  TextbookPublicationCreate,
} from './components/textbookPublications/TextbookPublicationForm';
import TextbookEditionList from './components/textbookEditions/TextbookEditionList';
import TextbookEditionShow from './components/textbookEditions/TextbookEditionShow';
import {
  TextbookEditionEdit,
  TextbookEditionCreate,
} from './components/textbookEditions/TextbookEditionForm';
import TextbookModuleShow from './components/textbookModules/TextbookModuleShow';
import TextbookModuleEdit from './components/textbookModules/TextbookModuleEdit';
import LegalDocumentList from './components/legalDocuments/LegalDocumentList';
import LegalDocumentEdit from './components/legalDocuments/LegalDocumentEdit';
import LegalDocumentShow from './components/legalDocuments/LegalDocumentShow';
import LegalDocumentCreate from './components/legalDocuments/LegalDocumentCreate';
import LegalDocumentGenerateAsCopy from './components/legalDocuments/LegalDocumentGenerateAsCopy';
import CommentariesEdit from './components/commentaries/CommentariesEdit';
import CommentariesFileUploader from './components/commentaries/FileUploader';
import CommentLinkingPage from './components/commentariesLinking/CommentLinkingPage';
import JournalArticleList from './components/journalArticles/JournalArticleList';
import { JournalPublicationsList } from './components/journalPublications/JournalPublicationList';
import { JournalPublicationShow } from './components/journalPublications/JournalPublicationShow';
import { JournalPublicationEdit } from './components/journalPublications/JournalPublicationForm';
import PublisherList from './components/publishers/PublisherList';
import { PublisherCreate, PublisherEdit } from './components/publishers/PublisherForm';
import TagList from './components/tags/TagList';
import LiteratureConversionProcessList from './components/literatureConversionProcesses/LiteratureConversionProcessList';
import LiteratureConversionProcessShow from './components/literatureConversionProcesses/LiteratureConversionProcessShow';
import {
  LiteratureConversionProcessCreate,
  LiteratureConversionProcessEdit,
} from './components/literatureConversionProcesses/LiteratureConversionProcessForm';
import LiteratureConversionBitsFormatShow from './components/literatureConversionBitsFormats/LiteratureConversionBitsFormatShow';
import CustomerList from './components/customers/CustomersList';
import CustomerEdit from './components/customers/CustomerEdit';
import { CustomerCreate } from './components/customers/CustomerCreate';
import StripeSubscriptionPriceCreate from './components/stripeSubscriptionPrice/StripeSubscriptionPriceCreate';
import UserList from './components/users/UserList';
import SessionInitializer from './containers/SessionInitializer';
import Auth0LoginPage from './components/Auth0LoginPage';
import CmsLayout from './components/CmsLayout';
import { JuridikaCmsContext } from './util/juridikaCmsContext';
import norwegianMessages from './i18nNorwegianMessages';
import theme from './theme';
import auth from './auth';
import UserEdit from './components/users/UserEdit';
import UserMigrationCreate from './components/userMigrations/UserMigrationCreate';
import { LegalChangeList } from './components/legalDocuments/legalChanges/LegalChangeList';
import LegalWorkList from './components/legalDocuments/legalWorks/LegalWorkList';
import LegalWorkCreate from './components/legalDocuments/legalWorks/LegalWorkCreate';
import { Motorcycle } from '@material-ui/icons';
import { LegalWorkShow } from './components/legalDocuments/legalWorks/LegalWorkShow';
import { LegalWorkEdit } from './components/legalDocuments/legalWorks/LegalWorkEdit';
import NIKDocumentList from './components/NIKDocuments/NIKDocumentList';
import NIKDocumentEdit from './components/NIKDocuments/NIKDocumentEdit';
import NIKDocumentCreate from './components/NIKDocuments/NIKDocumentCreate';
import NIKPublicationList from './components/NIKDocuments/NIKPublicationList';
import NIKPublicationShow from './components/NIKDocuments/NIKPublicationShow';
import {
  NIKPublicationCreate,
  NIKPublicationEdit,
} from './components/NIKDocuments/NIKPublicationForms';
import NIKEditionList from './components/NIKDocuments/NIKEditionList';
import NIKEditionShow from './components/NIKDocuments/NIKEditionShow';
import NIKEditionCreate from './components/NIKDocuments/NIKEditionCreate';
import { NikContributorDetailsCreate } from './components/NIKDocuments/NIKContributorDetailsForm';
import { NikContributorDetailsEdit } from './components/NIKDocuments/NIKContributorDetailsForm';
import NIKContributorDetailsList from './components/NIKDocuments/NIKContributorDetailsList';
import NIKEditionEdit from './components/NIKDocuments/NIKEditionEdit';

const history = createHistory();

const customRoutes = [
  <Route exact path="/auth/callback" component={SessionInitializer} />,
  <Route
    exact
    path="/legalDocuments/akn/:countryCode/:documentType/:subtype/:date/:seq/:languageCode@:inForceDate"
    component={LegalDocumentEdit}
  />,
  <Route
    exact
    path="/legalDocuments/:type/:identifier/:inForceAt/injector"
    component={CommentLinkingPage}
  />,
  <Route
    exact
    path="/legalDocuments/akn/:countryCode/:documentType/:subtype/:date/:seq/:languageCode@:inForceDate/changes"
    component={LegalChangeList}
  />,
  <Route exact path="/legalWorks" component={LegalWorkList} />,
  <Route
    exact
    path="/legalDocuments/akn/:countryCode/:documentType/:subtype/:date/:seq/:languageCode@:inForceDate/kommentarer"
    component={CommentariesEdit}
  />,
  <Route
    exact
    path="/legalDocuments/akn/:countryCode/:documentType/:type/:date/:seq/:inForceAt/create"
    component={LegalDocumentGenerateAsCopy}
  />,
  <Route exact noLayout path="/lookup/image/upload" component={CommentariesFileUploader} />,
];

const messages = {
  nb: norwegianMessages,
};

// eslint-disable-next-line no-sequences
const i18nProvider = polyglotI18nProvider(
  (locale) => (messages['nb'] as unknown) as TranslationMessages
);

const App = () => {
  const { dataProvider, customReducers, customSagas, backendUrls } = React.useContext(
    JuridikaCmsContext
  );

  const themeMainColor =
    backendUrls.juridikaFrontend === 'https://juridika.no/' ? '#004c36' : '#2b0a6d';

  return (
    <Admin
      title="Juridika CMS"
      i18nProvider={i18nProvider}
      history={history}
      theme={theme(themeMainColor)}
      layout={CmsLayout}
      loginPage={Auth0LoginPage}
      authProvider={auth.reactAdminAuthProvider.bind(auth)}
      dataProvider={(dataProvider as unknown) as DataProvider}
      customRoutes={customRoutes}
      customReducers={customReducers}
      customSagas={customSagas}
    >
      <Resource
        name="noticeSnapshots"
        icon={WebIcon}
        list={NoticeSnapshotList}
        edit={NoticeSnapshotEdit}
        create={NoticeSnapshotCreate}
      />
      <Resource name="feeds" />
      <Resource
        name="pageSnapshots"
        icon={WebAssetIcon}
        list={PageSnapshotList}
        create={PageSnapshotCreate}
        edit={PageSnapshotEdit}
      />
      <Resource
        name="contractSnapshots"
        icon={AssignmentIcon}
        list={ContractSnapshotList}
        create={ContractSnapshotCreate}
        edit={ContractSnapshotEdit}
      />
      <Resource
        name="embeddedSnapshots"
        icon={RateReviewIcon}
        list={EmbeddedSnapshotList}
        create={EmbeddedSnapshotCreate}
        edit={EmbeddedSnapshotEdit}
      />
      <Resource
        name="contributors"
        icon={PersonIcon}
        list={ContributorList}
        show={ContributorShow}
        create={ContributorCreate}
        edit={ContributorEdit}
      />
      <Resource name="revisions" />
      <Resource name="contentCategories" />
      <Resource name="literatureDocumentTags" />
      <Resource name="legalWorkTags" />
      <Resource name="staticDocumentTags" />
      <Resource name="editorAccounts" />
      <Resource
        name="legalDocuments"
        icon={GavelIcon}
        list={LegalDocumentList}
        show={LegalDocumentShow}
        create={LegalDocumentCreate}
      />
      <Resource
        name="legalWorks"
        icon={GavelIcon}
        show={LegalWorkShow}
        create={LegalWorkCreate}
        edit={LegalWorkEdit}
        list={LegalWorkList}
      />
      <Resource name="legalChanges" />
      <Resource name="legalChangesSuggest" />
      <Resource name="legalRelations" />
      <Resource name="legalToc" />
      <Resource name="tags" icon={LabelIcon} list={TagList} />
      <Resource
        name="textbookEditions"
        icon={MenuBookIcon}
        show={TextbookEditionShow}
        list={TextbookEditionList}
        edit={TextbookEditionEdit}
        create={TextbookEditionCreate}
      />
      <Resource
        name="textbookPublications"
        icon={MenuBookIcon}
        list={TextbookPublicationList}
        show={TextbookPublicationShow}
        edit={TextbookPublicationEdit}
        create={TextbookPublicationCreate}
      />
      <Resource
        name="literatureConversionProcesses"
        icon={TransformIcon}
        list={LiteratureConversionProcessList}
        show={LiteratureConversionProcessShow}
        create={LiteratureConversionProcessCreate}
        edit={LiteratureConversionProcessEdit}
      />
      <Resource name="textbookModules" show={TextbookModuleShow} edit={TextbookModuleEdit} />
      <Resource name="journalArticles" icon={SchoolIcon} list={JournalArticleList} />
      <Resource
        name="journalPublications"
        icon={SchoolIcon}
        list={JournalPublicationsList}
        show={JournalPublicationShow}
        edit={JournalPublicationEdit}
      />
      <Resource name="journalEditions" />
      <Resource
        name="publishers"
        icon={BusinessIcon}
        list={PublisherList}
        create={PublisherCreate}
        edit={PublisherEdit}
      />
      <Resource name="literatureConversionAttachments" />
      <Resource name="literatureConversionBitsFormats" show={LiteratureConversionBitsFormatShow} />
      <Resource
        name="customers"
        list={CustomerList}
        edit={CustomerEdit}
        create={CustomerCreate}
        icon={WorkIcon}
      />
      <Resource name="users" list={UserList} edit={UserEdit} icon={PeopleOutlineIcon} />
      <Resource name="userMigrations" create={UserMigrationCreate} />
      <Resource name="products" />
      <Resource name="contact" />
      <Resource name="subscriptionStatus" />
      <Resource name="accountManagers" />
      <Resource name="subscriptionPackage" />
      <Resource name="marketCategory" />
      <Resource name="stripeSubscriptionPrices" create={StripeSubscriptionPriceCreate} />
      <Resource
        name="nikPublications"
        icon={Motorcycle}
        list={NIKPublicationList}
        show={NIKPublicationShow}
        create={NIKPublicationCreate}
        edit={NIKPublicationEdit}
      />
      <Resource
        name="nikEditions"
        icon={Motorcycle}
        list={NIKEditionList}
        show={NIKEditionShow}
        create={NIKEditionCreate}
        edit={NIKEditionEdit}
      />
      <Resource
        name="nikDocuments"
        icon={Motorcycle}
        list={NIKDocumentList}
        create={NIKDocumentCreate}
        edit={NIKDocumentEdit}
      />
      <Resource
        name="nikContributorDetails"
        create={NikContributorDetailsCreate}
        edit={NikContributorDetailsEdit}
        icon={Motorcycle}
        list={NIKContributorDetailsList}
      />
    </Admin>
  );
};

export default App;
