import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { Modal as MUIModal } from '@material-ui/core';
import { juridikaHeaders } from '../../../dataProvider/apiUtil';
import { Identifier, Record } from 'react-admin';
import styles from './PublishDocumentButtons.module.css';
import { contentAPIBaseUrl } from '../../../dataProvider/apiUrls';

const unpublishDocument = (id: Identifier) => {
  const baseurl = contentAPIBaseUrl();
  const apiUrl = `${baseurl}/admin/v1/document/${id}/unpublish`;
  fetchData(apiUrl)
    .then((data) => {
      console.log('Data:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

const publishDocument = (id: Identifier) => {
  const baseurl = contentAPIBaseUrl();
  const apiUrl = `${baseurl}/admin/v1/document/${id}/publish`;
  fetchData(apiUrl)
    .then((data) => {
      console.log('Data:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

async function fetchData(url: string): Promise<any> {
  try {
    const response = await fetch(url, { method: 'PUT', headers: juridikaHeaders() });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // Reload the page if fetch is successful
    window.location.reload();
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const PublishButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      className={styles.publishButton}
      startIcon={<Icon>publish</Icon>}
      variant="contained"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      PUBLISER
    </Button>
  );
};

const UnpublishButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button
      className={styles.unpublishButton}
      startIcon={<Icon>unpublished</Icon>}
      variant="contained"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      AVPUBLISER
    </Button>
  );
};

const CancelButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button variant="outlined" onClick={onClick} className={styles.cancelButton}>
      Avbryt
    </Button>
  );
};

const PublishDocumentButtons: FC<{ record: Record | undefined }> = ({ record }) => {
  const [openModal, setOpenModal] = useState(false);

  if (record) {
    if (record.status === 'DRAFT') {
      return (
        <>
          <PublishButton onClick={() => setOpenModal(true)} />
          <MUIModal open={openModal} onClose={() => setOpenModal(false)} className={styles.modal}>
            <div className={styles.modalContent}>
              <h1>Er du sikker på at du ønsker å publisere dette dokumentet?</h1>
              <p>
                Når du publiserer dokumentet vil det bli synlig for brukere av Juridika. Den vil bli
                tilgjengeliggjort under <em>rettsområder</em>.
              </p>
              <p>
                <mark>Merk</mark> at dokumentet ikke vil bli indeksert for søk før midnatt.
              </p>
              <div className={styles.modalButtonWrapper}>
                <CancelButton onClick={() => setOpenModal(false)} />
                <PublishButton onClick={() => publishDocument(record.id)} />
              </div>
            </div>
          </MUIModal>
        </>
      );
    } else if (record.status === 'PUBLISHED') {
      return (
        <>
          <UnpublishButton onClick={() => setOpenModal(true)} />
          <MUIModal open={openModal} onClose={() => setOpenModal(false)} className={styles.modal}>
            <div className={styles.modalContent}>
              <h1>Er du sikker på at du ønsker å avpublisere dette dokumentet?</h1>
              <p>
                Når du avpubliserer dokumentet vil det ikke lenger være synlig for brukere av
                Juridika. Om det finnes et tidligere publisert dokument under samme problemstilling
                så vil den nå bli synlig, hvis ikke så vil hele problemstilligen bli borte fra
                Juridika.
              </p>
              <div className={styles.modalButtonWrapper}>
                <CancelButton onClick={() => setOpenModal(false)} />
                <UnpublishButton onClick={() => unpublishDocument(record.id)} />
              </div>
            </div>
          </MUIModal>
        </>
      );
    } else {
      return <p>Ukjent status</p>;
    }
  } else {
    return <p>Ukjent status</p>;
  }
};

export default PublishDocumentButtons;
