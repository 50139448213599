import * as React from 'react';
import * as Slate from 'slate';

import MenuItem from '@material-ui/core/MenuItem';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';

import SlateSimpleMenu from '../../components/SlateInput/SlateSimpleMenu';
import { Plugin } from './types';
import { getAboveByType } from '../utils/query';

interface BlockFloatConfig {
  leftClassName: string;
  rightClassName: string;
}

export interface FloatConfig {
  [slateType: string]: BlockFloatConfig;
}

enum FloatPosition {
  NONE,
  LEFT,
  RIGHT,
}

const key = 'block-float-plugin';

const FloatIcon = (position: FloatPosition): React.ComponentType<{}> => {
  switch (position) {
    case FloatPosition.NONE:
      return FormatAlignJustifyIcon;
    case FloatPosition.LEFT:
      return FormatAlignLeftIcon;
    case FloatPosition.RIGHT:
      return FormatAlignRightIcon;
  }
};

const FloatMenuItem = (
  editor: Slate.Editor,
  config: FloatConfig,
  floatPosition: FloatPosition,
  activeFloatPosition: FloatPosition,
  key: string
) =>
  React.createElement(
    MenuItem,
    {
      key,
      selected: floatPosition === activeFloatPosition,
      onMouseDown: () => {
        setFloat(editor, floatPosition, config);
      },
    },
    React.createElement(FloatIcon(floatPosition))
  );

const floatPositionOf = (element: Slate.Element, config: FloatConfig): FloatPosition => {
  const blockConfig = config[element.type as string];
  if (!blockConfig) return FloatPosition.NONE;

  const classNames = element.className as string[] | undefined;
  if (!classNames) return FloatPosition.NONE;

  if (classNames.includes(blockConfig.leftClassName)) return FloatPosition.LEFT;
  if (classNames.includes(blockConfig.rightClassName)) return FloatPosition.RIGHT;

  return FloatPosition.NONE;
};

const floatClassNameOf = (
  blockConfig: BlockFloatConfig,
  floatPosition: FloatPosition
): string | null => {
  switch (floatPosition) {
    case FloatPosition.NONE:
      return null;
    case FloatPosition.LEFT:
      return blockConfig.leftClassName;
    case FloatPosition.RIGHT:
      return blockConfig.rightClassName;
  }
};

const setFloatClassName = (
  classNames: string[] | undefined,
  blockConfig: BlockFloatConfig,
  floatPosition: FloatPosition
): string[] => {
  const floatClassName = floatClassNameOf(blockConfig, floatPosition);

  const filtered = classNames
    ? classNames.filter(
        (className) =>
          className !== blockConfig.leftClassName && className !== blockConfig.rightClassName
      )
    : [];

  if (floatClassName) {
    return filtered.concat([floatClassName]);
  }

  return filtered;
};

const setFloat = (editor: Slate.Editor, floatPosition: FloatPosition, config: FloatConfig) => {
  const floatableBlockEntry = getAboveByType(editor, Object.keys(config));
  if (!floatableBlockEntry) return;

  const [floatBlockNode, floatBlockPath] = floatableBlockEntry;
  const blockConfig = config[floatBlockNode.type as string];
  if (!blockConfig) return;

  const existingClassName = floatBlockNode.className as string[];
  Slate.Transforms.setNodes(
    editor,
    {
      className: setFloatClassName(existingClassName, blockConfig, floatPosition),
    },
    {
      at: floatBlockPath,
    }
  );
};

const BlockFloatPlugin: React.FC<{ editor: Slate.Editor; config: FloatConfig }> = (props) => {
  const floatableBlockEntry = getAboveByType(props.editor, Object.keys(props.config));
  const isActive = !!floatableBlockEntry;
  const floatPosition = !floatableBlockEntry
    ? FloatPosition.NONE
    : floatPositionOf(floatableBlockEntry[0] as Slate.Element, props.config);

  return React.createElement(SlateSimpleMenu, {
    key,
    isActive,
    Icon: FloatIcon(floatPosition),
    message: 'slate.contextMenu.blockFloat',
    menuItems: isActive
      ? [
          FloatMenuItem(props.editor, props.config, FloatPosition.NONE, floatPosition, 'justified'),
          FloatMenuItem(props.editor, props.config, FloatPosition.LEFT, floatPosition, 'left'),
          FloatMenuItem(props.editor, props.config, FloatPosition.RIGHT, floatPosition, 'right'),
        ]
      : [],
  });
};

export default (config: FloatConfig): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <BlockFloatPlugin key={key} editor={editor} config={config} />;
      },
    },
  };
};
