/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import uuidv4 from 'uuid/v4';
import { connect } from 'react-redux';

import Spinner from '../../components/Spinner';
import FileDropzone from '../FileDropzone';

import { uploadStaticImage } from '../../state/staticFiles/staticFilesActions';
import { StaticFileState, StaticFilesState } from '../../state/staticFiles/staticFilesReducer';

import { EditorWindow } from '../../xopus/types';

interface ReduxState {
  staticFiles: StaticFilesState;
}

interface StateProps {
  classes: { spinnerContainer: string };
  imageState: StaticFileState;
}

interface DispatchProps {
  upload: typeof uploadStaticImage.request;
}

interface OwnProps {
  uuid: string;
}

type Props = StateProps & DispatchProps & OwnProps;

const styles = (theme: any) => ({
  spinnerContainer: {
    height: 'calc(100vh - 32px)',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  },
});

const Uploader = ({ imageState: { status, error, url }, upload, classes, uuid }: Props) => {
  const [errorState, setErrorState] = useState<string>();

  useEffect(() => {
    if (error) {
      setErrorState(JSON.stringify(error, null, 2));
    } else if (status === 'done' && url) {
      (top as EditorWindow).choose({ File: url });
    } else if (status === 'done' && !url) {
      setErrorState('fileUpload.notification.upload_failure');
    }
  });

  const handleSave = (formData: any) => {
    if (Object.keys(formData).length > 0) {
      upload({ uuid, file: formData.files.rawFile });
    }
  };

  return (
    <React.Fragment>
      {status === 'uploading' ? (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        <FileDropzone
          accept="image/*"
          save={handleSave}
          errorState={errorState}
          setErrorState={setErrorState}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  return {
    imageState: state.staticFiles[ownProps.uuid] || {},
    uuid: ownProps.uuid,
  };
};

const mapDispatchToProps: DispatchProps = {
  upload: uploadStaticImage.request,
};

const ConnectedUploaderWithStyles = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Uploader)
);

const FileUploader = () => <ConnectedUploaderWithStyles uuid={uuidv4()} />;

export default FileUploader;
