import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';
import {
  idmlFormatUrl,
  bitsFormatUploadUrl,
  bitsBundleUrl,
  pdfUrl,
  pdfOutlineUrl,
} from './literatureConversionUtils';

enum What {
  Idml,
  Bits,
  BitsBundle,
  Pdf,
  PdfOutline,
}

const UploadFileMenu: React.FC<{ processId: string }> = ({ processId }) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const inputFile = React.useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);
  const [what, setWhat] = React.useState<What | null>(null);

  const openFile = () => {
    setMenuAnchorEl(null);
    inputFile.current?.click();
  };

  const handleUpload = async (fileList: FileList) => {
    const doUpload = async (method: string, url: string, formData: FormData) => {
      try {
        const response = await fetch(url, {
          method,
          headers: juridikaHeaders(),
          body: formData,
        });
        if (!response.ok) {
          throw await response.text();
        }
        await response.text();
      } catch (error) {
        notify(`Problem uploading: ${error}`, 'error');
      }
    };

    const file = fileList.item(0);
    if (file != null) {
      dispatch(fetchStart());

      switch (what) {
        case What.Idml: {
          const formData = new FormData();
          formData.append('file', file);

          await doUpload('PUT', idmlFormatUrl(backendUrls, processId), formData);
          break;
        }
        case What.Bits: {
          const formData = new FormData();
          formData.append('file', file);

          const description = prompt('Beskrivelse');

          if (!description) return;

          const url = bitsFormatUploadUrl(backendUrls, processId, description);

          await doUpload('POST', url, formData);
          break;
        }
        case What.BitsBundle: {
          const formData = new FormData();
          formData.append('file', file);

          await doUpload('POST', bitsBundleUrl(backendUrls, processId), formData);
          break;
        }
        case What.Pdf: {
          const formData = new FormData();
          formData.append('file', file);

          await doUpload('PUT', pdfUrl(backendUrls, processId), formData);
          break;
        }
        case What.PdfOutline: {
          const formData = new FormData();
          formData.append('file', file);

          await doUpload('PUT', pdfOutlineUrl(backendUrls, processId), formData);
          break;
        }
      }

      dispatch(fetchEnd());
      setMenuAnchorEl(null);
      refresh();
    }
  };

  return (
    <>
      <Button
        label="Last opp..."
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={(event) => {
          setMenuAnchorEl(event.currentTarget);
        }}
        size="small"
      >
        <>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={(e) => e.target.files && handleUpload(e.target.files)}
            style={{ display: 'none' }}
          />
          <CloudUploadIcon />
        </>
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setWhat(What.Idml);
            openFile();
          }}
        >
          Custom IDML...
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWhat(What.Bits);
            openFile();
          }}
        >
          BITS-XML...
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWhat(What.BitsBundle);
            openFile();
          }}
        >
          BITS bundle...
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWhat(What.Pdf);
            openFile();
          }}
        >
          PDF...
        </MenuItem>
        <MenuItem
          onClick={() => {
            setWhat(What.PdfOutline);
            openFile();
          }}
        >
          PDF outline (YAML)...
        </MenuItem>
      </Menu>
    </>
  );
};

export default UploadFileMenu;
