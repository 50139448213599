export const GENERATE_EXPRESSION_TOC = 'GENERATE_EXPRESSION_TOC';
export const GENERATE_EXPRESSION_TOC_SUCCESS = 'GENERATE_EXPRESSION_TOC_SUCCESS';
export const GENERATE_EXPRESSION_TOC_FAILURE = 'GENERATE_EXPRESSION_TOC_FAILURE';
export const GET_EXPRESSION_XML = 'GET_EXPRESSION_XML';
export const GET_EXPRESSION_XML_SUCCESS = 'GET_EXPRESSION_XML_SUCCESS';
export const GET_EXPRESSION_XML_FAILURE = 'GET_EXPRESSION_XML_FAILURE';
export const SAVE_EXPRESSION = 'SAVE_EXPRESSION';
export const SAVE_EXPRESSION_SUCCESS = 'SAVE_EXPRESSION_SUCCESS';
export const SAVE_EXPRESSION_FAILURE = 'SAVE_EXPRESSION_FAILURE';
export const PUBLISH_EXPRESSION = 'PUBLISH_EXPRESSION';
export const PUBLISH_EXPRESSION_SUCCESS = 'PUBLISH_EXPRESSION_SUCCESS';
export const PUBLISH_EXPRESSION_FAILURE = 'PUBLISH_EXPRESSION_FAILURE';
