import * as Slate from 'slate';
import { getAboveByType, isCollapsed } from './query';

export interface ResetBlockTypePluginRule {
  /**
   * Node types where the rule applies.
   */
  types: string[];

  hotkey?: string | string[];

  /**
   * Additional condition to the rule.
   */
  predicate: (editor: Slate.Editor) => boolean;

  /**
   * Set node to this default type when resetting.
   */
  defaultType?: string;

  /**
   * Callback called when resetting.
   */
  onReset?: (editor: Slate.Editor) => void;
}

export interface ResetBlockTypePluginOptions {
  rules: ResetBlockTypePluginRule[];
}

export const onKeyDownResetBlockType = ({ rules }: ResetBlockTypePluginOptions) => (
  event: KeyboardEvent | null,
  editor: Slate.Editor
) => {
  let reset: boolean | undefined;
  if (!editor.selection || !isCollapsed(editor.selection)) return;

  for (const { types, defaultType, hotkey, predicate, onReset } of rules) {
    if (!event || hotkey) {
      if (!predicate(editor) || !getAboveByType(editor, types)) {
        continue;
      }

      event?.preventDefault();
      Slate.Transforms.setNodes(editor, { type: defaultType });
      onReset?.(editor);
      reset = true;
    }
  }

  return reset;
};
