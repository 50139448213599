import React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import CmsAppBar from './CmsAppBar';
import CurrentUserInitializer from '../containers/CurrentUserInitializer/CurrentUserInitializer';
import { withStyles } from '@material-ui/core/styles';

// The app refuses to stay at the top of the window with our 'sticky' positioning,
// need to hack its CSS
const OverriddenLayout = withStyles({
  appFrame: {
    marginTop: 0,
  },
})(Layout);

const CmsLayout: React.FC<LayoutProps> = (props) => (
  <CurrentUserInitializer>
    <OverriddenLayout {...props} appBar={CmsAppBar as any} />
  </CurrentUserInitializer>
);

export default CmsLayout;
