import * as t from 'io-ts';

import {
  StaticDocumentRevision,
  StaticDocumentRevisionIncomingApiTypeV,
} from '../staticDocumentRevisionsProvider';

export interface BaseDocumentSnapshot {
  id: string;
  createdById: string;
  currentEditorId: string | null;
  currentEditorIsCurrentUser: boolean;
  revision: StaticDocumentRevision;
  firstPublishedAt: Date | null;
  lastPublishedAt: Date | null;
}

export const BaseDocumentSnapshotApiIncomingTypeV = t.type({
  id: t.string,
  createdById: t.string,
  currentEditorId: t.union([t.string, t.null]),
  currentEditorIsCurrentUser: t.boolean,
  revision: StaticDocumentRevisionIncomingApiTypeV,
  firstPublishedAt: t.union([t.string, t.null]),
  lastPublishedAt: t.union([t.string, t.null]),
});
