import * as t from 'io-ts';

import * as Types from './types';
import { parseNullableDate, halJsonCrudApiProvider } from './apiUtil';

import {
  BaseDocumentSnapshot,
  BaseDocumentSnapshotApiIncomingTypeV,
} from './models/staticContentCommonModels';

import {
  StaticDocumentRevisionOutgoingApiType,
  mapApiRevisionToFrontend,
  toApiMapper as staticDocumentRevisionToApiMapper,
} from './staticDocumentRevisionsProvider';
import { DefaultHast as AboutContractDefaultHast } from '../slate/documentSpecs/aboutContractSpec';

// url is set when already stored in backend,
// otherwise fileName, contentType and base64Content is set:
export interface ContractAttachment {
  key: string;
  fileName?: string;
  contentType?: string;
  base64Content?: string;
  url?: string;
}

export interface ContractSnapshot extends BaseDocumentSnapshot {
  slug: string | null;
  documentAttachments: ContractAttachment[] | null;
}

export const createEmptyContractSnapshot = (): ContractSnapshot => ({
  id: '',
  createdById: '',
  currentEditorId: '',
  currentEditorIsCurrentUser: true,
  firstPublishedAt: null,
  lastPublishedAt: null,
  slug: null,
  revision: {
    id: '',
    documentId: '',
    createdAt: new Date(),
    createdById: '',
    publishedAt: null,
    contentCategoryId: '',
    title: '',
    hast: AboutContractDefaultHast(),
    contributions: [],
    tags: [],
  },
  documentAttachments: null,
});

export enum ContractAttachmentKey {
  WORD = 'word',
  TEMPLATE_PREVIEW = 'preview',
  TEMPLATE_GUIDE = 'guide.word',
  TEMPLATE_GUIDE_PREVIEW = 'guide.preview',
}

export const getAttachment = (
  attachments: ContractAttachment[] | null,
  key: ContractAttachmentKey
): ContractAttachment | undefined => {
  return attachments?.find((file) => file.key === key);
};

const ApiIncomingTypeV = t.intersection([
  BaseDocumentSnapshotApiIncomingTypeV,
  t.type({
    slug: t.union([t.string, t.null]),
    documentAttachments: t.union([
      t.array(
        t.union([
          t.type({
            key: t.string,
            url: t.string,
          }),
          t.type({
            key: t.string,
            base64Content: t.string,
            fileName: t.string,
            contentType: t.string,
          }),
        ])
      ),
      t.null,
    ]),
  }),
]);

type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  revision: StaticDocumentRevisionOutgoingApiType;
  slug: string | null;
  documentAttachments: ContractAttachment[];
}

export const toApiMapper = (snapshot: ContractSnapshot): ApiOutgoingType => {
  if (getAttachment(snapshot.documentAttachments, ContractAttachmentKey.WORD) == null) {
    throw Error(`Missing attachment '${ContractAttachmentKey.WORD}'.`);
  }

  return {
    revision: staticDocumentRevisionToApiMapper(snapshot.revision),
    slug: snapshot.slug && snapshot.slug.replace(/\s/g, '').length > 0 ? snapshot.slug : null,
    documentAttachments: snapshot.documentAttachments!!,
  };
};

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<ContractSnapshot> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/contractSnapshots`,
    // Contracts works same as pages
    halListName: 'adminPageSnapshotList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiSnapshot: ApiIncomingType): ContractSnapshot => ({
      id: apiSnapshot.id,
      createdById: apiSnapshot.createdById,
      currentEditorId: apiSnapshot.currentEditorId,
      currentEditorIsCurrentUser: apiSnapshot.currentEditorIsCurrentUser,
      revision: mapApiRevisionToFrontend(apiSnapshot.revision),
      firstPublishedAt: parseNullableDate(apiSnapshot.firstPublishedAt),
      lastPublishedAt: parseNullableDate(apiSnapshot.lastPublishedAt),
      slug: apiSnapshot.slug,
      documentAttachments: apiSnapshot.documentAttachments,
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      revision: {
        contentCategoryId: (id) => ({ contentCategoryId: id }),
        tags: (tagPath) => ({ tag: tagPath }),
      },
    },
    sortFields: [],
  });
