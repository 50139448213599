import uuidv4 from 'uuid/v4';
import { ActionType, getType } from 'typesafe-actions';

import { call, put, takeEvery } from 'redux-saga/effects';
import URI from 'urijs';

import auth from '../../auth';
import * as ApiUrls from '../../dataProvider/apiUrls';

import * as actions from './excerptActions';

const { juridikaFrontend } = ApiUrls.backendUrls();

type GetExcerptAction = ActionType<typeof actions.getExcerpt>;

async function getExcerptFromExternalUrl(url: string): Promise<Blob> {
  let response;

  try {
    response = await fetch(url);
  } catch (error) {
    throw Error(`Failed to fetch excerpt from url: ${url}`);
  }

  if (!response.ok) {
    throw Error(
      `Unexpected response code ${response.status} when fetching excerpt from external url: ${url}`
    );
  }

  return await response.blob();
}

export async function getExcerptFromJuridikaFrontend(
  requestBody: actions.CommentExcerptBody,
  baseUrl: uri.URI = URI(juridikaFrontend)
): Promise<Response> {
  const url = baseUrl.segment('excerpt').segment('comment').toString();

  return await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${auth.getAccessToken()}`,
      'X-Juridika-Correlation-Id': uuidv4(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });
}

function* getExcerpt(action: GetExcerptAction) {
  const { payload, meta } = action;

  try {
    const response = yield call(getExcerptFromJuridikaFrontend, payload);

    if (response.ok) {
      let blob: Blob;

      // This header indicates that the excerpt is stored in S3
      if (response.headers.get('content-type')?.includes('text/uri-list')) {
        blob = yield getExcerptFromExternalUrl(yield response.text());
      } else {
        // Excerpt is provided directly (normally just preview excerpt, should not happen)
        blob = yield response.blob();
      }

      yield put(
        actions.getExcerptSuccess({
          blob,
          successCallback: meta.onSuccess.callback,
        })
      );
    } else {
      yield Promise.reject(`Unexpected response: ${response} while fetching excerpt: ${payload}`);
    }
  } catch (error) {
    console.error('Failed to create excerpt: ', error);
    yield put(actions.getExcerptFailure());
  }
}

export const createExcerptSaga = () => {
  return function* excerptSaga() {
    yield takeEvery(getType(actions.getExcerpt), function* (action: GetExcerptAction) {
      yield call<typeof getExcerpt>(getExcerpt, action);
    });
  };
};
