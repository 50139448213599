import React from 'react';
import { useForm } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishIcon from '@material-ui/icons/Publish';
import { FormDataConsumer, SelectInput, DateTimeInput, Button, SimpleForm } from 'react-admin';

import { TimeTemplate } from '../util/publishingUtils';

export interface FormType {
  timeTemplate: TimeTemplate;
  customDate: Date;
}

const PublishContentDialog: React.FC<{
  open: boolean;
  dialogLabel: string;
  submitLabel: string;
  extraContent?: React.ReactElement;
  onClose: React.ReactEventHandler<{}>;
  isSubmitting: boolean;
}> = ({ open, dialogLabel, submitLabel, extraContent, onClose, isSubmitting }) => {
  const form = useForm();
  return (
    <Dialog fullWidth open={open} onClose={onClose} aria-label="Publish">
      <DialogTitle>{dialogLabel}</DialogTitle>
      <DialogContent>
        <SelectInput
          source="timeTemplate"
          label="Tidspunkt, hurtigvalg"
          choices={[
            { id: 'now', name: 'Umiddelbart' },
            { id: 'tonight', name: 'I natt, før søkemotoren reindekserer' },
            { id: 'custom', name: 'Egendefinert' },
          ]}
          fullWidth
        />
        <FormDataConsumer>
          {({ formData, ...rest }: any) =>
            formData && (
              <DateTimeInput
                source="customDate"
                disabled={formData.timeTemplate !== 'custom'}
                fullWdith
                label="Tidspunkt"
              />
            )
          }
        </FormDataConsumer>
        {extraContent}
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={onClose}>
          <CancelIcon />
        </Button>
        <Button label={submitLabel} disabled={isSubmitting} onClick={() => form.submit()}>
          <PublishIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DialogWrapperProps<F extends FormType> {
  open: boolean;
  dialogLabel: string;
  submitLabel: string;
  extraContent?: React.ReactElement;
  onSubmit: (values: F) => void;
  onClose: () => void;
  initialValues: () => F;
  isSubmitting: boolean;
}

export const PublishContentDialogForm = <F extends FormType>({
  open,
  dialogLabel,
  submitLabel,
  extraContent,
  onSubmit,
  onClose,
  initialValues,
  isSubmitting,
}: DialogWrapperProps<F>): React.ReactElement<any, any> | null => {
  // SimpleForm incorrectly stores only the _first_ callback it receives
  // as a ref, so don't instantiate the SimpleForm until actually shown
  // (if not, it might use a closure with incorrect environment)
  if (!open) return null;

  return (
    <SimpleForm
      variant="standard"
      margin="normal"
      initialValues={initialValues()}
      save={(data: F) => onSubmit(data)}
      toolbar={<></>}
    >
      <PublishContentDialog
        open={open}
        dialogLabel={dialogLabel}
        submitLabel={submitLabel}
        extraContent={extraContent}
        onClose={onClose}
        isSubmitting={isSubmitting}
      />
    </SimpleForm>
  );
};
