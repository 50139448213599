import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, useQuery, Link as RaLink, LinearProgress, Record } from 'react-admin';
import RateReviewIcon from '@material-ui/icons/RateReview';
import Chip from '@material-ui/core/Chip';

import {
  EmbeddedSnapshot,
  EmbeddedForeignType,
} from '../../dataProvider/embeddedSnapshotsProvider';
import { createPrefilledEmbeddedOmtale } from '../../dataProvider/prefill';

/**
 * Field display used in _foreign records_, e.g. in an edition that wants to display a embedded snapshot field for review.
 */
export const EmbeddedSnapshotField: React.FC<{
  label?: string;
  addLabel?: boolean;
  record?: { id: string };
  foreignType: EmbeddedForeignType;
  createTitle: (record: Record) => string;
  className?: string;
}> = (props) => {
  const { data: snapshotData, loading: snapshotsLoading, error: snapshotsError } = useQuery({
    type: 'getList',
    resource: 'embeddedSnapshots',
    payload: {
      filter: {
        foreignId: props.record?.id,
        foreignType: props.foreignType,
      },
    },
  });
  const { data: categoryData, loading: categoriesLoading, error: categoriesError } = useQuery({
    type: 'getList',
    resource: 'contentCategories',
    payload: {},
  });

  if (snapshotsLoading || categoriesLoading) {
    return <LinearProgress className={props.className} />;
  }

  if (snapshotsError || categoriesError) {
    return <span className={props.className}>Feil ved henting av omtale</span>;
  }

  if (!props.record) {
    return null;
  } else if (snapshotData.length > 0) {
    const snapshot = snapshotData[0];
    return (
      <RaLink
        to={`/embeddedSnapshots/${snapshot.id}/show`}
        onClick={(e) => e.stopPropagation()}
        className={props.className}
      >
        <Chip style={{ cursor: 'inherit' }} label={snapshot.revision.title} />
      </RaLink>
    );
  } else {
    let record: EmbeddedSnapshot;
    try {
      record = createPrefilledEmbeddedOmtale(
        props.record?.id ?? '',
        props.foreignType,
        props.createTitle(props.record),
        categoryData
      );
    } catch (error) {
      return <>Kan ikke lage omtale, kategori mangler</>;
    }

    return (
      <Button
        className={props.className}
        label="Opprett omtale"
        component={Link}
        color="primary"
        to={{
          pathname: '/embeddedSnapshots/create',
          state: {
            record,
          },
        }}
      >
        <RateReviewIcon />
      </Button>
    );
  }
};

EmbeddedSnapshotField.defaultProps = {
  addLabel: true,
};
