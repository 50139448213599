import React from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import LiteraturePublicationFilter from '../LiteraturePublicationFilter';

export const JournalPublicationsList: React.FC<{}> = (props) => (
  <List {...props} perPage={25} filters={<LiteraturePublicationFilter />}>
    <Datagrid rowClick="show">
      <TextField source="fullTitle" />
      <TextField source="slug" />
      <TextField source="categoryId" />
      <EditButton />
    </Datagrid>
  </List>
);
