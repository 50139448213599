import * as React from 'react';
import * as Slate from 'slate';
import * as SlateRect from 'slate-react';

export interface SlateIframeProps {
  editor: Slate.Editor;
  node: Slate.Node;
  name: string;
  src: string;
  srcDoc: string;
  width: number | string;
  height: number | string;
  sandbox: string;
}

const SlateIframe: React.FC<SlateIframeProps> = ({
  // Iframe properties:
  name,
  src,
  srcDoc,
  width,
  height,
  sandbox,
  // Other:
  children,
  ...rest
}) => {
  const isSelected = SlateRect.useSelected();

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <iframe
        title={name}
        name={name}
        src={src}
        srcDoc={srcDoc}
        width={width}
        height={height}
        sandbox={sandbox}
      />
      {children}
      {isSelected && (
        <div
          style={{
            position: 'absolute',
            background: '#acf',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            opacity: 0.5,
          }}
        />
      )}
    </div>
  );
};

export default SlateIframe;
