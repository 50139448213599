import * as xopusApiHelpers from '../../xopus/xopusApiHelpers';

export const toggleTocItem = (node) => {
  const openClass = 'lawToc__item--isOpen';

  const htmlNodes = xopusApiHelpers.getHTMLElementsForXMLNode(node);
  const htmlNode = htmlNodes.find((node) => node.nodeName === 'LI');

  if (htmlNode.classList.contains(openClass)) {
    htmlNode.classList.remove(openClass);
  } else {
    htmlNode.classList.add(openClass);
  }
};

export const browseToNode = (node) => {
  xopusApiHelpers.setViewParam('active_node', node);
  xopusApiHelpers.setViewParam('scrollToNode', true);

  scrollToSelectedNode(node);
};

export const selectNode = () => {
  const node = xopusApiHelpers.getViewParam('active_node');
  const scrollToNode = xopusApiHelpers.getViewParam('scrollToNode');

  if (scrollToNode && node) {
    xopusApiHelpers.setViewParam('scrollToNode', false);

    scrollToSelectedNode(node);
  }
};

export const scrollToSelectedNode = (node) => {
  const htmlNode = xopusApiHelpers
    .getHTMLElementsForXMLNode(node)
    .find((node) => node.nodeName !== 'LI');
  if (htmlNode) {
    xopusApiHelpers.setSelection(node);

    htmlNode.scrollIntoView();
  }
};
