import * as React from 'react';
import { fetchStart, fetchEnd, useNotify, useRedirect } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { HastNode } from '@universitetsforlaget/hast';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../../../dataProvider/apiUtil';
import { convertHastToJats } from '../../../util/hastConverter';

import * as simpleXml from '../../../util/simpleXml';
import { bitsFormatUploadUrl } from '../../literatureConversionProcesses/literatureConversionUtils';
import { JuridikaCmsContext } from '../../../util/juridikaCmsContext';

export const BitsDebugToolbarUploadButton: React.FC<{
  processId: string;
  book: simpleXml.Model;
  disabled: boolean;
}> = ({ processId, book, disabled }) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  const saveAs = async () => {
    const formData = new FormData();
    const hastNode = modelToHastNode(book);
    if (hastNode.type === 'element') {
      const bits = convertHastToJats(hastNode);
      const blob = new Blob([bits.replace(new RegExp('&', 'g'), '&amp;')], {
        type: 'application/bits+xml',
      });
      formData.append('file', blob);
      const description = prompt('Beskrivelse');

      if (!description) return;

      const url = bitsFormatUploadUrl(backendUrls, processId, description);

      dispatch(fetchStart());

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: juridikaHeaders(),
          body: formData,
        });
        if (!response.ok) {
          throw await response.text();
        }
        await response.text();

        dispatch(fetchEnd());
        redirect(`/literatureConversionProcesses/${processId}/show`);
      } catch (error) {
        dispatch(fetchEnd());
        notify(`Problem uploading: ${error}`, 'error');
      }
    }
  };

  return (
    <Tooltip title="Lagre som...">
      <IconButton
        aria-label="Lagre som..."
        color="inherit"
        disabled={disabled}
        onClick={() => saveAs()}
      >
        <CloudUploadIcon />
      </IconButton>
    </Tooltip>
  );
};

const modelToHastNode = (model: simpleXml.Model): HastNode => {
  if (model.type === simpleXml.ELEMENT) {
    return {
      type: 'element',
      tagName: model.tagName,
      properties: model.attributes,
      children: model.children.map((child) => modelToHastNode(child)),
    };
  } else return { type: 'text', value: model.value };
};
