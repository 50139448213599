import React from 'react';
import auth from '../auth';

const Auth0LoginPage: React.FC = () => {
  React.useEffect(() => {
    auth.login();
  });

  return null;
};

export default Auth0LoginPage;
