import * as io from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface LiteratureConversionAttachment {
  id: string;
  key: string;
  description: string;
  latestFormatCreatedAt: Date | null;
  latestFormatContentType: string | null;
  latestFormatSize: number | null;
}

export interface PartialLiteratureConversionAttachment {}

const apiTypeIO = io.type({
  id: io.string,
  key: io.string,
  description: io.string,
  latestFormatCreatedAt: io.union([io.string, io.null]),
  latestFormatContentType: io.union([io.string, io.null]),
  latestFormatSize: io.union([io.number, io.null]),
});
type ApiType = io.TypeOf<typeof apiTypeIO>;

interface ApiOutgoingType {}

export const toApiMapper = (bitsFormat: LiteratureConversionAttachment): ApiOutgoingType => ({
  id: bitsFormat,
});

export interface ApiPartialOutgoingType {}

export const toApiPartialMapper = (
  process: PartialLiteratureConversionAttachment
): ApiPartialOutgoingType => ({});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<LiteratureConversionAttachment> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/conversionAttachments`,
    halListName: 'conversionAttachmentList',
    incomingType: apiTypeIO,
    toReactAdminMapper: (api: ApiType): LiteratureConversionAttachment => ({
      id: api.id,
      key: api.key,
      description: api.description,
      latestFormatCreatedAt: api.latestFormatCreatedAt ? new Date(api.latestFormatCreatedAt) : null,
      latestFormatContentType: api.latestFormatContentType,
      latestFormatSize: api.latestFormatSize,
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      processId: 'processId',
    },
    filterParams: {},
    sortFields: [],
  });
