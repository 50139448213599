import * as Types from './types';
import { juridikaHeaders } from './apiUtil';

export interface WorkTag {
  workUri: string;
  tagPath: string;
  sequence: number;
}

export interface APIWorkTagPatchBody {
  add: Array<string>;
  remove: Array<string>;
  workUris: Array<String>;
}

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<WorkTag> => {
  const provider = async (
    type: Types.ReactAdminApiType,
    params: Types.ReactAdminApiParams
  ): Promise<Types.ReactAdminResponse<WorkTag>> => {
    const baseUrl = `${urls.lovEditorBackend}/admin/v0/tags/work`;

    switch (type) {
      case 'UPDATE_MANY': {
        const ids = params.ids || [];
        const requestBody: APIWorkTagPatchBody = {
          workUris: ids.map((uri) => `${uri}`),
          add: params.data.add,
          remove: params.data.remove,
        };
        const response = await fetch(baseUrl, {
          method: 'POST',
          headers: {
            ...juridikaHeaders(),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
          throw new Error(`${await response.text()}`);
        }
        return {
          data: await response.json(),
        };
      }
      default:
        return Promise.reject(new Error(`HAL CRUD api for ${baseUrl}: Not implemented: ${type}`));
    }
  };
  return provider;
};
