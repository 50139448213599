import React, { FC } from 'react';
import { Datagrid, EditButton, FunctionField, List, TextField, Record } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PublishDocumentButtons from './PublishDocumentButtons/PublishDocumentButtons';
import DateTimeField from '../DateTimeField';

const useStyles = makeStyles({
  rowCell: {
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
  headerCell: {
    backgroundColor: '#00968836',
  },
  mark: {
    backgroundColor: '#faf8dd',
  },
});

const NIKDocumentList: FC = (props) => {
  const classes = useStyles();

  return (
    <div>
      <h1>Liste over dokumenter</h1>
      <div>
        <p>
          Denne siden viser en liste over alle opprettede dokumenter i problemstillinger. Brukere
          har muligheten til å opprette nye dokumenter direkte fra denne siden samt redigere de.
        </p>
        <p>
          Modellen for problemstillinger:{' '}
          <mark className={classes.mark}>
            Problemstilling &rarr; utgave &rarr; <strong>dokument</strong>
          </mark>
        </p>
      </div>

      <List {...props} perPage={25} bulkActionButtons={false}>
        <Datagrid
          classes={{
            headerCell: classes.headerCell,
            rowCell: classes.rowCell,
          }}
        >
          <TextField source="id" sortable={false} />
          <TextField source="editionId" sortable={false} />
          <TextField source="editorAccountId" sortable={false} />
          <DateTimeField source="createdAt" sortable={false} />
          <FunctionField
            label="Publisering"
            render={(record: Record | undefined) => <PublishDocumentButtons record={record} />}
          />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};

export default NIKDocumentList;
