import * as React from 'react';
import * as Slate from 'slate';
import MenuItem from '@material-ui/core/MenuItem';
import EmojiSymbolsIcon from '@material-ui/icons/EmojiSymbols';

import SlateSimpleMenu from '../../components/SlateInput/SlateSimpleMenu';

import { Plugin } from './types';

const key = 'special-character-plugin';

const SpecialCharacterMenuItem = (editor: Slate.Editor, character: string, name: string) =>
  React.createElement(
    MenuItem,
    {
      key: character,
      selected: false,
      onMouseDown: () => {
        editor.insertText(character);
      },
    },
    name
  );

const SpecialCharacterButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  return React.createElement(SlateSimpleMenu, {
    key,
    isActive: false,
    Icon: EmojiSymbolsIcon,
    message: 'slate.contextMenu.specialCharacter.name',
    menuItems: [SpecialCharacterMenuItem(editor, '\u00ad', 'Soft hyphen')],
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (editor: Slate.Editor) => {
        return <SpecialCharacterButton key={key} editor={editor} />;
      },
    },
  };
};
