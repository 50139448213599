import { CREATE, GET_ONE, UPDATE } from 'react-admin';

import * as Types from '../types';
import { LovbackendService } from './lovbackendService';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  legalChangesProvider(urls.lovEditorBackend, type, params);

const legalChangesProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<any> => {
  const backend = new LovbackendService(baseApiUrl);
  switch (type) {
    case GET_ONE:
      return backend.getChanges(params.id!!);
    case CREATE:
    case UPDATE:
      return backend.saveChanges(params.id!!, params.data);
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
