import { CREATE, GET_LIST, GET_MANY, GET_ONE, UPDATE } from 'react-admin';

import * as Types from '../types';
import { LovbackendService } from './lovbackendService';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => legalWorksProvider(urls.lovEditorBackend, type, params);

const legalWorksProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<any> => {
  const backend = new LovbackendService(baseApiUrl);

  switch (type) {
    case GET_LIST:
    case GET_MANY:
      return backend.getWorkPage(params).then((w) => w);
    case GET_ONE:
      return backend.getWork(params.id!!);
    case CREATE:
      return backend.createWork(params);
    case UPDATE:
      return backend.updateWork(params);
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
