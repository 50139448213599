import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { juridikaFrontendBaseUrl } from '../../../dataProvider/apiUrls';
import { useRecordContext } from 'react-admin';

const LivePreviewPanel = () => {
  const classes = useStyles();
  const record = useRecordContext();

  const FRONTEND_BASE_URL = juridikaFrontendBaseUrl();

  return (
    <div className={classes.root}>
      <iframe
        title="live-preview"
        id="iframe"
        src={`${FRONTEND_BASE_URL}/problemstilling/draft/${record.id}`}
        className={classes.iframe}
      />
    </div>
  );
};

export default LivePreviewPanel;

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
});
