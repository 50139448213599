import React, { FC } from 'react';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  Edit,
  ReferenceField,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export const NikContributorDetailsEdit: React.FC<{}> = (props) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Oppdater detaljer for en bidragsyter</h1>
          <p>Denne informasjonen vil vises på Juridikas problemstillinger.</p>
        </div>
        <ReferenceField source="contributorId" reference="contributors" label={'Bidragsyter'}>
          <TextField source="fullname" />
        </ReferenceField>
        <TextInput
          source="title"
          label="Bidragsyterens tittel"
          helperText="F.eks. Advokat, Dommer, Professor... Vil prefixe bidragsyterens navn"
        />
        <TextInput
          source="shortBio"
          label="Kort biografi"
          helperText="En kort biografi som vil vises på Juridika under navnet på bidragsyteren"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export const NikContributorDetailsCreate: React.FC<{}> = (props) => {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Opprett detaljer for en bidragsyter</h1>
          <p>Denne informasjonen vil vises på Juridikas problemstillinger.</p>
        </div>
        <ReferenceInput source="contributorId" reference="contributors">
          <AutocompleteInput
            optionValue="id"
            optionText="fullname"
            validate={required('Bidraget må være tilknyttet en bidragsyter')}
          />
        </ReferenceInput>
        <TextInput
          source="title"
          label="Bidragsyterens tittel"
          helperText="F.eks. Advokat, Dommer, Professor... Vil prefixe bidragsyterens navn"
        />
        <TextInput
          source="shortBio"
          label="Kort biografi"
          helperText="En kort biografi som vil vises på Juridika"
        />
      </SimpleForm>
    </Create>
  );
};
