import * as React from 'react';
import {
  AutocompleteInput,
  CheckboxGroupInput,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  Toolbar,
} from 'react-admin';
import { LegalChange } from '../../../state/legalDocuments/types';
import { formatLegalReference } from '../../../util/legalIdentifierUtils';

interface Props {
  possibleFragments: { id: string; name: string }[];
  change: LegalChange;
  updateChange: (change: LegalChange) => void;
}

export const LegalChangeEdit: React.FC<any> = (props: Props) => {
  const postSave = (data: LegalChange) => {
    props.updateChange(data);
  };

  return (
    <React.Fragment>
      <SimpleForm
        margin="normal"
        initialValues={props.change}
        save={(data: LegalChange) => postSave(data)}
        toolbar={<ChangeFormToolbar />}
      >
        <ReferenceInput
          isRequired
          label="Endret av"
          source="subjectUri"
          reference="legalWorks"
          fullWidth
        >
          <AutocompleteInput optionText={formatLegalReference} />
        </ReferenceInput>
        <CheckboxGroupInput
          fullWidth
          isRequired
          row={false}
          source="fragments"
          choices={props.possibleFragments}
          style={{ whiteSpace: 'pre' }}
        />
      </SimpleForm>
    </React.Fragment>
  );
};

const ChangeFormToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton label={'Ok'} />
  </Toolbar>
);
