import * as Slate from 'slate';

import { insertChildIfMissing } from '../../utils/transforms';
import * as tags from '../../tags';

const endNode = {
  type: tags.BLOCK_END_PARAGRAPH,
  children: [{ text: '' }],
};

const createAsideNode = (tag: string): Slate.Node => {
  return {
    type: tags.BLOCK_ASIDE,
    className: 'facts',
    children: [createRecommendedPagesModuleNode(tag), endNode],
  };
};

const createRecommendedPagesNode = (tag: string) => {
  return {
    type: tags.BLOCK_RECOMMENDED_PAGES,
    tag: tag,
    children: [{ text: '' }],
  };
};

const createRecommendedPagesModuleNode = (tag: string) => {
  let heading = 'Juridika anbefaler';
  if (tag === tags.TAG_RECOMMENDED_PAGES_NEW) {
    heading = 'Nylig publiserte';
  } else if (tag === tags.TAG_RECOMMENDED_PAGES_POPULAR) {
    heading = 'Mest populære';
  }

  return {
    type: tags.BLOCK_RECOMMENDED_PAGES_MODULE,
    children: [
      { type: tags.BLOCK_RECOMMENDED_PAGES_HEADER, children: [{ text: heading }] },
      createRecommendedPagesNode(tag),
    ],
  };
};

export const insertAsideRecommendedPages = (editor: Slate.Editor, tag: string) => {
  const sectionBodyPath = [0, 1];
  const sectionBodyNode = Slate.Node.get(editor, sectionBodyPath);
  const divWrapperIndex = 1;
  // Make sure right aside element exists
  const insertedAside = insertChildIfMissing(
    editor,
    [sectionBodyNode, sectionBodyPath],
    divWrapperIndex,
    tags.BLOCK_ASIDE,
    createAsideNode(tag)
  );

  if (!insertedAside) {
    // Make sure we always insert in the end
    const insertAtIndex = [
      ...Slate.Node.children(editor, [...sectionBodyPath, divWrapperIndex]),
    ].filter(([node]) => Slate.Element.isElement(node)).length;

    Slate.Transforms.insertNodes(editor, createRecommendedPagesModuleNode(tag), {
      at: [0, 1, 1, insertAtIndex],
      select: true,
    });

    Slate.Transforms.insertNodes(editor, endNode, {
      at: [0, 1, 1, insertAtIndex + 1],
      select: true,
    });
  }
};
