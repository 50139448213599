export default [
  {
    role: 'author',
    name: 'Forfatter',
  },
  {
    role: 'coauthor',
    name: 'Medforfatter',
  },
  {
    role: 'editor',
    name: 'Redaktør',
  },
];
