import { ZonedDateTime, ZoneId, convert } from '@js-joda/core';
import '@js-joda/timezone';

export type TimeTemplate = 'now' | 'tonight' | 'custom';

export const dateTimeFromTemplate = (template: TimeTemplate, customDate: Date): Date => {
  switch (template) {
    case 'now':
      return new Date();

    case 'tonight':
      return getTonightAtOsloZone(nowAtOsloZone());
    case 'custom':
      return customDate;
  }
};

export const nowAtOsloZone = (): ZonedDateTime => {
  return ZonedDateTime.now(ZoneId.of('Europe/Oslo'));
};

const getTonightAtOsloZone = (now: ZonedDateTime): Date => {
  // No matter where you are, we should use Oslo time zones, so `Date` won't suffice
  const adjusted = adjustDateTimeToMatchSearchEngineCrawler(now);

  return convert(adjusted).toDate();
};

// exported for testing
export const adjustDateTimeToMatchSearchEngineCrawler = (now: ZonedDateTime): ZonedDateTime => {
  const startOfDay = now.withHour(0).withMinute(0).withSecond(0).withNano(0);

  if (now.hour() === 0 && now.minute() < 30) {
    // Early "morning" we still have time to publish :)
    // the rationale here is that search-engine-crawler has been set up to execute at
    // 01:30 local time. But a bug in it could move it to 00:30 (earliest) when the
    // time zone changes from CEST to CET.
    return now;
  }

  // 00:00 the next day
  return startOfDay.plusDays(1);
};
