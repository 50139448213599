import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import TransformIcon from '@material-ui/icons/Transform';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';

export const WebifyAttachmentsButton: React.FC<{ label: string; processId: string }> = (props) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const webifyUrl = `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${props.processId}/webifyAttachments`;

  const doImport = async () => {
    dispatch(fetchStart());

    try {
      const response = await fetch(webifyUrl, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      await response.text();
    } catch (error) {
      notify(`Problem webifying: ${error}`, 'error');
    }

    dispatch(fetchEnd());
    refresh();
  };

  return (
    <Button label={props.label} color="primary" onClick={doImport}>
      <TransformIcon />
    </Button>
  );
};

export default WebifyAttachmentsButton;
