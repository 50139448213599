import { Descendant } from 'new-slate';

const wrapInBody = (nodes: Descendant[]): Descendant[] => [
  {
    type: 'body',
    children: nodes,
  },
];

const wrapInDynamicDocument = (nodes: Descendant[]): Descendant[] => [
  {
    type: 'dynamic-document',
    children: nodes,
  },
];

const translateHeadingLevel = (level: string | unknown): number => {
  switch (level) {
    case 'heading-one':
      return 1;
    case 'heading-two':
      return 2;
    case 'heading-three':
      return 3;
    case 'heading-four':
      return 4;
    case 'heading-five':
      return 5;
    case 'heading-six':
      return 6;
    default:
      return 7;
  }
};

const createPart = (level: number, children: Descendant[]): Descendant => {
  return {
    type: 'part',
    attributes: {
      level,
    },
    children: children,
  };
};

const convertToTitle = (node: Descendant): Descendant => {
  return {
    type: 'title',
    attributes: {
      level: translateHeadingLevel(node.type),
    },
    children: node.children,
  };
};

const resolvePartNesting = (nodes: Descendant[], level = 2): Descendant[] => {
  const parts: Descendant[] = [];
  var titleNode: Descendant[] = [];
  var children: Descendant[] = [];
  if (nodes.length === 0) {
    return [];
  }

  const testIfTitleNode = (nodeLevel: number): boolean => {
    return titleNode.length === 0 && nodeLevel === level && children.length === 0;
  };

  const isChildNode = (nodeLevel: number): boolean => {
    return nodeLevel > level;
  };

  nodes.forEach((node: any) => {
    const nodeLevel = translateHeadingLevel(node.type);
    if (testIfTitleNode(nodeLevel)) {
      titleNode.push(convertToTitle(node));
    } else if (!isChildNode(nodeLevel)) {
      const newChildren = resolvePartNesting([...children], nodeLevel + 1);
      const childrenWithTitles = titleNode.concat(...newChildren);
      if (titleNode.length !== 0) {
        parts.push(createPart(level, childrenWithTitles));
      } else {
        parts.push(children);
      }
      children = [];
      titleNode = [];
      titleNode.push(convertToTitle(node));
    } else {
      children.push(node);
    }
  });
  if (titleNode.length !== 0) {
    const resolvedChildren = resolvePartNesting(children, level + 1);
    const childrenWithTitles = titleNode.concat(...resolvedChildren);
    parts.push(createPart(level, childrenWithTitles));
  } else {
    parts.push(children);
  }
  return parts;
};

export const normalizeToXmlStructure = (nodes: Descendant[]): Descendant[] => {
  const wrappedInParts = resolvePartNesting(nodes);
  const inBody = wrapInBody(wrappedInParts);
  const inDynamicDocument = wrapInDynamicDocument(inBody);
  return inDynamicDocument;
};
