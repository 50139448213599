import { HypertextImportRule, HypertextRuleSet } from './rules';
import { HypertextDeserializationRule, HypertextDeserializationRuleSet } from './types';

export const makeDeserializationRuleSet = (
  object: 'block' | 'inline' | 'mark',
  ruleSet: HypertextRuleSet
): HypertextDeserializationRuleSet =>
  Object.keys(ruleSet).reduce(
    (agg: HypertextDeserializationRuleSet, htmlTag: string) => ({
      ...agg,
      [htmlTag]: ruleSet[htmlTag].reduce((rules, rule) => {
        const rest = {
          object,
          isInline: object === 'inline' || object === 'mark',
          isMark: object === 'mark',
        };

        if ('reimportHtmlTagAs' in rule) {
          const rewriteRule = (ruleSet[rule['reimportHtmlTagAs']] as HypertextImportRule[]).map(
            (importRule) => ({
              ...rest,
              ...importRule,
            })
          );

          return [...rules, ...rewriteRule];
        } else {
          return [
            ...rules,
            {
              ...rest,
              ...rule,
            },
          ];
        }
      }, [] as Array<HypertextDeserializationRule>),
    }),
    {}
  );

export const concat = <T>(a: T[], b: T[]): T[] => a.concat(b);
