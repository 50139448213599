import * as React from 'react';
import {
  SelectInput,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  Filter,
  TextInput,
  ArrayField,
  SingleFieldList,
  Pagination,
  useListContext,
  BooleanField,
  ChipField,
} from 'react-admin';
import LinkInjectButton from './LinkInjectButton';
import ModifyTagsButton from '../documentTags/ModifyTagsButton';
import { TagChipField } from '../StyledChipFields';
import { JournalArticleExpandShow } from './JournalArticleDetails';
import BulkPublishArticlesButton from './components/BulkPublishArticlesButton';
import { JournalArticle } from '../../dataProvider/journalArticlesProvider';

const JournalBulkActionButtons = (props: any) => {
  const { data } = useListContext<JournalArticle>();

  return (
    <React.Fragment>
      <LinkInjectButton {...props} />
      <ModifyTagsButton
        tagResource="literatureDocumentTags"
        tagPrefixes={['rettsområde', 'freebie']}
        getTagPathsOnSelectedIds={() => {
          const set: Set<string> = new Set();
          if (props.selectedIds) {
            props.selectedIds.forEach((id: string) => {
              data[id]?.tags?.forEach((tag: { path: string[]; name: string }) => {
                set.add(tag.path.join('.'));
              });
            });
          }
          return set;
        }}
      />
      <BulkPublishArticlesButton />
    </React.Fragment>
  );
};

const JournalFilter = (props: any) => {
  return (
    <Filter variant="standard" margin="normal" {...props}>
      <ReferenceInput
        label="Utgivelse"
        source="publicationId"
        reference="journalPublications"
        alwaysOn
      >
        <SelectInput optionText="fullTitle" />
      </ReferenceInput>
      <SelectInput
        label="Publisert"
        source="isJuridikaPublished"
        choices={[
          {
            id: 'true',
            name: 'Ja',
          },
          {
            id: 'false',
            name: 'Nei',
          },
        ]}
        alwaysOn
        allowEmpty
      />
      <TextInput label="Edition" source="editionQuery" placeholder="2020/1" alwaysOn />
      <TextInput label="Document key" source="documentKeyQuery" alwaysOn />
    </Filter>
  );
};

const ArticlePagination = (props: any) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 150, 200]} {...props} />
);

export default (props: {}) => (
  <List
    {...props}
    perPage={50}
    pagination={<ArticlePagination />}
    filters={<JournalFilter />}
    bulkActionButtons={<JournalBulkActionButtons />}
  >
    <Datagrid expand={<JournalArticleExpandShow />}>
      <ReferenceField source="editionId" reference="journalEditions" sortable={false}>
        <ChipField source="shortDescription" />
      </ReferenceField>
      <TextField
        label="resources.journalArticles.fields.title"
        source="fullTitle"
        sortable={false}
      />
      <ArrayField source="tags" sortable={false}>
        <SingleFieldList>
          <TagChipField source="displayName" />
        </SingleFieldList>
      </ArrayField>
      {/*<StatusField source="linkStatus" /> */}
      <BooleanField label="Publ." source="isPublishedAtJuridika" sortable={false} />
    </Datagrid>
  </List>
);
