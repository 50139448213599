import * as React from 'react';
import * as Slate from 'slate';
import InsertPhoto from '@material-ui/icons/InsertPhoto';

import { insertImage, removeImage } from './transforms';
import { Plugin } from '../types';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';

const key = 'image-plugin';

const ImageButton: React.FC<{ editor: Slate.Editor }> = (props) => {
  if (!props.editor) return null;

  const isActive = !!getAboveByType(props.editor, tags.BLOCK_IMAGE);

  return React.createElement(SlateToolbarButton, {
    key,
    isActive,
    Icon: InsertPhoto,
    message: 'slate.contextMenu.image',
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (isActive) {
        removeImage(props.editor);
      } else {
        insertImage(props.editor);
      }
    },
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <ImageButton key={key} editor={editor} />;
      },
    },
    withEditor: (editor) => {
      const { isVoid } = editor;

      editor.isVoid = (element: Slate.Element) => {
        return isVoid(element) || element.type === tags.BLOCK_IMAGE;
      };

      return editor;
    },
  };
};
