import React from 'react';
import {
  Create,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  SelectInput,
  Labeled,
  required,
} from 'react-admin';

import SlateTextInput from '../SlateTextInput';
import { DocumentLockedMessage } from '../staticContentCommon/DocumentLockedMessage';
import { ContributionsInput } from '../staticContentCommon/ContributionsInput';
import { PageSnapshot } from '../../dataProvider/pageSnapshotsProvider';
import { createEmptyPageSnapshot } from '../../dataProvider/pageSnapshotsProvider';
import newsArticleSpec from '../../slate/documentSpecs/newsArticleSpec';
import { ImageInputField } from '../../containers/ImageEditor/ImageInputField';

const validateSlug = (slug?: string): string | undefined => {
  if (slug && slug.search(/[^-0-9a-zæøåäöü]/) >= 0) {
    return 'Slug kan kun inneholde små bokstaver (a-å og äöü), siffer og bindestreker';
  }
};

const isMutable = (record: PageSnapshot | undefined) => {
  if (!record) return true;
  return record.currentEditorIsCurrentUser !== false;
};

const PageSnapshotForm: React.FC<{
  initialValues?: PageSnapshot;
  record?: PageSnapshot;
  redirect?: boolean;
}> = ({ redirect = true, record, ...props }) => {
  const disabled = !isMutable(record);

  return (
    <TabbedForm
      {...props}
      variant="standard"
      margin="normal"
      record={record}
      redirect={redirect}
      sanitizeEmptyValues={false}
    >
      <FormTab label="resources.pageSnapshots.form.contents">
        {disabled && <DocumentLockedMessage />}

        <ReferenceInput
          source="revision.contentCategoryId"
          reference="contentCategories"
          disabled={disabled}
          validate={required()}
        >
          <SelectInput source="revision.name" />
        </ReferenceInput>

        <TextInput
          multiline
          fullWidth
          source="slug"
          disabled={disabled}
          validate={[required(), validateSlug]}
        />

        <TextInput
          multiline
          fullWidth
          source="revision.title"
          disabled={disabled}
          validate={required()}
        />
        <Labeled key="revision.hast" label="HTML-innhold" fullWidth>
          <SlateTextInput
            source="revision.hast"
            documentSpec={newsArticleSpec}
            disabled={disabled}
          />
        </Labeled>
      </FormTab>

      <FormTab label="resources.pageSnapshots.form.metadata">
        <ContributionsInput
          resourceName="pageSnapshots"
          disabled={disabled}
          showOccupationField={true}
        />
        <Labeled label="resources.pageSnapshots.fields.shareImageUrl" fullWidth>
          <ImageInputField source="shareImageUrl" />
        </Labeled>
      </FormTab>
    </TabbedForm>
  );
};

export const PageSnapshotCreate: React.FC<any> = (props) => (
  <Create {...props} title={<span>Ny side</span>}>
    <PageSnapshotForm initialValues={createEmptyPageSnapshot()} />
  </Create>
);

const EditTitle: React.FC<{ record?: PageSnapshot }> = ({ record: snapshot }) => (
  <span>Revider {`"${snapshot!!.revision.title}"`}</span>
);

export const PageSnapshotEdit: React.FC<any> = (props) => (
  <Edit undoable={false} title={<EditTitle />} {...props}>
    <PageSnapshotForm />
  </Edit>
);
