import URI from 'urijs';
import { CommentaryMetadataForAllExpressionFragments } from '../../state/expressions/types';
import { LovbackendService } from './lovbackendService';
import { BackendUrls } from '../types';

export class LovbackendCommentsService {
  constructor(private url: string) {}

  getCommentedExpressions(workUri: string): Promise<string[]> {
    const url: uri.URI = URI(this.url)
      .segment('api/admin/cms/comment/allCommentedExpressions')
      .search({ workUri });
    return LovbackendService.fetchJson(url);
  }

  getCommentMeta(
    expressionUri: string,
    fragmentId: string,
    publicationStatus?: string
  ): Promise<CommentaryMetadataForAllExpressionFragments> {
    const url: uri.URI = URI(this.url)
      .segment('api')
      .segment('admin')
      .segment('comment')
      .segment(expressionUri)
      .segment(fragmentId)
      .segment('meta')
      .search({ publicationStatus });
    return LovbackendService.fetchJson(url);
  }

  getAllCommentMeta(expressionUri: string): Promise<CommentaryMetadataForAllExpressionFragments> {
    const url: uri.URI = URI(this.url)
      .segment('api/admin/cms/comment/allCommentsForExpressionGroupedByFragment')
      .search({ expressionUri });
    return LovbackendService.fetchJson(url);
  }

  getCommentXml(expressionUri: string, fragmentId: string, status?: string): Promise<string> {
    const url = URI(this.url)
      .segment('api/admin/comment')
      .segment(expressionUri)
      .segment(fragmentId)
      .search({ status });

    return LovbackendService.fetch(url, 'GET', null, {
      Accept: 'application/xml',
    }).then((response) => response.text());
  }

  saveComment(
    expressionUri: string,
    fragmentId: string,
    xml: string,
    editor: string
  ): Promise<string> {
    const url = URI(this.url)
      .segment('api/admin/cms/comment/save')
      .search({ expressionUri, fragmentId, editor });

    return LovbackendService.fetch(url, 'POST', xml).then((response) => response.text());
  }

  createFromLatestPublished(
    expressionUri: string,
    fragmentId: string,
    editor: string
  ): Promise<string> {
    const url = URI(this.url)
      .segment('api/admin/cms/comment/createFromLatest')
      .search({ expressionUri, fragmentId, editor });

    return LovbackendService.fetch(url, 'POST').then((response) => response.text());
  }

  publish(expressionUri: string, fragmentIds: string[], editor: string): Promise<string> {
    let form = new FormData();
    fragmentIds.forEach((id) => form.append('fragmentId', id));

    const url = URI(this.url)
      .segment('api/admin/cms/comment/publish')
      .search({ expressionUri, editor });

    return LovbackendService.fetch(url, 'POST', form).then((response) => response.text());
  }

  overwriteLastReviewed(
    expressionUri: string,
    fragmentIds: string[],
    editor: string,
    date: string
  ): Promise<string> {
    let form = new FormData();
    fragmentIds.forEach((id) => form.append('fragmentId', id));

    const url = URI(this.url)
      .segment('api/admin/cms/comment/overwriteLastReviewed')
      .search({ expressionUri, editor, date });
    return LovbackendService.fetch(url, 'POST', form).then((response) => response.text());
  }

  public static create(backends: BackendUrls): LovbackendCommentsService {
    return new LovbackendCommentsService(backends.lovEditorBackend);
  }
}
