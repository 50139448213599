import * as Slate from 'slate';

import { Plugin } from './types';
import * as tags from '../tags';
import { processNodes } from '../utils/process';

// Styles related
import '../../styles/slateEditor.css';

const postProcessNodesBeforeSerialization = (nodes: Slate.Node[]) => {
  return processNodes(nodes, {
    unwrap: (node) => {
      if (node.type === tags.BLOCK_FRAGMENT) return node.children;
    },
  });
};

const preProcessNodesAfterDeserialization = (nodes: Slate.Node[]) => {
  return [
    {
      type: tags.BLOCK_FRAGMENT,
      className: ['embeddedDocument'],
      children: nodes,
    },
  ];
};

export default (): Plugin => {
  return {
    key: 'embedded-document-plugin',
    preProcessNodesAfterDeserialization,
    postProcessNodesBeforeSerialization,
    withEditor: (editor) => {
      const { getFragment, isVoid } = editor;

      editor.getFragment = () => {
        return postProcessNodesBeforeSerialization(getFragment());
      };

      editor.isVoid = (element) => {
        return element.type === tags.BLOCK_IMAGE || isVoid(element);
      };

      return editor;
    },
  };
};
