import { SubscriptionPackage } from '../dataProvider/subscriptionPackageProvider';

import * as ProductKeys from './customerProductsSemanticKeys';
import { Product } from '../dataProvider/customerProductsProvider';
import { SubscriptionStatus } from '../dataProvider/subscriptionStatusProvider';

const DEFAULT_PRODUCTS_FOR_SUBSCRIPTION_PACKAGES: { [key: string]: string[] } = {
  'juridika-premium': [
    ProductKeys.PRODUCT_ALL_JOURNALS,
    ProductKeys.PRODUCT_BACKLIST,
    ProductKeys.PRODUCT_LAW_COMMENTARY,
    ProductKeys.PRODUCT_ALL_CONTRACTS,
  ],
  'juridika-basis': [ProductKeys.PRODUCT_LAW_COMMENTARY],
  'juridika-pluss': [ProductKeys.PRODUCT_LAW_COMMENTARY, ProductKeys.PRODUCT_ALL_JOURNALS],
  'juridika-nye-fagbøker': [
    ProductKeys.PRODUCT_ALL_JOURNALS,
    ProductKeys.PRODUCT_BACKLIST,
    ProductKeys.PRODUCT_FRONTLIST,
    ProductKeys.PRODUCT_LAW_COMMENTARY,
    ProductKeys.PRODUCT_ALL_CONTRACTS,
  ],
  'juridika-pakker': [
    ProductKeys.PACKAGE_EOS_GENERAL,
    ProductKeys.PACKAGE_METODE,
    ProductKeys.PACKAGE_PERSPECTIVE,
  ],
};

const DEDAULT_PRODUCTS_FOR_SUBSCRIPTION_STATUS: { [key: string]: string[] } = {
  CUSTOMER: [ProductKeys.PRODUCT_EXCERPT],
};

export const defaultProductIdsForSubscriptionPackages = (
  subscriptionPackageId: string | null,
  subscriptionStatusId: string | null,
  allProducts: Array<Product>,
  allSubscriptionPackages: Array<SubscriptionPackage>,
  allSubscriptionStatus: Array<SubscriptionStatus>
): string[] => {
  const subscriptionPackage =
    subscriptionPackageId &&
    allSubscriptionPackages.find(
      (subscriptionPackage) => subscriptionPackage.id === subscriptionPackageId
    );
  console.log(allProducts);
  console.log(allSubscriptionPackages);

  const subscriptionStatus =
    subscriptionStatusId &&
    allSubscriptionStatus.find(
      (subscriptionStatus) => subscriptionStatus.id === subscriptionStatusId
    );
  console.log('sub', subscriptionPackage);
  const productKeysFromPackages =
    DEFAULT_PRODUCTS_FOR_SUBSCRIPTION_PACKAGES[
      subscriptionPackage ? subscriptionPackage.semanticKey : ''
    ] || [];

  console.log('productKeysFromPackages', productKeysFromPackages);

  const productKeysFromStatus =
    DEDAULT_PRODUCTS_FOR_SUBSCRIPTION_STATUS[subscriptionStatus ? subscriptionStatus.id : ''] || [];

  const someProducts = allProducts
    .filter((product) => productKeysFromPackages.indexOf(product.name) >= 0)
    .map((product) => product.id);
  console.log('someProducts', someProducts);
  return allProducts
    .filter((product) => productKeysFromStatus.indexOf(product.name) >= 0)
    .map((product) => product.id)
    .concat(someProducts);
};
