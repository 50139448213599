import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useDispatch } from 'react-redux';

import { JuridikaCmsContext } from '../../util/juridikaCmsContext';
import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { pdfToolRunUrl } from './literatureConversionUtils';

export const RunPdfToolButton: React.FC<{
  processId: string;
}> = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  const runUrl = pdfToolRunUrl(backendUrls, props.processId);

  const startPdfTool = async () => {
    dispatch(fetchStart());

    try {
      const response = await fetch(runUrl, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      await response.text();
    } catch (error) {
      notify(`Problem starting PDF-tool: ${error}`, 'error');
    }

    dispatch(fetchEnd());
    refresh();
  };

  const handleClick = () => {
    startPdfTool();
  };

  return (
    <>
      <Button label="Kjør PDF-tool" color="primary" onClick={handleClick}>
        <MenuBookIcon />
      </Button>
    </>
  );
};

export default RunPdfToolButton;
