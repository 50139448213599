import * as t from 'io-ts';

import { halJsonCrudApiProvider } from './apiUtil';
import * as Types from './types';

export interface User {
  id: string;
  email: string;
  name: string;
  customerNumber: string;
  termsAccepted: boolean;
  emailVerified: boolean;
  lastLogin: string;
  loginCount: number;
  customerId: string | null;
}

// For patching user
interface PartialUser {
  id: string;
  email?: string;
  name?: string;
  customerId?: string | null;
}

const ApiIncomingType = t.type({
  id: t.string,
  email: t.string,
  name: t.string,
  customerNumber: t.string,
  termsAccepted: t.boolean,
  emailVerified: t.boolean,
  lastLogin: t.string,
  loginCount: t.number,
  subscriptions_customerId: t.union([t.string, t.null]),
});

type ApiType = t.TypeOf<typeof ApiIncomingType>;

interface ApiPartialOutgoingType {
  id: string;
  email?: string;
  name?: string;
  subscriptions_customerId?: string | null;
}

const toApiPartialMapper = (
  user: PartialUser,
  previousData: User | undefined
): ApiPartialOutgoingType => {
  return {
    id: user.id,
    ...(user.email !== undefined &&
      user.email !== previousData?.email && {
        email: user.email,
      }),
    ...(user.name !== undefined &&
      user.name !== previousData?.name && {
        name: user.name,
      }),
    ...(user.customerId !== undefined &&
      user.customerId !== previousData?.customerId && {
        subscriptions_customerId: user.customerId,
      }),
  };
};

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<User> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaSubscriptionBackend}/admin/v1/users`,
    halListName: 'userDataList',
    incomingType: ApiIncomingType,
    toReactAdminMapper: (apiUser: ApiType): User => ({
      id: apiUser.id,
      customerNumber: apiUser.customerNumber,
      name: apiUser.name,
      email: apiUser.email,
      termsAccepted: apiUser.termsAccepted,
      emailVerified: apiUser.emailVerified,
      lastLogin: apiUser.lastLogin,
      loginCount: apiUser.loginCount,
      customerId: apiUser.subscriptions_customerId,
    }),
    toApiMapper: () => {}, // Users are not created in the CMS, per now atleast
    toApiPartialMapper: toApiPartialMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      customerNumber: (customerNumber) => ({ customerNumber }),
      customerId: (customerId) => ({ customerId }),
    },
    sortFields: [],
  });
