import { findIdInListByName, getChoicesFromObject, ConfirmProps } from './customerBulkUtils';

export const setPacakageConfirm = (props: ConfirmProps, packageName: string) => {
  const { admin, basePath, crudUpdateMany, resource, selectedIds } = props;
  const allCustomers = admin.resources.customers.data;
  const packageId = findIdInListByName(props.admin.resources.subscriptionPackage.data, packageName);

  const customers = Object.keys(allCustomers).reduce((customers, customerId) => {
    if (
      selectedIds.includes(customerId) &&
      allCustomers[customerId].subscriptionPackageId !== packageId
    ) {
      return {
        ...customers,
        [customerId]: {
          ...allCustomers[customerId],
          subscriptionPackageId: packageId,
        },
      };
    }
    return customers;
  }, {});

  crudUpdateMany(resource, Object.keys(customers), customers, basePath);
};

export const getPackageChoices = (props: ConfirmProps): string[] => {
  const { subscriptionPackage } = props.admin.resources;
  return getChoicesFromObject(subscriptionPackage.data);
};
