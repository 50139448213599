import * as React from 'react';
import {
  ReferenceField,
  SingleFieldList,
  TextField,
  ChipField,
  Datagrid,
  ArrayField,
  BooleanField,
  EditButton,
} from 'react-admin';

import { TagChipField, ContributorChipField } from '../StyledChipFields';

const TextbookModuleDatagrid: React.FC = () => {
  return (
    <Datagrid isRowSelectable={() => true} hasBulkActions rowClick="show">
      <ChipField source="editionKey" sortable={false} label="Nøkkel" />
      <BooleanField source="isPublishedAtJuridika" sortable={false} label="Publ." />
      <TextField source="fullTitle" sortable={false} />

      <ArrayField source="formats" sortable={false}>
        <SingleFieldList>
          <ChipField source="displayName" />
        </SingleFieldList>
      </ArrayField>

      <ArrayField source="tags" sortable={false}>
        <SingleFieldList>
          <TagChipField source="displayName" />
        </SingleFieldList>
      </ArrayField>

      <ArrayField source="contributions" sortable={false}>
        <SingleFieldList>
          <ReferenceField
            source="contributorId"
            reference="contributors"
            label="resources.pageSnapshots.fields.revision.contributions.contributorId"
          >
            <ContributorChipField source="fullname" />
          </ReferenceField>
        </SingleFieldList>
      </ArrayField>
      <EditButton label="" />
    </Datagrid>
  );
};

export default TextbookModuleDatagrid;
