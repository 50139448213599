import React from 'react';
import { Button, Mutation } from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import WarningIcon from '@material-ui/icons/Warning';

import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';

interface AbstractSnapshot {
  currentEditorId: string | null;
  currentEditorIsCurrentUser: boolean;
  revision: StaticDocumentRevision;
}

export const MutateCurrentEditorButton: React.FC<{
  record?: AbstractSnapshot;
}> = ({ record }) => {
  if (!record) return null;

  const mutationPayload =
    !record.currentEditorId || !record.currentEditorIsCurrentUser
      ? {
          id: record.revision.id,
          data: {
            assignCurrentEditorToCurrentUser: true,
          },
        }
      : {
          id: record.revision.id,
          data: {
            currentEditorId: null,
          },
        };

  return (
    <Mutation
      type="UPDATE"
      resource="revisions"
      payload={mutationPayload}
      options={{
        undoable: false,
        onSuccess: {
          notification: { body: 'Ok!', level: 'info' },
          refresh: true,
        },
        onError: {
          notification: { body: 'Feil med låsing eller opplåsing av dokument', level: 'warning' },
        },
      }}
    >
      {(mutate: () => void) => {
        if (!record.currentEditorId) {
          return (
            <Button label="Lås dokument for redigering" variant="text" onClick={mutate}>
              <LockIcon />
            </Button>
          );
        } else if (!record.currentEditorIsCurrentUser) {
          return (
            <Button
              label="Stjel låsen til dokumentet (pass på så ikke flere redigerer samtidig)!"
              variant="text"
              onClick={mutate}
            >
              <WarningIcon />
            </Button>
          );
        } else {
          return (
            <Button
              label="Lås opp dokumentet så andre kan redigere"
              variant="text"
              onClick={mutate}
            >
              <LockOpenIcon />
            </Button>
          );
        }
      }}
    </Mutation>
  );
};
