import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  ReferenceField,
  TextField,
  RichTextField,
  SelectField,
  EditButton,
  TextInput,
  SelectInput,
  TopToolbar,
  CreateButton,
  RefreshButton,
} from 'react-admin';

import DateTimeField from '../DateTimeField';

import { EmbeddedSnapshotExpandShow } from './EmbeddedSnapshotDetails';
import foreignTypeChoices from './foreignTypeChoices';

const EmbeddedSnapshotFilter: React.FC<any> = (props) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput label="resources.embeddedSnapshots.actions.filterByTitle" source="q" alwaysOn />
    <SelectInput
      label="resources.embeddedSnapshots.fields.foreignType"
      source="foreignType"
      choices={foreignTypeChoices}
      optionText="name"
      optionValue="foreignType"
      alwaysOn
    />
  </Filter>
);

// TODO: Proper typings when it's possible to do in ts and react-admin
const Actions: React.FC<any> = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => (
  <TopToolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <RefreshButton />
  </TopToolbar>
);

export const EmbeddedSnapshotList: React.FC<any> = (props) => (
  <List {...props} filters={<EmbeddedSnapshotFilter />} actions={<Actions />} perPage={25}>
    <Datagrid expand={<EmbeddedSnapshotExpandShow />}>
      <RichTextField source="revision.title" sortable={false} />

      <SelectField
        source="foreignType"
        choices={foreignTypeChoices}
        optionText="name"
        optionValue="foreignType"
      />

      <DateTimeField source="lastPublishedAt" sortable={false} />

      <ReferenceField source="currentEditorId" reference="editorAccounts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>

      <EditButton onClick={(e: React.MouseEvent) => e.stopPropagation()} />
    </Datagrid>
  </List>
);
