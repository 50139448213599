import * as React from 'react';
import * as Slate from 'slate';
import * as SlateReact from 'slate-react';
import * as SlateHistory from 'slate-history';
import { DocumentSpec } from '../../slate/documentSpec';
import { Plugin } from '../../slate/plugins/types';
import { SlateFormattingCharacter } from './SlateFormattingCharacter';

export interface SlateEditorProps {
  value?: Slate.Node[];
  documentSpec: DocumentSpec;
  onChange: (value: Slate.Node[]) => void;
  readOnly?: boolean;
  onFocusChange?: (focused: boolean) => void;
  setEditor?: (editor: SlateReact.ReactEditor) => void;
  spellCheck?: boolean;
}

const withDocumentSpec = (
  documentSpec: DocumentSpec,
  editor: SlateReact.ReactEditor
): SlateReact.ReactEditor => {
  const { isVoid, isInline, insertData } = editor;

  editor.isVoid = (element: Slate.Element): boolean => {
    return documentSpec.nodeRules?.[(element as any).type]?.isVoid || isVoid(element);
  };

  editor.isInline = (element: Slate.Element): boolean => {
    const type: string = (element as any).type;
    return !!documentSpec.inlineRules[type] || isInline(element);
  };

  editor.insertData = (data: DataTransfer) => {
    const fragment = data.getData('application/x-slate-fragment');

    if (fragment) {
      insertData(data);
      return;
    }

    const html = data.getData('text/html');

    if (html) {
      try {
        const sanitizedHtml = html.replace(/\s+/g, ' ');
        const deserializedHtml = documentSpec.htmlSerializer.deserialize(sanitizedHtml, true);
        Slate.Transforms.insertFragment(editor, deserializedHtml, { voids: true });
        return;
      } catch (error) {
        console.error('Failed to deserialize pasted data: ', data, error);
      }
    }
    insertData(data);
  };

  return editor;
};

const FORMATTING_CHARACTERS_REGEX = /\u00ad+/g;

const decorateFormattingCharacters = ([node, path]: Slate.NodeEntry): Array<Slate.Range> => {
  if (!Slate.Text.isText(node)) {
    return [];
  }

  const decorations: Array<Slate.Range> = [];

  for (const match of node.text.matchAll(FORMATTING_CHARACTERS_REGEX)) {
    const { index } = match;
    if (index) {
      decorations.push({
        formattingCharacter: match[0],
        anchor: { path, offset: index },
        focus: { path, offset: index + match.length },
      });
    }
  }

  return decorations;
};

const SlateEditor = React.forwardRef<{}, SlateEditorProps>((props, ref) => {
  const {
    value,
    documentSpec,
    onChange,
    readOnly = false,
    onFocusChange,
    setEditor,
    spellCheck,
  } = props;

  const editor = React.useMemo(() => {
    const reactEditor = SlateReact.withReact(SlateHistory.withHistory(Slate.createEditor()));
    const customEditor = withDocumentSpec(documentSpec, reactEditor);
    // Override editor with plugins
    const pluginEnhancedEditor = documentSpec.plugins.reduce(
      (editor: SlateReact.ReactEditor, plugin: Plugin) => plugin.withEditor?.(editor) ?? editor,
      customEditor
    );

    setEditor?.(pluginEnhancedEditor);
    return customEditor;

    // Editor can only be set once or else it will cause problems as value might be out of sync with editor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderElement = React.useCallback(
    (renderProps: SlateReact.RenderElementProps) => {
      const element = documentSpec.renderElement(editor, renderProps);
      if (!element)
        throw Error(`Unexpected null React element for Slate element: ${renderProps.element}`);

      // Let plugin process element that will be rendered, e.g. add properties
      return documentSpec.plugins.reduce(
        (el, plugin) => plugin.renderElement?.(renderProps, el) ?? el,
        element
      );
    },
    [documentSpec, editor]
  );

  const renderLeaf = React.useCallback(
    (renderProps: SlateReact.RenderLeafProps) => {
      const { leaf } = renderProps;
      if (leaf.formattingCharacter) {
        const character = leaf.formattingCharacter as string;
        return (
          <SlateFormattingCharacter character={character} {...renderProps.attributes}>
            {renderProps.children}
          </SlateFormattingCharacter>
        );
      }

      const element = documentSpec.renderLeaf(editor, renderProps);
      if (!element)
        throw Error(`Unexpected null React leaf for Slate leaf value: ${renderProps.leaf}`);

      // Let plugin process leaf that will be rendered, e.g. add properties
      return documentSpec.plugins.reduce(
        (el, plugin) => plugin.renderLeaf?.(renderProps, el) ?? el,
        element
      );
    },
    [documentSpec, editor]
  );

  if (!value) return null;

  return (
    <SlateReact.Slate ref={ref} editor={editor} value={value} onChange={onChange}>
      <SlateReact.Editable
        spellCheck={spellCheck}
        readOnly={readOnly}
        decorate={decorateFormattingCharacters}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onFocus={() => onFocusChange?.(true)}
        onSelect={() => onFocusChange?.(true)}
        onBlur={() => onFocusChange?.(false)}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          documentSpec.plugins.forEach((plugin) => {
            plugin.onKeyDown?.(editor, e);
          });
        }}
      />
    </SlateReact.Slate>
  );
});

export default SlateEditor;
