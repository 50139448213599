import * as Slate from 'slate';

import { Plugin } from './types';
import * as tags from '../tags';
import * as Selector from '../utils/selector';
import { processNodes } from '../utils/process';
import { insertChildIfMissing } from '../utils/transforms';

const byline = {
  type: tags.BLOCK_BYLINE,
  children: [{ text: '' }],
};

const createArticleNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_ARTICLE,
    children,
  };
};

const createHeaderNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_HEADER,
    children: children.length > 0 ? children : [{ text: '' }],
  };
};

const createSectionNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_SECTION,
    className: ['content'],
    children: children.length > 0 ? children : [createSectionBodyNode(children)],
  };
};

const createSectionBodyNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_SECTION,
    className: ['body'],
    children: [
      byline,
      ...(children.length > 0
        ? children
        : [{ type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }]),
    ],
  };
};

export const createAsideNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_ASIDE,
    className: 'facts',
    children: children.length > 0 ? children : [{ text: '' }],
  };
};

const createTemplateNode = (
  headerNodes: Slate.Node[],
  contentNodes: Slate.Node[],
  asideNodes: Slate.Node[]
): Slate.Node => {
  return createArticleNode([
    createHeaderNode(headerNodes),
    createSectionNode([
      createSectionBodyNode(contentNodes),
      ...(asideNodes.length > 0 ? [createAsideNode(asideNodes)] : []),
    ]),
  ]);
};

const postProcessNodesBeforeSerialization = (nodes: Slate.Node[]) => {
  // Add p after last fact that should not be placed into aside on save
  return processNodes(nodes, {
    unwrap: (node) => {
      if (
        node.type === tags.BLOCK_ARTICLE ||
        node.type === tags.BLOCK_SECTION ||
        node.type === tags.BLOCK_HEADER ||
        node.type === tags.BLOCK_ASIDE
      ) {
        if ((node.className as string[])?.includes('body')) {
          if (
            node.children[node.children.length - 1].type === tags.BLOCK_FACTS ||
            node.children[node.children.length - 1].type === tags.BLOCK_RECOMMENDED_PAGES_MODULE
          ) {
            // Make sure to add paragraph as last child so editor does not move facts to aside
            return [...node.children, { type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }];
          }
        }
        return node.children;
      }
    },
    delete: (node) => node.type === tags.BLOCK_BYLINE,
  });
};

const preProcessNodesAfterDeserialization = (nodes: Slate.Node[]) => {
  const selector = new Selector.ChildSelector(nodes, {
    articleHeader: (...args) =>
      Selector.matchType(tags.BLOCK_HEADING_ONE, ...args) && Selector.matchIndex(0, ...args),
    articleFigure: (...args) =>
      Selector.matchType(tags.BLOCK_FIGURE, ...args) && Selector.matchIndex(1, ...args),
    lastFacts: (...args) =>
      Selector.matchType(tags.BLOCK_FACTS, ...args) ||
      Selector.matchType(tags.BLOCK_RECOMMENDED_PAGES_MODULE, ...args) ||
      Selector.matchType(tags.BLOCK_END_PARAGRAPH, ...args),
  });
  const articleHeader = selector.selectFirst('articleHeader');
  const articleFigure = selector.selectFirst('articleFigure');
  const lastFacts = selector.select('lastFacts');
  const articleContent = selector.rest();

  return [
    createTemplateNode(
      [...(articleHeader ? [articleHeader] : []), ...(articleFigure ? [articleFigure] : [])],
      articleContent,
      lastFacts
    ),
  ];
};

export default (): Plugin => {
  return {
    key: 'news-article-plugin',
    preProcessNodesAfterDeserialization,
    postProcessNodesBeforeSerialization,
    withEditor: (editor) => {
      const { normalizeNode, getFragment } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        // Force news article layout
        const [, path] = entry;

        // We will not be able to delete `article/section` as byline is now force added
        if (path.toString() === [0, 1, 0].toString()) {
          insertChildIfMissing(editor, entry, 0, tags.BLOCK_BYLINE, byline);
        }

        normalizeNode(entry);
      };

      editor.getFragment = () => {
        return postProcessNodesBeforeSerialization(getFragment());
      };

      return editor;
    },
  };
};
