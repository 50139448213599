import * as t from 'io-ts';
import * as Types from './types';

import { halJsonCrudApiProvider } from './apiUtil';

export interface AccountManager {
  id: string;
  name: string;
}

const ApiIncomingTypeV = t.type({
  id: t.string,
  name: t.string,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<AccountManager> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaSubscriptionBackend}/admin/v1/accountManagers`,
    halListName: 'accountManagerList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (x) => x,
    toApiMapper: (x) => x,
    toApiPartialMapper: (x) => x,
    referenceParams: {},
    filterParams: {},
    sortFields: [],
  });
