import * as React from 'react';
import * as Slate from 'slate';

import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SlateSimpleMenu from '../../components/SlateInput/SlateSimpleMenu';

import * as tags from '../tags';
import { Plugin } from './types';

import { insertNodeAfterAboveByType, wrapChildNodes } from '../utils/transforms';
import { getAboveByType, getNextSiblingNodes, isBlockAboveEmpty } from '../utils/query';

const toggleQuote = (editor: Slate.Editor, tag: string) => {
  if (getAboveByType(editor, tags.INLINE_QUOTE)) {
    Slate.Transforms.unwrapNodes(editor, {
      match: (n) => n.type === tags.INLINE_QUOTE,
    });
  } else if (tag === tags.INLINE_QUOTE) {
    Slate.Transforms.wrapNodes(editor, {
      type: tags.INLINE_QUOTE,
      children: [{ text: '' }],
    });
  } else if (getAboveByType(editor, tag)) {
    Slate.Transforms.wrapNodes(editor, {
      type: tags.BLOCK_PARAGRAPH,
      children: [{ text: '' }],
    });
    Slate.Transforms.unwrapNodes(editor, { match: (n) => n.type === tag });
  } else if (getAboveByType(editor, [tags.BLOCK_QUOTE, tags.BLOCK_QUOTE_DISPOSITION])) {
    Slate.Transforms.unwrapNodes(editor, {
      match: (n) => n.type === tags.BLOCK_QUOTE || n.type === tags.BLOCK_QUOTE_DISPOSITION,
      mode: 'highest',
    });
    Slate.Transforms.wrapNodes(editor, {
      type: tag,
      children: [{ text: '' }],
    });
  } else {
    Slate.Transforms.wrapNodes(editor, {
      type: tag,
      children: [{ text: '' }],
    });
  }
};

const SlateMenuItem = (
  editor: Slate.Editor,
  icon: React.ComponentType,
  selected: boolean,
  tag: string,
  tooltip: string
) => {
  return (
    <Tooltip key={tag} title={tooltip}>
      <MenuItem selected={selected} onMouseDown={() => toggleQuote(editor, tag)}>
        {React.createElement(icon)}
      </MenuItem>
    </Tooltip>
  );
};

const InlineQuote = () =>
  React.createElement(
    'svg',
    {
      focusable: 'false',
      height: 24,
      width: 24,
      'aria-hidden': true,
    },
    React.createElement(
      'text',
      {
        x: 0,
        y: 15,
      },
      '«»'
    )
  );

const key = 'quote-plugin';

const QuoteButton: React.FC<{ editor: Slate.Editor }> = (props) => {
  const hasQuoteCenter = !!getAboveByType(props.editor, tags.BLOCK_QUOTE);
  const hasDispositionQuote = !!getAboveByType(props.editor, tags.BLOCK_QUOTE_DISPOSITION);
  const hasInlineQuote = !!getAboveByType(props.editor, tags.INLINE_QUOTE);

  return React.createElement(SlateSimpleMenu, {
    key,
    isActive: hasQuoteCenter || hasDispositionQuote || hasInlineQuote,
    Icon: FormatQuoteIcon,
    message: 'slate.contextMenu.quote',
    menuItems: [
      SlateMenuItem(props.editor, FormatAlignCenterIcon, hasQuoteCenter, tags.BLOCK_QUOTE, 'Sitat'),
      SlateMenuItem(
        props.editor,
        MenuOpenIcon,
        hasDispositionQuote,
        tags.BLOCK_QUOTE_DISPOSITION,
        'Lang sitat'
      ),
      SlateMenuItem(props.editor, InlineQuote, hasInlineQuote, tags.INLINE_QUOTE, 'Inline sitat'),
    ],
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <QuoteButton key={key} editor={editor} />;
      },
    },
    withEditor: (editor) => {
      const { normalizeNode, insertBreak } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node, path] = entry;

        if (node.type === tags.BLOCK_QUOTE || node.type === tags.BLOCK_QUOTE_DISPOSITION) {
          // Ensure that all child nodes are paragraph blocks
          wrapChildNodes(
            editor,
            path,
            { type: tags.BLOCK_PARAGRAPH, children: [] },
            (node) => node.type !== tags.BLOCK_PARAGRAPH
          );
        }

        normalizeNode(entry);
      };

      editor.insertBreak = () => {
        const quoteNodeEntry = getAboveByType(editor, [
          tags.BLOCK_QUOTE,
          tags.BLOCK_QUOTE_DISPOSITION,
        ]);

        if (
          !quoteNodeEntry ||
          quoteNodeEntry[0].children.length === 1 ||
          !isBlockAboveEmpty(editor)
        ) {
          insertBreak();
          return;
        }
        const selection = editor.selection?.focus;
        if (selection) {
          // Allow user to put multiple empty paragraphs
          const nextNodes = getNextSiblingNodes(quoteNodeEntry, selection.path);
          if (nextNodes.length > 0) {
            insertBreak();
            return;
          }

          Slate.Transforms.removeNodes(editor, { at: selection });
          insertNodeAfterAboveByType(
            editor,
            {
              type: tags.BLOCK_PARAGRAPH,
              children: [{ text: '' }],
            },
            [tags.BLOCK_QUOTE, tags.BLOCK_QUOTE_DISPOSITION]
          );
        }
      };

      return editor;
    },
  };
};
