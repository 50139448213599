import React from 'react';
import URI from 'urijs';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { linkToRecord, Authenticated } from 'react-admin';
import { saveAs } from 'file-saver';
import BEMHelper from 'react-bem-helper';

import * as ApiUrls from '../../dataProvider/apiUrls';

import * as xopusApiHelpers from '../../xopus/xopusApiHelpers';
import { EditorWindow, Scope } from '../../xopus/types';

import * as editorHelpers from '../../util/editorHelpers';
import * as commentaryActions from '../../state/commentary/commentaryActions';
import { CommentariesState } from '../../state/commentary/commentaryReducers';
import * as expressionActions from '../../state/expressions/expressionActions';
import { ExpressionState } from '../../state/expressions/expressionReducers';
import * as excerptActions from '../../state/excerpts/excerptActions';

import { Commentary } from '../../state/commentary/types';
import {
  CommentaryMetadataForAllExpressionFragments,
  Toc,
  TocItem,
} from '../../state/expressions/types';
import Aside from './aside/Aside';
import CommentaryInfo from './CommentaryInfo';

import '../../styles/commentariesEditor/commentariesEdit.css';
import { CurrentUser } from '../../state/currentUser/types';
import { DateTime } from 'luxon';
import { expressionIdentifierToUri, workIdentifierToUri } from '../../util/legalIdentifierUtils';
import { LegalExpressionIdentifier, VersionStatus } from '../../state/legalDocuments/types';

const classes = new BEMHelper({
  name: 'commentariesEdit',
});

interface ReduxState {
  expressions: ExpressionState;
  commentaries: CommentariesState;
  currentUser: CurrentUser | null;
}

interface Match {
  params: LegalExpressionIdentifier & { publicationStatus: VersionStatus };
}

interface History {
  replace(params: { search: string }): void;
  push(path: string): void;
}

interface Query {
  status: VersionStatus;
  fragmentId: string;
}

interface OwnProps {
  match: Match;
  history: History;
  location: {
    search: string;
  };
}

interface StateProps {
  commentary: Commentary;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
  currentUser: CurrentUser | null;
  identifier: string;
  isLoadingCommentary: boolean;
  toc: Toc;
  query: Query;
  match: Match;
  history: History;
  location: any;
}

interface DispatchProps {
  publishComments: typeof commentaryActions.publishComments;
  overwriteLastReviewed: typeof commentaryActions.overwriteLastReviewed;
  getCommentary: typeof commentaryActions.getCommentary;
  saveCommentary: typeof commentaryActions.saveCommentary;
  getCommentaryMeta: typeof commentaryActions.getCommentaryMeta;
  generateExpressionToc: typeof expressionActions.generateExpressionToc;
  createFromLatestPublished: typeof commentaryActions.createFromLatestPublished;
  resetCommentary: typeof commentaryActions.resetCommentary;
  getExcerpt: typeof excerptActions.getExcerpt;
}

type Props = StateProps & DispatchProps;

interface State {
  xopusEditorIsConfigured: boolean;
  xopusCanvasIsLoaded: boolean;
}

interface EditorConfig {
  updateXopusCanvasIsLoaded: () => void;
  loadConfig: () => void;
  identifier: string | null;
}

const UNSAVED_CHANGES_PROMPT =
  'Du har foretatt endringer som ikke er lagret. Vil du forkaste endringene dine?';

class CommentariesEdit extends React.Component<Props, State> {
  editorConfig: EditorConfig;

  constructor(props: Props) {
    super(props);

    this.state = {
      xopusCanvasIsLoaded: false,
      xopusEditorIsConfigured: false,
    };
    this.editorConfig = {
      identifier: null,
      loadConfig: this.configureEditor,
      updateXopusCanvasIsLoaded: () => {
        if (!this.state.xopusCanvasIsLoaded) {
          this.setState({ xopusCanvasIsLoaded: true });
        }
      },
    };

    (window as EditorWindow & typeof window).editorConfig = this.editorConfig;
  }

  componentDidMount() {
    const {
      query: { fragmentId },
    } = this.props;

    this.handleTocGeneration();

    if (fragmentId) {
      this.handleGetCommentary(fragmentId);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { commentary: prevCommentary } = prevProps;
    const { commentary: currCommentary } = this.props;
    const { xopusCanvasIsLoaded: prevXopusCanvasIsLoaded } = prevState;
    const { xopusCanvasIsLoaded: currXopusCanvasIsLoaded, xopusEditorIsConfigured } = this.state;

    if (xopusEditorIsConfigured) {
      const commentaryIsEmpty = Object.keys(currCommentary).length === 0;

      if (!commentaryIsEmpty && prevCommentary.fragmentId !== currCommentary.fragmentId) {
        xopusApiHelpers.loadDocument('');
      }

      if (!prevXopusCanvasIsLoaded && currXopusCanvasIsLoaded) {
        this.setImageBasePath();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCommentary();
  }

  setImageBasePath = () => {
    const {
      query: { fragmentId },
    } = this.props;

    if (fragmentId) {
      const imageBasePath = URI(ApiUrls.juridikaStaticContentFilesS3BucketUrl())
        .segment('image')
        .toString();

      xopusApiHelpers.setViewParam('image_base_path', imageBasePath);
    }
  };

  updateQueryFragmentId = (fragmentId: string) => {
    this.props.history.replace({
      search: URI.buildQuery({
        ...this.props.query,
        fragmentId,
      }),
    });
  };

  handleGetCommentary = (fragmentId: string) => {
    const expressionUri = expressionIdentifierToUri(this.props.match.params);

    this.props.getCommentary(
      { expressionUri, fragmentId },
      {
        successCallback: () => this.updateQueryFragmentId(fragmentId),
        createNewCommentaryCallback: () =>
          this.handleCreateFromLatestPublished(
            expressionUri,
            fragmentId,
            this.props.currentUser?.name!!
          ),
      }
    );
  };

  handleGetCommentaryMeta = () => {
    this.props.getCommentaryMeta(expressionIdentifierToUri(this.props.match.params));
  };

  handleTocGeneration = () => {
    const {
      query,
      match: { params },
    } = this.props;

    this.props.generateExpressionToc({
      uri: expressionIdentifierToUri(params),
      publicationStatus: query.status,
    });
    this.handleGetCommentaryMeta();
  };

  generateEditorQuery = () => {
    const { currentUser } = this.props;
    return {
      editor: currentUser ? currentUser.name : null,
    };
  };

  handleSelectFragment = (fragmentId: string) => {
    if (Object.keys(this.props.commentary).length !== 0 && xopusApiHelpers.hasUnsavedChanges()) {
      editorHelpers.getConfirmation(
        UNSAVED_CHANGES_PROMPT,
        (isConfirmed: boolean) => isConfirmed && this.handleGetCommentary(fragmentId)
      );
    } else {
      this.handleGetCommentary(fragmentId);
    }
  };

  handleSave = (_: string, xmlDocument: XMLDocument) =>
    this.props.saveCommentary(
      {
        expressionUri: expressionIdentifierToUri(this.props.match.params),
        fragmentId: this.props.query.fragmentId,
        xmlDocument,
        editor: this.props.currentUser!!.name,
      },
      () => {}
    );

  handleCreateFromLatestPublished = (expressionUri: string, fragmentId: string, editor: string) => {
    editorHelpers.getConfirmation(
      'Det finnes ikke noe kommentar for dette elementet. Vil du lage en ny kommentar?',
      (isConfirmed: boolean) =>
        isConfirmed &&
        this.props.createFromLatestPublished(
          { expressionUri, fragmentId, editor },
          {
            successCallback: () => this.updateQueryFragmentId(fragmentId),
          }
        )
    );
  };

  loadXMLFunction = () => {
    const { commentary } = this.props;

    xopusApiHelpers.setScope('isPublished', commentary.isPublished);

    return xopusApiHelpers.createNativeXMLDocument(commentary.content);
  };

  handleCreateAsCopy = () => {
    const expressionUri = expressionIdentifierToUri(this.props.match.params);
    this.handleCreateFromLatestPublished(
      expressionUri,
      this.props.query.fragmentId,
      this.props.currentUser?.name!!
    );
  };

  saveDocument = (blob: Blob, fragment: string, format: string) => {
    const {
      match: {
        params: { date, seq },
      },
    } = this.props;
    const fileName = `utdrag_fra_editor__${date}-${seq}__${fragment}.${format.toLowerCase()}`;

    saveAs(blob, fileName);
  };

  downloadExcerpt = (shouldDownloadAllFragments: boolean, format: excerptActions.ExcerptFormat) => {
    const {
      match: {
        params: { subtype, date, seq, inForceDate },
      },
      query,
      getExcerpt,
    } = this.props;
    const fragment = shouldDownloadAllFragments ? 'body' : query.fragmentId;

    getExcerpt({
      excerptRequest: {
        actDate: date,
        actSeq: seq,
        actStatus: query.status,
        actType: subtype,
        inForceAt: inForceDate,
        fragmentIdsIncludingDescendants: [fragment],
        fragmentIdsExcludingDescendants: [],
        excerptParams: {
          format,
          includeDraftComments: true,
        },
      },
      successCallback: ({ payload }: excerptActions.SuccessCallbackParams) =>
        this.saveDocument(payload.blob, fragment, format),
    });
  };

  excerptCommand = (shouldDownloadAllFragments: boolean, format: excerptActions.ExcerptFormat) => ({
    execute: () => {
      this.downloadExcerpt(shouldDownloadAllFragments, format);
      return;
    },
    getEnabled: (scope: Scope) => true,
  });

  configureEditor = () => {
    const {
      currentUser,
      match: { params },
    } = this.props;

    xopusApiHelpers.setScopeListener('hasUnsavedChanges', editorHelpers.checkIfDocumentIsPublished);
    xopusApiHelpers.addCommand(
      'DownloadPdfExcerptOfAllFragmentsCommand',
      this.excerptCommand(true, 'PDF')
    );
    xopusApiHelpers.addCommand(
      'DownloadPdfExcerptOfCurrentFragmentCommand',
      this.excerptCommand(false, 'PDF')
    );
    xopusApiHelpers.addCommand(
      'DownloadDocxExcerptOfAllFragmentsCommand',
      this.excerptCommand(true, 'DOCX')
    );
    xopusApiHelpers.addCommand(
      'DownloadDocxExcerptOfCurrentFragmentCommand',
      this.excerptCommand(false, 'DOCX')
    );
    xopusApiHelpers.addCommand(
      'PublishDocumentCommand',
      editorHelpers.publishDocumentCommand(() =>
        this.props.publishComments(
          {
            expressionUri: expressionIdentifierToUri(this.props.match.params),
            fragmentIds: [this.props.query.fragmentId],
            editor: currentUser!!.name,
          },
          this.handleGetCommentaryMeta
        )
      )
    );

    // Use true as default to disable publish button when no document
    // is loaded.
    xopusApiHelpers.declareScopeVariable('isPublished', true);

    xopusApiHelpers.setUserNameForChangeTracking(currentUser ? currentUser.name : '');

    // The exit url needs to be set for the close button to be displayed.
    // but will never be used since the setExitFunction is called first.
    xopusApiHelpers.setExitURL('/');
    xopusApiHelpers.setExitFunction(() =>
      this.props.history.push(linkToRecord('/legalDocuments', workIdentifierToUri(params), 'show'))
    );

    xopusApiHelpers.setLoadXMLFunction(this.loadXMLFunction);
    xopusApiHelpers.setSaveXMLFunction(this.handleSave);

    this.setState({
      xopusEditorIsConfigured: true,
    });
  };

  handlePublishCommentaries = (fragmentIds: string[]) => {
    const expressionUri = expressionIdentifierToUri(this.props.match.params);
    this.props.publishComments(
      { expressionUri, fragmentIds, editor: this.props.currentUser!!.name },
      this.handleGetCommentaryMeta
    );
  };

  handleOverwriteLastReviewed = (fragmentIds: string[], reviewDate: DateTime) => {
    const expressionUri = expressionIdentifierToUri(this.props.match.params);
    this.props.overwriteLastReviewed(
      {
        expressionUri,
        fragmentIds,
        editor: this.props.currentUser!!.name,
        date: reviewDate.toISODate(),
      },
      this.handleGetCommentaryMeta
    );
  };

  render() {
    const { commentaryMeta, commentary, query, toc, location } = this.props;

    const reduceTocList = (list: Toc): TocItem[] =>
      list.reduce(
        (acc: TocItem[], item: TocItem) => [...acc, item, ...reduceTocList(item.children)],
        []
      );

    const activeFragment = reduceTocList(toc).find(
      (item: TocItem) => item.eId === query.fragmentId
    );

    const fragmentMeta = activeFragment && commentaryMeta[activeFragment.eId];
    const xopusCanvasUrl = URI('')
      .segment(process.env.PUBLIC_URL)
      .segment('xopus_5.4.4')
      .segment('commentaries')
      .segment('start.html')
      .toString();

    return (
      <Authenticated location={location}>
        <div {...classes()}>
          <div {...classes('wrapper')}>
            <Prompt
              message={() => (xopusApiHelpers.hasUnsavedChanges() ? UNSAVED_CHANGES_PROMPT : true)}
            />
            <Aside
              activeFragmentId={query.fragmentId}
              commentaryMeta={commentaryMeta}
              handleSelectFragment={this.handleSelectFragment}
              handlePublishCommentaries={this.handlePublishCommentaries}
              handleOverwriteLastReviewed={this.handleOverwriteLastReviewed}
              legalDocumentStructure={toc}
            />
            {activeFragment && (
              <iframe
                {...classes('xopusIframe')}
                title="editor"
                src={xopusCanvasUrl}
                name="xopus"
              />
            )}
            <CommentaryInfo
              activeFragment={activeFragment}
              commentary={commentary}
              fragmentMeta={fragmentMeta}
              handleCreateAsCopy={this.handleCreateAsCopy}
            />
          </div>
        </div>
      </Authenticated>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const { expressions, commentaries, currentUser } = state;
  const {
    history,
    match: {
      params: { date, seq },
    },
    location,
  } = ownProps;
  const { search } = location;
  const query = URI.parseQuery(search) as Query;
  const exprUri = expressionIdentifierToUri(ownProps.match.params);

  return {
    identifier: `${date}-${seq}`,
    history,
    toc: expressions.tocs[exprUri] || [],
    commentaryMeta: expressions.commentaryMeta[exprUri] || [],
    query,
    commentary: commentaries.commentary,
    currentUser,
    isLoadingCommentary: commentaries.isLoading,
    location,
  };
};

const mapDispatchToProps = {
  publishComments: commentaryActions.publishComments,
  overwriteLastReviewed: commentaryActions.overwriteLastReviewed,
  getCommentary: commentaryActions.getCommentary,
  saveCommentary: commentaryActions.saveCommentary,
  getCommentaryMeta: commentaryActions.getCommentaryMeta,
  generateExpressionToc: expressionActions.generateExpressionToc,
  createFromLatestPublished: commentaryActions.createFromLatestPublished,
  resetCommentary: commentaryActions.resetCommentary,
  getExcerpt: excerptActions.getExcerpt,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentariesEdit);
