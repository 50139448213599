import h from 'hastscript';

import { DocumentSpec } from '../documentSpec';
import * as tags from '../tags';
import SingleLinePlugin from '../plugins/SingleLinePlugin';
import SpecialCharacterPlugin from '../plugins/SpecialCharacterPlugin';

export default DocumentSpec.createSpec({
  plugins: [SingleLinePlugin(), SpecialCharacterPlugin()],
  hypertextRules: {
    blocks: {
      fragment: [
        {
          type: tags.BLOCK_FRAGMENT,
          render: 'span',
        },
      ],
    },
    marks: {},
    inlines: {},
  },
  serializationFormat: 'plaintext',
});

export const DefaultHast = () => h('fragment', {});
