import * as React from 'react';
import * as Slate from 'slate';

import { Plugin } from '../types';
import * as tags from '../../tags';
import LinkButton from './LinkButton';
import LinkPopup from './LinkPopup';

const key = 'link-plugin';

export default (hrefAttribute: string = 'href'): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <LinkButton editor={editor} hrefAttribute={hrefAttribute} />;
      },
    },
    popup: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean,
        slateEditorFocused: boolean
      ) => {
        return (
          <LinkPopup
            editor={editor}
            slateEditorFocused={slateEditorFocused}
            slateEditorChanged={slateEditorChanged}
            hrefAttribute={hrefAttribute}
          />
        );
      },
    },
    withEditor: (editor) => {
      const { normalizeNode } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node, path] = entry;

        // All links should have valid url
        if (
          Slate.Element.isElement(entry) &&
          node.type === tags.INLINE_LINK &&
          typeof node.url !== 'string'
        ) {
          // Null is not a valid value for a url
          Slate.Transforms.unwrapNodes(editor, { at: path });
          return;
        }

        normalizeNode(entry);
      };

      return editor;
    },
  };
};
