import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  useInput,
  Labeled,
  InputProps,
  SelectInput,
  EditProps,
  CreateProps,
  SimpleFormProps,
} from 'react-admin';

import { ContributionsInput } from '../staticContentCommon/ContributionsInput';
import {
  ContractAttachment,
  ContractAttachmentKey,
  ContractSnapshot,
  createEmptyContractSnapshot,
  getAttachment,
} from '../../dataProvider/contractSnapshotsProvider';
import { ContractAttachments } from './ContractAttachmentForm';
import SlateTextInput from '../SlateTextInput';
import SlugInput, { useGeneratedSlugState } from '../SlugInput';
import aboutContractSpec from '../../slate/documentSpecs/aboutContractSpec';

const isMutable = (record: ContractSnapshot | undefined) => {
  if (!record) return true;
  return record.currentEditorIsCurrentUser !== false;
};

const areAttachmentsValid = () => (attachments: ContractAttachment[] | undefined) =>
  getAttachment(attachments || null, ContractAttachmentKey.WORD)
    ? undefined
    : 'missing word attachment';

const KonraktCategorySelectField = (props: InputProps) => {
  const { input } = useInput(props);
  const { choices } = props;

  React.useEffect(() => {
    // Automatically select 'contract' category
    if (choices.length > 0) {
      input.onChange(choices[0].id);
    }
  }, [input, choices]);

  return <SelectInput {...props} disabled />;
};

const PageSnapshotForm: React.FC<Omit<SimpleFormProps, 'children'>> = ({
  save,
  redirect = true,
  record,
  ...props
}) => {
  const disabled = !isMutable(record);
  const { generatedSlug, setGeneratedSlug, generateSlug } = useGeneratedSlugState();

  React.useEffect(() => {
    if (record && record.slug) setGeneratedSlug(record.slug);
  }, [record, setGeneratedSlug]);

  return (
    <SimpleForm
      {...props}
      variant="standard"
      margin="normal"
      record={record}
      redirect={redirect}
      sanitizeEmptyValues={false}
      save={(data: ContractSnapshot) => {
        save({ ...data, slug: generatedSlug });
      }}
    >
      <ReferenceInput
        source="revision.contentCategoryId"
        reference="contentCategories"
        disabled={disabled}
        validate={required()}
        filter={{ semanticKey: 'kontrakt' }}
      >
        <KonraktCategorySelectField source="revision.name" />
      </ReferenceInput>

      <TextInput
        multiline
        fullWidth
        source="revision.title"
        disabled={disabled}
        validate={required()}
        onChange={generateSlug}
      />

      <SlugInput
        name="generatedSlug"
        value={generatedSlug}
        onChange={generateSlug}
        isRequired
        source="generatedSlug"
      />

      <ContributionsInput
        resourceName="contractSnapshots"
        disabled={disabled}
        showOccupationField={true}
      />

      <Labeled label="resources.contractSnapshots.fields.documentAttachments.name" fullWidth>
        <ContractAttachments source="documentAttachments" validate={areAttachmentsValid()} />
      </Labeled>

      <Labeled key="revision.hast" label="Om malen" fullWidth>
        <SlateTextInput
          source="revision.hast"
          documentSpec={aboutContractSpec}
          disabled={disabled}
        />
      </Labeled>
    </SimpleForm>
  );
};

export const ContractSnapshotCreate: React.FC<CreateProps> = (props) => (
  <Create {...props} title={<span>Ny side</span>}>
    <PageSnapshotForm initialValues={createEmptyContractSnapshot()} />
  </Create>
);

const EditTitle: React.FC<{ record?: ContractSnapshot }> = ({ record: snapshot }) => (
  <span>Revider {`"${snapshot!!.revision.title}"`}</span>
);

export const ContractSnapshotEdit: React.FC<EditProps> = (props) => (
  <Edit undoable={false} title={<EditTitle />} {...props}>
    <PageSnapshotForm />
  </Edit>
);
