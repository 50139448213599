import * as React from 'react';
import * as ReactForm from 'react-final-form';
import uuidv4 from 'uuid/v4';

import { ImageEditor, ImageValue } from './ImageEditor';

interface ReactAdminFieldProps {
  source: string;
}

const RenderComponent: React.FC<
  ReactForm.FieldRenderProps<string> & React.InputHTMLAttributes<HTMLInputElement>
> = ({ input: { value, onChange } }) => {
  const uuid = React.useRef(uuidv4());

  const url = value;

  const handleChange = (value: ImageValue) => {
    onChange(value.src);
  };

  const editorValue: ImageValue = {
    src: url,
    fileid: null,
    alt: null,
    title: null,
  };

  return (
    <ImageEditor
      uuid={uuid.current}
      value={editorValue}
      onChange={handleChange}
      onClear={() => onChange(null)}
    />
  );
};

export const ImageInputField: React.FC<ReactAdminFieldProps> = ({ source }) => {
  return <ReactForm.Field name={source} component={RenderComponent} />;
};
