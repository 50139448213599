import React from 'react';

import RefreshIcon from '@material-ui/icons/Refresh';
import { InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import uuidv4 from 'uuid/v4';
import { useForm } from 'react-final-form';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  DateInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ChipField,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  required,
} from 'react-admin';
import { RegistrationLink } from './RegistrationLink';
import { Customer } from '../../../dataProvider/customersProvider';
import { ProductsAutoSuggestQuery, AutoSuggestData } from './ProductsAutoSuggestQuery';
import { defaultProductIdsForSubscriptionPackages } from '../../../util/productsAutosuggestUtil';
import DownloadUsersButton from './DownloadUsersButton';

export const PackagesInput: React.FC<{
  subscriptionPackageId: string | null;
  subscriptionStatusId: string | null;
  autoSuggestData: AutoSuggestData;
}> = ({ subscriptionPackageId, subscriptionStatusId, autoSuggestData, ...rest }) => {
  const form = useForm();
  const suggestProductIds = React.useMemo(() => {
    return defaultProductIdsForSubscriptionPackages(
      subscriptionPackageId,
      subscriptionStatusId,
      autoSuggestData.allProducts,
      autoSuggestData.allPackages,
      autoSuggestData.allStatus
    );
  }, [
    subscriptionPackageId,
    subscriptionStatusId,
    autoSuggestData.allProducts,
    autoSuggestData.allPackages,
    autoSuggestData.allStatus,
  ]);
  React.useEffect(() => {
    form.change('products', suggestProductIds);
  }, [suggestProductIds, form]);
  return (
    <ReferenceArrayInput
      source="products"
      reference="products"
      initialValue={suggestProductIds}
      helperText="Husk å legge til produktet Utdrag på kunder som skal ha utdragsmulighet."
      allowEmpty
      {...rest}
    >
      <SelectArrayInput>
        <ChipField />
      </SelectArrayInput>
    </ReferenceArrayInput>
  );
};

const ExcerptMessage = () => (
  <span>OBS! HUSK Å LEGGE TIL PRODUKTET 'UTDRAG' PÅ KUNDER SOM SKAL HA UTDRAGSMULIGHET</span>
);

const GenerateSignupCodeForm = ({ formdata, ...rest }: any) => {
  const form = useForm();

  return (
    <TextInput
      disabled
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Generer ny kode">
              <IconButton
                aria-label="Encode URL"
                onClick={() => form.change('signupCode', uuidv4())}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      source="signupCode"
    />
  );
};

export const CustomerForm = (props: any) => (
  <TabbedForm variant="standard" margin="normal" sanitizeEmptyValues={false} {...props}>
    <FormTab label="resources.customers.titles.customerDetails">
      <TextInput disabled source="id" fullWidth />
      <ReferenceInput source="marketCategoryId" reference="marketCategory" allowEmpty>
        <SelectInput source="marketCategory.name" />
      </ReferenceInput>
      <ReferenceInput source="accountManager" reference="accountManagers" allowEmpty>
        <SelectInput source="accountManager" />
      </ReferenceInput>
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="customerNumber" validate={required()} />
      <TextInput source="contact.name" fullWidth />
      <TextInput source="contact.phone" />
      <TextInput source="contact.email" type="email" fullWidth />
      <BooleanInput label="Registreringsvarsel" source="contact.notify" />
    </FormTab>
    <FormTab label="resources.customers.titles.usersAndRegistration">
      <NumberInput source="potentialUsers" />
      <FormDataConsumer>
        {({ formData, ...rest }: any) => {
          return (
            <TextInput
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DownloadUsersButton label="Last ned" record={props.record} variant="text" />
                  </InputAdornment>
                ),
              }}
              source="numberOfUsers"
              label="Registrerte brukere"
            />
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>{(formDataProps: any) => <GenerateSignupCodeForm />}</FormDataConsumer>
      <RegistrationLink />
      <TextInput source="registrationCode" fullWidth />
    </FormTab>
    <FormTab label="resources.customers.titles.subscriptionAndProducts">
      <ReferenceInput
        source="subscriptionStatus"
        reference="subscriptionStatus"
        validate={required()}
      >
        <SelectInput source="subscriptionStatus.name" />
      </ReferenceInput>
      <DateInput source="subscriptionExpiration" />
      <ReferenceInput source="subscriptionPackageId" reference="subscriptionPackage" allowEmpty>
        <SelectInput source="subscriptionPackage.name" />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }: { formData: Customer }) => (
          <ProductsAutoSuggestQuery>
            {(autoSuggestData) => {
              const subscriptionPackageId = formData && formData.subscriptionPackageId;
              const subscriptionStatus = formData && formData.subscriptionStatus;

              return (
                <PackagesInput
                  subscriptionPackageId={subscriptionPackageId}
                  subscriptionStatusId={subscriptionStatus}
                  autoSuggestData={autoSuggestData}
                  {...rest}
                />
              );
            }}
          </ProductsAutoSuggestQuery>
        )}
      </FormDataConsumer>
      <ExcerptMessage />
    </FormTab>
  </TabbedForm>
);
