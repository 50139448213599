import * as React from 'react';
import { Show, SimpleShowLayout, ReferenceManyField, Datagrid, TextField } from 'react-admin';

import { BreadcrumbTitle } from '../BreadcrumbTitle';

import DateField from '../DateField';
import DateTimeField from '../DateTimeField';

const TextbookPublicationShow: React.FC<{}> = (props) => (
  <Show
    {...props}
    title={<BreadcrumbTitle resourceName="utgivelse" recordName={(record) => record.fullTitle} />}
  >
    <SimpleShowLayout>
      <TextField source="fullTitle" />
      <ReferenceManyField
        label="Utgaver"
        reference="textbookEditions"
        target="publicationId"
        fullWidth
      >
        <Datagrid rowClick="show">
          <TextField source="editionSequence" />

          <DateField source="originallyPublishedAt" />
          <TextField source="isbn" />

          <DateTimeField source="juridikaPublishedAt" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export default TextbookPublicationShow;
