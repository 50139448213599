import React from 'react';
import { EditButton } from 'react-admin';
import URI from 'urijs';

import { LegalExpression, LegalDocument } from '../../../state/legalDocuments/types';
import { Link, History } from '@material-ui/icons';
import { convertUriToLegacyId } from '../../../util/legalIdentifierUtils';

interface Props {
  basePath?: string;
  expression: LegalExpression;
  expressionsWithCommentaries: string[] | null;
  record?: LegalDocument;
}

export const LenkeInjectorAction = (props: Props) => {
  const { basePath, expression } = props;
  const status = expression.manifestations
    .map((version: { publicationStatus: string }) => version.publicationStatus)
    .includes('PUBLISHED')
    ? 'PUBLISHED'
    : 'DRAFT';
  return (
    <EditButton
      icon={<Link />}
      label="resources.commentaries.actions.openLinkInjector"
      to={URI(basePath ?? '')
        .segment(convertUriToLegacyId(expression.uri))
        .segment('injector')
        .search({ status })
        .toString()}
      disabled={props.record?.work!!.countryCode !== 'no'}
    />
  );
};

export const LegalChangeActions = (props: Props) => {
  const { basePath, expression } = props;
  return (
    <EditButton
      icon={<History />}
      label="Endringshistorikk"
      to={URI(basePath || '')
        .segment(expression.uri)
        .segment('changes')
        .toString()}
      disabled={props.record?.work!!.countryCode !== 'no'}
    />
  );
};

export const CommentaryEditAction = (props: Props) => {
  const {
    basePath,
    expression: { uri: exprid, manifestations, inForceDate },
    expressionsWithCommentaries,
  } = props;

  const hasCommentary =
    expressionsWithCommentaries && expressionsWithCommentaries.includes(inForceDate);
  const action = hasCommentary ? 'edit' : 'create';
  const status = manifestations
    .map((version: { publicationStatus: string }) => version.publicationStatus)
    .includes('PUBLISHED')
    ? 'PUBLISHED'
    : 'DRAFT';

  return (
    <EditButton
      // BUG: The EditButton component doesn't rerender when the label value changes.
      // Added key prop to ensure update.
      key={action}
      label={`resources.commentaries.actions.${action}`}
      to={URI(basePath ?? '')
        .segment(exprid)
        .segment('kommentarer')
        .search({ status })
        .toString()}
    />
  );
};
