import React from 'react';
import { Button, Confirm, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { useDispatch } from 'react-redux';

import { JuridikaCmsContext } from '../../util/juridikaCmsContext';
import { juridikaHeaders } from '../../dataProvider/apiUtil';

export const UploadIdmlButton: React.FC<{ processId: string; showContinuationWarning: boolean }> = (
  props
) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const [showConfirm, setShowConfirm] = React.useState(false);

  const convertUrl = `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${props.processId}/convertIdmlToBits`;

  const doConvert = async () => {
    setShowConfirm(false);
    dispatch(fetchStart());

    try {
      const response = await fetch(convertUrl, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      await response.text();
    } catch (error) {
      notify(`Problem converting to BITS: ${error}`, 'error');
    }

    dispatch(fetchEnd());
    refresh();
  };

  const handleClick = () => {
    if (props.showContinuationWarning) {
      setShowConfirm(true);
    } else {
      doConvert();
    }
  };

  return (
    <>
      <Button label="Lag BITS-format fra IDML" color="primary" onClick={handleClick}>
        <MenuBookIcon />
      </Button>
      <Confirm
        isOpen={showConfirm}
        title="Ett eller flere BITS-dokumenter eksisterer fra før"
        content={`
          Alle juridika-UUIDer blir generert på nytt, og det blir ingen naturlig kontinuitet mellom versjonene.
          Husk å slette gamle direkte-konverterte XML-filer etter hvert.
        `}
        onConfirm={doConvert}
        onClose={() => setShowConfirm(false)}
      />
    </>
  );
};

export default UploadIdmlButton;
