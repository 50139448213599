import { ActionType, createStandardAction } from 'typesafe-actions';

export interface SuccessCallbackParams {
  payload: { blob: Blob };
}

export type SuccessCallback = (params: { payload: { blob: Blob } }) => void;

export type ExcerptActions = ActionType<
  typeof getExcerpt | typeof getExcerptSuccess | typeof getExcerptFailure
>;

export type ExcerptFormat = 'PDF' | 'DOCX';
export interface CommentExcerptBody {
  actType: string;
  actDate: string;
  actSeq: number;
  inForceAt: string;
  actStatus: 'PUBLISHED' | 'DRAFT';
  fragmentIdsExcludingDescendants: string[];
  fragmentIdsIncludingDescendants: string[];
  excerptParams: {
    hideToc?: boolean;
    hideLawParagraphs?: boolean;
    hidePageNumbering?: boolean;
    hideCoverPage?: boolean;
    hideCuratedLegalSources?: boolean;
    hideCommentaryParagraphNumbers?: boolean;
    includeDraftComments?: boolean;
    format: ExcerptFormat;
  };
}

export const getExcerpt = createStandardAction('GET_EXCERPT').map(
  (data: { excerptRequest: CommentExcerptBody; successCallback: SuccessCallback }) => ({
    payload: data.excerptRequest,
    meta: {
      onSuccess: {
        callback: data.successCallback,
      },
    },
  })
);

export const getExcerptSuccess = createStandardAction('GET_EXCERPT_SUCCESS').map(
  (data: { blob: Blob; successCallback: SuccessCallback }) => ({
    payload: { blob: data.blob },
    meta: {
      callback: data.successCallback,
    },
  })
);

export const getExcerptFailure = createStandardAction('GET_EXCERPT_FAILURE').map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.excerpts.notification.get_excerpt_failure',
      level: 'warning',
    },
  },
}));
