import React from 'react';
import { saveAs } from 'file-saver';
import { Button } from '@material-ui/core';
import { juridikaHeaders } from '../dataProvider/apiUtil';
import { useNotify } from 'react-admin';

interface Props {
  url: string;
  filename: string;
  variant?: 'text' | 'outlined' | 'contained';
  label: string;
}

const DownloadFileButton: React.FC<Props> = ({ url, filename, variant, label }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const notify = useNotify();

  const download = React.useCallback(async () => {
    setIsDownloading(true);

    try {
      const response = await fetch(url, { method: 'GET', headers: juridikaHeaders() });
      if (!response.ok) {
        throw await response.text();
      }
      const blob = await response.blob();
      const blobParts = new Blob([blob], { type: 'application/octet-stream' });
      saveAs(blobParts, filename);
    } catch (error) {
      notify(`Kunne ikke laste ned: ${error}`, 'error');
    } finally {
      setIsDownloading(false);
    }
  }, [url, filename, notify]);

  return (
    <div>
      <Button variant={variant} size="small" onClick={() => download()}>
        {isDownloading ? 'LASTER NED' : label}
      </Button>
    </div>
  );
};

export default DownloadFileButton;
