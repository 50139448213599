import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify } from 'react-admin';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../../dataProvider/apiUtil';

export const CopyFileToClipboardButton: React.FC<{ label: string; url: string }> = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const copyToClipboard = async () => {
    dispatch(fetchStart());

    try {
      const response = await fetch(props.url, {
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      const text = await response.text();
      await navigator.clipboard.writeText(text);
      notify('Kopiert til utklippstavle.');
    } catch (error) {
      notify(`Problem downloading: ${error}`, 'error');
    }

    dispatch(fetchEnd());
  };

  return <Button label={props.label} color="primary" onClick={copyToClipboard} />;
};

export default CopyFileToClipboardButton;
