import React, { FC, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    '&.note': {
      margin: '10px',
      padding: '5px',
      backgroundColor: 'lightblue',
      display: 'block',
      border: '1px solid grey',
    },
    '&.reference': {
      margin: '10px',
      padding: '5px',
      backgroundColor: 'rgb(255, 243, 237)',
      display: 'block',
      border: '1px solid grey',
    },
    '&.boxed-content': {
      margin: '10px',
      padding: '5px',
      backgroundColor: '#3709741c',
      border: '1px solid grey',
      display: 'block',
    },
  },
});

const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: '0' }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

export const BoxedContentWrapper: FC<{ type: string; children: ReactNode }> = ({
  type,
  children,
}) => {
  const classes = useStyles();
  return (
    <span className={`${classes.wrapper} ${type}`}>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
};
