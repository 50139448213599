import { EditButton } from 'react-admin';
import { Menu, MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { ArrowDropDown } from '@material-ui/icons';

export const ActionMenu: React.FC<{ label: string; icon?: ReactElement; children: unknown }> = ({
  label,
  icon,
  children,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <EditButton
        icon={icon || <ArrowDropDown />}
        label={label}
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={(event) => {
          setMenuAnchorEl(event.currentTarget);
        }}
        size="small"
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        keepMounted
        onClose={() => setMenuAnchorEl(null)}
      >
        {React.Children.map(children, (child) => (
          <MenuItem onClick={() => setMenuAnchorEl(null)}>{child}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
