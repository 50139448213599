import * as React from 'react';
import * as Slate from 'slate';

import DeleteColumnButton from './DeleteColumnButton';
import InsertColumnButton from './InsertColumnButton';
import InsertRowButton from './InsertRowButton';
import DeleteRowButton from './DeleteRowButton';
import InsertTableButton from './InsertTableButton';
import { Plugin } from '../types';
import { getAboveByType, getNextSiblingNodes, isBlockAboveEmpty } from '../../utils/query';

import * as tags from '../../tags';
import { insertNodeAfterAboveByType } from '../../utils/transforms';
import { isLastRow } from './query';

const key = 'link-plugin';

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return (
          <>
            <InsertTableButton editor={editor} />
            <InsertRowButton editor={editor} />
            <InsertColumnButton editor={editor} />
            <DeleteRowButton editor={editor} />
            <DeleteColumnButton editor={editor} />
          </>
        );
      },
    },
    withEditor: (editor) => {
      const { insertBreak } = editor;

      editor.insertBreak = () => {
        const cellEntry = getAboveByType(editor, [
          tags.BLOCK_TABLE_CELL,
          tags.BLOCK_TABLE_HEADER_CELL,
        ]);

        if (
          !cellEntry ||
          cellEntry[0].children.length === 1 ||
          !isBlockAboveEmpty(editor) ||
          !isLastRow(editor)
        ) {
          insertBreak();
          return;
        }

        const selection = editor.selection?.focus;
        if (selection) {
          // Allow user to put multiple empty paragraphs
          const nextNodes = getNextSiblingNodes(cellEntry, selection.path);
          if (nextNodes.length > 0) {
            insertBreak();
            return;
          }

          Slate.Transforms.removeNodes(editor, { at: selection });
          insertNodeAfterAboveByType(
            editor,
            {
              type: tags.BLOCK_PARAGRAPH,
              children: [{ text: '' }],
            },
            tags.BLOCK_TABLE
          );
        }
      };

      return editor;
    },
  };
};
