import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

class AttributeDialog extends React.Component {
  state = {
    alt: this.props.alt,
    title: this.props.title,
  };

  onChangeAlt = (event) => {
    this.setState({ alt: event.target.value });
  };

  onChangeTitle = (event) => {
    this.setState({ title: event.target.value });
  };

  onClose = (event) => {
    event.preventDefault();
    this.props.onCancel();
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onConfirm(this.state);
  };

  render() {
    return (
      <Dialog open={true} onClose={this.onClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit image attributes</DialogTitle>
        <DialogContent>
          <TextField
            label="alt"
            type="text"
            value={this.state.alt}
            onChange={this.onChangeAlt}
            margin="dense"
            fullWidth
          />
          <TextField
            label="title"
            type="text"
            value={this.state.title}
            onChange={this.onChangeTitle}
            margin="dense"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" label="Ok" onClick={this.onSubmit}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AttributeDialog;
