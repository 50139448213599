import * as React from 'react';

import { Model, ElementModel, ModelMutation } from '../../util/simpleXml';

import { UniformElements, UiContext, SelectedElement } from './BitsDebugUtil';
import { HighLevelElement } from './BitsDebugHighLevel';
import { BitsDebugToolbar } from './toolbar/BitsDebugToolbar';

const ROOT_SIBLINGS: Array<Model> = [];

export const BitsDebug: React.FC<{
  processId: string;
  book: ElementModel;
  documentMutated: boolean;
  applyViewMutation: (mutation: ModelMutation) => void;
  applyDocumentMutation: (mutation: ModelMutation) => void;
}> = ({ processId, book, documentMutated, applyViewMutation, applyDocumentMutation }) => {
  const [
    selectedHighLevelElement,
    setSelectedHighLevelElement,
  ] = React.useState<SelectedElement | null>(null);
  const [
    selectedLowLevelElement,
    setSelectedLowLevelElement,
  ] = React.useState<SelectedElement | null>(null);

  return (
    <UiContext.Provider
      value={{
        book,
        processId,
        documentMutated,
        selectedHighLevelElement,
        setSelectedHighLevelElement,
        selectedLowLevelElement,
        setSelectedLowLevelElement,
        applyViewMutation,
        applyDocumentMutation,
      }}
    >
      <BitsDebugToolbar />
      <div className="bitsDebug">
        <UniformElements
          nodes={book.children}
          component={HighLevelElement}
          stack={{ element: book, siblings: ROOT_SIBLINGS, stack: null }}
        />
      </div>
    </UiContext.Provider>
  );
};
