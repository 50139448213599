import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  EditButton,
  Pagination,
  SingleFieldList,
  ArrayField,
  ChipField,
  SelectInput,
  BooleanInput,
  ReferenceManyField,
} from 'react-admin';

const ContributorPagination: React.FC<{}> = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ContributorBulkActionButtons: React.FC<{}> = () => (
  <React.Fragment>{/* <ResetViewsButton label="Reset Views" {...props} /> */}</React.Fragment>
);

const ContributorFilter: React.FC<{}> = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Profilert"
      source="featured"
      choices={[
        {
          id: 'true',
          name: 'Ja',
        },
        {
          id: 'false',
          name: 'Nei',
        },
      ]}
      alwaysOn
      allowEmpty
    />
    <SelectInput
      label="Har portrett"
      source="hasPortrait"
      choices={[
        {
          id: 'true',
          name: 'Ja',
        },
        {
          id: 'false',
          name: 'Nei',
        },
      ]}
      alwaysOn
      allowEmpty
    />
    <BooleanInput source="includeNonStatic" alwaysOn />
  </Filter>
);

const ContributorList: React.FC<{}> = (props) => (
  <List
    {...props}
    perPage={25}
    filters={<ContributorFilter />}
    filterDefaultValues={{ featured: false }}
    bulkActionButtons={<ContributorBulkActionButtons />}
    pagination={<ContributorPagination />}
  >
    <Datagrid rowClick="show">
      <TextField source="fullname" />
      <TextField source="slug" sortable={false} />
      <ArrayField source="checklist" sortable={false}>
        <SingleFieldList>
          <ChipField source="text" />
        </SingleFieldList>
      </ArrayField>

      <ReferenceManyField
        label="Omtale"
        reference="embeddedSnapshots"
        target="foreignId"
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="revision.title" />
        </SingleFieldList>
      </ReferenceManyField>

      <EditButton />
    </Datagrid>
  </List>
);

export default ContributorList;
