import * as React from 'react';
import * as Slate from 'slate';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import { Plugin } from '../types';
import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { insertAsideFacts } from './transforms';

const key = 'news-article-aside-facts-plugin';

const NewsArticleAsideFactsPlugin: React.FC<{ editor: Slate.Editor }> = (props) => {
  return React.createElement(SlateToolbarButton, {
    key,
    isActive: true,
    Icon: ChromeReaderModeIcon,
    message: 'slate.contextMenu.asideFacts',
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      insertAsideFacts(props.editor);
    },
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <NewsArticleAsideFactsPlugin editor={editor} />;
      },
    },
  };
};
