import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { Availability } from '../../dataProvider/textbookEditionsProvider';

export const EDITION_AVAILABILITY_CHOICES = [
  { id: 'ANNOUNCED', name: 'Annonsert (kommende bok)' },
  { id: 'PREVIEW', name: 'Forhåndsvisning (delvis komplett bok)' },
  { id: 'COMPLETE', name: 'Innhold er komplett' },
];

export const editionAvailabilityText = (availability: Availability): string => {
  return EDITION_AVAILABILITY_CHOICES.find((choice) => choice.id === availability)?.name ?? '';
};

const EditionAvailabilityIcon: React.FC<{ availability: Availability }> = ({ availability }) => {
  if (availability === 'ANNOUNCED') {
    return <NewReleasesIcon color="primary" />;
  }
  if (availability === 'PREVIEW') {
    return <AssignmentIcon color="primary" />;
  }

  // complete:
  return <AssignmentTurnedInIcon color="primary" />;
};

export const EditionAvailabilityAvatar: React.FC<{ availability: Availability }> = ({
  availability,
}) => {
  return (
    <Tooltip title={editionAvailabilityText(availability)}>
      <Avatar>
        <EditionAvailabilityIcon availability={availability} />
      </Avatar>
    </Tooltip>
  );
};
