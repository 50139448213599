import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TopToolbar,
  TextField,
  DateField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
  EditButton,
  DeleteButton,
  ShowProps,
  ReferenceField,
  ChipField,
  Pagination,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';

import { LiteratureConversionProcess } from '../../dataProvider/literatureConversionProcessesProvider';
import { LiteratureConversionBitsFormat } from '../../dataProvider/literatureConversionBitsFormatsProvider';
import { LiteratureConversionAttachment } from '../../dataProvider/literatureConversionAttachmentsProvider';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';
import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { BackendUrls } from '../../dataProvider/types';

import UploadFileButton from '../UploadFileButton';
import { BreadcrumbTitle } from '../BreadcrumbTitle';

import UploadFileMenu from './UploadFileMenu';
import ReimportIdmlAttachmentsButton from './ReimportIdmlAttachmentsButton';
import WebifyAttachmentsButton from './WebifyAttachmentsButton';
import ConvertIdmlToBitsButton from './ConvertIdmlToBitsButton';
import RunPdfToolButton from './RunPdfToolButton';
import CopyFileToClipboardButton from './CopyFileToClipboardButton';

import { formatProps as dateTimeFormatProps } from '../DateTimeField';

import {
  bitsFormatUrl,
  attachmentFormatUrl,
  pdfToolLogUrl,
  pdfOutlineUrl,
} from './literatureConversionUtils';
import DownloadFileButton from '../DownloadFileButton';

const useStyles = makeStyles({
  overviewContent: {
    display: 'grid',
  },
  overviewColumn1: {
    gridColumn: 1,
  },
  overviewColumn2: {
    gridColumn: 2,
  },
  overviewColumn12: {
    gridColumn: '1 / span 2',
  },
  overviewColumn3: {
    gridColumn: 3,
  },
  overviewColumn123: {
    gridColumn: '1 / span 3',
  },
});

const Actions: React.FC<{ basePath?: string; data?: LiteratureConversionProcess }> = ({
  basePath,
  data,
}) => {
  return data ? (
    <TopToolbar>
      {data.customIdmlFormat?.createdAt && (
        <ConvertIdmlToBitsButton
          processId={data.id}
          showContinuationWarning={!!data.latestBitsFormatCreatedAt}
        />
      )}
      {data.originalPdfFormatCreatedAt && <RunPdfToolButton processId={data.id} />}
      <UploadFileMenu processId={data.id} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  ) : null;
};

type Props = {
  record?: LiteratureConversionProcess;
};

const ConfigShow: React.FC<Props> = ({ record }) => {
  return <pre>{JSON.stringify(record?.config?.data ?? null, null, 2)}</pre>;
};

const CopyBitsToClipboardButton: React.FC<{ record?: LiteratureConversionBitsFormat }> = ({
  record,
}) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  if (!record) return null;

  return (
    <CopyFileToClipboardButton label="Kopier XML" url={bitsFormatUrl(backendUrls, record.id)} />
  );
};

const useAttachmentPreviewStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 100,
  },
});

const downloadAttachmentPreviewBlob = async (
  backendUrls: BackendUrls,
  record: LiteratureConversionAttachment
): Promise<Blob | null> => {
  const url = `${attachmentFormatUrl(backendUrls, record.id)}?web=true`;
  console.log('attachment preview blob url: ', url);
  const response = await fetch(url, {
    headers: juridikaHeaders(),
  });
  if (!response.ok) {
    return null;
  }
  return await response.blob();
};

const AttachmentPreview: React.FC<{ record?: LiteratureConversionAttachment; label: string }> = ({
  record,
}) => {
  const classes = useAttachmentPreviewStyles();
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const [objectUrl, setObjectUrl] = React.useState<string | null>(null);

  React.useEffect(() => {
    const downloadBlobAndMakeObjectUrl = async (record: LiteratureConversionAttachment) => {
      const blob = await downloadAttachmentPreviewBlob(backendUrls, record);
      if (!blob) return;
      setObjectUrl(URL.createObjectURL(blob));
    };

    if (record && !objectUrl) {
      downloadBlobAndMakeObjectUrl(record);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [record, objectUrl, backendUrls]);

  return (
    <Card className={classes.root}>
      {objectUrl ? <CardMedia className={classes.media} image={objectUrl} /> : <BrokenImageIcon />}
    </Card>
  );
};

const AttachmentUploadButton: React.FC<{ record?: LiteratureConversionAttachment }> = ({
  record,
}) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  if (!record) return null;
  return (
    <UploadFileButton
      label="Last opp ny"
      icon={<CloudUploadIcon />}
      method="PUT"
      url={attachmentFormatUrl(backendUrls, record.id)}
    />
  );
};

const AttachmentDownloadButton: React.FC<{ record?: LiteratureConversionAttachment }> = ({
  record,
}) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  const filename = React.useMemo(() => {
    const getExtension = () => {
      switch (record?.latestFormatContentType) {
        case 'image/jpeg':
          return '.jpeg';
        case 'image/png':
          return '.png';
        case 'image/svg+xml':
          return '.svg';
        case 'application/pdf':
          return '.pdf';
        case 'application/postscript':
          return '.ps';
        default:
          return '';
      }
    };

    return `attachment-${record?.key ?? 'unknown'}${getExtension()}`;
  }, [record]);

  if (!record) return null;

  return (
    <DownloadFileButton
      label="Last ned"
      filename={filename}
      url={attachmentFormatUrl(backendUrls, record.id)}
    />
  );
};

const LiteratureConversionProcessShow: React.FC<Props & ShowProps> = (props: Props) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const { id } = props as any;
  const classes = useStyles();

  return (
    <Show
      actions={<Actions />}
      title={
        <BreadcrumbTitle resourceName="konvertering" recordName={(record) => record.description} />
      }
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Oversikt" contentClassName={classes.overviewContent}>
          <TextField source="description" fullWidth className={classes.overviewColumn12} />
          <TextField source="id" fullWidth className={classes.overviewColumn3} />
          <ReferenceField
            label="Tilknyttet utgave"
            source="editionId"
            reference="textbookEditions"
            link="show"
            className={classes.overviewColumn12}
          >
            <ChipField source="descriptiveName" />
          </ReferenceField>
          <DateField
            source="updatedAt"
            {...dateTimeFormatProps}
            showTime={true}
            className={classes.overviewColumn3}
          />
          <DateField
            source="customIdmlFormat.createdAt"
            {...dateTimeFormatProps}
            className={classes.overviewColumn1}
          />
          <DateField
            source="originalPdfFormatCreatedAt"
            {...dateTimeFormatProps}
            className={classes.overviewColumn2}
          />
          <DateField
            source="outputPdfFormatCreatedAt"
            {...dateTimeFormatProps}
            className={classes.overviewColumn3}
          />

          <ReferenceManyField
            reference="literatureConversionBitsFormats"
            target="processId"
            label="BITS-format-versjoner"
            fullWidth
            className={classes.overviewColumn123}
          >
            <Datagrid>
              <TextField source="description" />
              <DateField source="createdAt" {...dateTimeFormatProps} />
              <ShowButton />
              <CopyBitsToClipboardButton />
              <DeleteButton redirect={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Config">
          <ConfigShow />
        </Tab>
        <Tab label="Attachments">
          <div>
            <ReimportIdmlAttachmentsButton label="Reimporter IDML-grafikk" processId={id} />
            <WebifyAttachmentsButton label="Konverter alle til web-formater" processId={id} />
          </div>

          <ReferenceManyField
            reference="literatureConversionAttachments"
            target="processId"
            label="Attachments"
            perPage={100}
            pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200]} />}
            fullWidth
            className={classes.overviewColumn123}
          >
            <Datagrid>
              <TextField source="key" />
              <TextField source="description" />
              <TextField source="latestFormatContentType" />
              <TextField source="latestFormatSize" />
              <AttachmentPreview label="Forhåndsvisning" />
              <AttachmentUploadButton />
              <AttachmentDownloadButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="PDF-tool">
          <div>
            <p>
              <Typography color="textSecondary">
                PDF-tool er et verktøy for å juridika-tilpasse PDF-filer som har suboptimal TOC
                (outline).
              </Typography>
            </p>

            <p>
              <Typography color="textSecondary">
                Når disse PDF-filene kommer ut fra Adobe/InDesign, skal hvert outline-element være
                knyttet til sidetallet det starter på. Men ofte stopper det der! Det vi er
                interessert i, er å vite nøyaktig <em>hvor</em> på siden (y-posisjon) hvert
                outline-element starter på. På denne måten kan søkemotoren vite mer nøyaktig hvilken
                tekst som hører til hvert kapittel eller seksjon, samt at navigasjon via TOC i
                juridikas PDF-leser tar leseren direkte til stedet brukeren vil.
              </Typography>
            </p>

            <p>
              <Typography color="textSecondary">
                PDF-tool fungerer ved å lese inn en outline-file (YAML) som inneholder informasjon
                om hvilke inndelinger denne boken skal ha, samt hvilket sidetall hvert
                outline-element befinner seg på. Deretter vil PDF-tool scanne hver side i PDF-filen,
                og prøve å finne igjen tittelen til hvert outline-element. Forskjellige parametere
                hjelper den å finne frem: F.eks. font-størrelser, fordi vi ønsker at den skal velge{' '}
                <em>overskrifter</em> fremfor brødtekst.
              </Typography>
            </p>

            <h5>Hvordan kjøre PDF-tool</h5>

            <p>
              <Typography color="textSecondary">
                <ol>
                  <li>
                    Konfigurer sidetallsparametere og sidenavn-intervaller (<code>REDIGER</code>
                    -knappen)
                  </li>
                  <li>Last opp PDF-fil</li>
                  <li>
                    Klikk <code>Kjør PDF-tool</code>
                  </li>
                  <li>
                    Hvis kommandoen feiler, er det mer jobb å gjøre:
                    <ul>
                      <li>Last ned PDF-tool logg om nødvendig (knapp under)</li>
                      <li>
                        Last ned outline i YAML (knapp under), tilpass den, og last den opp igjen
                      </li>
                    </ul>
                  </li>
                  <li>Kjør PDF-tool på nytt, til det fungerer</li>
                  <li>
                    Til slutt, gå inn på den tilknyttede utgaven, og klikk <code>IMPORTER PDF</code>
                    .
                  </li>
                </ol>
              </Typography>
            </p>

            <h5>Tips</h5>

            <Typography color="textSecondary">
              <ul>
                <li>
                  Outline YAML skal ikke inneholde tittelside eller kolofon. Disse sidetallene angis
                  separat under <code>REDIGER</code>.
                </li>
              </ul>
            </Typography>

            <DownloadFileButton
              label="Last ned PDF outline (YAML)"
              filename={`pdf-outline-${id}.yaml`}
              url={pdfOutlineUrl(backendUrls, id)}
            />
            <DownloadFileButton
              label="Last ned PDF-tool logg"
              filename={`pdf-tool-${id}.log`}
              url={pdfToolLogUrl(backendUrls, id)}
            />
          </div>
          <DateField
            source="outputPdfFormatCreatedAt"
            {...dateTimeFormatProps}
            className={classes.overviewColumn3}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LiteratureConversionProcessShow;
