import React, { ReactNode, Ref, PropsWithChildren, LegacyRef } from 'react';
import ReactDOM from 'react-dom';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      className={className}
      style={{
        cursor: 'pointer',
        color: active ? 'white' : '#aaa',
      }}
    />
  )
);

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      className="material-icons"
      style={{
        fontSize: '32px',
        verticalAlign: 'text-bottom',
      }}
    />
  )
);

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => <div {...props} data-test-id="menu" ref={ref} className={className} />
);

export const Portal = ({ children }: { children?: ReactNode }) => {
  return typeof document === 'object' ? ReactDOM.createPortal(children, document.body) : null;
};

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement>) => (
    <Menu
      {...props}
      ref={ref}
      className={className}
      style={{
        position: 'relative',
        padding: '1px 18px 17px',
        margin: '0 -20px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px',
      }}
    />
  )
);
