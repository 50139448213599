import h from 'hastscript';

import { DocumentSpec } from '../documentSpec';
import * as tags from '../tags';
import LinkPlugin from '../plugins/LinkPlugin';
import FormatPlugin from '../plugins/FormatPlugin';
import SingleLinePlugin from '../plugins/SingleLinePlugin';

export default DocumentSpec.createSpec({
  plugins: [SingleLinePlugin(), FormatPlugin(), LinkPlugin()],
  hypertextRules: {
    blocks: {
      fragment: [
        {
          type: tags.BLOCK_FRAGMENT,
          render: 'span',
        },
      ],
    },
    marks: {
      strong: [{ type: tags.MARK_BOLD }],
      em: [{ type: tags.MARK_ITALIC }],
      u: [{ type: tags.MARK_UNDERLINE }],
      s: [{ type: tags.MARK_STRIKETHROUGH }],
    },
    inlines: {
      a: [
        {
          type: tags.INLINE_LINK,
          optionalData: ['href'],
        },
      ],
    },
  },
  serializationFormat: 'hast',
});

export const DefaultHast = () => h('fragment', {});
