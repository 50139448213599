import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  ShowProps,
  BooleanField,
  EmailField,
  UrlField,
  TopToolbar,
  EditButton,
} from 'react-admin';
import PortraitIcon from '@material-ui/icons/Portrait';

import singleLineDocumentSpec, {
  DefaultHast as SingleLineDefaultHast,
} from '../../slate/documentSpecs/singleLineSpec';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';

import { EmbeddedSnapshotField } from '../embeddedSnapshots/EmbeddedSnapshotField';

import { SlateTextField } from '../SlateTextField';
import { Contributor } from '../../dataProvider/contributorsProvider';
import UploadFileButton from '../UploadFileButton';
import { BreadcrumbTitle } from '../BreadcrumbTitle';

import ContributorMakeStaticButton from './ContributorMakeStaticButton';

const Actions: React.FC<{ basePath?: string; data?: Contributor }> = ({ basePath, data }) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  return data ? (
    <TopToolbar>
      {data && !data.static && <ContributorMakeStaticButton id={data.id} />}
      {data && data.static && (
        <UploadFileButton
          label="Last opp portrett..."
          icon={<PortraitIcon />}
          method="POST"
          url={`${backendUrls.juridikaLitteraturBackend}/admin/v0/contributors/${data.id}/portrait`}
        />
      )}
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  ) : null;
};

export const ContributorShow: React.FC<
  {
    record?: Contributor;
  } & ShowProps
> = (props) => (
  <Show
    {...props}
    title={<BreadcrumbTitle resourceName="bidragsyter" recordName={(record) => record.fullname} />}
    actions={<Actions />}
  >
    <SimpleShowLayout>
      <TextField source="fullname" />
      <TextField source="slug" />
      <BooleanField source="featured" />
      <BooleanField source="hasPortrait" />

      <Labeled key="occupation" label="resources.contributors.fields.occupation" fullWidth>
        <SlateTextField
          source="occupation"
          documentSpec={singleLineDocumentSpec}
          defaultValue={SingleLineDefaultHast()}
          singlelineStyle={true}
        />
      </Labeled>

      <Labeled key="degree" label="resources.contributors.fields.degree" fullWidth>
        <SlateTextField
          source="degree"
          documentSpec={singleLineDocumentSpec}
          defaultValue={SingleLineDefaultHast()}
          singlelineStyle={true}
        />
      </Labeled>

      <EmailField source="emailAddress" />
      <UrlField source="homepageUrl" />

      <EmbeddedSnapshotField
        label="Omtale"
        foreignType="contributor_bio"
        createTitle={(record) => `${record.fullname}`}
      />
    </SimpleShowLayout>
  </Show>
);
