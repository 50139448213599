import * as React from 'react';
import * as Slate from 'slate';
import MenuItem from '@material-ui/core/MenuItem';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import LooksThreeIcon from '@material-ui/icons/Looks3';
import LooksFourIcon from '@material-ui/icons/Looks4';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

import { Plugin } from './types';
import { unsetElementIfActive } from '../utils/transforms';
import { getAboveByType } from '../utils/query';
import * as tags from '../tags';
import SlateSimpleMenu from '../../components/SlateInput/SlateSimpleMenu';

export interface PluginConfig {
  types: string[];
}

interface HeadingConfig {
  icon: React.ComponentType<SvgIconProps>;
  translation: string;
}

const HEADING_CONFIG: { [slateType: string]: HeadingConfig } = {
  [tags.BLOCK_HEADING_ONE]: {
    icon: LooksOneIcon,
    translation: 'slate.contextMenu.headings.heading1',
  },
  [tags.BLOCK_HEADING_TWO]: {
    icon: LooksTwoIcon,
    translation: 'slate.contextMenu.headings.heading2',
  },
  [tags.BLOCK_HEADING_THREE]: {
    icon: LooksThreeIcon,
    translation: 'slate.contextMenu.headings.heading3',
  },
  [tags.BLOCK_HEADING_FOUR]: {
    icon: LooksFourIcon,
    translation: 'slate.contextMenu.headings.heading4',
  },
  [tags.BLOCK_LEAD_PARAGRAPH]: {
    icon: FormatSizeIcon,
    translation: 'slate.contextMenu.leadParagraph',
  },
};

const key = 'heading-plugin';

const HeadingMenuItem = (
  editor: Slate.Editor,
  currentType: string | null,
  menuType: string,
  config: HeadingConfig
) =>
  React.createElement(
    MenuItem,
    {
      key: menuType,
      selected: menuType === currentType,
      onMouseDown: () =>
        unsetElementIfActive(editor, menuType, tags.BLOCK_PARAGRAPH, { className: null }),
    },
    React.createElement(config.icon)
  );

const HeadingButton: React.FC<{ editor: Slate.Editor; types: string[] }> = (props) => {
  const blockAboveEntry = getAboveByType(props.editor, props.types);
  const currentType = blockAboveEntry?.[0].type as string;
  const headingConfig = currentType && HEADING_CONFIG[currentType];

  return React.createElement(SlateSimpleMenu, {
    key,
    isActive: !!blockAboveEntry,
    Icon: headingConfig ? headingConfig.icon : FormatSizeIcon,
    message: 'slate.contextMenu.headings.name',
    menuItems: props.types.map((type) => {
      const headingConfig = HEADING_CONFIG[type];
      return HeadingMenuItem(props.editor, currentType, type, headingConfig);
    }),
  });
};

export default (types: string[]): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <HeadingButton key={key} editor={editor} types={types} />;
      },
    },
  };
};
