import { DocumentSpec } from '../documentSpec';
import * as tags from '../tags';
import QuotePlugin from '../plugins/QuotePlugin';
import ListPlugin from '../plugins/ListPlugin';
import HeadingPlugin from '../plugins/HeadingPlugin';
import BoxPlugin from '../plugins/BoxPlugin';
import ParagraphPlugin from '../plugins/ParagraphPlugin';
import LinkPlugin from '../plugins/LinkPlugin';
import FormatPlugin from '../plugins/FormatPlugin';
import BoxOutlinePlugin from '../plugins/BoxOutlinePlugin';
import EmptyTextHighlighterPlugin from '../plugins/EmptyTextHighlighterPlugin';
import EmbeddedDocumentPlugin from '../plugins/EmbeddedDocumentPlugin';
import SlateImage from '../../containers/ImageEditor/SlateImage';
import SlateIframe from '../../components/SlateInput/SlateIframe';
import SoftBreakPlugin from '../plugins/SoftBreakPlugin';

export default DocumentSpec.createSpec({
  plugins: [
    EmbeddedDocumentPlugin(),
    FormatPlugin(),
    LinkPlugin(),
    HeadingPlugin([
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
      tags.BLOCK_LEAD_PARAGRAPH,
    ]),
    BoxPlugin({ boxTypes: [tags.BLOCK_IFRAME] }),
    QuotePlugin(),
    ListPlugin(),
    ParagraphPlugin(),
    SoftBreakPlugin({
      activeInTypes: [
        tags.BLOCK_PARAGRAPH,
        tags.BLOCK_LEAD_PARAGRAPH,
        tags.BLOCK_QUOTE,
        tags.BLOCK_QUOTE_DISPOSITION,
      ],
    }),
    EmptyTextHighlighterPlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_FIGCAPTION,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
    BoxOutlinePlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_BULLETED_LIST,
      tags.BLOCK_ALPHA_LIST,
      tags.BLOCK_NUMBERED_LIST,
      tags.BLOCK_FIGURE,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
  ],
  hypertextRules: {
    blocks: {
      section: [
        {
          type: tags.BLOCK_FRAGMENT,
          className: 'embeddedDocument',
        },
      ],
      p: [
        {
          className: 'leadParagraph',
          type: tags.BLOCK_LEAD_PARAGRAPH,
        },
        {
          type: tags.BLOCK_PARAGRAPH,
        },
      ],
      ul: [{ type: tags.BLOCK_BULLETED_LIST }],
      ol: [{ type: tags.BLOCK_NUMBERED_LIST }],
      li: [{ type: tags.BLOCK_LIST_ITEM }],
      div: [
        {
          className: 'listItemContent',
          type: tags.BLOCK_LIST_ITEM_CONTENT,
        },
      ],
      blockquote: [
        {
          className: 'quote',
          type: tags.BLOCK_QUOTE,
        },
      ],
      h1: [{ type: tags.BLOCK_HEADING_ONE }],
      h2: [{ type: tags.BLOCK_HEADING_TWO }],
      h3: [{ type: tags.BLOCK_HEADING_THREE }],
      h4: [{ type: tags.BLOCK_HEADING_FOUR }],
      h5: [{ type: tags.BLOCK_HEADING_FIVE }],
      h6: [{ type: tags.BLOCK_HEADING_SIX }],
      img: [
        {
          data: ['src', 'alt', 'data-uuid', 'data-fileid'],
          type: tags.BLOCK_IMAGE,
          isVoid: true,
          render: SlateImage,
        },
      ],
      iframe: [
        {
          data: ['name', 'src', 'srcDoc', 'width', 'height', 'sandbox'],
          type: tags.BLOCK_IFRAME,
          isVoid: true,
          render: SlateIframe,
        },
      ],
    },
    marks: {
      strong: [{ type: tags.MARK_BOLD }],
      em: [{ type: tags.MARK_ITALIC }],
      u: [{ type: tags.MARK_UNDERLINE }],
      s: [{ type: tags.MARK_STRIKETHROUGH }],
      cite: [{ type: tags.MARK_CITE }],
      b: [{ reimportHtmlTagAs: 'strong' }],
      i: [{ reimportHtmlTagAs: 'em' }],
    },
    inlines: {
      a: [
        {
          type: tags.INLINE_LINK,
          optionalData: ['href'],
        },
      ],
      q: [
        {
          type: tags.INLINE_QUOTE,
        },
      ],
    },
  },
  serializationFormat: 'hast',
});
