import { ActionType, getType } from 'typesafe-actions';

import * as staticFilesActions from './staticFilesActions';

type StaticFilesAction = ActionType<typeof staticFilesActions>;

export interface StaticFileState {
  readonly status: 'uploading' | 'done' | 'error';
  readonly fileid: string | null;
  readonly url: string | null;
  readonly error: Error | null;
}

export interface StaticFilesState {
  readonly [uuid: string]: StaticFileState;
}

export default (state: StaticFilesState = {}, action: StaticFilesAction): StaticFilesState => {
  switch (action.type) {
    case getType(staticFilesActions.uploadStaticImage.request):
      return {
        ...state,
        [action.payload.uuid]: {
          status: 'uploading',
          fileid: null,
          url: null,
          error: null,
        },
      };
    case getType(staticFilesActions.uploadStaticImage.success):
      return {
        ...state,
        [action.payload.uuid]: {
          status: 'done',
          fileid: action.payload.fileid,
          url: action.payload.url,
          error: null,
        },
      };
    case getType(staticFilesActions.uploadStaticImage.failure):
      return {
        ...state,
        [action.payload.uuid]: {
          status: 'error',
          fileid: null,
          url: null,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
