import * as React from 'react';
import * as Slate from 'slate';
import uuidv4 from 'uuid/v4';

import SlateToolbarButton from '../../components/SlateInput/SlateToolbarButton';
import InsertPhoto from '@material-ui/icons/InsertPhoto';

import * as tags from '../tags';
import { Plugin } from './types';
import {
  insertChildIfMissing,
  insertNodeAfterAboveByType,
  insertOrRemoveElement,
} from '../utils/transforms';
import { getAboveByType, getNextSiblingNodes, isBlockAboveEmpty } from '../utils/query';

const key = 'figure-plugin';

const createImageNode = () => {
  return {
    type: tags.BLOCK_IMAGE,
    src: '',
    alt: '',
    'data-uuid': uuidv4(),
    'data-fileid': '',
    children: [{ text: '' }],
  };
};

const createFigureNode = () => {
  return {
    type: tags.BLOCK_FIGURE,
    children: [
      createImageNode(),
      { type: tags.BLOCK_FIGCAPTION, children: [{ text: 'Figur tekst' }] },
    ],
  };
};

const FigureButton: React.FC<{ editor: Slate.Editor }> = (props) => {
  const isActive = !!getAboveByType(props.editor, tags.BLOCK_FIGURE);

  return React.createElement(SlateToolbarButton, {
    key,
    isActive,
    Icon: InsertPhoto,
    message: 'slate.contextMenu.figure',
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      insertOrRemoveElement(props.editor, createFigureNode());
    },
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <FigureButton key={key} editor={editor} />;
      },
    },
    withEditor: (editor) => {
      const { normalizeNode, isVoid, insertBreak } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node] = entry;

        if (node.type === tags.BLOCK_FIGURE) {
          if ((node as Slate.Element).children.length < 2) {
            insertChildIfMissing(editor, entry, 0, tags.BLOCK_IMAGE, createImageNode());
          }
        }

        normalizeNode(entry);
      };

      editor.insertBreak = () => {
        const captionNodeEntry = getAboveByType(editor, tags.BLOCK_FIGCAPTION);
        const figureNodeEntry = getAboveByType(editor, tags.BLOCK_FIGURE);

        if (
          !captionNodeEntry ||
          !figureNodeEntry ||
          figureNodeEntry[0].children.length === 2 ||
          !isBlockAboveEmpty(editor)
        ) {
          insertBreak();
          return;
        }

        if (!figureNodeEntry) {
          console.warn('Figure caption must be wrapped in figure');
          return;
        }

        const selection = editor.selection?.focus;
        if (selection) {
          // Allow user to put multiple empty paragraphs
          const nextNodes = getNextSiblingNodes(figureNodeEntry, selection.path);
          if (nextNodes.length > 0) {
            insertBreak();
            return;
          }

          const [figureNode] = figureNodeEntry;
          if (figureNode.children.length > 2) {
            Slate.Transforms.removeNodes(editor, { at: selection });
          }
          insertNodeAfterAboveByType(
            editor,
            {
              type: tags.BLOCK_PARAGRAPH,
              children: [{ text: '' }],
            },
            tags.BLOCK_FIGURE
          );
        }
      };

      editor.isVoid = (element: Slate.Element) => {
        return isVoid(element) || element.type === tags.BLOCK_IMAGE;
      };

      return editor;
    },
  };
};
