import React, { FC } from 'react';
import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import NIKEditionShow from './NIKEditionShow';
import DateTimeField from '../DateTimeField';

const useStyles = makeStyles({
  rowCell: {
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
  headerCell: {
    backgroundColor: '#00968836',
  },
  headerContentWrapper: {
    display: 'flex',
  },
  mark: {
    backgroundColor: '#faf8dd',
  },
});

const NIKEditionList: FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      <h1>Liste over utgaver</h1>

      <div className={classes.headerContentWrapper}>
        <div>
          <p>
            Denne siden viser en liste over alle opprettede utgaver i problemstillinger. Brukere har
            muligheten til å opprette nye utgaver direkte fra denne siden. Du får utvidet
            informasjon om utgavene ved å trykke på pila til venstre for navnet. Her gir hver
            oppførte utgave også en oversikt over alle tilhørende dokumenter. Ved å klikke på et
            bestemt dokument, kan brukerne navigere til det spesifikke dokumentet for å utforske
            eller endre det.
          </p>
          <p>
            Modellen for problemstillinger:{' '}
            <mark className={classes.mark}>
              Problemstilling &rarr; <strong>utgave</strong> &rarr; dokument
            </mark>
          </p>
        </div>
      </div>
      <List {...props} perPage={25} bulkActionButtons={false}>
        <Datagrid
          expand={<NIKEditionShow />}
          classes={{ headerCell: classes.headerCell, rowCell: classes.rowCell }}
        >
          <TextField source="name" sortable={false} />
          <ReferenceField source="publicationId" reference="nikPublications" sortable={false}>
            <TextField source="title" />
          </ReferenceField>
          <DateTimeField source="publishedAt" sortable={false} emptyText="Ikke publisert" />
          <EditButton />
        </Datagrid>
      </List>
    </div>
  );
};

export default NIKEditionList;
