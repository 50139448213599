// Reference: https://github.com/marmelab/react-admin/blob/master/packages/ra-language-english/index.js

const staticContentRevisionFields = {
  id: 'Revisions-ID',
  documentId: 'Dokument-ID',
  createdAt: 'Opprettet',
  createdById: 'Redigert av',
  publishedAt: 'Publisert',
  contentCategoryId: 'Kategori',
  title: 'Tittel',
  hast: 'Innhold',
  contributions: 'Bidrag',
  'contributions.contributorId': 'Bidragsyter',
  'contributions.role': 'Rolle',
  'contributions.occupation': 'Stilling/yrke (i skrivende stund) (valgfritt)',
  tags: 'Tags',
};

const staticContentBaseSnapshotFields = {
  id: 'Dokument-ID',
  createdAt: 'Opprettet',
  createdById: 'Opprettet av',
  currentEditorId: 'Redigeres av',
  firstPublishedAt: 'Først publisert',
  lastPublishedAt: 'Sist publisert',
  revisionId: 'Revisjons-ID',
  revision: staticContentRevisionFields,
};

export default {
  ra: {
    action: {
      add_filter: 'Legg til filter',
      add: 'Legg til',
      back: 'Gå tilbake',
      bulk_actions: '%{smart_count} valgt',
      cancel: 'Avbryt',
      clear_input_value: 'Fjern verdi',
      clone: 'Klon',
      close: 'Lukk',
      create: 'Opprett',
      delete: 'Slett',
      edit: 'Rediger',
      expand: 'Ekspander',
      export: 'Eksporter',
      list: 'Liste',
      refresh: 'Oppdater',
      remove_filter: 'Fjern dette filteret',
      remove: 'Fjern',
      save: 'Lagre',
      show: 'Vis',
      sort: 'Sorter',
      undo: 'Angre',
      unselect: 'Fjern valg',
      confirm: 'Bekreft',
    },
    boolean: {
      true: 'Ja',
      false: 'Nei',
    },
    page: {
      create: 'Opprett %{name}',
      dashboard: 'Dashboard',
      edit: '%{name} #%{id}',
      error: 'Noe gikk galt',
      list: '%{name}',
      loading: 'Laster',
      not_found: 'Ikke funnet',
      show: '%{name} #%{id}',
      empty: 'Denne listen er tom',
      invite: 'Vil du lage en?',
    },
    input: {
      file: {
        upload_several: 'Dropp noen filer for opplasting, eller klikk for å velge en.',
        upload_single: 'Dropp en fil for opplasting, eller klikk for å velge en.',
      },
      image: {
        upload_several: 'Dropp noen bilder for opplasting, eller klikk for å velge et.',
        upload_single: 'Dropp noen bilder for opplasting, eller klikk for å velge et.',
      },
      references: {
        all_missing: 'Kan ikke finne referansedata.',
        many_missing: 'Minst én av de tilknyttede referansene er ikke lenger tilgjengelig.',
        single_missing: 'Tilknyttet referanse er ikke lenger tilgjengelig.',
      },
    },
    message: {
      about: 'Om',
      are_you_sure: 'Er du sikker?',
      bulk_delete_content:
        'Er du sikker på at du vil slette %{name}? |||| Er du sikker på at du vil slette disse %{smart_count} oppføringene?',
      bulk_delete_title: 'Slett %{name} |||| Slett %{smart_count} %{name} oppføringer',
      delete_content: 'Er du sikker på at du vil slette denne oppføringen?',
      delete_title: 'Slett %{name} #%{id}',
      details: 'Detaljer',
      error: 'En klientfeil oppstod og din forespørsel kunne ikke gjennomføres.',
      invalid_form: 'Skjema er ikke gyldig. Sjekk for feil.',
      loading: 'Siden laster. Et øyeblikk...',
      no: 'Nei',
      not_found: 'Du skrev enten feil URL, eller du fulgte en ødelagt lenke.',
      yes: 'Ja',
    },
    navigation: {
      no_results: 'Ingen resultater funnet',
      no_more_results: 'Ingen flere resultater på side %{page}. Prøv forrige side.',
      page_out_of_boundaries: 'Sidetall %{page} er ikke gyldig',
      page_out_from_end: 'Kan ikke gå videre fra siste side',
      page_out_from_begin: 'Kan ikke gå før første side',
      page_range_info: '%{offsetBegin}-%{offsetEnd} av %{total}',
      page_rows_per_page: 'Resultater per side',
      skip_nav: 'Hopp over navigasjon',
      next: 'Neste',
      prev: 'Forrige',
    },
    auth: {
      user_menu: 'Profil',
      username: 'Brukernavn',
      password: 'Passord',
      sign_in: 'Logg inn',
      sign_in_error: 'Autentisering feilet, vennligst prøv igjen',
      logout: 'Logg ut',
    },
    notification: {
      updated: 'Element oppdatert |||| %{smart_count} elementer oppdatert',
      created: 'Element opprettet',
      deleted: 'Element slettet |||| %{smart_count} elementer slettet',
      bad_item: 'Feil element',
      item_doesnt_exist: 'Elementet eksisterer ikke',
      http_error: 'Server kommunikasjonsfeil',
      data_provider_error: 'dataProvider-feil. Sjekk konsollen for detaljer.',
      canceled: 'Forespørsel avbrutt',
    },
    validation: {
      required: 'Obligatorisk',
      minLength: 'Må være minst %{min} karakterer langt',
      maxLength: 'Må være %{max} karakterer eller mindre',
      minValue: 'Må være minst %{min}',
      maxValue: 'Må være %{max} eller mindre',
      number: 'Må være et tall',
      email: 'Må være gyldig epost',
      oneOf: 'Må være en av: %{options}',
      regex: 'Må passe et spesifikt mønster (regexp): %{pattern}',
      slugFormat: 'Slug kan kun inneholde små bokstaver (a-å og äöü), siffer og bindestreker',
    },
  },
  resources: {
    users: {
      name: 'Bruker |||| Brukere',
      fields: {
        name: 'Navn',
        email: 'E-post',
        customerNumber: 'Kundenummer',
        emailVerified: 'E-post verifisert',
        termsAccepted: 'Vilkår akseptert',
        loginCount: 'Innlogginger',
        customerId: 'Kunde ID',
        lastLogin: 'Siste innlogging',
      },
    },
    revisions: {
      name: 'Side-revisjon |||| Side-revisjoner',
      fields: staticContentRevisionFields,
      actions: {
        publish: 'Publiser',
      },
    },
    noticeSnapshots: {
      name: 'Notis |||| Notiser',
      fields: {
        ...staticContentBaseSnapshotFields,
        revision: {
          ...staticContentRevisionFields,
          contributions:
            'Bidragsyter (kun nødvendig hvis portrett av bidragsyter eksisterer og skal vises i notisen på Innsikt-forsiden, som i ekspertkommentarer)',
        },
        linkedPage: 'Hyperlenke til side (valgfritt)',
        feeds: 'Feeds (hvor vises denne notisen)',
      },
      actions: {
        create: 'Opprett ny notis',
        createRevision: 'Opprett ny revision',
        filterByTitle: 'Søk tittel',
      },
    },
    pageSnapshots: {
      name: 'Side |||| Sider',
      fields: {
        ...staticContentBaseSnapshotFields,
        revision: {
          ...staticContentRevisionFields,
          title:
            'Tittel (brukes bl.a. for sidens tittel i browser-tab, og for tittel i søkemotorer som Google.)',
        },
        slug:
          'Slug (Nettadressens "etternavn"). Kan bli endret etter publisering og notis-lenke vil automatisk bli oppdatert. Lenker på eksterne sider (SoMe) vil derimot ikke oppdateres, men vil fortsatt ta deg til riktig versjon av saken.)',
        shareImageUrl:
          'Illustrasjonsbilde til deling på SoMe. Kun nødvendig hvis artikkelen er en ekspertkommentar (hvor vi bruker forfatterens portrett med Juridika-bakgrunn), eller hvis bildet må være forskjellig fra artikkelens hovedbilde.',
      },
      actions: {
        create: 'Opprett ny side',
        createRevision: 'Opprett ny revisjon',
        filterByTitle: 'Søk tittel',
      },
      form: {
        contents: 'Innhold',
        metadata: 'Metadata',
      },
      notification: {
        upload_image_failure:
          'Kunne ikke laste opp bildet. Bildestørrelse kan ikke overskride 1MB. """%{msg}"""',
      },
    },
    contractSnapshots: {
      name: 'Kontraktsmal |||| Kontraktsmaler',
      fields: {
        ...staticContentBaseSnapshotFields,
        revision: {
          ...staticContentRevisionFields,
          title: 'Tittel',
        },
        slug: 'Slug (Nettadressens "etternavn")',
        documentAttachments: {
          name: 'Vedlegg',
          templateWordDoc: 'Maldokument (Word)',
          templatePdfPreview: 'Maldokument forhåndsvisning (PDF)',
          templateGuideWordDoc: 'Veileder (Word)',
          templateGuidePdfPreview: 'Veileder forhåndsvisning (PDF)',
        },
      },
      actions: {
        create: 'Opprett ny kontraktsmal',
        createRevision: 'Endre kontraktmal',
        filterByTitle: 'Søk tittel',
      },
      form: {
        metadata: 'Metadata',
      },
    },
    embeddedSnapshots: {
      name: 'Omtale |||| Omtaler',
      fields: {
        ...staticContentBaseSnapshotFields,
        revision: {
          ...staticContentRevisionFields,
          title: 'Tittel (brukes kun internt i CMS)',
        },
        foreignId: 'Fremmed-ID',
        foreignType: 'Type',
      },
      actions: {
        create: 'Opprett ny omtale',
        createRevision: 'Opprett ny revisjon',
        filterByTitle: 'Søk tittel',
      },
      form: {
        contents: 'Innhold',
        metadata: 'Metadata',
      },
    },
    contentCategories: {
      name: 'Kategori |||| Kategorier',
      fields: {
        id: 'Kategori-ID',
        semanticKey: 'Semantisk nøkkel',
        name: 'Navn',
      },
    },
    contributors: {
      name: 'Bidragsyter |||| Bidragsytere',
      fields: {
        givenName: 'Fornavn (og evt. mellomnavn)',
        surname: 'Etternavn',
        fullname: 'Fullt navn',
        featured: 'Profilert',
        hasPortrait: 'Har portrett',
        occupation: 'Stilling/yrke',
        degree: 'Utdannelse/grad',
        emailAddress: 'E-postadresse',
        homepageUrl: 'Ekstern URL',
        checklist: 'Sjekkliste',
        includeNonStatic: 'Vis ikke-statiske (importerte)',
      },
    },
    textbookPublications: {
      name: 'Utgivelse (Fagbok) |||| Utgivelser (Fagbøker)',
      fields: {
        titles: 'Tittel og undertitler',
        mainTitle: 'Utgivelsens hovedtittel',
        fullTitle: 'Utgivelsens fullstendige tittel',
        categoryId: 'Kategori',
        actWorkIds: 'Liste over relaterte lovverks-IDer (relevant kun for lovkommentarer)',
        workId: 'verk-id (eksempel: /akn/no/act/lov/2004-05-14/25)',
        ufDepartment: 'Redaksjonsavdeling',
      },
      form: {
        editions: 'Utgaver',
        metadata: 'Metadata',
      },
    },
    textbookEditions: {
      name: 'Bokutgave |||| Bokutgaver',
      fields: {
        publicationId: 'Utgivelse',
        publisherId: 'Forlag',
        availability: 'Tilgjengelighet',
        titles: 'Tittel og undertitler',
        mainTitle: 'Hovedtittel',
        fullTitle: 'Fullstendig tittel',
        originallyPublishedAt: 'Utgivelsesdato',
        juridikaPublishedAt: 'Publisert på Juridika',
        editionSequence: 'Utgavenr.',
        hasCover: 'Har omslag',
        isbn: 'ISBN',
        pageCount: 'Antall sider',
        document: {
          files: 'Dokumentfiler',
          tagIds: 'Tags',
        },
        isPublishedAtJuridika: 'Publisert',
      },
    },
    textbookModules: {
      name: 'Bokmodul |||| Bokmoduler',
      fields: {
        contributions: 'Bidrag',
        contributorId: 'Bidragsyter',
        role: 'Rolle',
      },
    },
    literatureConversionProcesses: {
      name: 'Bok-konvertering |||| Bok-konverteringer',
      fields: {
        description: 'Beskrivelse',
        createdAt: 'Opprettet',
        updatedAt: 'Oppdatert',
        originalPdfFormatCreatedAt: 'PDF opplastet',
        outputPdfFormatCreatedAt: 'PDF sist generert',
        config: {
          createdAt: 'Config oppdatert',
          data: {
            titlePageName: 'Tittelsidenavn',
            colophonPageName: 'Kolofonsidenavn',
            bookBodyStartPageName: 'book-body startsidenavn',
            backMatterStartPageName: 'book-back startsidenavn',
            pdfPageLabelRanges: 'PDF page label ranges',
            indesignStyles: {
              exclude: 'utelat (ParagraphStyles)',
              characterExclude: 'utelat (CharacterStyles)',
              quote: 'sitat',
              list: 'liste',
              tableHeader: 'tabell-hode',
              petit: 'petit',
              caption: 'figurtekst (caption)',
            },
            indesignTocStyles: 'TOC-stiler',
          },
        },
        customIdmlFormat: {
          createdAt: 'IDML opplastet',
        },
        latestBitsFormatCreatedAt: 'Siste BITS-format opprettet',
        checklist: 'Sjekkliste',
      },
    },
    literatureConversionBitsFormats: {
      name: 'BITS-format |||| BITS-formater',
      fields: {
        description: 'Beskrivelse',
        createdAt: 'Opprettet',
      },
    },
    literatureConversionAttachments: {
      name: 'Vedlegg',
      fields: {
        key: 'Referansenøkkel',
        description: 'Beskrivelse',
        latestFormatCreatedAt: 'Siste format opprettet',
        latestFormatContentType: 'innholdstype',
        latestFormatSize: 'Størrelse (bytes)',
      },
    },
    customers: {
      name: 'Kunde |||| Kunder',
      customer: 'Kunde',
      fields: {
        customerNumber: 'Kundenummer',
        name: 'Navn',
        accountManager: 'Selger',
        numberOfUsers: 'Registrerte brukere',
        potentialUsers: 'Antall antatte brukere',
        registeredUsers: 'Registrerte brukere',
        subscriptionStatus: 'Abonnement status',
        subscriptionExpiration: 'Abonnement utgår',
        subscriptionPackage: 'Pakke',
        subscriptionPackageId: 'Pakke',
        products: 'Produkter',
        contacts: 'Kontakter',
        'contact.name': 'Kontaktperson',
        'contact.email': 'Epost',
        'contact.phone': 'Telefonnummer',
        deletionInDays: 'Slettes automatisk',
      },
      actions: {
        searchForCustomer: 'Søk på kunde',
        filterByStatus: 'Velg kundestatus',
        filterByManager: 'Velg selger',
        filterByPackage: 'Velg pakke',
        filterByCategory: 'Velg markedskategori',
        filterEmptyPackage: 'Ingen oppført pakke',
        filterEmptyManager: 'Ingen oppført selger',
        filterEmptyCategory: 'Ingen oppført markedskategori',
        scheduledDeletionBefore: 'Slettes innen gitt dato',
      },
      titles: {
        customerDetails: 'KundeDetaljer',
        contact: 'Kontakt',
        subscriptionAndProducts: 'Abonnement og produkter',
        usersAndRegistration: 'Brukere og registrering',
        products: 'Produkter',
        createCustomer: 'Opprett ny kunde',
      },
    },
    contracts: {
      name: 'Kontrakt |||| Kontrakter',
    },
    journalArticles: {
      name: 'Tidsskriftsartikkel |||| Tidsskriftsartikler',
      fields: {
        title: 'Artikkeltittel',
        linked: 'Lenket',
      },
    },
    journalPublications: {
      name: 'Tidsskrifter',
      fields: {
        titles: 'Tidsskriftstittel og undertitler',
        readPermission: 'Lese-rettighet (fra Auth0)',
        piplingPatterns:
          'PIPling-mønstre (les mer på https://github.com/universitetsforlaget/juridika-litteratur-backend#pipling-pattern-for-idunn-path-lingua)',
      },
    },
    nikPublications: {
      name: 'Problemstilling |||| Problemstillinger',
      fields: {
        name: 'Navn',
        slug: 'Slug',
        description: 'Beskrivelse',
        title: 'Tittel',
        publisher: 'Forlag',
        publisherId: 'Forlag',
        primaryTag: "Primært rettsområde"
      },
    },
    nikEditions: {
      name: 'NIK Utgave |||| NIK Utgaver',
      fields: {
        name: 'Navn',
        publicationId: 'Problemstilling',
        publishedAt: 'Publisert på Juridika',
        contributorId: 'Navn',
        role: 'Rolle',
        contributionMetadata: 'Bidragsytere',
        contributorRelation: "Bidragsytere",
        contributorDetailId: "Tittel | Bio",
      },
    },
    nikDocuments: {
      name: 'NIK Dokument |||| NIK Dokumenter',
      fields: {
        editionId: 'Utgave (id)',
        createdAt: 'Opprettelsesdato',
        editorAccountId: 'Redigert av (id)',
      },
    },
    nikContributorDetails: {
      name: 'NIK Bidragsyter |||| NIK Bidragsytere',
      fields: {
        contributorId: 'Bidragsyter ID',
        shortBio: 'Kort bio',
        title: 'Stillingstittel',
      },
    },
    linkedJournals: {
      name: 'Tidsskrift-lenking',
      fields: {
        valid: 'Gyldig',
        title: 'Tittel',
      },
    },
    stripeSubscriptionProducts: {
      name: 'Kortbetaling priser',
      fields: {
        createdAt: 'Opprettet',
        name: 'Produkt',
      },
    },
    stripeSubscriptionPrices: {
      fields: {
        pricingInterval: 'Betalingshyppighet',
        priceInSubunit: 'Pris (inkl. mva.)',
        createdAt: 'Opprettet',
        active: 'Vises på juridika',
        currency: 'Valuta',
      },
    },
    publishers: {
      name: 'Forlag',
      fields: {
        slug: 'Slug',
        name: 'Navn',
        memberOfForleggerforeningen: 'Medlem av Forleggerforeningen',
      },
    },
    legalDocuments: {
      name: 'Rettskilde |||| Rettskilder',
      types: {
        law: 'Lov',
        regulation: 'Forskrift',
        regulation2: 'Forordning',
        directive: 'Direktiv',
        treaty: 'Traktat',
        hr: 'Høyesterettsdom',
      },
      countryCodes: {
        no: 'Norge',
        eu: 'EU',
      },
      fields: {
        work: {
          uri: 'URI (Unique resource identifier)',
          identifier: 'ID',
          countryCode: 'Land',
          documentType: 'Dokumenttype',
          subtype: 'Subtype',
          date: 'Lovdato',
          seq: 'Løpenummer',
          activeSourceSystem: 'Kildetype',
          noLongerInForceDate: 'Opphevelsesdato',
          isHistoryEnabled: 'Historikk påslått',
          listingTitle: 'Listetittel',
          favoriteCount: 'Antall ganger favorittmerket',
          expressions: 'Uttrykk',
        },
        expression: {
          inForceAt: 'Ikrafttredelsesdato',
          languageCode: 'Språk',
          newInForceDate: 'Ny ikrafttredelsesdato',
          versions: 'Versjoner',
        },
        version: {
          status: 'Status',
          shortTitle: 'Korttittel',
          fullTitle: 'Tittel',
        },
      },
      actions: {
        editDraft: 'Rediger utkast',
        viewPublished: 'Se uttrykk',
        openLinkInjector: 'Åpne lenke-injector',
        generateAsCopy: 'Lag nytt uttrykk',
        createDraft: 'Lag utkast',
        delete: 'Slett',
        filterByTitleOrExprId: 'Søk på tittel og lov-id',
        filterByCountryCode: 'Land',
        publish: 'Publiser',
      },
      notification: {
        generate_as_copy_success: 'Kopien ble opprettet',
        generate_as_copy_failure: 'Kopien kunne ikke opprettes',
        save_success: 'Dokumentet ble lagret',
        save_failure: 'Dokumentet kunne ikke lagres',
        publish_success: 'Dokumentet ble publisert',
        publish_failure: 'Publiseringen feilet',
        get_legal_document_xml_failure: 'Kunne ikke hente dokumentet',
      },
    },
    legalWorks: {
      name: 'Rettsreferanse |||| Alle rettsreferanser',
      namePlural: 'Rettsreferanser',
    },
    commentaries: {
      actions: {
        edit: 'Se kommentarer',
        create: 'Se kommentarer (tom)',
        openLinkInjector: 'Åpne lenke-injector',
        collapse_all_toc_items: 'Kollaps alle',
        expand_all_toc_items: 'Utvid alle',
        hightlight_empty_commentaries: 'Marker tilnærmet tomme kommentarer',
        remove_empty_commentaries_highlight: 'Fjern markering av tilnærmet tomme kommentarer',
        check_all_fragments: 'Kryss av alle',
        check_all_fragments_tooltip: 'Kryss av alle kommentarer',
        uncheck_all_fragments: 'Fjern alle',
        uncheck_all_fragments_tooltip: 'Fjern alle avkryssede kommentarer',
        publish_all: 'Publisér',
        publish_all_tooltip: 'Publisér alle avkryssede upubliserte kommentarer',
        publish_all_confirm: 'Publisér %{count} upubliserte kommentarer?',
        overwrite_last_reviewed: 'Oppdater à jour-dato',
        overwrite_last_reviewed_tooltip: 'Oppdater à jour-dato for avkryssede kommentarer ',
        overwrite_last_reviewed_confirm:
          'Oppdater à jour-dato for %{count} kommentarer? (YYYY-MM-DD)',
        overwrite_last_reviewed_invalid_date: 'Kunne ikke oppdatere: ugyldig dato "%{date}"',
        create_as_copy: 'Lag ny versjon',
      },
      notification: {
        publish_commentary_success: 'Kommentaren ble publisert',
        publish_commentaries_success: 'Kommentarenene ble publisert',
        publish_commentary_failure: 'Kommentaren kunne ikke publiseres%{msg}',
        publish_commentaries_failure: 'Kommentarene kunne ikke publiseres%{msg}',
        overwrite_last_reviewed_success: 'À jour-dato oppdatert',
        overwrite_last_reviewed_failure: 'Kunne ikke oppdatere: %{msg}',
        save_commentary_success: 'Kommentaren ble lagret',
        save_commentary_failure: 'Kommentaren kunne ikke lagres: %{msg}',
        create_as_copy_success: 'Kopien ble opprettet',
        create_as_copy_failure: 'Kopien kunne ikke opprettes',
        create_commentary_success: 'Kommentaren ble opprettet',
        create_commentary_failure: 'Kommentaren kunne ikke opprettes',
        get_commentary_meta_failure: 'Kunne ikke hente kommentarmetadata',
      },
      notices: {
        is_draft:
          'Dette dokumentet er ennå ikke publisert. Trykk på publiserknappen i verktøylinjen for å gjøre dokumentet synlig på juridika.no.',
        is_published:
          'Dette dokumentet er publisert og endringer vil ikke bli lagret. Klikk på knappen under for å lage en ny versjon som kopi av dette dokumentet.',
      },
    },
    expressions: {
      notification: {
        get_all_expressions_with_comment_failure: 'Informasjon om kommentarer er ikke tilgjengelig',
      },
    },
    expression_toc: {
      notification: {
        generate_toc_failure: 'Kunne ikke generere innholdsfortegnelse',
      },
    },
    commentMeta: {
      field: {
        published_at: 'Publisert',
        last_updated: 'Sist oppdatert',
        created: 'Opprettet',
      },
      text: {
        published_at: 'Publisert: %{publishedAt}',
        last_updated_at: 'Sist oppdatert: %{lastUpdatedAt} av %{lastUpdatedBy}',
        created_at: 'Opprettet: %{createdAt} av %{createdBy}',
      },
    },
    excerpts: {
      notification: {
        get_excerpt_failure: 'Kunne ikke laste ned utdrag',
      },
    },
    reports: {
      name: 'Rapporter Juridika',
    },
    bitsview: {
      name: 'BITS-visning',
    },
    bitsDrafts: {
      name: 'BITS-utkast',
    },
  },
  fileDropzone: {
    notification: {
      dropRejected: 'Filen kunne ikke legges til',
    },
  },
  fileUpload: {
    notification: {
      upload_failure: 'Filen kunne ikke lastes opp',
    },
  },
  slate: {
    contextMenu: {
      markFormats: {
        bold: 'Fet',
        italic: 'Kursiv',
        underline: 'Understreking',
        strikethrough: 'Gjennomstreking',
        cite: 'Cite',
      },
      specialCharacter: {
        name: 'Sett inn spesialtegn',
        softHyphen: 'Soft hyphen',
      },
      box: 'Bokser',
      figure: 'Figur/bilde',
      image: 'Bilde',
      headings: {
        name: 'Overskrift',
        heading1: 'Tittel 1',
        heading2: 'Tittel 2',
        heading3: 'Tittel 3',
        heading4: 'Tittel 4',
        leadParagraph: 'Ingress',
      },
      blockFloat: 'Plassering',
      link: 'Sett inn link',
      list: 'Liste',
      lists: {
        bulletedList: 'Punktliste',
        numberedList: 'Nummerert liste',
      },
      quote: 'Sitat',
      asideFacts: 'Faktaboks på siden',
      recommendedPages: 'Anbefalte artikler',
      table: {
        name: 'Tabell',
        deleteRow: 'Slett rad',
        insertRow: 'Legg til rad',
        deleteColumn: 'Slett kolonne',
        insertColumn: 'Legg til kolonne',
      },
    },
    recommendedArticlesPrefix: {
      new: 'Sist publiserte',
      popular: 'Mest populære',
      recommended: 'Juridikas anbefalte',
    },
  },
  other: {
    injectLinks: 'Kjør lenkescript',
  },
};
