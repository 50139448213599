import React from 'react';
import { connect } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Button, fetchStart, fetchEnd } from 'react-admin';

import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';
import { CustomState } from '../../state/customState';
import {
  publishStaticContentRevision,
  unpublishStaticContentRevision,
  SnapshotInfo,
} from '../../state/publishing/publishingActions';
import { PublishContentDialogForm } from '../../components/PublishContentDialogForm';
import { dateTimeFromTemplate, TimeTemplate } from '../../util/publishingUtils';

interface FormData {
  timeTemplate: TimeTemplate;
  customDate: Date;
}

const PUBLISH_BUTTON_LABEL = 'resources.revisions.actions.publish';
const UNPUBLISH_LABEL = 'Avpubliser';

export interface OwnProps {
  snapshotInfo: SnapshotInfo;
  revision: StaticDocumentRevision;
  dialogLabel: string;
}

interface DispatchProps {
  fetchStart: () => void;
  fetchEnd: () => void;
  publishRevisionRequest: typeof publishStaticContentRevision.request;
  unpublishRevisionRequest: typeof unpublishStaticContentRevision.request;
}

interface StateProps {
  isSubmitting: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  showDialog: boolean;
}

const DialogMessage: React.FC<{ snapshotInfo: SnapshotInfo }> = ({ snapshotInfo }) => {
  if (snapshotInfo.snapshotType === 'page' && snapshotInfo.slug !== null) {
    return (
      <>
        <DialogContentText>
          Siden blir publisert med slug <code>{snapshotInfo.slug}</code>.
        </DialogContentText>
        <DialogContentText>NB: Husk også å opprette og publisere en notis.</DialogContentText>
      </>
    );
  }

  if (snapshotInfo.snapshotType === 'embedded' && snapshotInfo.foreignType === 'contributor_bio') {
    return (
      <>
        <DialogContentText>
          Når denne omtalen publiseres og forfatteren ikke allerede er profilert, skal forfatteren
          automatisk bli satt til å være profilert (d.v.s. vises i forfatteroversikten)
        </DialogContentText>
      </>
    );
  }

  return null;
};

class PublishRevisionButton extends React.PureComponent<Props, State> {
  state = {
    showDialog: false,
  };

  defaultFormData = (): FormData => ({
    timeTemplate: 'now',
    customDate: new Date(),
  });

  handleButtonClick = () => {
    this.setState({ showDialog: true });
  };

  handleDialogCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handlePublishFormSubmit = (values: FormData) => {
    const date = dateTimeFromTemplate(values.timeTemplate, values.customDate);

    this.props.publishRevisionRequest({
      id: this.props.revision.id,
      snapshotInfo: this.props.snapshotInfo,
      date: date,
    });
    this.setState({ showDialog: false });
  };

  handleUnpublishClick = () => {
    this.props.unpublishRevisionRequest({
      id: this.props.revision.id,
    });
    this.setState({ showDialog: false });
  };

  render() {
    const { snapshotInfo, revision, dialogLabel, isSubmitting } = this.props;
    const { showDialog } = this.state;

    const isPublished = revision.publishedAt !== null;
    const pageMissingSlugError =
      !isPublished && snapshotInfo.snapshotType === 'page' && snapshotInfo.slug === null;
    const canPublish = !isSubmitting && !isPublished && !pageMissingSlugError;
    const canUnpublish = !isSubmitting && isPublished;
    const canPerformAction = canPublish || canUnpublish;
    const buttonLabel = canPublish ? PUBLISH_BUTTON_LABEL : UNPUBLISH_LABEL;

    return (
      <React.Fragment>
        {isSubmitting && <LinearProgress color="secondary" />}
        {canPerformAction && (
          <Button label={buttonLabel} onClick={this.handleButtonClick}>
            {canPublish ? <PublishIcon /> : <ClearIcon />}
          </Button>
        )}
        {pageMissingSlugError && (
          <Typography color="error">Kan ikke publisere uten slug</Typography>
        )}
        {canPublish && (
          <PublishContentDialogForm
            open={showDialog}
            dialogLabel={dialogLabel}
            submitLabel={buttonLabel}
            extraContent={<DialogMessage snapshotInfo={snapshotInfo} />}
            onSubmit={this.handlePublishFormSubmit}
            onClose={this.handleDialogCloseClick}
            initialValues={this.defaultFormData}
            isSubmitting={isSubmitting}
          />
        )}
        {canUnpublish && (
          <Dialog
            fullWidth
            open={showDialog}
            onClose={this.handleDialogCloseClick}
            aria-label={UNPUBLISH_LABEL}
          >
            <DialogTitle>{UNPUBLISH_LABEL}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Er du sikker på at du vil avpublisere revisjonen publisert
                {revision.publishedAt ? revision.publishedAt.toLocaleString() : 'N/A'}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MuiButton onClick={this.handleDialogCloseClick} color="primary">
                Avbryt
              </MuiButton>
              <MuiButton onClick={this.handleUnpublishClick} color="primary" autoFocus>
                Ja
              </MuiButton>
            </DialogActions>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: CustomState, ownProps: OwnProps): StateProps => ({
  isSubmitting:
    (state.publishing[ownProps.revision.id] || { status: 'done' }).status === 'uploading',
});

const mapDispatchToProps: DispatchProps = {
  fetchStart,
  fetchEnd,
  publishRevisionRequest: publishStaticContentRevision.request,
  unpublishRevisionRequest: unpublishStaticContentRevision.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublishRevisionButton);
