import React from 'react';
import BEMHelper from 'react-bem-helper';

import Plus from '../../Plus';
import Minus from '../../Minus';

import CommentaryMeta from './CommentaryMeta';

import {
  CommentaryMetadataForAllExpressionFragments,
  EId,
  CommentMeta,
  Toc,
  TocItem as TocItemInterface,
} from '../../../state/expressions/types';

import '../../../styles/commentariesEditor/toc.css';

const classes = new BEMHelper({
  name: 'toc',
});

const TocItem: React.FC<{
  activeFragmentId: string;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
  handleSelectFragment(fragmentId: string): void;
  handleToggle(eId: EId): void;
  item: TocItemInterface;
  openTocItems: EId[];
  shouldExpandAllTocItems: boolean;
  shouldHighlightEmptyCommentaries: boolean;
  isChecked(fragmentId: string): boolean;
  toggleChecked(fragmentId: string): void;
}> = ({
  item,
  handleToggle,
  openTocItems,
  handleSelectFragment,
  activeFragmentId,
  commentaryMeta,
  shouldExpandAllTocItems,
  shouldHighlightEmptyCommentaries,
  isChecked,
  toggleChecked,
}) => {
  const { heading, children, eId } = item;
  const isOpen = shouldExpandAllTocItems || openTocItems.includes(eId);
  const fragmentMeta = commentaryMeta[eId];

  const numCharsArray: CommentMeta[] = [
    fragmentMeta?.draft,
    ...(fragmentMeta?.published || []),
    fragmentMeta?.old,
  ].filter((v) => v !== null) as CommentMeta[];
  const hasEmptyComment = !!(
    shouldHighlightEmptyCommentaries &&
    numCharsArray.find((version) => {
      return version.numChars < 400;
    })
  );

  return (
    <li {...classes('item')}>
      <div {...classes('heading', { isActiveFragment: eId === activeFragmentId, hasEmptyComment })}>
        <input onChange={() => toggleChecked(eId)} checked={isChecked(eId)} type="checkbox" />
        {fragmentMeta && <CommentaryMeta eId={eId} metadata={fragmentMeta} />}
        <button {...classes('selectAction')} onClick={() => handleSelectFragment(eId)}>
          {heading}
        </button>
        {children && children.length > 0 && (
          <button {...classes('toggleItemAction')} onClick={() => handleToggle(eId)}>
            {isOpen ? <Minus /> : <Plus />}
          </button>
        )}
      </div>
      {children && children.length > 0 && (
        <TocList
          {...classes('sublist', { isOpen })}
          isOpen={openTocItems.includes(eId)}
          handleToggle={handleToggle}
          tocList={children}
          openTocItems={openTocItems}
          shouldExpandAllTocItems={shouldExpandAllTocItems}
          handleSelectFragment={handleSelectFragment}
          activeFragmentId={activeFragmentId}
          commentaryMeta={commentaryMeta}
          shouldHighlightEmptyCommentaries={shouldHighlightEmptyCommentaries}
          isChecked={isChecked}
          toggleChecked={toggleChecked}
        />
      )}
    </li>
  );
};

export const TocList: React.FC<{
  activeFragmentId: string;
  className: string;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
  handleSelectFragment(fragmentId: string): void;
  handleToggle(eId: EId): void;
  isOpen?: boolean;
  openTocItems: EId[];
  shouldExpandAllTocItems: boolean;
  shouldHighlightEmptyCommentaries: boolean;
  tocList: Toc;
  isChecked(fragmentId: string): boolean;
  toggleChecked(fragmentId: string): void;
}> = ({
  activeFragmentId,
  className,
  commentaryMeta,
  handleSelectFragment,
  handleToggle,
  openTocItems,
  shouldExpandAllTocItems,
  shouldHighlightEmptyCommentaries,
  tocList,
  isChecked,
  toggleChecked,
}) => (
  <ul className={className}>
    {tocList.map((item: TocItemInterface) => {
      return (
        <TocItem
          key={`tocItem_${item.eId}`}
          activeFragmentId={activeFragmentId}
          commentaryMeta={commentaryMeta}
          handleSelectFragment={handleSelectFragment}
          handleToggle={handleToggle}
          item={item}
          openTocItems={openTocItems}
          shouldExpandAllTocItems={shouldExpandAllTocItems}
          shouldHighlightEmptyCommentaries={shouldHighlightEmptyCommentaries}
          isChecked={isChecked}
          toggleChecked={toggleChecked}
        />
      );
    })}
  </ul>
);
