import * as React from 'react';
import { Filter, TextInput } from 'react-admin';

const LiteraturePublicationFilter: React.FC<{}> = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export default LiteraturePublicationFilter;
