import * as hast from '@universitetsforlaget/hast';

export interface IFrameProps {
  name?: string;
  src?: string;
  srcDoc?: string;
  width?: number | string;
  height?: number | string;
  sandbox?: string;
}

export interface IFrameResult {
  iframeProps?: IFrameProps;
  error?: string;
}

const hastHtml5DeserializationConfig = hast.html5DeserializationConfig();

export const parseHtmlIframe = (html: string): IFrameResult => {
  try {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const hastBody = hast.domNodeToHast(doc.body, hastHtml5DeserializationConfig);
    if (!hastBody) return { error: 'HTML er tom eller kan ikke leses' };
    if (hastBody.type !== 'element') return { error: 'HTML er ikke et element' };
    if (hastBody.tagName !== 'body') return { error: 'HTML ble ikke riktig parset' };
    if (!hastBody.children) return { error: 'HTML inneholder ingen tagger' };
    if (hastBody.children.length !== 1) return { error: 'HTML inneholder for mange tagger' };

    const child = hastBody.children[0];

    if (child.type !== 'element') return { error: 'HTML-taggen er ikke et element' };
    if (child.tagName !== 'iframe') return { error: 'HTML-taggen er ikke en iframe' };

    const properties = child.properties;

    if (!properties) return { error: 'Ingen attributter på iframen' };

    return {
      iframeProps: {
        ...(properties.name && { name: properties.name }),
        ...(properties.src && { src: properties.src }),
        ...(properties.srcDoc && { srcDoc: properties.srcDoc }),
        ...(properties.width && { width: properties.width }),
        ...(properties.height && { height: properties.height }),
        ...(properties.sandbox && { sandbox: properties.sandbox }),
      },
    };
  } catch (error) {
    return { error: 'HTML er ugyldig' };
  }
};
