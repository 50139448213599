import React from 'react';
import {
  List,
  Filter,
  Datagrid,
  ReferenceField,
  TextField,
  SelectInput,
  TextInput,
  ReferenceInput,
  EditButton,
  ArrayField,
  SingleFieldList,
  useListContext,
} from 'react-admin';

import { ContractSnapshot } from '../../dataProvider/contractSnapshotsProvider';

import DateTimeField from '../DateTimeField';
import ModifyTagsButton from '../documentTags/ModifyTagsButton';
import { TagChipField } from '../StyledChipFields';

import { ContractSnapshotExpandShow } from './ContractSnapshotDetails';

const PageSnapshotBulkActionButtons = (props: any) => {
  const { data } = useListContext<ContractSnapshot>();

  return (
    <React.Fragment>
      <ModifyTagsButton
        tagResource="staticDocumentTags"
        tagPrefixes={['rettsområde', 'mal', 'freebie']}
        getTagPathsOnSelectedIds={() => {
          const set: Set<string> = new Set();
          if (props.selectedIds) {
            props.selectedIds.forEach((id: string) => {
              data[id]?.revision?.tags?.forEach((tag: { id: string; name: string }) => {
                set.add(tag.id);
              });
            });
          }
          return set;
        }}
      />
    </React.Fragment>
  );
};

const ContractFilter: React.FC<any> = (props) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput label="resources.pageSnapshots.actions.filterByTitle" source="q" alwaysOn />
    <ReferenceInput
      label="resources.pageSnapshots.fields.revision.tags"
      source="revision.tags"
      reference="tags"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const ContractSnapshotList: React.FC<any> = (props) => (
  <List
    {...props}
    filters={<ContractFilter />}
    perPage={25}
    bulkActionButtons={<PageSnapshotBulkActionButtons />}
  >
    <Datagrid expand={<ContractSnapshotExpandShow />}>
      <TextField source="revision.title" sortable={false} />

      <ArrayField source="revision.tags">
        <SingleFieldList>
          <TagChipField source="displayName" />
        </SingleFieldList>
      </ArrayField>

      <DateTimeField source="lastPublishedAt" sortable={false} />

      <ReferenceField source="currentEditorId" reference="editorAccounts" sortable={false}>
        <TextField source="name" />
      </ReferenceField>

      <EditButton onClick={(e: React.MouseEvent) => e.stopPropagation()} />
    </Datagrid>
  </List>
);
