import React from 'react';
import { FileInput, FileField, ImageField, SimpleForm, useTranslate } from 'react-admin';
import BEMHelper from 'react-bem-helper';

import '../styles/fileDropzone.css';

const classes = new BEMHelper({
  name: 'fileDropzone',
});

interface Props {
  accept?: string;
  label?: string | null;
  dropzoneProperties?: {};
  save: (props: any) => void;
  errorState?: string;
  setErrorState?: (message: string) => void;
}

const FilePreview = ({ source, title, record = {} }: any) => {
  const {
    rawFile: { type: fileType },
  } = record;
  const PreviewField = fileType.includes('image/') ? ImageField : FileField;

  return <PreviewField source={source} title={title} record={record} />;
};

const FileDropzone: React.FC<Props> = ({
  accept,
  dropzoneProperties = {},
  label = null,
  save,
  errorState,
  setErrorState = (message) => {},
}: Props) => {
  const translate = useTranslate();
  const onDropRejected = () => setErrorState('fileDropzone.notification.dropRejected');

  return (
    <div {...classes()}>
      {errorState && <div {...classes('error')}>{translate(errorState)}</div>}
      <SimpleForm save={save} variant="standard" margin="normal">
        <FileInput
          options={{
            ...classes('dropzone'),
            onDropRejected: onDropRejected,
            ...dropzoneProperties,
          }}
          source="files"
          label={label}
          accept={accept}
        >
          <FilePreview source="src" title="title" />
        </FileInput>
      </SimpleForm>
    </div>
  );
};

export default FileDropzone;
