import * as Slate from 'slate';

export const isPointAtRoot = (point: Slate.Point) => point.path.length === 2;

export const isRangeAtRoot = (range: Slate.Range) =>
  isPointAtRoot(range.anchor) || isPointAtRoot(range.focus);

export const getAboveByType = (editor: Slate.Editor, types: string[] | string, at?: Slate.Path) => {
  return Slate.Editor.above(editor, {
    match: (n) => (typeof types === 'string' ? [types] : types).includes(n.type as string),
    at,
  });
};

export const getBlockAbove = (editor: Slate.Editor): Slate.NodeEntry<Slate.Ancestor> =>
  Slate.Editor.above(editor, {
    match: (n) => Slate.Editor.isBlock(editor, n),
  }) || [editor, []];

export const getNextSiblingNodes = (
  ancestorEntry: Slate.NodeEntry<Slate.Ancestor>,
  path: Slate.Path
) => {
  const [ancestor, ancestorPath] = ancestorEntry;
  const leafIndex = path[ancestorPath.length];
  const siblings: Slate.Descendant[] = [];
  if (leafIndex + 1 < ancestor.children.length) {
    for (let i = leafIndex + 1; i < ancestor.children.length; i++) {
      siblings.push(ancestor.children[i]);
    }
  }

  return siblings;
};

export const isBlockAboveEmpty = (editor: Slate.Editor) => {
  const [node] = getBlockAbove(editor);
  return !Slate.Node.string(node) && !node.children.some((n) => Slate.Editor.isInline(editor, n));
};

export const isCollapsed = (range?: Slate.Range | null) =>
  !!range && Slate.Range.isCollapsed(range);

export const isSelectionAtBlockStart = (editor: Slate.Editor) => {
  const [, path] = getBlockAbove(editor);

  const point = editor.selection?.focus;
  return !!point && Slate.Editor.isStart(editor, point, path);
};

export const isMarkActive = (editor: Slate.Editor, format: string) => {
  const marks = Slate.Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isBlockTextEmptyAfterSelection = (editor: Slate.Editor) => {
  if (!editor.selection) return false;

  const blockAbove = getBlockAbove(editor);

  const cursor = editor.selection.focus;
  if (!Slate.Editor.isEnd(editor, cursor, Slate.Editor.parent(editor, editor.selection)[1]))
    return false;

  const siblingNodes = getNextSiblingNodes(blockAbove, cursor.path);
  if (siblingNodes.length) {
    for (const siblingNode of siblingNodes) {
      if (siblingNode.text) {
        return false;
      }
    }
  } else {
    return Slate.Editor.isEnd(editor, cursor, blockAbove[1]);
  }

  return true;
};

export const tryGetNode = (editor: Slate.Editor, path: Slate.Path) => {
  try {
    return Slate.Node.get(editor, path);
  } catch (error) {
    return null;
  }
};
