import React from 'react';
import { Button, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';

export const ImportBookButton: React.FC<{
  label: string;
  processId: string;
  endpoint: 'importLatestBitsFormat' | 'importPdfFile';
}> = (props) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const importUrl = `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionProcesses/${props.processId}/${props.endpoint}`;

  const doImport = async () => {
    dispatch(fetchStart());

    try {
      const response = await fetch(importUrl, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      await response.text();
    } catch (error) {
      notify(`Problem importing: ${error}`, 'error');
    }

    dispatch(fetchEnd());
    refresh();
  };

  return (
    <Button label={props.label} color="primary" onClick={doImport}>
      <AccountTreeIcon />
    </Button>
  );
};

export default ImportBookButton;
