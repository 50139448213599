import React from 'react';
import {
  Create,
  SimpleForm,
  DateInput,
  SelectInput,
  required,
  NumberInput,
  TextInput,
} from 'react-admin';

import {
  ACT_COUNTRIES,
  ACT_SUBTYPES,
  DOCUMENT_TYPES,
  SOURCE_SYSTEMS,
} from '../../../state/legalDocuments/types';
import { XOPUS_SOURCE_SYSTEM } from '../../../dataProvider/lovbackend/legalDocumentsProvider';

const LegalWorkCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm variant="standard" margin="normal">
      <SelectInput
        label="resources.legalDocuments.fields.work.countryCode"
        source="countryCode"
        defaultValue={ACT_COUNTRIES[0].id}
        choices={ACT_COUNTRIES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.documentType"
        source="documentType"
        defaultValue={DOCUMENT_TYPES[1].id}
        choices={DOCUMENT_TYPES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.subtype"
        source="subtype"
        choices={ACT_SUBTYPES}
        validate={required()}
      />
      <DateInput
        label="resources.legalDocuments.fields.work.date"
        source="date"
        validate={required()}
      />
      <NumberInput
        min="0"
        step="1"
        label="resources.legalDocuments.fields.work.seq"
        source="seq"
        validate={required()}
      />
      <TextInput
        min="0"
        step="1"
        label="resources.legalDocuments.fields.work.listingTitle"
        source="listingTitle"
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.activeSourceSystem"
        source="sourceSystem"
        defaultValue={XOPUS_SOURCE_SYSTEM}
        choices={SOURCE_SYSTEMS}
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

export default LegalWorkCreate;
