import { ActionType, getType } from 'typesafe-actions';

import * as publishingActions from './publishingActions';

type PublishingAction = ActionType<typeof publishingActions>;

export interface ItemPublishingState {
  readonly status: 'uploading' | 'done' | 'error';
  readonly error: string | null;
}

export interface PublishingState {
  readonly [id: string]: ItemPublishingState;
}

export default (state: PublishingState = {}, action: PublishingAction): PublishingState => {
  switch (action.type) {
    case getType(publishingActions.publishStaticContentRevision.request):
    case getType(publishingActions.unpublishStaticContentRevision.request):
    case getType(publishingActions.publishTextbookEdition.request):
    case getType(publishingActions.unPublishTextbookEdition.request):
      return {
        ...state,
        [action.payload.id]: {
          status: 'uploading',
          error: null,
        },
      };
    case getType(publishingActions.publishJournalArticles.request):
      return action.payload.ids.reduce(
        (agg, id) => ({
          [id]: {
            status: 'uploading',
            error: null,
          },
          ...agg,
        }),
        state
      );

    case getType(publishingActions.publishStaticContentRevision.success):
    case getType(publishingActions.unpublishStaticContentRevision.success):
    case getType(publishingActions.publishTextbookEdition.success):
    case getType(publishingActions.unPublishTextbookEdition.success):
      const { [action.payload.id]: _ignored, ...rest } = state;
      return rest;
    case getType(publishingActions.publishJournalArticles.success):
      return action.payload.ids.reduce((agg, id) => {
        const { [id]: _ignored, ...rest } = agg;
        return rest;
      }, state);
    case getType(publishingActions.publishStaticContentRevision.failure):
    case getType(publishingActions.unpublishStaticContentRevision.failure):
    case getType(publishingActions.publishTextbookEdition.failure):
    case getType(publishingActions.unPublishTextbookEdition.failure):
      return {
        ...state,
        [action.payload.id]: {
          status: 'error',
          error: action.payload.error,
        },
      };
    case getType(publishingActions.publishJournalArticles.failure):
      return action.payload.ids.reduce(
        (agg, id) => ({
          [id]: {
            status: 'error',
            error: action.payload.error,
          },
          ...agg,
        }),
        state
      );
    default:
      return state;
  }
};
