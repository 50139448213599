import { ActionType, getType } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';

import { BackendUrls } from '../../dataProvider/types';
import { ImageAPI } from '../../dataProvider/imageAPI';

import { uploadStaticImage } from './staticFilesActions';
import { showNotification } from 'react-admin';

export const createStaticFilesUploadWatcherSaga = (urls: BackendUrls) => {
  const imageApi = new ImageAPI(urls);

  return function* () {
    yield takeEvery(getType(uploadStaticImage.request), function* (
      action: ActionType<typeof uploadStaticImage.request>
    ) {
      yield call(uploadImageSaga, imageApi, action.payload.uuid, action.payload.file);
    });
  };
};

function* uploadImageSaga(imageApi: ImageAPI, uuid: string, file: File) {
  try {
    const fileHandle = yield call(imageApi.upload, file);
    if (fileHandle.status) {
      // On success there is no status
      yield put(
        showNotification(
          'resources.pageSnapshots.notification.upload_image_failure',
          'warning', // To show red notification box for visibility
          {
            messageArgs: {
              msg: fileHandle.message ? `Melding fra backend: ${fileHandle.message}` : '',
            },
          }
        )
      );
      yield put(uploadStaticImage.failure({ uuid, error: fileHandle.error }));
    } else {
      yield put(uploadStaticImage.success({ uuid, fileid: fileHandle.id, url: fileHandle.url }));
    }
  } catch (error) {
    yield put(uploadStaticImage.failure({ uuid, error: error }));
  }
}
