import * as React from 'react';
import * as Slate from 'slate';

import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import { insertEmptyTable } from './transforms';

const Icon: React.FC<{ disabled?: boolean }> = (props) => {
  return (
    <svg
      style={{ fill: props.disabled ? 'grey' : 'white' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M18 14h2v3h3v2h-3v3h-2v-3h-3v-2h3v-3M4 3h14a2 2 0 012 2v7.08a6.01 6.01 0 00-4.32.92H12v4h1.08c-.11.68-.11 1.35 0 2H4a2 2 0 01-2-2V5a2 2 0 012-2m0 4v4h6V7H4m8 0v4h6V7h-6m-8 6v4h6v-4H4z"></path>
    </svg>
  );
};

const IconDisabled: React.FC<{}> = (props) => {
  return <Icon disabled={true} />;
};

const InsertTableButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  const isActive = !getAboveByType(editor, tags.BLOCK_TABLE);

  return (
    <SlateToolbarButton
      isActive={isActive}
      Icon={isActive ? Icon : IconDisabled}
      message="slate.contextMenu.table.name"
      onMouseDown={() => insertEmptyTable(editor)}
    />
  );
};

export default InsertTableButton;
