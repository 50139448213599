import * as Slate from 'slate';
import { HastElementNode, HastNode } from '@universitetsforlaget/hast';
import { createFragment } from '@universitetsforlaget/hast';
import {
  HypertextRuleSets,
  HypertextSerializationRuleSets,
  HypertextSerializationRule,
} from '../../slate/serializer/rules';
import {
  HypertextDeserializationRule,
  HypertextDeserializationRuleSet,
  SlateAttributeData,
} from './types';
import { concat, makeDeserializationRuleSet } from './utils';

export class HastSerializer {
  readonly deserializationRules: HypertextDeserializationRuleSet;

  constructor(
    readonly options: {
      hypertextRules: HypertextRuleSets;
      serializationRules: HypertextSerializationRuleSets;
    }
  ) {
    this.deserializationRules = {
      ...makeDeserializationRuleSet('block', options.hypertextRules.blocks),
      ...makeDeserializationRuleSet('inline', options.hypertextRules.inlines),
      ...makeDeserializationRuleSet('mark', options.hypertextRules.marks),
    };
  }

  private deserializeAttributesByRule = (
    node: HastElementNode,
    elementClassNames: string[],
    rule: HypertextDeserializationRule
  ): SlateAttributeData | null => {
    let attributes: SlateAttributeData = {};

    if (elementClassNames.length > 0) {
      attributes = { className: elementClassNames };
    }

    if (node.properties?.tag) {
      attributes['tag'] = node.properties.tag;
    }

    if (rule.data) {
      if (!node.properties) {
        throw new Error(`no properties set on hast node,
          while rule demands for: ${node.type}, required properties: ${rule.data}`);
      }

      attributes = rule.data.reduce(
        (data, key) => ({
          ...data,
          [key]: node.properties![key],
        }),
        attributes
      );
    }

    if (rule.optionalData) {
      attributes = {
        ...attributes,
        ...rule.optionalData.reduce((data, key) => {
          if (!node.properties || !node.properties[key]) {
            return data;
          }
          return {
            ...data,
            [key]: node.properties[key],
          };
        }, attributes),
      };
    }

    return attributes;
  };

  private deserializeElementByRule = (
    node: HastElementNode,
    rule: HypertextDeserializationRule,
    marks: string[]
  ): Slate.Node[] | null => {
    const elementClassNames = node.properties?.className ?? [];

    if (rule.className && elementClassNames.indexOf(rule.className) < 0) {
      return null;
    }

    const currentMarks = rule.isMark ? [rule.type, ...marks] : marks;
    const attributes = this.deserializeAttributesByRule(node, elementClassNames, rule);
    const childElements = this.deserializeElements(node.children, currentMarks);

    if (rule.isMark) {
      return childElements;
    }

    return [
      {
        ...attributes,
        children: childElements ?? [{ text: '' }],
        type: rule.type,
        ...(rule.isInline && { isInline: rule.isInline }),
      },
    ];
  };

  private deserializeElement = (node: HastNode, marks: string[]): Slate.Node[] => {
    if (node.type !== 'element') {
      throw new Error('cannot deserialize a non-element node by rule');
    }
    const rules = this.deserializationRules[node.tagName];

    if (!rules) {
      if (node.tagName === 'fragment') {
        return this.deserializeElements(node.children || []);
      }
      throw new Error(`No result found for tag: ${node.tagName}`);
    }

    for (const rule of rules) {
      const deserializedElement = this.deserializeElementByRule(node, rule, marks);
      if (deserializedElement !== null) {
        return deserializedElement;
      }
    }

    throw new Error(
      `No rule for tagName ${node.tagName}, node ${JSON.stringify(
        node
      )}. Looked at: ${JSON.stringify(rules)}`
    );
  };

  private deserializeNode = (node: HastNode, marks: string[]): Slate.Node[] => {
    if (node.type === 'text') {
      const textNode = { text: node.value };
      if (marks.length > 0) {
        return [marks.reduce((previous, current) => ({ ...previous, [current]: true }), textNode)];
      } else {
        return [textNode];
      }
    }

    return this.deserializeElement(node, marks);
  };

  private deserializeElements = (
    nodes: HastNode[] | undefined,
    marks: string[] = []
  ): Slate.Node[] => {
    if (!nodes || nodes.length === 0) return [{ text: '' }];
    return nodes.map((node) => this.deserializeNode(node, marks)).reduce(concat, []);
  };

  private serializeTextNode = (node: Slate.Text): HastNode => {
    let hastNode: HastNode = {
      value: node.text,
      type: 'text',
    };

    for (const mark in node) {
      if (mark === 'text') continue;
      const rule = this.options.serializationRules['marks'][mark];
      hastNode = {
        tagName: rule.htmlTag,
        children: [hastNode],
        type: 'element',
      };
    }

    return hastNode;
  };

  private getSerializationRule = (element: Slate.Element): HypertextSerializationRule | null => {
    return (
      this.options.serializationRules['marks'][element.type as string] ??
      this.options.serializationRules['inlines'][element.type as string] ??
      this.options.serializationRules['blocks'][element.type as string]
    );
  };

  private serializeClassNames = (element: Slate.Element): string[] => {
    if (!element.className) return [];
    if (Array.isArray(element.className)) return element.className as string[];
    else return [element.className as string];
  };

  private serializeElementProperties = (
    element: Slate.Element,
    rule: HypertextSerializationRule
  ): { [key: string]: any } => {
    const classNames = [
      ...(rule.className ? [rule.className] : []),
      ...this.serializeClassNames(element).filter(
        (className) => rule.optionalClassNames && rule.optionalClassNames.includes(className)
      ),
    ];

    const rest = Object.keys(element).reduce((acc, key) => {
      if (
        key === 'isInline' ||
        key === 'type' ||
        key === 'children' ||
        key === 'className' ||
        !element[key]
      ) {
        return acc;
      }
      return { ...acc, [key]: element[key] };
    }, {});

    return {
      ...(classNames.length > 0 && { className: classNames }),
      ...rest,
    };
  };

  private serializeElementNode = (element: Slate.Element): HastNode[] => {
    const rule = this.getSerializationRule(element);

    if (!rule) {
      throw new Error(`Cannot find rule to serialize ${JSON.stringify(element)}`);
    }

    const children = element.children.reduce(
      (hast: HastNode[], child: Slate.Node) => [...hast, ...this.serializeNode(child)],
      []
    );

    if (element.isMark) {
      return children;
    }

    const properties = this.serializeElementProperties(element, rule);

    return [
      {
        tagName: rule.htmlTag,
        ...(Object.keys(properties).length > 0 && { properties }),
        ...(Object.keys(children).length > 0 && { children }),
        type: 'element',
      },
    ];
  };

  private serializeNode = (node: Slate.Node): HastNode[] => {
    if (Slate.Text.isText(node)) {
      if (node.text === '') return [];
      return [this.serializeTextNode(node as Slate.Text)];
    }

    return this.serializeElementNode(node as Slate.Element);
  };

  serialize = (value: Slate.Node[]): HastElementNode => {
    return createFragment(
      ...value.reduce(
        (hast: HastNode[], node: Slate.Node) => [...hast, ...this.serializeNode(node)],
        []
      )
    );
  };

  deserialize = (hast: HastElementNode): Slate.Node[] => {
    if (!hast.children) {
      return [];
    }

    if (hast.tagName === 'fragment') {
      return this.deserializeElements(hast.children);
    } else {
      return this.deserializeElement(hast, []);
    }
  };
}
