import * as React from 'react';
import { useQuery } from 'react-admin';

import { Product } from '../../../dataProvider/customerProductsProvider';
import { SubscriptionPackage } from '../../../dataProvider/subscriptionPackageProvider';
import { SubscriptionStatus } from '../../../dataProvider/subscriptionStatusProvider';

export interface AutoSuggestData {
  allPackages: Array<SubscriptionPackage>;
  allProducts: Array<Product>;
  allStatus: Array<SubscriptionStatus>;
}

type ChildRenderer = (autoSuggestData: AutoSuggestData) => React.ReactElement | null;

export const ProductsAutoSuggestQuery: React.FC<{ children: ChildRenderer }> = ({ children }) => {
  const { data: allProducts, loading: productsLoading, error: productsError } = useQuery({
    type: 'getList',
    resource: 'products',
    payload: {},
  });
  const { data: allPackages, loading: packagesLoading, error: packagesError } = useQuery({
    type: 'getList',
    resource: 'subscriptionPackage',
    payload: {},
  });
  const { data: allStatus, loading: statusLoading, error: statusError } = useQuery({
    type: 'getList',
    resource: 'subscriptionStatus',
    payload: {},
  });

  if (
    productsLoading ||
    packagesLoading ||
    statusLoading ||
    productsError ||
    packagesError ||
    statusError
  ) {
    return null;
  }

  return children({
    allPackages,
    allProducts,
    allStatus,
  });
};
