// eslint-disable-next-line no-control-regex
const isAscii = (str: string): boolean => /^[\x00-\x7F]*$/.test(str);

export const checkUrlForErrors = (url: string): string | null => {
  const resolved = resolveRelativeUrlToJuridika(url);

  try {
    decodeURI(resolved);
  } catch (error) {
    return 'URL har ugyldig struktur';
  }

  if (!isAscii(resolved)) {
    return 'URL kan ikke inneholder æ,ø,å eller lignende. Trykk på encode-knappen for å encode.';
  }

  return null;
};

export const resolveRelativeUrlToJuridika = (url: string): string => {
  if (url.startsWith('/')) {
    return `https://juridika.no${url}`;
  }

  return url;
};
