import * as Slate from 'slate';

import { insertChildIfMissing } from '../../utils/transforms';
import * as tags from '../../tags';

export const createAsideNode = (children: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_ASIDE,
    className: 'facts',
    children: children.length > 0 ? children : [{ text: '' }],
  };
};

export const insertAsideFacts = (editor: Slate.Editor) => {
  const sectionBodyPath = [0, 1];
  const sectionBodyNode = Slate.Node.get(editor, sectionBodyPath);
  const divWrapperIndex = 1;

  const factNode = {
    type: tags.BLOCK_FACTS,
    children: [
      {
        type: tags.BLOCK_PARAGRAPH,
        children: [{ text: '' }],
      },
    ],
  };

  // Make sure right aside element exists
  const insertedAside = insertChildIfMissing(
    editor,
    [sectionBodyNode, sectionBodyPath],
    divWrapperIndex,
    tags.BLOCK_ASIDE,
    createAsideNode([factNode])
  );

  if (!insertedAside) {
    // Make sure we always insert in the end
    const insertAtIndex = [
      ...Slate.Node.children(editor, [...sectionBodyPath, divWrapperIndex]),
    ].filter(([node]) => Slate.Element.isElement(node)).length;

    Slate.Transforms.insertNodes(editor, factNode, {
      at: [0, 1, 1, insertAtIndex],
      select: true,
    });
  }
};
