import * as io from 'io-ts';
import * as Types from './types';
import { halJsonCrudApiProvider, juridikaHalJsonHeaders } from './apiUtil';
import { tagDisplayName } from './tagsProvider';
import URI from 'urijs';

import { JournalLinkStatus } from '../models/LinkInjectorModels';

import { ModuleContribution, ModuleTag } from './textbookModulesProvider';

export interface JournalArticle {
  id: string;
  editionId: string;
  juridikaPublishedAt: Date | null;
  titles: Array<string>;
  tags: Array<ModuleTag>;
  contributions: Array<ModuleContribution>;

  // computed:
  fullTitle: string;
  isPublishedAtJuridika: boolean;
  linkStatus: JournalLinkStatus;
}

const ApiFormat = io.type({
  contentType: io.string,
  source: io.union([io.literal('import'), io.literal('link-injector')]),
  updatedAt: io.string,
  ready: io.boolean,
  approved: io.boolean,
});

const ApiIncomingTypeV = io.type({
  id: io.string,
  editionId: io.string,
  juridikaPublishedAt: io.union([io.string, io.null]),
  contributions: io.array(
    io.type({
      contributorId: io.string,
      role: io.string,
    })
  ),
  tags: io.array(
    io.type({
      path: io.array(io.string),
      name: io.string,
    })
  ),
  titles: io.array(io.string),
  formats: io.array(ApiFormat),
});

const computeLinkStatus = (formats: Array<io.TypeOf<typeof ApiFormat>>): JournalLinkStatus => {
  const linked = formats.find((format) => format.source === 'link-injector');

  if (!linked) {
    return JournalLinkStatus.ORIGINAL;
  }

  if (!linked.ready) {
    return JournalLinkStatus.PROCESSING;
  }

  if (!linked.approved) {
    return JournalLinkStatus.PROCESSED_UNAPPROVED;
  }

  return JournalLinkStatus.PROCESSED_APPROVED;
};

export const createProvider = (urls: Types.BackendUrls) =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/journalArticles`,
    halListName: 'journalArticleList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiResponse): JournalArticle => {
      return {
        id: apiResponse.id,
        editionId: apiResponse.editionId,
        juridikaPublishedAt: apiResponse.juridikaPublishedAt
          ? new Date(apiResponse.juridikaPublishedAt)
          : null,
        titles: apiResponse.titles,
        contributions: apiResponse.contributions,
        tags: apiResponse.tags.map(({ path, name }) => ({
          path,
          name,
          displayName: tagDisplayName(path),
        })),

        fullTitle: apiResponse.titles.join(' '),
        isPublishedAtJuridika: apiResponse.juridikaPublishedAt !== null,
        linkStatus: computeLinkStatus(apiResponse.formats),
      };
    },
    toApiMapper: (x) => x,
    toApiPartialMapper: (x) => x,
    referenceParams: {},
    filterParams: {
      editionQuery: (editionQuery) => ({ editionQuery }),
      documentKeyQuery: (documentKeyQuery) => ({ documentKeyQuery }),
      publicationId: (publicationId) => ({ publicationId }),
      isJuridikaPublished: (isJuridikaPublished) => ({ isJuridikaPublished }),
    },
    sortFields: [],
  });

// Auxiliary APIs:
export const postPublishJournalArticles = async (
  ids: Array<string>,
  juridikaPublishedAt: Date,
  backendUrls: Types.BackendUrls
) => {
  const url = URI(backendUrls.juridikaLitteraturBackend)
    .segment('admin')
    .segment('v0')
    .segment('journalArticles')
    .segment('publish');

  const body = { ids, juridikaPublishedAt: juridikaPublishedAt.toISOString() };

  const response = await fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...juridikaHalJsonHeaders(),
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
};
