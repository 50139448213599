import * as React from 'react';
import { InputProps, Labeled, useInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Publish';
import { Box } from '@material-ui/core';

import {
  ContractAttachment,
  ContractAttachmentKey,
  getAttachment,
} from '../../dataProvider/contractSnapshotsProvider';

export const ContractAttachments: React.FC<InputProps> = (props: InputProps) => {
  const { input } = useInput(props);

  const attachments: ContractAttachment[] | null = input.value || undefined;

  const changeAttachment = (a: ContractAttachment) => {
    const newAttachments = attachments?.filter((v) => v.key !== a.key) || [];
    newAttachments.push(a);
    input.onChange(newAttachments);
  };

  const templateWordDoc = getAttachment(attachments, ContractAttachmentKey.WORD);
  const templatePdfPreview = getAttachment(attachments, ContractAttachmentKey.TEMPLATE_PREVIEW);
  const templateGuideWordDoc = getAttachment(attachments, ContractAttachmentKey.TEMPLATE_GUIDE);
  const templateGuidePdfPreview = getAttachment(
    attachments,
    ContractAttachmentKey.TEMPLATE_GUIDE_PREVIEW
  );

  return (
    <div>
      <Box my="1rem">
        <AttachmentUploader
          fileKey={ContractAttachmentKey.WORD}
          label="resources.contractSnapshots.fields.documentAttachments.templateWordDoc"
          acceptFileTypes=".docx"
          currentFile={templateWordDoc}
          onFileChange={changeAttachment}
        />
      </Box>
      <Box my="1rem">
        <AttachmentUploader
          fileKey={ContractAttachmentKey.TEMPLATE_PREVIEW}
          label="resources.contractSnapshots.fields.documentAttachments.templatePdfPreview"
          acceptFileTypes=".pdf"
          currentFile={templatePdfPreview}
          onFileChange={changeAttachment}
        />
      </Box>
      <Box my="1rem">
        <AttachmentUploader
          fileKey={ContractAttachmentKey.TEMPLATE_GUIDE}
          label="resources.contractSnapshots.fields.documentAttachments.templateGuideWordDoc"
          acceptFileTypes=".docx"
          currentFile={templateGuideWordDoc}
          onFileChange={changeAttachment}
        />
      </Box>
      <Box my="1rem">
        <AttachmentUploader
          fileKey={ContractAttachmentKey.TEMPLATE_GUIDE_PREVIEW}
          label="resources.contractSnapshots.fields.documentAttachments.templateGuidePdfPreview"
          acceptFileTypes=".pdf"
          currentFile={templateGuidePdfPreview}
          onFileChange={changeAttachment}
        />
      </Box>
    </div>
  );
};

export const AttachmentUploader: React.FC<{
  fileKey: string;
  label: string;
  acceptFileTypes: string;
  currentFile: ContractAttachment | undefined;
  onFileChange: (a: ContractAttachment) => void;
}> = (props) => {
  const fileName = props.currentFile?.fileName || props.currentFile?.url;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      props.onFileChange({
        key: props.fileKey,
        contentType: file.type,
        base64Content: btoa(reader.result as string),
        fileName: file.name,
      });
    };
  };
  return (
    <Card>
      <CardContent>
        <Labeled label={props.label} fullWidth>
          <small>
            <a href={fileName}>{fileName}</a>
          </small>
        </Labeled>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component="label"
          endIcon={fileName ? <EditIcon /> : <PublishIcon />}
        >
          {' '}
          {fileName ? 'Endre' : 'Last opp'}
          <input hidden type="file" accept={props.acceptFileTypes} onChange={handleFileUpload} />
        </Button>
      </CardActions>
    </Card>
  );
};
