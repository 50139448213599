import * as React from 'react';
import { useQuery } from 'react-admin';

import { Feed } from '../../dataProvider/feedsProvider';
import { ContentCategory } from '../../dataProvider/contentCategoriesProvider';

interface QueryChildProp<T> {
  data: Array<T>;
  loading: boolean;
  error: any;
}

export interface AutoSuggestData {
  allFeeds: Array<Feed>;
  allContentCategories: Array<ContentCategory>;
}

type ChildRenderer = (autoSuggestData: AutoSuggestData) => React.ReactElement | null;

export const FeedsAutoSuggestQuery: React.FC<{
  children: ChildRenderer;
}> = ({ children }) => {
  const { data: allFeeds, loading: feedsLoading, error: feedsError } = useQuery({
    type: 'getList',
    resource: 'feeds',
    payload: {},
  });

  const {
    data: allContentCategories,
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery({
    type: 'getList',
    resource: 'contentCategories',
    payload: {},
  });
  if (feedsLoading || categoriesLoading || feedsError || categoriesError) return null;

  return children({
    allFeeds,
    allContentCategories,
  });
};
