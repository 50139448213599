export type VersionStatus = 'DRAFT' | 'PUBLISHED';

export const DOCUMENT_TYPES = [
  { id: 'act', name: 'act' },
  { id: 'amendment', name: 'amendment' },
  { id: 'judgment', name: 'judgment' },
];

export const ACT_COUNTRIES = [
  { id: 'no', name: 'resources.legalDocuments.countryCodes.no' },
  { id: 'eu', name: 'resources.legalDocuments.countryCodes.eu' },
];

export const ACT_SUBTYPES = [
  { id: 'forskrift', name: 'resources.legalDocuments.types.regulation' },
  { id: 'lov', name: 'resources.legalDocuments.types.law' },
  { id: 'direktiv', name: 'resources.legalDocuments.types.directive' },
  { id: 'forordning', name: 'resources.legalDocuments.types.regulation2' },
  { id: 'traktat', name: 'resources.legalDocuments.types.treaty' },
  { id: 'hr', name: 'resources.legalDocuments.types.hr' },
];

export const ACT_LANGUAGES = [
  { id: 'nor', name: 'nor' },
  { id: 'eng', name: 'eng' },
];

export const SOURCE_SYSTEMS = [
  { id: 'Xopus', name: 'Xopus' },
  { id: 'Eurlex', name: 'Eurlex' },
];

export const LEGAL_RELATIONS = [
  { id: 'repeals', name: 'opphever' },
  { id: 'repealed_by', name: 'opphevet av' },
  { id: 'replaces', name: 'erstatter' },
  { id: 'replaced_by', name: 'erstattet av' },
  { id: 'amends', name: 'endrer' },
  { id: 'amended_by', name: 'endret av' },
];

export interface LegalWorkIdentifier {
  countryCode: string;
  documentType: string;
  subtype: string;
  date: string;
  seq: number;
}

export interface LegalChangesSuggestion {
  amendments: {
    id: string;
    uri: string;
    title: string;
    lovtidendLink: string;
  }[];
  fragments: { id: string; type: 'create' | 'update' | 'delete' }[];
}

export interface LegalChange {
  subjectUri: string | null;
  fragments: string[];
}

export interface LegalRelations {
  predicate: string;
  object: LegalWorkRef;
  appliedIn: string | null;
}

export interface LegalWorkRef extends LegalWorkIdentifier {
  uri: string;
  shortTitle?: string;
}

export interface LegalExpressionIdentifier extends LegalWorkIdentifier {
  languageCode: string;
  inForceDate: string;
}

export interface LegalDocument {
  id: string;
  isSummary: boolean;
  summary?: LegalDocumentSummary;
  work?: LegalWork;
}

export interface LegalDocumentSummary extends LegalExpressionIdentifier {
  uri: string;
  noLongerInForceDate?: string;
  shortTitle?: string;
  docTitle?: string;
  tags: WorkDocumentTag[];
  lastPublishedAt?: string;
  originallyPublishedAt?: string;
}

export interface LegalPage<T> {
  limit: number;
  offset: number;
  total: number;
  data: T[];
}

export interface LegalWork extends LegalWorkIdentifier {
  uuid: string;
  uri: string;
  activeSourceSystem: string;
  noLongerInForceDate?: string;
  listingTitle?: string;
  favoriteCount: number;
  tags: WorkDocumentTag[];
  isHistoryEnabled: boolean;
  expressions: LegalExpression[];
}

export interface LegalExpression {
  uuid: string;
  uri: string;
  inForceDate: string;
  languageCode: string;
  manifestations: LegalManifestation[];
}

export interface LegalManifestation {
  uuid: string;
  publicationStatus: VersionStatus;
  shortTitle: string;
  docTitle: string;
  createdAt: string;
  createdBy: string;
  lastChangedAt: string;
  lastChangedBy: string;
  originallyPublishedAt?: string;
  lastPublishedAt?: string;
  lastPublishedBy?: string;
}

export interface WorkDocumentTag {
  path: string;
  name: string;
}

export interface GenerateCopyParams {
  id: string;
  data: {
    copyFromWorkUri: string;
    copyFromInForceDate: string;
    copyFromLanguageCode: string;
    copyFromPublicationStatus: string;
    newInForceDate: string;
    editor: string;
  };
}
