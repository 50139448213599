import * as React from 'react';
import { List, Datagrid, TextField, Filter, TextInput, TopToolbar } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { UserExpandShow } from './UserDetails';
import IconForward from '@material-ui/icons/ArrowForward';
import IconPerson from '@material-ui/icons/Person';

const UsersFilter: React.FC<{}> = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Søk" source="q" alwaysOn />
    <TextInput label="Kundenummer" source="customerNumber" alwaysOn />
    <TextInput label="Kunde ID" source="customerId" alwaysOn />
  </Filter>
);

const ListActions: React.FC<{}> = () => (
  <TopToolbar>
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: 'userMigrations/create',
      }}
    >
      <>
        Brukermigrering
        <IconPerson />
        <IconForward />
        <IconPerson />
      </>
    </Button>
  </TopToolbar>
);

const UsersList: React.FC<{}> = (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<UsersFilter {...props} />}
      actions={<ListActions />}
      perPage={25}
    >
      <Datagrid expand={<UserExpandShow />}>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="customerNumber" />
      </Datagrid>
    </List>
  );
};

export default UsersList;
