import * as t from 'io-ts';

import * as Types from './types';
import { parseNullableDate, halJsonCrudApiProvider } from './apiUtil';

import {
  BaseDocumentSnapshot,
  BaseDocumentSnapshotApiIncomingTypeV,
} from './models/staticContentCommonModels';

import {
  StaticDocumentRevisionOutgoingApiType,
  mapApiRevisionToFrontend,
  toApiMapper as staticDocumentRevisionToApiMapper,
} from './staticDocumentRevisionsProvider';

import { DefaultHast as NewsArticleDefaultHast } from '../slate/documentSpecs/newsArticleSpec';

export interface PageSnapshot extends BaseDocumentSnapshot {
  slug: string | null;
  shareImageUrl: string | null;
}

export const createEmptyPageSnapshot = (): PageSnapshot => ({
  id: '',
  createdById: '',
  currentEditorId: '',
  currentEditorIsCurrentUser: true,
  firstPublishedAt: null,
  lastPublishedAt: null,
  slug: null,
  revision: {
    id: '',
    documentId: '',
    createdAt: new Date(),
    createdById: '',
    publishedAt: null,
    contentCategoryId: '',
    title: '',
    hast: NewsArticleDefaultHast(),
    contributions: [],
    tags: [],
  },
  shareImageUrl: null,
});

const ApiIncomingTypeV = t.intersection([
  BaseDocumentSnapshotApiIncomingTypeV,
  t.type({
    slug: t.union([t.string, t.null]),
    shareImageUrl: t.union([t.string, t.null]),
  }),
]);

type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  revision: StaticDocumentRevisionOutgoingApiType;
  slug: string | null;
  shareImageUrl: string | null;
}

export const toApiMapper = (snapshot: PageSnapshot): ApiOutgoingType => ({
  revision: staticDocumentRevisionToApiMapper(snapshot.revision),
  slug: snapshot.slug && snapshot.slug.replace(/\s/g, '').length > 0 ? snapshot.slug : null,
  shareImageUrl: snapshot.shareImageUrl,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<PageSnapshot> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/pageSnapshots`,
    halListName: 'adminPageSnapshotList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiSnapshot: ApiIncomingType): PageSnapshot => ({
      id: apiSnapshot.id,
      createdById: apiSnapshot.createdById,
      currentEditorId: apiSnapshot.currentEditorId,
      currentEditorIsCurrentUser: apiSnapshot.currentEditorIsCurrentUser,
      revision: mapApiRevisionToFrontend(apiSnapshot.revision),
      firstPublishedAt: parseNullableDate(apiSnapshot.firstPublishedAt),
      lastPublishedAt: parseNullableDate(apiSnapshot.lastPublishedAt),
      slug: apiSnapshot.slug,
      shareImageUrl: apiSnapshot.shareImageUrl,
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      revision: {
        contentCategoryId: (id) => ({ contentCategoryId: id }),
        tags: (tagPath) => ({ tag: tagPath }),
      },
    },
    sortFields: [],
  });
