import { Toc } from './types';

const xslUrl = '/xopus_5.4.4/commentaries/xsl/toc.xsl';

export const generateLegalToc = async (xml: string): Promise<Toc> => {
  const parser = new DOMParser();

  const xsltProcessor = new XSLTProcessor();
  const xsl = await (await fetch(xslUrl)).text();
  const xslDoc = parser.parseFromString(xsl, 'text/xml');
  xsltProcessor.importStylesheet(xslDoc);

  const xmlDoc = parser.parseFromString(xml, 'text/xml');
  const html = xsltProcessor.transformToFragment(xmlDoc, document);

  return JSON.parse(html.textContent!!);
};
