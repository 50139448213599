import * as Slate from 'slate';
import * as React from 'react';

import { Plugin } from './types';
import { getAboveByType } from '../utils/query';

export interface SoftBreakPluginConfig {
  // Soft break will only be active inside given types
  activeInTypes?: string[];
}

const isNodeType = (editor: Slate.Editor, types?: string[]) => {
  if (!types) return true;
  return !!getAboveByType(editor, types);
};

export default (config: SoftBreakPluginConfig): Plugin => {
  return {
    key: 'soft-break-plugin',
    onKeyDown: (editor: Slate.Editor, e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.shiftKey && e.key === 'Enter' && isNodeType(editor, config.activeInTypes)) {
        e.preventDefault();
        editor.insertText('\n');
      }
    },
  };
};
