import { CREATE, GET_LIST, GET_MANY_REFERENCE, GET_ONE } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders, requestHeaders } from './apiUtil';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  NIKEditionsApiProvider(urls.innholdBackend, type, params);

const NIKEditionsApiProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => {
  const contentAPI = baseApiUrl;
  switch (type) {
    case GET_LIST: {
      const filter =
        (params.filter &&
          params.filter.publicationId &&
          '?publicationId=' + params.filter.publicationId) ||
        '';
      const response = fetch(`${contentAPI}/admin/v1/edition${filter}`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((editions) => {
          const sortedEditions = editions.sort((a: any, b: any) => a.name.localeCompare(b.name));
          return { data: sortedEditions, total: editions.length };
        });
      return response;
    }
    case GET_ONE: {
      const response = fetch(`${contentAPI}/admin/v1/edition/${params.id}`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((edition) => {
          const data = { data: { id: edition.id, ...edition }, total: 1 };
          return data;
        });
      return response;
    }

    case GET_MANY_REFERENCE: {
      const response = fetch(`${contentAPI}/admin/v1/edition`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((editions) => {
          const editionsByPublicationsId = editions.filter(
            (edition: any) => edition.publicationId === params.id
          );
          const sortedEditionsByPublicationsId = editionsByPublicationsId.sort((a: any, b: any) => {
            if (a.publishedAt === null && b.publishedAt === null) {
              return 0;
            }
            if (a.publishedAt === null) {
              return 1;
            }
            if (b.publishedAt === null) {
              return -1;
            }
            return b.publishedAt.localeCompare(a.publishedAt);
          });

          return { data: sortedEditionsByPublicationsId, total: 1 };
        });
      return response;
    }

    case CREATE: {
      const response = fetch(`${contentAPI}/admin/v1/edition`, {
        method: 'POST',
        ...requestHeaders(),
        body: JSON.stringify({
          publicationId: params.data.publicationId,
          name: params.data.name,
          contributorDetailIds: params.data.contributorDetailIds.map(
            (contributorDetail: any) => contributorDetail.id
          ),
        }),
      })
        .then((res) => res.json())
        .then((edition) => {
          const data = { data: { id: edition.id, ...edition }, total: 1 };
          return data;
        });
      return response;
    }

    case 'UPDATE': {
      const contributorDetailIds = params.data.contributorRelation
        ? params.data.contributorRelation.map(
            (contributorDetail: any) => contributorDetail.contributorDetailId
          )
        : [];

      const response = fetch(`${contentAPI}/admin/v1/edition/${params.id}`, {
        method: 'PUT',
        ...requestHeaders(),
        body: JSON.stringify({
          publicationId: params.data.publicationId,
          name: params.data.name,
          contributorDetailIds,
        }),
      })
        .then((res) => res.json())
        .then((edition) => {
          return { data: edition, total: 1 };
        });
      return response;
    }

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
