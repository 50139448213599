import * as io from 'io-ts';
import URI from 'urijs';

import { DataFormat, DateOrString, NamedString } from './dataFormat';

import * as Types from './types';
import { halJsonCrudApiProvider, juridikaHalJsonHeaders } from './apiUtil';
import { ModuleTag, apiModuleTagToReactAdmin } from './textbookModulesProvider';

export type Availability = 'ANNOUNCED' | 'PREVIEW' | 'COMPLETE';

interface TextbookEditionWritableProps<Fmt extends DataFormat> {
  publicationId: string;
  publisherId: string;
  editionSequence: number;
  availability: Availability;
  originallyPublishedAt: DateOrString<Fmt>;
  titles: Array<NamedString<'title', Fmt>>;
  pageCount: number | null;
  isbn: string | null;
}

export interface TextbookEdition extends TextbookEditionWritableProps<'RECORD'> {
  id: string;
  juridikaPublishedAt: Date | null;
  name: string;
  hasCover: boolean;
  rootDocumentTags: Array<ModuleTag>;
  rootDocumentContributionTexts: Array<string>;
  ufDepartment: number | null;

  // Computed/helper fields
  fullTitle: string;
  mainTitle: string;

  descriptiveName: string;

  isPublishedAtJuridika: boolean;
}

// Runtime type!!!
const ApiTypeV = io.type({
  id: io.string,
  publicationId: io.string,
  publisherId: io.string,
  editionSequence: io.number,
  availability: io.union([io.literal('ANNOUNCED'), io.literal('PREVIEW'), io.literal('COMPLETE')]),
  originallyPublishedAt: io.string,
  juridikaPublishedAt: io.union([io.string, io.null]),
  titles: io.array(io.string),
  hasCover: io.boolean,
  pageCount: io.union([io.number, io.null]),
  isbn: io.union([io.string, io.null]),
  ufDepartment: io.union([io.number, io.null]),
  rootDocumentTags: io.union([
    io.array(
      io.type({
        path: io.array(io.string),
        name: io.string,
      })
    ),
    io.null,
  ]),
  rootDocumentContributionTexts: io.union([io.array(io.string), io.null]),
});
type ApiType = io.TypeOf<typeof ApiTypeV>;

type ApiOutgoingType = TextbookEditionWritableProps<'JSON'>;

export const toApiMapper = (
  edition: TextbookEditionWritableProps<'PROVIDER'>
): ApiOutgoingType => ({
  publicationId: edition.publicationId,
  publisherId: edition.publisherId,
  editionSequence: edition.editionSequence,
  availability: edition.availability,
  originallyPublishedAt: edition.originallyPublishedAt,
  titles: edition.titles.map(({ title }) => title),
  pageCount: edition.pageCount,
  isbn: edition.isbn,
});

export type ApiPartialOutgoingType = Partial<TextbookEditionWritableProps<'JSON'>>;

// when here, the originallyPublishedAt has already been converted to a string
export const toApiPartialMapper = (
  partialEdition: Partial<TextbookEditionWritableProps<'PROVIDER'>>
): ApiPartialOutgoingType => ({
  publicationId: partialEdition.publicationId,
  publisherId: partialEdition.publisherId,
  availability: partialEdition.availability,
  originallyPublishedAt: partialEdition.originallyPublishedAt,
  titles: partialEdition.titles?.map(({ title }) => title),
  pageCount: partialEdition.pageCount ?? undefined,
  isbn: partialEdition.isbn ?? undefined,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<TextbookEdition> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/textbookEditions`,
    halListName: 'adminTextbookEditionList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (edition: ApiType): TextbookEdition => {
      const originallyPublishedAt = new Date(edition.originallyPublishedAt);

      return {
        id: edition.id,
        publicationId: edition.publicationId,
        publisherId: edition.publisherId,
        editionSequence: edition.editionSequence,
        availability: edition.availability,
        originallyPublishedAt,
        juridikaPublishedAt: edition.juridikaPublishedAt
          ? new Date(edition.juridikaPublishedAt)
          : null,
        titles: edition.titles.map((title) => ({ title })),
        name: `${edition.editionSequence}. utgave`,
        hasCover: edition.hasCover,
        pageCount: edition.pageCount,
        isbn: edition.isbn,
        ufDepartment: edition.ufDepartment,
        rootDocumentTags: edition.rootDocumentTags
          ? edition.rootDocumentTags.map(apiModuleTagToReactAdmin)
          : [],
        rootDocumentContributionTexts: edition.rootDocumentContributionTexts ?? [],

        fullTitle: edition.titles.join(' — '),
        mainTitle: edition.titles.length > 0 ? edition.titles[0] : '',

        descriptiveName: `${edition.titles[0]} - ${
          edition.editionSequence
        }. utgave, ${originallyPublishedAt.getFullYear()}`,
        isPublishedAtJuridika: !!edition.juridikaPublishedAt,
      };
    },
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      publicationId: 'publicationId',
    },
    filterParams: {
      publicationId: (publicationId) => ({ publicationId }),
      q: (q) => ({ q }),
      isJuridikaPublished: (isJuridikaPublished) => ({ isJuridikaPublished }),
    },
    sortFields: ['originallyPublishedAt', 'titles'],
  });

// Auxiliary APIs:
export const postPublishTextbookEdition = async (
  id: string,
  juridikaPublishedAt: Date,
  documentFilter: 'rootOnly' | 'all',
  backendUrls: Types.BackendUrls
) => {
  let endpoint: string;
  switch (documentFilter) {
    case 'rootOnly':
      endpoint = 'publishRoot';
      break;
    case 'all':
      endpoint = 'publishAll';
      break;
  }

  const url = URI(backendUrls.juridikaLitteraturBackend)
    .segment('admin')
    .segment('v0')
    .segment('textbookEditions')
    .segment(id)
    .segment(endpoint);

  const body = { juridikaPublishedAt: juridikaPublishedAt.toISOString() };

  const response = await fetch(url.toString(), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      ...juridikaHalJsonHeaders(),
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
};

export const postUnPublishTextbookEdition = async (id: string, backendUrls: Types.BackendUrls) => {
  const url = URI(backendUrls.juridikaLitteraturBackend)
    .segment('admin')
    .segment('v0')
    .segment('textbookEditions')
    .segment(id)
    .segment('unPublish');

  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...juridikaHalJsonHeaders(),
    },
  });
  if (!response.ok) {
    throw new Error(await response.text());
  }
};
