import React, { useState } from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslate } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  EId,
  CommentaryMetadataForAllExpressionFragments,
  Toc,
} from '../../../state/expressions/types';

import { TocList } from './Toc';

import '../../../styles/commentariesEditor/toc.css';
import * as Immutable from 'immutable';
import PublishIcon from '@material-ui/icons/Publish';
import UpdateIcon from '@material-ui/icons/Update';
import { DateTime } from 'luxon';

const classes = new BEMHelper({
  name: 'toc',
});

type OpenTocItems = EId[];

// Toggle actions for the TOC
const ToggleAction: React.FC<{
  message: string;
  onClick: () => void;
  disabled?: boolean;
  tooltip?: string;
  children?: any;
}> = ({ message, onClick, disabled, tooltip, children }) => {
  const translate = useTranslate();
  return (
    <button
      title={tooltip ? translate(tooltip) : ''}
      disabled={disabled}
      {...classes('toggleAction')}
      onClick={onClick}
    >
      {children}
      {translate(message)}
    </button>
  );
};

const Aside: React.FC<{
  legalDocumentStructure: Toc;
  handleSelectFragment(fragmentId: string): void;
  handlePublishCommentaries(fragmentIds: string[]): void;
  handleOverwriteLastReviewed(fragmentIds: string[], date: DateTime): void;
  activeFragmentId: string | null;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
}> = ({
  legalDocumentStructure,
  handleSelectFragment,
  handlePublishCommentaries,
  handleOverwriteLastReviewed,
  activeFragmentId,
  commentaryMeta,
}) => {
  const translate = useTranslate();
  const [openTocItems, setOpenTocItems] = useState<OpenTocItems>([]);
  const [shouldExpandAllTocItems, setShouldExpandAllTocItems] = useState(false);
  const [shouldHighlightEmptyCommentaries, setshouldHighlightEmptyCommentaries] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleExpandAllTocItems = () => setShouldExpandAllTocItems(!shouldExpandAllTocItems);

  const handleToggle = (eId: EId) => {
    if (shouldExpandAllTocItems) {
      toggleExpandAllTocItems();
      return;
    }

    const index = openTocItems.indexOf(eId);

    if (index === -1) {
      setOpenTocItems(
        eId
          .split('__')
          .reduce(
            (result: OpenTocItems, _, index: number, array: OpenTocItems) => [
              ...result,
              array.slice(0, index + 1).join('__'),
            ],
            []
          )
      );
    } else {
      setOpenTocItems([...openTocItems.slice(0, index), ...openTocItems.slice(index + 1)]);
    }
  };

  const toggleEmptyCommentariesHighLight = () => {
    setshouldHighlightEmptyCommentaries(!shouldHighlightEmptyCommentaries);
    setShouldExpandAllTocItems(shouldExpandAllTocItems ? shouldExpandAllTocItems : true);
  };

  const getAll = () => Object.keys(commentaryMeta);

  const [checkedFragmentIds, setCheckedFragmentIds] = useState(Immutable.Set.of<string>());
  const getCheckedUnpublished = () =>
    Object.entries(commentaryMeta)
      .filter(([, commentary]) => !!commentary.draft)
      .map(([id]) => id)
      .filter((id) => checkedFragmentIds.has(id));
  const isChecked = (fragmentId: string) => {
    return checkedFragmentIds.has(fragmentId);
  };
  const toggleChecked = (fragmentId: string) => {
    const newSet = checkedFragmentIds.has(fragmentId)
      ? checkedFragmentIds.remove(fragmentId)
      : checkedFragmentIds.add(fragmentId);
    setCheckedFragmentIds(newSet);
  };
  const toggleCheckAll = () => {
    const newSet =
      checkedFragmentIds.size > 0 ? Immutable.Set.of<string>() : Immutable.Set.of(...getAll());
    setCheckedFragmentIds(newSet);
  };
  const publishAllChecked = () => {
    const fragmentIdsToPublish = getCheckedUnpublished();
    if (fragmentIdsToPublish.length === 0) return;
    const shouldPublish = window.confirm(
      translate('resources.commentaries.actions.publish_all_confirm', {
        count: fragmentIdsToPublish.length,
      })
    );
    if (!shouldPublish) return;
    handlePublishCommentaries(fragmentIdsToPublish);
    toggleCheckAll();
  };
  const clickOverwriteLastReviewed = () => {
    const now = DateTime.local().toISODate();
    const dateString = window.prompt(
      translate('resources.commentaries.actions.overwrite_last_reviewed_confirm', {
        count: checkedFragmentIds.size,
      }),
      now
    );
    if (!dateString) return;

    const date = DateTime.fromISO(dateString);
    if (!date.isValid) {
      window.alert(
        translate('resources.commentaries.actions.overwrite_last_reviewed_invalid_date', {
          date: dateString,
        })
      );
    } else {
      handleOverwriteLastReviewed(checkedFragmentIds.toArray(), date);
      toggleCheckAll();
    }
  };

  return (
    <aside {...classes('', { isCollapsed })}>
      <div {...classes('toggleAsideAction')}>
        <IconButton
          aria-label={isCollapsed ? 'Open' : 'Close'}
          onClick={() => setIsCollapsed(!isCollapsed)}
          color="secondary"
        >
          {isCollapsed ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </div>
      <div {...classes('wrapper', { isCollapsed })}>
        <div {...classes('toggleActions')}>
          <ToggleAction
            message={
              shouldHighlightEmptyCommentaries
                ? 'resources.commentaries.actions.remove_empty_commentaries_highlight'
                : 'resources.commentaries.actions.hightlight_empty_commentaries'
            }
            onClick={toggleEmptyCommentariesHighLight}
          />
          <ToggleAction
            message={
              shouldExpandAllTocItems
                ? 'resources.commentaries.actions.collapse_all_toc_items'
                : 'resources.commentaries.actions.expand_all_toc_items'
            }
            onClick={toggleExpandAllTocItems}
          />
          <br />
          <ToggleAction
            message={
              checkedFragmentIds.size > 0
                ? 'resources.commentaries.actions.uncheck_all_fragments'
                : 'resources.commentaries.actions.check_all_fragments'
            }
            tooltip={
              checkedFragmentIds.size > 0
                ? 'resources.commentaries.actions.uncheck_all_fragments_tooltip'
                : 'resources.commentaries.actions.check_all_fragments_tooltip'
            }
            onClick={toggleCheckAll}
          />
          <ToggleAction
            tooltip={'resources.commentaries.actions.publish_all_tooltip'}
            disabled={getCheckedUnpublished().length === 0}
            message={'resources.commentaries.actions.publish_all'}
            onClick={publishAllChecked}
          >
            <PublishIcon style={{ fontSize: 15, marginBottom: '-3px', marginRight: '3px' }} />
          </ToggleAction>
          <ToggleAction
            tooltip={'resources.commentaries.actions.overwrite_last_reviewed_tooltip'}
            disabled={checkedFragmentIds.size === 0}
            message={'resources.commentaries.actions.overwrite_last_reviewed'}
            onClick={clickOverwriteLastReviewed}
          >
            <UpdateIcon style={{ fontSize: 15, marginBottom: '-3px', marginRight: '3px' }} />
          </ToggleAction>
        </div>
        <TocList
          {...classes('list')}
          activeFragmentId={activeFragmentId || ''}
          commentaryMeta={commentaryMeta}
          handleSelectFragment={handleSelectFragment}
          handleToggle={handleToggle}
          openTocItems={openTocItems}
          shouldExpandAllTocItems={shouldExpandAllTocItems}
          shouldHighlightEmptyCommentaries={shouldHighlightEmptyCommentaries}
          tocList={legalDocumentStructure}
          isChecked={isChecked}
          toggleChecked={toggleChecked}
        />
      </div>
    </aside>
  );
};

export default Aside;
