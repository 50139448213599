import * as React from 'react';

export interface HypertextImportRule {
  readonly type: string;
  readonly className?: string;
  readonly optionalClassNames?: string[];
  readonly data?: string[];
  readonly optionalData?: string[];
  readonly isVoid?: boolean;
  readonly render?: React.ClassType<any, any, any>;
  readonly renderTemplate?: React.ClassType<any, any, any>;
  readonly convertFromHtmlTag?: Array<string>;
  readonly isInline?: boolean;
  readonly isMark?: boolean;
}

export interface HypertextRewriteRule {
  readonly reimportHtmlTagAs: string;
}

export type HypertextRule = HypertextImportRule | HypertextRewriteRule;

export type HypertextRuleSet = { [htmlTag: string]: HypertextRule[] };

export interface HypertextRuleSets {
  readonly blocks: HypertextRuleSet;
  readonly inlines: HypertextRuleSet;
  readonly marks: HypertextRuleSet;
}

export interface HypertextSerializationRule {
  readonly htmlTag: string;
  readonly className?: string;
  readonly data?: string[];
  readonly optionalClassNames?: string[];
  readonly isVoid: boolean | undefined;
  readonly render: React.ClassType<any, any, any> | undefined;
  readonly renderTemplate: React.ClassType<any, any, any> | undefined;
  readonly isInline: boolean;
  readonly isMark: boolean;
}

export type HypertextSerializationRuleSet = {
  [type: string]: HypertextSerializationRule;
};

export interface HypertextSerializationRuleSets {
  readonly blocks: HypertextSerializationRuleSet;
  readonly inlines: HypertextSerializationRuleSet;
  readonly marks: HypertextSerializationRuleSet;
}

export const createSerializationRules = (
  hypertextRuleSet: HypertextRuleSet,
  object: 'block' | 'inline' | 'mark'
): HypertextSerializationRuleSet => {
  return Object.keys(hypertextRuleSet)
    .map((htmlTag) => hypertextRuleSet[htmlTag].map((rule) => ({ htmlTag, rule })))
    .reduce((a, b) => a.concat(b), [])
    .reduce((ruleSet, item) => {
      if ('reimportHtmlTagAs' in item.rule) {
        // Ignore these rules in serialization.
        return ruleSet;
      }

      if (ruleSet[item.rule.type]) {
        throw new Error('Duplicate serialization rule for ' + item.rule.type);
      }
      ruleSet[item.rule.type] = {
        htmlTag: item.htmlTag,
        className: item.rule.className,
        data: (item.rule.data || []).concat(item.rule.optionalData || []),
        optionalClassNames: item.rule.optionalClassNames,
        isVoid: item.rule.isVoid,
        render: item.rule.render,
        renderTemplate: item.rule.renderTemplate,
        isInline: object === 'inline' || object === 'mark',
        isMark: object === 'mark',
      };
      return ruleSet;
    }, {} as HypertextSerializationRuleSet);
};

export const createSerializationRuleSets = (
  sets: HypertextRuleSets
): HypertextSerializationRuleSets => ({
  blocks: createSerializationRules(sets.blocks, 'block'),
  inlines: createSerializationRules(sets.inlines, 'inline'),
  marks: createSerializationRules(sets.marks, 'mark'),
});
