import { BackendUrls } from './types';

const isBrowser = (): boolean => typeof window !== 'undefined';

export const juridikaLegacyApiBaseUrl = (): string =>
  (isBrowser() && (window as any).REACT_APP_JURIDIKA_API_BASE_URL) || '';

export const juridikaFrontendBaseUrl = (): string =>
  (isBrowser() && (window as any).REACT_APP_JURIDIKA_BASE_URL) || 'http://localhost:3002';

  export const contentAPIBaseUrl = (): string =>
  (isBrowser() && (window as any).REACT_APP_CONTENT_API_BASE_URL) || 'https://innhold-backend.test.juridika.no';

export const githubStaticContentBaseUrl = (): string =>
  (isBrowser() && (window as any).REACT_APP_GITHUB_STATIC_CONTENT_BASE_URL) || '';

export const backendUrlsFromApiBaseUrl = (
  apiBaseUrl: string,
  frontendBaseUrl: string = ''
): BackendUrls => ({
  staticContentBackend: `${apiBaseUrl}/static-content-api`,
  juridikaLitteraturBackend: `${apiBaseUrl}/litteratur-api`,
  lovEditorBackend: `${apiBaseUrl}/lov-api`,
  juridikaSubscriptionBackend: `${apiBaseUrl}/subscriptions-api`,
  innholdBackend: contentAPIBaseUrl(),
  // Frontend backend calls serverless utdrag and creates excerpt
  juridikaFrontend: `${frontendBaseUrl}/`,
});

export const backendUrls = (): BackendUrls =>
  backendUrlsFromApiBaseUrl(juridikaLegacyApiBaseUrl(), juridikaFrontendBaseUrl());

export const juridikaStaticContentFilesS3BucketUrl = (): string => {
  const url = `https://${
    (window as any).JURIDIKA_STATIC_CONTENT_FILES_S3_BUCKET
  }.s3.eu-central-1.amazonaws.com`;

  return (isBrowser() && url) || '';
};
