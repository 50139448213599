import * as t from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface Feed {
  id: string;
  semanticKey: string;
  name: string;
  noticeCount: number;
}

const ApiIncomingTypeV = t.type({
  id: t.string,
  semanticKey: t.string,
  name: t.string,
  noticeCount: t.number,
});
type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  semanticKey: string;
  name: string;
}

export const toApiMapper = (feed: Feed): ApiOutgoingType => ({
  semanticKey: feed.semanticKey,
  name: feed.name,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Feed> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/feeds`,
    halListName: 'adminFeedList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (x) => x,
    toApiMapper: toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {},
    sortFields: ['noticeCount', 'name'],
  });
