import * as Slate from 'slate';
import * as tags from '../../tags';
import { getAboveByType, isBlockTextEmptyAfterSelection, isRangeAtRoot } from '../../utils/query';

export const listTypes = [
  tags.BLOCK_BULLETED_LIST,
  tags.BLOCK_ALPHA_LIST,
  tags.BLOCK_NUMBERED_LIST,
  tags.BLOCK_REFERENCE_LIST,
];

export const unwrapList = (editor: Slate.Editor) => {
  Slate.Transforms.unwrapNodes(editor, {
    match: (node) => node.type === tags.BLOCK_LIST_ITEM,
    split: true,
  });
  Slate.Transforms.unwrapNodes(editor, {
    match: (node) => listTypes.includes(node.type as string),
    split: true,
  });
};

export const toggleList = (editor: Slate.Editor, listType: string) => {
  if (!editor.selection) return;

  const isActive = getAboveByType(editor, listType);

  unwrapList(editor);

  Slate.Transforms.setNodes(editor, {
    type: tags.BLOCK_PARAGRAPH,
  });

  if (!isActive) {
    const list = { type: listType, children: [] };

    Slate.Transforms.wrapNodes(editor, list);

    const paragraphNodes = Slate.Editor.nodes(editor, {
      match: (node) => node.type === tags.BLOCK_PARAGRAPH,
    });

    const listItem = { type: tags.BLOCK_LIST_ITEM, children: [] };

    for (const [, path] of paragraphNodes) {
      Slate.Transforms.wrapNodes(editor, listItem, {
        at: path,
      });
    }
  }
};

/**
 * Insert list item if selection in li>p.
 */
export const insertListItem = (editor: Slate.Editor) => {
  if (editor.selection && !isRangeAtRoot(editor.selection)) {
    const paragraphEntry = getAboveByType(editor, tags.BLOCK_PARAGRAPH);
    if (!paragraphEntry) return;
    const [, paragraphPath] = paragraphEntry;

    const [listItemNode, listItemPath] = Slate.Editor.parent(editor, paragraphPath);
    if (listItemNode.type !== tags.BLOCK_LIST_ITEM) return;

    if (!Slate.Range.isCollapsed(editor.selection)) {
      Slate.Transforms.delete(editor);
    }

    const isStart = Slate.Editor.isStart(editor, editor.selection.focus, paragraphPath);
    const isEnd = isBlockTextEmptyAfterSelection(editor);
    const nextParagraphPath = Slate.Path.next(paragraphPath);
    const nextListItemPath = Slate.Path.next(listItemPath);

    /**
     * If start, insert a list item before
     */
    if (isStart) {
      Slate.Transforms.insertNodes(
        editor,
        {
          type: tags.BLOCK_LIST_ITEM,
          children: [{ type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }],
        },
        { at: listItemPath }
      );
      return true;
    }

    /**
     * If not end, split nodes, wrap a list item on the new paragraph and move it to the next list item
     */
    if (!isEnd) {
      Slate.Transforms.splitNodes(editor, { at: editor.selection });
      Slate.Transforms.wrapNodes(
        editor,
        {
          type: tags.BLOCK_LIST_ITEM,
          children: [],
        },
        { at: nextParagraphPath }
      );
      Slate.Transforms.moveNodes(editor, {
        at: nextParagraphPath,
        to: nextListItemPath,
      });
    } else {
      /**
       * If end, insert a list item after and select it
       */
      Slate.Transforms.insertNodes(
        editor,
        {
          type: tags.BLOCK_LIST_ITEM,
          children: [{ type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }],
        },
        { at: nextListItemPath }
      );
      Slate.Transforms.select(editor, nextListItemPath);
    }

    /**
     * If there is a list in the list item, move it to the next list item
     */
    if (listItemNode.children.length > 1) {
      Slate.Transforms.moveNodes(editor, {
        at: nextParagraphPath,
        to: nextListItemPath.concat(1),
      });
    }

    return true;
  }
};

export const outdentList = (
  editor: Slate.Editor,
  listNode: Slate.Ancestor,
  listPath: number[],
  listItemPath: number[]
) => {
  const [listParentNode, listParentPath] = Slate.Editor.parent(editor, listPath);
  if (listParentNode.type !== tags.BLOCK_LIST_ITEM) return;

  const newListItemPath = Slate.Path.next(listParentPath);

  // Move item one level up
  Slate.Transforms.moveNodes(editor, {
    at: listItemPath,
    to: newListItemPath,
  });

  /**
   * Move the next siblings to a new list
   */
  const listItemIdx = listItemPath[listItemPath.length - 1];
  const siblingPath = [...listItemPath];
  const newListPath = newListItemPath.concat(1);
  let siblingFound = false;
  let newSiblingIdx = 0;
  listNode.children.forEach((n, idx) => {
    if (listItemIdx < idx) {
      if (!siblingFound) {
        siblingFound = true;

        Slate.Transforms.insertNodes(
          editor,
          {
            type: listNode.type,
            children: [],
          },
          { at: newListPath }
        );
      }

      siblingPath[siblingPath.length - 1] = listItemIdx;
      const newSiblingsPath = newListPath.concat(newSiblingIdx);
      newSiblingIdx++;
      Slate.Transforms.moveNodes(editor, {
        at: siblingPath,
        to: newSiblingsPath,
      });
    }
  });

  // Remove sublist if it was the first list item
  if (!listItemIdx) {
    Slate.Transforms.removeNodes(editor, {
      at: listPath,
    });
  }

  return true;
};

export const indentList = (
  editor: Slate.Editor,
  listNode: Slate.Ancestor,
  listItemPath: number[]
) => {
  // Previous sibling is the new parent
  const previousSiblingItem = Slate.Editor.node(
    editor,
    Slate.Path.previous(listItemPath)
  ) as Slate.NodeEntry<Slate.Ancestor>;

  if (previousSiblingItem) {
    const [previousNode, previousPath] = previousSiblingItem;

    const sublist = previousNode.children.find((n) => listTypes.includes(n.type as string)) as
      | Element
      | undefined;

    const newPath = previousPath.concat(sublist ? [1, sublist.children.length] : [1]);

    if (!sublist) {
      // Create new sublist
      Slate.Transforms.wrapNodes(
        editor,
        { type: listNode.type, children: [] },
        { at: listItemPath }
      );
    }

    // Move the current item to the sublist
    Slate.Transforms.moveNodes(editor, {
      at: listItemPath,
      to: newPath,
    });
  }
};
