import * as React from 'react';
import {
  AutocompleteInput,
  BooleanField,
  DateField,
  Edit,
  NumberField,
  ReferenceInput,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from 'react-admin';

const UserForm: React.FC<{}> = (props) => {
  return (
    <SimpleForm variant="standard" margin="normal" {...props} toolbar={<UserEditToolbar />}>
      <TextField source="id" />
      <TextInput source="name" fullWidth />
      <TextInput source="email" fullWidth disabled />
      <ReferenceInput label="Kunde" source="customerId" reference="customers">
        <AutocompleteInput
          optionText="name"
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>
      <BooleanField source="emailVerified" />
      <BooleanField source="termsAccepted" />
      <DateField source="lastLogin" />
      <NumberField source="loginCount" />
    </SimpleForm>
  );
};

const UserEditToolbar: React.FC<{}> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const UserEdit: React.FC<{}> = (props) => {
  return (
    <Edit {...props}>
      <UserForm />
    </Edit>
  );
};

export default UserEdit;
