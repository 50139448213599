import React from 'react';
import { Button, Confirm, fetchStart, fetchEnd, useNotify, useRefresh } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { useDispatch } from 'react-redux';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';

export const ContributorMakeStaticButton: React.FC<{ id: string }> = ({ id }) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const [showConfirm, setShowConfirm] = React.useState(false);

  const makeStaticUrl = `${backendUrls.juridikaLitteraturBackend}/admin/v0/contributors/${id}/makeStatic`;

  const doConvert = async () => {
    setShowConfirm(false);
    dispatch(fetchStart());

    try {
      const response = await fetch(makeStaticUrl, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
      if (!response.ok) {
        throw await response.text();
      }
      await response.text();
    } catch (error) {
      notify(`Problem webifying: ${error}`, 'error');
    }

    dispatch(fetchEnd());
    refresh();
  };

  const handleClick = () => {
    setShowConfirm(true);
  };

  return (
    <>
      <Button label="Konverter til statisk" color="primary" onClick={handleClick}>
        <PersonIcon />
      </Button>
      <Confirm
        isOpen={showConfirm}
        title="Konverter til statisk?"
        content={`
          Denne forfatteren vil nå konverteres fra en importert forfatter (fra Idunn) til en offisiell juridika-forfatter.
          Før dette gjøres, forsikre deg om at forfatteren ikke allerede finnes, f.eks. under et annet navn/skrevet på en annen måte.
          Noen ganger vil det eksistere flere idunn-forfattere som egentlig er samme person, med navn skrevet litt forskjellig. Da må man
          velge hvilken av disse som skal bli den offisielle. De andre ikke-offisielle bør da slettes av en utvikler, samt legge
          til en oppføring i contributor_slug_map.
        `}
        onConfirm={doConvert}
        onClose={() => setShowConfirm(false)}
      />
    </>
  );
};

export default ContributorMakeStaticButton;
