import * as React from 'react';
import * as Slate from 'slate';
import { useTranslate } from 'react-admin';
import * as tags from '../../tags';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RecommendedPages1 from '@material-ui/icons/Filter1';
import RecommendedPages2 from '@material-ui/icons/Filter2';
import RecommendedPages3 from '@material-ui/icons/Filter3';
import { Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Plugin } from '../types';
import SlateSimpleMenu from '../../../components/SlateInput/SlateSimpleMenu';

import { insertAsideRecommendedPages } from './transforms';

const SlateMenuItem = (
  editor: Slate.Editor,
  icon: React.ComponentType,
  selected: boolean,
  tag: string,
  tooltip: string
) => {
  return (
    <Tooltip key={tag} title={tooltip}>
      <MenuItem selected={selected} onClick={() => insertAsideRecommendedPages(editor, tag)}>
        {React.createElement(icon)}
      </MenuItem>
    </Tooltip>
  );
};

const key = 'recommended-pages-plugin';

const RecommendedPagesPlugin: React.FC<{ editor: Slate.Editor }> = (props) => {
  const translate = useTranslate();

  return React.createElement(SlateSimpleMenu, {
    key,
    isActive: true,
    Icon: ListAltIcon,
    message: 'slate.contextMenu.recommendedPages',
    menuItems: [
      SlateMenuItem(
        props.editor,
        RecommendedPages1,
        false,
        tags.TAG_RECOMMENDED_PAGES_NEW,
        translate('slate.recommendedArticlesPrefix.new')
      ),
      SlateMenuItem(
        props.editor,
        RecommendedPages2,
        false,
        tags.TAG_RECOMMENDED_PAGES_POPULAR,
        translate('slate.recommendedArticlesPrefix.popular')
      ),
      SlateMenuItem(
        props.editor,
        RecommendedPages3,
        false,
        tags.TAG_RECOMMENDED_PAGES_JURIDIKA_RECOMMENDS,
        translate('slate.recommendedArticlesPrefix.recommended')
      ),
    ],
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <RecommendedPagesPlugin editor={editor} />;
      },
    },
  };
};
