import React, { cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import MuiAppBar from '@material-ui/core/AppBar';
import { Theme } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SyncIcon from '@material-ui/icons/Sync';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { toggleSidebar } from 'ra-core';
import { AppBarProps, LoadingIndicator, UserMenu } from 'react-admin';

import { clearLocalStorageTokens } from '../auth';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
  },
  clearButton: {
    float: 'right',
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(0deg)',
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: 'rotate(180deg)',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const CmsAppBar: React.FC<AppBarProps> = ({
  children,
  className,
  classes,
  logout,
  open,
  title,
  userMenu,
  ...rest
}) => {
  const customClasses = useStyles();
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));

  return (
    <MuiAppBar className={className} color="primary" position="sticky" {...rest}>
      <Toolbar
        disableGutters
        variant={isXSmall ? 'regular' : 'dense'}
        className={customClasses.toolbar}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toggleSidebar())}
          className={customClasses.menuButton}
        >
          <MenuIcon
            classes={{
              root: open ? customClasses.menuButtonIconOpen : customClasses.menuButtonIconClosed,
            }}
          />
        </IconButton>
        <Typography
          variant="h6"
          color="inherit"
          className={customClasses.title}
          id="react-admin-title"
        />
        <div id="cms-context-menu" />
        <LoadingIndicator />
        <IconButton
          color="inherit"
          aria-label="clear auth"
          onClick={() => {
            clearLocalStorageTokens();
            window.location.reload();
          }}
          className={customClasses.clearButton}
        >
          <SyncIcon />
        </IconButton>
        {typeof userMenu === 'boolean' || userMenu === undefined
          ? userMenu === true
            ? cloneElement(<UserMenu />, { logout })
            : null
          : cloneElement(userMenu, { logout })}
      </Toolbar>
    </MuiAppBar>
  );
};

export default CmsAppBar;
