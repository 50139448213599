import * as React from 'react';
import h from 'hastscript';
import uuidv4 from 'uuid/v4';
import Tooltip from '@material-ui/core/Tooltip';

import { DocumentSpec } from '../documentSpec';
import * as tags from '../tags';
import LinkPlugin from '../plugins/LinkPlugin';
import FormatPlugin from '../plugins/FormatPlugin';
import QuotePlugin from '../plugins/QuotePlugin';
import FigurePlugin from '../plugins/FigurePlugin';
import RecommendedPagesPlugin from '../plugins/RecommendedPagesPlugin';
import ListPlugin from '../plugins/ListPlugin';
import HeadingPlugin from '../plugins/HeadingPlugin';
import BoxPlugin from '../plugins/BoxPlugin';
import ParagraphPlugin from '../plugins/ParagraphPlugin';
import NewsArticlePlugin from '../plugins/NewsArticlePlugin';
import NewsArticleAsideFactsPlugin from '../plugins/NewsArticleAsideFactsPlugin';
import BlockFloatPlugin from '../plugins/BlockFloatPlugin';
import SlateImage from '../../containers/ImageEditor/SlateImage';
import RecommendedPages from '../../containers/RecommendedPages/RecommendedPages';
import SlateIframe from '../../components/SlateInput/SlateIframe';
import BoxOutlinePlugin from '../plugins/BoxOutlinePlugin';
import EmptyTextHighlighterPlugin from '../plugins/EmptyTextHighlighterPlugin';
import SoftBreakPlugin from '../plugins/SoftBreakPlugin';
import ForcedLayoutPlugin from '../plugins/ForcedLayoutPlugin';
import TablePlugin from '../plugins/TablePlugin';
import { Rule } from '../plugins/ForcedLayoutPlugin/types';

const ByLine = React.forwardRef(({ children, ...props }, ref: React.Ref<any>) => {
  return (
    <div
      {...props}
      ref={ref}
      className="byline"
      contentEditable={false}
      style={{ userSelect: 'none' }}
    >
      <Tooltip title="Du kan legge til figur under tittelen over bylinen.">
        <code>Byline. Dette innholdet blir generert fra artikkelens metadata.</code>
      </Tooltip>
      {children}
    </div>
  );
});

const createImageNode = () => {
  return {
    type: tags.BLOCK_IMAGE,
    src: '',
    alt: '',
    'data-uuid': uuidv4(),
    'data-fileid': '',
    children: [{ text: '' }],
  };
};

const createFigureNode = () => {
  return {
    type: tags.BLOCK_FIGURE,
    children: [
      createImageNode(),
      { type: tags.BLOCK_FIGCAPTION, children: [{ text: 'Figur tekst' }] },
    ],
  };
};

const layoutRules: Rule[] = [
  {
    match: { type: tags.BLOCK_ARTICLE, className: ['newsArticle'] },
    at: [0],
  },
  {
    match: tags.BLOCK_HEADER,
    at: [0, 0],
    limitChildrenCount: 2,
  },
  {
    match: tags.BLOCK_HEADING_ONE,
    at: [0, 0, 0],
  },
  {
    match: createFigureNode(),
    alwaysPresent: false,
    at: [0, 0, 1],
  },
  {
    match: { type: tags.BLOCK_SECTION, className: ['content'] },
    at: [0, 1],
    limitChildrenCount: 2,
  },
  {
    match: { type: tags.BLOCK_SECTION, className: ['body'] },
    at: [0, 1, 0],
  },
  {
    match: tags.BLOCK_BYLINE,
    at: [0, 1, 0, 0],
  },
  {
    match: tags.BLOCK_LEAD_PARAGRAPH,
    overridePath: false,
    at: [0, 1, 0, 1],
  },
  {
    match: tags.BLOCK_ASIDE,
    at: [0, 1, 1],
    alwaysPresent: false,
    strictChildenTypes: [
      tags.BLOCK_FACTS,
      tags.BLOCK_RECOMMENDED_PAGES_MODULE,
      tags.BLOCK_END_PARAGRAPH,
    ],
  },
];

export default DocumentSpec.createSpec({
  plugins: [
    NewsArticlePlugin(),
    ForcedLayoutPlugin(layoutRules),
    FormatPlugin(),
    LinkPlugin(),
    HeadingPlugin([
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
      tags.BLOCK_LEAD_PARAGRAPH,
    ]),
    BlockFloatPlugin({
      [tags.BLOCK_FIGURE]: {
        leftClassName: 'figure--isLeft',
        rightClassName: 'figure--isRight',
      },
      [tags.BLOCK_QUOTE]: {
        leftClassName: 'quote--isLeft',
        rightClassName: 'quote--isRight',
      },
    }),
    BoxPlugin({
      boxTypes: [tags.BLOCK_FACTS, tags.BLOCK_IFRAME],
      useDefaultInsertEditorBreakInTypes: [tags.BLOCK_ASIDE],
    }),
    QuotePlugin(),
    ListPlugin(),
    FigurePlugin(),
    ParagraphPlugin(),
    NewsArticleAsideFactsPlugin(),
    RecommendedPagesPlugin(),
    TablePlugin(),
    SoftBreakPlugin({
      activeInTypes: [
        tags.BLOCK_PARAGRAPH,
        tags.BLOCK_LEAD_PARAGRAPH,
        tags.BLOCK_QUOTE,
        tags.BLOCK_QUOTE_DISPOSITION,
        tags.BLOCK_FIGCAPTION,
      ],
    }),
    EmptyTextHighlighterPlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_FIGCAPTION,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
    BoxOutlinePlugin([
      tags.BLOCK_PARAGRAPH,
      tags.BLOCK_LEAD_PARAGRAPH,
      tags.BLOCK_BULLETED_LIST,
      tags.BLOCK_LIST_ITEM,
      tags.BLOCK_ALPHA_LIST,
      tags.BLOCK_NUMBERED_LIST,
      tags.BLOCK_FIGURE,
      tags.BLOCK_HEADING_ONE,
      tags.BLOCK_HEADING_TWO,
      tags.BLOCK_HEADING_THREE,
      tags.BLOCK_HEADING_FOUR,
    ]),
  ],
  hypertextRules: {
    blocks: {
      article: [
        {
          className: 'newsArticle',
          type: tags.BLOCK_ARTICLE,
        },
      ],
      byline: [
        {
          type: tags.BLOCK_BYLINE,
          isVoid: true,
          render: ByLine,
        },
      ],
      header: [
        {
          type: tags.BLOCK_HEADER,
        },
      ],
      section: [
        {
          type: tags.BLOCK_SECTION,
        },
      ],
      aside: [
        {
          className: 'boxedText',
          type: tags.BLOCK_FACTS,
        },
        {
          className: 'recommendedPagesModule',
          type: tags.BLOCK_RECOMMENDED_PAGES_MODULE,
        },
        {
          className: 'endParagraph',
          type: tags.BLOCK_END_PARAGRAPH,
        },
      ],
      figure: [
        {
          optionalClassNames: ['figure--isLeft', 'figure--isRight'],
          type: tags.BLOCK_FIGURE,
        },
      ],
      figcaption: [{ type: tags.BLOCK_FIGCAPTION }],
      p: [
        {
          className: 'leadParagraph',
          type: tags.BLOCK_LEAD_PARAGRAPH,
        },
        {
          type: tags.BLOCK_PARAGRAPH,
        },
      ],
      ul: [
        {
          className: 'references',
          type: tags.BLOCK_REFERENCE_LIST,
        },
        {
          type: tags.BLOCK_BULLETED_LIST,
        },
      ],
      ol: [
        {
          className: 'alpha',
          type: tags.BLOCK_ALPHA_LIST,
        },
        {
          type: tags.BLOCK_NUMBERED_LIST,
        },
      ],
      li: [{ type: tags.BLOCK_LIST_ITEM }],
      div: [
        {
          className: 'listItemContent',
          type: tags.BLOCK_LIST_ITEM_CONTENT,
        },
        {
          className: 'facts',
          type: tags.BLOCK_ASIDE,
        },
        {
          className: 'recommendedPages',
          type: tags.BLOCK_RECOMMENDED_PAGES,
          isVoid: true,
          render: RecommendedPages,
        },
      ],
      blockquote: [
        {
          className: 'quote',
          optionalClassNames: ['quote--isLeft', 'quote--isRight'],
          type: tags.BLOCK_QUOTE,
        },
        {
          className: 'dispQuote',
          type: tags.BLOCK_QUOTE_DISPOSITION,
        },
      ],
      h1: [{ type: tags.BLOCK_HEADING_ONE }],
      h2: [
        { type: tags.BLOCK_HEADING_TWO },
        { type: tags.BLOCK_RECOMMENDED_PAGES_HEADER, className: 'recommendedPagesHeading' },
      ],
      h3: [{ type: tags.BLOCK_HEADING_THREE }],
      h4: [{ type: tags.BLOCK_HEADING_FOUR }],
      h5: [{ type: tags.BLOCK_HEADING_FIVE }],
      h6: [{ type: tags.BLOCK_HEADING_SIX }],
      img: [
        {
          data: ['src', 'alt', 'title', 'data-uuid', 'data-fileid'],
          type: tags.BLOCK_IMAGE,
          isVoid: true,
          render: SlateImage,
        },
      ],
      table: [{ type: tags.BLOCK_TABLE }],
      thead: [{ type: tags.BLOCK_TABLE_HEADER }],
      tbody: [{ type: tags.BLOCK_TABLE_BODY }],
      tr: [{ type: tags.BLOCK_TABLE_ROW }],
      th: [{ type: tags.BLOCK_TABLE_HEADER_CELL }],
      td: [{ type: tags.BLOCK_TABLE_CELL }],
      iframe: [
        {
          data: ['name', 'src', 'srcDoc', 'width', 'height', 'sandbox'],
          type: tags.BLOCK_IFRAME,
          render: SlateIframe,
        },
      ],
    },
    inlines: {
      a: [
        {
          type: tags.INLINE_LINK,
          optionalData: ['href'],
        },
      ],
      q: [
        {
          type: tags.INLINE_QUOTE,
        },
      ],
    },
    marks: {
      strong: [{ type: tags.MARK_BOLD }],
      em: [{ type: tags.MARK_ITALIC }],
      u: [{ type: tags.MARK_UNDERLINE }],
      s: [{ type: tags.MARK_STRIKETHROUGH }],
      cite: [{ type: tags.MARK_CITE }],
      b: [{ reimportHtmlTagAs: 'strong' }],
      i: [{ reimportHtmlTagAs: 'em' }],
    },
  },
  serializationFormat: 'hast',
});

export const DefaultHast = () =>
  h('fragment', {}, h('h1', {}, 'Overskrift'), h('p', { className: 'leadParagraph' }, ''));
