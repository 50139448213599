import * as React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  TabbedForm,
  FormTab,
  TextInput,
  ArrayInput,
  AutocompleteInput,
  AutocompleteArrayInput,
  NumberInput,
  SimpleFormIterator,
  CreateProps,
  EditProps,
  SelectInput,
  Toolbar,
  SaveButton,
  FieldProps,
  ReferenceInput,
  CheckboxGroupInput,
  required,
  minValue,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { LiteratureConversionProcess } from '../../dataProvider/literatureConversionProcessesProvider';

const IndesignTocStylesFormIterator = withStyles({
  form: {
    display: 'inline-grid',
  },
})(SimpleFormIterator);

const useTocStyles = makeStyles({
  // row 1:
  indesignTocStyles_name: {
    gridColumnStart: 1,
  },
  indesignTocStyles_level: {
    gridColumnStart: 2,
  },
  indesignTocStyles_bitsUnit: {
    gridColumnStart: 3,
  },

  // row 2:
  indesignTocStyles_subtitleStyles: {
    gridColumnStart: 1,
    paddingRight: '1rem',
  },
  indesignTocStyles_titleLabelPattern: {
    gridColumnStart: 3,
    paddingRight: '1rem',
  },

  // row 3:
  indesignTocStyles_options: {
    gridColumn: '1 / span 2',
  },
  indesignTocStyles_titleLayout: {
    gridColumnStart: 3,
    paddingRight: '1rem',
  },
});

const usePdfPageLabelStyles = makeStyles({
  // row 1:
  pdfPageLabelsIndex: {
    gridColumnStart: 1,
  },
  pdfPageLabelsStyle: {
    gridColumnStart: 2,
  },
  pdfPageLabelsStart: {
    gridColumnStart: 3,
  },
});

interface Props {
  record?: LiteratureConversionProcess;
}

interface StringChoice {
  id: string;
  name: string;
}

const getParagraphStyleChoices = (record?: LiteratureConversionProcess): StringChoice[] => {
  const idmlMetadata = record?.customIdmlFormat?.metadata;
  if (!idmlMetadata) return [];

  return idmlMetadata.namedParagraphStyles
    .slice()
    .sort()
    .map((styleName) => ({
      id: styleName,
      name: styleName,
    }));
};

const getCharacterStyleChoices = (record?: LiteratureConversionProcess): StringChoice[] => {
  const idmlMetadata = record?.customIdmlFormat?.metadata;
  if (!idmlMetadata) return [];

  return idmlMetadata.namedCharacterStyles
    .slice()
    .sort()
    .map((styleName) => ({
      id: styleName,
      name: styleName,
    }));
};

const LiteratureConversionProcessEditToolbar: React.FC = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton redirect="show" />
    </Toolbar>
  );
};

const PageNumbersHelpText: React.FC<FieldProps> = (props) => {
  return (
    <>
      <Typography>Hjelp til «PDF page label ranges»:</Typography>
      <Typography color="textSecondary">
        PDF side-indeks: PDF side-indeksen for en side får man ved å telle hver side i PDF-filen fra
        0 og oppover. Den første siden har PDF side-indeks 0, den andre siden har PDF side-indeks 1,
        osv. Hvis man ser på PDF-filen i Acrobat, og det står sidetall i parantes, f.eks «(27 av
        698)», så er det tallet i parantes minus 1 (i dette tilfelle 26 siden 27-1 = 26) som er PDF
        side-indeksen.
      </Typography>
      <Typography color="textSecondary">
        Eksempel for en bok med romertall-stil for de første sidene, og desimaltall-stil for resten
        av boken: Dette angis da med to «Page label ranges»-rader, med romertall-stil for den
        første, og desmaltall-stil for den andre. Rad 1: Hvis første side i PDF-en skal angis som
        romertall side I, angis det med 0 i PDF side-indeks-feltet og 1 i tellingsstart-feltet. Rad
        2: Hvis så den 35. siden i boken, som da har PDF side-indeks 34, skal angis som side 30 (for
        det står «30» som sidetall på siden, eller «31» på neste side), så skal det stå 34 i PDF
        side-indeks-feltet og 30 i tellingsstart-feltet.
      </Typography>
    </>
  );
};

const IndesignStyleHelpText: React.FC<FieldProps> = (props) => {
  return (
    <Typography color="textSecondary">
      Denne informasjonen brukes som hint for generering av forskjellige typer bits-tagger.
    </Typography>
  );
};

const FrontMatterIndesignTocStyleHelpText: React.FC<FieldProps> = (props) => {
  return (
    <Typography color="textSecondary">
      InDesign TOC styles for front-matter. Front-matter avgrenses av "book-body startsidenavn"
      under SIDETALL-taben. Se ellers hjelpetekst for TOC (BODY).
    </Typography>
  );
};

const BookBodyIndesignTocStyleHelpText: React.FC<FieldProps> = (props) => {
  return (
    <Typography color="textSecondary">
      InDesign TOC styles for bokens hovedkropp (book-body).
      <br />
      Hele BITS-dokumentstrukturen deles opp basert på InDesign-overskrifter i følgende stil og
      TOC-nivå. Denne informasjonen har altså to effekter: Oppdeling av hele bokstrukturen i
      book-part/section pluss generering av en bits-toc som speiler den samme strukturen. Det brukes
      omtrent samme algoritme som InDesign selv bruker for å generere sin egen TOC til trykking/PDF.
      <br />
      De følgene stilene gjelder for bokens book-body (ikke front-matter og book-back, se neste de
      andre TOC-tabene):
    </Typography>
  );
};

const BackMatterIndesignTocStyleHelpText: React.FC<FieldProps> = (props) => {
  return (
    <Typography color="textSecondary">
      InDesign TOC styles for book-back. Book-back avgrenses av "book-back startsidenavn" under
      SIDETALL-taben. Se ellers hjelpetekst for TOC (BODY).
    </Typography>
  );
};

const validateLevel = [required(), minValue(1, 'må være større enn 0')];

const InDesignTocStylesArrayInput: React.FC<{
  source: string;
  paragraphStyleChoices: StringChoice[];
  disableAdvancedSettings: boolean;
}> = ({ source, paragraphStyleChoices, disableAdvancedSettings }) => {
  const classes = useTocStyles();

  return (
    <ArrayInput source={source}>
      <IndesignTocStylesFormIterator>
        <AutocompleteInput
          source="name"
          choices={paragraphStyleChoices}
          formClassName={classes.indesignTocStyles_name}
          label="Stilnavn"
        />
        <NumberInput
          source="level"
          validate={validateLevel}
          label="Nivå"
          formClassName={classes.indesignTocStyles_level}
          helperText="Øverste nivå er 1"
        />
        <SelectInput
          source="bitsUnit"
          label="Bits-enhet"
          disabled={disableAdvancedSettings}
          defaultValue="book-part"
          choices={[
            { id: 'book-part', name: '<book-part type="part">' },
            { id: 'chapter-book-part', name: '<book-part type="chapter">' },
            { id: 'section-with-local-counter', name: '<sec> med lokal id-teller' },
            { id: 'section-with-global-counter', name: '<sec> med global id-teller' },
          ]}
          formClassName={classes.indesignTocStyles_bitsUnit}
          helperText="Type inndeling denne skal kodes som i BITS"
        />
        <AutocompleteArrayInput
          source="subtitleStyles"
          label="Undertittelstiler"
          choices={paragraphStyleChoices}
          helperText="Stiler som legger til ekstra <subtitle> til dette TOC-nivået"
          formClassName={classes.indesignTocStyles_subtitleStyles}
          fullWidth
        />
        <SelectInput
          source="titleLabelPattern"
          label="label-mønster"
          choices={[
            { id: 'first-word', name: 'Ord + tittel (Eks: "(a) Innledning")' },
            { id: 'first-word-with-numbers', name: 'Tall + tittel (Eks: "1. Innledning")' },
            { id: 'section-sign', name: 'Paragraftegn § + tall + tittel (Eks: "§ 23. Formål")' },
            {
              id: 'everything',
              name: 'Alt er label (Eks: "Kapittel 1"). Første undertittel blir til omgjort tittel.',
            },
          ]}
          allowEmpty
          emptyText="Ingen"
          formClassName={classes.indesignTocStyles_titleLabelPattern}
          helperText="Bruk et mønster for å gjenkjenne <label> (eller ingenting)"
        />
        <CheckboxGroupInput
          source="options"
          label="Valg"
          choices={[
            { id: 'includeInToc', name: 'Inkluder i TOC' },
            { id: 'resetParagraphCounter', name: 'Nullstill avsnittsnummer-teller' },
            { id: 'resetFootnoteCounter', name: 'Nullstill fotnote-teller' },
            { id: 'deduplicateGhostMarginTitle', name: 'Fjern margduplikat av spøkelsestittel' },
          ]}
          formClassName={classes.indesignTocStyles_options}
        />
        <SelectInput
          source="titleLayout"
          label="tittel-layout"
          defaultValue="heading"
          choices={[
            { id: 'heading', name: 'Overskrift' },
            { id: 'margin-title', name: 'Margtittel' },
          ]}
          formClassName={classes.indesignTocStyles_titleLayout}
          helperText="Hvordan tittelen bør vises i presentasjon"
          disabled={disableAdvancedSettings}
        />
      </IndesignTocStylesFormIterator>
    </ArrayInput>
  );
};

const LiteratureConversionProcessEditForm: React.FC<Props> = (props) => {
  const pdfPageClasses = usePdfPageLabelStyles();
  const paragraphStyleChoices = React.useMemo(() => getParagraphStyleChoices(props.record), [
    props.record,
  ]);
  const characterStyleChoices = React.useMemo(() => getCharacterStyleChoices(props.record), [
    props.record,
  ]);

  return (
    <TabbedForm
      {...props}
      variant="standard"
      margin="normal"
      toolbar={<LiteratureConversionProcessEditToolbar />}
    >
      <FormTab label="Generelt">
        <TextInput source="description" fullWidth />
        <ReferenceInput
          label="Tilknyttet utgave"
          source="editionId"
          reference="textbookEditions"
          fullWidth
        >
          <AutocompleteInput
            optionValue="id"
            optionText="descriptiveName"
            allowEmpty={true}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
      </FormTab>
      <FormTab label="Sidetall">
        <TextInput
          source="config.data.titlePageName"
          helperText="Sidetallet. Eksempel: 3 eller III"
        />
        <TextInput
          source="config.data.colophonPageName"
          helperText="Sidetallet. Eksempel: 4 eller IV"
        />
        <TextInput
          source="config.data.bookBodyStartPageName"
          helperText="Sidetallet. Første side som _ikke_ er front-matter"
        />
        <TextInput
          source="config.data.backMatterStartPageName"
          helperText="Sidetallet. Første side etter hovedinnholdet, registre eller book-back"
        />
        <ArrayInput source="config.data.pdfPageLabelRanges">
          <IndesignTocStylesFormIterator>
            <NumberInput
              source="index"
              defaultValue={0}
              label="PDF side-indeks"
              helperText="PDF-sideindeks (første side er 0)"
              formClassName={pdfPageClasses.pdfPageLabelsIndex}
            />
            <SelectInput
              source="style"
              label="Stil"
              defaultValue="D"
              choices={[
                { id: 'D', name: 'D (desimaltall)' },
                { id: 'R', name: 'R (romertall, kapitalisert)' },
                { id: 'r', name: 'r (romertall)' },
                { id: 'A', name: 'A (bokstav, kapitalisert)' },
                { id: 'a', name: 'a (bokstav)' },
              ]}
              formClassName={pdfPageClasses.pdfPageLabelsStyle}
              helperText="Sidenummererings-stil"
            />
            <NumberInput
              source="start"
              defaultValue={1}
              label="Tellingsstart"
              helperText="Hvor tellingen starter"
              formClassName={pdfPageClasses.pdfPageLabelsStart}
            />
          </IndesignTocStylesFormIterator>
        </ArrayInput>

        <PageNumbersHelpText />
      </FormTab>

      <FormTab label="TOC (front)">
        <FrontMatterIndesignTocStyleHelpText addLabel={false} />
        <InDesignTocStylesArrayInput
          source="config.data.frontMatterIndesignTocStyles"
          paragraphStyleChoices={paragraphStyleChoices}
          disableAdvancedSettings={true}
        />
      </FormTab>
      <FormTab label="TOC (body)">
        <BookBodyIndesignTocStyleHelpText addLabel={false} />
        <InDesignTocStylesArrayInput
          source="config.data.indesignTocStyles"
          paragraphStyleChoices={paragraphStyleChoices}
          disableAdvancedSettings={false}
        />
      </FormTab>
      <FormTab label="TOC (back)">
        <BackMatterIndesignTocStyleHelpText addLabel={false} />
        <InDesignTocStylesArrayInput
          source="config.data.backMatterIndesignTocStyles"
          paragraphStyleChoices={paragraphStyleChoices}
          disableAdvancedSettings={true}
        />
      </FormTab>
      <FormTab label="InDesign-stiler">
        <IndesignStyleHelpText addLabel={false} />

        <AutocompleteArrayInput
          source="config.data.indesignStyles.exclude"
          choices={paragraphStyleChoices}
          helperText="Paragrafstiler hvis innhold blir helt utelatt fra bits-boken"
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          source="config.data.indesignStyles.characterExclude"
          choices={characterStyleChoices}
          helperText="Karakter-stiler hvis innhold blir helt utelatt fra bits-boken"
          variant="outlined"
          fullWidth
        />

        <AutocompleteArrayInput
          source="config.data.indesignStyles.quote"
          choices={paragraphStyleChoices}
          helperText="Sammenhengende rekker av disse paragrafstilene blir lagt i en <disp-quote>"
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          source="config.data.indesignStyles.list"
          choices={paragraphStyleChoices}
          helperText="Sammenhengende rekker av disse paragrafstilene blir lagt i en <list>, og hver blokk/paragraf blir til et <list-item>"
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          source="config.data.indesignStyles.tableHeader"
          choices={paragraphStyleChoices}
          helperText="Stil i en tabell som representerer den første raden med f.eks. beskrivende navn til kolonnene"
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          source="config.data.indesignStyles.petit"
          choices={paragraphStyleChoices}
          helperText={
            'Sammenhengende rekker av disse paragrafstilene blir lagt i <p content-type="petit">'
          }
          variant="outlined"
          fullWidth
        />
        <AutocompleteArrayInput
          source="config.data.indesignStyles.caption"
          choices={paragraphStyleChoices}
          helperText="Figurtekst og grafikk/tabell blir i tospann blir omgjort til <fig>/<table-wrap> (uavhengig av rekkefølgen) med <caption>"
          variant="outlined"
          fullWidth
        />
      </FormTab>
    </TabbedForm>
  );
};

export const LiteratureConversionProcessEdit: React.FC<Props & EditProps> = (props) => {
  return (
    <Edit {...props}>
      <LiteratureConversionProcessEditForm />
    </Edit>
  );
};

export const LiteratureConversionProcessCreate: React.FC<Props & CreateProps> = (props) => {
  return (
    <Create {...props}>
      <SimpleForm variant="standard" margin="normal">
        <TextInput
          source="description"
          helperText="F.eks. arbeidstittel - Brukes kun internt. Bruk helst et beskrivende navn så boken kan finnes igjen."
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
