import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchStart, fetchEnd } from 'react-admin';

import { juridikaHeaders } from '../../dataProvider/apiUtil';

import * as simpleXml from '../../util/simpleXml';
import { LiteratureConversionBitsFormat } from '../../dataProvider/literatureConversionBitsFormatsProvider';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';
import { BitsDebug } from '../bitsDebug/BitsDebug';

export type Props = {
  record?: LiteratureConversionBitsFormat;
};

export const CONFIG: simpleXml.DeserializationConfig = {
  initialExpandedLevels: 0,
};

const BitsDebugWithModel: React.FC<{ initialModel: simpleXml.Model; processId: string }> = ({
  initialModel,
  processId,
}) => {
  const [documentMutated, setDocumentMutated] = React.useState(false);
  const [model, applyMutation] = simpleXml.useXmlModel(initialModel);
  return (
    <BitsDebug
      processId={processId}
      book={model as simpleXml.ElementModel}
      documentMutated={documentMutated}
      applyViewMutation={applyMutation}
      applyDocumentMutation={(mutation) => {
        applyMutation(mutation);
        setDocumentMutated(true);
      }}
    />
  );
};

const DocumentForm: React.FC<{
  record?: LiteratureConversionBitsFormat;
}> = ({ record }) => {
  const dispatch = useDispatch();
  const { backendUrls } = React.useContext(JuridikaCmsContext);
  const [book, setBook] = React.useState<simpleXml.Model | null>(null);

  React.useEffect(() => {
    const url = `${backendUrls.juridikaLitteraturBackend}/admin/v0/conversionBitsFormats/${record?.id}/format`;
    const downloadXml = async () => {
      dispatch(fetchStart());
      const response = await fetch(url, {
        headers: {
          ...juridikaHeaders(),
          accept: 'application/json',
        },
      });

      const jsonml = (await response.json()) as any;

      dispatch(fetchEnd());

      if (!jsonml) return;
      const model = simpleXml.fromJsonML(jsonml, CONFIG);
      setBook(model);
    };

    if (record) {
      downloadXml();
    }
  }, [record, backendUrls, dispatch]);

  if (record && book) {
    return (
      <BitsDebugWithModel
        initialModel={book as simpleXml.ElementModel}
        processId={record.processId}
      />
    );
  }

  return null;
};

export default DocumentForm;
