import * as React from 'react';
import * as Slate from 'slate';
import BorderOuterIcon from '@material-ui/icons/BorderOuter';

import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import SchoolIcon from '@material-ui/icons/School';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';

import { toggleElement } from '../../utils/transforms';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import SlateSimpleMenu from '../../../components/SlateInput/SlateSimpleMenu';

const BoxButton: React.FC<{
  editor: Slate.Editor;
  types: string[];
  openDialog: (editorSelectionPath: Slate.Path) => void;
}> = (props) => {
  const blockAboveEntry = getAboveByType(props.editor, [tags.BLOCK_FACTS, tags.BLOCK_IFRAME]);

  const menuItems = [];

  if (props.types.includes(tags.BLOCK_FACTS)) {
    menuItems.push(
      <MenuItem
        selected={blockAboveEntry?.[0].type === tags.BLOCK_FACTS}
        key="facts"
        onClick={() => toggleElement(props.editor, tags.BLOCK_FACTS)}
      >
        <Tooltip title="Faktaboks">
          <SchoolIcon />
        </Tooltip>
      </MenuItem>
    );
  }

  if (props.types.includes(tags.BLOCK_IFRAME)) {
    menuItems.push(
      <MenuItem
        key="iframe"
        selected={blockAboveEntry?.[0].type === tags.BLOCK_IFRAME}
        onClick={() => {
          if (!props.editor?.selection?.focus) {
            throw Error('Editor selection should not be null');
          }
          props.openDialog(props.editor.selection!!.focus.path);
        }}
      >
        <Tooltip title="iframe">
          <MusicVideoIcon />
        </Tooltip>
      </MenuItem>
    );
  }

  return React.createElement(SlateSimpleMenu, {
    isActive: !!blockAboveEntry,
    Icon: BorderOuterIcon,
    message: 'slate.contextMenu.box',
    menuItems,
  });
};

export default BoxButton;
