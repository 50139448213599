import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

import { ElementModel, Model, TEXT } from '../../util/simpleXml';
import { BitsElement, Stack, stackContainsTag, isSimpleList } from './BitsDebugUtil';
import {
  HeadingTypography,
  ParagraphTypography,
  PetitParagraphTypography,
} from './BitsDebugTypography';
import { LowLevelElementInteraction } from './BitsDebugUI';

const useStyles = makeStyles({
  footnoteBox: { backgroundColor: '#b1cbbb' },
  footnoteReference: { backgroundColor: '#f0efef' },
  listSimple: {
    listStyle: 'none',
    position: 'relative',
    '& span': {
      position: 'absolute',
      left: '0.1rem',
      width: '2.2rem',
      textAlign: 'right',
      lineHeight: '1.25',
    },
  },
  refList: {
    listStyle: 'none',
  },
  dispQuote: {
    fontStyle: 'italic',
    fontWeight: 600,
    lineHeight: 1.375,
    backgroundColor: '#e6daed',
  },
  table: {
    '& tr:nth-child(odd)': {
      backgroundColor: '#e2e2e2',
    },
  },
  targetPagebreak: {
    color: '#bc5a45',
    backgroundColor: '#f4e1d2',
    fontFamily: 'monospace',
    textDecoration: 'none',
  },
  term: {
    color: '#000',
    backgroundColor: '#ffeead',
    textDecoration: 'none',
  },
  seeEntry: {
    color: '#000',
    backgroundColor: '#f9d5e5',
    textDecoration: 'none',
  },
  label: { backgroundColor: '#faebd7' },
  fig: { backgroundColor: '#d9ecd0' },
});

export const NodeText: React.FC<{ node: Model; stack: Stack; siblings: ReadonlyArray<Model> }> = ({
  node,
  stack,
  siblings,
}) => {
  if (node.type === TEXT) return <>{node.value}</>;
  const newStack = { element: node, stack, siblings };
  return (
    <>
      {node.children.map((child, index) => {
        if (child.type === TEXT) {
          return <NodeText key={index} node={child} stack={newStack} siblings={node.children} />;
        }

        return (
          <ElementText key={index} element={child} stack={newStack} siblings={node.children} />
        );
      })}
    </>
  );
};

export const ElementText: BitsElement = ({ element, stack, siblings }) => {
  switch (element.tagName) {
    case 'target':
      return <Target element={element} stack={stack} siblings={siblings} />;
    default:
      return <NodeText node={element} stack={stack} siblings={siblings} />;
  }
};

export const LowLevelElement: BitsElement = ({ element, stack, siblings }) => {
  const classes = useStyles();
  switch (element.tagName) {
    case 'object-id':
    case 'book-part-id':
      return null;
    case 'title-group':
    case 'nav-pointer-group':
    case 'mixed-citation':
    case 'person-group':
    case 'string-name':
    case 'surname':
    case 'given-names':
      return (
        <>
          <LowLevelNodes nodes={element.children} stack={{ element, stack, siblings }} />
        </>
      );
    case 'title':
    case 'subtitle':
      return <Title element={element} stack={stack} siblings={siblings} />;
    case 'p':
      return <Paragraph element={element} stack={stack} siblings={siblings} />;
    case 'fig':
      return <Fig element={element} stack={stack} siblings={siblings} />;
    case 'graphic':
      return <Graphic element={element} stack={stack} siblings={siblings} />;
    case 'disp-quote':
      return <DispQuote element={element} stack={stack} siblings={siblings} />;
    case 'list':
      return <List element={element} stack={stack} siblings={siblings} />;
    case 'list-item':
      return (
        <li>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </li>
      );
    case 'ref-list':
      return <RefList element={element} stack={stack} siblings={siblings} />;
    case 'ref': {
      return <Ref element={element} stack={stack} siblings={siblings} />;
    }
    case 'table-wrap':
      return (
        <div>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </div>
      );
    case 'table':
      return <Table element={element} stack={stack} siblings={siblings} />;
    case 'thead':
    case 'tbody':
      return <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />;
    case 'tr':
      return (
        <tr>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </tr>
      );
    case 'th':
      return (
        <th>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </th>
      );
    case 'td':
      return <Td element={element} stack={stack} siblings={siblings} />;
    case 'label':
      return <Label element={element} stack={stack} siblings={siblings} />;
    case 'fn':
      return (
        <sup className={classes.footnoteReference}>
          <FootnoteSymbol element={element} stack={stack} siblings={siblings} />
        </sup>
      );
    case 'target':
      return <Target element={element} stack={stack} siblings={siblings} />;
    case 'italic':
      return (
        <em>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </em>
      );
    case 'bold':
      return (
        <strong>
          <LowLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </strong>
      );
    case 'xref':
    case 'nav-pointer':
      return <XrefOrNavPointer element={element} stack={stack} siblings={siblings} />;
    case 'see-entry':
    case 'see-also-entry':
      return <SeeEntry element={element} stack={stack} siblings={siblings} />;
    case 'ext-link':
      return <ExtLink element={element} stack={stack} siblings={siblings} />;
    case 'term':
      return <Term element={element} stack={stack} siblings={siblings} />;
    default:
      return <ElementText element={element} stack={stack} siblings={siblings} />;
  }
};

export const LowLevelNode: React.FC<{
  node: Model;
  stack: Stack;
  siblings: ReadonlyArray<Model>;
}> = ({ node, stack, siblings }) => {
  if (node.type === TEXT) return <>{node.value}</>;
  return <LowLevelElement element={node} stack={stack} siblings={siblings} />;
};

/**
 * Render low level nodes.
 * Even if a list of nodes is rendered, it is possible to also send a sibling list.
 * this needs to be done in case the node list is not the same as the sibling list.
 */
export const LowLevelNodes: React.FC<{
  nodes: Model[];
  siblings?: ReadonlyArray<Model>;
  stack: Stack;
}> = ({ nodes, siblings, stack }) => {
  const resolvedSiblings = siblings ?? nodes;
  return (
    <>
      {nodes.map((node, index) => (
        <LowLevelNode key={index} node={node} stack={stack} siblings={resolvedSiblings} />
      ))}
    </>
  );
};

const Paragraph: BitsElement = ({ element, siblings, stack }) => {
  const newStack = { element, siblings, stack };
  const children = (
    <LowLevelNodes nodes={element.children} siblings={element.children} stack={newStack} />
  );

  switch (element.attributes?.['content-type']) {
    case 'petit':
      return <PetitParagraphTypography stack={newStack}>{children}</PetitParagraphTypography>;
    default:
      return <ParagraphTypography stack={newStack}>{children}</ParagraphTypography>;
  }
};

const DispQuote: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };

  return (
    <div className={classes.dispQuote}>
      <LowLevelElementInteraction stack={newStack}>
        <LowLevelNodes nodes={element.children} siblings={element.children} stack={newStack} />
      </LowLevelElementInteraction>
    </div>
  );
};

const Table: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };

  return (
    <table className={classes.table}>
      <LowLevelNodes nodes={element.children} siblings={element.children} stack={newStack} />
    </table>
  );
};

const Td: BitsElement = ({ element, siblings, stack }) => {
  const newStack = { element, siblings, stack };

  return (
    <td colSpan={element.attributes['colspan']} rowSpan={element.attributes['rowspan']}>
      <LowLevelElementInteraction stack={newStack}>
        <LowLevelNodes nodes={element.children} stack={newStack} />
      </LowLevelElementInteraction>
    </td>
  );
};

const Title: BitsElement = ({ element, siblings, stack }) => {
  const isDeepToc = (stack: Stack | null): boolean => {
    if (!stack) return false;
    switch (stack.element.tagName) {
      case 'toc-entry':
      case 'toc-div':
        return true;
      default:
        return isDeepToc(stack.stack);
    }
  };

  if (isDeepToc(stack)) {
    return <ElementText element={element} stack={stack} siblings={siblings} />;
  }

  return <Heading element={element} stack={stack} siblings={siblings} />;
};

export const Heading: React.FC<{
  textClassName?: string;
  element: ElementModel;
  siblings: ReadonlyArray<Model>;
  stack: Stack;
}> = ({ textClassName, element, stack, siblings }) => {
  const newStack = { element, siblings, stack };

  return (
    <HeadingTypography stack={newStack} level={4}>
      <span className={textClassName}>
        <LowLevelNodes nodes={element.children} stack={newStack} />
      </span>
    </HeadingTypography>
  );
};

export const Fig: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const children = element.children.slice();

  // Taking advantage of 'stable sort':
  children.sort((child) => {
    if (child.type === TEXT) {
      return 0;
    }

    // Caption should move below
    if (child.tagName === 'caption') {
      return 1;
    }

    return 0;
  });

  return (
    <div className={classes.fig}>
      <LowLevelNodes nodes={children} stack={{ element, siblings, stack }} />
    </div>
  );
};

export const Graphic: BitsElement = ({ element, stack }) => {
  const href = element.attributes['xlink:href'];
  return (
    <p>
      <code>
        <PhotoLibraryIcon />
        grafikk med xlink:href="{href}"
      </code>
    </p>
  );
};

export const List: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };

  const listType = element.attributes['list-type'];
  const children = <LowLevelNodes nodes={element.children} stack={newStack} />;
  let listElement: React.ReactElement;

  switch (listType) {
    case 'number':
      listElement = <ol>{children}</ol>;
      break;
    case 'bullet':
      listElement = <ul>{children}</ul>;
      break;
    case 'alpha-lower':
      listElement = <ol type="a">{children}</ol>;
      break;
    case 'alpha-upper':
      listElement = <ol type="A">{children}</ol>;
      break;
    case 'roman-lower':
      listElement = <ol type="i">{children}</ol>;
      break;
    case 'roman-upper':
      listElement = <ol type="I">{children}</ol>;
      break;
    case 'simple':
    default:
      listElement = <ul className={classes.listSimple}>{children}</ul>;
      break;
  }

  return <LowLevelElementInteraction stack={newStack}>{listElement}</LowLevelElementInteraction>;
};

export const RefList: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };
  return (
    <LowLevelElementInteraction stack={newStack}>
      <ul className={classes.refList}>
        <LowLevelNodes nodes={element.children} stack={newStack} />
      </ul>
    </LowLevelElementInteraction>
  );
};

export const Ref: BitsElement = ({ element, siblings, stack }) => {
  const newStack = { element, siblings, stack };
  return (
    <li>
      <LowLevelElementInteraction stack={newStack}>
        <p>
          <LowLevelNodes nodes={element.children} stack={newStack} />
        </p>
      </LowLevelElementInteraction>
    </li>
  );
};

export const XrefOrNavPointer: BitsElement = ({ element, siblings, stack }) => {
  const { rid } = element.attributes;
  const newStack = { element, siblings, stack };

  let title = element.tagName;

  if (rid.startsWith('pb')) {
    title += ` til side ${rid.replace('pb', '')}`;
  }

  return (
    <a href={`#${rid}`} title={title}>
      <LowLevelNodes nodes={element.children} stack={newStack} />
    </a>
  );
};

export const SeeEntry: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };
  return (
    <a href={`#${element.tagName}`} className={classes.seeEntry} title={element.tagName}>
      <LowLevelNodes nodes={element.children} stack={newStack} />
    </a>
  );
};

export const Term: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };
  return (
    <a href="#term" className={classes.term} title="Term">
      <LowLevelNodes nodes={element.children} stack={newStack} />
    </a>
  );
};

export const ExtLink: BitsElement = ({ element, siblings, stack }) => {
  const href = element.attributes['xlink:href'];
  const newStack = { element, siblings, stack };

  return (
    <a href={href}>
      <LowLevelNodes nodes={element.children} stack={newStack} />
    </a>
  );
};

const Label: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  if (stack.element.tagName === 'list-item' && !isSimpleList(stack)) return null;
  if (stackContainsTag(stack, 'toc'))
    return (
      <strong>
        <ElementText element={element} siblings={siblings} stack={stack} />
      </strong>
    );

  return (
    <span className={classes.label}>
      <ElementText element={element} siblings={siblings} stack={stack} />
    </span>
  );
};

export const Target: BitsElement = ({ element }) => {
  const classes = useStyles();
  const type = element.attributes['target-type'];
  if (type === 'pagebreak') {
    const id = element.attributes['id'] ?? '';
    const pageName = id.replace('pb', '');
    return (
      <a
        href={`#${id}`}
        id={`bits-${id}`}
        className={classes.targetPagebreak}
        title={`Side ${pageName} begynner`}
      >
        {pageName}
      </a>
    );
  }
  return null;
};

export const FootnoteSymbol: BitsElement = ({ element }) => {
  if (element.attributes['fn-type'] === 'marginalia') return null;
  const symbol = element.attributes['symbol'];
  return <>{symbol ?? 'fotnote uten symbol'}</>;
};
