import { spawn, call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './commentaryLinkingActions';

import URI from 'urijs';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import { BackendUrls } from '../../dataProvider/types';
import { getType, ActionType } from 'typesafe-actions';
import { showNotification } from 'ra-core';
import { LegalExpressionIdentifier } from '../legalDocuments/types';
import { convertActExprIdToKey, expressionIdentifierToUri } from '../../util/legalIdentifierUtils';

export const createCommentaryLinkingSagas = (urls: BackendUrls) => {
  const createBaseUrl = () => URI(urls.lovEditorBackend);

  function* commentaryLinkingSagas() {
    yield takeEvery(getType(actions.getCommentInjectStatuses), function* (
      action: ActionType<typeof actions.getCommentInjectStatuses>
    ) {
      yield call(commentaryFetchLinkingStatuses, action.payload.exprId);
    });

    yield takeEvery(getType(actions.injectComment), function* (
      action: ActionType<typeof actions.injectComment>
    ) {
      yield call(
        commentaryInjectLinks,
        action.payload.exprId,
        action.payload.fragmentId,
        action.payload.injectCommentBody,
        action.payload.injectCuratedSources,
        action.payload.currentLoggedUser
      );
    });

    yield takeEvery(getType(actions.getCommentInjectResult), function* (
      action: ActionType<typeof actions.getCommentInjectResult>
    ) {
      yield call(fetchInjectResults, action.payload.exprId, action.payload.fragmentId);
    });
  }

  // --- SAGA Handlers

  function* fetchInjectResults(exprId: LegalExpressionIdentifier, fragmentId: string) {
    const expressionUri = expressionIdentifierToUri(exprId);
    const url = createBaseUrl()
      .segment('api/admin/comment/linkinjector')
      .query({ expressionUri, fragmentId })
      .toString();

    try {
      const response = yield fetch(url, {
        method: 'GET',
        headers: juridikaHeaders(),
      }).then((response) => response.json());

      yield put(actions.getCommentInjectResultSuccess(exprId, fragmentId, response));
    } catch (error) {
      const exprIdKey = convertActExprIdToKey(exprId);
      const msg = `Failed while trying to fetch link inject results for comment with exprId: ${exprIdKey}`;
      yield put(showNotification(msg, 'error'));
      yield put(actions.getCommentInjectResultFailed(exprId, fragmentId, msg));
    }
  }

  function* commentaryInjectLinks(
    exprId: LegalExpressionIdentifier,
    fragmentId: string,
    injectCommentBody: boolean,
    injectCuratedSources: boolean,
    editor: string
  ) {
    const expressionUri = expressionIdentifierToUri(exprId);
    const url = createBaseUrl()
      .segment('api/admin/comment/linkinjector')
      .query({ expressionUri, fragmentId, injectCommentBody, injectCuratedSources, editor })
      .toString();

    try {
      yield fetch(url, {
        method: 'POST',
        headers: juridikaHeaders(),
      });
    } catch (error) {
      const msg = `Failed while trying inject links for comment with exprId: ${expressionUri},
       fragmentId: ${fragmentId}, includeCommentBody: ${injectCommentBody},
       injectCuratedSources: ${injectCuratedSources}`;
      yield put(showNotification(msg, 'error'));
    }
  }

  function* commentaryFetchLinkingStatuses(exprId: LegalExpressionIdentifier) {
    const expressionUri = expressionIdentifierToUri(exprId);
    const url = createBaseUrl()
      .segment('api/admin/comment/linkinjector/status')
      .query({ expressionUri })
      .toString();

    try {
      const response = yield fetch(url, {
        method: 'GET',
        headers: juridikaHeaders(),
      }).then((response) => response.json());

      yield put(actions.getCommentInjectStatusesSuccess(exprId, response));
    } catch (error) {
      const msg = `Failed while trying to fetch link inject statuses for comment with exprId: ${expressionUri}`;
      yield put(showNotification(msg, 'error'));
      yield put(actions.getCommentInjectStatusesFailed(exprId, msg));
    }
  }

  return function* commentaryInjectorWatcherSagas() {
    yield spawn(commentaryLinkingSagas);
  };
};
