import * as React from 'react';
import * as Redux from 'redux';
import Typography from '@material-ui/core/Typography';
import {
  SimpleFormIterator,
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  Labeled,
  required,
} from 'react-admin';
import { Query } from 'ra-core';

import { Contributor } from '../../dataProvider/contributorsProvider';
import { StaticDocumentContribution } from '../../dataProvider/staticDocumentRevisionsProvider';
import { hastToString, containsAnyText } from '../../util/hast';
import singleLineDocumentSpec, {
  DefaultHast as singleLineDefaultHast,
} from '../../slate/documentSpecs/singleLineSpec';

import SlateTextInput from '../SlateTextInput';

import contributionRoleChoices from '../../util/contributionRoleChoices';

const ContributionOccupationInput: React.FC<{
  resourceName: string;
  contributor: Contributor | null;
  source: string;
  dispatch: (action: Redux.AnyAction) => void;
  disabled: boolean;
}> = ({ resourceName, contributor, source, dispatch, disabled }) => {
  const hasOccupation = contributor ? containsAnyText(contributor.occupation) : false;

  return (
    <>
      <Labeled
        key={
          contributor
            ? `occupation-${contributor.id}`
            : 'occupation-loading' /* Re-render on reload */
        }
        label={`resources.${resourceName}.fields.revision.contributions.occupation`}
        fullWidth
      >
        <SlateTextInput
          disabled={disabled}
          source={source}
          documentSpec={singleLineDocumentSpec}
          slateDefaultValue={singleLineDefaultHast}
          singlelineStyle={true}
        />
      </Labeled>
      {contributor && (
        <Typography color="primary" style={{ marginBottom: '10px' }}>
          <code>{contributor.fullname}</code> sin nåværende stilling/yrke er{' '}
          {hasOccupation && (
            <>
              <code>{hastToString(contributor.occupation)}</code>. Hvis du ønsker at stilling/yrke
              skal være annerledes for denne spesifikke revisjonen kan du legge det inn her.
            </>
          )}
          {!hasOccupation && (
            <>ikke angitt. Vurdér å legge inn stilling/yrke på denne bidragsyteren.</>
          )}
        </Typography>
      )}
    </>
  );
};

export const ContributionsInput: React.FC<{
  resourceName: string;
  disabled: boolean;
  showOccupationField: boolean;
}> = ({ resourceName, disabled, showOccupationField, ...rest }) => {
  const { className, ...arrayProps } = rest as any;

  /* Should support "default value": https://github.com/marmelab/react-admin/issues/2489 */
  return (
    <ArrayInput
      {...arrayProps}
      source="revision.contributions"
      label={`resources.${resourceName}.fields.revision.contributions`}
      fullWidth={true}
      disabled={disabled}
    >
      <SimpleFormIterator>
        <ReferenceInput
          source="contributorId"
          reference="contributors"
          label={`resources.${resourceName}.fields.revision.contributions.contributorId`}
          fullWidth
          disabled={disabled}
          validate={required('Bidraget må være tilknyttet en bidragsyter')}
        >
          <AutocompleteInput
            optionValue="id"
            optionText="fullname"
            options={{
              fullWidth: true,
            }}
            disabled={disabled}
          />
        </ReferenceInput>
        <SelectInput
          source="role"
          choices={contributionRoleChoices}
          optionText="name"
          optionValue="role"
          label={`resources.${resourceName}.fields.revision.contributions.role`}
          disabled={disabled}
          validate={required('Bidragsyteren må ha en gyldig rolle')}
        />
        <FormDataConsumer>
          {(props: any) => {
            if (!showOccupationField) return null;

            const {
              scopedFormData,
              getSource,
              dispatch,
            }: {
              scopedFormData: Partial<StaticDocumentContribution>;
              getSource: (field: string) => string;
              dispatch: (action: Redux.AnyAction) => void;
            } = props;

            if (!scopedFormData) return null;

            const { contributorId } = scopedFormData;

            const source = getSource('occupation');

            return contributorId ? (
              <Query
                key={`query-contributor-${contributorId}` /* re-fetch on change id */}
                type="GET_ONE"
                resource="contributors"
                payload={{ id: contributorId || 'nope' }}
              >
                {({ data, loading, error }) => {
                  return (
                    <ContributionOccupationInput
                      resourceName={resourceName}
                      contributor={loading || error ? null : data}
                      source={source}
                      dispatch={dispatch}
                      disabled={disabled}
                    />
                  );
                }}
              </Query>
            ) : (
              <ContributionOccupationInput
                resourceName={resourceName}
                contributor={null}
                source={source}
                dispatch={dispatch}
                disabled={disabled}
              />
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};
