import React from 'react';
import { useTranslate } from 'react-admin';
import BEMHelper from 'react-bem-helper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { LenkeInjectorAction, CommentaryEditAction, LegalChangeActions } from './ExpressionActions';
import {
  GenerateAsCopyButton,
  EditExpressionButton,
  DeleteExpressionButton,
  CreateDraftButton,
  PublishDraftButton,
} from './VersionActions';

import {
  LegalWork,
  LegalExpression,
  LegalManifestation,
  LegalDocument,
} from '../../../state/legalDocuments/types';

import '../../../styles/legalDocumentList/expressionsTable.css';
import { ActionMenu } from './ActionMenu';

const classes = new BEMHelper({
  name: 'expressionsTable',
});

export interface Props {
  expressionsWithCommentariesLoading: boolean;
  expressionsWithCommentaries: string[] | null;
  currentUserName: string;
  basePath?: string;
  record?: LegalDocument;
  resource?: string;
}

export interface PropsWithExpressionAndVersion extends Props {
  work: LegalWork;
  expression: LegalExpression;
  manifestation: LegalManifestation;
}

const expressionTableHeadings = [
  'resources.legalDocuments.fields.expression.languageCode',
  'resources.legalDocuments.fields.expression.inForceAt',
  null,
  null,
  'resources.legalDocuments.fields.expression.versions',
];

const versionsTableHeadings = [
  'resources.legalDocuments.fields.version.status',
  'resources.legalDocuments.fields.version.shortTitle',
  null,
  null,
  null,
  null,
];

const TableHeading: React.FC<{ headings: (string | null)[] }> = ({ headings }) => {
  const translate = useTranslate();
  return (
    <TableHead>
      <TableRow>
        {headings.map((heading, index) => (
          <TableCell key={index}>{heading ? translate(heading) : heading}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const VersionsTable = (props: Props & { expression: LegalExpression }) => {
  const { expression, record: document } = props;
  const work = document?.work!;

  return (
    <Table>
      <TableHeading headings={versionsTableHeadings} />
      <TableBody>
        {expression.manifestations.map((manifestation: LegalManifestation) => {
          return (
            <TableRow key={`${expression.uri}-${manifestation.publicationStatus}`}>
              <TableCell>{manifestation.publicationStatus}</TableCell>
              <TableCell>{manifestation.shortTitle}</TableCell>
              <TableCell>
                <EditExpressionButton
                  {...props}
                  work={work}
                  expression={expression}
                  manifestation={manifestation}
                />
              </TableCell>
              <TableCell>
                <PublishDraftButton
                  {...props}
                  work={work}
                  expression={expression}
                  manifestation={manifestation}
                />
              </TableCell>
              <TableCell>
                <GenerateAsCopyButton
                  {...props}
                  work={work}
                  expression={expression}
                  manifestation={manifestation}
                />
              </TableCell>
              <TableCell>
                <CreateDraftButton
                  {...props}
                  work={work}
                  expression={expression}
                  manifestation={manifestation}
                />
                <DeleteExpressionButton
                  {...props}
                  work={work}
                  expression={expression}
                  manifestation={manifestation}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const ExpressionsTable: React.FC<Props> = (props) => {
  const { record: document } = props;

  if (!document) return null;
  if (document.isSummary) return null;

  let work = document.work!!;

  return (
    <Table {...classes()}>
      <TableHeading headings={expressionTableHeadings} />
      <TableBody>
        {work.expressions.map((expression) => {
          return (
            <TableRow key={expression.uri}>
              <TableCell>{expression.languageCode}</TableCell>
              <TableCell>{expression.inForceDate}</TableCell>
              <TableCell align="left">
                <CommentaryEditAction {...props} expression={expression} />
              </TableCell>
              <TableCell>
                <ActionMenu label="Annet">
                  <LenkeInjectorAction {...props} expression={expression} />
                  <LegalChangeActions {...props} expression={expression} />
                </ActionMenu>
              </TableCell>
              <TableCell colSpan={1}>
                <VersionsTable {...props} record={document} expression={expression} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ExpressionsTable;
