import * as React from 'react';
import * as Slate from 'slate';
import { useTranslate } from 'react-admin';
import * as tags from '../../slate/tags';

import '../../styles/recommendedPages.css';

export interface RecommendedPagesProps {
  node: Slate.Node;
  editor: Slate.Editor;
}

export interface RecommendedPagesValue {
  tag: string | null;
}

const RecommendedPages = React.forwardRef<any, RecommendedPagesProps>(
  ({ node, editor, children }, ref) => {
    const recommendedPagesValue: RecommendedPagesValue = {
      tag: String(node.tag),
    };

    let linkText = 'slate.recommendedArticlesPrefix.new';
    if (recommendedPagesValue.tag === tags.TAG_RECOMMENDED_PAGES_POPULAR) {
      linkText = 'slate.recommendedArticlesPrefix.popular';
    } else if (recommendedPagesValue.tag === tags.TAG_RECOMMENDED_PAGES_JURIDIKA_RECOMMENDS) {
      linkText = 'slate.recommendedArticlesPrefix.recommended';
    }

    const translate = useTranslate();
    linkText = translate(linkText) || '';

    const className = 'recommendedPages';
    return (
      <div ref={ref} className={className} contentEditable={false}>
        <ul className="recommendedPagesList">
          {[1, 2, 3, 4, 5].map((n) => {
            return (
              <li className="recommendedPagesListItem">
                <h3>
                  <span>
                    <div className="recommendedPagesLink">
                      {linkText} artikel nr {n}
                    </div>
                  </span>
                </h3>
              </li>
            );
          })}
        </ul>
        {children}
      </div>
    );
  }
);

export default RecommendedPages;
