import * as Types from '../types';
import {
  GenerateCopyParams,
  LegalChange,
  LegalChangesSuggestion,
  LegalDocument,
  LegalDocumentSummary,
  LegalPage,
  LegalWorkIdentifier,
  LegalWork,
  LegalRelations,
} from '../../state/legalDocuments/types';
import URI from 'urijs';
import { expressionIdentifierToUri, workIdentifierToUri } from '../../util/legalIdentifierUtils';
import { juridikaHeaders } from '../apiUtil';
import { BackendUrls } from '../types';
export class LovbackendService {
  constructor(private url: string) {}

  getDocumentPage(
    params: Types.ReactAdminApiParams
  ): Promise<{ data: LegalDocument[]; total: number }> {
    const { filter = {}, pagination: { page = 1, perPage = 10 } = {} } = params;

    const url: uri.URI = URI(this.url)
      .segment('api/admin/document')
      .query({ ...LovbackendService.pageToOffset(page, perPage), ...filter });

    return LovbackendService.fetchJson(url).then((json) => {
      return this.transformGetDocuments(json);
    });
  }

  getPublicDocumentPage(
    params: Types.ReactAdminApiParams
  ): Promise<{ data: LegalDocument[]; total: number }> {
    const { filter = {}, pagination: { page = 1, perPage = 1000 } = {} } = params;

    const url: uri.URI = URI(this.url)
      .segment('api')
      .segment('public')
      .segment('document')
      .query({ ...LovbackendService.pageToOffset(page, perPage), ...filter });

    return LovbackendService.fetchJson(url).then((json) => {
      return this.transformGetDocuments(json);
    });
  }

  async getDocumentFragments(
    params: Types.ReactAdminApiParams
  ): Promise<{ data: any; total: number }> {
    console.log(this.url);

    const url: uri.URI = URI(this.url)
      .segment('api')
      .segment('public')
      .segment('document')
      .segment(params.data.uri);

    return LovbackendService.fetchJson(url).then((json) => {
      return json;
    });
  }

  createDocument(
    params: Types.ReactAdminApiParams
  ): Promise<{ data: { id: string }; total: number }> {
    const { editor } = params.data;

    const expressionUri = expressionIdentifierToUri(params.data);

    const url: uri.URI = URI(this.url).segment('api/admin/cms/manifestation/init').query({
      editor,
      expressionUri,
    });

    return LovbackendService.fetch(url, 'POST').then(() => ({
      data: { id: expressionUri },
      total: 1,
    }));
  }

  getWorkPage(params: Types.ReactAdminApiParams): Promise<{ data: LegalWork[]; total: number }> {
    const { filter = {}, pagination: { page = 1, perPage = 10 } = {} } = params;

    if (filter.q) {
      filter.query = filter.q;
      delete filter.q;
    }

    const url: uri.URI = URI(this.url)
      .segment('api/admin/work')
      .query({ ...LovbackendService.pageToOffset(page, perPage), ...filter });

    return LovbackendService.fetchJson(url).then((json) => {
      return { data: json.data.map((w: LegalWork) => ({ id: w.uri, ...w })), total: json.total };
    });
  }

  getLegalDocument(workUri: string): Promise<{ data: LegalDocument; total: number }> {
    return this.getWork(workUri).then((w) => this.transformWorkToDocumentStructure(w.data));
  }

  getWork(workUri: string): Promise<{ data: LegalWork & { id: string } }> {
    const url: uri.URI = URI(this.url).segment('api/admin/work').segment(workUri);

    return LovbackendService.fetchJson(url).then((json: LegalWork) => {
      return { data: { id: json.uri, ...json } };
    });
  }

  createWork(params: Types.ReactAdminApiParams): Promise<{ data: any; total: number }> {
    const identifier = params.data as LegalWorkIdentifier;
    const uri = workIdentifierToUri(identifier);

    const { sourceSystem, listingTitle } = params.data;

    const url: uri.URI = URI(this.url)
      .segment('api/admin/work')
      .segment(uri)
      .search({ sourceSystem, listingTitle });

    return LovbackendService.fetch(url, 'POST').then((response: any) => {
      return {
        data: { id: uri, created: true },
        total: 1,
      };
    });
  }

  updateWork(params: Types.ReactAdminApiParams): Promise<{ data: any; total: number }> {
    const identifier = params.data as LegalWorkIdentifier;
    const uri = params.data.id;
    const newUri = workIdentifierToUri(identifier);
    const { sourceSystem, listingTitle, noLongerInForceDate } = params.data;
    const url: uri.URI = URI(this.url)
      .segment('api/admin/work')
      .segment(uri)
      .search({ newUri, sourceSystem, listingTitle, noLongerInForceDate });

    return LovbackendService.fetch(url, 'PUT').then((response: any) => {
      return {
        data: { id: newUri, created: true },
        total: 1,
      };
    });
  }

  getChanges(expressionUri: string): Promise<{ data: { id: string; changes: LegalChange[] } }> {
    const url: uri.URI = URI(this.url).segment('api/admin/changes').segment(expressionUri);

    return LovbackendService.fetchJson(url).then((json) => {
      return { data: { id: expressionUri, changes: json } };
    });
  }

  getChangesSuggest(
    expressionUri: string
  ): Promise<{ data: { id: string; suggestion: LegalChangesSuggestion } }> {
    const url: uri.URI = URI(this.url).segment('api/admin/changes/suggest').segment(expressionUri);

    return LovbackendService.fetchJson(url).then((json) => {
      return { data: { id: expressionUri, suggestion: json } };
    });
  }

  saveChanges(
    expressionUri: string,
    amendments: LegalChange[]
  ): Promise<{ data: { id: string; changes: LegalChange[] } }> {
    const url: uri.URI = URI(this.url).segment('api/admin/changes').segment(expressionUri);

    return LovbackendService.fetchJson(url, 'POST', JSON.stringify(amendments)).then((json) => {
      return { data: { id: expressionUri, changes: json } };
    });
  }

  getRelations(workUri: string): Promise<{ data: { id: string; relations: LegalRelations[] } }> {
    const url: uri.URI = URI(this.url).segment('api/admin/relations').query({ workUri });

    return LovbackendService.fetchJson(url).then((json) => {
      return { data: { id: workUri, relations: json } };
    });
  }

  generateAsCopy(params: Types.ReactAdminApiParams): Promise<{ data: any; total: number }> {
    let copyParams: GenerateCopyParams = params as GenerateCopyParams;

    const newExpressionUri = `${copyParams.id}/${copyParams.data.copyFromLanguageCode}@${copyParams.data.newInForceDate}`;
    const copiedExpressionUri = `${copyParams.id}/${copyParams.data.copyFromLanguageCode}@${copyParams.data.copyFromInForceDate}`;

    const url = URI(this.url).segment('api/admin/cms/manifestation/copy').search({
      editor: copyParams.data.editor,
      expressionUri: newExpressionUri,
      copiedExpressionUri,
    });

    return LovbackendService.fetch(url, 'POST').then(() => ({ data: {}, total: 0 }));
  }

  deleteDraft(params: Types.ReactAdminApiParams): Promise<{ data: any; total: number }> {
    const {
      id,
      previousData: { editor },
    } = params;

    let expressionUri = id!.startsWith('/') ? id : `/${id}`;

    const url = URI(this.url).segment('api/admin/cms/manifestation/delete').search({
      editor,
      expressionUri: expressionUri,
    });

    return LovbackendService.fetch(url, 'DELETE').then(() => ({
      data: params.previousData,
      total: 1,
    }));
  }

  getItemXml(expressionIdentifier: string, status?: string): Promise<string> {
    const url = URI(this.url)
      .segment('api/admin/work')
      .segment(expressionIdentifier)
      .segment('item')
      .search({ status });

    return LovbackendService.fetch(url).then((response: Response) => response.text());
  }

  private transformGetDocuments(
    json: LegalPage<LegalDocumentSummary>
  ): { data: LegalDocument[]; total: number } {
    let data: LegalDocument[] = (json.data as LegalDocumentSummary[]).map((doc) => ({
      id: doc.uri,
      isSummary: true,
      summary: doc,
    }));
    return { data, total: json.total };
  }

  private transformWorkToDocumentStructure(
    json: LegalWork
  ): { data: LegalDocument; total: number } {
    return { data: { id: json.uri, isSummary: false, work: json as LegalWork }, total: 1 };
  }

  static fetchJson(url: uri.URI, method: string = 'GET', body: any = null): Promise<any> {
    return LovbackendService.fetch(url, method, body, {
      'Content-Type': 'application/json',
    }).then(async (response) => response.json());
  }

  static fetch(
    url: uri.URI,
    method: string = 'GET',
    body: any = null,
    headers?: Record<string, string>
  ): Promise<Response> {
    return fetch(url.toString(), {
      method: method,
      ...LovbackendService.requestHeaders(headers),
      body,
    }).then(async (response) => {
      if (!response.ok) {
        const status = response.status || 0;
        const message = await LovbackendService.getErrorMessage(response);
        throw new LovbackendError(status, `${status} ${message}`);
      } else return response;
    });
  }

  static requestHeaders(opts = {}): { headers: Record<string, string> } {
    return {
      headers: {
        ...juridikaHeaders(),
        ...opts,
      },
    };
  }

  static async getErrorMessage(response: Response): Promise<string> {
    try {
      if (response.headers.get('Content-Type')?.toLowerCase().startsWith('application/json')) {
        return (await response.json()).message;
      } else {
        return await response.text();
      }
    } catch (e) {
      console.error(e);
      return 'Unknown error (error handling code failed)';
    }
  }

  static pageToOffset(page: number, perPage: number) {
    return { offset: (page - 1) * perPage, limit: perPage };
  }

  public static create(backends: BackendUrls): LovbackendService {
    return new LovbackendService(backends.lovEditorBackend);
  }
}
class LovbackendError extends Error {
  constructor(public status: number, public message: string) {
    super(message);
  }
}
