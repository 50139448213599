import { Edit, SaveButton, SimpleForm, Toolbar } from 'react-admin';
import React, { FC, useEffect, useState } from 'react';
import { FieldProps, useInput } from 'react-admin';
import RichTextEditor from './RichTextEditor';
import { rootSerializeToXML, xmlToSlate } from './utils';
import EditorSupportPanel from './EditorSupportPanel/EditorSupportPanel';
import { Descendant } from 'slate';
import { makeStyles } from '@material-ui/core/styles';

const NIKEdit: FC = (props) => {
  return (
    <Edit {...props}>
      <NIKDocumentForm />
    </Edit>
  );
};

export default NIKEdit;

const NIKDocumentForm: FC = (props) => {
  return (
    <SimpleForm variant="standard" margin="normal" {...props} toolbar={<NIKEditToolbar />}>
      <RichTextEditorAndEditorSupportPanelFields source="content" label="Post Content" />
    </SimpleForm>
  );
};

const NIKEditToolbar: React.FC<{}> = (props) => (
  <Toolbar {...props}>
    <SaveButton redirect={false} />
  </Toolbar>
);

interface RichTextEditorAndEditorSupportPanelFieldsProps extends FieldProps {
  source: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '800px',
    margin: '15px 15px 0',
    padding: '15px',
    alignItems: 'stretch',
  },
  containerItem: {
    width: '50%',
  },
  errorMessage: {
    color: 'red',
  },
});

const RichTextEditorAndEditorSupportPanelFields: FC<RichTextEditorAndEditorSupportPanelFieldsProps> = (
  props
) => {
  const {
    input: { value, onChange },
    meta: { touched, error },
  } = useInput(props);

  const [slateFormat, setSlateFormat] = useState<Descendant[]>([]);

  useEffect(() => {
    if (value !== '') setSlateFormat(xmlToSlate(value));
  }, [value]);

  const classes = useStyles();

  const handleEditorChange = (newValue: Descendant[]) => {
    setSlateFormat(newValue);
    const xmlformat = rootSerializeToXML(newValue);
    onChange(xmlformat);
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerItem}>
        {slateFormat.length > 0 && (
          <RichTextEditor value={slateFormat} onChange={handleEditorChange} />
        )}
        {touched && error && <p className={classes.errorMessage}>{error}</p>}
      </div>
      <div className={classes.containerItem}>
        <EditorSupportPanel slateFormat={slateFormat} />
      </div>
    </div>
  );
};
