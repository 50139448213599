import { getType, ActionType } from 'typesafe-actions';
import * as actions from './commentaryLinkingActions';
import { CommentaryLinkingStatus } from './types';
import { convertActExprIdToKey } from '../../util/legalIdentifierUtils';
import { LinkInjectorResults } from '../../models/LinkInjectorModels';

type CommentaryLinkingActions = ActionType<typeof actions>;

export interface CommentariesLinkingState {
  statuses: {
    [exprIdKey: string]: { fragments: CommentaryLinkingStatus } & { error: string | null };
  };
  injectResults: {
    [exprIdKeyFragment: string]: {
      results: LinkInjectorResults;
      error: string | null;
      loading: boolean;
    };
  };
}

const initialState: CommentariesLinkingState = {
  statuses: {},
  injectResults: {},
};

export default (
  state: CommentariesLinkingState = initialState,
  action: CommentaryLinkingActions
): CommentariesLinkingState => {
  switch (action.type) {
    case getType(actions.getCommentInjectStatuses): {
      const exprIdKey = convertActExprIdToKey(action.payload.exprId);
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [exprIdKey]: {
            // @ts-ignore
            fragments: {},
            ...state.statuses[exprIdKey],
            error: null,
          },
        },
      };
    }
    case getType(actions.getCommentInjectStatusesSuccess): {
      const exprIdKey = convertActExprIdToKey(action.payload.exprId);
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [exprIdKey]: {
            ...state.statuses[exprIdKey],
            fragments: action.payload.statuses,
          },
        },
      };
    }
    case getType(actions.getCommentInjectStatusesFailed): {
      const exprIdKey = convertActExprIdToKey(action.payload.exprId);
      return {
        ...state,
        statuses: {
          ...state.statuses,
          [exprIdKey]: {
            ...state.statuses[exprIdKey],
            error: action.payload.message,
          },
        },
      };
    }

    case getType(actions.getCommentInjectResult): {
      const exprFragmentId =
        convertActExprIdToKey(action.payload.exprId) + '@' + action.payload.fragmentId;
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [exprFragmentId]: {
            ...state.injectResults[exprFragmentId],
            loading: true,
            error: null,
          },
        },
      };
    }

    case getType(actions.getCommentInjectResultSuccess): {
      const exprFragmentId =
        convertActExprIdToKey(action.payload.exprId) + '@' + action.payload.fragmentId;
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [exprFragmentId]: {
            results: action.payload.results,
            loading: false,
            error: null,
          },
        },
      };
    }

    case getType(actions.getCommentInjectResultFailed): {
      const exprFragmentId =
        convertActExprIdToKey(action.payload.exprId) + '@' + action.payload.fragmentId;
      return {
        ...state,
        injectResults: {
          ...state.injectResults,
          [exprFragmentId]: {
            ...state.injectResults[exprFragmentId],
            loading: false,
            error: action.payload.message,
          },
        },
      };
    }

    default:
      return state;
  }
};
