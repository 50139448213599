import * as React from 'react';
import * as Slate from 'slate';
import lodashget from 'lodash/get';

import SlateEditor from './SlateInput/SlateEditor';
import { DocumentSpec } from '../slate/documentSpec';

// A slate editor intended to be used as a non-editable react-admin Field, outside form context.
export const SlateTextField: React.FC<{
  source: string;
  record?: any;
  documentSpec: DocumentSpec;
  defaultValue: any;
  singlelineStyle: boolean;
}> = ({ source, record, documentSpec, defaultValue, singlelineStyle }) => {
  if (!record) return null;
  const serializedValue = lodashget(record, source);

  let slateValue: Slate.Node[];

  try {
    slateValue = documentSpec.deserialize(serializedValue);
  } catch (error) {
    slateValue = documentSpec.deserialize(defaultValue);
  }

  return (
    <>
      <SlateEditor
        value={slateValue}
        documentSpec={documentSpec}
        readOnly={true}
        onChange={() => {}}
      />
      {false && <div style={{ background: '#000', height: '1px', marginTop: '6px' }} />}
    </>
  );
};
