import * as React from 'react';
import * as Slate from 'slate';

import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import { insertTableColumn } from './transforms';

const Icon: React.FC<{ disabled?: boolean }> = (props) => {
  return (
    <svg
      style={{ fill: props.disabled ? 'grey' : 'white' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M11 2a2 2 0 012 2v16a2 2 0 01-2 2H2V2h9m-7 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m11 7h3V8h2v3h3v2h-3v3h-2v-3h-3v-2z"></path>
    </svg>
  );
};

const IconDisabled: React.FC<{}> = (props) => {
  return <Icon disabled={true} />;
};

const InsertColumnButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  const isActive = !!getAboveByType(editor, tags.BLOCK_TABLE_ROW);

  return (
    <SlateToolbarButton
      isActive={isActive}
      Icon={isActive ? Icon : IconDisabled}
      message="slate.contextMenu.table.insertColumn"
      onMouseDown={() => insertTableColumn(editor)}
    />
  );
};

export default InsertColumnButton;
