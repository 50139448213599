import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface FormatPreviewPanelProps {
  label: string;
  format: string;
}

const FormatPreviewPanel: FC<FormatPreviewPanelProps> = ({ label, format }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3>{label}</h3>
      <div>
        <pre className={classes.preformattedText}>
          <code>{format}</code>
        </pre>
      </div>
    </div>
  );
};

export default FormatPreviewPanel;

const useStyles = makeStyles({
  root: {
    padding: '15px',
  },
  preformattedText: {
    fontSize: '10px',
    overflow: 'scroll',
    maxHeight: '580px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
});
