import React from 'react';
import URI from 'urijs';
import { connect } from 'react-redux';
import { linkToRecord, Authenticated } from 'react-admin';

import * as xopusApiHelpers from '../../xopus/xopusApiHelpers';
import { EditorWindow, EditorConfig } from '../../xopus/types';
import * as editorHelpers from '../../util/editorHelpers';
import * as expressionActions from '../../state/expressions/expressionActions';
import * as legalDocumentTypes from '../../state/legalDocuments/types';

import * as navigationHelpers from './navigationHelpers';
import { CurrentUser } from '../../state/currentUser/types';
import { LegalExpressionIdentifier } from '../../state/legalDocuments/types';

interface Query {
  publicationStatus: legalDocumentTypes.VersionStatus;
}

interface ReduxState {
  currentUser: CurrentUser;
  expressions: {
    xml: {
      [key: string]: string;
    };
  };
}

interface OwnProps {
  location: {
    pathname: string;
    search: string;
  };
  match: {
    params: LegalExpressionIdentifier;
  };
  history: {
    push: (path: string) => void;
  };
}

interface StateProps {
  currentUserName: string;
  exprId: string;
  workId: string;
  exprStatus: string;
  query: Query;
  expressionXML: string;
  location: any;
}

interface DispatchProps {
  publishExpression: typeof expressionActions.publish;
  saveExpression: typeof expressionActions.save;
  getExpressionXML: typeof expressionActions.getExpressionXML;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  xopusEditorIsConfigured: boolean;
}

class LegalDocumentEdit extends React.Component<Props, State> {
  editorConfig: EditorConfig;

  constructor(props: Props) {
    super(props);

    this.state = {
      xopusEditorIsConfigured: false,
    };

    this.editorConfig = {
      loadConfig: this.configureEditor,
      navigationHelpers,
      updateXopusCanvasIsLoaded: () => {},
    };

    (window as EditorWindow & typeof window).editorConfig = this.editorConfig;
  }

  componentDidMount() {
    const { exprId, query } = this.props;

    this.props.getExpressionXML(exprId, query);
  }

  saveExpression = (_: string, xmlDocument: XMLDocument) => {
    const { exprId, currentUserName } = this.props;

    this.props.saveExpression(exprId, {
      editor: currentUserName,
      xmlDocument,
      successCallback: () => xopusApiHelpers.loadDocument('', true),
    });
  };

  loadXMLFunction = () => xopusApiHelpers.createNativeXMLDocument(this.props.expressionXML);

  configureEditor = () => {
    const {
      workId,
      currentUserName,
      query: { publicationStatus },
    } = this.props;

    xopusApiHelpers.declareScopeVariable('isPublished', publicationStatus === 'PUBLISHED');
    xopusApiHelpers.setScopeListener('hasUnsavedChanges', editorHelpers.checkIfDocumentIsPublished);
    // Not sure why I added a listener to after redraw but I want to keep it
    // for now.
    // xopusApiHelpers.addEventListener("afterRedraw", navigationHelpers.selectNode);
    xopusApiHelpers.addCommand(
      'PublishDocumentCommand',
      editorHelpers.publishDocumentCommand(() =>
        this.props.publishExpression(this.props.exprId, {
          editor: currentUserName,
          successCallback: () => xopusApiHelpers.exitEditor,
        })
      )
    );
    xopusApiHelpers.setUserNameForChangeTracking(currentUserName);
    xopusApiHelpers.setSaveXMLFunction(this.saveExpression);
    // The exit url needs to be set for the close button to be displayed.
    // but will never be used since the setExitFunction is called first.
    xopusApiHelpers.setExitURL('/');
    xopusApiHelpers.setExitFunction(() =>
      this.props.history.push(linkToRecord('/legalDocuments', `/${workId}`, 'show'))
    );

    xopusApiHelpers.setLoadXMLFunction(this.loadXMLFunction);

    this.setState({
      xopusEditorIsConfigured: true,
    });
  };

  render() {
    const xopusCanvasUrl = URI('')
      .segment(process.env.PUBLIC_URL)
      .segment('xopus_5.4.4')
      .segment('akomantoso')
      .segment('start.html')
      .toString();
    return (
      <Authenticated location={this.props.location}>
        <iframe
          title="editor"
          style={{
            border: 'none',
            height: 'calc(100vh - 100px)',
            background: '#fff',
          }}
          src={xopusCanvasUrl}
          name="xopus"
        />
      </Authenticated>
    );
  }
}

const mapStateToProps = (state: ReduxState, ownProps: OwnProps) => {
  const { expressions, currentUser } = state;
  const {
    countryCode,
    documentType,
    subtype,
    date,
    seq,
    languageCode,
    inForceDate,
  } = ownProps.match.params;
  const query = URI.parseQuery(ownProps.location.search) as Query;
  const workId = ['akn', countryCode, documentType, subtype, date, seq].join('/');
  const exprId = `${workId}/${languageCode}@${inForceDate}`;

  return {
    // TODO: Get current user name.
    currentUserName: currentUser ? currentUser.name : '',
    workId,
    exprId,
    query,
    expressionXML: expressions.xml[[exprId, query.publicationStatus].join('@')],
    location: ownProps.location,
  };
};

const mapDispatchToProps = {
  publishExpression: expressionActions.publish,
  saveExpression: expressionActions.save,
  getExpressionXML: expressionActions.getExpressionXML,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalDocumentEdit);
