import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrayField,
  Datagrid,
  FunctionField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

const useStyles = makeStyles({
  showContent: {
    display: 'grid',
  },
  column1: {
    gridColumn: 1,
  },
  column2: {
    gridColumn: 2,
  },
  column12: {
    gridColumn: '1 / span 2',
  },
  column3: {
    gridColumn: 3,
  },
  column123: {
    gridColumn: '1 / span 3',
  },
});

export const JournalArticleExpandShow: React.FC<any> = (props) => {
  const classes = useStyles();
  return (
    <Show {...props} title=" ">
      <SimpleShowLayout className={classes.showContent}>
        <TextField source="id" className={classes.column1} />
        <ArrayField source="contributions" className={classes.column3}>
          <Datagrid>
            <ReferenceField source="contributorId" reference="contributors">
              <TextField source="fullname" />
            </ReferenceField>
            <TextField source="role" />
          </Datagrid>
        </ArrayField>
        <FunctionField
          source="contents"
          render={() => 'Visning (kommer!)'}
          className={classes.column1}
        />
      </SimpleShowLayout>
    </Show>
  );
};
