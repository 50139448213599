import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  ReferenceField,
  ChipField,
  SingleFieldList,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  Edit,
  SimpleForm,
  NumberInput,
  Pagination,
  EditButton,
  crudUpdateMany,
  BooleanInput,
  DateInput,
  ListButton,
  FormDataConsumer,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { RegistrationLink } from './components/RegistrationLink';
import { connect } from 'react-redux';
import CustomerBulkActions from './components/CustomerBulkActions';
import DownloadUsersButton from './components/DownloadUsersButton';
import { ProductsAutoSuggestQuery } from './components/ProductsAutoSuggestQuery';
import { Customer } from '../../dataProvider/customersProvider';
import { PackagesInput } from './components/CustomerForm';

const CustomerFilter = (props: any) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput label="resources.customers.actions.searchForCustomer" source="q" alwaysOn />
    <ReferenceInput
      label="resources.customers.actions.filterByStatus"
      source="customer.subscriptionStatus"
      reference="subscriptionStatus"
      alwaysOn
    >
      <SelectInput />
    </ReferenceInput>
    <ReferenceInput
      label="resources.customers.actions.filterByManager"
      source="customer.accountManager"
      reference="accountManagers"
      alwaysOn
      disabled={props.filterValues.emptyAccountManager ? true : false}
    >
      <SelectInput />
    </ReferenceInput>
    <ReferenceInput
      label="resources.customers.actions.filterByPackage"
      source="customer.subscriptionPackageId"
      reference="subscriptionPackage"
      alwaysOn
      disabled={props.filterValues.emptySubscriptionPackageId ? true : false}
    >
      <SelectInput />
    </ReferenceInput>
    <ReferenceInput
      label="resources.customers.actions.filterByCategory"
      source="customer.marketCategoryId"
      reference="marketCategory"
      disabled={props.filterValues.emptyMarketCategoryId ? true : false}
      alwaysOn
    >
      <SelectInput />
    </ReferenceInput>
    <BooleanInput
      source="emptySubscriptionPackageId"
      label="resources.customers.actions.filterEmptyPackage"
      defaultValue="true"
    />
    <BooleanInput
      source="emptyAccountManager"
      label="resources.customers.actions.filterEmptyManager"
      defaultValue="true"
    />
    <BooleanInput
      source="emptyMarketCategoryId"
      label="resources.customers.actions.filterEmptyCategory"
      defaultValue="true"
    />
    <DateInput
      source="scheduledDeletionBefore"
      label="resources.customers.actions.scheduledDeletionBefore"
    />
  </Filter>
);

const HelperText = () => (
  <div style={{ backgroundColor: 'orange', display: 'inline-block', padding: '5px' }}>
    <p>
      <strong> NB: Redigering av kundebildet resetter produktene</strong> <br />
      Husk å se om produktene er riktig. Pakken som er valgt vil automatisk legge til produkter i
      produkt-feltet og dette kommer til å overskrive <br />
      kundens tidligere registrerte produkter når du trykker <em>lagre</em>. Bruk produkt-feltet i
      tabellen til kunden for å se hvilke produkter kunden er <br />
      registrert på.
    </p>
  </div>
);

const CustomerExpandEditer = (props: any) => (
  <Edit undoable={false} title=" " {...props}>
    <SimpleForm variant="standard" margin="normal">
      <RegistrationLink />
      <ReferenceInput source="subscriptionStatus" reference="subscriptionStatus">
        <SelectInput source="subscriptionStatus.name" allowEmpty />
      </ReferenceInput>

      <ReferenceInput source="subscriptionPackageId" reference="subscriptionPackage" allowEmpty>
        <SelectInput
          source="subscriptionPackage.name"
          helperText="Pakkene styrer hvilke produkter som blir lagt til i feltet under."
        />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }: { formData: Customer }) => (
          <ProductsAutoSuggestQuery>
            {(autoSuggestData) => {
              const subscriptionPackageId = formData && formData.subscriptionPackageId;
              const subscriptionStatus = formData && formData.subscriptionStatus;

              return (
                <PackagesInput
                  subscriptionPackageId={subscriptionPackageId}
                  subscriptionStatusId={subscriptionStatus}
                  autoSuggestData={autoSuggestData}
                  {...rest}
                />
              );
            }}
          </ProductsAutoSuggestQuery>
        )}
      </FormDataConsumer>
      <ReferenceInput source="accountManager" reference="accountManagers" allowEmpty>
        <SelectInput source="accountManager" />
      </ReferenceInput>
      <ReferenceInput source="marketCategoryId" reference="marketCategory" allowEmpty>
        <SelectInput source="marketCategory.name" />
      </ReferenceInput>
      <NumberInput source="potentialUsers" />
      <ListButton
        component={Link}
        style={{ marginBottom: '0.5rem' }}
        to={{
          pathname: '/users',
          search: `filter=${JSON.stringify({ customerId: props.record.id })}`,
        }}
        label="Se brukere"
        variant="contained"
      />
      <DownloadUsersButton
        record={props.record}
        label="Last ned brukere(.xlsx)"
        variant="contained"
      />
      <HelperText />
    </SimpleForm>
  </Edit>
);

const CustomerPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[5, 10, 20, 50, 100, 1000]} {...props} />
);

const ConditionalDeletionInDaysField: React.FC<{ record?: any; source: string }> = ({ record }) => {
  const { deletionInDays, subscriptionStatus } = record;

  if (subscriptionStatus.includes('CUSTOMER') || subscriptionStatus.includes('TRIAL')) {
    if (deletionInDays === null) return <small>Viser når abonnementet har gått ut</small>;
    if (deletionInDays > 365) return <span>om over et år</span>;

    return (
      <span style={{ color: deletionInDays < 1 ? 'red' : undefined }}>
        {deletionInDays > 0 ? `om ${deletionInDays} dag(er)` : 'i morgen midnatt'}
      </span>
    );
  }

  return <small>Denne kunden slettes ikke automatisk (bare vanlige kunder slettes)</small>;
};

const CustomerList = (props: any) => {
  const { journalInjector, staticFiles, currentUser, crudUpdateMany, ...rest } = props;
  return (
    <List
      {...rest}
      filters={<CustomerFilter />}
      perPage={20}
      pagination={<CustomerPagination />}
      bulkActionButtons={<CustomerBulkActions {...props} />}
    >
      <Datagrid expand={<CustomerExpandEditer />}>
        <TextField source="customerNumber" />
        <TextField source="name" />
        <EditButton />
        <TextField source="numberOfUsers" />
        <TextField source="potentialUsers" />
        <ReferenceField source="subscriptionStatus" reference="subscriptionStatus">
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="marketCategoryId" reference="marketCategory">
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="accountManager" reference="accountManagers">
          <ChipField source="name" />
        </ReferenceField>
        <TextField source="subscriptionExpiration" />
        <ConditionalDeletionInDaysField source="deletionInDays" />
        <ReferenceField source="subscriptionPackageId" reference="subscriptionPackage">
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceArrayField source="products" reference="products">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};

const mapStateToProps = (state: object, props: object) => ({
  ...state,
  ...props,
});

export default connect(mapStateToProps, { crudUpdateMany })(CustomerList);
