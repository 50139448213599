import { spawn, call, put, all, takeEvery } from 'redux-saga/effects';
import { fetchStart, fetchEnd, refreshView, showNotification } from 'react-admin';
import URI from 'urijs';

import { juridikaHeaders } from '../../dataProvider/apiUtil';
import {
  InjectorActionTypes,
  InjectionStarted,
  FetchInjectedJournalStarted,
  fetchInjectedJournalSuccessAction,
  fetchInjectedJournalFailedAction,
  injectionFailedAction,
  injectionSuccessAction,
  FetchInjectResultsStarted,
  fetchInjectResultFailed,
  fetchInjectResultSuccess,
} from './injectorActions';
import { BackendUrls } from '../../dataProvider/types';

let BASE_URL: string;

export const createJournalInjectorWatcherSagas = (urls: BackendUrls) => {
  BASE_URL = URI(urls.juridikaLitteraturBackend)
    .segment('admin')
    .segment('v0')
    .segment('linkinject')
    .segment('journal')
    .toString();

  return function* journalInjectorWatcherSagas() {
    yield spawn(injectSaga);
    yield spawn(fetchInjectedDocumentSaga);
    yield spawn(fetchInjectResultsSaga);
  };
};

function* injectSaga() {
  yield takeEvery(InjectorActionTypes.INJECTOR_STARTED, function* (action: InjectionStarted) {
    yield call(injectDocument, action.ids);
  });
}

function* fetchInjectedDocumentSaga() {
  yield takeEvery(InjectorActionTypes.FETCH_INJECTED_JOURNAL_STARTED, function* (
    action: FetchInjectedJournalStarted
  ) {
    yield call(fetchInjectedDocument, action.id);
  });
}

function* fetchInjectResultsSaga() {
  yield takeEvery(InjectorActionTypes.FETCH_INJECT_RESULT_STARTED, function* (
    action: FetchInjectResultsStarted
  ) {
    yield call(fetchInjectResults, action.id);
  });
}

function* fetchInjectResults(id: string) {
  yield call(fetchStart);

  try {
    const matches = yield fetch(URI(BASE_URL).segment('results').segment(id).toString(), {
      method: 'GET',
      headers: juridikaHeaders(),
    }).then((response) => response.json());
    yield put(fetchInjectResultSuccess(id, matches));
  } catch (error) {
    console.error('Failed to fetch injector results', error);
    yield put(showNotification('Failed to fetch injector results', 'error'));
    yield put(fetchInjectResultFailed(id));
  }

  yield call(fetchEnd);
}

function* fetchInjectedDocument(id: string) {
  yield call(fetchStart);

  try {
    const injectedDocument = yield fetch(URI(BASE_URL).segment(id).toString(), {
      method: 'GET',
      headers: juridikaHeaders(),
    }).then((response) => response.json());

    yield put(fetchInjectedJournalSuccessAction(id, injectedDocument));
  } catch (error) {
    console.error('Failed to fetch injected document', error);
    yield put(showNotification('Failed to fetch injected document', 'error'));
    yield put(fetchInjectedJournalFailedAction(id));
  }

  yield call(fetchEnd);
}

function* injectDocument(ids: string[]) {
  yield call(fetchStart);

  // Runs all jobs (injection process) in parallell
  yield all(
    ids.map(function* (id: string) {
      try {
        yield call(fetch, URI(BASE_URL).segment(id).toString(), {
          method: 'PATCH',
          headers: juridikaHeaders(),
        });

        return yield put(injectionSuccessAction([id]));
      } catch (error) {
        console.error('Failed to injected document', error);
        yield put(showNotification(`Failed to inject document with id ${id}`, 'error'));
        return yield put(injectionFailedAction([id]));
      }
    })
  );

  yield put(refreshView());
  yield call(fetchEnd);
}
