import * as t from 'io-ts';
import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface Product {
  id: string;
  name: string;
  productNumber: string;
  enabled: boolean;
  roleId: string;
  type: string;
}
const ApiIncomingTypeV = t.type({
  id: t.string,
  name: t.string,
  productNumber: t.string,
  enabled: t.boolean,
  roleId: t.string,
  type: t.string,
});

type ApiType = t.TypeOf<typeof ApiIncomingTypeV>;

const toApiMapper = (product: Product): ApiType => ({
  id: product.id,
  name: product.name,
  productNumber: product.productNumber,
  enabled: product.enabled,
  roleId: product.roleId,
  type: product.type,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Product> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaSubscriptionBackend}/admin/v1/products`,
    halListName: 'productDtoList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiProduct: ApiType): Product => ({
      id: apiProduct.id,
      name: apiProduct.name,
      productNumber: apiProduct.productNumber,
      enabled: apiProduct.enabled,
      roleId: apiProduct.roleId,
      type: apiProduct.type,
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {},
    filterParams: {},
    sortFields: [],
  });
