import * as React from 'react';
import { useListContext, LinearProgress } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';

import { publishJournalArticles } from '../../../state/publishing/publishingActions';
import { dateTimeFromTemplate } from '../../../util/publishingUtils';

import { FormType, PublishContentDialogForm } from '../../PublishContentDialogForm';
import { CustomState } from '../../../state/customState';

const BulkPublishArticlesButton: React.FC = () => {
  const { selectedIds } = useListContext();
  const dispatch = useDispatch();
  const isSubmitting = useSelector<CustomState, boolean>((state) =>
    selectedIds.some((id) => state.publishing[id]?.status === 'uploading')
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const handleSubmit = ({ timeTemplate, customDate }: FormType) => {
    dispatch(
      publishJournalArticles.request({
        ids: selectedIds as Array<string>,
        juridikaPublishedAt: dateTimeFromTemplate(timeTemplate, customDate),
      })
    );
    setIsOpen(false);
  };

  return (
    <>
      {isSubmitting && <LinearProgress color="secondary" />}
      <Button variant="contained" onClick={() => setIsOpen(true)} startIcon={<PublishIcon />}>
        Publiser...
      </Button>
      <PublishContentDialogForm
        open={isOpen}
        dialogLabel="Publiser utgave"
        submitLabel="Publiser"
        onSubmit={handleSubmit}
        onClose={() => setIsOpen(false)}
        initialValues={(): FormType => ({
          timeTemplate: 'now',
          customDate: new Date(),
        })}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default BulkPublishArticlesButton;
