import * as React from 'react';
import {
  Dialog,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  withStyles,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { LinkInjectorResults } from '../../models/LinkInjectorModels';
import InjectResults from '../injector/InjectResults';

interface InjectorResultsDialogProps {
  title: string;
  onClose: () => void;
  classes?: any;
  injectorResults: LinkInjectorResults | null;
}

const styles = {
  exitBtn: {
    position: 'absolute' as 'absolute',
    right: 0,
    top: -10,
  },
};

const InjectorResultsDialog: React.FC<InjectorResultsDialogProps> = (props) => {
  const renderResults = () => {
    if (!props.injectorResults) return <CircularProgress />;
    return (
      <InjectResults
        hrefAttributeName="href"
        matches={props.injectorResults.matchResults.matches}
      />
    );
  };

  return (
    <Dialog open scroll="body" onClose={props.onClose} fullWidth={true}>
      <DialogTitle>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={props.onClose}
            aria-label="Close"
            className={props.classes.exitBtn}
          >
            <Close />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {props.title}
          </Typography>
        </Toolbar>
      </DialogTitle>
      <DialogContent>{renderResults()}</DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(InjectorResultsDialog);
