import * as React from 'react';
import * as Slate from 'slate';

import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import { deleteTableColumn } from './transforms';

const Icon: React.FC<{ disabled?: boolean }> = (props) => {
  return (
    <svg
      style={{ fill: props.disabled ? 'grey' : 'white' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M4 2h7a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2m0 8v4h7v-4H4m0 6v4h7v-4H4M4 4v4h7V4H4m13.59 8L15 9.41 16.41 8 19 10.59 21.59 8 23 9.41 20.41 12 23 14.59 21.59 16 19 13.41 16.41 16 15 14.59 17.59 12z"></path>
    </svg>
  );
};

const IconDisabled: React.FC<{}> = (props) => {
  return <Icon disabled={true} />;
};

const DeleteColumnButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  const isActive = !!getAboveByType(editor, tags.BLOCK_TABLE_ROW);

  return (
    <SlateToolbarButton
      isActive={isActive}
      Icon={isActive ? Icon : IconDisabled}
      message="slate.contextMenu.table.deleteColumn"
      onMouseDown={() => deleteTableColumn(editor)}
    />
  );
};

export default DeleteColumnButton;
