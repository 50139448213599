import React from 'react';
import { Customer } from '../../../dataProvider/customersProvider';
import { backendUrls } from '../../../dataProvider/apiUrls';
export const RegistrationLink: React.FC<{ record?: Customer }> = ({ record }) => {
  const signUpBaseUrl: string = `${backendUrls().juridikaFrontend}signup?code=`;

  return (
    <span>
      {record && record.signupCode
        ? `${signUpBaseUrl}${record.signupCode}`
        : `Obs! Ny/generert signup kode må lagres før bruk!`}
    </span>
  );
};
