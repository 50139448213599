import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  softHyphen: {
    '&:before': {
      display: 'inline-block',
      content: "'-'",
      border: '1px solid #86af49',
      backgroundColor: '#b5e7a0',
    },
  },
});

export const SlateFormattingCharacter: React.FC<{ character: string }> = ({
  character,
  children,
  ...props
}) => {
  const classes = useStyles();
  let outerClassName;
  switch (character) {
    case '\u00ad':
      outerClassName = classes.softHyphen;
      break;
  }

  return (
    <span className={outerClassName} {...props}>
      {children}
    </span>
  );
};
