import React, { FC } from 'react';
import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  rowCell: {
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
  headerCell: {
    backgroundColor: '#00968836',
  },
  mark: {
    backgroundColor: '#faf8dd',
  },
});

const NIKContributorDetailsList: FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      <h1>Liste over bidragsyterdetaljer</h1>
      Disse detaljene vil vises på Juridikas problemstillinger, hvor man kan knytte bidragsytere og deres detaljer til en problemstilling.

      <List {...props} perPage={25} bulkActionButtons={false}>
        <Datagrid
          classes={{
            headerCell: classes.headerCell,
            rowCell: classes.rowCell,
          }}
        >
          <TextField source="id" sortable={false} />
          <TextField source="title" sortable={false} />
          <ReferenceField
            source="contributorId"
            reference="contributors"
            sortable={false}
            label="Navn"
          >
            <TextField source="fullname" label="Navn" />
          </ReferenceField>
          <TextField source="shortBio" sortable={false} />
          <EditButton variant="contained" />
        </Datagrid>
      </List>
    </div>
  );
};

export default NIKContributorDetailsList;
