import React from 'react';
import { Redirect } from 'react-router-dom';

import auth from '../../auth';

//import { parseHash } from '../../state/context/contextActions';

export default class SessionInitializer extends React.Component {
  state = {
    loggingIn: true,
  };

  componentDidMount() {
    auth.handleAuthentication().then(() => this.setState({ loggingIn: false }));
  }

  render() {
    if (this.state.loggingIn) {
      return <div />;
    } else {
      return <Redirect to="/" />;
    }
  }
}
