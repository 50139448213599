import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: '#aaa',
  },
});

export const BreadcrumbTitle: React.FC<{
  resourceName: string;
  recordName: (record: Record<string | number, any>) => string | undefined;
  record?: Record<string | number, any>;
}> = ({ resourceName, recordName, record }) => {
  const classes = useStyles();
  return (
    <span>
      <span className={classes.root}>{resourceName} / </span>
      {record ? recordName(record) : ''}
    </span>
  );
};
