import React, { FC } from 'react';
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  Edit,
  ArrayInput,
  SimpleFormIterator,
  useGetOne,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const Contributor: FC = (record: any) => {
  const { data, loading, error } = useGetOne('contributors', record.record.contributorId);

  if (loading) return <span>Laster...</span>;
  if (error) return <span>Feil: {error.message}</span>;
  return (
    <span>
      {record.record.title} | {data!!.fullname} | {record.record.shortBio}
    </span>
  );
};

const useStyles = makeStyles({
  referenceInput: {
    width: '100%',
  },
});

const NIKEditionEdit: FC = (props) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <ReferenceInput
          source="publicationId"
          label="Tilknyttet Problemstilling"
          reference="nikPublications"
          allowEmpty={false}
          validate={[required()]}
          disabled
        >
          <AutocompleteInput
            optionValue="id"
            optionText="title"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
        <TextInput
          source="name"
          validate={[required()]}
          label="Utgavenavn"
          helperText="Navn for å differensiere utgaver i CMS. Vises ikke på Juridika"
        />
        <ArrayInput
          source="contributorRelation"
          label="Bidragsyter(e) *"
          helperText="En utgave må ha minst èn bidragsyter"
        >
          <SimpleFormIterator>
            <ReferenceInput
              source="contributorDetailId"
              reference="nikContributorDetails"
              className={classes.referenceInput}
            >
              <AutocompleteInput
                optionText={<Contributor />}
                inputText={(record: any) => `${record.title} | ${record.shortBio}`}
                matchSuggestion={(filterValue: any, suggestion: any) => true}
                validate={required('Bidraget må være tilknyttet en bidragsyter')}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default NIKEditionEdit;
