import * as t from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface Publisher {
  id: string;
  slug: string;
  name: string;
  memberOfForleggerforeningen: boolean;
}

export const createEmptyPublisher = (): Publisher => ({
  id: '',
  slug: '',
  name: '',
  memberOfForleggerforeningen: true,
});

export interface PartialPublisher {
  slug?: string;
  name?: string;
  memberOfForleggerforeningen?: boolean;
}

// Runtime type!!!
const ApiTypeV = t.type({
  id: t.string,
  slug: t.string,
  name: t.string,
  memberOfForleggerforeningen: t.boolean,
});
type ApiType = t.TypeOf<typeof ApiTypeV>;

interface ApiOutgoingType {
  slug: string;
  name: string;
  memberOfForleggerforeningen?: boolean;
}

export const toApiMapper = (publisher: Publisher): ApiOutgoingType => ({
  slug: publisher.slug,
  name: publisher.name,
  memberOfForleggerforeningen: publisher.memberOfForleggerforeningen,
});

export interface ApiPartialOutgoingType {
  slug?: string;
  name?: string;
  memberOfForleggerforeningen?: boolean;
}

// Mapper used when PATCH-ing (called UPDATE in react-admin)
export const toApiPartialMapper = (publisher: PartialPublisher): ApiPartialOutgoingType => ({
  ...(publisher.slug !== undefined && {
    slug: publisher.slug,
  }),
  ...(publisher.name !== undefined && {
    name: publisher.name,
  }),
  ...(publisher.memberOfForleggerforeningen !== undefined && {
    memberOfForleggerforeningen: publisher.memberOfForleggerforeningen,
  }),
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Publisher> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/publishers`,
    halListName: 'adminPublisherList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (apiPublisher: ApiType): Publisher => ({
      id: apiPublisher.id,
      slug: apiPublisher.slug,
      name: apiPublisher.name,
      memberOfForleggerforeningen: apiPublisher.memberOfForleggerforeningen,
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      featured: () => ({ featured: true }),
    },
    sortFields: [],
  });
