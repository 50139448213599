import React from 'react';
import ReactDOM from 'react-dom';

const CmsContextMenu: React.FC<{}> = ({ children }) => {
  const contextMenuDiv = React.useRef<HTMLElement | null>(null);

  if (!contextMenuDiv.current) {
    contextMenuDiv.current = document.getElementById('cms-context-menu');
  }

  return ReactDOM.createPortal(children, contextMenuDiv.current!);
};

export default CmsContextMenu;
