import React, { ReactElement, useState } from 'react';
import {
  FunctionField,
  Show,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useGetOne,
  useNotify,
  useShowContext,
} from 'react-admin';
import {
  formatLegalReference,
  getDescriptiveWorkTitle,
  uriToWorkIdentifier,
  workIdentifierToLovdataPath,
} from '../../../util/legalIdentifierUtils';
import {
  LEGAL_RELATIONS,
  LegalRelations,
  LegalWork,
  LegalWorkIdentifier,
} from '../../../state/legalDocuments/types';
import { Link } from 'react-router-dom';
import { encode } from 'urijs';
import '../../../styles/legalRelationsTable.css';
import BEMHelper from 'react-bem-helper';
import { getExternalLink, getLovdataProLink } from '../legalChanges/LegalChangeList';

const classes = new BEMHelper({
  name: 'legalRelationsTable',
});

const getLovtidendLink = (identifier: LegalWorkIdentifier): ReactElement => {
  return getExternalLink(
    `lovdata.no/dokument/LTI${workIdentifierToLovdataPath(identifier)}`,
    `https://lovdata.no/dokument/LTI${workIdentifierToLovdataPath(identifier)}`
  );
};

const getEuEliLink = (identifier: LegalWorkIdentifier): ReactElement => {
  if (identifier.subtype !== 'direktiv' && identifier.subtype !== 'forordning')
    throw Error('invalid subtype');
  const eliType = identifier.subtype === 'direktiv' ? 'dir' : 'reg';
  const eliYear = identifier.date.split('-')[0];
  const eliSequence = identifier.seq;
  const eli = `http://data.europa.eu/eli/${eliType}/${eliYear}/${eliSequence}/oj`;

  return getExternalLink(eli, eli);
};

export const LegalWorkShow: React.FC<ShowProps> = (props) => {
  return (
    <Show {...props} title={formatLegalReference(uriToWorkIdentifier(props.id!!))}>
      <LegalWorkShowLayout {...props} />
    </Show>
  );
};

const LegalWorkShowLayout: React.FC<ShowProps> = (props) => {
  const { record, loading } = useShowContext();
  const notify = useNotify();

  const [relations, setRelations] = useState<LegalRelations[]>([]);
  useGetOne('legalRelations', props.id!!, {
    onSuccess: (val) => {
      setRelations(val.data.relations);
    },
    onFailure: (err) => {
      notify(`Kunne ikke hente relasjoner:: ${err}`, 'error');
    },
  });

  if (loading) return null;
  const work: LegalWork = (record!! as unknown) as LegalWork;

  return (
    <SimpleShowLayout {...classes}>
      <h2>{getDescriptiveWorkTitle(work)}</h2>
      {work.expressions.length ? (
        <p>
          <Link to={`/legalDocuments/${encode(work.uri)}/show`}>Gå til tilhørende rettskilde</Link>
        </p>
      ) : (
        <p>(Ingen tilhørende rettskilde)</p>
      )}
      <FunctionField
        label="resources.legalDocuments.fields.work.identifier"
        render={() => formatLegalReference(work, false)}
      />
      <TextField source="uri" />
      <h3>ID-Metadata</h3>
      <TextField label="resources.legalDocuments.fields.work.documentType" source="documentType" />
      <TextField label="resources.legalDocuments.fields.work.countryCode" source="countryCode" />
      <TextField label="resources.legalDocuments.fields.work.subtype" source="subtype" />
      <TextField label="resources.legalDocuments.fields.work.date" source="date" />
      <TextField label="resources.legalDocuments.fields.work.seq" source="seq" />
      <h3>Andre felter</h3>
      <TextField label="resources.legalDocuments.fields.work.listingTitle" source="listingTitle" />
      <TextField
        label="resources.legalDocuments.fields.work.activeSourceSystem"
        source="activeSourceSystem"
      />
      <TextField
        label="resources.legalDocuments.fields.work.noLongerInForceDate"
        source="noLongerInForceDate"
      />
      {work.countryCode === 'no' && (work.subtype === 'lov' || work.subtype === 'forskrift') && (
        <div>
          <h3>Lenker</h3>
          <p>Lovtidend: {getLovtidendLink(work)}</p>
          <p>Lovdata pro: {getLovdataProLink(work)}</p>
        </div>
      )}
      {work.countryCode === 'eu' && (work.subtype === 'direktiv' || work.subtype === 'forordning') && (
        <div>
          <h3>Lenker</h3>
          <p>EUR-Lex: {getEuEliLink(work)}</p>
        </div>
      )}
      <h3>Relasjoner</h3>
      {relations.length === 0 ? (
        'Ingen.'
      ) : (
        <table className="legal_relations_table">
          <thead>
            <tr>
              <th>Dato</th>
              <th>Relasjon</th>
              <th>Objekt</th>
            </tr>
          </thead>
          <tbody>
            {relations
              .sort((a, b) => (b.appliedIn || '').localeCompare(a.appliedIn || ''))
              .map((r, i) => (
                <tr key={i}>
                  <td>{r.appliedIn || ''}</td>
                  <td>{LEGAL_RELATIONS.find((s) => s.id === r.predicate)?.name || r.predicate}</td>
                  <td>
                    <Link to={`/legalWorks/${encode(r.object.uri)}/show`}>
                      {formatLegalReference(r.object, false)}
                    </Link>
                    {r.object.shortTitle ? ` ${r.object.shortTitle}` : ''}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </SimpleShowLayout>
  );
};
