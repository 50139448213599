import React from 'react';
import { Show, ShowProps, SimpleShowLayout } from 'react-admin';

import DocumentForm, { Props } from './LiteratureConverstionBitsFormatForm';

const LiteratureConversionBitsFormatShow: React.FC<Props & ShowProps> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DocumentForm />
      </SimpleShowLayout>
    </Show>
  );
};

export default LiteratureConversionBitsFormatShow;
