import { getType, ActionType } from 'typesafe-actions';

import * as actions from './commentaryActions';
import { Commentary } from './types';
import { uriToWorkIdentifier } from '../../util/legalIdentifierUtils';

export interface CommentariesState {
  isLoading: boolean;
  commentary: Commentary;
}

const initialState = {
  isLoading: false,
  commentary: {},
};

type CommentaryActions = ActionType<typeof actions>;

export default (state = initialState, action: CommentaryActions) => {
  switch (action.type) {
    case getType(actions.getCommentary): {
      return {
        ...state,
        isLoading: true,
        commentary: {},
      };
    }
    case getType(actions.getCommentarySuccess): {
      const { workUri, inForceDate, fragmentId } = action.payload.data;
      const identifier = uriToWorkIdentifier(workUri);

      const id = [identifier.date, identifier.seq, inForceDate, fragmentId].join('-');

      return {
        ...state,
        isLoading: false,
        commentary: {
          ...action.payload.data,
          id,
        },
      };
    }
    case getType(actions.getCommentaryFailure): {
      return {
        ...state,
        isLoading: false,
        commentary: {},
      };
    }
    case getType(actions.resetCommentary): {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
