import * as io from 'io-ts';

import { DataFormat, NamedString } from './dataFormat';
import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

interface JournalPublicationWritableProps<Fmt extends DataFormat> {
  titles: Array<NamedString<'title', Fmt>>;
  categoryId: string;
  readPermission: string | null;
  piplingPatterns: Array<NamedString<'pattern', Fmt>>;
  idunnSlug: string | null;
}

export interface JournalPublication extends JournalPublicationWritableProps<'RECORD'> {
  id: string;
  slug: string;

  // Computed/helper fields
  fullTitle: string;
}

export type PartialJournalPublication = Partial<JournalPublicationWritableProps<'PROVIDER'>>;

// Runtime type!!!
const ApiTypeV = io.type({
  id: io.string,
  slug: io.string,
  titles: io.array(io.string),
  categoryId: io.string,
  readPermission: io.union([io.string, io.null]),
  piplingPatterns: io.array(io.string),
  idunnSlug: io.union([io.string, io.null]),
});
type ApiType = io.TypeOf<typeof ApiTypeV>;

interface ApiOutgoingType {}

export const toApiMapper = (publication: JournalPublication): ApiOutgoingType => ({});

export type ApiPartialOutgoingType = Partial<Omit<ApiType, 'id' | 'slug'>>;

export const toApiPartialMapper = (
  publication: PartialJournalPublication
): ApiPartialOutgoingType => ({
  titles: publication.titles?.map(({ title }) => title),
  categoryId: publication.categoryId,
  readPermission: publication.readPermission,
  piplingPatterns: publication.piplingPatterns?.map(({ pattern }) => pattern),
  idunnSlug: publication.idunnSlug,
});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<JournalPublication> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/journalPublications`,
    halListName: 'adminJournalPublicationList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (publication: ApiType): JournalPublication => ({
      id: publication.id,
      slug: publication.slug,
      titles: publication.titles.map((title) => ({ title })),
      categoryId: publication.categoryId,
      readPermission: publication.readPermission,
      piplingPatterns: publication.piplingPatterns.map((pattern) => ({ pattern })),
      idunnSlug: publication.idunnSlug,

      fullTitle: publication.titles.join(', '),
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
    },
    sortFields: [],
  });
