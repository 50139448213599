import * as TypesafeActions from 'typesafe-actions';

export const uploadStaticImage = TypesafeActions.createAsyncAction(
  '@@staticFiles/UPLOAD_IMAGE_REQUEST',
  '@@staticFiles/UPLOAD_IMAGE_SUCCESS',
  '@@staticFiles/UPLOAD_IMAGE_FAILURE'
)<
  {
    uuid: string;
    file: File;
  },
  {
    uuid: string;
    fileid: string;
    url: string;
  },
  {
    uuid: string;
    error: Error;
  }
>();
