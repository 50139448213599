import * as t from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { stringify } from 'query-string';

import * as Types from './types';

import auth from '../auth';

export const juridikaHeaders = (): Record<string, string> => ({
  Authorization: `Bearer ${auth.getAccessToken()}`,
});

export const juridikaHalJsonHeaders = (): Record<string, string> => ({
  ...juridikaHeaders(),
  Accept: 'application/hal+json',
});

export const requestHeaders = (opts = {}): { headers: Record<string, string> } => ({
  headers: {
    'Content-Type': 'application/json',
    ...juridikaHeaders(),
    ...opts,
  },
});

export const parseNullableDate = (apiDate: string | null): Date | null =>
  apiDate !== null ? new Date(apiDate) : null;

const decodeApiType = <ApiType>(data: any, type: t.Type<ApiType>): ApiType => {
  const either = type.decode(data);
  return either.fold(
    (errors) => {
      const messages = reporter(either);
      throw new Error(messages.join('\n'));
    },
    (value) => value
  );
};

export async function fetchHalJsonList<ApiType, AdminType>(
  url: string,
  params: Types.ReactAdminApiParams,
  listName: string,
  type: t.Type<ApiType>,
  itemMapper: (apiObj: ApiType) => AdminType,
  customQuery: { [key: string]: any }
): Promise<Types.ReactAdminResponse<AdminType>> {
  const query = {
    page: params.pagination ? params.pagination.page - 1 : 0,
    size: params.pagination ? params.pagination.perPage : 1000,
    ...customQuery,
  };
  const headers = juridikaHalJsonHeaders();

  const response = await fetch(`${url}?${stringify(query, { arrayFormat: 'comma' })}`, {
    headers,
    credentials: 'omit',
  });
  const json = await response.json();
  if (!json.page || typeof json.page.totalElements !== 'number') {
    throw new Error('Invalid HAL Json response, got ' + JSON.stringify(json));
  }
  if (json['_embedded']) {
    const jsonItems = json['_embedded'][listName];
    if (jsonItems === undefined) {
      throw new Error("Cannot find items '" + listName + "' in response");
    }
    const decodedItems: ApiType[] = jsonItems.map((jsonItem: any) => decodeApiType(jsonItem, type));
    const mappedItems = decodedItems.map(itemMapper);
    return {
      data: mappedItems,
      total: json.page.totalElements,
    };
  } else {
    return {
      data: [],
      total: 0,
    };
  }
}

type FilterConfigFunction = (filterValue: any) => { [key: string]: any };
interface FilterConfigObject {
  [key: string]: FilterConfigObject | FilterConfigFunction;
}

export interface HalJsonCrudApiConfig<
  ApiIncomingType,
  AdminType,
  ApiOutgoingType,
  ProviderType = AdminType,
  ProviderPartialType = AdminType,
  ApiPartialOutgoingType = ApiOutgoingType
> {
  baseUrl: string;
  halListName: string;
  incomingType: t.Type<ApiIncomingType>;
  toReactAdminMapper: (apiObj: ApiIncomingType) => AdminType;
  toApiMapper: (adminObj: ProviderType) => ApiOutgoingType;
  toApiPartialMapper: (
    adminObj: ProviderPartialType,
    previousAdminObj?: AdminType
  ) => ApiPartialOutgoingType;
  referenceParams: { [key: string]: string };
  filterParams: FilterConfigObject;
  sortFields: string[];
}

export const resolveFilter = (
  filterData: Types.FilterData,
  configObject: FilterConfigObject
): { [key: string]: any } => {
  return Object.keys(filterData).reduce((agg, filterKey) => {
    if (filterKey in configObject) {
      const filterValue = filterData[filterKey];
      const configValue = configObject[filterKey];
      if (typeof filterValue === 'object' && typeof configValue === 'object') {
        return {
          ...agg,
          ...resolveFilter(filterValue, configValue),
        };
      } else if (filterValue && typeof configValue === 'function') {
        return {
          ...agg,
          ...configValue(filterValue),
        };
      }
    }
    return agg;
  }, {});
};

export function halJsonCrudApiProvider<
  ApiIncomingType,
  AdminType,
  ApiOutgoingType,
  ProviderType = AdminType,
  ProviderPartialType = AdminType,
  ApiPartialOutgoingType = ApiOutgoingType
>(
  config: HalJsonCrudApiConfig<
    ApiIncomingType,
    AdminType,
    ApiOutgoingType,
    ProviderType,
    ProviderPartialType,
    ApiPartialOutgoingType
  >
): (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
) => Promise<Types.ReactAdminResponse<AdminType>> {
  const provider = async (
    type: Types.ReactAdminApiType,
    params: Types.ReactAdminApiParams
  ): Promise<Types.ReactAdminResponse<AdminType>> => {
    switch (type) {
      case 'GET_LIST':
        return fetchHalJsonList(
          config.baseUrl,
          params,
          config.halListName,
          config.incomingType,
          config.toReactAdminMapper,
          {
            ...(params.filter && resolveFilter(params.filter, config.filterParams)),
            ...(params.sort &&
              config.sortFields.indexOf(params.sort.field) >= 0 && {
                sort: [params.sort.field, params.sort.order === 'DESC' ? 'desc' : 'asc'],
              }),
          }
        );
      case 'GET_MANY': {
        const { ids } = params;
        return fetchHalJsonList(
          config.baseUrl,
          params,
          config.halListName,
          config.incomingType,
          config.toReactAdminMapper,
          { id: ids }
        );
      }
      case 'GET_ONE':
        return fetch(encodeURI(`${config.baseUrl}/${params.id}`), {
          headers: juridikaHalJsonHeaders(),
        })
          .then((response) => response.json())
          .then((item) => ({
            data: config.toReactAdminMapper(item),
          }));
      case 'GET_MANY_REFERENCE':
        return fetchHalJsonList(
          config.baseUrl,
          params,
          config.halListName,
          config.incomingType,
          config.toReactAdminMapper,
          {
            ...Object.keys(config.referenceParams).reduce((agg, target) => {
              if (params.target === target) {
                return {
                  ...agg,
                  [config.referenceParams[target]]: params.id,
                };
              } else {
                return agg;
              }
            }, {}),
            ...(params.filter && resolveFilter(params.filter, config.filterParams)),
          }
        );
      case 'CREATE': {
        const response = await fetch(config.baseUrl, {
          method: 'POST',
          headers: {
            ...juridikaHalJsonHeaders(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(config.toApiMapper(params.data)),
        });
        if (!response.ok) {
          throw new Error(`${await response.text()}`);
        }
        const decoded = decodeApiType(await response.json(), config.incomingType);
        return {
          data: config.toReactAdminMapper(decoded),
        };
      }
      case 'UPDATE': {
        const response = await fetch(encodeURI(`${config.baseUrl}/${params.id}`), {
          method: 'PATCH',
          headers: {
            ...juridikaHalJsonHeaders(),
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(config.toApiPartialMapper(params.data, params.previousData)),
        });
        if (!response.ok) {
          throw new Error(`${await response.text()}`);
        }
        const decoded = decodeApiType(await response.json(), config.incomingType);
        return {
          data: config.toReactAdminMapper(decoded),
        };
      }
      case 'UPDATE_MANY': {
        const ids = params.ids || [];
        const results = [];
        for (const id of ids) {
          results.push(
            await provider('UPDATE', {
              id,
              data: params.data[id],
            })
          );
        }
        return {
          data: results.map((response) => response.data as AdminType),
        };
      }
      case 'DELETE': {
        const response = await fetch(encodeURI(`${config.baseUrl}/${params.id}`), {
          method: 'DELETE',
          headers: juridikaHalJsonHeaders(),
        });
        const decoded = decodeApiType(await response.json(), config.incomingType);
        return {
          data: config.toReactAdminMapper(decoded),
        };
      }
      default:
        return Promise.reject(
          new Error(`HAL CRUD api for ${config.baseUrl}: Not implemented: ${type}`)
        );
    }
  };

  return provider;
}
