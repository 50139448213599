import { LegalExpressionIdentifier } from '../state/legalDocuments/types';

/**
 * Match results for injector
 */
export enum InjectOption {
  IS_UPDATE = 'IS_UPDATE',
  IS_NEW = 'IS_NEW',
  IS_EXISTING = 'IS_EXISTING',
}

export enum InjectStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface InjectedResult {
  alternativeRefIds: string[];
  contextString: string;
  incompleteRef: {
    ambiguous: boolean;
    description: string;
    missingParameters: boolean;
  };
  matchEndExclusive: number;
  matchStart: number;
  matchedString: string;
  oldHref: string;
  option: InjectOption;
  refId: string;
  referenceHref: string;
  uuid: string;
  xpath: string;
  linkAttributes: {
    [attribute: string]: string;
  };
}

export interface ExcludedResult {
  excludeCause: {
    description: string;
    excludedBy: string;
    '@type': string;
  };
  matchEndExclusive: number;
  matchStart: number;
  matchedString: string;
  oldHref: string | null;
  option: InjectOption;
  uuid: string;
  linkAttributes: {
    [attribute: string]: string;
  };
}

export interface Matches {
  [xPath: string]: {
    injected: InjectedResult[];
    excluded: ExcludedResult[];
  };
}

export interface LinkInjectorResults {
  exprId: LegalExpressionIdentifier;
  fragmentId: string;
  status: InjectStatus;
  queuedAt: string;
  startedAt: string;
  completedAt: string;
  matchResults: {
    matches: Matches;
    matchStats: {
      existingLinks: string[];
      successfullyUpdatedLinks: string[];
      successfullyInjectedNewLinks: string[];
      failedInjectingNewLinks: string[];
      failedUpdatingExistingLinks: string[];
      injectedAmbiguousLinks: string[];
      injectedIncompleteLinks: string[];
    };
  };
}

/**
 * Journal articles linking
 */
export type LinkedFileTypes = 'PROCESSED_UNAPPROVED' | 'PROCESSED_APPROVED';

export interface InjectedJournal {
  data: string;
  type: LinkedFileTypes;
}

export enum JournalLinkStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED_UNAPPROVED = 'PROCESSED_UNAPPROVED',
  PROCESSED_APPROVED = 'PROCESSED_APPROVED',
  ORIGINAL = 'ORIGINAL',
}

export interface DOCUMENT_ID {
  documentKey: string;
  editionPath: string[];
  publicationSlug: string;
}

export interface LinkedJournalStatus {
  documentKey: string;
  documentId: DOCUMENT_ID;
  valid: string;
  type: JournalLinkStatus;
}
