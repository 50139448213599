import { Feed } from '../dataProvider/feedsProvider';
import { ContentCategory } from '../dataProvider/contentCategoriesProvider';

import * as Keys from './staticContentFeedSemanticKeys';

const DEFAULT_FEEDS_FOR_CONTENT_CATEGORY: { [key: string]: string[] } = {
  litteratur: [Keys.FEED_FORSIDE_LITTERATUR],
  juridika: [Keys.FEED_FORSIDE_JURIDIKA],
  nyhet: [Keys.FEED_FORSIDE_INNSIKT, Keys.FEED_INNSIKT_HOVED],
  ekspertkommentar: [Keys.FEED_FORSIDE_INNSIKT, Keys.FEED_INNSIKT_HOVED, Keys.FEED_INNSIKT_EKSPERT],
  serie: [Keys.FEED_FORSIDE_INNSIKT, Keys.FEED_INNSIKT_HOVED],
};

export const defaultFeedIdsForContentCategory = (
  contentCategoryId: string | undefined,
  allFeeds: Array<Feed>,
  allContentCategories: Array<ContentCategory>
): string[] => {
  if (!contentCategoryId) return [];

  const contentCategory = allContentCategories.find(
    (category) => category.id === contentCategoryId
  );

  if (!contentCategory) return [];

  const feedKeys = DEFAULT_FEEDS_FOR_CONTENT_CATEGORY[contentCategory.semanticKey] || [];

  return allFeeds.filter((feed) => feedKeys.indexOf(feed.semanticKey) >= 0).map((feed) => feed.id);
};
