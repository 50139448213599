import * as React from 'react';
import { useMutation, useRefresh, useNotify, useListContext } from 'react-admin';
import { Button } from '@material-ui/core';

import { CmsResource } from '../../dataProvider/types';

import { FormType, TagDialogFormWrapper } from './TagDialogForm';

const ModifyTagsButton: React.FC<{
  tagResource: CmsResource;
  getTagPathsOnSelectedIds: () => Set<string>;
  tagPrefixes?: Array<string>;
}> = ({
  tagResource,
  getTagPathsOnSelectedIds,
  tagPrefixes = ['rettsområde', 'publiseringsstatus'],
}) => {
  const { selectedIds } = useListContext();

  const [isOpen, setIsOpen] = React.useState(false);

  const refresh = useRefresh();
  const notify = useNotify();

  const [mutate, { loading }] = useMutation();

  const handleSubmit = (formData: FormType) => {
    mutate(
      {
        type: 'UPDATE_MANY',
        resource: tagResource,
        payload: {
          ids: selectedIds,
          data: {
            add: formData.add,
            remove: formData.remove,
          },
        },
      },
      {
        onSuccess: () => {
          setIsOpen(false);
          refresh();
        },
        onFailure: (error: any) => {
          setIsOpen(false);
          notify(`Noe gikk galt i taggingen. Feilmelding: ${error.message}`, 'warning');
        },
      }
    );
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} variant="contained" color="primary">
        Rediger tags
      </Button>
      <TagDialogFormWrapper
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={(formData) => {
          handleSubmit(formData);
          setIsOpen(false);
        }}
        tagPrefixes={tagPrefixes}
        getTagPathsOnSelectedIds={getTagPathsOnSelectedIds}
        isSubmitting={loading}
      />
    </>
  );
};

export default ModifyTagsButton;
