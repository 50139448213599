import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  ChipField,
  Labeled,
  TopToolbar,
  ShowProps,
  useGetList,
  EditButton,
  BooleanField,
  FunctionField,
  Record,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';

import { TextbookEdition } from '../../dataProvider/textbookEditionsProvider';
import { LiteratureConversionProcess } from '../../dataProvider/literatureConversionProcessesProvider';
import { JuridikaCmsContext } from '../../util/juridikaCmsContext';

// General components
import DateField from '../DateField';
import DateTimeField from '../DateTimeField';
import { BreadcrumbTitle } from '../BreadcrumbTitle';

// "Borrows" from other component modules
import { EmbeddedSnapshotField } from '../embeddedSnapshots/EmbeddedSnapshotField';
import ImportBookButton from '../literatureConversionProcesses/ImportBookButton';
import TextbookModuleDatagrid from '../textbookModules/TextbookModuleDatagrid';
import TextbookModuleReferenceManyField from '../textbookModules/TextbookModuleReferenceManyField';

// Locals
import { PublishEditionButton } from './PublishEditionButton';
import UploadFileButton from '../UploadFileButton';
import { editionAvailabilityText } from './EditionAvailability';
import { UnPublishEditionButton } from './UnPublishButton';

const useStyles = makeStyles({
  showContent: {
    display: 'grid',
  },
  column1: {
    gridColumn: 1,
  },
  column2: {
    gridColumn: 2,
  },
  column12: {
    gridColumn: '1 / span 2',
  },
  column3: {
    gridColumn: 3,
  },
  column123: {
    gridColumn: '1 / span 3',
  },
});

const useConversionProcess = (editionId: string): LiteratureConversionProcess | null => {
  const { ids, data } = useGetList<LiteratureConversionProcess>(
    'literatureConversionProcesses',
    { page: 1, perPage: 1 },
    { field: '', order: 'ASC' },
    {
      editionId,
    }
  );

  if (!ids || !data) return null;
  if (ids.length === 0) return null;

  return data[ids[0]];
};

const ImportActionButtons: React.FC<{ data: TextbookEdition }> = ({ data }) => {
  const conversionProcess = useConversionProcess(data.id);

  return (
    <>
      {conversionProcess && conversionProcess.latestBitsFormatCreatedAt && (
        <ImportBookButton
          label="Importer BITS"
          processId={conversionProcess.id}
          endpoint="importLatestBitsFormat"
        />
      )}
      {conversionProcess && conversionProcess.outputPdfFormatCreatedAt && (
        <ImportBookButton
          label="Importer PDF"
          processId={conversionProcess.id}
          endpoint="importPdfFile"
        />
      )}
    </>
  );
};

const Actions: React.FC<{ basePath?: string; data?: TextbookEdition }> = ({ basePath, data }) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  return (
    <TopToolbar>
      {data && (
        <UploadFileButton
          label="Last opp omslag..."
          icon={<ImageIcon />}
          method="POST"
          url={`${backendUrls.juridikaLitteraturBackend}/admin/v0/textbookEditions/${data.id}/cover`}
        />
      )}
      {data && <ImportActionButtons data={data} />}
      {data && <PublishEditionButton id={data.id} />}
      {data && <UnPublishEditionButton id={data.id} />}
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
};

const TextbookEditionShow: React.FC<{ record?: TextbookEdition } & ShowProps> = (props) => {
  const classes = useStyles();
  return (
    <Show
      {...props}
      actions={<Actions />}
      title={
        <BreadcrumbTitle resourceName="utgave" recordName={(record) => record.descriptiveName} />
      }
    >
      <SimpleShowLayout className={classes.showContent}>
        <TextField source="fullTitle" className={classes.column12} />
        <TextField source="id" fullWidth className={classes.column3} />
        <TextField source="editionSequence" className={classes.column1} />
        <BooleanField source="hasCover" className={classes.column2} />
        <TextField source="isbn" className={classes.column3} />

        <Labeled
          label="resources.textbookEditions.fields.originallyPublishedAt"
          className={classes.column1}
        >
          <DateField source="originallyPublishedAt" />
        </Labeled>
        <Labeled
          label="resources.textbookEditions.fields.juridikaPublishedAt"
          className={classes.column2}
        >
          <DateTimeField source="juridikaPublishedAt" />
        </Labeled>
        <Labeled label="resources.textbookEditions.fields.availability" className={classes.column3}>
          <FunctionField
            source="availability"
            sortable={false}
            render={(record: Record | undefined) => {
              if (!record) return null;
              const edition = record as TextbookEdition;
              return editionAvailabilityText(edition.availability);
            }}
          />
        </Labeled>

        <ReferenceField
          source="publicationId"
          reference="textbookPublications"
          link="show"
          className={classes.column1}
        >
          <ChipField source="mainTitle" />
        </ReferenceField>

        <ReferenceField
          source="publisherId"
          reference="publishers"
          link="show"
          className={classes.column2}
        >
          <ChipField source="name" />
        </ReferenceField>

        <EmbeddedSnapshotField
          label="Omtale"
          addLabel
          foreignType="literature_edition_review"
          createTitle={(record) => {
            const { titles, name } = record as TextbookEdition;
            return Array.isArray(titles)
              ? `${titles.map(({ title }) => title).join(' ')}, ${name}`
              : '';
          }}
          className={classes.column3}
        />

        <Labeled label="Tilknyttet konvertering" className={classes.column1}>
          <ReferenceManyField
            addLabel={true}
            reference="literatureConversionProcesses"
            target="editionId"
          >
            <SingleFieldList linkType="show">
              <ChipField source="description" />
            </SingleFieldList>
          </ReferenceManyField>
        </Labeled>

        <TextbookModuleReferenceManyField
          label="Rot-modul"
          reference="textbookModules"
          target="editionId"
          perPage={1000}
          className={classes.column123}
        >
          <TextbookModuleDatagrid />
        </TextbookModuleReferenceManyField>

        <TextbookModuleReferenceManyField
          label="Undermoduler — nivå 1"
          reference="textbookModules"
          target="editionId"
          filter={{ parentModulePath: 'book' }}
          perPage={1000}
          className={classes.column123}
        >
          <TextbookModuleDatagrid />
        </TextbookModuleReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default TextbookEditionShow;
