import * as Types from './types';
import { juridikaHalJsonHeaders } from './apiUtil';

export interface APIUserMigrateRequestParams {
  oldExternalId: string; // Auth0 id migrating data FROM
  newExternalId: string; // Auth0 id migrating data TO
  removeOldJuridikaUserId?: boolean; // (default is `true`) if `false` two accounts will have the same juridika user id
  overWriteExistingMapping?: boolean; // (default is `false`) `overWriteExistingMapping` must be true  if the exisiting juridika user id mapped to the `newExternalId` has data mapped to it, otherwise a ForbiddenException will be thrown
}

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<{ id: string }> => {
  const provider = async (
    type: Types.ReactAdminApiType,
    params: Types.ReactAdminApiParams
  ): Promise<Types.ReactAdminResponse<{ id: string }>> => {
    const data = params.data as APIUserMigrateRequestParams;
    const query = new URLSearchParams({
      oldExternalId: data.oldExternalId,
      newExternalId: data.newExternalId,
      ...(data.removeOldJuridikaUserId !== undefined && {
        removeOldJuridikaUserId: data.removeOldJuridikaUserId.toString(),
      }),
      ...(data.overWriteExistingMapping !== undefined && {
        overWriteExistingMapping: data.overWriteExistingMapping.toString(),
      }),
    });
    const baseUrl = `${urls.juridikaSubscriptionBackend}/admin/v1/users/migrate?${query}`;
    switch (type) {
      case 'CREATE': {
        const response = await fetch(baseUrl, {
          method: 'POST',
          headers: {
            ...juridikaHalJsonHeaders(),
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`${await response.text()}`);
        }
        const updatedId = await response.json();
        return {
          data: {
            id: updatedId,
          },
        };
      }
      default:
        return Promise.reject(new Error(`HAL CRUD api for ${baseUrl}: Not implemented: ${type}`));
    }
  };

  return provider;
};
