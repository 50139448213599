import { NoticeSnapshot, createEmptyNoticeSnapshot } from './noticeSnapshotsProvider';
import {
  EmbeddedSnapshot,
  createEmptyEmbeddedSnapshot,
  EmbeddedForeignType,
} from './embeddedSnapshotsProvider';
import { PageSnapshot } from './pageSnapshotsProvider';
import { ContentCategory } from './contentCategoriesProvider';

export const createPrefilledNoticeSnapshotFromPageSnapshot = (
  pageSnapshot: PageSnapshot
): NoticeSnapshot => {
  const noticeSnapshot = createEmptyNoticeSnapshot();
  return {
    ...noticeSnapshot,
    linkedPage: pageSnapshot.id,
    revision: {
      ...noticeSnapshot.revision,
      contentCategoryId: pageSnapshot.revision.contentCategoryId,
    },
  };
};

export const createPrefilledEmbeddedOmtale = (
  foreignId: string,
  foreignType: EmbeddedForeignType,
  title: string,
  allContentCategories: ContentCategory[]
): EmbeddedSnapshot => {
  const embeddedSnapshot = createEmptyEmbeddedSnapshot();
  const contentCategory = allContentCategories.find(
    (category) => category.semanticKey === 'omtale'
  );
  if (!contentCategory) {
    throw new Error('No content category for "omtale"');
  }
  return {
    ...embeddedSnapshot,
    foreignId,
    foreignType,
    revision: {
      ...embeddedSnapshot.revision,
      contentCategoryId: contentCategory.id,
      title,
    },
  };
};
