import React from 'react';
import { DateField as ReactAdminDateField } from 'react-admin';

export const formatProps = {
  showTime: true,
  locales: 'nb-NO',
  options: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
};

const DateField: React.FC<{
  source: string;
  sortable?: boolean;
}> = (props) => <ReactAdminDateField {...props} {...formatProps} />;

export default DateField;
