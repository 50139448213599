import * as React from 'react';
import * as Slate from 'slate';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatStrikethrough from '@material-ui/icons/FormatStrikethrough';

import { Plugin } from './types';
import * as tags from '../tags';
import { isMarkActive } from '../utils/query';
import { toggleMark } from '../utils/transforms';
import SlateToolbarButton from '../../components/SlateInput/SlateToolbarButton';

interface FormatButtonProps {
  editor: Slate.Editor;
  mark: string;
  icon: React.ComponentType;
  message: string;
}

const FormatButton: React.FC<FormatButtonProps> = ({ editor, mark, icon, message }) => {
  return React.createElement(SlateToolbarButton, {
    isActive: isMarkActive(editor, mark),
    Icon: icon,
    message,
    onMouseDown: (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      toggleMark(editor, mark);
    },
  });
};

export default (): Plugin => {
  return {
    key: 'format',
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return [
          <FormatButton
            editor={editor}
            key="strong"
            mark={tags.MARK_BOLD}
            icon={FormatBold}
            message="slate.contextMenu.markFormats.bold"
          />,
          <FormatButton
            editor={editor}
            key="italic"
            mark={tags.MARK_ITALIC}
            icon={FormatItalic}
            message="slate.contextMenu.markFormats.italic"
          />,
          <FormatButton
            editor={editor}
            key="underlime"
            mark={tags.MARK_UNDERLINE}
            icon={FormatUnderlined}
            message="slate.contextMenu.markFormats.underline"
          />,
          <FormatButton
            editor={editor}
            key="strike-through"
            mark={tags.MARK_STRIKETHROUGH}
            icon={FormatStrikethrough}
            message="slate.contextMenu.markFormats.strikethrough"
          />,
        ];
      },
    },
  };
};
