import * as xopusApiHelpers from '../xopus/xopusApiHelpers';
import { Scope } from '../xopus/types';

export const getConfirmation = (dialogTxt: string, callback: (isConfirmed: boolean) => void) => {
  const isConfirmed = window.confirm(dialogTxt);

  callback(isConfirmed);
};

export const checkIfDocumentIsPublished = () => {
  const { getScopeByName, setScope } = xopusApiHelpers;
  const isPublished = getScopeByName('isPublished');
  const hasUnsavedChanges = getScopeByName('hasUnsavedChanges');

  if (isPublished && hasUnsavedChanges) {
    setScope('hasUnsavedChanges', false);
  }
};

export const publishDocumentCommand = (publish: () => void) => ({
  execute: () => {
    getConfirmation(
      `
        Når du publiserer vil dette dokumentet bli synlige på
        juridika.no. Er du sikker på at du vil publisere dokumentet?
      `,
      (isConfirmed: boolean) => isConfirmed && publish()
    );

    return;
  },
  getEnabled: (scope: Scope) => {
    const isPublished = scope.get('isPublished');
    const hasUnsavedChanges = scope.get('hasUnsavedChanges');

    return isPublished ? false : !hasUnsavedChanges;
  },
});
