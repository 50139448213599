import { findIdInListByName, getChoicesFromObject, ConfirmProps } from './customerBulkUtils';

export const setSubscriptionStatusConfirm = (props: ConfirmProps, status: string) => {
  const { admin, basePath, crudUpdateMany, resource, selectedIds } = props;
  const allCustomers = admin.resources.customers.data;
  const { subscriptionStatus } = props.admin.resources;
  const statusId = findIdInListByName(subscriptionStatus.data, status);

  const customers = Object.keys(allCustomers).reduce((customers, customerId) => {
    if (
      selectedIds.includes(customerId) &&
      allCustomers[customerId].subscriptionStatus !== statusId
    ) {
      return {
        ...customers,
        [customerId]: {
          ...allCustomers[customerId],
          subscriptionStatus: statusId,
        },
      };
    }
    return customers;
  }, {});

  crudUpdateMany(resource, Object.keys(customers), customers, basePath);
};

export const getSubscriptionStatusChoices = (props: ConfirmProps): string[] => {
  const { subscriptionStatus } = props.admin.resources;
  return getChoicesFromObject(subscriptionStatus.data);
};
