import { convertBodyToIndexEntriesOrDivs, scanPageMap } from './simpleBitsIndexHelper';
import {
  Model,
  matchElement,
  matchIndex,
  Path,
  ModelMutation,
  appendChildren,
  count,
  remove,
  createElement,
  INDEX,
  insertChildren,
  select,
  ELEMENT,
  TEXT,
  selectElements,
  ElementModel,
  insert,
} from './simpleXml';

/**
 * Simple commands for the BITS tag set
 */

const bookMatcher = matchElement('book');
const bookBackMatcher = matchElement('book-back');
const titleMatcher = matchElement('title');
const titleGroupMatcher = matchElement('title-group');
const indexTitleGroupMatcher = matchElement('index-title-group');
const bodyMatcher = matchElement('body');
const bookPartMetaMatcher = matchElement('book-part-meta');
const bookPartIdMatcher = matchElement('book-part-id');

export enum Direction {
  UP = -1 as number,
  DOWN = 1 as number,
}

const ensureBookBack = (model: Model) => {
  if (count(model, [bookBackMatcher]) === 0) {
    appendChildren(model, [bookMatcher], [createElement('book-back', {})]);
  }
};

export const moveToBackOfBookBack = (path: Path): ModelMutation => (model) => {
  ensureBookBack(model);
  for (const node of remove(model, path)) {
    appendChildren(model, [bookBackMatcher], [node]);
  }
};

export const moveInDirection = (path: Path, direction: Direction): ModelMutation => (model) => {
  const segment = path[path.length - 1];
  if (segment.type === INDEX) {
    for (const node of remove(model, path)) {
      switch (direction) {
        case Direction.DOWN:
        case Direction.UP:
          insertChildren(model, path, [node], segment.value + direction.valueOf());
          break;
      }
    }
  }
};

export const convertToIndexWithParsing = (path: Path): ModelMutation => (model) => {
  const bookPart: Model = select(model, path).next().value;
  // TODO, index may be other elements?
  if (bookPart.type !== ELEMENT || bookPart.tagName !== 'book-part') {
    console.error('Could not convert, must be book-part');
    return;
  }

  const oldId: string | undefined = bookPart.attributes.id;
  const newId = 'index_1';

  /* create index, insert titlestructure and title */
  const index: ElementModel = createElement('index', { id: newId });

  /* add title */
  appendChildren(index, [], [createElement('index-title-group', {})]);
  for (const title of remove(bookPart, [bookPartMetaMatcher, titleGroupMatcher, titleMatcher])) {
    appendChildren(index, [indexTitleGroupMatcher], [title]);
  }

  /* Add id */
  for (const bookPartId of remove(bookPart, [bookPartMetaMatcher, bookPartIdMatcher])) {
    if (bookPartId.type === ELEMENT) {
      const text = bookPartId.children[0].type === TEXT && bookPartId.children[0].value;
      if (text) {
        const objectIdNode = createElement('related-object', {
          'content-type': 'juridika-index',
          'object-id': text,
        });
        appendChildren(index, [], [objectIdNode]);
      }
    }
  }

  const bodyResult = remove(bookPart, [bodyMatcher]).next();
  if (bodyResult.done) {
    return;
  }

  const pageMap = scanPageMap(model);
  const indexEntries = convertBodyToIndexEntriesOrDivs(bodyResult.value as ElementModel, pageMap);
  appendChildren(index, [], indexEntries);

  // Remove original book-part:
  remove(model, path).next();

  // Insert index in an appropriate place:
  // it must be rooted in `book-back`, it is not
  // allowed to live inside another book-part:
  for (let level = 0; level < path.length; level += 1) {
    const parentPath = path.slice(0, level + 1);
    const elements = Array.from(selectElements(model, parentPath));
    if (elements.length === 0) {
      break;
    }

    const element = elements[0];

    if (element.tagName === 'book-back') {
      // We want to insert <index> as a child of 'book-back'
      const indexPath = parentPath.concat([matchIndex(element.children.length)]);

      insert(model, indexPath, [index]);
      if (oldId) {
        rewriteId(model, oldId, newId);
      }
      return;
    }
  }

  throw new Error('Did not find a place to inject index');
};

/// Rewrite all 'rid' pointers from `oldId` to `newId`
export const rewriteId = (model: Model, oldId: string, newId: string) => {
  const recurse = (model: Model) => {
    if (model.type === TEXT) return;

    if (model.attributes.rid === oldId) {
      model.attributes.rid = newId;
    }

    for (const child of model.children) {
      recurse(child);
    }
  };

  recurse(model);
};

/* Convert <book-part> to <ref-list>, conserves items */
export const convertToRefList = (path: Path) => (model: Model) => {
  const bookPart: Model = select(model, path).next().value;

  /* Only support converting bookparts */
  if (bookPart.type !== ELEMENT || bookPart.tagName !== 'book-part') {
    console.error('Could not convert, must be book-part');
    return;
  }

  /* create ref-list, insert object-id */
  const refListElement = createElement('ref-list', { id: bookPart.attributes.id });

  for (const bookPartId of remove(bookPart, [bookPartMetaMatcher, bookPartIdMatcher])) {
    if (bookPartId.type === ELEMENT) {
      const objectIdNode = createElement('object-id', { 'pub-id-type': 'juridika-uuid' });
      appendChildren(objectIdNode, [], bookPartId.children);
      appendChildren(refListElement, [], [objectIdNode]);
    }
  }

  /* create index-title-group, add title */
  for (const title of remove(bookPart, [bookPartMetaMatcher, titleGroupMatcher, titleMatcher])) {
    appendChildren(refListElement, [], [title]);
  }

  /* insert content unchanged into index */
  for (const body of selectElements(bookPart, [bodyMatcher])) {
    body.children.forEach((element) => appendChildren(refListElement, [], [element]));
  }

  remove(model, path).next();
  const location = path[path.length - 1];
  if (location.type === INDEX) {
    insertChildren(model, path, [refListElement], location.value - 1);
  }
};

export const moveEntryIntoPreviousEntry = (path: Path) => (model: Model) => {
  const indexEntry: Model = select(model, path).next().value;
  if (indexEntry.type !== ELEMENT || indexEntry.tagName !== 'index-entry') {
    console.error(
      'Could not convert, must be index-entry. Element: ',
      indexEntry.type === ELEMENT && indexEntry.tagName
    );
    return;
  }

  const segment = path[path.length - 1];
  if (segment.type === INDEX) {
    /* find last index-entry, then append path */
    for (let i = 1; segment.value - i >= 0; i++) {
      const newPath = path.slice(0, path.length - 1);
      newPath.push({ type: INDEX, value: segment.value - i });
      for (const node of select(model, newPath)) {
        if (node.type === ELEMENT && node.tagName === 'index-entry') {
          for (const node of remove(model, path)) {
            appendChildren(model, newPath, [node]);
            return;
          }
        }
      }
    }
  }
};
