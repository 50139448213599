import { findIdInListByName, getChoicesFromObject, ConfirmProps } from './customerBulkUtils';

export const addProductConfirm = (props: ConfirmProps, name: string) => {
  const { admin, basePath, crudUpdateMany, resource, selectedIds } = props;
  const allCustomers = admin.resources.customers.data;
  const { products } = admin.resources;
  const productId: string = findIdInListByName(products.data, name);

  const customers = Object.keys(allCustomers).reduce((customers, customerId) => {
    const customer = allCustomers[customerId];
    if (selectedIds.includes(customerId) && !customer.products.includes(productId)) {
      return {
        ...customers,
        [customerId]: {
          ...customer,
          products: [...customer.products, productId],
        },
      };
    }
    return customers;
  }, {});

  crudUpdateMany(resource, Object.keys(customers), customers, basePath);
};

export const getProductChoices = (props: ConfirmProps): string[] => {
  const { products } = props.admin.resources;
  return getChoicesFromObject(products.data);
};

export const removeProductConfirm = (props: ConfirmProps, name: string) => {
  const { admin, basePath, crudUpdateMany, resource, selectedIds } = props;
  const allCustomers = admin.resources.customers.data;
  const { products } = admin.resources;
  const productId = findIdInListByName(products.data, name);

  const customers = Object.keys(allCustomers).reduce((customers, customerId) => {
    const customer = allCustomers[customerId];
    if (selectedIds.includes(customerId) && customer.products.includes(productId)) {
      const products = customer.products.filter((product: string | null) => product !== productId);
      return {
        ...customers,
        [customerId]: {
          ...customer,
          products: [...products],
        },
      };
    }
    return customers;
  }, {});

  crudUpdateMany(resource, Object.keys(customers), customers, basePath);
};
