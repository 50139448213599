import { getType, ActionType } from 'typesafe-actions';

import * as expressionActions from './expressionActions';
import * as commentaryActions from '../commentary/commentaryActions';

import { Toc, CommentaryMetadataForAllExpressionFragments, TocItem } from './types';

type Actions = ActionType<typeof expressionActions | typeof commentaryActions>;

export interface ExpressionsWithCommentaries {
  [workId: string]: string[] | null;
}

export interface ExpressionState {
  expressionsWithCommentariesLoading: boolean;
  tocs: { [key: string]: Toc };
  expressionsWithCommentaries: ExpressionsWithCommentaries;
  commentaryMeta: {
    [key: string]: CommentaryMetadataForAllExpressionFragments;
  };
  xml: {};
}

const initialState = {
  expressionsWithCommentariesLoading: false,
  tocs: {},
  expressionsWithCommentaries: {},
  commentaryMeta: {},
  xml: {},
};

export default (state = initialState, action: Actions) => {
  switch (action.type) {
    case getType(commentaryActions.getListOfAllExpressionsWithComment): {
      return {
        ...state,
        expressionsWithCommentariesLoading: true,
      };
    }
    case getType(expressionActions.generateExpressionTocSuccess): {
      // How to avoid 'action as { payload: { id: string; toc: TocItem[] }}' ?
      const {
        payload: { id, toc },
      } = action as { payload: { id: string; toc: TocItem[] } };

      return {
        ...state,
        tocs: {
          ...state.tocs,
          [id]: toc,
        },
      };
    }
    case getType(expressionActions.getExpressionXMLSuccess):
    case getType(expressionActions.saveSuccess): {
      const {
        payload: { expression },
      } = action as { payload: { expression: { id: string; xml: string } } };

      return {
        ...state,
        xml: {
          [expression.id]: expression.xml,
        },
      };
    }
    case getType(commentaryActions.getListOfAllExpressionsWithCommentSuccess): {
      const {
        data: { expressionsWithComments, id },
      } = action.payload as { data: { expressionsWithComments: string[]; id: string } };
      const { expressionsWithCommentaries } = state;

      return {
        ...state,
        expressionsWithCommentariesLoading: false,
        expressionsWithCommentaries: {
          ...expressionsWithCommentaries,
          [id]: expressionsWithComments.map((expr: any) => expr),
        },
      };
    }
    case getType(commentaryActions.getCommentaryMetaSuccess): {
      const {
        commentaryMeta: { expressionUri, fragments },
      } = action.payload as {
        commentaryMeta: {
          expressionUri: string;
          fragments: CommentaryMetadataForAllExpressionFragments;
        };
      };

      return {
        ...state,
        commentaryMeta: {
          ...state.commentaryMeta,
          [expressionUri]: { ...fragments },
        },
      };
    }
    default: {
      return state;
    }
  }
};
