import * as t from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface JournalEdition {
  id: string;
  publicationId: string;
  publicationSlug: string;
  path: Array<string>;
  titles: Array<{ title: string }>;
  originallyPublishedAt: Date;
  juridikaPublishedAt: Date | null;

  // Computed/helper fields
  fullTitle: string;
  shortDescription: string;
  editionYear: number;
  editionSequence: number;
}

export interface PartialJournalPublication {}

// Runtime type!!!
const ApiTypeV = t.type({
  id: t.string,
  publicationId: t.string,
  publicationSlug: t.string,
  path: t.array(t.string),
  originallyPublishedAt: t.string,
  juridikaPublishedAt: t.union([t.string, t.null]),
  titles: t.array(t.string),
});
type ApiType = t.TypeOf<typeof ApiTypeV>;

interface ApiOutgoingType {}

export const toApiMapper = (publication: JournalEdition): ApiOutgoingType => ({});

export interface ApiPartialOutgoingType {}

const makeShortTitle = (titles: Array<string>): string => {
  const joined = titles.join(' ');
  if (joined.length > 32) {
    return `${joined.slice(0, 32)}…`;
  }

  return joined;
};

export const toApiPartialMapper = (
  publication: PartialJournalPublication
): ApiPartialOutgoingType => ({});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<JournalEdition> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/journalEditions`,
    halListName: 'adminJournalEditionList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (edition: ApiType): JournalEdition => ({
      id: edition.id,
      path: edition.path,
      publicationId: edition.publicationId,
      publicationSlug: edition.publicationSlug,
      titles: edition.titles.map((title) => ({ title })),
      originallyPublishedAt: new Date(edition.originallyPublishedAt),
      juridikaPublishedAt: edition.juridikaPublishedAt
        ? new Date(edition.juridikaPublishedAt)
        : null,
      fullTitle: edition.titles.join(', '),
      shortDescription: `${makeShortTitle(edition.titles)} — ${edition.path.join('/')}`,
      editionYear: parseInt(edition.path[0], 10),
      editionSequence: parseInt(edition.path[1], 10),
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      publicationId: 'publicationId',
    },
    filterParams: {
      publicationId: (publicationId) => ({ publicationId }),
      q: (q) => ({ q }),
    },
    sortFields: [],
  });
