import React, { FC, ReactNode } from 'react';
import styles from './ResourceStickerButton.module.css';
import { CustomElement } from '../../../@types/new-slate';

const ResourceStickerButtonArrowIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path
      d="M0.0820312 1H9.99939M9.99939 1V11M9.99939 1L1.07377 9.92562"
      stroke="currentcolor"
      strokeWidth="2"
    />
  </svg>
);

const ResourceStickerButton: FC<{ attributes: CustomElement; children: ReactNode }> = ({
  attributes,
  children,
}) => {
  return (
    <button className={styles.button} {...attributes}>
      {children}
      <ResourceStickerButtonArrowIcon />
    </button>
  );
};

export default ResourceStickerButton;
