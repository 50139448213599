import React, { FC } from 'react';
import { Datagrid, EditButton, List, TextField } from 'react-admin';
import NIKPublicationsShow from './NIKPublicationShow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: '#00968836',
  },
  headerContentWrapper: {
    display: 'flex',
  },
  mark: {
    backgroundColor: '#faf8dd',
  },
});

const NIKPublicationList: FC = (props) => {
  const classes = useStyles();
  return (
    <div>
      <h1>Liste over problemstillinger</h1>

      <div className={classes.headerContentWrapper}>
        <div>
          <p>
            Denne siden viser en liste over alle opprettede problemstillinger. Brukere har
            muligheten til å opprette nye problemstillinger direkte fra denne siden. Du får utvidet
            informasjon om problemstillingen ved å trykke på pila til venstre for navnet. Her gir
            hver oppførte problemstilling også en oversikt over alle tilhørende utgaver. Ved å
            klikke på en bestemt utgave, kan brukerne navigere til den spesifikke utgaven for å
            utforske eller interagere med de dokumentene som er inkludert i den.
          </p>
          <p>
            Modellen for problemstillinger:{' '}
            <mark className={classes.mark}>
              <strong>Problemstilling</strong> &rarr; utgave &rarr; dokument
            </mark>
          </p>
        </div>
      </div>
      <List {...props} perPage={25} bulkActionButtons={false}>
        <Datagrid expand={<NIKPublicationsShow />} classes={{ headerCell: classes.headerCell }}>
          <TextField source="title" sortable={false} />
          <TextField source="description" sortable={false} emptyText="Ingen beskrivelse" />
          <EditButton variant="contained" />
        </Datagrid>
      </List>
    </div>
  );
};

export default NIKPublicationList;
