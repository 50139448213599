import h from 'hastscript';
import { DOMParser as XMLDOMParser } from 'xmldom';
import {
  domNodeToHast,
  HastNode,
  HastElementNode,
  HastProperties,
  hastToHtml5,
  html5SerializationConfig,
} from '@universitetsforlaget/hast';

type contentType = 'text/html' | 'application/xml';

export const parseMarkup = (html: string, type: contentType): Document => {
  const parsed = new XMLDOMParser().parseFromString(html, type);
  return parsed;
};

const hastPropertyOfAttr = (attrib: Attr): HastProperties => {
  switch (attrib.name) {
    case 'class':
      return {
        className: (attrib.nodeValue || '').split(' ').filter(Boolean),
      };
    default:
      return {
        [attrib.name]: attrib.nodeValue,
      };
  }
};

const hastOfElement = (element: Element): HastNode => {
  if (element.nodeType === element.TEXT_NODE) {
    return {
      type: 'text',
      value: element.nodeValue!,
    };
  } else {
    const hastChildren = hastChildrenOf(element);
    let hastProperties = {};
    for (let i = 0; i < element.attributes.length; i += 1) {
      const hastProperty = hastPropertyOfAttr(element.attributes[i]);
      hastProperties = {
        ...hastProperties,
        ...hastProperty,
      };
    }
    return h.apply(null, [element.tagName.toLowerCase(), hastProperties].concat(hastChildren));
  }
};

const hastChildrenOf = (element: Element): HastNode[] => {
  const hastNodes: HastNode[] = [];
  for (let i = 0; i < element.childNodes.length; i += 1) {
    const childNode = element.childNodes[i];
    const hastNode = hastOfElement(childNode as Element);
    hastNodes.push(hastNode);
  }
  return hastNodes;
};

const htmlRootToHast = (root: Element): HastElementNode => {
  const hastNodes = hastChildrenOf(root);
  return h.apply(null, ['fragment', {}].concat(hastNodes));
};

export const convertHtmlToHast = (html: string): HastElementNode => {
  const root = parseMarkup(html, 'text/html');
  const hast = htmlRootToHast(root.body);

  return hast;
};

const hastToHtmlConfig = html5SerializationConfig();

export const convertHastToHtml = (hast: HastElementNode): string => {
  return hastToHtml5(hast, hastToHtmlConfig);
};

export const convertJatsToHast = (jats: string): HastElementNode => {
  const root = parseMarkup(jats, 'application/xml');
  const body = root.getElementsByTagName('body')[0];
  if (!body) throw new Error('Body not found in JATS');
  const hast = domNodeToHast(body, {
    deserializeTagName: (name) => name,
    deserializeAttribute: (_tagName, name, value) => {
      return { [name]: value };
    },
  });

  return hast as HastElementNode;
};

const decodeUnknownEntities = (html: string) => {
  return html.replace(new RegExp('&amp;', 'g'), '&');
};

export const convertHastToJats = (hast: HastElementNode): string => {
  const html5 = hastToHtml5(hast, {
    isFragment: (_node) => false,
    serializeTagName: (name) => name,
    serializeAttribute: (_tagName, attribute, value) => {
      return value ? [attribute, value] : null;
    },
    serializeAsSelfClosing: (node: HastElementNode) => !node.children || node.children.length === 0,
  });

  return decodeUnknownEntities(html5);
};
