import * as React from 'react';
import { Edit, SimpleForm, SimpleFormIterator, ArrayInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import plaintextDocumentSpec from '../../slate/documentSpecs/plaintextSpec';

import { BreadcrumbTitle } from '../BreadcrumbTitle';
import { CodeInput } from '../CodeInput';
import SlateTextInput from '../SlateTextInput';

const TitlesFormIterator = withStyles({
  form: {
    paddingTop: '1.2em',
  },
})(SimpleFormIterator);

const JournalPublicationForm: React.FC = (props) => {
  return (
    <SimpleForm variant="standard" margin="normal" {...props}>
      <ArrayInput source="titles" fullWidth>
        <TitlesFormIterator>
          <SlateTextInput
            source="title"
            documentSpec={plaintextDocumentSpec}
            singlelineStyle={false}
          />
        </TitlesFormIterator>
      </ArrayInput>
      <CodeInput source="categoryId" fullWidth />
      <CodeInput source="readPermission" fullWidth />
      <ArrayInput source="piplingPatterns" fullWidth>
        <SimpleFormIterator>
          <CodeInput source="pattern" fullWidth label="mønster" />
        </SimpleFormIterator>
      </ArrayInput>
      <CodeInput
        source="idunnSlug"
        label="Idunn-slug (brukes for å hente forsidebilder)"
        fullWidth
      />
    </SimpleForm>
  );
};

export const JournalPublicationEdit: React.FC<{}> = (props) => {
  return (
    <Edit
      {...props}
      title={
        <BreadcrumbTitle resourceName="tidsskrift" recordName={(record) => record.fullTitle} />
      }
      mutationMode="pessimistic"
    >
      <JournalPublicationForm />
    </Edit>
  );
};
