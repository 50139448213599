import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme } from '@material-ui/core';

const styles = (theme: Theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
});

interface Props {
  classes: { progress: string };
  color?: 'inherit' | 'primary' | 'secondary';
}

const CircularIndeterminate = ({ classes, color = 'primary' }: Props) => (
  <CircularProgress className={classes.progress} color={color} />
);

export default withStyles(styles)(CircularIndeterminate);
