import * as React from 'react';
import * as Slate from 'slate';
import * as SlateReact from 'slate-react';
import { HastElementNode } from '@universitetsforlaget/hast';
import {
  HypertextRuleSets,
  HypertextSerializationRuleSet,
  createSerializationRules,
} from '../slate/serializer/rules';
import { HastSerializer } from '../slate/serializer/hastSerializer';
import { HtmlSerialize } from './serializer/htmlSerializer';
import { convertHastToJats, convertJatsToHast } from '../util/hastConverter';
import { removeBlockWrapperElements, normalizeJats } from '../util/jatsUtil';

import { Plugin } from './plugins/types';
import * as tags from './tags';

export type SerializationFormat = 'hast' | 'jats' | 'html' | 'plaintext';

export interface DocumentSpecProperties {
  hypertextRules: HypertextRuleSets;
  serializationFormat: SerializationFormat;
  plugins: Plugin[];
}

export class DocumentSpec {
  readonly nodeRules: HypertextSerializationRuleSet;

  constructor(
    readonly hypertextRules: HypertextRuleSets,
    readonly htmlSerializer: HtmlSerialize,
    readonly hastSerializer: HastSerializer,
    readonly serializationFormat: SerializationFormat,
    readonly blockRules: HypertextSerializationRuleSet,
    readonly inlineRules: HypertextSerializationRuleSet,
    readonly markRules: HypertextSerializationRuleSet,
    readonly plugins: Array<Plugin>
  ) {
    this.nodeRules = { ...blockRules, ...inlineRules };
  }

  renderLeaf = (
    editor: SlateReact.ReactEditor,
    { leaf, children, attributes }: SlateReact.RenderLeafProps
  ): React.ReactElement => {
    for (const mark in leaf) {
      if (mark === 'text') continue;
      const rule = this.markRules[mark];
      if (!rule) continue;
      children = React.createElement(rule.render || rule.htmlTag, attributes, children);
    }

    return React.createElement('span', attributes, children);
  };

  getElementClassNames = (element: Slate.Element): string[] => {
    if (!element.className) return [];
    if (Array.isArray(element.className)) return element.className as string[];
    else return [element.className as string];
  };

  renderElement = (
    editor: SlateReact.ReactEditor,
    props: SlateReact.RenderElementProps
  ): React.ReactElement | null => {
    const { element, attributes, children } = props;
    const rule = this.nodeRules[element.type as string];
    if (!rule) return null;

    const isComponent = rule.render && typeof rule.render !== 'string';
    const classNames = [
      ...(rule.className ? [rule.className] : []),
      ...this.getElementClassNames(element).filter(
        (className) => !rule.className?.includes(className)
      ),
    ];
    const elementProps = {
      editor,
      ...attributes,
      ...(isComponent && { node: element }),
      ...(classNames.length > 0 && { className: classNames.join(' ') }),
      ...(rule.data &&
        rule.data.reduce((props, prop) => ({ ...props, [prop]: element[prop] }), {})),
    };

    return React.createElement(rule.render || rule.htmlTag, elementProps, children);
  };

  deserialize = (document: string | HastElementNode): Slate.Node[] => {
    switch (this.serializationFormat) {
      case 'hast':
        return this.hastSerializer.deserialize(document as HastElementNode);
      case 'jats':
        return this.hastSerializer.deserialize(
          normalizeJats(convertJatsToHast(document as string), this.hypertextRules)
        );
      case 'plaintext':
        if (typeof document === 'string') {
          return [{ type: tags.BLOCK_FRAGMENT, children: [{ text: document }] }];
        } else {
          throw Error('"plaintext" format expects a string.');
        }
      default:
        throw Error(`No deserializer for: ${this.serializationFormat}`);
    }
  };

  serialize = (value: Slate.Node[]): string | HastElementNode => {
    switch (this.serializationFormat) {
      case 'hast':
        return this.hastSerializer.serialize(value);
      case 'jats':
        return convertHastToJats(removeBlockWrapperElements(this.hastSerializer.serialize(value)));
      case 'plaintext': {
        const allTexts = (node: Slate.Node): string[] => {
          if (Slate.Text.isText(node)) {
            return [node.text];
          }

          return node.children.flatMap(allTexts);
        };

        return value.flatMap(allTexts).join('');
      }
      default:
        throw Error(`No serializer for: ${this.serializationFormat}`);
    }
  };

  static createSpec = (specProps: DocumentSpecProperties): DocumentSpec => {
    const blockRules = createSerializationRules(specProps.hypertextRules.blocks, 'block');
    const inlineRules = createSerializationRules(specProps.hypertextRules.inlines, 'inline');
    const markRules = createSerializationRules(specProps.hypertextRules.marks, 'mark');

    return new DocumentSpec(
      specProps.hypertextRules,
      new HtmlSerialize({
        hypertextRules: specProps.hypertextRules,
        serializationRules: {
          blocks: blockRules,
          inlines: inlineRules,
          marks: markRules,
        },
      }),
      new HastSerializer({
        hypertextRules: specProps.hypertextRules,
        serializationRules: {
          blocks: blockRules,
          inlines: inlineRules,
          marks: markRules,
        },
      }),
      specProps.serializationFormat,
      blockRules,
      inlineRules,
      markRules,
      specProps.plugins
    );
  };
}
