import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import { Button, SelectInput } from 'react-admin';

import { CustomState } from '../../state/customState';
import { publishTextbookEdition } from '../../state/publishing/publishingActions';
import { PublishContentDialogForm } from '../../components/PublishContentDialogForm';
import { dateTimeFromTemplate, TimeTemplate } from '../../util/publishingUtils';

interface FormData {
  timeTemplate: TimeTemplate;
  customDate: Date;
  documentFilter: 'rootOnly' | 'all';
}

export const PublishEditionButton: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector<CustomState, boolean>(
    (state) => state.publishing[id]?.status === 'uploading'
  );
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleSubmit = ({ timeTemplate, customDate, documentFilter }: FormData) => {
    dispatch(
      publishTextbookEdition.request({
        id,
        juridikaPublishedAt: dateTimeFromTemplate(timeTemplate, customDate),
        documentFilter,
      })
    );
    setDialogOpen(false);
  };

  return (
    <>
      {isSubmitting && <LinearProgress color="secondary" />}
      <Button label="Publiser..." onClick={() => setDialogOpen(true)}>
        <PublishIcon />
      </Button>
      <PublishContentDialogForm
        open={dialogOpen}
        dialogLabel="Publiser utgave"
        submitLabel="Publiser"
        extraContent={
          <>
            <div>
              <SelectInput
                source="documentFilter"
                choices={[
                  { id: 'rootOnly', name: 'Kun rot-modulen (f.eks. PDF)' },
                  { id: 'all', name: 'Alle bokmoduler (BITS)' },
                ]}
              />
            </div>
          </>
        }
        onSubmit={handleSubmit}
        onClose={() => setDialogOpen(false)}
        initialValues={(): FormData => ({
          timeTemplate: 'tonight',
          customDate: new Date(),
          documentFilter: 'rootOnly',
        })}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
