import * as React from 'react';
import { Card, CardContent, Chip, Divider, withStyles, Typography } from '@material-ui/core';
import { Link } from '@material-ui/icons';
import { Matches, InjectedResult, ExcludedResult } from '../../models/LinkInjectorModels';

type InjectResultsProps = {
  matches: Matches;
  hrefAttributeName: string;
  classes: any;
};

const matchExcludeDisplayText: { [type: string]: string } = {
  MatchExcludeCause$ReferencedDocNotFound: 'Referanse for denne lenken ble ikke funnet',
  MatchExcludeCause$ConflictingWithMatch: 'Overlapper en annen lenke',
  MatchExcludeCause$ExistingLinkUpdated: 'Oppdatert av en ny lenke',
  MatchExcludeCause$IncompleteLink: 'Lenken er ufullstending',
};

const styles = {
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  injectedList: {
    marginBottom: 10,
  },
  listTitle: {
    marginBottom: 10,
    marginTop: 10,
  },
};

class InjectResults extends React.PureComponent<InjectResultsProps> {
  renderIncompleteTag = (match: InjectedResult) => {
    if (!match.incompleteRef) return null;
    return (
      <Chip
        style={{ fontSize: 10, height: 16, background: '#f57c00', color: 'white' }}
        label={match.incompleteRef.description}
      />
    );
  };

  renderExcludeTag = (match: ExcludedResult) => {
    return (
      <Chip
        style={{ fontSize: 10, height: 16, background: '#f44336', color: 'white' }}
        label={matchExcludeDisplayText[match.excludeCause['@type']]}
      />
    );
  };

  renderInjectedMatch = (match: InjectedResult) => {
    const existingHref = match.linkAttributes
      ? match.linkAttributes[this.props.hrefAttributeName]
      : null;

    return (
      <div key={match.uuid} className={this.props.classes.injectedList}>
        <div dangerouslySetInnerHTML={{ __html: match.contextString }} />
        <code>
          {match.referenceHref ? (
            <div>
              <Link fontSize="inherit" color="primary" /> Ny Lenke:{' '}
              <a href={match.referenceHref}>{match.referenceHref}</a>
            </div>
          ) : null}
          {match.oldHref ? (
            <div>
              <Link fontSize="inherit" color="secondary" /> Gammel Lenke:{' '}
              <a href={match.oldHref}>{match.oldHref}</a>
            </div>
          ) : null}
          {existingHref ? (
            <div>
              <Link fontSize="inherit" color="error" /> Eksisterende Lenke:{' '}
              <a href={existingHref}>{existingHref}</a>
            </div>
          ) : null}
        </code>
        {this.renderIncompleteTag(match)}
        <Divider className={this.props.classes.divider} />
      </div>
    );
  };

  renderExcludedMatch = (match: ExcludedResult) => {
    return (
      <div key={match.uuid}>
        <div>{match.matchedString}</div>
        {this.renderExcludeTag(match)}
        <Divider className={this.props.classes.divider} />
      </div>
    );
  };

  renderInjectedList = (matches: InjectedResult[]) => {
    if (matches.length === 0) return null;
    return (
      <React.Fragment>
        <Typography className={this.props.classes.listTitle} variant="h5">
          Referanser som ble lenket
        </Typography>
        <div>{matches.map((match) => this.renderInjectedMatch(match))}</div>
      </React.Fragment>
    );
  };

  renderExcludedList = (matches: ExcludedResult[]) => {
    if (matches.length === 0) return null;
    return (
      <div className={this.props.classes.excludeList}>
        <Typography className={this.props.classes.listTitle} variant="h5">
          Match som ble ekskludert
        </Typography>
        {matches.map((match) => this.renderExcludedMatch(match))}
      </div>
    );
  };

  render() {
    const { matches } = this.props;
    const paths = Object.values(matches);
    const excludes = paths.reduce(
      (acc, match) => [...acc, ...match.excluded],
      [] as ExcludedResult[]
    );
    const injected = paths.reduce(
      (acc, match) => [...acc, ...match.injected],
      [] as InjectedResult[]
    );

    const incompleteMatches = injected.filter((match) => match.incompleteRef);
    const missingParameterCount = incompleteMatches.filter(
      (reason) => reason.incompleteRef.missingParameters
    ).length;
    const ambiguousCount = incompleteMatches.filter((reason) => reason.incompleteRef.ambiguous)
      .length;

    return (
      <React.Fragment>
        <Card elevation={2}>
          <CardContent>
            Satt inn <strong>{injected.length}</strong> lenker.&nbsp;
            <strong>{missingParameterCount}</strong> av disse er ufullstending og&nbsp;
            <strong>{ambiguousCount}</strong> treff som er tvedydelig. Disse er merkert under.
          </CardContent>
        </Card>
        {this.renderInjectedList(injected)}
        {this.renderExcludedList(excludes)}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(InjectResults);
