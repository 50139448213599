import { CREATE, GET_LIST, GET_MANY_REFERENCE, GET_ONE, UPDATE } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders, requestHeaders } from './apiUtil';
import uuidv4 from 'uuid/v4';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  NIKDocumentsApiProvider(urls.innholdBackend, type, params);

const NIKDocumentsApiProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => {
  const contentAPI = baseApiUrl;
  const uuid = uuidv4();

  switch (type) {
    case GET_LIST: {
      const response = fetch(`${contentAPI}/admin/v1/document`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((documents) => {
          const sortedDocuments = documents.sort((a: any, b: any) =>
            b.createdAt.localeCompare(a.createdAt)
          );
          return { data: sortedDocuments, total: 1 };
        });
      return response;
    }
    case GET_ONE: {
      const response = fetch(`${contentAPI}/admin/v1/document/${params.id}`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((document) => {
          return { data: { id: document.documentId, ...document }, total: 1 };
        });
      return response;
    }

    case GET_MANY_REFERENCE: {
      const response = fetch(`${contentAPI}/admin/v1/document`, {
        headers: juridikaHeaders(),
      })
        .then((res) => res.json())
        .then((documents) => {
          const documentsByEditionId = documents.filter(
            (document: any) => document.editionId === params.id
          );

          const sortedDocumentsByEditionId = documentsByEditionId.sort((a: any, b: any) =>
            b.createdAt.localeCompare(a.createdAt)
          );
          return { data: sortedDocumentsByEditionId, total: 1 };
        });
      return response;
    }

    case CREATE: {
      const response = fetch(`${contentAPI}/admin/v1/document`, {
        method: 'POST',
        ...requestHeaders(),
        body: JSON.stringify({
          id: uuid,
          editionId: params.data.editionsId,
          status: 'DRAFT',
          content:
          "<dynamic-document><body><part><title>sett inn tittel...</title><paragraph><p>start å skrive ditt dokument...</p></paragraph></part></body></dynamic-document>",
        }),
      })
        .then((res) => res.json())
        .then((document) => {
          return { data: { id: document.id, ...document }, total: 1 };
        });
      return response;
    }

    case UPDATE: {
      const response = fetch(`${contentAPI}/admin/v1/document/${params.id}`, {
        method: 'PUT',
        ...requestHeaders(),
        body: params.data.content,
      })
        .then((res) => res.json())
        .then((document) => {
          return { data: { id: document.id, ...document }, total: 1 };
        });
      return response;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
