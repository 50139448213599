import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ELEMENT, ElementModel, Model, TEXT } from '../../util/simpleXml';

import {
  BitsElement,
  extractFootnotesFromNodes,
  findElementIndex,
  findElementIndexAfter,
  Stack,
} from './BitsDebugUtil';
import { FootnoteSymbol, LowLevelElement, LowLevelNodes } from './BitsDebugLowLevel';
import { HighLevelElementInteraction } from './BitsDebugUI';
import {
  InlineFootnoteDisplayTypography,
  MarginaliaFootnoteDisplayTypography,
  ParagraphHeadingTypography,
} from './BitsDebugTypography';

/**
 * High level:
 * BITS elements from the root of the document,
 * down to any element that must be able to display its own footnotes
 * in a FootnoteContainer.
 *
 * The renderers used in this file mostly have circular dependencies between them.
 * So that splitting them up into several modules is virtually impossible because of
 * how JS modules work
 */

const useStyles = makeStyles({
  tocEntry: { marginLeft: '1rem' },
  footnoteSymbol: {
    fontSize: '.75rem',
    fontWeight: 700,
    verticalAlign: 'top',
  },
  notes: {
    backgroundColor: '#ffffa6',
  },
  module: {},
  abstractParagraph: {
    display: 'flex',
  },
  abstractParagraphNum: {
    flex: '0 0 2rem',
    color: '#888888',
  },
  abstractParagraphBody: {
    marginRight: '1rem',
  },
  abstractParagraphMargin: {
    flex: '0 0 10rem',
  },
  indexEntry: {
    marginTop: '0.5rem',
    marginLeft: '1rem',
  },
});

const HighLevelNodes: React.FC<{ nodes: Model[]; stack: Stack }> = ({ nodes, stack }) => (
  <>
    {nodes.map((node, index) => (
      <HighLevelNode key={index} node={node} siblings={nodes} stack={stack} />
    ))}
  </>
);

const HighLevelNode: React.FC<{ node: Model; siblings: ReadonlyArray<Model>; stack: Stack }> = ({
  node,
  siblings,
  stack,
}) => {
  if (node.type === TEXT) return <>{node.value}</>;
  return <HighLevelElement element={node} siblings={siblings} stack={stack} />;
};

export const HighLevelElement: BitsElement = ({ element, siblings, stack }) => {
  switch (element.tagName) {
    case 'front-matter':
      return <FrontMatter element={element} siblings={siblings} stack={stack} />;
    case 'front-matter-part':
    case 'toc':
    case 'foreword':
    case 'index':
      return <HighLevelNodeWithInteraction element={element} siblings={siblings} stack={stack} />;
    case 'book-part':
      return <BookPart element={element} siblings={siblings} stack={stack} />;
    case 'toc-title-group':
    case 'index-div':
    case 'book-body':
    case 'book-back':
    case 'book-part-meta':
      return (
        <div className={`bits-${element.tagName}`}>
          <HighLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
        </div>
      );
    case 'index-entry':
      return <IndexEntry element={element} siblings={siblings} stack={stack} />;
    case 'named-book-part-body':
    case 'body':
    case 'back':
      return <Body element={element} siblings={siblings} stack={stack} />;
    case 'sec':
      return <Section element={element} siblings={siblings} stack={stack} />;
    case 'toc-entry':
      return <TocEntry element={element} siblings={siblings} stack={stack} />;
    case 'toc-div':
      return <TocDiv element={element} siblings={siblings} stack={stack} />;
    case 'title-group':
    case 'index-title-group':
      return <TitleGroup element={element} siblings={siblings} stack={stack} />;
    case 'notes':
      return <Notes element={element} siblings={siblings} stack={stack} />;
    default:
      return <LowLevelElement element={element} siblings={siblings} stack={stack} />;
  }
};

const FrontMatter: BitsElement = ({ element, siblings, stack }) => {
  const newStack = { element, siblings, stack };
  return (
    <div className={`bits-${element.tagName}`}>
      <HighLevelElementInteraction stack={newStack}>
        <HighLevelNodes nodes={element.children} stack={newStack} />
      </HighLevelElementInteraction>
    </div>
  );
};

const HighLevelNodeWithInteraction: BitsElement = ({ element, siblings, stack }) => {
  const newStack = { element, siblings, stack };
  return (
    <div className={`bits-${element.tagName}`}>
      <HighLevelElementInteraction stack={newStack}>
        <HighLevelNodes nodes={element.children} stack={newStack} />
      </HighLevelElementInteraction>
    </div>
  );
};

const TocEntry: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const subTocIndex = findElementIndex(
    element.children,
    (child) => child.type === ELEMENT && child.tagName === 'toc-entry',
    element.children.length
  );
  const localNodes = element.children.slice(0, subTocIndex);
  const subNodes = element.children.slice(subTocIndex);

  const findNavPointer = (): ElementModel | null => {
    for (const node of localNodes) {
      if (node.type === ELEMENT && node.tagName === 'nav-pointer') {
        return node;
      }
    }
    return null;
  };

  const navPointer = findNavPointer();
  const localNodesWithoutNavPointer = localNodes.filter((node) => node !== navPointer);

  const newStack = { element, siblings, stack };

  return (
    <div className={classes.tocEntry} style={{ marginLeft: '1rem' }}>
      <div>
        <a href={`#${navPointer?.attributes?.['rid']}`}>
          <HighLevelNodes nodes={localNodesWithoutNavPointer} stack={newStack} />
        </a>
      </div>
      <HighLevelNodes nodes={subNodes} stack={newStack} />
    </div>
  );
};

const TocDiv: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();

  return (
    <div className={classes.tocEntry} style={{ marginLeft: '1rem' }}>
      <HighLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
    </div>
  );
};

const BookPart: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };
  return (
    <HighLevelElementInteraction stack={newStack}>
      <div className={classes.module}>
        <HighLevelNodes nodes={element.children} stack={newStack} />
      </div>
    </HighLevelElementInteraction>
  );
};

const Body: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  return (
    <div className={classes.module}>
      <ModuleNodes nodes={element.children} stack={{ element, siblings, stack }} />
    </div>
  );
};

const Section: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { element, siblings, stack };
  return (
    <HighLevelElementInteraction stack={newStack}>
      <div className={classes.module}>
        <ModuleNodes nodes={element.children} stack={newStack} />
      </div>
    </HighLevelElementInteraction>
  );
};

/**
 * Group module nodes together. This is needed for <sec>,
 * which has all its <label>, <title> and <subtitle> unpacked amongst all its
 * other elements, but we have to display a footnote container for the title group.
 */
const groupModuleNodes = (nodes: Model[]): Model[] => {
  const output: Model[] = [];
  let currentGroup: string | null = null;
  let groupNodes: Model[] = [];

  const flushGroup = () => {
    if (currentGroup) {
      output.push({
        type: ELEMENT,
        tagName: currentGroup,
        attributes: {},
        children: groupNodes,
        expanded: true,
        fake: true,
      });
      groupNodes = [];
      currentGroup = null;
    }
  };

  const flushNamedGroup = (groupName: string) => {
    if (currentGroup === groupName) {
      flushGroup();
    }
  };

  const pushToGroup = (groupName: string, node: Model) => {
    if (groupName !== currentGroup) {
      flushGroup();
    }
    currentGroup = groupName;
    groupNodes.push(node);
  };

  const pushToNewGroup = (groupName: string, node: Model) => {
    flushGroup();
    pushToGroup(groupName, node);
  };

  const push = (node: Model) => {
    if (currentGroup) {
      groupNodes.push(node);
    } else {
      output.push(node);
    }
  };

  for (const node of nodes) {
    if (node.type === TEXT) {
      push(node);
    } else {
      switch (node.tagName) {
        case 'label':
        case 'title':
        case 'subtitle':
          pushToGroup('title-group', node);
          break;
        case 'related-object':
          flushNamedGroup('title-group');
          if (node.attributes['content-type'] === 'juridika-p') {
            pushToNewGroup('p-group', node);
          } else {
            push(node);
          }
          break;
        case 'sec':
        case 'book-part':
          flushGroup();
          push(node);
          break;
        default:
          flushNamedGroup('title-group');
          push(node);
          break;
      }
    }
  }

  flushGroup();

  return output;
};

/**
 * The nodes in a module body
 */
const ModuleNodes: React.FC<{ nodes: Model[]; stack: Stack }> = ({ nodes, stack }) => {
  return (
    <>
      {groupModuleNodes(nodes).map((node, index) => (
        <GroupedModuleNode key={index} node={node} siblings={nodes} stack={stack} />
      ))}
    </>
  );
};

/**
 * One node in a module body
 */
const GroupedModuleNode: React.FC<{
  node: Model;
  siblings: ReadonlyArray<Model>;
  stack: Stack;
}> = ({ node, siblings, stack }) => {
  if (node.type === TEXT) return <>{node.value}</>;

  const element = node;

  switch (element.tagName) {
    case 'object-id':
      return null;
    case 'p-group':
      return <AbstractParagraph nodes={element.children} siblings={siblings} stack={stack} />;
    case 'book-part':
      return <BookPart element={element} siblings={siblings} stack={stack} />;
    case 'sec':
      return <Section element={element} siblings={siblings} stack={stack} />;
    case 'title-group':
      return <TitleGroup element={element} siblings={siblings} stack={stack} />;
    case 'notes':
      return <Notes element={element} siblings={siblings} stack={stack} />;
    default:
      return <AbstractParagraph nodes={[element]} siblings={siblings} stack={stack} />;
  }
};

const TitleGroup: BitsElement = ({ element, stack, siblings }) => {
  const children = element.children;
  const titleIndex = children.findIndex(
    (child) => child.type === ELEMENT && child.tagName === 'title'
  );

  if (titleIndex > 0) {
    const title = children[titleIndex] as ElementModel;
    const subtitles = titleIndex < 0 ? children : children.slice(titleIndex + 1);

    // Draw <label> (if it exists) as a child of <title>
    return (
      <AbstractParagraph
        nodes={[
          {
            ...element,
            tagName: 'title-group',
            children: [
              {
                ...title,
                children: [...children.slice(0, titleIndex), ...title.children],
              },
            ],
            fake: true,
          },
          ...subtitles,
        ]}
        siblings={siblings}
        stack={stack}
      />
    );
  } else {
    const foundSubtitleIndex = children.findIndex(
      (child) => child.type === ELEMENT && child.tagName === 'subtitle'
    );
    const subtitleIndex = foundSubtitleIndex < 0 ? children.length : foundSubtitleIndex;
    return (
      <AbstractParagraph
        nodes={[
          {
            ...element,
            tagName: 'title-group',
            children: [
              {
                type: ELEMENT,
                tagName: 'title',
                attributes: {},
                children: children.slice(0, subtitleIndex),
                fake: true,
                expanded: true,
              },
            ],
            fake: true,
          },
          ...children.slice(subtitleIndex),
        ]}
        siblings={siblings}
        stack={stack}
      />
    );
  }
};

const AbstractParagraph: React.FC<{
  nodes: Model[];
  siblings: ReadonlyArray<Model>;
  stack: Stack;
}> = ({ nodes, siblings, stack }) => {
  const classes = useStyles();

  const bodyIndex = findElementIndexAfter(nodes, (child) => child.tagName === 'x', 0);

  const footnotes = extractFootnotesFromNodes(nodes, siblings, stack);
  const normalFootnotes = footnotes.filter(
    (footnote) => footnote.element.attributes['fn-type'] !== 'marginalia'
  );
  const marginaliaFootnotes = footnotes.filter(
    (footnote) => footnote.element.attributes['fn-type'] === 'marginalia'
  );

  return (
    <div className={classes.abstractParagraph}>
      <div className={classes.abstractParagraphNum}>
        <ParagraphHeadingTypography stack={stack}>
          {nodes.slice(0, bodyIndex).map((child, index) => (
            <HighLevelNode key={index} node={child} siblings={siblings} stack={stack} />
          ))}
        </ParagraphHeadingTypography>
      </div>

      <div className={classes.abstractParagraphBody}>
        <LowLevelNodes nodes={nodes.slice(bodyIndex)} siblings={nodes} stack={stack} />

        {normalFootnotes.map(({ element, siblings, stack }, index) => {
          const newStack = { element, siblings, stack };
          return (
            <InlineFootnoteDisplayTypography key={index} stack={newStack}>
              <span className={classes.footnoteSymbol}>
                <FootnoteSymbol element={element} siblings={siblings} stack={stack} />
              </span>
              {
                // The paragraph _inside_ the footnote needs to be unpacked
                element.children.map((child, index) => {
                  if (child.type === TEXT)
                    return <React.Fragment key={index}>{child.value}</React.Fragment>;
                  const newNewStack = {
                    element: child,
                    siblings: element.children,
                    stack: newStack,
                  };
                  return <LowLevelNodes key={index} nodes={child.children} stack={newNewStack} />;
                })
              }
            </InlineFootnoteDisplayTypography>
          );
        })}
      </div>

      <div className={classes.abstractParagraphMargin}>
        {marginaliaFootnotes.map(({ element, siblings, stack }, index) => {
          const newStack = { element, siblings, stack };
          return (
            <MarginaliaFootnoteDisplayTypography key={index} stack={newStack}>
              <LowLevelNodes nodes={element.children} siblings={siblings} stack={newStack} />
            </MarginaliaFootnoteDisplayTypography>
          );
        })}
      </div>
    </div>
  );
};

const Notes: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  const newStack = { stack, element, siblings };
  return (
    <div className={classes.notes}>
      <HighLevelElementInteraction stack={newStack}>
        <HighLevelNodes nodes={element.children} stack={newStack} />
      </HighLevelElementInteraction>
    </div>
  );
};

const IndexEntry: BitsElement = ({ element, siblings, stack }) => {
  const classes = useStyles();
  return (
    <div className={classes.indexEntry}>
      <HighLevelNodes nodes={element.children} stack={{ element, siblings, stack }} />
    </div>
  );
};
