import React from 'react';
import BEMHelper from 'react-bem-helper';
import ReactTooltip from 'react-tooltip';
import { useTranslate } from 'react-admin';

import { GroupedCommentMeta, CommentMeta } from '../../../state/expressions/types';

import '../../../styles/commentariesEditor/commentMeta.css';

var classes = new BEMHelper({
  name: 'commentMeta',
});

const formatDateString = (dateString: string) =>
  new Date(dateString).toLocaleDateString('nb-NO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

const StatusIndicator = ({
  status,
  eId,
  tooltipId,
}: {
  status: 'isPublished' | 'isDraft' | 'isOld';
  eId: string;
  tooltipId: string;
}) => (
  <span
    {...classes('statusIndicator', { [status]: true })}
    data-tip=""
    data-for={tooltipId}
    data-place="bottom"
  />
);

const FragmentMetaInfo: React.FC<{ fragmentComment: CommentMeta }> = ({ fragmentComment }) => {
  const translate = useTranslate();
  const { lastUpdatedAt, lastUpdatedBy, createdAt, createdBy, publishedAt } = fragmentComment;

  return (
    <div {...classes('versionInfo')}>
      {publishedAt && (
        <p>
          {translate('resources.commentMeta.text.published_at', {
            publishedAt: formatDateString(publishedAt),
          })}
        </p>
      )}
      {lastUpdatedAt && (
        <p>
          {translate('resources.commentMeta.text.last_updated_at', {
            lastUpdatedAt: formatDateString(lastUpdatedAt),
            lastUpdatedBy,
          })}
        </p>
      )}
      {createdAt && (
        <p>
          {translate('resources.commentMeta.text.created_at', {
            createdAt: formatDateString(createdAt),
            createdBy,
          })}
        </p>
      )}
    </div>
  );
};

export const Published = ({ published }: { published: CommentMeta[] }) => {
  if (published.length === 0) return null;

  return (
    <div {...classes('published')}>
      <p {...classes('title')}>Har {published.length} publisert(e) kommentar(er):</p>
      <ul>
        {published.map((fragment: CommentMeta) => (
          <li key={`publishedTooltipListItem-${fragment.publishedAt}`}>
            <FragmentMetaInfo fragmentComment={fragment} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Draft = ({ draft }: { draft: CommentMeta | undefined }) => {
  if (!draft) return null;

  return (
    <div {...classes('draft')}>
      <p {...classes('title')}>Har eksisterende utkast</p>
      <FragmentMetaInfo fragmentComment={draft} />
    </div>
  );
};

export const Old = ({ old }: { old: CommentMeta | undefined }) => {
  if (!old) return null;

  return (
    <div {...classes('draft')}>
      <p {...classes('title')}>Har kommentar i et foregående utrykk</p>
      <FragmentMetaInfo fragmentComment={old} />
    </div>
  );
};

const CommentaryMeta = ({ metadata, eId }: { metadata: GroupedCommentMeta; eId: string }) => {
  const { draft, published, old } = metadata;

  return (
    <div {...classes()}>
      {draft && (
        <React.Fragment>
          <StatusIndicator eId={eId} status={'isDraft'} tooltipId={`draftTooltip-${eId}`} />
          <ReactTooltip id={`draftTooltip-${eId}`} getContent={() => <Draft draft={draft} />} />
        </React.Fragment>
      )}
      {old && (
        <React.Fragment>
          <StatusIndicator eId={eId} status={'isOld'} tooltipId={`draftTooltip-${eId}`} />
          <ReactTooltip id={`draftTooltip-${eId}`} getContent={() => <Old old={old} />} />
        </React.Fragment>
      )}
      {published.length > 0 && (
        <React.Fragment>
          <StatusIndicator eId={eId} status={'isPublished'} tooltipId={`publishedTooltip-${eId}`} />
          <ReactTooltip
            id={`publishedTooltip-${eId}`}
            getContent={() => <Published published={published} />}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default CommentaryMeta;
