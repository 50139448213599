import * as React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';
import { Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { BulkActionInput } from '../utils/customerBulkUtils';
import {
  addProductConfirm,
  getProductChoices,
  removeProductConfirm,
} from '../utils/productBulkActions';
import { setPacakageConfirm, getPackageChoices } from '../utils/packageBulkActions';
import {
  setSubscriptionStatusConfirm,
  getSubscriptionStatusChoices,
} from '../utils/subscriptionStatusBulkActions';

const styles = createStyles({
  genericBulkAction: {
    margin: '3px',
    'padding-left': '2px',
  },
  removeButton: {
    backgroundColor: '#f5b7b1',
  },
  addButton: {
    backgroundColor: ' #abebc6',
  },
  setButton: {
    backgroundColor: '#aed6f1',
  },
});

type ButtonSpecificInput = {
  handleConfirm: (props: any, name: string) => void;
  getChoices: () => string[];
  label: string;
  headline: string;
  className: string;
  classes: {
    removeButton?: string;
    addButton?: string;
    setButton?: string;
  };
};

const GenericCustomerBulkButton: React.FC<ButtonSpecificInput> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { handleConfirm, getChoices, label, headline, className } = props;
  return (
    <React.Fragment>
      <Button
        variant="contained"
        className={className}
        color="default"
        label={label}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="bulkPopup"
        maxWidth="md"
      >
        <DialogTitle id="bulkPopup">{headline}</DialogTitle>
        <List>
          {getChoices().map((item) => (
            <ListItem
              button
              onClick={() => {
                setIsOpen(false);
                handleConfirm(props, item);
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    </React.Fragment>
  );
};

const CustomerBulkActions: React.FC<BulkActionInput> = (props) => {
  const { classes } = props;
  return (
    <div>
      <GenericCustomerBulkButton
        {...props}
        className={[classes.genericBulkAction, classes.setButton].join(' ')}
        handleConfirm={setSubscriptionStatusConfirm}
        getChoices={() => getSubscriptionStatusChoices(props)}
        label="Angi abonnementstatus"
        headline="Angi abonnementstatus for valgte kunder"
      />
      <GenericCustomerBulkButton
        {...props}
        className={[classes.genericBulkAction, classes.setButton].join(' ')}
        handleConfirm={setPacakageConfirm}
        getChoices={() => getPackageChoices(props)}
        label="Angi pakke"
        headline="Angi pakke for valgte kunder"
      />
      <GenericCustomerBulkButton
        {...props}
        className={[classes.genericBulkAction, classes.addButton].join(' ')}
        handleConfirm={addProductConfirm}
        getChoices={() => getProductChoices(props)}
        label="Legg til produkt"
        headline="Velg et produkt å legge til for valgte kunder"
      />
      <GenericCustomerBulkButton
        {...props}
        className={[classes.genericBulkAction, classes.removeButton].join(' ')}
        handleConfirm={removeProductConfirm}
        getChoices={() => getProductChoices(props)}
        label="Fjern et produkt"
        headline="Velg et produkt å fjerne for valgte kunder"
      />
    </div>
  );
};

export default withStyles(styles)(CustomerBulkActions);
