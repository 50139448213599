import { HastElementNode } from '@universitetsforlaget/hast';
import { HypertextRuleSets } from '../slate/serializer/rules';
import { normalizeWhitespace, normalizeFragment, unwrapFragment } from '../util/documentConverter';

export const BLOCK_WRAPPER_ELEMENT = 'span';
export const UNWRAP_WHEN_SERIALIZING_PROP = 'unwrap';

/**
 * Jats files contains text-elements next to blocks which does not satifsy Slate model.
 * For example, '<fn>Some text next to block <p>Valid block</p></fn>' will be normalized to
 * '<fn><blockWrapperElement blockWrapperAttribute>Some text next to block</blockWrapperElement> <p>Valid block</p></fn>'
 * The element with attribute 'blockWrapperAttribute' should be removed when serializing.
 */
export const normalizeJats = (body: HastElementNode, rules: HypertextRuleSets): HastElementNode => {
  const normalizedBody = normalizeFragment(body, {
    ruleSets: rules,
    unwrap: (_node) => null,
    rewrapAs: (node) => node,
    remove: (_node) => false,

    blockWrapperElement: BLOCK_WRAPPER_ELEMENT,
    // This is automatically removed by JatsPlugin when serializing
    blockWrapperProperties: { [UNWRAP_WHEN_SERIALIZING_PROP]: true },
  });

  return normalizeWhitespace(normalizedBody, rules);
};

export const removeBlockWrapperElements = (body: HastElementNode): HastElementNode => {
  return unwrapFragment(body, (node: HastElementNode) => {
    return node.properties?.[UNWRAP_WHEN_SERIALIZING_PROP] === true;
  });
};
