import { Matches, InjectedJournal } from '../../models/LinkInjectorModels';

export enum InjectorActionTypes {
  INJECTOR_STARTED = '@@injector/INJECTOR_STARTED',
  INJECTOR_SUCCESS = '@@injector/INJECTOR_SUCCESS',
  INJECTOR_FAILURE = '@@injector/INJECTOR_FAILURE',

  FETCH_INJECTED_JOURNAL_STARTED = '@@injector/FETCH_INJECTED_JOURNAL_STARTED',
  FETCH_INJECTED_JOURNAL_SUCCESS = '@@injector/FETCH_INJECTED_JOURNAL_SUCCESS',
  FETCH_INJECTED_JOURNAL_FAILURE = '@@injector/FETCH_INJECTED_JOURNAL_FAILURE',

  FETCH_INJECT_RESULT_STARTED = '@@injector/FETCH_INJECT_RESULT_STARTED',
  FETCH_INJECT_RESULT_SUCCESS = '@@injector/FETCH_INJECT_RESULT_SUCCESS',
  FETCH_INJECT_RESULT_FAILED = '@@injector/FETCH_INJECT_RESULT_FAILED',
}

export interface InjectionStarted {
  readonly type: InjectorActionTypes.INJECTOR_STARTED;
  readonly ids: string[];
}

export interface InjectionSuccess {
  readonly type: InjectorActionTypes.INJECTOR_SUCCESS;
  readonly ids: string[];
}

export interface InjectionFailed {
  readonly type: InjectorActionTypes.INJECTOR_FAILURE;
  readonly ids: string[];
}

export interface FetchInjectedJournalStarted {
  readonly type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_STARTED;
  readonly id: string;
}

export interface FetchInjectResultsStarted {
  readonly type: InjectorActionTypes.FETCH_INJECT_RESULT_STARTED;
  readonly id: string;
}

export interface FetchInjectResultsSuccess {
  readonly type: InjectorActionTypes.FETCH_INJECT_RESULT_SUCCESS;
  readonly id: string;
  readonly matches: Matches;
}

export interface FetchInjectResultsFailed {
  readonly type: InjectorActionTypes.FETCH_INJECT_RESULT_FAILED;
  readonly id: string;
}

export interface FetchInjectedJournalSuccess {
  readonly type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_SUCCESS;
  readonly id: string;
  readonly content: InjectedJournal;
}

export interface FetchInjectedJournalFailed {
  readonly type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_FAILURE;
  readonly id: string;
}

export const fetchInjectResultsStarted = (id: string): FetchInjectResultsStarted => {
  return {
    type: InjectorActionTypes.FETCH_INJECT_RESULT_STARTED,
    id,
  };
};

export const fetchInjectResultSuccess = (
  id: string,
  matches: Matches
): FetchInjectResultsSuccess => {
  return {
    type: InjectorActionTypes.FETCH_INJECT_RESULT_SUCCESS,
    id,
    matches,
  };
};

export const fetchInjectResultFailed = (id: string): FetchInjectResultsFailed => {
  return {
    type: InjectorActionTypes.FETCH_INJECT_RESULT_FAILED,
    id,
  };
};

export const fetchInjectedJournalStartedAction = (id: string): FetchInjectedJournalStarted => {
  return {
    type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_STARTED,
    id,
  };
};

export const fetchInjectedJournalSuccessAction = (
  id: string,
  content: InjectedJournal
): FetchInjectedJournalSuccess => {
  return {
    type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_SUCCESS,
    id,
    content,
  };
};

export const fetchInjectedJournalFailedAction = (id: string): FetchInjectedJournalFailed => {
  return {
    type: InjectorActionTypes.FETCH_INJECTED_JOURNAL_FAILURE,
    id,
  };
};

export const injectJournal = (ids: string[]): InjectionStarted => {
  return {
    type: InjectorActionTypes.INJECTOR_STARTED,
    ids,
  };
};

export const injectionSuccessAction = (ids: string[]): InjectionSuccess => {
  return {
    type: InjectorActionTypes.INJECTOR_SUCCESS,
    ids,
  };
};

export const injectionFailedAction = (ids: string[]): InjectionFailed => {
  return {
    type: InjectorActionTypes.INJECTOR_FAILURE,
    ids,
  };
};
