import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  Create,
  NumberInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import { TextbookPublication } from '../../dataProvider/textbookPublicationsProvider';

import plaintextDocumentSpec from '../../slate/documentSpecs/plaintextSpec';

import SlateTextInput from '../SlateTextInput';

const TitlesFormIterator = withStyles({
  form: {
    paddingTop: '1.2em',
  },
})(SimpleFormIterator);

const TextbookPublicationTitle: React.FC<{
  record?: TextbookPublication;
}> = ({ record }) => {
  return <span>Fagbok {record ? `"${record.fullTitle}"` : ''}</span>;
};

const TextbookPublicationForm: React.FC<{ isNew: boolean }> = (props) => (
  <SimpleForm variant="standard" margin="normal" {...props}>
    <TextInput disabled source="id" fullWidth />
    <TextInput
      source="slug"
      fullWidth
      allowEmpty
      label={props.isNew ? 'Slug (autogenereress fra tittel hvis tom)' : undefined}
    />
    <ArrayInput source="titles" fullWidth>
      <TitlesFormIterator>
        <SlateTextInput
          source="title"
          documentSpec={plaintextDocumentSpec}
          singlelineStyle={false}
        />
      </TitlesFormIterator>
    </ArrayInput>
    <ArrayInput source="actWorkIds" fullWidth>
      <SimpleFormIterator>
        <TextInput source="workId" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
    <NumberInput source="ufDepartment" fullWidth allowEmpty />
  </SimpleForm>
);

export const TextbookPublicationEdit: React.FC<{}> = (props) => (
  <Edit title={<TextbookPublicationTitle />} {...props}>
    <TextbookPublicationForm isNew={false} />
  </Edit>
);

export const TextbookPublicationCreate: React.FC<{}> = (props) => (
  <Create title={<TextbookPublicationTitle />} {...props}>
    <TextbookPublicationForm isNew />
  </Create>
);
