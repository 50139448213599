import { ActionType, getType } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';
import URI from 'urijs';

import { requestHeaders } from '../../dataProvider/apiUtil';
import { BackendUrls } from '../../dataProvider/types';

import * as actions from './expressionActions';

const xslUrl = '/xopus_5.4.4/commentaries/xsl/toc.xsl';

export const fetchExpressionXml = async (id: string, query: any, baseUrl: string, options = {}) => {
  return fetch(
    URI(baseUrl)
      .segment('api')
      .segment('admin')
      .segment('work')
      .segment(id)
      .segment('item')
      .search({
        ...query,
      })
      .toString(),
    {
      ...requestHeaders(),
      ...options,
    }
  );
};

type GenerateExpressionTocAction = ActionType<typeof actions.generateExpressionToc>;
type GetExpressionXML = ActionType<typeof actions.getExpressionXML>;
type SaveExpression = ActionType<typeof actions.save>;

function* generateExpressionToc(action: GenerateExpressionTocAction, backendUrls: BackendUrls) {
  const {
    payload: { uri, publicationStatus },
  } = action;

  try {
    const xmlResponse = yield call(
      fetchExpressionXml,
      uri,
      { publicationStatus },
      backendUrls.lovEditorBackend
    );
    const xslResponse = yield call(fetch, xslUrl);
    const xmlString = yield xmlResponse.text();
    const xslString = yield xslResponse.text();

    const xsltProcessor = new XSLTProcessor();
    const parser = new DOMParser();

    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
    const xslDoc = parser.parseFromString(xslString, 'text/xml');

    xsltProcessor.importStylesheet(xslDoc);

    const html = xsltProcessor.transformToFragment(xmlDoc, document);

    console.log(html.textContent);

    yield put(
      actions.generateExpressionTocSuccess({
        id: uri,
        toc: JSON.parse(html.textContent || ''),
      })
    );
  } catch (error) {
    console.error(error);
    yield put(actions.generateExpressionTocFailure());
  }
}

export function* getExpressionXML(action: GetExpressionXML, backendUrls: BackendUrls) {
  const {
    payload: {
      id,
      data: { query },
    },
  } = action;

  try {
    const xmlResponse = yield call(fetchExpressionXml, id, query, backendUrls.lovEditorBackend);
    const xml = yield xmlResponse.text();

    yield put(
      actions.getExpressionXMLSuccess({
        id: [id, query.publicationStatus].join('@'),
        xml,
      })
    );
  } catch (error) {
    yield put(actions.getExpressionXMLFailure());
  }
}

function* saveExpression(action: SaveExpression, backendUrls: BackendUrls) {
  const {
    payload: {
      id,
      data: { xmlDocument, editor },
    },
    meta,
  } = action;

  let expressionUri = id.startsWith('/') ? id : `/${id}`;

  const url = URI(backendUrls.lovEditorBackend)
    .segment('api')
    .segment('admin')
    .segment('cms')
    .segment('manifestation')
    .segment('save')
    .search({
      editor,
      expressionUri,
    })
    .toString();

  try {
    const xmlResponse = yield call(fetch, url, {
      ...requestHeaders({
        'Content-Type': 'application/xml;charset=UTF-8',
      }),
      method: 'POST',
      body: new XMLSerializer().serializeToString(xmlDocument),
    });
    const xml = yield xmlResponse.text();

    yield put(
      actions.saveSuccess({
        payload: {
          expression: {
            id: [id, 'DRAFT'].join('@'),
            xml,
          },
        },
        meta: meta.onSuccess,
      })
    );
  } catch (error) {
    yield put(actions.saveFailure());
  }
}

function* publishExpression(action: actions.PublishExpression, backendUrls: BackendUrls) {
  const {
    payload: {
      id,
      data: { editor },
    },
    meta: {
      onSuccess: { callback: successCallback },
    },
  } = action;

  let expressionUri = id.startsWith('/') ? id : `/${id}`;

  const url = URI(backendUrls.lovEditorBackend)
    .segment('api')
    .segment('admin')
    .segment('cms')
    .segment('manifestation')
    .segment('publish')
    .query({
      expressionUri: expressionUri,
      editor,
    })
    .toString();

  try {
    const { status } = yield call(fetch, url, {
      ...requestHeaders(),
      method: 'POST',
    });
    if (status < 200 || status >= 300) {
      yield put(actions.publishFailure());
    }
    yield put(actions.publishSuccess(successCallback));
  } catch (error) {
    yield put(actions.publishFailure());
  }
}

export const createExpressionSaga = (backendUrls: BackendUrls) => {
  return function* expressionSaga() {
    yield takeEvery(getType(actions.generateExpressionToc), function* (action: any) {
      yield call(generateExpressionToc, action, backendUrls);
    });
    yield takeEvery(getType(actions.getExpressionXML), function* (action: any) {
      yield call(getExpressionXML, action, backendUrls);
    });
    yield takeEvery(getType(actions.save), function* (action: any) {
      yield call(saveExpression, action, backendUrls);
    });
    yield takeEvery(getType(actions.publish), function* (action: any) {
      yield call(publishExpression, action, backendUrls);
    });
  };
};
