import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import MuiButton from '@material-ui/core/Button';

export const SlateHelpDialog: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose} scroll="paper" aria-label={''}>
      <DialogTitle>Hjelp til Slate-Editor</DialogTitle>
      <DialogContent>
        <h3>1. Redigering</h3>
        <p>
          Denne HTML-editoren er en W.Y.S.I.W.Y.G. (What You See Is What You Get)-editor med noen
          unntak for nyhetsartikkler (se punkt 3.1). Det betyr at man redigerer HTML uten å forholde
          seg til den underliggende HTML-strukturen. De enkle funksjonene er kanskje intuitive nok,
          men noen funksjoner som involverer mer avanserte HTML-elementer krever kanskje en
          forklaring.
        </p>
        <h3>2. Lim inn fra Word</h3>
        <p>
          Å lime inn tekst fra Word vil vanligvis kreve noe etter-redigering. Dette gjelder i hvert
          fall for lister, om ikke annet. Denne teksten kan oppdateres med tips etter hvert.
        </p>
        <h3>3. Nyhetsartikkler</h3>
        <h4 style={{ color: 'red' }}>3.1 Byline</h4>
        <p>
          Byline er et element som ikke er en del av selve dokumentet. Bylinen blir "skutt inn" rett
          før dokumentets første vanlige paragraf på juridika.no.{' '}
          <b>
            Du må manuelt legge til tittel (<i>h1</i>) og figur over byline i editoren.
          </b>
        </p>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose} color="primary" autoFocus>
          Lukk
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};
