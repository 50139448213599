import * as React from 'react';
import {
  ReferenceManyFieldProps,
  useReferenceManyFieldController,
  ListContextProvider,
  ResourceContextProvider,
  sanitizeFieldRestProps,
  ListControllerProps,
  useListContext,
} from 'react-admin';

import { TextbookModule } from '../../dataProvider/textbookModulesProvider';

import ModifyTagsButton from '../documentTags/ModifyTagsButton';

const BulkActions = (props: any) => {
  const { data } = useListContext<TextbookModule>();

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ModifyTagsButton
        tagResource="literatureDocumentTags"
        tagPrefixes={['rettsområde', 'lisens', 'serie', 'freebie', 'publiseringsstatus']}
        getTagPathsOnSelectedIds={() => {
          const set: Set<string> = new Set();
          if (props.selectedIds) {
            props.selectedIds.forEach((id: string) => {
              data[id]?.tags?.forEach((tag) => {
                set.add(tag.path.join('.'));
              });
            });
          }
          return set;
        }}
      />
    </div>
  );
};

interface ReferenceManyFieldViewProps
  extends Omit<ReferenceManyFieldProps, 'basePath' | 'resource' | 'page' | 'perPage' | 'children'>,
    ListControllerProps {}

const CloneTheChildren: React.FC<ReferenceManyFieldViewProps> = (props) => {
  const { basePath, children, pagination, reference, ...rest } = props;
  return (
    <>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              ...sanitizeFieldRestProps(rest),
              basePath,
              resource: reference,
            })
          : null
      )}
      {pagination && props.total !== undefined && React.cloneElement(pagination)}
    </>
  );
};

const TextbookModuleReferenceManyField: React.FC<ReferenceManyFieldProps> = (props) => {
  const {
    basePath,
    filter,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
    children,
  } = props;

  const controllerProps = useReferenceManyFieldController({
    basePath: basePath ?? '',
    filter,
    page,
    perPage,
    record,
    reference,
    resource: resource ?? '',
    sort,
    source,
    target,
  });

  return (
    <ResourceContextProvider value={reference}>
      <ListContextProvider value={controllerProps}>
        <CloneTheChildren {...props} {...controllerProps}>
          {controllerProps.selectedIds.length > 0 ? <BulkActions /> : null}
          {children}
        </CloneTheChildren>
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

TextbookModuleReferenceManyField.defaultProps = {
  filter: {},
  perPage: 25,
  sort: { field: 'id', order: 'DESC' },
  source: 'id',
  addLabel: true,
};

export default TextbookModuleReferenceManyField;
