import React from 'react';
import {
  Filter,
  FunctionField,
  Datagrid,
  List,
  SelectInput,
  TextField,
  TextInput,
  EditButton,
} from 'react-admin';
import { ACT_COUNTRIES, ACT_SUBTYPES, DOCUMENT_TYPES } from '../../../state/legalDocuments/types';
import { formatLegalReference } from '../../../util/legalIdentifierUtils';

const Filters: React.FC<any> = (props) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput label="Søk på id" source="query" alwaysOn />
    <SelectInput
      alwaysOn
      label="resources.legalDocuments.actions.filterByCountryCode"
      source="countryCode"
      choices={ACT_COUNTRIES}
      optionText="name"
      optionValue="id"
    />
    <SelectInput
      alwaysOn
      label="Dokumenttype"
      source="documentType"
      choices={DOCUMENT_TYPES}
      optionText="name"
      optionValue="id"
    />
    <SelectInput
      alwaysOn
      label="Subtype"
      source="subtype"
      choices={ACT_SUBTYPES}
      optionText="name"
      optionValue="id"
    />
  </Filter>
);

export const LegalWorkList: React.FC<{}> = (props) => {
  return (
    <List
      basePath="legalWorks"
      resource="legalWorks"
      filters={<Filters />}
      filterDefaultValues={{ countryCode: 'no' }}
    >
      <Datagrid rowClick="show">
        <FunctionField
          label="resources.legalDocuments.fields.work.identifier"
          render={(record: any) => formatLegalReference(record)}
        />
        <TextField
          label="resources.legalDocuments.fields.work.listingTitle"
          source="listingTitle"
        />
        <TextField label="resources.legalDocuments.fields.work.countryCode" source="countryCode" />
        <TextField
          label="resources.legalDocuments.fields.work.documentType"
          source="documentType"
        />
        <TextField label="resources.legalDocuments.fields.work.uri" source="uri" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default LegalWorkList;
