import React from 'react';
import { DateField } from 'react-admin';

export const formatProps = {
  showTime: true,
  locales: 'nb-NO',
  options: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
};

const DateTimeField: React.FC<{
  source: string;
  sortable?: boolean;
  className?: string;
  addLabel?: boolean;
  emptyText?: string;
}> = (props) => <DateField {...props} {...formatProps} addLabel />;

export default DateTimeField;
