import * as React from 'react';
import { Create, Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

import { Publisher, createEmptyPublisher } from '../../dataProvider/publishersProvider';

const PublisherForm: React.FC<{
  initialValues?: Publisher;
  record?: Publisher;
  redirect?: boolean;
}> = ({ redirect = true, record, ...props }) => {
  return (
    <SimpleForm {...props} variant="standard" margin="normal" record={record} redirect={redirect}>
      <TextInput multiline source="slug" />
      <TextInput multiline source="name" />
      <BooleanInput source="memberOfForleggerforeningen" />
    </SimpleForm>
  );
};

export const PublisherCreate: React.FC<any> = (props) => (
  <Create {...props} title={<span>Nytt forlag</span>}>
    <PublisherForm initialValues={createEmptyPublisher()} />
  </Create>
);

const EditTitle: React.FC<{ record?: Publisher }> = ({ record: publisher }) => (
  <span>Rediger forlag {`"${publisher!!.name}"`}</span>
);

export const PublisherEdit: React.FC<any> = (props) => (
  <Edit undoable={false} title={<EditTitle />} {...props}>
    <PublisherForm />
  </Edit>
);
