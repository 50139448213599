import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useNotify, useGetOne, Button } from 'react-admin';
import { LegalChange, LegalChangesSuggestion } from '../../../state/legalDocuments/types';
import { ReactElement, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Check } from '@material-ui/icons';
import { getExternalLink } from './LegalChangeList';

export const LegalChangesSuggest: React.FC<{
  uri: string;
  labels: Map<string, string>;
  applySuggestionCallback: (changes: LegalChange[]) => void;
}> = ({ uri, labels, applySuggestionCallback }) => {
  const [isSuggestLoading, setIsSuggestLoading] = useState<boolean>(true);
  const [suggestion, setSuggestion] = useState<LegalChangesSuggestion | undefined>(undefined);
  const notify = useNotify();

  useGetOne('legalChangesSuggest', uri, {
    onSuccess: (val) => {
      setIsSuggestLoading(false);
      setSuggestion(val.data.suggestion);
    },
    onFailure: (err) => {
      notify(`Noe gikk galt: ${err}`, 'error');
      setIsSuggestLoading(false);
    },
  });

  const toText = (fragments: { id: string; type: string }[], filterType: string): ReactElement => {
    return (
      <ul style={{ listStyle: 'none' }}>
        {fragments
          .filter((f) => f.type === filterType)
          .map((f) => (
            <li>{labels.get(f.id) || f.id}</li>
          ))}
      </ul>
    );
  };

  const getSuggestionType = (
    suggestion: LegalChangesSuggestion
  ): 'unknown' | 'complete' | 'ambiguous' => {
    switch (suggestion.amendments.length) {
      case 0:
        return 'unknown';
      case 1:
        return 'complete';
      default:
        return 'ambiguous';
    }
  };

  const toLegalChange = (suggestion: LegalChangesSuggestion): LegalChange[] => {
    if (suggestion.amendments.length === 0) {
      return [
        {
          subjectUri: null,
          fragments: suggestion.fragments.map((f) => f.id),
        },
      ];
    } else {
      return suggestion.amendments.map((a) => ({
        subjectUri: a.uri,
        fragments: suggestion.fragments.map((f) => f.id),
      }));
    }
  };

  return (
    <>
      <br />
      <br />
      {isSuggestLoading && !suggestion && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '1rem' }}>
          <CircularProgress />
        </div>
      )}
      {!isSuggestLoading && !suggestion && (
        <div>
          <h2>:[</h2>
        </div>
      )}
      {suggestion && (
        <>
          {suggestion.amendments.length > 0 && (
            <>
              <h4>Endrende lover funnet fra Lovtidend</h4>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>URI</TableCell>
                    <TableCell>Tittel</TableCell>
                    <TableCell>Lovtidend-link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {suggestion.amendments.map((it) => (
                    <TableRow key={it.uri}>
                      <TableCell>{it.id}</TableCell>
                      <TableCell>{it.uri}</TableCell>
                      <TableCell>{it.title}</TableCell>
                      <TableCell>
                        {getExternalLink(it.lovtidendLink.split('://')[1], it.lovtidendLink)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <h4>Endrede paragrafer funnet i XML-dokumentene</h4>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Lagt til</TableCell>
                <TableCell>{toText(suggestion.fragments, 'create')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Endret</TableCell>
                <TableCell>{toText(suggestion.fragments, 'update')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Fjernet</TableCell>
                <TableCell>{toText(suggestion.fragments, 'delete')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <br />
          <Button
            style={{ margin: '1rem' }}
            startIcon={<Check />}
            label={
              'Bruk forslag' +
              (getSuggestionType(suggestion) !== 'complete' ? ' (Må redigeres)' : '')
            }
            onClick={() => {
              applySuggestionCallback(toLegalChange(suggestion));
            }}
            variant="contained"
          />
          {getSuggestionType(suggestion) === 'unknown'
            ? ' - Uvisst hvilke lover som forårsaket endringen'
            : ''}
          {getSuggestionType(suggestion) === 'ambiguous'
            ? ' - Uvisst hvilke endringer som tilhører hvilke lover'
            : ''}
        </>
      )}
    </>
  );
};
