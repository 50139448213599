import { createStandardAction, createAction } from 'typesafe-actions';

import { CommentaryMetadataForAllExpressionFragments } from '../expressions/types';
import { Commentary } from './types';

export const getListOfAllExpressionsWithComment = createAction(
  'GET_LIST_OF_ALL_EXPRESSIONS_WITH_COMMENTS',
  (action) => (id: string) => action({ id })
);

export const getListOfAllExpressionsWithCommentSuccess = createStandardAction(
  'GET_LIST_OF_ALL_EXPRESSIONS_WITH_COMMENTS_SUCCESS'
).map((data: { id: string; expressionsWithComments: string[] }) => ({ payload: { data } }));

export const getListOfAllExpressionsWithCommentFailure = createStandardAction(
  'GET_LIST_OF_ALL_EXPRESSIONS_WITH_COMMENTS_FAILURE'
).map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.expressions.notification.get_all_expressions_with_comment_failure',
      level: 'warning',
    },
  },
}));

export const createFromLatestPublished = createStandardAction('CREATE_COMMENTARY').map(
  (
    payload: { expressionUri: string; fragmentId: string; editor: string },
    meta: { successCallback(): void }
  ) => ({
    payload,
    meta: {
      onSuccess: {
        callback: meta.successCallback,
      },
    },
  })
);

export const createCommentarySuccess = createStandardAction('CREATE_COMMENTARY_SUCCESS').map(
  (successCallback: () => void) => ({
    payload: {},
    meta: {
      notification: {
        body: 'resources.commentaries.notification.create_commentary_success',
        level: 'info',
      },
      callback: successCallback,
    },
  })
);

export const createCommentaryFailure = createStandardAction('CREATE_COMMENTARY_FAILURE').map(
  () => ({
    payload: {},
    meta: {
      notification: {
        body: 'resources.expressions.notification.create_commentary_failure',
        level: 'warning',
      },
    },
  })
);

export const getCommentary = createStandardAction('GET_COMMENTARY').map(
  (
    payload: { expressionUri: string; fragmentId: string; publicationStatus?: string },
    meta: { successCallback(): void; createNewCommentaryCallback(): void }
  ) => ({
    payload,
    meta: {
      onSuccess: {
        callback: meta.successCallback,
      },
      onFailure: {
        notification: {
          body: 'resources.commentaries.notification.get_commentary_failure',
          level: 'warning',
        },
        callback: meta.createNewCommentaryCallback,
      },
    },
  })
);

export const getCommentarySuccess = createStandardAction('GET_COMMENTARY_SUCCESS').map(
  (
    body: Commentary,
    meta: {
      successCallback(): void;
    }
  ) => ({
    payload: { data: body },
    meta: {
      callback: meta.successCallback,
    },
  })
);

export const getCommentaryFailure = createStandardAction('GET_COMMENTARY_FAILURE').map(
  (data: {
    meta: {
      notification?: {
        body: string;
        level: string;
      };
      callback: () => void;
    };
    error: {
      status: string;
      message: string;
    };
  }) => ({
    payload: {},
    meta: data.meta,
    error: data.error,
  })
);

export const getCommentaryMeta = createAction(
  'GET_COMMENTARY_META',
  (action) => (expressionUri: string) => action({ expressionUri })
);

export const getCommentaryMetaSuccess = createStandardAction(
  'GET_COMMENTARY_META_SUCCESS'
).map(
  (commentaryMeta: {
    expressionUri: string;
    fragments: CommentaryMetadataForAllExpressionFragments;
  }) => ({ payload: { commentaryMeta } })
);

export const getCommentaryMetaFailure = createStandardAction('GET_COMMENTARY_META_FAILURE').map(
  () => ({
    payload: {},
    meta: {
      notification: {
        body: 'resources.commentaries.notification.get_commentary_meta_failure',
        level: 'warning',
      },
    },
  })
);

export const publishComments = createStandardAction('PUBLISH_COMMENTARY').map(
  (
    payload: { expressionUri: string; fragmentIds: string[]; editor: string },
    successCallback: () => void
  ) => ({
    payload,
    meta: {
      onSuccess: {
        callback: () => successCallback(),
      },
    },
  })
);

export const publishCommentarySuccess = createStandardAction('PUBLISH_COMMENTARY_SUCCESS').map(
  (isMultiple: boolean, successCallback: () => void) => ({
    payload: {},
    meta: {
      callback: () => successCallback(),
      notification: {
        body: isMultiple
          ? 'resources.commentaries.notification.publish_commentaries_success'
          : 'resources.commentaries.notification.publish_commentary_success',
        level: 'info',
      },
    },
  })
);

export const publishCommentaryFailure = createStandardAction('PUBLISH_COMMENTARY_FAILURE').map(
  (data: {
    isMultiple: boolean;
    error: {
      detailMessage: string | null;
    };
  }) => ({
    payload: {},
    meta: {
      notification: {
        body: data.isMultiple
          ? 'resources.commentaries.notification.publish_commentaries_failure'
          : 'resources.commentaries.notification.publish_commentary_failure',
        messageArgs: {
          msg: data.error.detailMessage ? '. ' + data.error.detailMessage : '',
        },
        level: 'warning',
      },
    },
  })
);

export const resetCommentary = createAction('RESET_COMMENTARY', (action) => () => action({}));

export const saveCommentary = createStandardAction('SAVE_COMMENTARY').map(
  (
    payload: {
      expressionUri: string;
      fragmentId: string;
      xmlDocument: XMLDocument;
      editor: string;
    },
    successCallback: () => void
  ) => ({
    payload,
    meta: {
      onSuccess: {
        callback: () => successCallback(),
      },
    },
  })
);

export const saveCommentarySuccess = createStandardAction('SAVE_COMMENTARY_SUCCESS').map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.commentaries.notification.save_commentary_success',
      level: 'info',
    },
  },
}));

export const saveCommentaryFailure = createStandardAction('SAVE_COMMENTARY_FAILURE').map(() => ({
  payload: {},
}));

export const overwriteLastReviewed = createStandardAction('OVERWRITE_LAST_REVIEWED').map(
  (
    payload: { expressionUri: string; fragmentIds: string[]; editor: string; date: string },
    successCallback: () => void
  ) => ({
    payload,
    meta: {
      onSuccess: {
        callback: () => successCallback(),
      },
    },
  })
);

export const overwriteLastReviewedSuccess = createStandardAction(
  'OVERWRITE_LAST_REVIEWED_SUCCESS'
).map((successCallback: () => void) => ({
  payload: {},
  meta: {
    callback: () => successCallback(),
    notification: {
      body: 'resources.commentaries.notification.overwrite_last_reviewed_success',
      level: 'info',
    },
  },
}));

export const overwriteLastReviewedFailure = createStandardAction(
  'OVERWRITE_LAST_REVIEWED_FAILURE'
).map((errorMessage: string | null) => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.commentaries.notification.overwrite_last_reviewed_failure',
      messageArgs: {
        msg: errorMessage,
      },
      level: 'error',
    },
  },
}));
