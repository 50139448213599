import * as React from 'react';
import * as Slate from 'slate';
import { Button } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import PublishIcon from '@material-ui/icons/Publish';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';

import { IFrameResult, parseHtmlIframe } from './utils';
import * as tags from '../../tags';

const IFrameDialog: React.FC<{
  editor: Slate.Editor;
  editorSelectionPath: Slate.Path;
  onClose: () => void;
}> = (props) => {
  const [iFrameResult, setIFrameResult] = React.useState<IFrameResult>({ error: 'Lim inn HTML' });
  const [html, setHtml] = React.useState('');

  const updateHtml = React.useCallback((html: string) => {
    setHtml(html);
    setIFrameResult(parseHtmlIframe(html));
  }, []);

  const handleSubmit = () => {
    if (!iFrameResult.iframeProps) return;
    Slate.Transforms.insertNodes(
      props.editor,
      [
        {
          type: tags.BLOCK_IFRAME,
          src: iFrameResult.iframeProps.src,
          srcDoc: iFrameResult.iframeProps.srcDoc,
          width: iFrameResult.iframeProps.width,
          height: iFrameResult.iframeProps.height,
          sandbox: iFrameResult.iframeProps.sandbox,
          children: [{ text: '' }],
        },
        {
          type: tags.BLOCK_PARAGRAPH,
          children: [{ text: '' }],
        },
      ],
      {
        at: props.editorSelectionPath,
      }
    );
    props.onClose();
  };

  return (
    <Dialog
      open={!!props.editorSelectionPath}
      onClose={props.onClose}
      aria-label="IFrame"
      fullWidth
    >
      <DialogTitle>Sett inn embedded html (iframe)</DialogTitle>
      <DialogContent>
        {!!iFrameResult.iframeProps && (
          <iframe title={iFrameResult.iframeProps.name || ''} {...iFrameResult.iframeProps} />
        )}
        {!iFrameResult.iframeProps && (
          <div
            style={{
              width: '100%',
              height: '200px',
              backgroundColor: '#888',
            }}
          />
        )}
        <br />
        <TextField
          id="iframe-html"
          label="Lim inn iframe HTML"
          error={!!iFrameResult.error}
          helperText={iFrameResult.error ? <>{iFrameResult.error}</> : null}
          fullWidth
          value={html}
          onChange={(event) => updateHtml(event.target.value)}
        />
        <DialogContentText style={{ marginTop: '20px' }}>
          Tips: Standardhøyde brukt tidligere for SoundCloud er 166. Sett høyden selv inni
          HTML-koden.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={props.onClose}>
          <CancelIcon />
        </Button>
        <Button label="Ok" onClick={handleSubmit}>
          <PublishIcon />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IFrameDialog;
