import * as React from 'react';
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  DateInput,
  SelectInput,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

import plaintextDocumentSpec from '../../slate/documentSpecs/plaintextSpec';

import { BreadcrumbTitle } from '../BreadcrumbTitle';
import SlateTextInput from '../SlateTextInput';

const TitlesFormIterator = withStyles({
  form: {
    paddingTop: '1.2em',
  },
})(SimpleFormIterator);

const TextbookEditionForm: React.FC<{ isNew: boolean }> = (props) => {
  return (
    <SimpleForm variant="standard" margin="normal" {...props}>
      <ReferenceInput
        label="Fagbokutgivelse"
        source="publicationId"
        reference="textbookPublications"
        disabled={!props.isNew}
        allowEmpty={false}
        fullWidth
      >
        <AutocompleteInput
          optionValue="id"
          optionText="fullTitle"
          allowEmpty={false}
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>

      <NumberInput disabled={!props.isNew} source="editionSequence" />
      <DateInput
        source="originallyPublishedAt"
        fullWidth
        label="Utgivelsesdato (kan være et fremtidsestimat for f.eks. kommende bøker)"
      />

      <SelectInput
        source="availability"
        defaultValue="ANNOUNCED"
        allowEmpty={false}
        choices={[
          { id: 'ANNOUNCED', name: 'Annonsert (kommende bok)' },
          { id: 'PREVIEW', name: 'Forhåndsvisning (delvis komplett bok)' },
          { id: 'COMPLETE', name: 'Innhold er komplett' },
        ]}
        helperText="Hvilken tilgjengelighet boken har"
      />

      <ArrayInput
        source="titles"
        fullWidth
        label={
          props.isNew
            ? 'Tittel og undertitler (la stå tom for å kopiere fra utgivelsen)'
            : undefined
        }
      >
        <TitlesFormIterator>
          <SlateTextInput
            source="title"
            documentSpec={plaintextDocumentSpec}
            singlelineStyle={false}
          />
        </TitlesFormIterator>
      </ArrayInput>

      <ReferenceInput source="publisherId" reference="publishers" fullWidth allowEmpty={false}>
        <AutocompleteInput
          optionValue="id"
          optionText="name"
          allowEmpty={false}
          options={{
            fullWidth: true,
          }}
        />
      </ReferenceInput>

      <TextInput source="isbn" />
      <TextInput source="pageCount" />
    </SimpleForm>
  );
};

export const TextbookEditionEdit: React.FC<{}> = (props) => (
  <Edit
    title={
      <BreadcrumbTitle resourceName="utgave" recordName={(record) => record.descriptiveName} />
    }
    {...props}
  >
    <TextbookEditionForm isNew={false} />
  </Edit>
);

export const TextbookEditionCreate: React.FC<{}> = (props) => (
  <Create
    title={
      <BreadcrumbTitle resourceName="utgave" recordName={(record) => record.descriptiveName} />
    }
    {...props}
  >
    <TextbookEditionForm isNew={true} />
  </Create>
);
