import React from 'react';
import {
  Filter,
  FunctionField,
  Datagrid,
  List,
  SelectInput,
  TextField,
  TextInput,
  ArrayField,
  SingleFieldList,
  ChipField,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';
import ModifyTagsButton from '../documentTags/ModifyTagsButton';
import { ACT_COUNTRIES, ACT_SUBTYPES, LegalDocument } from '../../state/legalDocuments/types';

const LegalWorkBulkActionButtons = (props: any) => {
  const { data } = useListContext<LegalDocument>();

  return (
    <React.Fragment>
      <ModifyTagsButton
        tagResource="legalWorkTags"
        getTagPathsOnSelectedIds={() => {
          const set: Set<string> = new Set();

          if (props.selectedIds) {
            props.selectedIds.forEach((id: string) => {
              data[id]?.summary?.tags?.forEach((tag: { path: string; name: string }) => {
                set.add(tag.path);
              });
            });
          }

          return set;
        }}
      />
    </React.Fragment>
  );
};

const LegalDocumentListFilter: React.FC<any> = (props) => (
  <Filter variant="standard" margin="normal" {...props}>
    <TextInput
      label="resources.legalDocuments.actions.filterByTitleOrExprId"
      source="query"
      alwaysOn
    />
    <SelectInput
      alwaysOn
      label="resources.legalDocuments.actions.filterByCountryCode"
      source="countryCode"
      choices={ACT_COUNTRIES}
      optionText="name"
      optionValue="id"
    />
    <SelectInput
      alwaysOn
      source="subtype"
      choices={ACT_SUBTYPES}
      optionText="name"
      optionValue="id"
    />
  </Filter>
);

const LegalDocumentActions: React.FC<any> = (props) => (
  <TopToolbar>
    {/*<Button*/}
    {/*  label="EUR-lex-import"*/}
    {/*  onClick={() => props.history.push('legalWorks')}*/}
    {/*  startIcon={<EuroIcon />}*/}
    {/*/>*/}
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const LegalDocumentList: React.FC = (props) => {
  return (
    <List
      actions={<LegalDocumentActions {...props} />}
      filterDefaultValues={{ countryCode: 'no' }}
      {...props}
      filters={<LegalDocumentListFilter />}
      bulkActionButtons={<LegalWorkBulkActionButtons />}
    >
      <Datagrid rowClick="show">
        <TextField
          label="resources.legalDocuments.fields.version.shortTitle"
          source="summary.shortTitle"
        />
        <FunctionField
          label="resources.legalDocuments.fields.work.identifier"
          render={(record: any) =>
            `${record?.summary?.subtype}-${record?.summary?.date}-${record?.summary?.seq}`
          }
        />
        <TextField source="summary.subtype" label="resources.legalDocuments.fields.work.subtype" />
        <ArrayField source="summary.tags" label="tags">
          <SingleFieldList>
            <ChipField source="path" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </List>
  );
};

export default LegalDocumentList;
