import * as React from 'react';
import Menu from '@material-ui/core/Menu';

import SlateToolbarButton from './SlateToolbarButton';
import { SlateInputContext } from './SlateInputContextProvider';

interface SlateSimpleMenuProps {
  isActive: boolean;
  Icon: React.ComponentType;
  message: string;
  menuItems: Array<React.ReactNode>;
}

const SlateSimpleMenu: React.FC<SlateSimpleMenuProps> = ({
  isActive,
  Icon,
  message,
  menuItems,
}) => {
  const slateInputContext = React.useContext(SlateInputContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  let ref = React.useRef({
    scrollOffsetY: window.scrollY,
  });

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    if (anchorEl) {
      ref.current.scrollOffsetY = window.scrollY;
    } else {
      window.scrollTo(0, ref.current.scrollOffsetY);
    }
  }, [anchorEl]);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const anchor = anchorEl ? null : event.currentTarget;

    if (anchor) slateInputContext.setActiveToolbarItemVisible(true);

    setAnchorEl(anchor);
  };

  const handleMenuOptionSelect = (event: React.MouseEvent<HTMLElement>, onClick?: () => void) => {
    event.preventDefault();

    onClick?.();

    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <SlateToolbarButton
        isActive={isActive}
        Icon={Icon}
        message={message}
        onMouseDown={toggleMenu}
        aria-owns={open ? 'simple-menu' : null}
        aria-haspopup="true"
      />
      {menuItems.length > 0 && (
        <Menu
          id="simple-menu"
          disableAutoFocus={true}
          disableAutoFocusItem={true}
          anchorEl={anchorEl}
          open={open}
          onClose={toggleMenu}
        >
          {menuItems.map((item: any) =>
            React.cloneElement(item, {
              onMouseDown: (e: React.MouseEvent<HTMLElement>) =>
                handleMenuOptionSelect(e, item.props.onMouseDown),
            })
          )}
        </Menu>
      )}
    </React.Fragment>
  );
};

export default SlateSimpleMenu;
