import * as React from 'react';
import { RouteChildrenProps } from 'react-router';
import * as queryString from 'query-string';

// Internal components
import FragmentSelect from '../../containers/CommentariesLinking/FragmentSelect';
import { convertKeyToActExprId } from '../../util/legalIdentifierUtils';
import { VersionStatus } from '../../state/legalDocuments/types';

interface RoutePathParams {
  type: string;
  identifier: string;
  inForceAt: string;
}

const CommentLinkingPage: React.FC<RouteChildrenProps> = (props) => {
  if (!props.match) return null;
  const { type, identifier, inForceAt } = props.match.params as RoutePathParams;
  const { status } = queryString.parse(props.location.search);

  return (
    <div>
      <FragmentSelect
        exprId={convertKeyToActExprId(type, identifier, inForceAt)}
        actStatus={(status as VersionStatus) || 'published'}
      />
    </div>
  );
};

export default CommentLinkingPage;
