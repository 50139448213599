import { CommentaryLinkingStatus } from './types';
import { createAction } from 'typesafe-actions';
import { LinkInjectorResults } from '../../models/LinkInjectorModels';
import { LegalExpressionIdentifier } from '../legalDocuments/types';

export const getCommentInjectStatuses = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_STATUS',
  (action) => {
    return (exprId: LegalExpressionIdentifier) => action({ exprId });
  }
);

export const getCommentInjectStatusesSuccess = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_STATUS_SUCCESS',
  (action) => {
    return (exprId: LegalExpressionIdentifier, statuses: CommentaryLinkingStatus) =>
      action({ exprId, statuses });
  }
);

export const getCommentInjectStatusesFailed = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_STATUS_FAILED',
  (action) => {
    return (exprId: LegalExpressionIdentifier, message: string) => action({ exprId, message });
  }
);

export const getCommentInjectResult = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_RESULTS',
  (action) => {
    return (exprId: LegalExpressionIdentifier, fragmentId: string) =>
      action({ exprId, fragmentId });
  }
);

export const getCommentInjectResultSuccess = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_RESULTS_SUCCESS',
  (action) => {
    return (exprId: LegalExpressionIdentifier, fragmentId: string, results: LinkInjectorResults) =>
      action({ exprId, fragmentId, results });
  }
);

export const getCommentInjectResultFailed = createAction(
  '@@commentaryLinking/COMMENT_FETCH_INJECT_RESULTS_FAILED',
  (action) => {
    return (exprId: LegalExpressionIdentifier, fragmentId: string, message: string) =>
      action({ exprId, fragmentId, message });
  }
);

export const injectComment = createAction('@@commentaryLinking/COMMENT_INJECT_LINKS', (action) => {
  return (
    exprId: LegalExpressionIdentifier,
    fragmentId: string,
    injectCommentBody: boolean,
    injectCuratedSources: boolean,
    currentLoggedUser: string
  ) =>
    action({
      exprId,
      fragmentId,
      injectCommentBody,
      injectCuratedSources,
      currentLoggedUser,
    });
});

export const injectCommentCancel = createAction(
  '@@commentaryLinking/COMMENT_INJECT_LINKS_CANCEL',
  (action) => {
    return (exprId: LegalExpressionIdentifier, fragmentId: string) =>
      action({ exprId, fragmentId });
  }
);
