import { useEffect } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';

import * as currentUserReducers from '../../state/currentUser/currentUserReducers';
import * as currentUserActions from '../../state/currentUser/currentUserActions';
import auth from '../../auth';

interface dispatchProps {
  setCurrentUser: currentUserReducers.CurrentUserAction;
}

const CurrentUserInitializer = ({ currentUser, children, setCurrentUser }: any) => {
  useEffect(() => {
    const isAuthenticated = auth.isAuthenticated();

    if (isAuthenticated && !currentUser) {
      const idToken = localStorage.getItem('id_token') || '';
      const { name } = jwtDecode(idToken);

      setCurrentUser({ name });
    }
  });

  return children;
};

const mapStateToProps = (state: currentUserReducers.CurrentUserState) => {
  return {
    currentUser: state.currentUser,
  };
};

const dispatchProps = {
  setCurrentUser: currentUserActions.setCurrentUser,
};

export default connect(mapStateToProps, dispatchProps)(CurrentUserInitializer);
