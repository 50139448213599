import { createMuiTheme } from '@material-ui/core/styles';

const theme = (mainColor) =>
  createMuiTheme({
    palette: {
      primary: {
        light: '#4f5b62',
        main: mainColor,
        dark: '#000a12',
        contrastText: '#fff',
      },
      secondary: {
        light: '#efefef',
        main: '#bdbdbd',
        dark: '#8d8d8d',
        contrastText: '#000',
      },
    },
  });

export default theme;
