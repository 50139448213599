import URI from 'urijs';
import { GET_LIST, GET_ONE, fetchUtils } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders } from './apiUtil';

export const createProvider = (urls: Types.BackendUrls) => {
  const BASE_URL = URI(urls.juridikaSubscriptionBackend)
    .segment('admin')
    .segment('v1')
    .segment('stripe')
    .toString();

  const convertDataProviderRequestToHTTP = (
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    switch (type) {
      case GET_ONE:
      case GET_LIST: {
        return {
          url: URI(BASE_URL).segment('products').toString(),
          options: {
            method: 'GET',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  const convertHTTPResponseToDataProvider = (
    response: any,
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    const { json } = response;

    switch (type) {
      case GET_ONE: {
        return {
          data: json.find((product: any) => product.id === params.id),
          total: json.length,
        };
      }

      case GET_LIST: {
        return {
          data: json,
          total: json.length,
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  const performDataProviderRequestViaHttp = (
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    const { fetchJson } = fetchUtils;
    const { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);

    const response = fetchJson(url, options).then((response) =>
      convertHTTPResponseToDataProvider(response, type, resource, params)
    );

    return response;
  };

  return performDataProviderRequestViaHttp;
};
