import React, { useEffect } from 'react';
import { useDispatch, TypedUseSelectorHook, useSelector as useSelectorRedux } from 'react-redux';
import { FunctionField, Show, ShowProps, Tab, TabbedShowLayout, TextField } from 'react-admin';

import { LegalDocument } from '../../state/legalDocuments/types';

import * as commentaryActions from '../../state/commentary/commentaryActions';
import { ExpressionState } from '../../state/expressions/expressionReducers';
import { CurrentUser } from '../../state/currentUser/types';
import { formatLegalReference, uriToWorkIdentifier } from '../../util/legalIdentifierUtils';
import ExpressionsTable from './components/ExpressionsTable';
import { encode } from 'urijs';
import { Link } from 'react-router-dom';

interface ReduxState {
  expressionsWithCommentariesLoading: boolean;
  expressions: ExpressionState;
  currentUser: CurrentUser | null;
}

const useSelector: TypedUseSelectorHook<ReduxState> = useSelectorRedux;

const Title: React.FC<{ record?: LegalDocument }> = ({ record }) => {
  return (
    <span>
      {record?.isSummary
        ? record?.summary?.shortTitle
        : record?.work?.expressions[0].manifestations[0].shortTitle}
    </span>
  );
};

const LegalDocumentShow: React.FC<ShowProps & { record?: LegalDocument }> = (props) => {
  const dispatch = useDispatch();

  const currentUserName = useSelector((state) => state.currentUser?.name ?? '');
  const expressionsWithCommentaries = useSelector(
    (state) => state.expressions.expressionsWithCommentaries[props.id!!]
  );
  const expressionsWithCommentariesLoading = useSelector(
    (state) => state.expressions.expressionsWithCommentariesLoading
  );

  useEffect(() => {
    if (!expressionsWithCommentaries && !expressionsWithCommentariesLoading) {
      dispatch(commentaryActions.getListOfAllExpressionsWithComment(props.id!!));
    }
  });

  return (
    <Show {...props} title={<Title {...props} />}>
      <TabbedShowLayout>
        <Tab label="Utrykk">
          <FunctionField
            label="resources.legalDocuments.fields.work.identifier"
            render={() => formatLegalReference(uriToWorkIdentifier(props.id!!))}
          />
          <ExpressionsTable
            record={props.record}
            {...props}
            currentUserName={currentUserName}
            expressionsWithCommentaries={expressionsWithCommentaries}
            expressionsWithCommentariesLoading={expressionsWithCommentariesLoading}
          ></ExpressionsTable>
        </Tab>
        <Tab label="Metadata">
          <p>
            <Link to={`/legalWorks/${encode(props.id!!)}/show`}>
              Gå til tilhørende rettsreferanse
            </Link>
          </p>
          <FunctionField
            label="resources.legalDocuments.fields.work.identifier"
            render={() => formatLegalReference(uriToWorkIdentifier(props.id!!))}
          />
          <FunctionField label="resources.legalDocuments.fields.work.uri" render={() => props.id} />
          <TextField
            label="resources.legalDocuments.fields.work.countryCode"
            source="work.countryCode"
          />
          <TextField
            label="resources.legalDocuments.fields.work.documentType"
            source="work.documentType"
          />
          <TextField label="resources.legalDocuments.fields.work.subtype" source="work.subtype" />
          <TextField label="resources.legalDocuments.fields.work.date" source="work.date" />
          <TextField label="resources.legalDocuments.fields.work.seq" source="work.seq" />
          <TextField
            label="resources.legalDocuments.fields.work.activeSourceSystem"
            source="work.activeSourceSystem"
          />
          <TextField
            label="resources.legalDocuments.fields.work.isHistoryEnabled"
            source="work.isHistoryEnabled"
          />
          <TextField
            label="resources.legalDocuments.fields.work.listingTitle"
            source="work.listingTitle"
          />
          <TextField
            label="resources.legalDocuments.fields.work.favoriteCount"
            source="work.favoriteCount"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default LegalDocumentShow;
