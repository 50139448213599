import * as React from 'react';
import * as SlateReact from 'slate-react';
import { Plugin } from './types';

const Boxed: React.FC<{ children: React.ReactElement; style?: React.CSSProperties }> = ({
  children,
  ...props
}) => {
  const isSelected = SlateReact.useSelected();
  return React.cloneElement(children, {
    style: {
      ...(isSelected
        ? { background: 'rgb(248 247 249)', transition: 'background 0.25s ease' }
        : {}),
      ...props.style,
    },
  });
};

export default (types: string[]): Plugin => {
  return {
    key: 'box-outline-plugin',
    renderElement: (renderProps: SlateReact.RenderElementProps, element) => {
      if (!types.includes(renderProps.element.type as string)) return element;
      return <Boxed>{element}</Boxed>;
    },
  };
};
