import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  NumberField,
  DateField,
} from 'react-admin';

export const UserExpandShow: React.FC<{}> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="customerNumber" />
        <BooleanField source="emailVerified" />
        <BooleanField source="termsAccepted" />
        <DateField source="lastLogin" />
        <NumberField source="loginCount" />
        <TextField source="customerId" />
      </SimpleShowLayout>
    </Show>
  );
};
