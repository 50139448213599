import * as t from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface EditorAccount {
  id: string;
  name: string;
}

const ApiIncomingTypeV = t.type({
  id: t.string,
  name: t.string,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<EditorAccount> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/editorAccounts`,
    halListName: 'adminEditorAccountList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (x) => x,
    toApiMapper: (x) => x,
    toApiPartialMapper: (x) => x,
    referenceParams: {},
    filterParams: {},
    sortFields: [],
  });
