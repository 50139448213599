import * as TypesafeActions from 'typesafe-actions';
import { EmbeddedForeignType } from '../../dataProvider/embeddedSnapshotsProvider';

export interface PageSnapshotInfo {
  snapshotType: 'page';
  slug: string | null;
}

export interface NoticeSnapshotInfo {
  snapshotType: 'notice';
}

export interface EmbeddedSnapshotInfo {
  snapshotType: 'embedded';
  foreignType: EmbeddedForeignType;
  foreignId: string;
}

export type SnapshotInfo = PageSnapshotInfo | NoticeSnapshotInfo | EmbeddedSnapshotInfo;

export const publishStaticContentRevision = TypesafeActions.createAsyncAction(
  '@@publishing/PUBLISH_STATIC_CONTENT_REVISION_REQUEST',
  '@@publishing/PUBLISH_STATIC_CONTENT_REVISION_SUCCESS',
  '@@publishing/PUBLISH_STATIC_CONTENT_REVISION_FAILURE'
)<
  {
    id: string;
    snapshotInfo: SnapshotInfo;
    date: Date;
  },
  {
    id: string;
  },
  {
    id: string;
    error: string;
  }
>();

export const unpublishStaticContentRevision = TypesafeActions.createAsyncAction(
  '@@publishing/UNPUBLISH_STATIC_CONTENT_REVISION_REQUEST',
  '@@publishing/UNPUBLISH_STATIC_CONTENT_REVISION_SUCCESS',
  '@@publishing/UNPUBLISH_STATIC_CONTENT_REVISION_FAILURE'
)<
  {
    id: string;
  },
  {
    id: string;
  },
  {
    id: string;
    error: string;
  }
>();

export const publishTextbookEdition = TypesafeActions.createAsyncAction(
  '@@publishing/PUBLISH_TEXTBOOK_EDITION_REQUEST',
  '@@publishing/PUBLISH_TEXTBOOK_EDITION_SUCCESS',
  '@@publishing/PUBLISH_TEXTBOOK_EDITION_FAILURE'
)<
  {
    id: string;
    juridikaPublishedAt: Date;
    documentFilter: 'rootOnly' | 'all';
  },
  {
    id: string;
  },
  {
    id: string;
    error: string;
  }
>();

export const unPublishTextbookEdition = TypesafeActions.createAsyncAction(
  '@@publishing/UNPUBLISH_TEXTBOOK_EDITION_REQUEST',
  '@@publishing/UNPUBLISH_TEXTBOOK_EDITION_SUCCESS',
  '@@publishing/UNPUBLISH_TEXTBOOK_EDITION_FAILURE'
)<
  {
    id: string;
  },
  {
    id: string;
  },
  {
    id: string;
    error: string;
  }
>();

export const publishJournalArticles = TypesafeActions.createAsyncAction(
  '@@publishing/PUBLISH_JOURNAL_ARTICLES_REQUEST',
  '@@publishing/PUBLISH_JOURNAL_ARTICLES_SUCCESS',
  '@@publishing/PUBLISH_JOURNAL_ARTICLES_FAILURE'
)<
  {
    ids: Array<string>;
    juridikaPublishedAt: Date;
  },
  {
    ids: Array<string>;
  },
  {
    ids: Array<string>;
    error: string;
  }
>();
