import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import auth0 from 'auth0-js';
import { auth0Domain, auth0CmsClientId } from './auth0Constants';

export const clearLocalStorageTokens = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
};

class Auth {
  webAuth0 = new auth0.WebAuth({
    clientID: auth0CmsClientId,
    domain: auth0Domain,
    scope: 'openid profile juridikaUserId',
    responseType: 'token id_token',
    redirectUri: `${window.location.origin}/auth/callback`,
    audience: 'juridika',
  });

  reactAdminAuthProvider(type, params) {
    console.log('Auth provider: ', type, params);
    if (type === AUTH_LOGIN) {
      return Promise.resolve();
    } else if (type === AUTH_LOGOUT) {
      clearLocalStorageTokens();
      return Promise.resolve();
    } else if (type === AUTH_CHECK) {
      return this.checkAuthPromise();
    } else if (type === AUTH_GET_PERMISSIONS) {
      return Promise.resolve();
    }
    return Promise.resolve();
  }

  login() {
    this.webAuth0.authorize();
    //console.log('LOGIN!');
  }

  handleAuthentication() {
    this.authPromise = new Promise((resolve, reject) => {
      this.webAuth0.parseHash((err, authResult) => {
        console.log('parseHash result: ', err, authResult);
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          this.authPromise = null;
          resolve();
        } else if (err) {
          console.log(err);
          this.authPromise = null;
          reject();
        }
      });
    });
    return this.authPromise;
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  checkAuthPromise() {
    if (this.authPromise) {
      return this.authPromise.then(() =>
        this.isAuthenticated() ? Promise.resolve() : Promise.reject()
      );
    }
    return this.isAuthenticated() ? Promise.resolve() : Promise.reject();
  }
}

export default new Auth();
