import React from 'react';
import { Create } from 'react-admin';

import { CustomerForm } from './components/CustomerForm';
import { createEmptyCustomer } from '../../dataProvider/customersProvider';

const Title = () => <span>Opprett ny kunde</span>;

export const CustomerCreate = (props: any) => (
  <Create {...props} title={<Title />}>
    <CustomerForm initialValues={createEmptyCustomer()} />
  </Create>
);
