import h from 'hastscript';
import * as t from 'io-ts';

import * as Types from './types';
import { parseNullableDate, halJsonCrudApiProvider } from './apiUtil';

import {
  BaseDocumentSnapshot,
  BaseDocumentSnapshotApiIncomingTypeV,
} from './models/staticContentCommonModels';

import {
  StaticDocumentRevisionOutgoingApiType,
  mapApiRevisionToFrontend,
  toApiMapper as staticDocumentRevisionToApiMapper,
} from './staticDocumentRevisionsProvider';

export interface NoticeSnapshotFeedConnection {
  feedId: string;
  pinned: boolean;
}

export interface NoticeSnapshot extends BaseDocumentSnapshot {
  linkedPage: string | null;
  customLink: string | null;
  pinnedInAllFeeds: boolean;
  feeds: Array<string>;
}

export const createEmptyNoticeSnapshot = (): NoticeSnapshot => ({
  id: '',
  createdById: '',
  currentEditorId: '',
  currentEditorIsCurrentUser: true,
  firstPublishedAt: null,
  lastPublishedAt: null,
  revision: {
    id: '',
    documentId: '',
    createdAt: new Date(),
    createdById: '',
    publishedAt: null,
    contentCategoryId: '',
    title: '',
    hast: h('fragment', {}, []),
    contributions: [],
    tags: [],
  },
  linkedPage: null,
  customLink: null,
  pinnedInAllFeeds: false,
  feeds: [],
});

const ApiIncomingTypeV = t.intersection([
  BaseDocumentSnapshotApiIncomingTypeV,
  t.type({
    linkedPage: t.union([t.string, t.null]),
    customLink: t.union([t.string, t.null]),
    feeds: t.array(
      t.type({
        feedId: t.string,
        pinned: t.boolean,
      })
    ),
  }),
]);
type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  revision: StaticDocumentRevisionOutgoingApiType;
  linkedPage: string | null;
  customLink: string | null;
  feeds: Array<NoticeSnapshotFeedConnection>;
}

export const toApiMapper = (snapshot: NoticeSnapshot): ApiOutgoingType => ({
  revision: staticDocumentRevisionToApiMapper(snapshot.revision),
  linkedPage: snapshot.linkedPage,
  customLink: snapshot.customLink,
  feeds: snapshot.feeds.map((feedId) => ({
    feedId,
    pinned: snapshot.pinnedInAllFeeds,
  })),
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<NoticeSnapshot> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/noticeSnapshots`,
    halListName: 'adminNoticeSnapshotList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiSnapshot: ApiIncomingType): NoticeSnapshot => ({
      id: apiSnapshot.id,
      createdById: apiSnapshot.createdById,
      currentEditorId: apiSnapshot.currentEditorId,
      currentEditorIsCurrentUser: apiSnapshot.currentEditorIsCurrentUser,
      revision: mapApiRevisionToFrontend(apiSnapshot.revision),
      firstPublishedAt: parseNullableDate(apiSnapshot.firstPublishedAt),
      lastPublishedAt: parseNullableDate(apiSnapshot.lastPublishedAt),
      linkedPage: apiSnapshot.linkedPage,
      customLink: apiSnapshot.customLink,
      feeds: apiSnapshot.feeds.map((feed) => feed.feedId),
      pinnedInAllFeeds: apiSnapshot.feeds.every((feed) => feed.pinned),
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {
      linkedPage: 'linkedPageId',
    },
    filterParams: {
      q: (q) => ({ q }),
      revision: {
        contentCategoryId: (id) => ({ contentCategoryId: id }),
      },
      linkedPage: (id) => ({ linkedPageId: id }),
    },
    sortFields: [],
  });
