import { Modal as MUIModal, Button as MUIButton, Icon, Input as MUIInput } from '@material-ui/core';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import styles from './CreateLinkModal.module.css';

const CreateLinkModal: FC<{
  onSave: (url: string) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen, onSave }) => {
  const closeModal = () => setOpen(false);
  const [value, setValue] = useState<string>('');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  const handleOnSave = () => {
    onSave(value);
    closeModal();
  };

  return (
    <MUIModal open={open} onClose={closeModal} className={styles.modal}>
      <div className={styles.modalContent}>
        <MUIButton
          variant="outlined"
          color="secondary"
          size="small"
          onClick={closeModal}
          className={styles.closeButton}
        >
          <Icon>close</Icon>
        </MUIButton>
        <h2>Lag en lenke</h2>

        <section className={styles.section}>
          <small className={styles.helperText}>Skriv adressen lenken skal gå til</small>
          <MUIInput value={value} onChange={(e) => handleChange(e.target.value)} />
        </section>

        <MUIButton
          variant="contained"
          color="primary"
          onClick={handleOnSave}
          className={styles.saveButton}
        >
          Lagre
        </MUIButton>
      </div>
    </MUIModal>
  );
};

export default CreateLinkModal;
