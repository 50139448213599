import React, { FC } from 'react';
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
  Edit,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import NIKImage from '../../images/nik_publication_image_1.png';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  aside: {
    marginLeft: '1em',
    padding: '1em',
    backgroundColor: '#f5f5f5',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    maxWidth: '650px',
    '& p': {
      fontSize: '14px',
    },
  },
  list: {
    fontSize: '10px',
    paddingLeft: '1em',
  },
});

const Aside: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.aside}>
      <h2>Hjelpepanel</h2>
      <p>
        Du må fylle inn alle felter som er merket med asteriks, men best praksis er å fylle inn
        alle.
      </p>
      <img src={NIKImage} alt="" style={{ boxShadow: '1px 1px 8px 1px #0000004a' }} />
      <h3>Slug</h3>
      <p>
        Det viktigste feltet i denne opprettelsen er <em>slug</em>-en. Dette er fordi at slug-en
        ender opp som en del av adressen (URL) til selve problemstillingen på Juridika.no, og hvis
        vi må endre den i etterkant av publikasjon så vil alle lenkene vi kanskje har postet på
        f.eks SoMe dirigere trafikk til en <em>død</em> side. Ta deg god tid til å velge en unik og
        forståelig slug etter beste praksis. Om du aldri har laget en før så ta kontakt med en
        kollega, det er en del hensyn man burde ta når man skal lage en god slug.
      </p>
      <p>
        Slug må være unik. Opprettelsen av problemstilling vil feile om slug-en finnes i databasen
        fra før.
      </p>
      <p>
        https://juridika.no/problemstilling/<mark>din-slug</mark>
      </p>
      <h3>Beskrivelse</h3>
      <p>
        Akkurat nå vil beskrivelsen bli avbrutt med ellipsis (...) når den brekker over til to
        linjer.
      </p>
    </div>
  );
};

const isValidSlug = (slug?: string): boolean => !!slug && slug.search(/[^-0-9a-zæøåäöü§]/) === -1;

const slugValidator = (value: string, allValues: any) => {
  if (!value) {
    return 'Må fylles ut';
  }

  if (!isValidSlug(value)) {
    return 'Må ikke inneholde spesialtegn eller mellomrom.';
  }

  return undefined;
};

export const NIKPublicationEdit: FC = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props} aside={<Aside />}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Endre problemstilling</h1>
        </div>

        <TextInput
          source="slug"
          validate={slugValidator}
          helperText="Denne må være unik."
          fullWidth
        />
        <TextInput
          source="title"
          validate={[required()]}
          helperText="Tittel på problemstillingen slik som den vises på Juridika"
          fullWidth
        />
        <ReferenceInput
          source="primaryTag"
          reference="tags"
          allowEmpty={false}
          validate={[required()]}
          perPage={500}
          filter={{ tagPrefixes: 'rettsområde' }}
          fullWidth
        >
          <AutocompleteInput
            optionValue="id"
            optionText="name"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>

        <ReferenceInput
          source="publisherId"
          reference="publishers"
          validate={[required()]}
          fullWidth
        >
          <AutocompleteInput
            optionValue="id"
            optionText="name"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>

        <TextInput
          source="description"
          helperText="En kort beskrivelse av problemstillingen som vises i oversikten over problemstillinger."
          allowEmpty
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export const NIKPublicationCreate: FC = (props) => {
  const classes = useStyles();

  return (
    <Create {...props} aside={<Aside />}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Opprett en ny problemstilling</h1>
        </div>

        <TextInput
          source="slug"
          validate={slugValidator}
          helperText="Denne må være unik."
          fullWidth
        />
        <TextInput
          source="title"
          validate={[required()]}
          helperText="Tittel på problemstillingen slik som den vises på Juridika"
          fullWidth
        />
        <ReferenceInput
          source="primaryTag"
          reference="tags"
          allowEmpty={false}
          validate={[required()]}
          perPage={500}
          filter={{ tagPrefixes: 'rettsområde' }}
          fullWidth
        >
          <AutocompleteInput
            optionValue="id"
            optionText="name"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          source="publisherId"
          reference="publishers"
          validate={[required()]}
          fullWidth
        >
          <AutocompleteInput
            optionValue="id"
            optionText="name"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>

        <TextInput
          source="description"
          helperText="En kort beskrivelse av problemstillingen som vises i oversikten over problemstillinger."
          allowEmpty
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};
