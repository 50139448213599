import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Fade from '@material-ui/core/Fade';

import '../../styles/slatePopper.css';

interface Props {
  anchorEl: Element;
}

interface State {
  showArrow: boolean;
}

class SlatePopper extends React.Component<Props, State> {
  arrowRef: React.RefObject<HTMLDivElement>;
  timeoutHandle?: NodeJS.Timeout;

  state = {
    showArrow: false,
  };

  constructor(props: Props) {
    super(props);
    this.arrowRef = React.createRef();
    this.timeoutHandle = undefined;
  }

  componentDidMount() {
    this.timeoutHandle = setTimeout(() => {
      this.setState({ showArrow: true });
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }

  render() {
    const { anchorEl, children } = this.props;
    return (
      <Popper
        id="slate-popper"
        className="SlatePopper"
        placement="top"
        anchorEl={anchorEl}
        disablePortal={false}
        open={true}
        transition={true}
        modifiers={{
          arrow: {
            enabled: this.state.showArrow,
            element: this.arrowRef.current,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={1000}>
            <div>
              <span className={'SlatePopperArrow'} ref={this.arrowRef} />
              <Paper className="SlatePopperPaper" elevation={10}>
                <div className="SlatePopperContent">{children}</div>
              </Paper>
            </div>
          </Fade>
        )}
      </Popper>
    );
  }
}

export default SlatePopper;
