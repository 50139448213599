export default [
  {
    foreignType: 'contributor_bio',
    name: 'Forfatteromtale',
  },
  {
    foreignType: 'literature_publication_review',
    name: 'Utgivelse-omtale',
  },
  {
    foreignType: 'literature_edition_review',
    name: 'Utgaveomtale',
  },
];
