import { Customer } from '../../../dataProvider/customersProvider';
import { Product } from '../../../dataProvider/customerProductsProvider';
import { SubscriptionStatus } from '../../../dataProvider/subscriptionStatusProvider';
import { SubscriptionPackage } from '../../../dataProvider/subscriptionPackageProvider';
export type BulkActionInput = {
  basePath: string;
  resource: any;
  selectedIds: string[];
  admin: Admin;
  crudUpdateMany: (
    resource: any,
    selectedIds: string[],
    customers: object,
    basePath: string
  ) => void;
  onClose: (value: string) => void;
  classes: {
    genericBulkAction: string;
    removeButton?: string;
    addButton?: string;
    setButton?: string;
  };
};

export type Admin = {
  resources: {
    customers: {
      data: {
        [id: string]: Customer;
      };
    };
    products: {
      data: {
        [id: string]: Product;
      };
    };
    subscriptionStatus: {
      data: {
        [id: string]: SubscriptionStatus;
      };
    };
    subscriptionPackage: {
      data: {
        [id: string]: SubscriptionPackage;
      };
    };
  };
};

export interface ConfirmProps {
  admin: Admin;
  basePath: string;
  crudUpdateMany: (
    resource: any,
    selectedIds: string[],
    customers: object,
    basePath: string
  ) => void;
  resource: any;
  selectedIds: string[];
}

type Input = {
  [id: string]: any;
};

export const findIdInListByName = (list: Input, name: string): string => {
  return Object.values(list).filter((obj) => obj.name === name)[0].id;
};
export const getChoicesFromObject = (input: Input): string[] => {
  return Object.values(input).map((obj: any) => obj.name);
};
