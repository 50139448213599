import * as React from 'react';
import { List, Datagrid, TextField, Pagination } from 'react-admin';

const TagPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 500]} {...props} />
);

const TagList: React.FC<{}> = (props: {}) => (
  <List {...props} perPage={100} pagination={<TagPagination />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField label="Antall dokumenter" source="documentCount" />
    </Datagrid>
  </List>
);

export default TagList;
