import { getType, ActionType } from 'typesafe-actions';
import { call, put, takeEvery } from 'redux-saga/effects';

import { BackendUrls } from '../../dataProvider/types';

import * as actions from './commentaryActions';
import { showNotification } from 'ra-core';
import { backendUrls } from '../../dataProvider/apiUrls';
import { CommentaryMetadataForAllExpressionFragments, CommentMeta } from '../expressions/types';
import { Commentary } from './types';
import { LovbackendCommentsService } from '../../dataProvider/lovbackend/lovbackendCommentsService';

function* createFromLatestPublished(action: ActionType<typeof actions.createFromLatestPublished>) {
  const {
    payload: { expressionUri, fragmentId, editor },
    meta,
  } = action;

  try {
    yield LovbackendCommentsService.create(backendUrls()).createFromLatestPublished(
      expressionUri,
      fragmentId,
      editor
    );

    yield put(actions.createCommentarySuccess(meta.onSuccess.callback));
    yield put(actions.getCommentaryMeta(expressionUri));
    yield put(
      actions.getCommentary(
        { expressionUri, fragmentId: fragmentId },
        {
          successCallback: () => {},
          createNewCommentaryCallback: () => {},
        }
      )
    );
  } catch (error) {
    console.error(error);
    yield put(actions.createCommentaryFailure());
  }
}

function* getCommentary(action: ActionType<typeof actions.getCommentary>) {
  const {
    payload: { expressionUri, fragmentId, publicationStatus },
    meta,
  } = action;

  try {
    let service = LovbackendCommentsService.create(backendUrls());

    const xml: string = yield service.getCommentXml(expressionUri, fragmentId);
    const commentMeta: CommentMeta = yield service.getCommentMeta(
      expressionUri,
      fragmentId,
      publicationStatus
    );

    const comment: Commentary = {
      id: commentMeta.id,
      workUri: commentMeta.workUri,
      inForceDate: commentMeta.inForceDate,
      content: xml,
      fragmentId: commentMeta.fragmentId,
      createdAt: commentMeta.createdAt,
      createdBy: commentMeta.createdBy,
      isPublished: commentMeta.publicationStatus === 'PUBLISHED',
      lastUpdatedAt: commentMeta.lastUpdatedAt,
      lastUpdatedBy: commentMeta.lastUpdatedBy,
      publishedAt: commentMeta.publishedAt,
    };

    yield put(
      actions.getCommentarySuccess(comment, {
        successCallback: meta.onSuccess.callback,
      })
    );
  } catch (error) {
    yield put(
      actions.getCommentaryFailure({
        error,
        meta:
          error.status && error.status === 404
            ? { callback: meta.onFailure.callback }
            : {
                notification: meta.onFailure.notification,
                callback: () => {},
              },
      })
    );
  }
}

function* getCommentaryMeta(action: ActionType<typeof actions.getCommentaryMeta>) {
  const {
    payload: { expressionUri },
  } = action;

  try {
    const response: CommentaryMetadataForAllExpressionFragments = yield LovbackendCommentsService.create(
      backendUrls()
    ).getAllCommentMeta(expressionUri);
    yield put(actions.getCommentaryMetaSuccess({ expressionUri, fragments: response }));
  } catch (error) {
    console.error(error);
    yield put(actions.getCommentaryMetaFailure());
  }
}

function* publishComments(action: ActionType<typeof actions.publishComments>) {
  const {
    payload: { expressionUri, fragmentIds, editor },
    meta,
  } = action;

  const isMultiple = fragmentIds.length > 1;

  try {
    yield LovbackendCommentsService.create(backendUrls()).publish(
      expressionUri,
      fragmentIds,
      editor
    );
    yield put(actions.publishCommentarySuccess(isMultiple, meta.onSuccess.callback));
  } catch (error) {
    console.error(error);
    yield put(
      actions.publishCommentaryFailure({
        isMultiple,
        error: {
          detailMessage: error.message,
        },
      })
    );
  }
}

function* overwriteLastReviewed(action: ActionType<typeof actions.overwriteLastReviewed>) {
  const {
    payload: { expressionUri, fragmentIds, editor, date },
    meta,
  } = action;

  try {
    yield LovbackendCommentsService.create(backendUrls()).overwriteLastReviewed(
      expressionUri,
      fragmentIds,
      editor,
      date
    );
    yield put(actions.overwriteLastReviewedSuccess(meta.onSuccess.callback));
  } catch (error) {
    console.error(error);
    yield put(actions.overwriteLastReviewedFailure(error.message));
  }
}

function* saveCommentary(action: ActionType<typeof actions.saveCommentary>) {
  const {
    payload: { expressionUri, fragmentId, xmlDocument, editor },
  } = action;

  const serializedXml = new XMLSerializer().serializeToString(xmlDocument);

  try {
    yield LovbackendCommentsService.create(backendUrls()).saveComment(
      expressionUri,
      fragmentId,
      serializedXml,
      editor
    );
    yield put(actions.saveCommentarySuccess());
    yield put(actions.getCommentaryMeta(expressionUri));
  } catch (error) {
    yield put(
      showNotification('resources.commentaries.notification.save_commentary_failure', 'error', {
        messageArgs: {
          msg: error.message,
        },
        autoHideDuration: 20_000,
      })
    );
    console.error(error);
    yield put(actions.saveCommentaryFailure());
  }
}

function* getListOfAllExpressionsWithComments(
  action: ActionType<typeof actions.getListOfAllExpressionsWithComment>,
  backendUrls: BackendUrls
) {
  const {
    payload: { id },
  } = action;

  try {
    const response: string[] = yield LovbackendCommentsService.create(
      backendUrls
    ).getCommentedExpressions(id);

    yield put(
      actions.getListOfAllExpressionsWithCommentSuccess({
        id,
        expressionsWithComments: response,
      })
    );
  } catch (error) {
    yield put(actions.getListOfAllExpressionsWithCommentFailure());
  }
}

export const createCommentarySaga = (backendUrls: BackendUrls) => {
  return function* commentarySaga() {
    yield takeEvery(getType(actions.createFromLatestPublished), createFromLatestPublished);
    yield takeEvery(getType(actions.getCommentary), getCommentary);
    yield takeEvery(getType(actions.getCommentaryMeta), getCommentaryMeta);
    yield takeEvery(getType(actions.publishComments), publishComments);
    yield takeEvery(getType(actions.overwriteLastReviewed), overwriteLastReviewed);
    yield takeEvery(getType(actions.saveCommentary), saveCommentary);
    yield takeEvery(getType(actions.getListOfAllExpressionsWithComment), function* (action: any) {
      yield call(getListOfAllExpressionsWithComments, action, backendUrls);
    });
  };
};
