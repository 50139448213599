import * as io from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

export interface LiteratureConversionBitsFormat {
  id: string;
  processId: string;
  createdAt: Date;
  description: string;
}

export interface PartialLiteratureConversionBitsFormat {}

const apiTypeIO = io.type({
  id: io.string,
  processId: io.string,
  createdAt: io.string,
  description: io.string,
});
type ApiType = io.TypeOf<typeof apiTypeIO>;

interface ApiOutgoingType {}

export const toApiMapper = (bitsFormat: LiteratureConversionBitsFormat): ApiOutgoingType => ({
  id: bitsFormat,
});

export interface ApiPartialOutgoingType {}

export const toApiPartialMapper = (
  process: PartialLiteratureConversionBitsFormat
): ApiPartialOutgoingType => ({});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<LiteratureConversionBitsFormat> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/conversionBitsFormats`,
    halListName: 'conversionBitsFormatList',
    incomingType: apiTypeIO,
    toReactAdminMapper: (api: ApiType): LiteratureConversionBitsFormat => ({
      id: api.id,
      processId: api.processId,
      createdAt: new Date(api.createdAt),
      description: api.description,
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      processId: 'processId',
    },
    filterParams: {},
    sortFields: [],
  });
