import * as React from 'react';
import {
  Toc,
  TocItem,
  CommentaryMetadataForAllExpressionFragments,
} from '../../../state/expressions/types';
import ActExpressionTocItem from './ActExpressionTocItem';
import { List, withStyles, Theme, ListSubheader, Paper, Typography } from '@material-ui/core';
import { CommentaryLinkingStatus } from '../../../state/commentaryLinking/types';

interface ActExpressionTocProps {
  toc: Toc;
  title: string;
  classes?: any;
  selectedFragmentIds: { eId: string; injectCommentBody: boolean; injectCuratedSources: boolean }[];
  fragmentsLinkingStatuses: CommentaryLinkingStatus;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
  onSelectId: (fragmentId: string, isCuratedSource: boolean) => void;
  onShowInjectResults: (fragmentId: string) => void;
}

const styles = (theme: Theme) => ({
  list: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5),
    border: `1px solid ${theme.palette.divider}`,
  },
});

const ActExpressionToc: React.FC<ActExpressionTocProps> = (props) => {
  return (
    <React.Fragment>
      <Paper className={props.classes.paper} elevation={0}>
        <Typography variant="h5" component="h4">
          Velg kommentarer du vil lenke
        </Typography>
        <Typography component="p">
          Velg rød sjekkboks for å lenke i kommentaren eller blå sjekkboks for kuratertekilder
        </Typography>
      </Paper>

      <List
        dense
        subheader={<ListSubheader component="div">{props.title}</ListSubheader>}
        component="nav"
        className={props.classes.list}
      >
        {props.toc.map((tocItem: TocItem) => (
          <ActExpressionTocItem
            {...props}
            tocItem={tocItem}
            selectedFragmentIds={props.selectedFragmentIds}
            key={tocItem.eId}
            nestedClassName={props.classes.nested}
            commentaryMeta={props.commentaryMeta}
          />
        ))}
      </List>
    </React.Fragment>
  );
};

export default withStyles(styles)(ActExpressionToc);
