import {
  Command,
  Editor,
  IO,
  Scope,
  XopusApi,
  XopusCanvas,
  XopusNode,
  XopusRange,
  EmbeddedXopus,
} from './types';

const getEmbeddedXopus = (): EmbeddedXopus | null => {
  const anyWindow: any = window;
  const embeddedXopus = anyWindow.EmbeddedXopus;

  return embeddedXopus || null;
};

export const getXopusApi = (): XopusApi | null => {
  const embeddedXopus = getEmbeddedXopus();

  return embeddedXopus && embeddedXopus.getAPI('xopus');
};

const getEditor = (): Editor | null => {
  const xopusApi = getXopusApi();

  return xopusApi && xopusApi.Editor;
};

const getIO = (): IO | null => {
  const xopusApi = getXopusApi();

  return xopusApi && xopusApi.IO;
};

export const getActiveCanvas = (): XopusCanvas | null => {
  const editor = getEditor();

  return editor && editor.getActiveCanvas();
};

const getDocument = (): XopusNode | null => {
  const editor = getEditor();

  try {
    return editor && editor.getActiveDocument();
  } catch (e) {
    console.warn(e);
    return null;
  }
};

const getScope = (): Scope | null => {
  const editor = getEditor();

  return editor && editor.getScope();
};

const getRange = (): XopusRange | null => {
  const editor = getEditor();

  return editor && editor.Selection.getRange();
};

export const getDocumentUrl = (): string | null => {
  const document = getDocument();

  return document && document.documentURI;
};

export const getHTMLElementsForXMLNode = (node: XopusNode): Array<HTMLElement> | null => {
  const editor = getEditor();

  return editor && editor.getHTMLElementsForXMLNode(node);
};

export const getScopeByName = (name: string): number | string | boolean | null => {
  const scope = getScope();

  return scope && scope.get(name);
};

export const getViewParam = (name: string): object | null => {
  const canvas = getActiveCanvas();

  return canvas && canvas.getViewParam(name);
};

export const getXML = (): string | null => {
  const document = getDocument();

  return document && document.getXML();
};

export const getXMLNodeForHTMLElement = (htmlNode: HTMLElement): XopusNode | null => {
  const editor = getEditor();

  return editor && editor.getXMLNodeForHTMLElement(htmlNode);
};

export const declareScopeVariable = (name: string, value: boolean | number | string | object) => {
  const scope = getScope();

  scope && scope.declare(name, value);
};

export const setScope = (name: string, value: boolean | number | string | object) => {
  const scope = getScope();

  scope && scope.set(name, value);
};

export const setScopeListener = (name: string, handler: () => void) => {
  const scope = getScope();

  scope && scope.setListener(name, handler);
};

export const setRange = (range: XopusRange) => {
  const editor = getEditor();

  editor && editor.Selection.setRange(range);
};

export const setSelection = (node: XopusNode) => {
  const editor = getEditor();
  const range = getRange();

  if (range) {
    range.selectNode(node);
    range.collapse(true);
  }

  editor && range && editor.Selection.setRange(range);
};

export const setViewParam = (name: string, value: object | string) => {
  const canvas = getActiveCanvas();

  canvas && canvas.setViewParam(name, value);
};

export const findXMLNodeByAttr = (attr: string, value: string): XopusNode | null => {
  const document = getDocument();

  return document && document.selectSingleNode(`//*[@${attr}='${value}']`);
};

export const findXMLNodeByType = (node: XopusNode, xpath: string): XopusNode =>
  node.selectSingleNode(xpath);

export const loadDocument = (url: string, ignoreUnsavedChanges = true): XopusNode | null => {
  const canvas = getActiveCanvas();

  return canvas && canvas.loadDocument(url, ignoreUnsavedChanges);
};

export const createNativeXMLDocument = (xml: string): XMLDocument | null => {
  const editor = getEditor();

  return editor && editor.XML.createNativeXMLDocument(xml);
};

export const addCommand = (name: string, command: Command): object | null => {
  const editor = getEditor();

  return editor && editor.addCommand(name, command);
};

export const addEventListener = (type: string, listener: () => void) => {
  const editor = getEditor();

  editor && editor.addEventListener(type, listener);
};

export const setExitURL = (exitUlr: string) => {
  const editor = getEditor();

  editor && editor.setExitURL(exitUlr);
};

export const setExitFunction = (exitFunction: () => void) => {
  const editor = getEditor();

  editor && editor.setExitFunction(exitFunction);
};

export const setUserNameForChangeTracking = (userName: string) => {
  const editor = getEditor();

  editor && editor.ChangeTracking.setUserName(userName);
};

export const setSaveXMLFunction = (saveFunction: (_: string, xmlDocument: XMLDocument) => void) => {
  const io = getIO();

  io && io.setSaveXMLFunction(saveFunction);
};

export const setLoadXMLFunction = (loadFunction: () => void) => {
  const io = getIO();

  io && io.setLoadXMLFunction(loadFunction);
};

export const addApiAvailableListener = (iFrameName: string, listener: () => void) => {
  const embeddedXopus = getEmbeddedXopus();

  embeddedXopus && embeddedXopus.addListener(listener, iFrameName);
};

export const setURL = (url: string, ignoreUnsavedChanges: boolean = true) => {
  const editor = getEditor();

  editor && editor.setURL(url, ignoreUnsavedChanges);
};

export const hasUnsavedChanges = (): boolean => {
  const document = getDocument();

  return document ? document.hasUnsavedChanges() : false;
};

export const exitEditor = () => {
  const editor = getEditor();

  editor && editor.exit();
};
