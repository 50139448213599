import * as Slate from 'slate';
import * as tags from '../../tags';
import { getAboveByType, isRangeAtRoot } from '../../utils/query';

export const isSelectionInListItem = (editor: Slate.Editor) => {
  if (
    !editor.selection ||
    !getAboveByType(editor, tags.BLOCK_LIST_ITEM) ||
    isRangeAtRoot(editor.selection)
  ) {
    return;
  }

  const [, paragraphPath] = Slate.Editor.parent(editor, editor.selection);

  const [listItemNode, listItemPath] =
    getAboveByType(editor, tags.BLOCK_LIST_ITEM) || Slate.Editor.parent(editor, paragraphPath);

  if (listItemNode.type !== tags.BLOCK_LIST_ITEM) return;
  const [listNode, listPath] = Slate.Editor.parent(editor, listItemPath);

  return {
    listNode,
    listPath,
    listItemNode,
    listItemPath,
  };
};
