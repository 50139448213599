export const BLOCK_ARTICLE = 'article';
export const BLOCK_HEADER = 'header';
export const BLOCK_ASIDE = 'aside';
export const BLOCK_BYLINE = 'byline';
export const BLOCK_FRAGMENT = 'fragment';
export const BLOCK_FACTS = 'facts';
export const BLOCK_FIGURE = 'figure';

export const BLOCK_FIGCAPTION = 'figcaption';
export const BLOCK_LEAD_PARAGRAPH = 'lead-paragraph';
export const BLOCK_PARAGRAPH = 'paragraph';
export const BLOCK_END_PARAGRAPH = 'end-paragraph';
export const BLOCK_BULLETED_LIST = 'bulleted-list';
export const BLOCK_NUMBERED_LIST = 'numbered-list';
export const BLOCK_ALPHA_LIST = 'alpha-list';
export const BLOCK_REFERENCE_LIST = 'reference-list';
export const BLOCK_LIST_ITEM = 'list-item';
export const BLOCK_LIST_ITEM_CONTENT = 'list-item-content';
export const BLOCK_QUOTE = 'quote';
export const BLOCK_QUOTE_DISPOSITION = 'quote-disposition';
export const BLOCK_HEADING_ONE = 'heading-one';
export const BLOCK_HEADING_TWO = 'heading-two';
export const BLOCK_HEADING_THREE = 'heading-three';
export const BLOCK_HEADING_FOUR = 'heading-four';
export const BLOCK_HEADING_FIVE = 'heading-five';
export const BLOCK_HEADING_SIX = 'heading-six';
export const BLOCK_IMAGE = 'image';
export const BLOCK_TABLE = 'table';
export const BLOCK_TABLE_HEADER = 'table-header';
export const BLOCK_TABLE_BODY = 'table-body';
export const BLOCK_TABLE_ROW = 'table-row';
export const BLOCK_TABLE_HEADER_CELL = 'table-header-cell';
export const BLOCK_TABLE_CELL = 'table-cell';
export const BLOCK_IFRAME = 'iframe';

export const BLOCK_RECOMMENDED_PAGES_MODULE = 'recommended-pages-module';
export const BLOCK_RECOMMENDED_PAGES = 'recommended-pages';
export const BLOCK_RECOMMENDED_PAGES_HEADER = 'recommended-pages-header';

export const TAG_RECOMMENDED_PAGES_POPULAR = 'recommended-pages-most-popular';
export const TAG_RECOMMENDED_PAGES_NEW = 'recommended-pages-new';
export const TAG_RECOMMENDED_PAGES_JURIDIKA_RECOMMENDS = 'recommended-pages-juridika-recommends';

export const MARK_BOLD = 'bold';
export const MARK_ITALIC = 'italic';
export const MARK_UNDERLINE = 'underline';
export const MARK_STRIKETHROUGH = 'strikethrough';
export const MARK_CITE = 'cite';

export const INLINE_PLACEHOLDER = 'placeholder';

// Elements used in JATS only
export const BLOCK_BODY = 'body';
export const BLOCK_SECTION = 'section';
export const BLOCK_FOOT_NOTE_GROUP = 'fn-group';
export const BLOCK_TABLE_WRAPPER = 'table-wrap';
export const BLOCK_TABLE_WRAPPER_FOOTER = 'table-wrap-foot';
export const BLOCK_TABLE_WRAPPER_GROUP = 'table-wrap-group';
export const BLOCK_TABLE_LABEL = 'label';
export const BLOCK_ATTRIBUTE = 'attrib';
export const BLOCK_BREAK = 'br';
export const BLOCK_TABLE_FOOTER = 'table-footer';
export const BLOCK_DIV = 'div';
export const BLOCK_HORIZONTAL_RULE = 'hr';

export const MARK_SUPERSCRIPT = 'superscript';
export const MARL_SUBSCRIPT = 'subscript';

export const INLINE_LINK = 'link';
export const INLINE_QUOTE = 'inline-quote';
export const INLINE_SPAN = 'span';
export const INLINE_FOOT_NOTE = 'fn';
