import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ArrayField,
  SingleFieldList,
  ChipField,
  Pagination,
} from 'react-admin';

import DateTimeField from '../DateTimeField';

const ConversionListPagination: React.FC<{}> = (props) => (
  <Pagination rowsPerPageOptions={[10, 20, 50, 100, 500]} {...props} />
);

const LiteratureConversionProcessList: React.FC<{}> = (props: {}) => (
  <List {...props} pagination={<ConversionListPagination {...props} />}>
    <Datagrid rowClick="show">
      <TextField source="description" />
      <DateTimeField source="updatedAt" />
      <ArrayField source="checklist">
        <SingleFieldList>
          <ChipField source="text" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);

export default LiteratureConversionProcessList;
