import {
  LegalExpressionIdentifier,
  LegalWork,
  LegalWorkIdentifier,
} from '../state/legalDocuments/types';

/**
 * Converts actExpId: { type: 'lov', seq: 3, date: '01-02-1990', inForceAt: '05-13-2010' }
 * to string: '01-02-1990-3@05-13-2010'
 * @param exprId
 */
export function convertActExprIdToKey(exprId: LegalExpressionIdentifier): string {
  return [`${exprId.date}-${exprId.seq}`, exprId.inForceDate].join('@');
}

export function convertKeyToActExprId(
  subtype: string,
  identifier: string,
  inForceDate: string
): LegalExpressionIdentifier {
  const date = identifier.substring(0, identifier.lastIndexOf('-'));
  const seq = parseInt(identifier.substring(identifier.lastIndexOf('-') + 1, identifier.length));
  return {
    countryCode: 'no',
    documentType: 'act',
    subtype,
    inForceDate,
    seq,
    languageCode: 'nor',
    date,
  };
}

// uri string e.g. /akn/no/act/forskrift/1986-03-21/747
export function uriToWorkIdentifier(uri: string): LegalWorkIdentifier {
  let segments = uri.split('/');
  return {
    countryCode: segments[2],
    documentType: segments[3],
    subtype: segments[4],
    date: segments[5],
    seq: parseInt(segments[6]),
  };
}

export function workIdentifierToUri(workId: LegalWorkIdentifier): string {
  return `/akn/${workId.countryCode}/${workId.documentType || 'act'}/${workId.subtype}/${
    workId.date
  }/${workId.seq}`;
}

export const formatLegalReference = (
  identifier: LegalWorkIdentifier,
  includeAmendmentNote = true
) => {
  const amendmentNote =
    includeAmendmentNote && identifier.documentType === 'amendment'
      ? ` (Endrings${identifier.subtype})`
      : '';
  return `${workIdentifierToId(identifier)}${amendmentNote}`;
};

export const getDescriptiveWorkTitle = (work: LegalWork): string => {
  const shortTitle = work.expressions[0]?.manifestations[0]?.shortTitle;
  return shortTitle || work.listingTitle || formatLegalReference(work);
};

export function expressionIdentifierToUri(exprId: LegalExpressionIdentifier): string {
  return `${workIdentifierToUri(exprId)}/${exprId.languageCode}@${exprId.inForceDate}`;
}

export function workIdentifierToId(workId: LegalWorkIdentifier): string {
  return `${workId.subtype}-${workId.date}-${workId.seq}`;
}

export function workIdentifierToLovdataPath(workId: LegalWorkIdentifier): string {
  return `/${workId.subtype}/${workId.date}-${workId.seq}`;
}

export function convertUriToLegacyId(uri: string): string {
  let segments = uri.split('/');

  const workId = [segments[4], [segments[5], segments[6]].join('-')].join('/');
  return segments.length <= 7 ? workId : `${workId}/${segments[7].split('@')[1]}`;
}
