import * as Slate from 'slate';
import uuidv4 from 'uuid/v4';
import * as tags from '../../tags';

export const insertImage = (editor: Slate.Editor) => {
  Slate.Transforms.insertNodes(editor, {
    type: tags.BLOCK_IMAGE,
    src: '',
    alt: 'Insert image here',
    'data-uuid': uuidv4(),
    'data-fileid': '',
    children: [{ text: '' }],
  });
};

export const removeImage = (editor: Slate.Editor) => {
  Slate.Transforms.removeNodes(editor, {
    match: (node: Slate.Node) => node.type === tags.BLOCK_IMAGE,
  });
};
