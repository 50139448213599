import URI from 'urijs';
import { GET_LIST, GET_MANY, GET_ONE, DELETE, UPDATE, fetchUtils } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders } from './apiUtil';
import { LinkedJournalStatus, JournalLinkStatus } from '../models/LinkInjectorModels';

export interface LinkedJournalStatusReactAdmin extends LinkedJournalStatus {
  id: string;
}

export const createProvider = (urls: Types.BackendUrls) => {
  const BASE_URL = URI(urls.juridikaLitteraturBackend)
    .segment('admin')
    .segment('v0')
    .segment('linkinject')
    .segment('journal')
    .toString();

  const convertDataProviderRequestToHTTP = (
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    switch (type) {
      case UPDATE: {
        const query = {};
        return {
          url: URI(`${BASE_URL}/${params.id}`).query(query).toString(),
          options: {
            method: 'POST',
            headers: new Headers(juridikaHeaders()),
            body: params.data,
          },
        };
      }

      case DELETE: {
        const query = { status: params.previousData.type };
        return {
          url: URI(`${BASE_URL}/${params.id}`).query(query).toString(),
          options: {
            method: 'DELETE',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }
      case GET_ONE: {
        return {
          url: URI(BASE_URL).segment('status').query(params).toString(),
          options: {
            method: 'GET',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }

      case GET_MANY: {
        return {
          url: URI(BASE_URL).segment('status').toString(),
          options: {
            method: 'GET',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }

      case GET_LIST: {
        const query = params.filter ? params.filter : {};
        return {
          url: URI(BASE_URL).segment('status').query(query).toString(),
          options: {
            method: 'GET',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  const convertHTTPResponseToDataProvider = (
    response: any,
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    const { json } = response;

    switch (type) {
      case UPDATE: {
        return { data: { id: params.id, ...json } };
      }

      case DELETE: {
        return { data: { id: params.id } };
      }

      case GET_ONE: {
        if (!params.id) return { data: {} };

        const data = json.map((journal: LinkedJournalStatus) => {
          const { publicationSlug, editionPath, documentKey } = journal.documentId;
          const id = `${publicationSlug}/${editionPath.join('/')}/${documentKey}`;
          return { id, ...journal };
        })[0];

        if (data.id === params.id) return { data };
        else return { data: { id: params.id, type: JournalLinkStatus.ORIGINAL, valid: false } };
      }

      case GET_MANY: {
        if (!params.ids) return { data: [] };

        const data = json.map((journal: LinkedJournalStatus) => {
          const { publicationSlug, editionPath, documentKey } = journal.documentId;
          const id = `${publicationSlug}/${editionPath.join('/')}/${documentKey}`;
          return { id, ...journal };
        });

        return {
          data: params.ids.map((id) => {
            return (
              data.find((journal: LinkedJournalStatusReactAdmin) => journal.id === id) || {
                id,
                type: JournalLinkStatus.ORIGINAL,
                valid: false,
              }
            );
          }),
        };
      }
      case GET_LIST: {
        return {
          data: json.map((journal: any) => {
            const { publicationSlug, editionPath, documentKey } = journal.documentId;
            const id = `${publicationSlug}/${editionPath.join('/')}/${documentKey}`;
            return { id, ...journal };
          }),
          total: json.length,
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  return (type: string, resource: string, params: Types.ReactAdminApiParams) => {
    const { fetchJson } = fetchUtils;
    const { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);

    const response = fetchJson(url, options).then((response) =>
      convertHTTPResponseToDataProvider(response, type, resource, params)
    );

    return response;
  };
};
