import * as Slate from 'slate';
import * as SlateReact from 'slate-react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
  HypertextDeserializationRule,
  HypertextDeserializationRuleSet,
  SlateAttributeData,
} from './types';
import { HypertextRuleSets, HypertextSerializationRuleSets } from '../../slate/serializer/rules';
import { concat, makeDeserializationRuleSet } from './utils';

export class HtmlSerialize {
  readonly deserializationRules: HypertextDeserializationRuleSet;

  constructor(
    readonly options: {
      hypertextRules: HypertextRuleSets;
      serializationRules: HypertextSerializationRuleSets;
    }
  ) {
    this.deserializationRules = {
      ...makeDeserializationRuleSet('block', options.hypertextRules.blocks),
      ...makeDeserializationRuleSet('inline', options.hypertextRules.inlines),
      ...makeDeserializationRuleSet('mark', options.hypertextRules.marks),
    };
  }

  // Remove extra whitespace generated by ReactDOMServer
  private trimWhitespace = (rawHtml: string): string => rawHtml.replace(/(\r\n|\n|\r|\t)/gm, '');

  // Remove redundant data attributes
  private stripSlateDataAttributes = (rawHtml: string): string =>
    rawHtml
      .replace(/( data-slate)(-node|-type|-leaf)="[^"]+"/gm, '')
      .replace(/( data-testid)="[^"]+"/gm, '');

  /**
   * Remove all class names that are not starting with `slate-`
   */
  private stripClassNames = (html: string) => {
    const allClasses = html.split(/(class="[^"]*")/g);

    let filteredHtml = '';
    allClasses.forEach((item, index) => {
      if (index % 2 === 0) {
        return (filteredHtml += item);
      }
      const slateClassNames = item.match(/(slate-[^"\s]*)/g);
      if (slateClassNames) {
        filteredHtml += `class="${slateClassNames.join(' ')}"`;
      }
    });

    return filteredHtml;
  };

  private serializeElement = (
    renderElement: (props: SlateReact.RenderElementProps) => JSX.Element | null,
    elementProps: SlateReact.RenderElementProps
  ) => {
    // If no type provided we wrap children with div tag
    if (!elementProps.element.type) {
      return `<div>${elementProps.children}</div>`;
    }

    let html: string | undefined;

    const renderedElement = renderElement(elementProps);

    if (!renderedElement) return;

    html = renderToStaticMarkup(renderedElement);
    html = this.stripClassNames(html);
    return html;
  };

  private serializeLeaf = (
    renderLeaf: (props: SlateReact.RenderLeafProps) => JSX.Element | null,
    leafProps: SlateReact.RenderLeafProps
  ) => {
    const { children } = leafProps;

    let html: string | undefined;

    const newLeafProps = {
      ...leafProps,
      children: encodeURIComponent(children),
    };

    const renderedLeaf = renderLeaf(newLeafProps);

    if (!renderedLeaf) return;

    html = renderToStaticMarkup(renderedLeaf);
    html = this.stripClassNames(html);
    return html;
  };

  private deserializeAttributesByRule = (
    element: Element,
    elementClassNames: string[],
    rule: HypertextDeserializationRule
  ): SlateAttributeData | null => {
    let attributes: SlateAttributeData = {};

    if (elementClassNames.length > 0) {
      attributes = { className: elementClassNames };
    }

    if (rule.data) {
      if (!element.attributes) {
        throw new Error(`no attributes set on HTML node,
          while rule demands for: ${element.nodeName.toLowerCase()}, required properties: ${
          rule.data
        }`);
      }

      attributes = rule.data.reduce(
        (data, key) => ({
          ...data,
          [key]: element.getAttribute(key)!,
        }),
        attributes
      );
    }

    if (rule.optionalData) {
      attributes = {
        ...attributes,
        ...rule.optionalData.reduce((data, key) => {
          if (!element.attributes || !element.getAttribute(key)) {
            return data;
          }
          return {
            ...data,
            [key]: element.getAttribute(key),
          };
        }, attributes),
      };
    }

    return attributes;
  };

  private deserializeElementByRule = (
    element: Element,
    rule: HypertextDeserializationRule,
    marks: string[],
    ignoreUnknownTags: boolean
  ): Slate.Node[] | null => {
    const elementClassNames = element.getAttribute('class')?.split(/\s+/) ?? [];

    if (rule.className && !elementClassNames.includes(rule.className)) {
      return null;
    }

    const currentMarks = rule.isMark ? [rule.type, ...marks] : marks;
    const attributes = this.deserializeAttributesByRule(element, elementClassNames, rule);
    const childElements = this.deserializeElements(
      element.childNodes,
      currentMarks,
      ignoreUnknownTags
    );

    return [
      {
        ...attributes,
        children: childElements ?? [{ text: '' }],
        type: rule.type,
        ...(rule.isInline && { isInline: rule.isInline }),
        ...(rule.isMark && { isMark: rule.isMark }),
      },
    ];
  };

  private deserializeElement = (
    element: Element,
    marks: string[],
    ignoreUnknownTags: boolean
  ): Slate.Node[] => {
    if (element.nodeType !== Node.ELEMENT_NODE) {
      throw new Error(
        `cannot deserialize a non-element node by rule for element: ${element.tagName}`
      );
    }

    const tagName = element.tagName.toLowerCase();
    const rules = this.deserializationRules[tagName] || [];

    for (const rule of rules) {
      const deserializedElement = this.deserializeElementByRule(
        element,
        rule,
        marks,
        ignoreUnknownTags
      );
      if (deserializedElement !== null) {
        return deserializedElement;
      }
    }

    if (!ignoreUnknownTags) {
      throw Error(`No rule found for element with tag: ${tagName} in rules: ${rules}`);
    }
    return this.deserializeElements(element.childNodes, marks, ignoreUnknownTags);
  };

  private deserializeNode = (
    node: ChildNode,
    marks: string[],
    ignoreUnknownTags: boolean
  ): Slate.Node[] => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textNode = { text: node.textContent || '' };
      if (marks.length > 0) {
        return [marks.reduce((previous, current) => ({ ...previous, [current]: true }), textNode)];
      } else {
        return [textNode];
      }
    }

    return this.deserializeElement(node as Element, marks, ignoreUnknownTags);
  };

  private deserializeElements = (
    nodes: NodeListOf<ChildNode> | undefined,
    marks: string[] = [],
    ignoreUnknownTags: boolean
  ): Slate.Node[] => {
    if (!nodes || nodes.length === 0) return [{ text: '' }];
    return Array.from(nodes)
      .map((node) => this.deserializeNode(node, marks, ignoreUnknownTags))
      .reduce(concat, []);
  };

  private deserializeHtmlBody = (
    fragment: HTMLElement,
    ignoreUnknownTags: boolean
  ): Slate.Node[] => {
    if (fragment.nodeName === 'BODY') {
      return this.deserializeElements(fragment.childNodes, [], ignoreUnknownTags);
    }
    return [];
  };

  serialize = (
    nodes: Slate.Node[],
    renderElement: (props: SlateReact.RenderElementProps) => JSX.Element | null,
    renderLeaf: (props: SlateReact.RenderLeafProps) => JSX.Element | null
  ): string => {
    const result = nodes
      .map((node: Slate.Node) => {
        if (Slate.Text.isText(node)) {
          return this.serializeLeaf(renderLeaf, {
            leaf: node as Slate.Text,
            text: node as Slate.Text,
            children: node.text,
            attributes: { 'data-slate-leaf': true },
          });
        }

        return this.serializeElement(renderElement, {
          element: node,
          children: encodeURIComponent(this.serialize(node.children, renderElement, renderLeaf)),
          attributes: { 'data-slate-node': 'element', ref: null },
        });
      })
      .join('');

    return this.stripSlateDataAttributes(this.trimWhitespace(decodeURIComponent(result)));
  };

  deserialize = (html: string, ignoreUnknownTags: boolean = false): Slate.Node[] => {
    const { body } = new DOMParser().parseFromString(html, 'text/html');
    return this.deserializeHtmlBody(body, ignoreUnknownTags);
  };
}
