import * as React from 'react';
import * as Slate from 'slate';

import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import { deleteTableRow } from './transforms';

const Icon: React.FC<{ disabled?: boolean }> = (props) => {
  return (
    <svg
      style={{ fill: props.disabled ? 'grey' : 'white' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M9.41 13L12 15.59 14.59 13 16 14.41 13.41 17 16 19.59 14.59 21 12 18.41 9.41 21 8 19.59 10.59 17 8 14.41 9.41 13M22 9a2 2 0 01-2 2H4a2 2 0 01-2-2V6a2 2 0 012-2h16a2 2 0 012 2v3M4 9h4V6H4v3m6 0h4V6h-4v3m6 0h4V6h-4v3z"></path>
    </svg>
  );
};

const IconDisabled: React.FC<{}> = (props) => {
  return <Icon disabled={true} />;
};

const DeleteColumnButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  const isActive = !!getAboveByType(editor, tags.BLOCK_TABLE_ROW);

  return (
    <SlateToolbarButton
      isActive={isActive}
      Icon={isActive ? Icon : IconDisabled}
      message="slate.contextMenu.table.deleteRow"
      onMouseDown={() => deleteTableRow(editor)}
    />
  );
};

export default DeleteColumnButton;
