import React from 'react';

const Plus = () => (
  <svg width="12" height="12" viewBox="0 0 12 12">
    <path
      fill="#9289A1"
      fillRule="evenodd"
      d="M5.14285714,5.14285714 L0,5.14285714 L0,6.85714286 L5.14285714,6.85714286 L5.14285714,12 L6.85714286,12 L6.85714286,6.85714286 L12,6.85714286 L12,5.14285714 L6.85714286,5.14285714 L6.85714286,0 L5.14285714,1.04969726e-16 L5.14285714,5.14285714 Z"
    />
  </svg>
);

export default Plus;
