import * as React from 'react';
import {
  Create,
  required,
  TextInput,
  SimpleForm,
  BooleanInput,
  useNotify,
  useRefresh,
} from 'react-admin';

const UserMigrationCreate: React.FC<{}> = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    notify('Suksess!');
    refresh();
  };
  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput
          source="oldExternalId"
          label="Gammel auth0 id"
          validate={[required()]}
          helperText={'Lim inn auth0 id til brukeren som det skal flyttes data FRA'}
          fullWidth
        />
        <TextInput
          source="newExternalId"
          label="Ny auth0 id"
          validate={[required()]}
          helperText={'Lim inn auth0 id til brukeren som det skal flyttes data TIL'}
          fullWidth
        />
        <BooleanInput
          source="removeOldJuridikaUserId"
          defaultValue={true}
          label="Migrer data"
          helperText="Hvorvidt koblingen mellom gammel auth0 konto og data som flyttes skal slettes"
          falseLabel="Nei"
          trueLabel="Ja"
          disabled // Disabled for now. A false value will result in two accounts sharing user data, e.g. favorites.
          fullWidth
        />
        <BooleanInput
          source="overWriteExistingMapping"
          defaultValue={false}
          label="Overskriv eksisterende data"
          helperText="Hvorvidt koblingen mellom ny auth0 konto og den eventuelle eksisterende dataen skal slettes. Hvis brukeren det migreres data TIL allerede har data, vil det komme en feilmelding om dette valget ikke er satt til ja."
          falseLabel="Nei"
          trueLabel="Ja"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default UserMigrationCreate;
