import { ChipField } from 'react-admin';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const tagChipCss = {
  chip: { margin: 4, cursor: 'inherit', backgroundColor: '#d7e6f7' },
  root: {},
};

const contributorChipCss = {
  chip: { margin: 4, cursor: 'inherit', backgroundColor: '#f8efb7' },
  root: {},
};

const reviewChipCss = {
  chip: { margin: 4, cursor: 'inherit', backgroundColor: '#ffc5bd' },
  root: {},
};

// Special style to indicate something gets _removed_ inside forms
const deleteChipCss = {
  chip: { margin: 4, cursor: 'inherit', backgroundColor: '#f00' },
  root: {},
};

export const useTagChipStyles = makeStyles(tagChipCss);
export const useDeleteChipStyles = makeStyles(deleteChipCss);

export const TagChipField = withStyles(tagChipCss)(ChipField);
export const ContributorChipField = withStyles(contributorChipCss)(ChipField);
export const ReviewChipField = withStyles(reviewChipCss)(ChipField);
