import * as io from 'io-ts';

import { DataFormat, NamedString } from './dataFormat';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

interface TextbookPublicationWritableProps<Fmt extends DataFormat> {
  slug: string | null; // We can create the publication with slug=null,
  titles: Array<NamedString<'title', Fmt>>;
  actWorkIds: Array<NamedString<'workId', Fmt>>;
  ufDepartment: number | null;
}

export interface TextbookPublication extends TextbookPublicationWritableProps<'RECORD'> {
  id: string;

  // Computed/helper fields
  fullTitle: string;
  mainTitle: string;
}

export interface PartialTextbookPublication {}

// Runtime type!!!
const ApiTypeV = io.type({
  id: io.string,
  slug: io.string,
  titles: io.array(io.string),
  actWorkIds: io.union([io.array(io.string), io.null]),
  ufDepartment: io.union([io.number, io.null]),
});
type ApiType = io.TypeOf<typeof ApiTypeV>;

type ApiOutgoingType = TextbookPublicationWritableProps<'JSON'>;

export const toApiMapper = (publication: TextbookPublication): ApiOutgoingType => ({
  slug: !publication.slug ? null : publication.slug,
  titles: publication.titles.map(({ title }) => title),
  actWorkIds: publication.actWorkIds?.map(({ workId }) => workId),
  ufDepartment: publication.ufDepartment,
});

export type ApiPartialOutgoingType = Partial<TextbookPublicationWritableProps<'JSON'>>;

export const toApiPartialMapper = (
  publication: Partial<TextbookPublicationWritableProps<'PROVIDER'>>
): ApiPartialOutgoingType => ({
  slug: publication.slug,
  titles: publication.titles?.map(({ title }) => title),
  actWorkIds: publication.actWorkIds?.map(({ workId }) => workId),
  ufDepartment: publication.ufDepartment,
});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<TextbookPublication> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/textbookPublications`,
    halListName: 'adminTextbookPublicationList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (publication: ApiType): TextbookPublication => ({
      id: publication.id,
      slug: publication.slug,
      titles: publication.titles.map((title) => ({ title })),
      actWorkIds: (publication.actWorkIds || []).map((workId) => ({ workId })),
      ufDepartment: publication.ufDepartment,

      fullTitle: publication.titles.join(' — '),
      mainTitle: publication.titles.length > 0 ? publication.titles[0] : '',
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
    },
    sortFields: [],
  });
