import { Model, TEXT, ElementModel, ELEMENT, createElement, createText } from './simpleXml';

/*
 * This module contains parsers and generators
 * for creating a tagged <index> (stikkordregister).
 */

/**
 * Representation of a book's pages
 */
interface PageMap {
  nameToIndex: Map<string, number>;
  indexToName: Map<number, string>;
}

/**
 * The parser works with tokens of the following kinds:
 */
export enum TokenKind {
  // 'ff.':
  FollowingFollowing = 1,
  // 'f.':
  Following = 2,
  See = 3,
  Also = 4,
  Word = 5,
  Number = 6,
  CommaOrSemicolon = 7,
  Period = 8,
  Dash = 9,
  Tab = 10,
  LineBreak = 11,
  Space = 12,
  OtherPunct = 13,
  Unknown = 14,
}

export interface Token {
  kind: TokenKind;
  text: string;
}

export interface TokenizedParagraph {
  leadingElements: Array<ElementModel>;
  tokens: Array<Token>;
  trailingElements: Array<ElementModel>;
}

export enum LookupKind {
  PageReference,
  AbsolutePageLocation,
  RelativePageOffset,
  See,
  SeeAlso,
}

interface AbsolutePageLocation {
  kind: LookupKind.AbsolutePageLocation;
  pageName: string;
  index: number;
}

interface RelativePageOffset {
  kind: LookupKind.RelativePageOffset;
  offset: number;
  text: string;
}

interface PageReference {
  kind: LookupKind.PageReference;
  leading?: string;
  firstPageLocation: AbsolutePageLocation | null;
  separator?: string;
  lastPageLocation: AbsolutePageLocation | RelativePageOffset;
}

interface SeeAlso {
  kind: LookupKind.See | LookupKind.SeeAlso;
  leading?: string;
  term: string;
}

export enum EntryType {
  IndexEntry,
  IndexDiv,
}

interface ParsedIndexEntry {
  entryType: EntryType.IndexEntry;
  leadingNodes: Array<Model>;
  // If it starts with a Dash and then a Space, we declare it as a Sub Entry,
  // And will try to match it to a non-sub entry
  isIndented: boolean;
  term: string;
  lookups: ReadonlyArray<PageReference | SeeAlso>;
  trailingElements: Array<ElementModel>;
}

interface ParsedIndexDiv {
  entryType: EntryType.IndexDiv;
  title: string;
  trailingElements: Array<ElementModel>;
}

interface Tree<E> {
  // This may be either an alphabetic letter to group under, or an entry:
  entry: E;
  subtrees: Array<Tree<E>>;
}

export const scanPageMap = (model: Model): PageMap => {
  const nameToIndex: Map<string, number> = new Map();
  const indexToName: Map<number, string> = new Map();

  let currentIndex = 0;

  const recurse = (model: Model) => {
    if (model.type !== ELEMENT) return;

    if (model.tagName === 'target') {
      const id = model.attributes.id;
      if (!id || typeof id !== 'string') return;
      if (!id.startsWith('pb')) return;

      const pageName = id.slice(2);
      nameToIndex.set(pageName, currentIndex);
      indexToName.set(currentIndex, pageName);
      currentIndex += 1;
    } else {
      model.children.forEach(recurse);
    }
  };

  recurse(model);

  return { nameToIndex, indexToName };
};

export const createPageMap = (pageNames: Array<string>): PageMap => {
  const nameToIndex: Map<string, number> = new Map();
  const indexToName: Map<number, string> = new Map();

  for (let i = 0; i < pageNames.length; i += 1) {
    const pageName = pageNames[i];
    nameToIndex.set(pageName, i);
    indexToName.set(i, pageName);
  }

  return { nameToIndex, indexToName };
};

/**
 * Convert a <body> into an array of <index-div> or <index-entry>
 */
export const convertBodyToIndexEntriesOrDivs = (
  body: ElementModel,
  pageMap: PageMap
): Array<ElementModel> => {
  const groups = convertTokenizedParagraphsToIndexEntryGroups(analyzeBody(body.children), pageMap);

  return groups.map((group) => convertTreeToIndexEntry(group, pageMap));
};

/**
 * Here we generate the actual BITS tags
 */
const convertTreeToIndexEntry = (
  tree: Tree<ParsedIndexEntry | ParsedIndexDiv>,
  pageMap: PageMap
): ElementModel => {
  const { entry, subtrees } = tree;

  if (entry.entryType === EntryType.IndexDiv) {
    return createElement(
      'index-div',
      {},
      createElement(
        'index-title-group',
        {},
        createElement('title', {}, createText(entry.title), ...entry.trailingElements)
      ),
      ...subtrees.map((subtree) => convertTreeToIndexEntry(subtree, pageMap))
    );
  }

  const lookupNodes = entry.lookups.reduce((agg, lookup, index) => {
    if (index === entry.lookups.length - 1) {
      // Append trailing elements inside the _last_ lookup/nav-pointer
      // Because the BITS schema is very strict for <index-entry> :(
      return agg.concat(convertLookupToElementWithLeading(lookup, entry.trailingElements, pageMap));
    }
    return agg.concat(convertLookupToElementWithLeading(lookup, [], pageMap));
  }, [] as Array<Model | undefined>);

  return createElement(
    'index-entry',
    {},
    createElement('term', {}, createText(entry.term)),
    ...lookupNodes,
    ...subtrees.map((subtree) => convertTreeToIndexEntry(subtree, pageMap))
  );
};

const NAV_POINTER_TYPE = 'nav-pointer-type';

const convertLookupToElementWithLeading = (
  lookup: PageReference | SeeAlso,
  trailingElements: Array<ElementModel>,
  pageMap: PageMap
): Array<ElementModel | undefined> => {
  const { leading } = lookup;

  const lookupElement = convertLookupToElement(lookup, trailingElements, pageMap);

  return [
    leading ? createElement('x', {}, createText(leading)) : undefined,
    lookupElement ?? undefined,
  ];
};

const convertLookupToElement = (
  lookup: PageReference | SeeAlso,
  trailingElements: Array<ElementModel>,
  pageMap: PageMap
): ElementModel | null => {
  switch (lookup.kind) {
    case LookupKind.PageReference:
      return convertPageReferenceToNavPointer(lookup, trailingElements, pageMap);
    case LookupKind.See:
      return createElement('see-entry', {}, ...[createText(lookup.term), ...trailingElements]);
    case LookupKind.SeeAlso:
      return createElement('see-also-entry', {}, ...[createText(lookup.term), ...trailingElements]);
  }
};

const convertPageReferenceToNavPointer = (
  pageReference: PageReference,
  trailingElements: Array<ElementModel>,
  pageMap: PageMap
): ElementModel | null => {
  const { firstPageLocation, separator, lastPageLocation } = pageReference;

  if (lastPageLocation.kind === LookupKind.AbsolutePageLocation) {
    if (firstPageLocation) {
      return createElement(
        'nav-pointer-group',
        {},
        createAbsoluteNavPointer(firstPageLocation, 'start-of-range', []),
        separator ? createText(separator) : undefined,
        createAbsoluteNavPointer(lastPageLocation, 'end-of-range', trailingElements)
      );
    } else {
      return createAbsoluteNavPointer(lastPageLocation, 'point', trailingElements);
    }
  }

  // Last page location is relative:

  if (lastPageLocation.kind === LookupKind.RelativePageOffset) {
    if (!firstPageLocation) return null;

    const offsetNavPointer = createOffsetNavPointer(
      firstPageLocation,
      lastPageLocation,
      'end-of-range',
      trailingElements,
      pageMap
    );

    if (offsetNavPointer) {
      return createElement(
        'nav-pointer-group',
        {},
        createAbsoluteNavPointer(firstPageLocation, 'start-of-range', []),
        separator ? createText(separator) : undefined,
        offsetNavPointer
      );
    }

    return createAbsoluteNavPointer(firstPageLocation, 'point', trailingElements);
  }

  return null;
};

const createAbsoluteNavPointer = (
  pageLocation: AbsolutePageLocation,
  navPointerType: string,
  trailingElements: Array<ElementModel>
): ElementModel => {
  return createElement(
    'nav-pointer',
    { rid: `pb${pageLocation.pageName}`, [NAV_POINTER_TYPE]: navPointerType },
    createText(pageLocation.pageName),
    ...trailingElements
  );
};

const createOffsetNavPointer = (
  startPageLocation: AbsolutePageLocation,
  relativePageOffset: RelativePageOffset,
  navPointerType: string,
  trailingElements: Array<ElementModel>,
  pageMap: PageMap
): ElementModel | null => {
  const { offset, text } = relativePageOffset;
  const pageIndex = startPageLocation.index + offset;
  const pageName = pageMap.indexToName.get(pageIndex);

  if (!pageName) return null;

  return createElement(
    'nav-pointer',
    { rid: `pb${pageName}`, [NAV_POINTER_TYPE]: navPointerType },
    createText(text),
    ...trailingElements
  );
};

const EMPTY_ENTRY_TREE_LIST: Array<Tree<ParsedIndexEntry>> = [];

export const convertTokenizedParagraphsToIndexEntryGroups = (
  tokenizedParagraphs: Array<TokenizedParagraph>,
  pageMap: PageMap
): Array<Tree<ParsedIndexEntry | ParsedIndexDiv>> => {
  const parsedIndexTrees: Array<Tree<ParsedIndexEntry>> = tokenizedParagraphs.map(
    (tokenizedParagraph) => {
      return {
        entry: parseIndexEntry(tokenizedParagraph, pageMap),
        subtrees: EMPTY_ENTRY_TREE_LIST,
      };
    }
  );

  const groupedByDash = groupByIndent(parsedIndexTrees);
  const groupedByCharacter = groupBySingleAlphabeticCharacter(groupedByDash);

  return groupedByCharacter;
};

/**
 * Groups index entries into parents and children, children has the `isIndented` property set to true.
 */
const groupByIndent = (trees: Array<Tree<ParsedIndexEntry>>): Array<Tree<ParsedIndexEntry>> => {
  const output: Array<Tree<ParsedIndexEntry>> = [];
  let i = 0;

  while (i < trees.length) {
    let tree = trees[i];
    i += 1;

    // Find subsequent nodes with isIndented:
    if (i < trees.length && trees[i].entry.isIndented) {
      const subtrees: Array<Tree<ParsedIndexEntry>> = [];

      while (i < trees.length) {
        const subtree = trees[i];
        if (!subtree.entry.isIndented) {
          break;
        }
        subtrees.push(subtree);
        i += 1;
      }

      output.push({
        ...tree,
        subtrees,
      });
    } else {
      output.push(tree);
    }
  }
  return output;
};

/**
 * Group into single-alphabetic characters
 * E.g.
 * - A
 * - - Ansvar
 * - B
 * - - Bolle
 */
const groupBySingleAlphabeticCharacter = (
  trees: Array<Tree<ParsedIndexEntry>>
): Array<Tree<ParsedIndexEntry | ParsedIndexDiv>> => {
  const output: Array<Tree<ParsedIndexEntry | ParsedIndexDiv>> = [];
  let i = 0;

  while (i < trees.length) {
    const tree = trees[i];
    i += 1;

    if (tree.entry.term.length === 1) {
      const subtrees: Array<Tree<ParsedIndexEntry>> = [];
      while (i < trees.length) {
        const subtree = trees[i];
        if (subtree.entry.term.length === 1) {
          break;
        }
        subtrees.push(subtree);
        i += 1;
      }
      output.push({
        entry: {
          entryType: EntryType.IndexDiv,
          title: tree.entry.term,
          trailingElements: tree.entry.trailingElements,
        },
        subtrees,
      });
    } else {
      output.push(tree);
    }
  }

  return output;
};

const analyzeBody = (nodes: Array<Model>): Array<TokenizedParagraph> => {
  let currentLeadingElements: Array<ElementModel> = [];
  const tokenizedParagraphs: Array<TokenizedParagraph> = [];

  const addAsParagraph = (text: string, trailingElements: ElementModel[]) => {
    // For some reason, some of the paragraphs contain line breaks which
    // we must use to split them up further..
    const tokensByLine = splitTokensByLineBreaks(tokenizeText(text));

    let lineIndex = 0;

    for (const tokens of tokensByLine) {
      const isLast = lineIndex === tokensByLine.length - 1;
      tokenizedParagraphs.push({
        leadingElements: currentLeadingElements,
        tokens,
        trailingElements: isLast ? trailingElements : [],
      });
      lineIndex += 1;
    }

    currentLeadingElements = [];
  };

  for (let i = 0; i < nodes.length; i += 1) {
    const node = nodes[i];
    if (node.type === TEXT) {
      currentLeadingElements.push(createElement('x', {}, node));
    } else if (node.tagName === 'p') {
      const { text, trailingElements } = destructureNode(node);
      addAsParagraph(text, trailingElements);
    } else if (node.tagName === 'list') {
      // A bit of a weird situation, but we have:
      // <p>Term</p>
      // <list>
      //   <list-item>subterm</list-item>
      // </list>
      // So let's hack this situation into shape by converting each list-item into
      // a dash-entry:
      for (const child of node.children) {
        if (child.type === ELEMENT && child.tagName === 'list-item') {
          const { text, trailingElements } = destructureNode(child);
          addAsParagraph(`- ${text}`, trailingElements);
        }
      }
    } else {
      currentLeadingElements.push(node);
    }
  }

  return tokenizedParagraphs;
};

const destructureNode = (node: Model): { text: string; trailingElements: Array<ElementModel> } => {
  // Collect up important trailing internal nodes and make them trailing
  const trailingElements: Array<ElementModel> = [];

  const concatText = (node: Model): string => {
    if (node.type === TEXT) {
      return node.value;
    } else if (node.tagName === 'target') {
      // Need to carry over this node, although it will be slightly reordered:
      trailingElements.push(node);
      return '';
    } else {
      return node.children.map(concatText).join('');
    }
  };

  const text = concatText(node);

  return {
    text,
    trailingElements,
  };
};

export const splitTokensByLineBreaks = (tokens: Array<Token>): Array<Array<Token>> => {
  const output: Array<Array<Token>> = [];
  let currentLine: Array<Token> = [];

  for (const token of tokens) {
    if (token.kind === TokenKind.LineBreak) {
      if (currentLine.length > 0) {
        output.push(currentLine);
        currentLine = [];
      }
    } else {
      currentLine.push(token);
    }
  }

  if (currentLine.length > 0) {
    output.push(currentLine);
  }

  return output;
};

const REGEX_KINDS: Array<TokenKind> = [
  TokenKind.FollowingFollowing,
  TokenKind.Following,
  TokenKind.See,
  TokenKind.Also,
  TokenKind.Word,
  TokenKind.Number,
  TokenKind.CommaOrSemicolon,
  TokenKind.Period,
  TokenKind.Dash,
  TokenKind.Tab,
  TokenKind.LineBreak,
  TokenKind.Space,
  TokenKind.OtherPunct,
  TokenKind.Unknown,
];

/**
 * On JS regex unicode properties:
 * https://javascript.info/regexp-unicode
 *
 * The groups' positions matches the TokenKind enum
 */
const PATTERN = /(ff\.?)|(fl?g?\.?)|([Ss]e)|(også)|(\p{L}+)|(\p{N}+)|([,;])|(\.)|(\p{Pd})|(\t+)|([\n\p{Zl}])|([\s\p{Z}]+)|(\p{P})|(.)/u;

export const tokenizeText = (text: string): Array<Token> => {
  const tokens: Array<Token> = [];

  while (text.length > 0) {
    const match = text.match(PATTERN);
    if (match) {
      for (const kind of REGEX_KINDS) {
        const groupMatch = match[kind];
        if (groupMatch) {
          tokens.push({
            kind,
            text: groupMatch,
          });
          break;
        }
      }
      text = text.substr(match[0].length);
    } else {
      // ignore it, just try to pop a character off the string
      text = text.substr(1);
    }
  }

  return tokens;
};

/**
 * Parse an array of tokens into one index entry.
 */
export const parseIndexEntry = (
  tokenizedParagraph: TokenizedParagraph,
  pageMap: PageMap
): ParsedIndexEntry => {
  const { tokens } = tokenizedParagraph;
  const until = tokens.length;
  let firstTermToken = 0;
  let lastTermToken = until - 1;

  let isIndented = false;

  while (
    parseOneTokenByKind(tokens, firstTermToken, until, [
      TokenKind.Dash,
      TokenKind.LineBreak,
      TokenKind.Space,
      TokenKind.Tab,
    ])
  ) {
    switch (tokens[firstTermToken].kind) {
      case TokenKind.Dash:
      case TokenKind.Tab:
        isIndented = true;
        break;
      default:
        break;
    }
    firstTermToken += 1;
  }

  // A helper function because `kind in [...]` does not appear to work for some unknown reason
  const shouldPopTrailing = (kind: TokenKind): boolean =>
    kind === TokenKind.LineBreak ||
    kind === TokenKind.Space ||
    kind === TokenKind.OtherPunct ||
    kind === TokenKind.Unknown;

  // Pop trailing space, punctuation
  while (lastTermToken > firstTermToken && shouldPopTrailing(tokens[lastTermToken].kind)) {
    lastTermToken -= 1;
  }

  const { lookups, lastIndex } = reverseParseLookups(
    tokens,
    lastTermToken,
    firstTermToken,
    pageMap
  );

  return {
    entryType: EntryType.IndexEntry,
    leadingNodes: tokenizedParagraph.leadingElements,
    isIndented,
    term: tokenSubstring(tokens, firstTermToken, lastIndex + 1),
    lookups,
    trailingElements: tokenizedParagraph.trailingElements,
  };
};

// Parse entry lookups, return the references and the last index that was read.
// because it works reversely, from is larger than until
const reverseParseLookups = (
  tokens: Array<Token>,
  from: number,
  until: number,
  pageMap: PageMap
): { lookups: Array<PageReference | SeeAlso>; lastIndex: number } => {
  const lookups: Array<PageReference | SeeAlso> = [];

  let current = from;

  while (true) {
    const pageReferenceResult = reverseParseOnePageReferenceLookup(tokens, current, until, pageMap);
    if (pageReferenceResult) {
      const { pageReference, lastIndex } = pageReferenceResult;
      lookups.push(pageReference);
      current = lastIndex;
      if (pageReferenceResult.isFirstLookup) {
        break;
      } else {
        continue;
      }
    }

    const seeAlsoResult = reverseParseSeeAlsoLookups(tokens, current, until);
    if (seeAlsoResult) {
      for (const lookup of seeAlsoResult.lookups) {
        lookups.push(lookup);
      }
      current = seeAlsoResult.lastIndex;
      continue;
    }

    break;
  }

  // We parsed in reverse direction, but still pushed at the end of the vector
  lookups.reverse();

  return { lookups, lastIndex: current };
};

/**
 * Parse one page reference, possibly a range, of the end of the token list.
 * `until` is where to stop parsing (earlier in the array).
 */
const reverseParseOnePageReferenceLookup = (
  tokens: Array<Token>,
  from: number,
  until: number,
  pageMap: PageMap
): { pageReference: PageReference; lastIndex: number; isFirstLookup: boolean } | null => {
  let current = from;
  let firstPageLocation: AbsolutePageLocation | null = null;

  const lastPageLocation = reverseParseOnePageLocation(tokens, current, until, pageMap);
  if (!lastPageLocation) return null;

  const lastPageLocationStart = current;
  let firstPageLocationStart = current;
  let firstPageLocationEnd = current;
  let isFirstLookup = true;

  current -= 1;

  if (lastPageLocation.kind === LookupKind.AbsolutePageLocation) {
    const dash = reverseParseOneTokenByKind(tokens, current, until, [TokenKind.Dash]);

    if (dash) {
      firstPageLocationEnd = current;
      current -= 1;
      firstPageLocation = reverseParseOneAbsolutePageLocation(tokens, current, until, pageMap);
      if (firstPageLocation) {
        firstPageLocationStart = current;
        current -= 1;
      } else {
        return null;
      }
    }
  } else if (lastPageLocation.kind === LookupKind.RelativePageOffset) {
    const space = reverseParseOneTokenByKind(tokens, current, until, [TokenKind.Space]);

    if (space) {
      firstPageLocationEnd = current;
      current -= 1;
      firstPageLocation = reverseParseOneAbsolutePageLocation(tokens, current, until, pageMap);
      if (firstPageLocation) {
        firstPageLocationStart = current;
        current -= 1;
      } else {
        return null;
      }
    }
  }

  if (reverseParseOneTokenByKind(tokens, current, until, [TokenKind.Space, TokenKind.OtherPunct])) {
    current -= 1;
  }
  if (reverseParseOneTokenByKind(tokens, current, until, [TokenKind.CommaOrSemicolon])) {
    current -= 1;
    // We parsed a comma or a semicolon, so it's not guaranteed that this page ref
    // is the first one.
    isFirstLookup = false;
  }

  return {
    pageReference: {
      kind: LookupKind.PageReference,
      leading: tokenSubstringOrUndefined(tokens, current + 1, firstPageLocationStart),
      firstPageLocation,
      separator: tokenSubstringOrUndefined(tokens, firstPageLocationEnd, lastPageLocationStart),
      lastPageLocation,
    },
    lastIndex: current,
    isFirstLookup,
  };
};

const reverseParseOnePageLocation = (
  tokens: Array<Token>,
  from: number,
  until: number,
  pageLocations: PageMap
): AbsolutePageLocation | RelativePageOffset | null => {
  return (
    reverseParseOneAbsolutePageLocation(tokens, from, until, pageLocations) ||
    reverseParseOneRelativePageOffset(tokens, from, until)
  );
};

const reverseParseOneAbsolutePageLocation = (
  tokens: Array<Token>,
  from: number,
  until: number,
  pageLocations: PageMap
): AbsolutePageLocation | null => {
  const number = reverseParseOneTokenByKind(tokens, from, until, [TokenKind.Number]);
  if (!number) return null;

  const pageIndex = pageLocations.nameToIndex.get(number.text);
  if (pageIndex === undefined) return null;

  return { kind: LookupKind.AbsolutePageLocation, pageName: number.text, index: pageIndex };
};

const reverseParseOneRelativePageOffset = (
  tokens: Array<Token>,
  from: number,
  until: number
): RelativePageOffset | null => {
  let token: Token | null;

  token = reverseParseOneTokenByKind(tokens, from, until, [TokenKind.FollowingFollowing]);
  if (token) {
    return { kind: LookupKind.RelativePageOffset, offset: 2, text: token.text };
  }

  token = reverseParseOneTokenByKind(tokens, from, until, [TokenKind.Following]);
  if (token) {
    return { kind: LookupKind.RelativePageOffset, offset: 1, text: token.text };
  }

  return null;
};

/**
 * Parse ', Se x y z' or ', Se også x y z'
 * @param tokens
 * @param from
 * @param until
 */
const reverseParseSeeAlsoLookups = (
  tokens: Array<Token>,
  from: number,
  until: number
): { lookups: ReadonlyArray<SeeAlso>; lastIndex: number } | null => {
  let current = from;

  const findSeeAlso = (): { seeFirst: number; seeLast: number; isAlso: boolean } | null => {
    let next = from;
    while (next > until) {
      let current = next;
      let isAlso = false;

      if (tokens[current].kind === TokenKind.Space) {
        current -= 1;
      }
      if (current > until && tokens[current].kind === TokenKind.Also) {
        isAlso = true;
        current -= 1;
      }
      if (current > until && tokens[current].kind === TokenKind.Space) {
        current -= 1;
      }
      if (current > until && tokens[current].kind === TokenKind.See) {
        return { seeFirst: current, seeLast: next, isAlso };
      } else {
        next -= 1;
      }
    }
    return null;
  };

  const foundSeeAlso = findSeeAlso();

  if (!foundSeeAlso) return null;

  const { seeFirst, seeLast, isAlso } = foundSeeAlso;
  current = seeFirst - 1;

  if (reverseParseOneTokenByKind(tokens, current, until, [TokenKind.Space])) {
    current -= 1;
  } else {
    return null;
  }

  if (
    reverseParseOneTokenByKind(tokens, current, until, [
      TokenKind.CommaOrSemicolon,
      TokenKind.Period,
    ])
  ) {
    current -= 1;
  } else {
    return null;
  }

  if (seeLast === from) {
    return null;
  }

  const lookups: Array<SeeAlso> = [];

  const tryPushLookup = (leadingStart: number, termStart: number, end: number) => {
    const term = tokenSubstringOrUndefined(tokens, termStart, end);
    if (term) {
      lookups.push({
        kind: isAlso ? LookupKind.SeeAlso : LookupKind.See,
        leading: tokenSubstringOrUndefined(tokens, leadingStart, termStart),
        term,
      });
    }
  };

  let currentLookupLeadingStart = current + 1;
  let currentLookupTermStart = -1;

  // We found the start+end of the whole see-entry, now split it up by commas
  for (let i = seeLast + 1; i < from + 1; i += 1) {
    const token = tokens[i];

    switch (token.kind) {
      case TokenKind.CommaOrSemicolon:
        tryPushLookup(currentLookupLeadingStart, currentLookupTermStart, i);
        currentLookupLeadingStart = i;
        currentLookupTermStart = -1;
        break;
      case TokenKind.Word:
      case TokenKind.Number:
      case TokenKind.Following:
      case TokenKind.FollowingFollowing:
      case TokenKind.See:
      case TokenKind.Also:
        if (currentLookupTermStart < 0) {
          currentLookupTermStart = i;
        }
        break;
      default:
        break;
    }
  }

  // try to push the last one, which does not need to be comma-terminated
  tryPushLookup(currentLookupLeadingStart, currentLookupTermStart, from + 1);

  // These were added in forwards manner, while we are in a backwards parsing environment:
  lookups.reverse();

  return {
    lookups,
    lastIndex: current,
  };
};

const parseOneTokenByKind = (
  tokens: Array<Token>,
  index: number,
  until: number,
  kindOf: Array<TokenKind>
): Token | null => {
  if (index >= until) return null;
  for (const kind of kindOf) {
    if (tokens[index].kind === kind) {
      return tokens[index];
    }
  }
  return null;
};

const reverseParseOneTokenByKind = (
  tokens: Array<Token>,
  index: number,
  until: number,
  kindOf: Array<TokenKind>
): Token | null => {
  if (index <= until) return null;
  for (const kind of kindOf) {
    if (tokens[index].kind === kind) {
      return tokens[index];
    }
  }
  return null;
};

const tokenSubstringOrUndefined = (
  tokens: Array<Token>,
  from: number,
  to: number
): string | undefined => {
  if (from >= to) return undefined;
  const text = tokenSubstring(tokens, from, to);
  if (text.length === 0) return undefined;
  return text;
};

const tokenSubstring = (tokens: Array<Token>, from: number, to: number): string => {
  let text = '';
  for (const token of tokens.slice(from, to)) {
    text += token.text;
  }
  return text;
};
