import { spawn, call, put, takeEvery } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { fetchStart, fetchEnd, showNotification } from 'react-admin';

import { RootProvider } from '../../dataProvider/rootProvider';
import { PartialContributor } from '../../dataProvider/contributorsProvider';

import {
  postPublishTextbookEdition,
  postUnPublishTextbookEdition,
} from '../../dataProvider/textbookEditionsProvider';

import {
  publishStaticContentRevision,
  unpublishStaticContentRevision,
  publishTextbookEdition,
  unPublishTextbookEdition,
  SnapshotInfo,
  publishJournalArticles,
} from './publishingActions';
import { postPublishJournalArticles } from '../../dataProvider/journalArticlesProvider';

const refreshAction = () => ({
  type: 'PUBLISHING_REFRESH',
  payload: {},
  meta: {
    refresh: true,
  },
});

export const createPublishingWatcherSaga = (rootProvider: RootProvider) => {
  return function* publishingWatcherSaga() {
    yield spawn(function* revisionPublishingSaga() {
      yield takeEvery(getType(publishStaticContentRevision.request), function* ({
        payload: { id, snapshotInfo, date },
      }: ActionType<typeof publishStaticContentRevision.request>) {
        yield call(publishRevisionSaga, id, snapshotInfo, date, rootProvider);
      });
    });

    yield spawn(function* revisionUnpublishingSaga() {
      yield takeEvery(getType(unpublishStaticContentRevision.request), function* ({
        payload: { id },
      }: ActionType<typeof unpublishStaticContentRevision.request>) {
        yield call(unpublishRevisionSaga, id, rootProvider);
      });
    });

    yield spawn(function* textbookEditionPublishingSaga() {
      yield takeEvery(getType(publishTextbookEdition.request), function* ({
        payload: { id, juridikaPublishedAt, documentFilter },
      }: ActionType<typeof publishTextbookEdition.request>) {
        yield call(
          publishTextbookEditionSaga,
          id,
          juridikaPublishedAt,
          documentFilter,
          rootProvider
        );
      });
    });

    yield spawn(function* textbookEditionUnPublishingSaga() {
      yield takeEvery(getType(unPublishTextbookEdition.request), function* ({
        payload: { id },
      }: ActionType<typeof unPublishTextbookEdition.request>) {
        yield call(unPublishTextbookEditionSaga, id, rootProvider);
      });
    });

    yield spawn(function* journalArticlePublishingSaga() {
      yield takeEvery(getType(publishJournalArticles.request), function* ({
        payload: { ids, juridikaPublishedAt },
      }: ActionType<typeof publishJournalArticles.request>) {
        yield call(publishJournalArticlesSaga, ids, juridikaPublishedAt, rootProvider);
      });
    });
  };
};

function* publishRevisionSaga(
  id: string,
  snapshotInfo: SnapshotInfo,
  date: Date,
  rootProvider: RootProvider
) {
  yield put(fetchStart());
  try {
    yield call(rootProvider.revisions, 'UPDATE', {
      id,
      data: {
        publishedAt: date,
      },
    });

    switch (snapshotInfo.snapshotType) {
      case 'embedded': {
        switch (snapshotInfo.foreignType) {
          case 'contributor_bio': {
            // A contributor bio is published. This means that the contributor should
            // now 'feature' in juridika contributors page. The following code automates this.
            const data: PartialContributor = {
              featured: true,
            };

            yield call(rootProvider.contributors, 'UPDATE', {
              id: snapshotInfo.foreignId,
              data,
            });
            break;
          }
          default:
            break;
        }
        break;
      }
      default:
        break;
    }

    yield put(publishStaticContentRevision.success({ id }));
  } catch (error) {
    yield put(publishStaticContentRevision.failure({ id, error: `${error}` }));
    yield put(showNotification(`${error}`, 'error'));
  } finally {
    yield put(fetchEnd());
    yield put(refreshAction());
  }
}

function* unpublishRevisionSaga(id: string, rootProvider: RootProvider) {
  yield put(fetchStart());
  try {
    yield call(rootProvider.revisions, 'UPDATE', {
      id,
      data: {
        publishedAt: null,
      },
    });
    yield put(unpublishStaticContentRevision.success({ id }));
  } catch (error) {
    yield put(unpublishStaticContentRevision.failure({ id, error: `${error}` }));
    yield put(showNotification(`${error}`, 'error'));
  } finally {
    yield put(fetchEnd());
    yield put(refreshAction());
  }
}

function* publishTextbookEditionSaga(
  id: string,
  juridikaPublishedAt: Date,
  documentFilter: 'rootOnly' | 'all',
  rootProvider: RootProvider
) {
  yield put(fetchStart());
  try {
    yield call(
      postPublishTextbookEdition,
      id,
      juridikaPublishedAt,
      documentFilter,
      rootProvider.backendUrls
    );
    yield put(publishTextbookEdition.success({ id }));
  } catch (error) {
    yield put(publishTextbookEdition.failure({ id, error: `${error}` }));
    yield put(showNotification(`${error}`, 'error'));
  } finally {
    yield put(fetchEnd());
    yield put(refreshAction());
  }
}

function* unPublishTextbookEditionSaga(id: string, rootProvider: RootProvider) {
  yield put(fetchStart());
  try {
    yield call(postUnPublishTextbookEdition, id, rootProvider.backendUrls);
    yield put(publishTextbookEdition.success({ id }));
  } catch (error) {
    yield put(publishTextbookEdition.failure({ id, error: `${error}` }));
    yield put(showNotification(`${error}`, 'error'));
  } finally {
    yield put(fetchEnd());
    yield put(refreshAction());
  }
}

function* publishJournalArticlesSaga(
  ids: Array<string>,
  juridikaPublishedAt: Date,
  rootProvider: RootProvider
) {
  yield put(fetchStart());
  try {
    yield call(postPublishJournalArticles, ids, juridikaPublishedAt, rootProvider.backendUrls);
    yield put(publishJournalArticles.success({ ids }));
  } catch (error) {
    yield put(publishJournalArticles.failure({ ids, error: `${error}` }));
    yield put(showNotification(`${error}`, 'error'));
  } finally {
    yield put(fetchEnd());
    yield put(refreshAction());
  }
}
