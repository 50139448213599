import { GET_ONE } from 'react-admin';

import * as Types from '../types';
import { LovbackendService } from './lovbackendService';

export const createProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> =>
  legalRelationsProvider(urls.lovEditorBackend, type, params);

// @ts-ignore
const legalRelationsProvider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<any> => {
  const backend = new LovbackendService(baseApiUrl);

  switch (type) {
    case GET_ONE:
      return backend.getRelations(params.id!!);
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
