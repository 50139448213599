import * as t from 'io-ts';
import * as Types from './types';
import { fetchHalJsonList, halJsonCrudApiProvider, resolveFilter } from './apiUtil';
import { GET_LIST } from 'react-admin';

export interface Tag {
  id: string;
  displayName: string;
  name: string;
  documentCount: number;
}

interface WorkTag {
  path: string;
  name: string;
  workCount: number;
}

export interface ReactAdminApiTagParams {
  id: string;
}
const ApiIncomingTypeV = t.type({
  id: t.string,
  name: t.string,
  documentCount: t.number,
});

const ApiIncomingTypeWork = t.type({
  path: t.string,
  name: t.string,
  workCount: t.number,
});

type ApiType = t.TypeOf<typeof ApiIncomingTypeV>;
type WorkApiType = t.TypeOf<typeof ApiIncomingTypeWork>;

interface ApiOutgoingType {
  id: string;
  name: string;
}

const toApiMapper = (tag: Tag): ApiOutgoingType => ({
  id: tag.id,
  name: tag.name,
});

const halListName = 'adminTagList';

const toReactAdminMapper = (apiTag: ApiType): Tag => ({
  id: apiTag.id,
  name: apiTag.name,
  displayName: tagDisplayName(apiTag.id.split('.')),
  documentCount: apiTag.documentCount,
});

const workToReactAdminMapper = (workApiTag: WorkApiType): Tag => ({
  id: workApiTag.path,
  name: workApiTag.name,
  displayName: tagDisplayName(workApiTag.path.split('.')),
  documentCount: workApiTag.workCount,
});

const filterParams = {
  tagPrefixes: (filterValue: string[]) => ({ prefixes: filterValue }),
};

export const tagDisplayName = (path: string[]) =>
  path.join('.').replace(/\./g, ' → ').replace(/_/g, ' ');

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Tag> => {
  const provider = async (
    type: Types.ReactAdminApiType,
    params: Types.ReactAdminApiParams
  ): Promise<Types.ReactAdminResponse<Tag>> => {
    const defaultProvider = halJsonCrudApiProvider({
      baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/tags`,
      halListName: halListName,
      incomingType: ApiIncomingTypeV,
      toReactAdminMapper: toReactAdminMapper,
      toApiMapper,
      toApiPartialMapper: toApiMapper,
      referenceParams: {},
      filterParams: filterParams,
      sortFields: [],
    });

    switch (type) {
      case GET_LIST: {
        const literatureTagResult = fetchHalJsonList(
          `${urls.juridikaLitteraturBackend}/admin/v0/tags`,
          params,
          halListName,
          ApiIncomingTypeV,
          toReactAdminMapper,
          {
            ...(params.filter && resolveFilter(params.filter, filterParams)),
          }
        );
        const staticContentTagResult = fetchHalJsonList(
          `${urls.staticContentBackend}/admin/v0/tags`,
          params,
          halListName,
          ApiIncomingTypeV,
          toReactAdminMapper,
          {
            ...(params.filter && resolveFilter(params.filter, filterParams)),
          }
        );

        const actWorkTagResult = fetchHalJsonList(
          `${urls.lovEditorBackend}/admin/v0/tags`,
          params,
          'juridikaTagList',
          ApiIncomingTypeWork,
          workToReactAdminMapper,
          {
            ...(params.filter && resolveFilter(params.filter, filterParams)),
          }
        );
        // Combine document count from both literature-backend and static-content-backend
        return Promise.all([literatureTagResult, staticContentTagResult, actWorkTagResult]).then(
          (result) => {
            const literatureTagList = result[0].data as Tag[];
            const staticContentTagList = result[1].data as Tag[];
            const actWorkTagList = result[2].data as Tag[];

            const allTags = [...literatureTagList, ...staticContentTagList, ...actWorkTagList];

            const aggregatedTags = allTags.reduce<{
              [key: string]: Tag;
            }>((acc, tag) => {
              return {
                ...acc,
                [tag.id]: {
                  ...tag,
                  documentCount: (acc[tag.id]?.documentCount || 0) + tag.documentCount,
                },
              };
            }, {});

            return {
              data: Object.values(aggregatedTags),
              total: result[0].total,
            };
          }
        );
      }
    }

    return defaultProvider(type, params);
  };

  return provider;
};
