import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { Icon } from '@material-ui/core';
import formatter from 'xml-formatter';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Descendant } from 'new-slate';
import { rootSerializeToXML, xmlToJsonML } from '../utils';
import LivePreview from './LivePreviewPanel';
import ReadmePanel from './ReadmePanel';
import FormatPreviewPanel from './FormatPreviewPanel';
import { juridikaFrontendBaseUrl } from '../../../dataProvider/apiUrls';

interface EditorSupportPanelProps {
  slateFormat: Descendant[];
}

const EditorSupportPanel: FC<EditorSupportPanelProps> = ({ slateFormat }) => {
  const classes = useStyles();
  const [toggleSupportPanel, setToggleSupportPanel] = useState(1);
  const [toggleFormatPreviewPanel, setToggleFormatPreviewPanel] = useState(0);
  const xmlFormatAsString = rootSerializeToXML(slateFormat);
  const jsonml = xmlToJsonML(xmlFormatAsString);

  const FRONTEND_BASE_URL = juridikaFrontendBaseUrl();

  const sendMessage = useCallback(
    (windowObj: Window, payload: string) =>
      windowObj.postMessage(payload, FRONTEND_BASE_URL as string),
    [FRONTEND_BASE_URL]
  );

  useEffect(() => {
    window.addEventListener('message', (event) => {
      console.log('Parent received message:', event.data);
    });
  }, []);

  useEffect(() => {
    var iframe: HTMLIFrameElement | null = document.getElementById(
      'iframe'
    ) as HTMLIFrameElement | null;

    const message = JSON.stringify(jsonml);

    if (iframe && iframe.contentWindow) {
      sendMessage(iframe.contentWindow, message);
    }
  }, [jsonml, sendMessage]);

  const handleToggleSupportPanel = (_event: ChangeEvent<{}>, newValue: number) =>
    setToggleSupportPanel(newValue);

  const handleSetToggleFormatPreviewPanel = (_event: ChangeEvent<{}>, newValue: number) =>
    setToggleFormatPreviewPanel(newValue);

  const formatXML = () => {
    let formattedXml;
    try {
      formattedXml = formatter(xmlFormatAsString, {
        indentation: '    ', // 4 spaces
        lineSeparator: '\n',
      });
    } catch (error) {
      console.error('Error occurred while formatting XML:', error);
      formattedXml = 'Feil i formatteringen..';
    }

    return formattedXml;
  };

  return (
    <Paper square className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={toggleSupportPanel}
        onChange={handleToggleSupportPanel}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab
          style={{ color: toggleSupportPanel === 0 ? 'white' : '#aaa' }}
          icon={<Icon>description</Icon>}
          label="README"
        />
        <Tab
          style={{ color: toggleSupportPanel === 1 ? 'white' : '#aaa' }}
          icon={<Icon>preview</Icon>}
          label="PREVIEW"
        />
        <Tab
          style={{ color: toggleSupportPanel === 2 ? 'white' : '#aaa' }}
          icon={<PersonPinIcon />}
          label="RAW"
        />
      </Tabs>
      <TabPanel value={toggleSupportPanel} index={0}>
        <ReadmePanel />
      </TabPanel>
      <TabPanel value={toggleSupportPanel} index={1}>
        <LivePreview />
      </TabPanel>

      <TabPanel value={toggleSupportPanel} index={2}>
        <Paper className={classes.formatPreviewPanel}>
          <Tabs
            value={toggleFormatPreviewPanel}
            onChange={handleSetToggleFormatPreviewPanel}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="SLATE" />
            <Tab label="XML" />
            <Tab label="JSONML" />
          </Tabs>

          <TabPanel index={0} value={toggleFormatPreviewPanel}>
            <FormatPreviewPanel
              label="SLATE OUTPUT"
              format={JSON.stringify(slateFormat, null, 2)}
            />
          </TabPanel>
          <TabPanel index={1} value={toggleFormatPreviewPanel}>
            <FormatPreviewPanel label="XML OUTPUT" format={formatXML()} />
          </TabPanel>
          <TabPanel index={2} value={toggleFormatPreviewPanel}>
            <FormatPreviewPanel label="JSONML OUTPUT" format={JSON.stringify(jsonml, null, 2)} />
          </TabPanel>
        </Paper>
      </TabPanel>
    </Paper>
  );
};

export default EditorSupportPanel;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.tabpanel}
      style={{ flexGrow: value === index ? 1 : 0 }}
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  formatPreviewPanel: {
    flexGrow: 0,
    boxShadow: 'none',
  },
  tabpanel: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    backgroundColor: '#004c36',
    minHeight: '72px',
  },
});
