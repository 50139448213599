import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  SimpleForm,
  DateInput,
  TextField,
  required,
  fetchEnd,
  fetchStart,
  showNotification,
  SaveButton,
  Toolbar,
  SelectInput,
} from 'react-admin';

import { generateAsCopy } from '../../state/legalDocuments/legalDocumentActions';
import {
  ACT_LANGUAGES,
  LegalExpression,
  GenerateCopyParams,
  LegalManifestation,
  LegalWork,
} from '../../state/legalDocuments/types';
import { CurrentUser } from '../../state/currentUser/types';

interface Props {
  currentUser: CurrentUser;
  location: {
    state: {
      work: LegalWork;
      expression: LegalExpression;
      manifestation: LegalManifestation;
    };
  };
}

const CustomToolbar: React.FC<{
  handleSave: (values: GenerateCopyParams) => void;
}> = ({ handleSave, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton onSave={(values) => handleSave(values as GenerateCopyParams)} />
    </Toolbar>
  );
};

const LegalDocumentCreateAsCopy = (props: Props) => {
  const dispatch = useDispatch();

  const handleSave = (params: GenerateCopyParams) => {
    dispatch(generateAsCopy(params));
  };

  let state = props.location.state;

  let record = {
    id: state.work.uri,
    shortTitle: state.manifestation.shortTitle,
    date: state.work.date,
    seq: state.work.seq,
    inForceDate: state.expression.inForceDate,
    data: {
      copyFromWorkUri: state.work.uri,
      copyFromInForceDate: state.expression.inForceDate,
      copyFromLanguageCode: state.expression.languageCode,
      copyFromPublicationStatus: state.manifestation.publicationStatus,
      newInForceDate: null,
      editor: props.currentUser?.name,
    },
  };

  return (
    <React.Fragment>
      <SimpleForm
        variant="standard"
        margin="normal"
        record={record}
        toolbar={<CustomToolbar handleSave={handleSave} {...props} />}
      >
        <TextField label="resources.legalDocuments.fields.version.shortTitle" source="shortTitle" />
        <TextField label="resources.legalDocuments.fields.work.date" source="date" />
        <TextField label="resources.legalDocuments.fields.work.seq" source="seq" />
        <TextField
          label="resources.legalDocuments.fields.expression.inForceAt"
          source="inForceDate"
        />
        <SelectInput
          label="resources.legalDocuments.fields.expression.languageCode"
          source="data.copyFromLanguageCode"
          choices={ACT_LANGUAGES}
          validate={required()}
        />
        <DateInput
          label="resources.legalDocuments.fields.expression.newInForceDate"
          source="data.newInForceDate"
          validate={required()}
        />
      </SimpleForm>
    </React.Fragment>
  );
};

const mapStateToProps = (state: object, ownProps: object) => ({
  ...state,
  ...ownProps,
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  push,
  generateAsCopy,
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalDocumentCreateAsCopy);
