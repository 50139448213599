import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  AutocompleteInput,
  required,
  useRedirect,
  OnSuccess,
  useNotify,
  useRefresh,
} from 'react-admin';
import { TextbookModule } from '../../dataProvider/textbookModulesProvider';

import contributionRoleChoices from '../../util/contributionRoleChoices';

import { BreadcrumbTitle } from '../BreadcrumbTitle';

const TextbookModuleForm: React.FC<{}> = (props) => (
  <SimpleForm variant="standard" margin="normal" {...props}>
    <ReferenceInput
      label="utgave"
      disabled
      reference="textbookEditions"
      source="editionId"
      fullWidth
    >
      <SelectInput optionText="descriptiveName" />
    </ReferenceInput>
    <TextInput disabled source="editionKey" fullWidth />

    <ArrayInput source="contributions">
      <SimpleFormIterator>
        <ReferenceInput source="contributorId" reference="contributors">
          <AutocompleteInput
            optionValue="id"
            optionText="fullname"
            options={{
              fullWidth: true,
            }}
            validate={required('Bidraget må være tilknyttet en bidragsyter')}
          />
        </ReferenceInput>
        <SelectInput
          source="role"
          choices={contributionRoleChoices}
          optionText="name"
          optionValue="role"
          validate={required('Bidragsyteren må ha en gyldig rolle')}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

const TextbookModuleEdit: React.FC<{}> = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const onSuccess: OnSuccess = ({ data }) => {
    const record = data as TextbookModule;
    notify('Dokument oppdatert', 'info');

    if (record.modulePath.length > 2) {
      // Hack since we do not know the `id` of our parent document...
      window.history.back();
    } else {
      redirect(`/textbookEditions/${data.editionId}/show`);
    }

    refresh();
  };

  return (
    <Edit
      {...props}
      title={<BreadcrumbTitle resourceName="bokmodul" recordName={(record) => record.fullTitle} />}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
    >
      <TextbookModuleForm />
    </Edit>
  );
};

export default TextbookModuleEdit;
