import React from 'react';
import { connect } from 'react-redux';
import { Create, SimpleForm, TextInput, DateInput, SelectInput, required } from 'react-admin';
import URI from 'urijs';

import {
  ACT_COUNTRIES,
  ACT_LANGUAGES,
  ACT_SUBTYPES,
  DOCUMENT_TYPES,
} from '../../state/legalDocuments/types';
import { CurrentUser } from '../../state/currentUser/types';

interface ReduxState {
  currentUser: CurrentUser | null;
}

interface Props {
  currentUserName: string;
}

const sanitizeProps = (props: Props) => {
  const { currentUserName, ...rest } = props;

  return rest;
};

const LegalDocumentCreate = (props: Props) => (
  <Create {...sanitizeProps(props)}>
    <SimpleForm
      variant="standard"
      margin="normal"
      initialValues={{
        editor: props.currentUserName,
      }}
      redirect={(basePath: string, id: string) =>
        URI(basePath).segment(id).search({ publicationStatus: 'DRAFT' }).toString()
      }
    >
      <SelectInput
        label="resources.legalDocuments.fields.work.countryCode"
        source="countryCode"
        defaultValue={ACT_COUNTRIES[0].id}
        choices={ACT_COUNTRIES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.documentType"
        source="documentType"
        defaultValue={DOCUMENT_TYPES[0].id}
        choices={DOCUMENT_TYPES}
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.work.subtype"
        source="subtype"
        choices={ACT_SUBTYPES}
        validate={required()}
      />
      <DateInput
        label="resources.legalDocuments.fields.work.date"
        source="date"
        validate={required()}
      />
      <TextInput
        label="resources.legalDocuments.fields.work.seq"
        source="seq"
        validate={required()}
      />
      <SelectInput
        label="resources.legalDocuments.fields.expression.languageCode"
        source="languageCode"
        defaultValue={ACT_LANGUAGES[0].id}
        choices={ACT_LANGUAGES}
        validate={required()}
      />
      <DateInput
        label="resources.legalDocuments.fields.expression.inForceAt"
        source="inForceDate"
        validate={required()}
      />
    </SimpleForm>
  </Create>
);

const mapStateToProps = (state: ReduxState) => ({
  currentUserName: state.currentUser?.name || '',
});

export default connect(mapStateToProps, {})(LegalDocumentCreate);
