import * as Slate from 'slate';
import uuidv4 from 'uuid/v4';

// Import related styles
import '../../styles/slateEditor/notice.css';

import { Plugin } from './types';
import * as tags from '../tags';
import { insertChildrenIfMissing } from '../utils/transforms';
import { processNodes } from '../utils/process';
import * as Selector from '../utils/selector';

const createImageNode = (): Slate.Node => {
  return {
    type: tags.BLOCK_IMAGE,
    src: '',
    alt: '',
    'data-uuid': uuidv4(),
    'data-fileid': '',
    children: [{ text: '' }],
  };
};

const createHeading = (): Slate.Node => {
  return { type: tags.BLOCK_HEADING_THREE, children: [{ text: 'Overskrift' }] };
};

const createParagraph = (): Slate.Node => {
  return { type: tags.BLOCK_PARAGRAPH, children: [{ text: 'Innhold' }] };
};

const createContentWrapper = (children: Slate.Node[]): Slate.Node => {
  const hasBlockChildren = children.find((child) => Slate.Element.isElement(child));

  return {
    type: tags.BLOCK_DIV,
    className: ['content'],
    children: hasBlockChildren ? children : [createHeading(), createParagraph()],
  };
};

const createTemplateNode = (imageNode: Slate.Node, rest: Slate.Node[]): Slate.Node => {
  return {
    type: tags.BLOCK_SECTION,
    className: ['summary'],
    children: [imageNode ?? createImageNode(), createContentWrapper(rest)],
  };
};

const postProcessNodesBeforeSerialization = (nodes: Slate.Node[]) => {
  return processNodes(nodes, {
    unwrap: (node) => {
      if (node.type === tags.BLOCK_SECTION || node.type === tags.BLOCK_DIV) return node.children;
    },
    delete: (node) => node.type === tags.BLOCK_IMAGE && !node.src,
  });
};

const preProcessNodesAfterDeserialization = (nodes: Slate.Node[]) => {
  const selector = new Selector.ChildSelector(nodes, {
    image: (...args) =>
      Selector.matchType(tags.BLOCK_IMAGE, ...args) && Selector.matchIndex(0, ...args),
  });

  const image = selector.select('image');
  const rest = selector.rest();

  if (image.length > 1) {
    throw Error('Selector selected multiple image nodes, expected 1');
  }

  return [createTemplateNode(image[0], rest)];
};

export default (): Plugin => {
  return {
    key: 'notice-body-plugin',
    preProcessNodesAfterDeserialization,
    postProcessNodesBeforeSerialization,
    withEditor: (editor) => {
      const { normalizeNode, getFragment, isVoid } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        // Forced layout
        const [node, path] = entry;

        if (path.toString() === [0].toString()) {
          insertChildrenIfMissing(
            editor,
            entry,
            [
              { index: 0, child: createImageNode() },
              { index: 1, child: createContentWrapper([]) },
            ],
            true
          );
        } else if (path.toString() === [0, 1].toString()) {
          if ((node as Slate.Element).children.length === 0) {
            Slate.Transforms.insertNodes(editor, createParagraph(), { at: path.concat(0) });
          }
        }

        normalizeNode(entry);
      };

      editor.getFragment = () => {
        return postProcessNodesBeforeSerialization(getFragment());
      };

      editor.isVoid = (element) => {
        return element.type === tags.BLOCK_IMAGE || isVoid(element);
      };

      return editor;
    },
  };
};
