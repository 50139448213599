import { GET_ONE } from 'react-admin';

import * as Types from '../types';
import { LovbackendService } from './lovbackendService';
import { generateLegalToc } from '../../state/expressions/generateLegalToc';

export const legalTocProvider = (urls: Types.BackendUrls) => (
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<{ data: any; total: number }> => provider(urls.lovEditorBackend, type, params);

const provider = (
  baseApiUrl: string,
  type: Types.ReactAdminApiType,
  params: Types.ReactAdminApiParams
): Promise<any> => {
  const backend = new LovbackendService(baseApiUrl);

  switch (type) {
    case GET_ONE:
      return backend
        .getItemXml(params.id!!, undefined)
        .then((xml) => generateLegalToc(xml))
        .then((toc) => ({ data: { id: params.id!!, toc: toc } }));

    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};
