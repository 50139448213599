import * as Slate from 'slate';
import { getAboveByType, isMarkActive } from './query';

interface RequiredChilren {
  index: number;
  child: Slate.Node;
}

export const toggleElement = (editor: Slate.Editor, type: string) => {
  const inHierarchy = getAboveByType(editor, type);

  if (inHierarchy) {
    Slate.Transforms.unwrapNodes(editor, {
      match: (node: Slate.Node) => node.type === type,
      mode: 'lowest',
    });
  } else {
    Slate.Transforms.wrapNodes(editor, { type: type, children: [{ text: '' }] });
  }
};

/**
 *
 * @param type: The type to check if is in hierarchy
 * @param unsetType Will set node to this type when node is active
 * @param newProps Set new props
 */
export const unsetElementIfActive = (
  editor: Slate.Editor,
  type: string,
  unsetType: string,
  newProps: { [key: string]: any }
) => {
  const isActive = !!getAboveByType(editor, type);
  Slate.Transforms.setNodes(editor, {
    type: isActive ? unsetType : type,
    ...newProps,
  });
};

/**
 * Inserts node or else remove if same type exists in hierarchy
 */
export const insertOrRemoveElement = (editor: Slate.Editor, node: Slate.Element) => {
  const type = node.type as string;
  const isActive = getAboveByType(editor, type);

  if (!isActive) {
    Slate.Transforms.insertNodes(editor, node);
  } else {
    Slate.Transforms.removeNodes(editor, {
      match: (node: Slate.Node) => node.type === type,
    });
  }
};

export const toggleMark = (editor: Slate.Editor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Slate.Editor.removeMark(editor, format);
  } else {
    Slate.Editor.addMark(editor, format, true);
  }
};

export const wrapChildNodes = (
  editor: Slate.Editor,
  path: Slate.Path,
  element: Slate.Element,
  match?: (node: Slate.Node) => boolean
) => {
  let previous: Slate.Path | null = null;
  Slate.Editor.withoutNormalizing(editor, () => {
    for (const [child, childPath] of Slate.Node.children(editor, path, { reverse: true })) {
      if (!(match?.(child) ?? true)) continue;
      Slate.Transforms.wrapNodes(editor, element, { at: childPath, voids: true });

      // Prevent multiple paragraph when two inles are next to each other
      if (previous) {
        Slate.Transforms.mergeNodes(editor, {
          at: previous,
          match: (node: Slate.Node) => node.type === element.type,
        });
      }

      // Since merge node works by merging current node with previous...
      previous = childPath;
    }
  });
};

export const insertChildrenIfMissing = (
  editor: Slate.Editor,
  parentEntry: Slate.NodeEntry,
  requiredChildren: RequiredChilren[],
  skipRestAfterFirstInsertion: boolean = false
): boolean => {
  for (const { index, child } of requiredChildren) {
    if (
      insertChildIfMissing(editor, parentEntry, index, child.type as string, child) &&
      skipRestAfterFirstInsertion
    ) {
      return true;
    }
  }
  return false;
};

export const insertChildIfMissing = (
  editor: Slate.Editor,
  parentEntry: Slate.NodeEntry,
  requiredAtIndex: number,
  requiredType: string,
  child: Slate.Node
): boolean => {
  const [node, path] = parentEntry;
  if (!Slate.Element.isElement(node)) return false;
  const children = (node as Slate.Element).children.map((child) => child.type);
  if (children[requiredAtIndex] === requiredType) return false;

  Slate.Transforms.insertNodes(editor, child, {
    at: path.concat(requiredAtIndex),
  });

  return true;
};

/**
 * Finds above type, then insert node after
 */
export const insertNodeAfterAboveByType = (
  editor: Slate.Editor,
  node: Slate.Node,
  aboveTypes: string[] | string
) => {
  const aboveNodeEntry = getAboveByType(editor, aboveTypes);

  if (aboveNodeEntry) {
    const [, aboveNodePath] = aboveNodeEntry;
    Slate.Transforms.insertNodes(editor, node, {
      at: [...aboveNodePath.slice(0, -1), aboveNodePath[aboveNodePath.length - 1] + 1],
      select: true,
    });
    return;
  }
};
