import * as Slate from 'slate';
import { Plugin } from './types';
import * as tags from '../tags';

// Import related styles
import '../../styles/slateEditor/notice.css';

const unwrapFragments = (nodes: Slate.Node[]) => {
  return nodes.reduce((acc: Slate.Node[], node: Slate.Node) => {
    if (node.type === tags.BLOCK_FRAGMENT) return [...acc, ...(node as Slate.Element).children];
    else return [...acc, node];
  }, []);
};

export default (): Plugin => {
  return {
    key: 'single-line-plugin',
    preProcessNodesAfterDeserialization: (nodes: Slate.Node[]) => {
      return [
        {
          type: tags.BLOCK_FRAGMENT,
          children: unwrapFragments(nodes),
        },
      ];
    },
    postProcessNodesBeforeSerialization: (nodes: Slate.Node[]) => {
      return unwrapFragments(nodes);
    },
    withEditor: (editor) => {
      const { normalizeNode } = editor;

      editor.insertBreak = () => {
        // Prevent ENTER, this will make sure that we never get multiple fragments
      };

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node, path] = entry;

        if (path.length === 0) {
          const children = (node as Slate.Element).children;

          if (children.length === 0) {
            Slate.Transforms.insertNodes(editor, {
              type: tags.BLOCK_FRAGMENT,
              children: [{ text: '' }],
            });
            return;
          }
        }

        normalizeNode(entry);
      };

      return editor;
    },
  };
};
