import * as React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { ExpandLess, ExpandMore, Done, Error } from '@material-ui/icons';
import {
  TocItem,
  CommentaryMetadataForAllExpressionFragments,
} from '../../../state/expressions/types';
import { CommentaryLinkingStatus } from '../../../state/commentaryLinking/types';

interface ActExpressionTocProps {
  tocItem: TocItem;
  nestedClassName: string;
  selectedFragmentIds: { eId: string; injectCommentBody: boolean; injectCuratedSources: boolean }[];
  fragmentsLinkingStatuses: CommentaryLinkingStatus;
  commentaryMeta: CommentaryMetadataForAllExpressionFragments;
  onSelectId: (fragmentId: string, isCuratedSource: boolean) => void;
  onShowInjectResults: (fragmentId: string) => void;
}

const ActExpressionTocItem: React.FC<ActExpressionTocProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const renderToggle = () => {
    if (props.tocItem.children.length === 0) return null;
    return (
      <React.Fragment>
        {open ? (
          <ExpandLess onClick={() => setOpen(false)} />
        ) : (
          <ExpandMore onClick={() => setOpen(true)} />
        )}
      </React.Fragment>
    );
  };

  const renderStatus = () => {
    switch (props.fragmentsLinkingStatuses[props.tocItem.eId]) {
      case 'SUCCESSFUL':
        return (
          <React.Fragment>
            <button onClick={() => props.onShowInjectResults(props.tocItem.eId)}>
              <Done fontSize="inherit" htmlColor="#00c853" /> Lenket (trykk for å se resultater)
            </button>
          </React.Fragment>
        );
      case 'FAILED':
        return (
          <React.Fragment>
            Lenking Feilet <Error fontSize="inherit" color="error" />
          </React.Fragment>
        );
      case 'RUNNING':
        return (
          <React.Fragment>
            Lenkescriptet kjører <CircularProgress size="12px" />
          </React.Fragment>
        );
      case 'PENDING':
        return (
          <React.Fragment>
            I kø <CircularProgress size="12px" />
          </React.Fragment>
        );
    }
  };

  const renderCheckboxes = () => {
    const isPending = props.fragmentsLinkingStatuses[props.tocItem.eId] === 'RUNNING';
    const commentMeta = props.commentaryMeta[props.tocItem.eId];
    const isDisabled = isPending || !(commentMeta?.draft || commentMeta?.published.length > 0);
    const selectedFragmentId = props.selectedFragmentIds.find((id) => id.eId === props.tocItem.eId);
    const isCommentBodySelected = selectedFragmentId ? selectedFragmentId.injectCommentBody : false;
    const isCuratedSourcesSelected = selectedFragmentId
      ? selectedFragmentId.injectCuratedSources
      : false;

    return (
      <React.Fragment>
        <Checkbox
          checked={isCommentBodySelected}
          disabled={isDisabled}
          style={{ color: isDisabled ? undefined : '#ff1744', width: 25 }}
          onClick={() => props.onSelectId(props.tocItem.eId, false)}
        />
        <Checkbox
          checked={isCuratedSourcesSelected}
          disabled={isDisabled}
          style={{ color: isDisabled ? undefined : '#42a5f5', width: 25 }}
          onClick={() => props.onSelectId(props.tocItem.eId, true)}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <ListItem>
        {renderCheckboxes()}
        <ListItemText
          primary={<span dangerouslySetInnerHTML={{ __html: props.tocItem.heading }} />}
          secondary={renderStatus()}
        />
        {renderToggle()}
      </ListItem>

      <Collapse in={open} unmountOnExit>
        <List className={props.nestedClassName} dense>
          {props.tocItem.children.map((child: TocItem) => (
            <ActExpressionTocItem {...props} key={child.eId} tocItem={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default ActExpressionTocItem;
