import * as Slate from 'slate';

import { Plugin } from './types';
import * as tags from '../tags';
import { getAboveByType } from '../utils/query';

const key = 'paragaph-plugin';

const splitToParagraph = [
  tags.BLOCK_HEADING_ONE,
  tags.BLOCK_HEADING_TWO,
  tags.BLOCK_HEADING_THREE,
  tags.BLOCK_HEADING_FOUR,
  tags.BLOCK_HEADING_FIVE,
  tags.BLOCK_HEADING_SIX,
  tags.BLOCK_LEAD_PARAGRAPH,
  tags.BLOCK_IMAGE,
  tags.BLOCK_IFRAME,
];

export default (): Plugin => {
  return {
    key,
    withEditor: (editor) => {
      const { normalizeNode, insertBreak } = editor;

      editor.normalizeNode = (entry: Slate.NodeEntry) => {
        const [node, path] = entry;

        if (node.type === tags.BLOCK_PARAGRAPH) {
          for (const [child, childPath] of Slate.Node.children(node, [])) {
            if (Slate.Element.isElement(child)) {
              // Do not allow nested blocks
              if (!editor.isInline(child)) {
                Slate.Transforms.unwrapNodes(editor, { at: [...path, ...childPath] });
                return;
              }
            }
          }
        }

        normalizeNode(entry);
      };

      editor.insertBreak = () => {
        if (getAboveByType(editor, splitToParagraph)) {
          Slate.Transforms.splitNodes(editor, { always: true });
          Slate.Transforms.setNodes(editor, { type: tags.BLOCK_PARAGRAPH, className: null });
          return;
        }

        insertBreak();
      };

      return editor;
    },
  };
};
