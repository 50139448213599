import { createStandardAction, ActionType } from 'typesafe-actions';
import { Toc } from './types';
import * as constants from './expressionConstants';

export const generateExpressionToc = createStandardAction(constants.GENERATE_EXPRESSION_TOC).map(
  (params: { uri: string; publicationStatus: string }) => ({
    payload: params,
  })
);

export const generateExpressionTocSuccess = createStandardAction(
  constants.GENERATE_EXPRESSION_TOC_SUCCESS
).map((params: { id: string; toc: Toc }) => ({ payload: params }));

export const generateExpressionTocFailure = createStandardAction(
  constants.GENERATE_EXPRESSION_TOC_FAILURE
).map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.expression_toc.notification.generate_toc_failure',
      level: 'warning',
    },
  },
}));

export const getExpressionXML = createStandardAction(
  constants.GET_EXPRESSION_XML
).map((id: string, query: { publicationStatus: string }) => ({ payload: { id, data: { query } } }));

export const getExpressionXMLSuccess = createStandardAction(
  constants.GET_EXPRESSION_XML_SUCCESS
).map((expression: { id: string; xml: string }) => ({ payload: { expression } }));

export const getExpressionXMLFailure = createStandardAction(
  constants.GET_EXPRESSION_XML_FAILURE
).map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.legalDocuments.notification.get_legal_document_xml_failure',
      level: 'warning',
    },
  },
}));

export const save = createStandardAction(constants.SAVE_EXPRESSION).map(
  (
    id: string,
    data: {
      editor: string;
      xmlDocument: XMLDocument;
      successCallback(): void;
    }
  ) => {
    const { successCallback, ...rest } = data;
    return {
      payload: { id, data: rest },
      meta: {
        onSuccess: {
          callback: successCallback,
        },
      },
    };
  }
);

export const saveSuccess = createStandardAction(constants.SAVE_EXPRESSION_SUCCESS).map(
  ({
    payload,
    meta,
  }: {
    payload: { expression: { id: string; xml: string } };
    meta: { callback(): void };
  }) => ({
    payload: payload,
    meta: {
      notification: {
        body: 'resources.legalDocuments.notification.save_success',
        level: 'info',
      },
      ...meta,
    },
  })
);

export const saveFailure = createStandardAction(constants.SAVE_EXPRESSION_FAILURE).map(() => ({
  payload: {},
  meta: {
    notification: {
      body: 'resources.legalDocuments.notification.save_failure',
      level: 'warning',
    },
  },
}));

export const publish = createStandardAction(constants.PUBLISH_EXPRESSION).map(
  (
    id: string,
    data: {
      editor: string;
      successCallback: () => void;
    }
  ) => {
    const { successCallback, ...rest } = data;
    return {
      payload: { id, data: rest },
      meta: {
        onSuccess: {
          callback: successCallback,
        },
      },
    };
  }
);

export type PublishAction = typeof publish;
export type PublishExpression = ActionType<PublishAction>;

export const publishSuccess = createStandardAction(constants.PUBLISH_EXPRESSION_SUCCESS).map(
  (successCallback: () => void) => ({
    payload: {},
    meta: {
      notification: {
        body: 'resources.legalDocuments.notification.publish_success',
        level: 'info',
      },
      callback: successCallback,
      refresh: true,
    },
  })
);

export const publishFailure = createStandardAction(constants.PUBLISH_EXPRESSION_FAILURE).map(
  () => ({
    payload: {},
    meta: {
      notification: {
        body: 'resources.legalDocuments.notification.publish_failure',
        level: 'warning',
      },
    },
  })
);
