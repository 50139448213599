import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import CmsContextMenu from '../../CmsContextMenu';
import { SelectedElement, Stack, UiContext } from '../BitsDebugUtil';
import { BitsDebugToolbarConvertContextMenu } from './BitsDebugToolbarConvertContextMenu';
import { BitsDebugToolbarUploadButton } from './BitsDebugToolbarUploadButton';

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  breadcrumbs: {
    fontFamily: 'monospace',
  },
  breadcrumbSeparator: {
    color: '#aaa',
  },
  mutedBreadcrumbTagName: {
    color: '#aaa',
  },
  breadcrumbTagName: {
    color: '#b7d7e8',
  },
  breadcrumbAttrib: {
    color: '#82b74b',
  },
  breadcrumbAttribValue: {
    color: '#b5e7a0',
  },
});

export const BitsDebugToolbar: React.FC = () => {
  const classes = useStyles();
  const {
    book,
    processId,
    documentMutated,
    selectedHighLevelElement,
    selectedLowLevelElement,
  } = React.useContext(UiContext);

  return (
    <CmsContextMenu>
      <div className={classes.toolbar}>
        <div className={classes.breadcrumbs}>
          <Breadcrumbs
            selectedHighLevelElement={selectedHighLevelElement}
            selectedLowLevelElement={selectedLowLevelElement}
          />
        </div>
        <BitsDebugToolbarConvertContextMenu stack={selectedHighLevelElement?.stack} />
        <BitsDebugToolbarUploadButton
          processId={processId}
          book={book}
          disabled={!documentMutated}
        />
      </div>
    </CmsContextMenu>
  );
};

const Breadcrumbs: React.FC<{
  selectedHighLevelElement: SelectedElement | null;
  selectedLowLevelElement: SelectedElement | null;
}> = ({ selectedHighLevelElement, selectedLowLevelElement }) => {
  const classes = useStyles();
  const breadcrumbStacks = createBreadcrumbStackArray(
    selectedHighLevelElement,
    selectedLowLevelElement
  );

  const breadcrumbs = breadcrumbStacks.map((stack, index) => (
    <BreadcrumbItem stack={stack} index={index} />
  ));

  const joinedBreadcrumbs = breadcrumbs.reduce((agg, breadcrumb, index, array) => {
    if (index >= array.length - 1) {
      return [...agg, breadcrumb];
    }
    return [...agg, breadcrumb, <span className={classes.breadcrumbSeparator}>{' → '}</span>];
  }, [] as React.ReactElement[]);

  return <>{joinedBreadcrumbs}</>;
};

const BreadcrumbItem: React.FC<{ stack: Stack; index: number }> = ({ stack, index }) => {
  const classes = useStyles();
  const { element } = stack;
  if (index === 0) {
    return <span className={classes.mutedBreadcrumbTagName}>{element.tagName}</span>;
  }

  const attributes = Object.keys(element.attributes).reduce(
    (agg, key) => [
      ...agg,
      <span className={classes.breadcrumbAttrib}>{` ${key}="`}</span>,
      <span className={classes.breadcrumbAttribValue}>{`${element.attributes[key]}`}</span>,
      <span className={classes.breadcrumbAttrib}>{`"`}</span>,
    ],
    [] as React.ReactElement[]
  );

  return (
    <>
      <span className={classes.breadcrumbTagName}>{element.tagName}</span>
      {attributes}
    </>
  );
};

const createBreadcrumbStackArray = (
  selectedHighLevelElement: SelectedElement | null,
  selectedLowLevelElement: SelectedElement | null
): Array<Stack> => {
  const highLevelStackArray = stackToArray(selectedHighLevelElement?.stack);
  const lowLevelStackArray = stackToArray(selectedLowLevelElement?.stack);

  lowLevelStackArray.splice(0, Math.max(highLevelStackArray.length - 1, 0));

  return lowLevelStackArray;
};

const stackToArray = (stack: Stack | null | undefined): Array<Stack> => {
  const stackArray: Array<Stack> = [];

  let current: Stack | null | undefined = stack;
  while (current) {
    stackArray.push(current);

    current = current.stack;
  }

  stackArray.reverse();
  return stackArray;
};
