import h from 'hastscript';
import * as t from 'io-ts';

import * as Types from './types';
import { parseNullableDate, halJsonCrudApiProvider } from './apiUtil';

import {
  BaseDocumentSnapshot,
  BaseDocumentSnapshotApiIncomingTypeV,
} from './models/staticContentCommonModels';

import {
  StaticDocumentRevisionOutgoingApiType,
  mapApiRevisionToFrontend,
  toApiMapper as staticDocumentRevisionToApiMapper,
} from './staticDocumentRevisionsProvider';

export type EmbeddedForeignType =
  | 'contributor_bio'
  | 'literature_publication_review'
  | 'literature_edition_review'
  | 'literature_document_review';

export interface EmbeddedSnapshot extends BaseDocumentSnapshot {
  foreignId: string;
  foreignType: EmbeddedForeignType;
}

export const createEmptyEmbeddedSnapshot = (): EmbeddedSnapshot => ({
  id: '',
  createdById: '',
  currentEditorId: '',
  currentEditorIsCurrentUser: true,
  firstPublishedAt: null,
  lastPublishedAt: null,
  revision: {
    id: '',
    documentId: '',
    createdAt: new Date(),
    createdById: '',
    publishedAt: null,
    contentCategoryId: '',
    title: '',
    hast: h(
      'fragment',
      {},
      h('p', { className: ['leadParagraph'] }, 'Innledning'),
      h('p', {}, 'Tekst')
    ),
    contributions: [],
    tags: [],
  },
  foreignId: '',
  foreignType: 'contributor_bio',
});

const ApiForeignTypeV = t.keyof({
  contributor_bio: null,
  literature_publication_review: null,
  literature_edition_review: null,
  literature_document_review: null,
});

const ApiIncomingTypeV = t.intersection([
  BaseDocumentSnapshotApiIncomingTypeV,
  t.type({
    foreignId: t.string,
    foreignType: ApiForeignTypeV,
  }),
]);
type ApiIncomingType = t.TypeOf<typeof ApiIncomingTypeV>;

interface ApiOutgoingType {
  revision: StaticDocumentRevisionOutgoingApiType;
  foreignId: string;
  foreignType: EmbeddedForeignType;
}

export const toApiMapper = (snapshot: EmbeddedSnapshot): ApiOutgoingType => ({
  revision: staticDocumentRevisionToApiMapper(snapshot.revision),
  foreignId: snapshot.foreignId,
  foreignType: snapshot.foreignType,
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<EmbeddedSnapshot> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/embeddedSnapshots`,
    halListName: 'adminEmbeddedSnapshotList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (apiSnapshot: ApiIncomingType): EmbeddedSnapshot => ({
      id: apiSnapshot.id,
      createdById: apiSnapshot.createdById,
      currentEditorId: apiSnapshot.currentEditorId,
      currentEditorIsCurrentUser: apiSnapshot.currentEditorIsCurrentUser,
      revision: mapApiRevisionToFrontend(apiSnapshot.revision),
      firstPublishedAt: parseNullableDate(apiSnapshot.firstPublishedAt),
      lastPublishedAt: parseNullableDate(apiSnapshot.lastPublishedAt),
      foreignId: apiSnapshot.foreignId,
      foreignType: apiSnapshot.foreignType,
    }),
    toApiMapper,
    toApiPartialMapper: toApiMapper,
    referenceParams: {
      foreignId: 'foreignId',
    },
    filterParams: {
      q: (q) => ({ q }),
      revision: {
        contentCategoryId: (id) => ({ contentCategoryId: id }),
      },
      foreignId: (foreignId) => ({ foreignId }),
      foreignType: (foreignType) => ({ foreignType }),
    },
    sortFields: [],
  });
