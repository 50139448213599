import React from 'react';
import {
  Create,
  Edit,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  CheckboxGroupInput,
  TextInput,
  Labeled,
  FormDataConsumer,
  required,
} from 'react-admin';

import {
  NoticeSnapshot,
  createEmptyNoticeSnapshot,
} from '../../dataProvider/noticeSnapshotsProvider';
import { Feed } from '../../dataProvider/feedsProvider';
import { ContentCategory } from '../../dataProvider/contentCategoriesProvider';
import { defaultFeedIdsForContentCategory } from '../../util/staticContentAutosuggestUtil';
import noticeBodyDocumentSpec from '../../slate/documentSpecs/noticeBodySpec';

import { DocumentLockedMessage } from '../staticContentCommon/DocumentLockedMessage';
import SlateTextInput from '../SlateTextInput';

import { FeedsAutoSuggestQuery, AutoSuggestData } from './FeedsAutoSuggestQuery';
import { ContributionsInput } from '../staticContentCommon/ContributionsInput';

const FeedsInput: React.FC<{
  contentCategoryId: string | undefined;
  autosuggestData: AutoSuggestData;
  disabled: boolean;
}> = ({ contentCategoryId, autosuggestData, disabled, ...rest }) => {
  const suggestedFeedIds = React.useMemo(() => {
    return defaultFeedIdsForContentCategory(
      contentCategoryId,
      autosuggestData.allFeeds,
      autosuggestData.allContentCategories
    );
  }, [contentCategoryId, autosuggestData.allFeeds, autosuggestData.allContentCategories]);

  return (
    <ReferenceArrayInput
      source="feeds"
      reference="feeds"
      sort={{ field: 'name', order: 'ASC' }}
      defaultValue={suggestedFeedIds}
      allowEmpty
      disabled={disabled}
      {...rest}
    >
      <CheckboxGroupInput optionText="name" disabled={disabled} />
    </ReferenceArrayInput>
  );
};

const isMutable = (record: NoticeSnapshot | undefined) => {
  if (!record) return true;
  return record.currentEditorIsCurrentUser !== false;
};

const NoticeSnapshotForm: React.FC<{
  isNew: boolean;
  initialValues?: NoticeSnapshot;
  record?: NoticeSnapshot;
  redirect?: boolean;
  suggestData?: {
    allFeeds: Feed[];
    allContentCategories: ContentCategory[];
  };
}> = ({ record, isNew = false, redirect = true, ...props }) => {
  const disabled = !isMutable(record);

  return (
    <SimpleForm
      {...props}
      variant="standard"
      margin="normal"
      record={record}
      redirect={redirect}
      sanitizeEmptyValues={false}
    >
      {disabled && <DocumentLockedMessage />}
      {!isNew && <TextInput disabled source="id" fullWidth />}
      <ReferenceInput
        source="revision.contentCategoryId"
        reference="contentCategories"
        disabled={disabled}
        validate={required('Notisen må ha en kategori')}
      >
        <SelectInput source="revision.name" />
      </ReferenceInput>

      <FormDataConsumer>
        {({ formData, ...rest }: { formData: NoticeSnapshot }) => (
          <FeedsAutoSuggestQuery>
            {(autosuggestData) => {
              const contentCategoryId =
                formData && formData.revision && formData.revision.contentCategoryId;

              return (
                <FeedsInput
                  contentCategoryId={contentCategoryId}
                  autosuggestData={autosuggestData}
                  disabled={disabled}
                  {...rest}
                />
              );
            }}
          </FeedsAutoSuggestQuery>
        )}
      </FormDataConsumer>

      <ReferenceInput
        source="linkedPage"
        reference="pageSnapshots"
        allowEmpty
        fullWidth
        disabled={disabled}
      >
        {isNew ? (
          <AutocompleteInput
            optionValue="id"
            optionText="revision.title"
            allowEmpty={true}
            disabled={true}
            options={{
              fullWidth: true,
            }}
          />
        ) : (
          <SelectInput optionValue="id" optionText="revision.title" disabled={true} />
        )}
      </ReferenceInput>

      <TextInput
        multiline
        source="revision.title"
        disabled={disabled}
        validate={required(
          'Notisen må ha en tittel (for søk i CMS. Ideelt sett det samme som Overskrift.)'
        )}
      />

      <Labeled key="revision.hast" label="Innhold:" fullWidth>
        <SlateTextInput
          source="revision.hast"
          documentSpec={noticeBodyDocumentSpec}
          disabled={disabled}
        />
      </Labeled>

      <ContributionsInput
        resourceName="noticeSnapshots"
        disabled={disabled}
        showOccupationField={false}
      />
    </SimpleForm>
  );
};

export const NoticeSnapshotCreate: React.FC<any> = (props) => (
  <Create {...props} title={<span>Ny notis</span>}>
    <NoticeSnapshotForm isNew={true} initialValues={createEmptyNoticeSnapshot()} />
  </Create>
);

const EditTitle: React.FC<{
  record?: NoticeSnapshot;
}> = ({ record: snapshot }) => <span>Revider {snapshot && `"${snapshot.revision.title}"`} </span>;

export const NoticeSnapshotEdit: React.FC<any> = (props) => (
  <Edit undoable={false} title={<EditTitle />} {...props}>
    <NoticeSnapshotForm isNew={false} />
  </Edit>
);
