import { TextInput } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

/**
 * Style TextInput with a monospace font, perfect to signal that this is code-like.
 */
export const CodeInput = withStyles({
  root: {
    '& input': {
      fontFamily: 'monospace',
    },
  },
})(TextInput);
