import * as Slate from 'slate';
import * as tags from '../../tags';

export const createEmptyTableCellNode = (isHeader: boolean = false): Slate.Node => {
  return {
    type: isHeader ? tags.BLOCK_TABLE_HEADER_CELL : tags.BLOCK_TABLE_CELL,
    children: [{ type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }],
  };
};

export const createEmptyTableRow = (columns: number, isHeader: boolean = false): Slate.Node => {
  return {
    type: tags.BLOCK_TABLE_ROW,
    children: Array(columns)
      .fill(columns)
      .map(() => createEmptyTableCellNode(isHeader)),
  };
};

export const createEmptyTable = (hasHeader: boolean = false): Slate.Node => {
  return {
    type: tags.BLOCK_TABLE,
    children: [
      {
        type: tags.BLOCK_TABLE_BODY,
        children: [createEmptyTableRow(2, hasHeader), createEmptyTableRow(2, false)],
      },
    ],
  };
};
