import * as io from 'io-ts';

import * as Types from './types';
import { halJsonCrudApiProvider, parseNullableDate } from './apiUtil';

interface ProcessChecklist {
  text: string;
}

export interface ConfigData {
  titlePageName: string | null;
  colophonPageName: string | null;
  bookBodyStartPageName: string | null;
  backMatterStartPageName: string | null;
  indesignStyles: {
    exclude: string[] | null;
    characterExclude: string[] | null;
    quote: string[] | null;
    list: string[] | null;
    tableHeader: string[] | null;
    petit: string[] | null;
    caption: string[] | null;
  } | null;
  frontMatterIndesignTocStyles: Array<InDesignTocStyle> | null;
  indesignTocStyles: Array<InDesignTocStyle> | null;
  backMatterIndesignTocStyles: Array<InDesignTocStyle> | null;
  pdfPageLabelRanges: Array<{
    index: number;
    prefix: string | null;
    style: string;
    start: number;
  }> | null;
  pdfNamePatterns: Array<{
    matchPattern: string | null;
    namePrefix: string | null;
  }> | null;
}

type InDesignTocStyleOption =
  | 'includeInToc'
  | 'resetParagraphCounter'
  | 'resetFootnoteCounter'
  | 'deduplicateGhostMarginTitle';

export interface InDesignTocStyle {
  name: string;
  level: number;
  bitsUnit: string | null;
  subtitleStyles: string[] | null;
  options: Array<InDesignTocStyleOption>;
  titleLabelPattern: string | null;
}

export interface LiteratureConversionProcess {
  id: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  config: {
    createdAt: Date;
    data: ConfigData;
  } | null;
  customIdmlFormat: {
    createdAt: Date;
    metadata: {
      namedParagraphStyles: string[];
      namedCharacterStyles: string[];
    };
  } | null;
  latestBitsFormatCreatedAt: Date | null;
  originalPdfFormatCreatedAt: Date | null;
  outputPdfFormatCreatedAt: Date | null;
  checklist: ProcessChecklist[];
  editionId: string | null;
}

export interface PartialLiteratureConversionProcess {
  description?: string;
  config?: {
    data: ConfigData;
  };
  editionId?: string | null;
}

const nullableStringIO = io.union([io.string, io.null]);
const nullableStringArrayIO = io.union([io.array(io.string), io.null]);

const indesignTocStyleIO = io.type({
  name: io.string,
  level: io.number,
  bitsUnit: nullableStringIO,
  subtitleStyles: nullableStringArrayIO,
  includeInToc: io.boolean,
  resetParagraphCounter: io.boolean,
  resetFootnoteCounter: io.boolean,
  deduplicateGhostMarginTitle: io.boolean,
  titleLabelPattern: nullableStringIO,
});

const configDataIO = io.type({
  titlePageName: nullableStringIO,
  colophonPageName: nullableStringIO,
  bookBodyStartPageName: nullableStringIO,
  backMatterStartPageName: nullableStringIO,
  indesignStyles: io.union([
    io.type({
      exclude: nullableStringArrayIO,
      characterExclude: nullableStringArrayIO,
      quote: nullableStringArrayIO,
      list: nullableStringArrayIO,
      tableHeader: nullableStringArrayIO,
      petit: nullableStringArrayIO,
      caption: nullableStringArrayIO,
    }),
    io.null,
  ]),
  frontMatterIndesignTocStyles: io.union([io.array(indesignTocStyleIO), io.null]),
  indesignTocStyles: io.union([io.array(indesignTocStyleIO), io.null]),
  backMatterIndesignTocStyles: io.union([io.array(indesignTocStyleIO), io.null]),
  pdfPageLabelRanges: io.union([
    io.array(
      io.type({
        index: io.number,
        prefix: nullableStringIO,
        style: io.string,
        start: io.number,
      })
    ),
    io.null,
  ]),
  pdfNamePatterns: io.union([
    io.array(
      io.type({
        matchPattern: nullableStringIO,
        namePrefix: nullableStringIO,
      })
    ),
    io.null,
  ]),
  // TODO: Perhaps integrate this later, because it's recursive which is a bit more of a hassle
  // and I'm lazy right now:
  // pdfOutline: Array<PdfOutlineItem> | null;
});

const apiTypeIO = io.type({
  id: io.string,
  description: io.string,
  createdAt: io.string,
  updatedAt: io.string,
  config: io.union([
    io.type({
      createdAt: io.string,
      data: configDataIO,
    }),
    io.null,
  ]),
  customIdmlFormat: io.union([
    io.type({
      createdAt: io.string,
      metadata: io.type({
        namedParagraphStyles: io.array(io.string),
        namedCharacterStyles: io.array(io.string),
      }),
    }),
    io.null,
  ]),
  latestBitsFormatCreatedAt: io.union([io.string, io.null]),
  originalPdfFormatCreatedAt: io.union([io.string, io.null]),
  outputPdfFormatCreatedAt: io.union([io.string, io.null]),
  editionId: io.union([io.string, io.null]),
});
type ApiType = io.TypeOf<typeof apiTypeIO>;

interface ApiOutgoingType {
  description: string;
  config: {
    data: io.TypeOf<typeof configDataIO>;
  } | null;
}

const apiConfigDataToReactAdmin = (configData: io.TypeOf<typeof configDataIO>): ConfigData => {
  return {
    ...configData,
    frontMatterIndesignTocStyles: apiInDesignTocStylesToReactAdmin(
      configData.frontMatterIndesignTocStyles
    ),
    indesignTocStyles: apiInDesignTocStylesToReactAdmin(configData.indesignTocStyles),
    backMatterIndesignTocStyles: apiInDesignTocStylesToReactAdmin(
      configData.backMatterIndesignTocStyles
    ),
  };
};

const apiInDesignTocStylesToReactAdmin = (
  tocStyles: Array<io.TypeOf<typeof indesignTocStyleIO>> | null
): Array<InDesignTocStyle> | null => {
  if (tocStyles == null) return null;

  const allOptions: Array<InDesignTocStyleOption> = [
    'includeInToc',
    'resetParagraphCounter',
    'resetFootnoteCounter',
    'deduplicateGhostMarginTitle',
  ];

  return tocStyles.map(
    ({ name, level, bitsUnit, subtitleStyles, titleLabelPattern, ...options }) => ({
      name,
      level: level + 1, // from "machine-represented" level to human level :)
      bitsUnit,
      subtitleStyles,
      options: allOptions.filter((option) => options[option] === true),
      titleLabelPattern,
    })
  );
};

const reactAdminConfigDataToApi = (configData: ConfigData): io.TypeOf<typeof configDataIO> => ({
  ...configData,
  frontMatterIndesignTocStyles: reactAdminInDesignTocStylesToApi(
    configData.frontMatterIndesignTocStyles
  ),
  indesignTocStyles: reactAdminInDesignTocStylesToApi(configData.indesignTocStyles),
  backMatterIndesignTocStyles: reactAdminInDesignTocStylesToApi(
    configData.backMatterIndesignTocStyles
  ),
});

const reactAdminInDesignTocStylesToApi = (
  tocStyles: Array<InDesignTocStyle> | null | undefined
): Array<io.TypeOf<typeof indesignTocStyleIO>> | null => {
  if (!tocStyles) return null;

  return tocStyles.map(({ name, level, bitsUnit, subtitleStyles, options, titleLabelPattern }) => ({
    name,
    level: level - 1, // from human level to machine level :)
    bitsUnit,
    subtitleStyles,
    includeInToc: options.indexOf('includeInToc') >= 0,
    resetParagraphCounter: options.indexOf('resetParagraphCounter') >= 0,
    resetFootnoteCounter: options.indexOf('resetFootnoteCounter') >= 0,
    deduplicateGhostMarginTitle: options.indexOf('deduplicateGhostMarginTitle') >= 0,
    titleLabelPattern,
  }));
};

export const toApiMapper = ({
  description,
  config,
}: LiteratureConversionProcess): ApiOutgoingType => ({
  description: description,
  config: config
    ? {
        data: reactAdminConfigDataToApi(config.data),
      }
    : null,
});

export interface ApiPartialOutgoingType {
  description?: string;
  config?: {
    data: io.TypeOf<typeof configDataIO>;
  };
  editionId: string | null;
}

export const toApiPartialMapper = ({
  description,
  config,
  editionId,
}: PartialLiteratureConversionProcess): ApiPartialOutgoingType => ({
  description: description,
  config: config
    ? {
        data: reactAdminConfigDataToApi(config.data),
      }
    : undefined,
  editionId: editionId ?? null,
});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<LiteratureConversionProcess> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/conversionProcesses`,
    halListName: 'conversionProcessList',
    incomingType: apiTypeIO,
    toReactAdminMapper: (process: ApiType): LiteratureConversionProcess => ({
      id: process.id,
      description: process.description,
      createdAt: new Date(process.createdAt),
      updatedAt: new Date(process.updatedAt),
      config: process.config
        ? {
            createdAt: new Date(process.config.createdAt),
            data: apiConfigDataToReactAdmin(process.config.data),
          }
        : null,
      customIdmlFormat: process.customIdmlFormat
        ? {
            createdAt: new Date(process.customIdmlFormat.createdAt),
            metadata: {
              namedParagraphStyles: process.customIdmlFormat.metadata.namedParagraphStyles,
              namedCharacterStyles: process.customIdmlFormat.metadata.namedCharacterStyles,
            },
          }
        : null,
      latestBitsFormatCreatedAt: parseNullableDate(process.latestBitsFormatCreatedAt),
      originalPdfFormatCreatedAt: parseNullableDate(process.originalPdfFormatCreatedAt),
      outputPdfFormatCreatedAt: parseNullableDate(process.outputPdfFormatCreatedAt),
      editionId: process.editionId,
      checklist: [
        ...(process.customIdmlFormat ? [{ text: 'idml' }] : []),
        ...(process.latestBitsFormatCreatedAt ? [{ text: 'bits' }] : []),
        ...(process.originalPdfFormatCreatedAt || process.outputPdfFormatCreatedAt
          ? [{ text: 'pdf' }]
          : []),
      ],
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      editionId: 'editionId',
    },
    filterParams: {
      editionId: (editionId) => ({ editionId }),
    },
    sortFields: ['description', 'updatedAt'],
  });
