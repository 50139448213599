import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  Filter,
  EditButton,
  Pagination,
  BooleanField,
  downloadCSV,
  FunctionField,
  Record,
  SelectInput,
} from 'react-admin';

import { TextbookEdition } from '../../dataProvider/textbookEditionsProvider';

import DateField from '../DateField';
import { EditionAvailabilityAvatar } from './EditionAvailability';

const jsonExport = require('jsonexport/dist');

interface EditionCSV {
  Tittel: string;
  Utgave: number;
  Utgivelsesår: number;
  ISBN: string | null;
  Tags: string;
  Forfattere: string;
  Redaksjonsavdeling: number | null;
}

const exporter = async (editions: Array<TextbookEdition>) => {
  const headers: Array<keyof EditionCSV> = [
    'Tittel',
    'Utgave',
    'Utgivelsesår',
    'ISBN',
    'Tags',
    'Forfattere',
    'Redaksjonsavdeling',
  ];

  const csvRecords = editions.map(
    (edition): EditionCSV => ({
      Tittel: edition.fullTitle,
      Utgave: edition.editionSequence,
      Utgivelsesår: edition.originallyPublishedAt.getFullYear(),
      ISBN: edition.isbn,
      Tags: edition.rootDocumentTags.map(({ displayName }) => displayName).join(',\n'),
      Forfattere: edition.rootDocumentContributionTexts.join(', '),
      Redaksjonsavdeling: edition.ufDepartment,
    })
  );
  jsonExport(
    csvRecords,
    {
      headers,
    },
    (err: any, csv: string) => {
      downloadCSV(csv, 'fagbøker');
    }
  );
};

const TextbookEditionPagination: React.FC<{}> = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const TextbookEditionFilter: React.FC<{}> = (props) => (
  <Filter variant="standard" {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Publisert"
      source="isJuridikaPublished"
      choices={[
        {
          id: 'true',
          name: 'Ja',
        },
        {
          id: 'false',
          name: 'Nei',
        },
      ]}
      alwaysOn
      allowEmpty
    />
  </Filter>
);

const TextbookEditionList: React.FC<{}> = (props) => (
  <List
    {...props}
    perPage={25}
    filters={<TextbookEditionFilter />}
    filterDefaultValues={{ featured: false }}
    exporter={exporter}
    bulkActionButtons={false}
    pagination={<TextbookEditionPagination />}
  >
    <Datagrid rowClick="show">
      <FunctionField
        source="titles"
        render={(record: Record | undefined) => {
          if (!record) return null;
          const edition = record as TextbookEdition;
          return edition.fullTitle;
        }}
      />
      <TextField source="editionSequence" sortable={false} />
      <DateField source="originallyPublishedAt" />
      <FunctionField
        source="availability"
        sortable={false}
        render={(record: Record | undefined) => {
          if (!record) return null;
          const edition = record as TextbookEdition;
          return <EditionAvailabilityAvatar availability={edition.availability} />;
        }}
      />
      <BooleanField source="isPublishedAtJuridika" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default TextbookEditionList;
