import React from 'react';

import { Customer } from '../../../dataProvider/customersProvider';
import { JuridikaCmsContext } from '../../../util/juridikaCmsContext';

import DownloadFileButton from '../../DownloadFileButton';

interface Props {
  record: Customer;
  variant?: 'text' | 'outlined' | 'contained';
  label: string;
}

const DownloadUsersButton: React.FC<Props> = ({ record, label }) => {
  const { backendUrls } = React.useContext(JuridikaCmsContext);

  const url = React.useMemo(
    () => `${backendUrls.juridikaSubscriptionBackend}/export/v0/users/${record.customerNumber}`,
    [backendUrls, record]
  );
  const filename = React.useMemo(() => {
    const utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
    const name = record.name || 'kundeExport';
    // Removing illegal file name characters, replacing them with '-'
    return `export_${name}_${utc}.xlsx`.replace(/[/\\?%*:|"<>]/g, '-');
  }, [record]);

  return <DownloadFileButton url={url} filename={filename} label={label} />;
};

export default DownloadUsersButton;
