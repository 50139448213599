import React, { FC } from 'react';
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
  useGetOne,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const Contributor: FC = (record: any) => {
  const { data, loading, error } = useGetOne('contributors', record.record.contributorId);

  if (loading) return <span>Laster...</span>;
  if (error) return <span>Feil: {error.message}</span>;
  return (
    <span>
      {record.record.title} | {data!!.fullname} | {record.record.shortBio}
    </span>
  );
};

const NIKEditionCreate: FC = (props: any): JSX.Element => {
  const classes = useStyles();

  return (
    <Create {...props}>
      <SimpleForm {...props} variant="standard" margin="normal">
        <div className={classes.root}>
          <h1>Opprett en NIK utgave</h1>
          <p>
            Her oppretter du en ny utgave av en problemstilling. Du må fylle inn alle felter som er
            merket med asteriks, men best praksis er å fylle inn alle.
          </p>
        </div>
        <ReferenceInput
          source="publicationId"
          label="Tilknyttet Problemstilling"
          reference="nikPublications"
          allowEmpty={false}
          validate={[required()]}
        >
          <AutocompleteInput
            optionValue="id"
            optionText="title"
            allowEmpty={false}
            options={{
              fullWidth: true,
            }}
          />
        </ReferenceInput>
        <TextInput
          source="name"
          validate={[required()]}
          label="Utgavenavn"
          helperText="Navn for å differensiere utgaver i CMS. Vises ikke på Juridika"
        />
        <ArrayInput
          source="contributorDetailIds"
          label="Bidragsyter(e) *"
          helperText="En utgave må ha minst èn bidragsyter"
        >
          <SimpleFormIterator>
            <ReferenceInput source="id" reference="nikContributorDetails">
              <AutocompleteInput
                optionText={<Contributor />}
                inputText={(record: any) => `${record.title} | ${record.shortBio}`}
                matchSuggestion={(filterValue: any, suggestion: any) => true}
                validate={required('Bidraget må være tilknyttet en bidragsyter')}
              />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default NIKEditionCreate;
