import { ActionType, getType } from 'typesafe-actions';

import * as currentUserActions from './currentUserActions';
import { CurrentUser } from './types';

export type CurrentUserState = {
  currentUser: CurrentUser | null;
};

export type CurrentUserAction = ActionType<typeof currentUserActions>;

export const currentUserReducers = (state = null, action: CurrentUserAction) => {
  switch (action.type) {
    case getType(currentUserActions.setCurrentUser): {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default currentUserReducers;
