import * as React from 'react';

import {
  Model,
  ElementModel,
  Path,
  ModelMutation,
  createElement,
  TEXT,
  ELEMENT,
  INDEX,
} from '../../util/simpleXml';

// BitsDebug uses a functional stack
// for representing positions in the tree. This stack
// can be introspected for displaying XML-like information
// like breadcrumbs, and creating an edit path
export interface Stack {
  // The element at this stack node
  element: ElementModel;
  // Complete sibling list of the element
  siblings: ReadonlyArray<Model>;
  // The parent element:
  stack: Stack | null;
}

export interface SelectedElement {
  div: HTMLDivElement | null;
  stack: Stack;
}

export type BitsElement = React.FC<{
  stack: Stack;
  element: ElementModel;
  siblings: ReadonlyArray<Model>;
}>;

const emptyFunction = () => {};

export const UiContext = React.createContext({
  book: createElement('book', {}),
  processId: '',
  documentMutated: false,
  selectedHighLevelElement: null as SelectedElement | null,
  setSelectedHighLevelElement: (div: SelectedElement | null) => {},
  selectedLowLevelElement: null as SelectedElement | null,
  setSelectedLowLevelElement: (div: SelectedElement | null) => {},
  applyViewMutation: emptyFunction as (mutation: ModelMutation) => void,
  applyDocumentMutation: emptyFunction as (mutation: ModelMutation) => void,
});

export const UniformElements: React.FC<{
  nodes: Model[];
  component: React.ComponentType<{
    element: ElementModel;
    siblings: ReadonlyArray<Model>;
    stack: Stack;
  }>;
  stack: Stack;
}> = ({ nodes, component, stack }) => {
  const Component = component;
  return (
    <>
      {nodes.map((node, index) => {
        if (node.type === TEXT) return <React.Fragment key={index}>{node.value}</React.Fragment>;
        return <Component key={index} element={node} siblings={nodes} stack={stack} />;
      })}
    </>
  );
};

export const DifferingElements: React.FC<{
  nodes: Model[];
  stack: Stack;
  map: Record<string, React.ComponentType<{ element: ElementModel; stack: Stack }>>;
  fallback?: React.ComponentType<{ element: ElementModel; stack: Stack }>;
}> = ({ nodes, stack, map, fallback }) => {
  return (
    <>
      {nodes.map((node, index) => {
        if (node.type === TEXT) return <React.Fragment key={index}>{node.value}</React.Fragment>;
        const Component = map[node.tagName];
        if (Component) {
          return <Component key={index} element={node} stack={stack} />;
        }
        if (fallback) {
          const Fallback = fallback;
          return <Fallback key={index} element={node} stack={stack} />;
        }
        return null;
      })}
    </>
  );
};

const ElementText: React.FC<{ node: Model }> = ({ node }) => {
  if (node.type === TEXT) return <>{node.value}</>;
  return (
    <>
      {node.children.map((child, index) => (
        <ElementText key={index} node={child} />
      ))}
    </>
  );
};

export const stackContainsTag = (stack: Stack, tagName: string): boolean => {
  let current: Stack | null = stack;
  while (current) {
    if (current.element.tagName === tagName) return true;
    current = current.stack;
  }
  return false;
};

export const findElementIndex = (
  nodes: Model[],
  predicate: (element: ElementModel) => boolean,
  defaultValue: number
): number => {
  const index = nodes.findIndex((child) => child.type === ELEMENT && predicate(child));
  return index < 0 ? defaultValue : index;
};

export const findElementIndexAfter = (
  nodes: Model[],
  predicate: (element: ElementModel) => boolean,
  defaultValue: number
): number => {
  const index = nodes.findIndex((child) => child.type === ELEMENT && predicate(child));
  return index < 0 ? defaultValue : index + 1;
};

export interface Footnote {
  element: ElementModel;
  siblings: ReadonlyArray<Model>;
  stack: Stack;
}

export const extractFootnotesFromNode = (
  node: Model,
  siblings: ReadonlyArray<Model>,
  stack: Stack
): Array<Footnote> => {
  if (node.type === TEXT) return [];
  if (node.tagName === 'fn') {
    return [
      {
        element: node,
        siblings,
        stack,
      },
    ];
  }
  return extractFootnotesFromNodes(node.children, node.children, {
    element: node,
    siblings,
    stack,
  });
};

export const extractFootnotesFromNodes = (
  nodes: Model[],
  siblings: ReadonlyArray<Model>,
  stack: Stack
): Array<Footnote> => {
  return nodes.reduce(
    (footnotes, child) => footnotes.concat(extractFootnotesFromNode(child, siblings, stack)),
    [] as Footnote[]
  );
};

export const describeNode = (node: Model): string => {
  if (node.type === TEXT) {
    return '<text "' + node.value + '">';
  }
  const element = node;
  const attributes = Object.keys(element.attributes)
    .reduce((agg, key) => [...agg, `${key}="${element.attributes[key]}"`], [] as string[])
    .join(' ');
  return `<${element.tagName} ${attributes}>`;
};

export const stackToPath = (stack: Stack | null): Path => {
  interface ParentPath {
    path: Path;
    fake: boolean;
    siblings: ReadonlyArray<Model>;
  }

  const internalHelper = (stack: Stack | null): ParentPath => {
    if (!stack) return { path: [], fake: false, siblings: [] };
    if (!stack.stack) return { path: [], fake: false, siblings: stack.siblings };

    const parentPath = internalHelper(stack.stack);
    const siblings = parentPath.fake ? parentPath.siblings : stack.siblings;
    const index = siblings.indexOf(stack.element);

    // If not found, then this stack node must represent a fake element, no?
    if (index < 0) {
      if (!stack.element.fake) {
        throw new Error('Expected a fake element');
      }
      return { path: parentPath.path, fake: true, siblings: stack.siblings };
    }

    return {
      path: parentPath.path.concat([
        {
          type: INDEX,
          value: index,
        },
      ]),
      fake: false,
      siblings: stack.siblings,
    };
  };

  return internalHelper(stack).path;
};

export const stackTagNames = (stack: Stack | null): string[] => {
  const tagNames: string[] = [];
  let current = stack;
  while (current) {
    tagNames.push(current.element.tagName);
    current = current.stack;
  }
  tagNames.reverse();
  return tagNames;
};

export const isSimpleList = (stack: Stack): boolean =>
  !!stack.stack && stack.stack.element.attributes['list-type'] === 'simple';
