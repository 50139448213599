import * as t from 'io-ts';

import { HastElementNode } from '@universitetsforlaget/hast';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';
import { tagDisplayName } from './tagsProvider';
import { hastToNullableFragment } from '../util/hast';

export interface StaticDocumentContribution {
  contributorId: string;
  role: string;
  occupation: HastElementNode | null;
}

export interface StaticDocumentTag {
  id: string;
  name: string;
  displayName: string;
}

export interface StaticDocumentRevision {
  id: string;
  documentId: string;
  createdAt: Date;
  createdById: string;
  publishedAt: Date | null;
  contentCategoryId: string;
  title: string;
  hast: HastElementNode;
  contributions: Array<StaticDocumentContribution>;
  tags: Array<StaticDocumentTag>;
}

export interface PartialStaticDocumentRevision {
  publishedAt?: Date | null;
  currentEditorId?: string | null;
  assignCurrentEditorToCurrentUser?: boolean;
}

export const StaticDocumentRevisionIncomingApiTypeV = t.type({
  id: t.string,
  documentId: t.string,
  createdAt: t.string,
  createdById: t.string,
  publishedAt: t.union([t.string, t.null]),
  contentCategoryId: t.string,
  title: t.string,
  hast: t.object,
  contributions: t.array(
    t.type({
      contributorId: t.string,
      role: t.string,
      occupation: t.union([t.object, t.null]),
    })
  ),
  tags: t.array(
    t.type({
      name: t.string,
      id: t.string,
    })
  ),
});
export type StaticDocumentRevisionIncomingApiType = t.TypeOf<
  typeof StaticDocumentRevisionIncomingApiTypeV
>;

export const mapApiRevisionToFrontend = (
  apiRevision: StaticDocumentRevisionIncomingApiType
): StaticDocumentRevision => ({
  id: apiRevision.id,
  documentId: apiRevision.documentId,
  createdAt: new Date(apiRevision.createdAt),
  createdById: apiRevision.createdById,
  publishedAt: apiRevision.publishedAt ? new Date(apiRevision.publishedAt) : null,
  contentCategoryId: apiRevision.contentCategoryId,
  title: apiRevision.title,
  hast: apiRevision.hast as any,
  contributions: apiRevision.contributions.map((contribution) => ({
    contributorId: contribution.contributorId,
    role: contribution.role,
    occupation: contribution.occupation as HastElementNode | null,
  })),
  tags: apiRevision.tags.map(({ id, name }) => ({
    id,
    name,
    displayName: tagDisplayName(id.split('.')),
  })),
});

export interface StaticDocumentRevisionOutgoingApiType {
  title: string;
  hast: HastElementNode;
  contentCategoryId: string;
  contributions: Array<StaticDocumentContribution>;
}

export interface StaticDocumentRevisionPartialOutgoingApiType {
  publishedAt?: string | null;
  currentEditorId?: string | null;
  assignCurrentEditorToCurrentUser?: boolean;
}

const logHast = (node: HastElementNode): HastElementNode => {
  console.log('sending hast to server:');
  console.log(node);
  return node;
};

export const toApiMapper = (
  revision: StaticDocumentRevision
): StaticDocumentRevisionOutgoingApiType => ({
  title: revision.title,
  hast: logHast(revision.hast),
  contentCategoryId: revision.contentCategoryId,
  contributions: revision.contributions.map((contribution) => ({
    contributorId: contribution.contributorId,
    role: contribution.role,
    occupation: hastToNullableFragment(contribution.occupation),
  })),
});

export const toApiPartialMapper = (
  partialRevision: PartialStaticDocumentRevision
): StaticDocumentRevisionPartialOutgoingApiType => ({
  ...(partialRevision.publishedAt !== undefined && {
    publishedAt: partialRevision.publishedAt ? partialRevision.publishedAt.toISOString() : null,
  }),
  ...(partialRevision.currentEditorId !== undefined && {
    currentEditorId: partialRevision.currentEditorId,
  }),
  ...(partialRevision.assignCurrentEditorToCurrentUser !== undefined && {
    assignCurrentEditorToCurrentUser: partialRevision.assignCurrentEditorToCurrentUser,
  }),
});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<StaticDocumentRevision> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.staticContentBackend}/admin/v0/documentRevisions`,
    halListName: 'adminDocumentRevisionList',
    incomingType: StaticDocumentRevisionIncomingApiTypeV,
    toReactAdminMapper: mapApiRevisionToFrontend,
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {
      documentId: 'documentId',
    },
    filterParams: {
      q: (q) => ({ q }),
    },
    sortFields: [],
  });
