import React, { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  Datagrid,
  ReferenceManyField,
  ReferenceField,
  ChipField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DateTimeField from '../DateTimeField';

const useStyles = makeStyles({
  aside: {
    width: 300,
    marginLeft: '1em',
    padding: '1em',
    backgroundColor: '#ffeb3b2b',
    boxShadow:
      '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
  },
  list: {
    fontSize: '10px',
    paddingLeft: '1em',
  },
  headerCell: {
    backgroundColor: '#ebf5f4',
  },
});

const NIKPublicationShow: FC = (props) => {
  const classes = useStyles();

  return (
    <Show {...props} hasEdit={false}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="primaryTag" sortable={false} />
        <TextField source="slug" sortable={false} />
        <ReferenceField source="publisherId" reference="publishers" link="show" sortable={false}>
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceManyField
          label="Liste over utgaver i denne problemstillingen"
          reference="nikEditions"
          target="publication_id"
          fullWidth
        >
          <Datagrid rowClick="show" classes={{ headerCell: classes.headerCell }}>
            <TextField source="name" sortable={false} />
            <TextField source="id" sortable={false} />
            <DateTimeField source="publishedAt" sortable={false} emptyText="Ikke publisert" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default NIKPublicationShow;
