import URI from 'urijs';

import * as constants from './legalDocumentConstants';
import { GenerateCopyParams } from './types';

export type GenerateAsCopyAction = (params: GenerateCopyParams) => any;

export const generateAsCopy: GenerateAsCopyAction = (params) => {
  const { copyFromWorkUri, copyFromLanguageCode, newInForceDate } = params.data;

  const redirectUrl = URI('/')
    .segment('legalDocuments')
    .segment(copyFromWorkUri)
    .segment(`${copyFromLanguageCode}@${newInForceDate}`)
    .search({
      publicationStatus: 'DRAFT',
    })
    .toString();

  return {
    type: constants.GENERATE_AS_COPY,
    payload: params,
    meta: {
      fetch: constants.GENERATE_AS_COPY,
      resource: 'legalDocuments',
      onSuccess: {
        notification: {
          body: 'resources.legalDocuments.notification.generate_as_copy_success',
          level: 'info',
        },
        redirectTo: redirectUrl,
      },
      onFailure: {
        notification: {
          body: 'resources.legalDocuments.notification.generate_as_copy_failure',
          level: 'warning',
        },
      },
    },
  };
};
