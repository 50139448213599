import * as t from 'io-ts';
import * as hast from '@universitetsforlaget/hast';

import * as Types from './types';
import { halJsonCrudApiProvider } from './apiUtil';

import { singleLineHastFromNullableFragment, hastToNullableFragment } from '../util/hast';

export interface Contributor {
  id: string;
  slug: string;
  givenName: string | null;
  surname: string;
  static: boolean;
  featured: boolean;
  hasPortrait: boolean;
  occupation: hast.HastElementNode;
  degree: hast.HastElementNode;
  emailAddress: string | null;
  homepageUrl: string | null;
  slugMaps: Array<{
    slug: string;
  }>;

  // computed fields
  fullname: string;
  checklist: Array<{ text: string }>;
}

export interface PartialContributor {
  featured?: boolean;
  occupation?: hast.HastElementNode | null;
  degree?: hast.HastElementNode | null;
  emailAddress?: string | null;
  homepageUrl?: string | null;
  slugMaps?: Array<{ slug: string }>;
  givenName?: string | null;
  surname?: string;
}

// Runtime type!!!
const ApiTypeV = t.type({
  id: t.string,
  slug: t.string,
  givenName: t.union([t.string, t.null]),
  surname: t.string,
  static: t.boolean,
  featured: t.boolean,
  hasPortrait: t.boolean,
  occupation: t.union([t.object, t.null]),
  degree: t.union([t.object, t.null]),
  emailAddress: t.union([t.string, t.null]),
  homepageUrl: t.union([t.string, t.null]),
  slugMaps: t.array(t.string),
});
type ApiType = t.TypeOf<typeof ApiTypeV>;

interface ApiOutgoingType {
  givenName: string | null;
  surname: string;
  featured: boolean;
  occupation: hast.HastElementNode | null;
  degree: hast.HastElementNode | null;
  emailAddress: string | null;
  homepageUrl: string | null;
}

export const toApiMapper = (contributor: Contributor): ApiOutgoingType => ({
  givenName:
    contributor.givenName && contributor.givenName.length > 0 ? contributor.givenName : null,
  surname: contributor.surname,
  featured: contributor.featured,
  occupation: hastToNullableFragment(contributor.occupation),
  degree: hastToNullableFragment(contributor.degree),
  emailAddress: contributor.emailAddress,
  homepageUrl: contributor.homepageUrl,
});

export interface ApiPartialOutgoingType {
  featured?: boolean;
  occupation?: hast.HastElementNode | null;
  degree?: hast.HastElementNode | null;
  emailAddress?: string | null;
  homepageUrl?: string | null;
  slugMaps?: Array<string>;
  givenName?: string | null;
  surname?: string;
}

// Mapper used when PATCH-ing (called UPDATE in react-admin)
export const toApiPartialMapper = (contributor: PartialContributor): ApiPartialOutgoingType => ({
  ...(contributor.featured !== undefined && {
    featured: contributor.featured,
  }),
  ...(contributor.occupation !== undefined && {
    occupation: contributor.occupation && hastToNullableFragment(contributor.occupation),
  }),
  ...(contributor.degree !== undefined && {
    degree: contributor.degree && hastToNullableFragment(contributor.degree),
  }),
  ...(contributor.emailAddress !== undefined && {
    emailAddress: contributor.emailAddress,
  }),
  ...(contributor.homepageUrl !== undefined && {
    homepageUrl: contributor.homepageUrl,
  }),
  ...(contributor.slugMaps && {
    slugMaps: contributor.slugMaps.map(({ slug }) => slug),
  }),
  ...(contributor.givenName !== undefined && {
    givenName: contributor.givenName,
  }),
  ...(contributor.surname !== undefined && {
    surname: contributor.surname,
  }),
});

export const createProvider = (urls: Types.BackendUrls): Types.ResourceProvider<Contributor> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaLitteraturBackend}/admin/v0/contributors`,
    halListName: 'adminContributorList',
    incomingType: ApiTypeV,
    toReactAdminMapper: (apiContributor: ApiType): Contributor => ({
      id: apiContributor.id,
      slug: apiContributor.slug,
      givenName: apiContributor.givenName,
      surname: apiContributor.surname,
      static: apiContributor.static,
      featured: apiContributor.featured,
      hasPortrait: apiContributor.hasPortrait,
      occupation: singleLineHastFromNullableFragment(
        apiContributor.occupation as hast.HastElementNode
      ),
      degree: singleLineHastFromNullableFragment(apiContributor.degree as hast.HastElementNode),
      emailAddress: apiContributor.emailAddress,
      homepageUrl: apiContributor.homepageUrl,
      slugMaps: apiContributor.slugMaps.map((slug) => ({ slug })),

      fullname: apiContributor.givenName
        ? `${apiContributor.givenName} ${apiContributor.surname}`
        : apiContributor.surname,
      checklist: getChecklist(apiContributor),
    }),
    toApiMapper,
    toApiPartialMapper,
    referenceParams: {},
    filterParams: {
      q: (q) => ({ q }),
      featured: (featured) => ({ featured }),
      hasPortrait: (hasPortrait) => ({ hasPortrait }),
      includeNonStatic: () => ({ includeNonStatic: true }),
    },
    sortFields: [],
  });

export const getChecklist = (flags: {
  static: boolean;
  featured: boolean;
  hasPortrait: boolean;
}): Array<{ text: string }> => {
  const checklist: Array<{ text: string }> = [];

  if (!flags.static) {
    checklist.push({ text: 'ikke-statisk (importert)' });
  }

  if (flags.featured) {
    checklist.push({ text: 'Profilert ✓' });
  }

  if (flags.hasPortrait) {
    checklist.push({ text: 'Portrett ✓' });
  }

  return checklist;
};

export const createEmptyContributor = (): Contributor => ({
  id: '',
  slug: '',
  givenName: null,
  surname: '',
  static: true,
  featured: false,
  hasPortrait: false,
  occupation: hast.createFragment(hast.stringToHast('')),
  degree: hast.createFragment(hast.stringToHast('')),
  emailAddress: null,
  homepageUrl: null,
  slugMaps: [],

  // computed fields
  fullname: '',
  checklist: [],
});
