import React, { FC } from 'react';
import styles from './ErrorNotice.module.css';
import { Icon } from '@material-ui/core';

const ErrorNotice: FC<{ message: string }> = ({ message }) => (
  <div className={styles.errorNotice}>
    <Icon color="error">error-outline</Icon>
    <p>Fant ikke innhold</p>
  </div>
);

export default ErrorNotice;
