import { Modal as MUIModal, Button as MUIButton, Icon } from '@material-ui/core';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LovbackendService } from '../../../dataProvider/lovbackend/lovbackendService';
import SearchableSelect from '../SearchableSelect/SearchableSelect';
import { Option } from '../SearchableSelect/SearchableSelect';
import { ResourceStickerData } from '../RichTextEditor';
import styles from './CreateResourceStickerModal.module.css';
import ErrorNotice from '../ErrorNotice/ErrorNotice';
import * as ApiUrls from '../../../dataProvider/apiUrls';

interface Fragment {
  value: string;
  label: string;
  htmlContents: string;
}

// Akoma Ntoso type
enum AKN_TYPE {
  JUDGMENT = 'judgment',
  LAW = 'act',
}

const CreateResourceStickerModal: FC<{
  onSave: (resourceStickerData: ResourceStickerData) => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ open, setOpen, onSave }) => {
  const [documents, setDocuments] = useState<Option[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<Option | null>(null);
  const [fragments, setFragments] = React.useState<Fragment[]>([]);
  const [selectedFragment, setSelectedFragment] = React.useState<Option | null>(null);

  const currentHtmlFragment = useMemo(() => {
    const currentFragment = fragments.find(
      (fragment) => fragment.value === selectedFragment?.value
    );
    return currentFragment?.htmlContents;
  }, [fragments, selectedFragment]);

  const getDocumentResourceType = useCallback((uri: string) => {
    const AKNTypeFromSelectedDocumentValue = uri.split('/')[3];
    return AKNTypeFromSelectedDocumentValue as AKN_TYPE;
  }, []);

  const disableSaving = useMemo(() => {
    return !selectedDocument || !selectedFragment || !currentHtmlFragment;
  }, [currentHtmlFragment, selectedDocument, selectedFragment]);

  const fetchDocuments = useCallback(async () => {
    const backendUrls = ApiUrls.backendUrls();
    const lovApi = new LovbackendService(backendUrls.lovEditorBackend);

    const legalDocuments = await lovApi.getPublicDocumentPage({});
    const options = legalDocuments.data.map((document) => {
      return {
        value: document.id,
        label: `${document.summary?.shortTitle} ${document.summary?.date}`,
      };
    });
    setDocuments(options);
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const fetchFragments = useCallback(
    async (selectedDocumentValue: string) => {
      const backendUrls = ApiUrls.backendUrls();
      const lovApi = new LovbackendService(backendUrls.lovEditorBackend);

      try {
        const fragments: {
          data: any;
          total: number;
          body?: any;
        } = await lovApi.getDocumentFragments({
          data: { uri: selectedDocumentValue },
        });

        const flat = (fragments: any[]): any[] => {
          return fragments.flatMap((fragment) => [fragment, ...flat(fragment.children || [])]);
        };

        if (!fragments.body) return;

        const flattenedFragments = flat(fragments.body.children).filter(
          (fragment) =>
            fragment.type !== 'chapter' &&
            fragment.type !== 'division' &&
            fragment.type !== 'subchapter'
        );

        const documentResourceType = getDocumentResourceType(selectedDocumentValue);

        const options = flattenedFragments.map((fragment: any) => {
          return {
            value: fragment.eId,
            label: documentResourceType === AKN_TYPE.JUDGMENT ? fragment.eId : fragment.numValue,
            htmlContents: fragment.htmlContents,
          };
        });

        setFragments(options);
      } catch (error) {
        console.error('Error getting fragments from uri. ', error);
      }
    },
    [getDocumentResourceType]
  );

  useEffect(() => {
    // clear fragments when document is changed
    setSelectedFragment(null);

    if (selectedDocument) {
      fetchFragments(selectedDocument.value);
    }
  }, [fetchFragments, selectedDocument]);

  const closeModal = () => setOpen(false);
  const handleSelectChange = (selectedOption: Option | null) => setSelectedDocument(selectedOption);
  const handleFragmentChange = (selectedOption: Option | null) =>
    setSelectedFragment(selectedOption);

  const handleOnSave = () => {
    if (selectedDocument && selectedFragment) {
      onSave({
        uri: selectedDocument.value,
        fragmentId: selectedFragment.value,
      });
      closeModal();
    }
  };

  const renderFragmentContent = () => {
    if (!selectedFragment) return null;

    return (
      <section>
        <small className={styles.helperText}>Visning av innhold i ressursboks</small>

        {currentHtmlFragment ? (
          <div
            className={styles.fragmentContent}
            dangerouslySetInnerHTML={{
              __html: currentHtmlFragment,
            }}
          ></div>
        ) : (
          <ErrorNotice message="Fant ikke innhold" />
        )}
      </section>
    );
  };

  return (
    <MUIModal open={open} onClose={closeModal} className={styles.modal}>
      <div className={styles.modalContent}>
        <MUIButton
          variant="outlined"
          color="secondary"
          size="small"
          onClick={closeModal}
          className={styles.closeButton}
        >
          <Icon>close</Icon>
        </MUIButton>
        <h2>Ressursknapp</h2>
        <p>
          Denne knappen henter en paragraf av en dom, forskrift eller lov inn på Juridika sin side i
          form av en <em>ressursboks</em> i sidemargen.
        </p>

        <section className={styles.section}>
          <small className={styles.helperText}>Velg dokumentet du vil hente kilden fra</small>
          <SearchableSelect
            selectedOption={selectedDocument}
            options={documents}
            onChange={handleSelectChange}
            placeholder="Velg dokument..."
          />
        </section>
        {selectedDocument && (
          <section className={styles.section}>
            <small className={styles.helperText}>
              Velg fragmentet du ønsker å vise i ressursboksen
            </small>
            <SearchableSelect
              selectedOption={selectedFragment}
              options={fragments}
              onChange={handleFragmentChange}
              placeholder="Velg fragment..."
            />
          </section>
        )}

        {selectedFragment && renderFragmentContent()}

        <MUIButton
          className={styles.saveButton}
          variant="contained"
          color="primary"
          disabled={disableSaving}
          onClick={handleOnSave}
        >
          Lagre
        </MUIButton>
      </div>
    </MUIModal>
  );
};

export default CreateResourceStickerModal;
