import React from 'react';
import {
  Show,
  Datagrid,
  SimpleShowLayout,
  ReferenceField,
  ReferenceManyField,
  TextField,
  RichTextField,
  Labeled,
} from 'react-admin';

import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';

import PublishRevisionButton from '../../containers/PublishRevision/PublishRevisionButton';

import DateTimeField from '../DateTimeField';
import { MutateCurrentEditorButton } from '../staticContentCommon/MutateCurrentEditorButton';

const PublishNoticeRevisionButton: React.FC<{
  record?: StaticDocumentRevision;
}> = ({ record }) =>
  record ? (
    <PublishRevisionButton
      snapshotInfo={{
        snapshotType: 'notice',
      }}
      revision={record}
      dialogLabel="Publiser notis-revisjon"
    />
  ) : null;

const NoticeRevisions: React.FC<any> = (props) => (
  <Labeled label="Revisjoner">
    <ReferenceManyField
      {...props}
      reference="revisions"
      target="documentId"
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <DateTimeField source="createdAt" />
        <ReferenceField source="createdById" reference="editorAccounts">
          <TextField source="editorAccountId" />
        </ReferenceField>

        <DateTimeField source="publishedAt" />
        <PublishNoticeRevisionButton />
      </Datagrid>
    </ReferenceManyField>
  </Labeled>
);

export const NoticeSnapshotShowLayout: React.FC<any> = (props) => (
  <SimpleShowLayout>
    <TextField source="id" />
    <RichTextField source="revision.title" />
    <ReferenceField source="createdById" reference="editorAccounts">
      <TextField source="name" />
    </ReferenceField>

    <ReferenceField source="linkedPage" reference="pageSnapshots" link="show">
      <TextField source="revision.title" />
    </ReferenceField>

    <NoticeRevisions />
  </SimpleShowLayout>
);

export const NoticeSnapshotExpandShow: React.FC<any> = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="id" />
      <RichTextField source="revision.title" />
      <ReferenceField source="currentEditorId" reference="editorAccounts">
        <TextField source="name" />
      </ReferenceField>
      <MutateCurrentEditorButton />

      <ReferenceField source="linkedPage" reference="pageSnapshots" link="show">
        <TextField source="revision.title" />
      </ReferenceField>

      <NoticeRevisions />
    </SimpleShowLayout>
  </Show>
);
