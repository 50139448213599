import * as React from 'react';
import * as Slate from 'slate';

import SlateToolbarButton from '../../../components/SlateInput/SlateToolbarButton';
import { getAboveByType } from '../../utils/query';
import * as tags from '../../tags';
import { insertTableRow } from './transforms';

const Icon: React.FC<{ disabled?: boolean }> = (props) => {
  return (
    <svg
      style={{ fill: props.disabled ? 'grey' : 'white' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M22 10a2 2 0 01-2 2H4a2 2 0 01-2-2V3h2v2h4V3h2v2h4V3h2v2h4V3h2v7M4 10h4V7H4v3m6 0h4V7h-4v3m10 0V7h-4v3h4m-9 4h2v3h3v2h-3v3h-2v-3H8v-2h3v-3z"></path>
    </svg>
  );
};

const IconDisabled: React.FC<{}> = (props) => {
  return <Icon disabled={true} />;
};

const InsertRowButton: React.FC<{ editor: Slate.Editor }> = ({ editor }) => {
  const isActive = !!getAboveByType(editor, tags.BLOCK_TABLE_ROW);

  return (
    <SlateToolbarButton
      isActive={isActive}
      Icon={isActive ? Icon : IconDisabled}
      message="slate.contextMenu.table.insertRow"
      onMouseDown={() => insertTableRow(editor)}
    />
  );
};

export default InsertRowButton;
