import * as React from 'react';
import * as Slate from 'slate';

import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

import { Plugin } from '../types';
import SlateSimpleMenu from '../../../components/SlateInput/SlateSimpleMenu';
import * as tags from '../../tags';
import { insertListItem, indentList, outdentList, toggleList, unwrapList } from './transforms';
import { isSelectionInListItem } from './query';
import { isBlockAboveEmpty, isSelectionAtBlockStart } from '../../utils/query';
import { onKeyDownResetBlockType } from '../../utils/handlers';

export interface ListPluginProps {
  editor: Slate.Editor;
}

const key = 'list-plugin';

const indent = (editor: Slate.Editor) => {
  const inListItem = isSelectionInListItem(editor);
  if (!inListItem) return;

  const { listNode, listItemPath } = inListItem;
  if (listItemPath[listItemPath.length - 1] > 0) {
    indentList(editor, listNode, listItemPath);
  }
};

const outdent = (editor: Slate.Editor) => {
  const inListItem = isSelectionInListItem(editor);
  if (!inListItem) return;
  const { listNode, listPath, listItemPath } = inListItem;
  outdentList(editor, listNode, listPath, listItemPath);
};

const ListButton: React.SFC<ListPluginProps> = (props) => {
  const activeListType = isSelectionInListItem(props.editor)?.listItemNode?.type;

  return React.createElement(SlateSimpleMenu, {
    key: 'list',
    isActive: !!activeListType,
    Icon: FormatListBulletedIcon,
    message: 'slate.contextMenu.list',
    menuItems: [
      <MenuItem key="indent" disabled={!activeListType} onMouseDown={() => indent(props.editor)}>
        <Tooltip title={'Liste - innrykk'}>
          <FormatIndentIncreaseIcon />
        </Tooltip>
      </MenuItem>,
      <MenuItem key="outdent" disabled={!activeListType} onMouseDown={() => outdent(props.editor)}>
        <Tooltip title="Liste - utrykk">
          <FormatIndentDecreaseIcon />
        </Tooltip>
      </MenuItem>,
      <Divider key="divider" />,
      <MenuItem
        key="bulleted"
        selected={activeListType === tags.BLOCK_BULLETED_LIST}
        onMouseDown={() => toggleList(props.editor, tags.BLOCK_BULLETED_LIST)}
      >
        <Tooltip title="Punkt">
          <FormatListBulletedIcon />
        </Tooltip>
      </MenuItem>,
      <MenuItem
        key="numbered"
        selected={activeListType === tags.BLOCK_NUMBERED_LIST}
        onMouseDown={() => toggleList(props.editor, tags.BLOCK_NUMBERED_LIST)}
      >
        <Tooltip title="Numerisk">
          <FormatListNumberedIcon />
        </Tooltip>
      </MenuItem>,
      <MenuItem
        key="alpha"
        selected={activeListType === tags.BLOCK_ALPHA_LIST}
        onMouseDown={() => toggleList(props.editor, tags.BLOCK_ALPHA_LIST)}
      >
        <Tooltip title="Alfabetisk">
          <SortByAlphaIcon />
        </Tooltip>
      </MenuItem>,
      <MenuItem
        key="references"
        selected={activeListType === tags.BLOCK_REFERENCE_LIST}
        onMouseDown={() => toggleList(props.editor, tags.BLOCK_REFERENCE_LIST)}
      >
        <Tooltip title="Referanseliste">
          <ImportContactsIcon />
        </Tooltip>
      </MenuItem>,
    ],
  });
};

export default (): Plugin => {
  return {
    key,
    toolbar: {
      render: (
        editor: Slate.Editor,
        pluginState: any,
        setPluginState: (state: any) => void,
        slateEditorChanged: boolean
      ) => {
        return <ListButton key={key} editor={editor} />;
      },
    },
    onKeyDown: (editor, e) => {
      let moved: boolean | undefined = false;

      if (e.key === 'Tab') {
        const res = isSelectionInListItem(editor);
        if (!res) return;

        const { listNode, listPath, listItemPath } = res;
        e.preventDefault();

        // move up with shift+tab
        const shiftTab = e.shiftKey;
        if (shiftTab) {
          moved = outdentList(editor, listNode, listPath, listItemPath);
          if (moved) e.preventDefault();
        }

        // move down with tab
        const tab = !e.shiftKey;
        if (tab && listItemPath[listItemPath.length - 1] > 0) {
          indentList(editor, listNode, listItemPath);
        }
      }
    },
    withEditor: (editor) => {
      const { insertBreak, deleteBackward } = editor;

      const resetBlockTypesListRule = {
        types: [tags.BLOCK_LIST_ITEM],
        defaultType: tags.BLOCK_PARAGRAPH,
        onReset: unwrapList,
      };

      editor.insertBreak = () => {
        const inListItem = isSelectionInListItem(editor);
        let moved: boolean | undefined;

        if (inListItem) {
          const { listItemNode, listItemPath } = inListItem;
          if (listItemNode.children.length > 1) {
            return Slate.Transforms.insertNodes(
              editor,
              {
                type: tags.BLOCK_LIST_ITEM,
                children: [{ type: tags.BLOCK_PARAGRAPH, children: [{ text: '' }] }],
              },
              { at: Slate.Path.next(listItemPath), select: true }
            );
          }
        }
        if (inListItem && isBlockAboveEmpty(editor)) {
          const { listNode, listPath, listItemPath } = inListItem;
          moved = outdentList(editor, listNode, listPath, listItemPath);
          if (moved) return;
        }

        const didReset = onKeyDownResetBlockType({
          rules: [
            {
              ...resetBlockTypesListRule,
              predicate: () => !moved && isBlockAboveEmpty(editor),
            },
          ],
        })(null, editor);
        if (didReset) return;

        /**
         * Add a new list item if selection is in a LIST_ITEM > p.type.
         */
        if (!moved) {
          const inserted = insertListItem(editor);
          if (inserted) return;
        }

        insertBreak();
      };

      editor.deleteBackward = (unit) => {
        const res = isSelectionInListItem(editor);

        let moved: boolean | undefined;

        if (res && isSelectionAtBlockStart(editor)) {
          const { listNode, listPath, listItemPath } = res;

          moved = outdentList(editor, listNode, listPath, listItemPath);
          if (moved) return;
        }

        if (res) {
          const { listItemNode } = res;
          if (
            listItemNode.children.length > 1 &&
            Slate.Range.isCollapsed(editor.selection as Slate.Range)
          ) {
            return deleteBackward(unit);
          }
        }

        const didReset = onKeyDownResetBlockType({
          rules: [
            {
              ...resetBlockTypesListRule,
              predicate: () => !moved && isSelectionAtBlockStart(editor),
            },
          ],
        })(null, editor);
        if (didReset) return;

        deleteBackward(unit);
      };

      return editor;
    },
  };
};
