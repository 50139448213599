import * as React from 'react';
import * as Slate from 'slate';
import * as SlateReact from 'slate-react';
import { ImageValue, ImageEditor } from './ImageEditor';
import * as tags from '../../slate/tags';
import { getAboveByType } from '../../slate/utils/query';

export interface SlateImageProps {
  node: Slate.Node;
  src: string;
  alt: string;
  title?: string;
  'data-uuid': string;
  'data-fileid': string;
  editor: Slate.Editor;
}

const SlateImage = React.forwardRef<any, SlateImageProps>(
  ({ node, src, alt, title, 'data-fileid': fileid, 'data-uuid': uuid, editor, children }, ref) => {
    const isSelected = SlateReact.useSelected();
    const editorValue: ImageValue = {
      src: src || null,
      fileid: fileid || null,
      alt: alt || null,
      title: title || null,
    };

    const handleChange = (value: ImageValue) => {
      // Couldnt use Slate.Transforms for this because of bug where it does not match void nodes (even with voids: true)
      for (const [n, path] of Slate.Node.descendants(editor, {
        pass: ([node]) => node.type === tags.BLOCK_IMAGE,
      })) {
        if (n['data-uuid'] === uuid) {
          Slate.Transforms.setNodes(
            editor,
            {
              // Retains fileid if already applied, so we can re-scale the image later.
              'data-fileid': fileid || value.fileid || null,
              ...(value.src && { src: value.src }),
              ...(value.alt && { alt: value.alt }),
              ...(value.title && { title: value.title }),
            },
            {
              at: path,
            }
          );
        }
      }
    };

    const handleDelete = () => {
      const paths = Array.from(
        Slate.Node.descendants(editor, {
          pass: ([node]) => node.type === tags.BLOCK_IMAGE,
        })
      )
        .filter(([node]) => node['data-uuid'] === uuid)
        .map(([, path]) => path);

      for (const path of paths) {
        const parentFigureEntry = getAboveByType(editor, tags.BLOCK_FIGURE, path);
        if (parentFigureEntry) {
          const [, figurePath] = parentFigureEntry;
          Slate.Transforms.removeNodes(editor, {
            at: figurePath,
            match: (node: Slate.Node) => node.type === tags.BLOCK_FIGURE,
          });
        } else {
          // Maybe image is not wrapped in figure
          Slate.Transforms.removeNodes(editor, {
            at: path,
          });
        }
      }
    };

    const className = src ? 'img SlateImage' : 'img SlateImagePlaceholder';

    return (
      <div
        ref={ref}
        className={className}
        style={{
          position: 'relative',
          userSelect: 'none',
        }}
        contentEditable={false}
      >
        <ImageEditor
          uuid={uuid}
          value={editorValue}
          onChange={handleChange}
          onClear={handleDelete}
        />
        {isSelected && (
          <div
            style={{
              position: 'absolute',
              background: '#acf',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              opacity: 0.5,
            }}
          />
        )}
        {children}
      </div>
    );
  }
);

export default SlateImage;
