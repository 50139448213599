import * as Slate from 'slate';

export interface ProcessNodesConfig {
  unwrap?: (node: Slate.Element) => Slate.Node[] | undefined;
  delete?: (node: Slate.Node) => boolean;
}

export const processNodes = (nodes: Slate.Node[], config: ProcessNodesConfig): Slate.Node[] => {
  return nodes.reduce((acc: Slate.Node[], current: Slate.Node) => {
    if (config.delete?.(current)) {
      return [...acc];
    }

    if (Slate.Element.isElement(current)) {
      const unwrappedNodes = config.unwrap?.(current);
      if (unwrappedNodes) {
        return [...acc, ...processNodes(unwrappedNodes, config)];
      }
    }

    if (current.tag) {
      return [
        ...acc,
        {
          ...current,
          tag: current.tag,
          children: processNodes((current as Slate.Element).children, config),
        },
      ];
    }

    if (current.children) {
      return [
        ...acc,
        {
          ...current,
          children: processNodes((current as Slate.Element).children, config),
        },
      ];
    } else {
      return [...acc, current];
    }
  }, []);
};
