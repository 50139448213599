import React from 'react';
import {
  Show,
  Datagrid,
  SimpleShowLayout,
  ReferenceField,
  ReferenceManyField,
  TextField,
  RichTextField,
  Labeled,
} from 'react-admin';

import { StaticDocumentRevision } from '../../dataProvider/staticDocumentRevisionsProvider';

import PublishRevisionButton from '../../containers/PublishRevision/PublishRevisionButton';

import DateTimeField from '../DateTimeField';
import { MutateCurrentEditorButton } from '../staticContentCommon/MutateCurrentEditorButton';
import { EmbeddedSnapshot } from '../../dataProvider/embeddedSnapshotsProvider';

const PublishEmbeddedRevisionButton: React.FC<{
  snapshot: EmbeddedSnapshot;
  record?: StaticDocumentRevision;
}> = ({ snapshot, record }) =>
  record ? (
    <PublishRevisionButton
      snapshotInfo={{
        snapshotType: 'embedded',
        foreignId: snapshot.foreignId,
        foreignType: snapshot.foreignType,
      }}
      revision={record}
      dialogLabel="Publiser deldokument-revisjon"
    />
  ) : null;

const EmbeddedRevisions: React.FC<{
  record?: EmbeddedSnapshot;
}> = (props) => (
  <Labeled label="Revisjoner">
    <ReferenceManyField
      {...props}
      reference="revisions"
      target="documentId"
      sort={{
        field: 'createdAt',
        order: 'DESC',
      }}
    >
      <Datagrid>
        <DateTimeField source="createdAt" />
        <ReferenceField source="createdById" reference="editorAccounts">
          <TextField source="name" />
        </ReferenceField>

        <DateTimeField source="publishedAt" />
        {props.record && <PublishEmbeddedRevisionButton snapshot={props.record} />}
      </Datagrid>
    </ReferenceManyField>
  </Labeled>
);

export const EmbeddedSnapshotShowLayout: React.FC<any> = (props) => (
  <SimpleShowLayout>
    <TextField source="id" />
    <RichTextField source="revision.title" />
    <ReferenceField source="createdById" reference="editorAccounts">
      <TextField source="name" />
    </ReferenceField>

    <EmbeddedRevisions />
  </SimpleShowLayout>
);

export const EmbeddedSnapshotExpandShow: React.FC<any> = (props) => (
  <Show {...props} title=" ">
    <SimpleShowLayout>
      <TextField source="id" />
      <RichTextField source="revision.title" />
      <TextField source="foreignId" />
      <ReferenceField source="currentEditorId" reference="editorAccounts">
        <TextField source="name" />
      </ReferenceField>
      <MutateCurrentEditorButton />

      <EmbeddedRevisions />
    </SimpleShowLayout>
  </Show>
);
