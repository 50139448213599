import { Rule } from './types';

export const sortRulesByParentFirstAscending = (rules: Rule[]) =>
  rules.sort((left: Rule, right: Rule) => {
    if (left.at.length === right.at.length) {
      // 0 meaning equal
      let isLeftHigherPrecedence = 0;
      // determine if a has higher precendence than b
      for (let i = 0; i < left.at.length; i++) {
        const leftIndex = left.at[i];
        const rightIndex = right.at[i];

        // [0, 1] < [0, 0]
        if (leftIndex > rightIndex) {
          return 1;
        } else if (rightIndex > leftIndex) {
          return -1;
        }
      }

      return isLeftHigherPrecedence;
    }

    // Parent first, [0, 1] < [0]
    return left.at.length > right.at.length ? 1 : -1;
  });
