import React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, Pagination } from 'react-admin';

import LiteraturePublicationFilter from '../LiteraturePublicationFilter';

import TextbookPublicationShow from './TextbookPublicationShow';

const LiteraturePublicationPagination: React.FC<{}> = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const LiteraturePublicationList: React.FC<{}> = (props) => (
  <List
    {...props}
    perPage={25}
    filters={<LiteraturePublicationFilter />}
    filterDefaultValues={{ featured: false }}
    bulkActionButtons={false}
    pagination={<LiteraturePublicationPagination />}
  >
    <Datagrid expand={<TextbookPublicationShow />}>
      <TextField source="fullTitle" sortable={false} />
      <TextField source="slug" sortable={false} />
      <NumberField source="ufDepartment" sortable={false} />
      <EditButton />
    </Datagrid>
  </List>
);

export default LiteraturePublicationList;
