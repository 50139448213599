import { Descendant } from 'new-slate';
import { normalizeToXmlStructure } from './slateTransformations';

type JSONMLAttribute = { [key: string]: string };
type JSONMLNode = string | JSONMLArray;
interface JSONMLArray extends Array<string | JSONMLAttribute | JSONMLNode> {}

export const xmlToJsonML = (xmlString: string): JSONMLNode => {
  // Parse the XML string into a DOM Document
  const parser: DOMParser = new DOMParser();
  const xmlDoc: Document = parser.parseFromString(xmlString, 'application/xml');

  // Function to recursively convert a node to JSONML
  function nodeToJsonML(node: HTMLElement): JSONMLNode {
    // Base case for text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue || '';
    }

    // For element nodes, create an array with the element name
    let jsonML: JSONMLArray = [node.nodeName];

    // If the element has attributes, add them as an object
    if (node.attributes && node.attributes.length > 0) {
      let attributes: JSONMLAttribute = {};
      Array.from(node.attributes).forEach((attr) => {
        attributes[attr.nodeName] = attr.nodeValue || '';
      });
      jsonML.push(attributes);
    }

    // Recursively process child nodes
    Array.from(node.childNodes).forEach((child: Node) => {
      jsonML.push(nodeToJsonML(child as HTMLElement));
    });

    return jsonML;
  }

  // Convert the root element of the XML document
  return nodeToJsonML(xmlDoc.documentElement);
};

export const rootSerializeToXML = (nodes: Descendant[]): string => {
  const res = normalizeToXmlStructure([...nodes]);
  const serialized = serializeToXML(res);
  return serialized;
};

export const serializeToXML = (nodes: Descendant[], parentIsPart = false): string => {
  return nodes
    .map((node: Descendant) => {
      switch (node.type) {
        case 'dynamic-document':
          return `<dynamic-document xmlns:mml="http://www.w3.org/1998/Math/MathML">${serializeToXML(
            node.children
          )}</dynamic-document>`;
        case 'body':
          return `<body>${serializeToXML(node.children)}</body>`;
        case 'part':
          return `<part>${serializeToXML(node.children, true)}</part>`;
        case 'paragraph':
          return `<paragraph>${serializeToXML(node.children)}</paragraph>`;
        case 'p':
          if (parentIsPart) {
            return `<paragraph><p>${serializeToXML(node.children)}</p></paragraph>`;
          }
          return `<p>${serializeToXML(node.children)}</p>`;
        case 'block-quote':
          return `<block-quote cite="${node.attributes?.cite || ""}">${serializeToXML(node.children)}</block-quote>`;
          case 'footer':
            return `<footer>${serializeToXML(node.children)}</footer>`;
        case 'table':
          return `<table>${serializeToXML(node.children)}</table>`;
        case 'thead':
          return `<thead>${serializeToXML(node.children)}</thead>`;
        case 'tbody':
          return `<tbody>${serializeToXML(node.children)}</tbody>`;
        case 'th':
          return `<th>${serializeToXML(node.children)}</th>`;
        case 'tr':
          return `<tr>${serializeToXML(node.children)}</tr>`;
        case 'td':
          return `<td>${serializeToXML(node.children)}</td>`;
        case 'caption':
          return `<caption>${serializeToXML(node.children)}</caption>`;
        case 'unordered-list':
          return `<list>${serializeToXML(node.children)}</list>`;
        case 'ordered-list':
          const listType = node.attributes?.type || "1"
          return `<list type="${listType}">${serializeToXML(node.children)}</list>`;
        case 'list-item':
          return `<list-item>${serializeToXML(node.children)}</list-item>`;
        case 'title':
          return `<title>${serializeToXML(node.children)}</title>`;
        case 'button':
          const uri = node['data-uri'];
          const uriWithSlashAsLastChar = uri[uri.length - 1] === '/' ? uri : `${uri}/`;
          return `<a href="internal:${uriWithSlashAsLastChar}${
            node['data-fragment-id']
          }">${serializeToXML(node.children)}</a>`;
        case 'label':
          return `<label>${serializeToXML(node.children)}</label>`;
        case 'link':
          return `<a href="${node['url']}">${serializeToXML(node.children)}</a>`;
        case 'boxed-content':
          switch (node.className) {
            case 'note':
              return `<note>${serializeToXML(node.children)}</note>`;
            case 'reference':
              return `<reference>${serializeToXML(node.children)}</reference>`;
            case 'boxed-content':
              return `<boxed-content>${serializeToXML(node.children)}</boxed-content>`;
            default:
              return `<boxed-content>${serializeToXML(node.children)}</boxed-content>`;
          }
        case 'math':
          return node.serializedChildren;
        case 'inline-content':
          switch (node.className) {
            case 'note':
              return `<note>${serializeToXML(node.children)}</note>`;
            case 'reference':
              return `<reference>${serializeToXML(node.children)}</reference>`;
            default:
              return `<boxed-content>${serializeToXML(node.children)}</boxed-content>`;
          }
        default:
          let text = node.text;
          if (node.bold) {
            text = `<b>${text}</b>`;
          }
          if (node.italic) {
            text = `<i>${text}</i>`;
          }
          if (node.underline) {
            text = `<u>${text}</u>`;
          }
          return text;
      }
    })
    .join('');
};

const createHeading = (level: number): string => {
  switch (level) {
    case 1:
      return 'heading-one';
    case 2:
      return 'heading-two';
    case 3:
      return 'heading-three';
    case 4:
      return 'heading-four';
    case 5:
      return 'heading-five';
    case 6:
      return 'heading-six';
    default:
      return 'heading-seven';
  }
};

function trimSpacesAndNewlines(input: string): string {
  // All whitespace, not including special whitespace characters as non-breaking space
  const regexMultipleSpaces = /[ \t\r\n\f\v]+/g;
  const result = input.slice().replace(regexMultipleSpaces, ' ');
  // regex to trim preceding spaces if not capital letter in the beginning
  const regexPrecedingSpaces = /^\s(?=[A-ZÆØÅ])/;
  const withOutPreceding = result.replace(regexPrecedingSpaces, '');

  return withOutPreceding;
}

const createLawButton = (uri: string, fragmentId: string, children: any): any => ({
  type: 'button',
  'data-uri': uri,
  'data-fragment-id': fragmentId,
  children,
});

const extractLawDetails = (attribute: any, text: string): any => {
  const value = (attribute['href'] && attribute['href'].value) || '';
  if (value.includes('internal:')) {
    const groups = value.match(/internal:(\/akn\/[a-z/]+)([0-9-/]+\/)([a-z0-9_]+)/);
    const res = createLawButton((groups[1] += groups[2]), groups[3], [{ text }]);
    return res;
  }
  const alt = { type: 'a', href: value, children: [{ text }] };
  return alt;
};

const serializeContent = (node: any): string => {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(node);
};

const deserialize = (nodes: any, level: number, inBoxedContent: boolean): any => {

  if (nodes.length === 0) {
    return [{ text: '' }];
  }

  return Array.from(nodes)
    .flatMap((node: any) => {
      if (node.nodeType === 3) {
        trimSpacesAndNewlines(node.textContent);
        if (!/\S/.test(node.textContent)) {
          return null;
        }
        return { text: trimSpacesAndNewlines(node.nodeValue) };
      }

      switch (node.nodeName) {
        case 'part': {
          return deserialize(node.childNodes, level + 1, inBoxedContent);
        }
        case 'p':
        return {
          type: 'p',
          children: deserialize(node.childNodes, level, inBoxedContent),
        };
        case 'title':
          if (inBoxedContent) {
            return {
              type: 'box-title',
              attributes: { 'data-title-type': 'box-title' },
              children: deserialize(node.childNodes, level, inBoxedContent),
            };
          }
          return {
            type: createHeading(level),
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'list':
          return {
            type: node.attributes['type'] ? "ordered-list" : "unordered-list",
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'list-item':
          return {
            type: 'list-item',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'a':
          const url = node.attributes['href'].value;

          const isResourceButton = url.split(':')[0] === 'internal';
          if (isResourceButton) {
            return extractLawDetails(node.attributes, trimSpacesAndNewlines(node.textContent));
          }

          return {
            type: 'link',
            children: deserialize(node.childNodes, level, inBoxedContent),
            url,
          };

        case 'b':
          return { text: trimSpacesAndNewlines(node.textContent), bold: true };
        case 'i':
          return { text: trimSpacesAndNewlines(node.textContent), italic: true };
        case 'u':
          return { text: trimSpacesAndNewlines(node.textContent), underline: true };
        case 'note':
          return {
            type: 'inline-content',
            children: deserialize(node.childNodes, level, true),
            className: 'note',
          };
        case 'reference':
          return {
            type: 'inline-content',
            children: deserialize(node.childNodes, level, true),
            className: 'reference',
          };
        case 'boxed-content':
          return {
            type: 'boxed-content',
            children: deserialize(node.childNodes, level, true),
            className: 'boxed-content',
          };
        case 'math':
          return {
            type: 'math',
            children: deserialize(node.childNodes, level, inBoxedContent),
            attributes: { style: { display: 'block' } },
            serializedChildren: serializeContent(node),
          };
        case 'table':
          return {
            type: 'table',
            children: deserialize(node.childNodes, level, inBoxedContent),
            serializedChildren: serializeContent(node),
          };
        case 'thead':
          return {
            type: 'thead',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'tbody':
          return {
            type: 'tbody',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'tr':
          return {
            type: 'tr',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'td':
          return {
            type: 'td',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'th':
          return {
            type: 'th',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'caption':
          return {
            type: 'caption',
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'label':
          return {
            type: 'label',
            attributes: { 'data-type': 'label' },
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
        case 'num':
          return null;
        case 'block-quote':
          return {
            type: 'block-quote',
            attributes: {"cite": node.attributes['cite']?.value || "" },
            children: deserialize(node.childNodes, level, inBoxedContent),
          };
          case 'footer':
            return {
              type: 'footer',
              children: deserialize(node.childNodes, level, inBoxedContent),
            };
        default: {
          return deserialize(node.childNodes, level, inBoxedContent);
        }
      }
    })
    .filter((node: any) => node !== null);
};

export function xmlToSlate(xmlString: string): Descendant[] {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const root = xmlDoc.getElementsByTagName('body')[0]; // Assuming the root element contains the content
  // const root = xmlDoc.documentElement;
  const slateNodes = deserialize(root.childNodes, 1, false);

  return slateNodes;
}

export function jsonmlToXml(jsonml: any) {
  // Base case: if jsonml is a string, return it as a text node.
  if (typeof jsonml === 'string') {
    return jsonml;
  }

  // Extract the tag name, attributes, and children from the JSONML array.
  const [tagName, ...rest] = jsonml;
  let attributes = {};
  let children = [];

  // Check if the second element is an attributes object or a child.
  if (rest.length > 0 && typeof rest[0] === 'object' && !Array.isArray(rest[0])) {
    attributes = rest.shift(); // Remove the attributes object from the rest array.
  }

  // The rest of the elements are children.
  children = rest;

  // Convert attributes object into a string.
  const attributesString = Object.keys(attributes)
    // @ts-ignore
    .map((key) => `${key}="${attributes[key]}"`)
    .join(' ');

  // Recursively convert children into XML strings.
  const childrenString = children.map((child: any) => jsonmlToXml(child)).join('');

  // Combine tag name, attributes, and children into an XML string.
  return `<${tagName}${
    attributesString.length > 0 ? ' ' + attributesString : ''
  }>${childrenString}</${tagName}>`;
}
