import * as Types from './types';
import { juridikaHeaders } from './apiUtil';

export interface FileHandle {
  contentType: string;
  id: string;
  url: string;
}

export class ImageAPI {
  baseUrl: string;

  constructor(urls: Types.BackendUrls) {
    this.baseUrl = `${urls.staticContentBackend}/admin/v0/images`;
  }

  upload = async (blob: Blob): Promise<FileHandle> => {
    const formData = new FormData();
    formData.append('file', blob);

    const response = await fetch(this.baseUrl, {
      method: 'POST',
      headers: juridikaHeaders(),
      body: formData,
    });
    return await response.json();
  };

  importUrl = async (url: string): Promise<FileHandle> => {
    const response = await fetch(this.baseUrl, {
      method: 'POST',
      headers: {
        ...juridikaHeaders(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url }),
    });
    return await response.json();
  };
}
