/**
 * These feed semantic keys really lives in static-content-backend.
 * It is assumed that they exist there so that we can provide logic like auto suggestion, etc.
 * The user may freely create more feeds, and this list can be extended with those feeds so
 * that the suggestion logic can also be extended.
 */

export const FEED_FORSIDE_LITTERATUR = 'forside-litteratur';
export const FEED_FORSIDE_INNSIKT = 'forside-innsikt';
export const FEED_FORSIDE_JURIDIKA = 'forside-nytt-om-juridika';
export const FEED_INNSIKT_HOVED = 'innsikt-hovedfeed';
export const FEED_INNSIKT_EKSPERT = 'innsikt-ekspertkommentarer';
export const FEED_INNSIKT_SERIE_JUSSENS_HELTER = 'innsikt-serie-jussens-helter';
