import * as React from 'react';
import * as Slate from 'slate';
import * as SlateReact from 'slate-react';

import { InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';

import * as tags from '../../../slate/tags';
import SlatePopper from '../../../components/SlateInput/SlatePopper';
import { checkUrlForErrors, resolveRelativeUrlToJuridika } from '../../../util/url';
import { getAboveByType } from '../../utils/query';

const LinkPopup: React.FC<{
  editor: Slate.Editor;
  slateEditorFocused: boolean;
  slateEditorChanged: boolean;
  hrefAttribute?: string;
}> = ({ editor, slateEditorFocused, slateEditorChanged, hrefAttribute = 'href' }) => {
  const [href, setHref] = React.useState<string>('');
  const [linkNodeEntry, setLinkNodeEntry] = React.useState<Slate.NodeEntry>();

  React.useEffect(() => {
    if (!editor || !slateEditorFocused) return;

    const { selection } = editor;
    if (!selection) return;
    const isCollapsed = Slate.Range.isCollapsed(selection);
    // Prevent popup from appearing when user select a range of nodes
    if (!isCollapsed) return;

    const linkNodeEntry = getAboveByType(editor, tags.INLINE_LINK);

    if (!linkNodeEntry) {
      setHref('');
      setLinkNodeEntry(undefined);
      return;
    }

    setHref(((linkNodeEntry[0] as Slate.Element)[hrefAttribute] as string) || '');
    setLinkNodeEntry(linkNodeEntry);
  }, [slateEditorFocused, editor, slateEditorChanged, hrefAttribute]);

  const setNodeHref = (newHref: string) => {
    if (!linkNodeEntry) return null;
    Slate.Transforms.setNodes(
      editor,
      { [hrefAttribute]: newHref },
      { at: linkNodeEntry[1], match: (node: Slate.Node) => node.type === tags.INLINE_LINK }
    );
  };

  const reEncodeUrl = () => {
    const encodedHref = encodeURI(decodeURI(href));
    setHref(encodedHref);
    setNodeHref(encodedHref);
  };

  const displayedUrl = (href: string | undefined) => {
    if (!href || !href.trim().length) return null;

    try {
      return (
        <Typography>
          Vises i adressefeltet som <code>{decodeURI(resolveRelativeUrlToJuridika(href))}</code>
        </Typography>
      );
    } catch (error) {
      return <Typography color="error">Kunne ikke vise URL</Typography>;
    }
  };

  if (!linkNodeEntry) return null;
  let linkDOMNode: HTMLElement | null = null;
  try {
    linkDOMNode = SlateReact.ReactEditor.toDOMNode(
      editor as SlateReact.ReactEditor,
      linkNodeEntry[0]
    );
  } catch (error) {
    console.warn('Failed to find link DOM node', error);
  }
  if (!linkDOMNode) return null;

  const errorMessage = checkUrlForErrors(href);

  return (
    <SlatePopper anchorEl={linkDOMNode}>
      <TextField
        label="Link href"
        type="url"
        error={errorMessage !== null}
        InputProps={{
          ...(errorMessage !== null && {
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Encode URL">
                  <IconButton aria-label="Encode URL" onClick={reEncodeUrl}>
                    <CodeIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }),
        }}
        helperText={errorMessage}
        value={href}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          setNodeHref(e.target.value);
          setHref(e.target.value);
        }}
        margin="dense"
        fullWidth
      />
      <Typography color="textSecondary">{displayedUrl(href)}</Typography>
    </SlatePopper>
  );
};

export default LinkPopup;
