import * as React from 'react';
import { Confirm } from 'react-admin';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ContentSave from '@material-ui/icons/Save';

const styles = createStyles({
  button: {
    position: 'relative',
  },
  iconPaddingStyle: {
    marginRight: '0.5em',
  },
});

interface SaveButtonWithConfirmationProps {
  redirect: string | boolean;
  classes: any;
  handleSubmitWithRedirect?: (redirect: string | boolean) => () => void;
  dialogContent: string;
  dialogTitle?: string;
}

const SaveButtonWithConfirmation: React.FC<SaveButtonWithConfirmationProps> = (props) => {
  const [showDialog, setShowDialog] = React.useState(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={props.classes.button}
        type="submit"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          setShowDialog(true);
        }}
      >
        <ContentSave className={props.classes.iconPaddingStyle} />
        Lagre
      </Button>
      <Confirm
        isOpen={showDialog}
        title={props.dialogTitle || 'Lagre?'}
        content={props.dialogContent}
        confirm="Lagre"
        confirmColor="primary"
        cancel="Avbryt"
        onConfirm={() => {
          if (props.handleSubmitWithRedirect && props.redirect) {
            props.handleSubmitWithRedirect(props.redirect);
          }
          setShowDialog(false);
        }}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default withStyles(styles)(SaveButtonWithConfirmation);
