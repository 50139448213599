import * as t from 'io-ts';
import * as Types from './types';

import { halJsonCrudApiProvider } from './apiUtil';

export interface SubscriptionPackage {
  id: string;
  semanticKey: string;
  name: string;
}

const ApiIncomingTypeV = t.type({
  id: t.string,
  semanticKey: t.string,
  name: t.string,
});

export const createProvider = (
  urls: Types.BackendUrls
): Types.ResourceProvider<SubscriptionPackage> =>
  halJsonCrudApiProvider({
    baseUrl: `${urls.juridikaSubscriptionBackend}/admin/v1/subscriptionPackages`,
    halListName: 'subscriptionPackageList',
    incomingType: ApiIncomingTypeV,
    toReactAdminMapper: (x) => x,
    toApiMapper: (x) => x,
    toApiPartialMapper: (x) => x,
    referenceParams: {},
    filterParams: {},
    sortFields: [],
  });
