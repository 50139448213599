import * as React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { injectJournal } from '../../state/journalLinkInjector/injectorActions';
import { CustomState } from '../../state/customState';

type LinkInjectButtonProps = {
  selectedIds?: string[];
  inject: (ids: string[]) => void;
  injecting: boolean;
};

const LinkInjectButton: React.FC<LinkInjectButtonProps> = (props) => {
  const translate = useTranslate();
  const handleClick = () => {
    const { selectedIds, inject } = props;

    if (selectedIds) {
      inject(selectedIds);
    }
  };

  const { injecting } = props;
  return (
    <Button onClick={handleClick} variant="contained">
      {translate('other.injectLinks')}
      {injecting && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
    </Button>
  );
};

const mapDispatchToProps = {
  inject: injectJournal,
};

const mapState = (state: CustomState) => ({
  injecting: state.journalInjector.injectingIds.length > 0,
});

export default connect(mapState, mapDispatchToProps)(LinkInjectButton);
