import React, { useCallback, useMemo, FC, ReactNode, KeyboardEventHandler, useState } from 'react';
import {
  createEditor,
  Transforms,
  Element as SlateElement,
  Editor,
  Descendant,
  Range as SlateRange,
} from 'new-slate';
import { isKeyHotkey } from 'is-hotkey';
import { Button, Icon, Toolbar } from './components';
import { Editable, Slate, useSelected, useSlate, withReact } from 'new-slate-react';
import { withHistory } from 'new-slate-history';
import { ButtonElement, CustomEditor, CustomElement, CustomText } from '../../@types/new-slate';
import CreateResourceStickerModal from './CreateResourceStickerModal/CreateResourceStickerModal';
import ResourceStickerButton from './ResourceStickerButton/ResourceStickerButton';
import { makeStyles } from '@material-ui/core/styles';
import { BoxedContentWrapper } from './BoxedContentWrapper/BoxedContentWrapper';
import CreateLinkModal from './CreateLinkModal/CreateLinkModal';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

export interface ResourceStickerData {
  uri: string;
  fragmentId: string;
}

interface Props {
  value: Descendant[];
  onChange: (value: Descendant[]) => void;
}

const useStyles = makeStyles({
  toolbar: {
    margin: '0 !important',
    padding: '19px 15px !important',
    backgroundColor: '#004c36',
  },
  editor: {
    overflow: 'auto',
    backgroundColor: 'white',
    padding: '10px',
    flexGrow: 1,
    fontFamily: 'Source Sans Pro',
    '& h1, h2, h3, h4, h5, h6': {
      fontFamily: 'Tuna',
    },
  },
  richtextWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: '15px',
    boxShadow:
      '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)',
  },
  table: {
    fontFamily: 'arial, sans-serif',
    borderCollapse: 'collapse',
    width: '100%',

    '& td, th': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    },

    '& tr:nth-child(even)': {
      backgroundColor: ' #dddddd',
    },
  },
  blockquoteWrapper: {
    backgroundColor: '#eae0f5',
    padding: '90px 10px',
    justifyContent: 'center',
    display: 'flex',
    '& svg': {
      marginRight: '8px',
    },
    '& blockquote': {
      margin: 0,
      '& p': {
        margin: 0,
        marginBottom: '24px',
      },
      '& footer': {
        letterSpacing: '0.04rem',
        fontStyle: 'italic',
        fontSize: '1.25rem',
        fontFamily: 'Tuna',
      },
    },
  },
});

const RichTextEditor: FC<Props> = ({ value, onChange }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []);
  const classes = useStyles();

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { selection } = editor;

    console.log('onKeyDown:', event, selection);
    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    if (selection && SlateRange.isCollapsed(selection)) {
      const { nativeEvent } = event;
      if (isKeyHotkey('left', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset', reverse: true });
        return;
      }
      if (isKeyHotkey('right', nativeEvent)) {
        event.preventDefault();
        Transforms.move(editor, { unit: 'offset' });
        return;
      }
    }
  };

  return (
    <div className={classes.richtextWrapper}>
      <Slate
        editor={editor}
        initialValue={value}
        onChange={(value: Descendant[]) => {
          if (value.length === 0 || (value.length === 1 && Editor.isEmpty(editor, value[0]))) {
            const initialValue = [
              {
                type: 'heading-two',
                children: [
                  {
                    text: 'initial value',
                  },
                ],
              },
              {
                type: 'p',
                children: [{ text: 'start å skrive ditt dokument...' }],
              },
            ];

            onChange(initialValue);
          } else onChange(value);
        }}
      >
        <Toolbar className={classes.toolbar}>
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <MarkButton format="underline" icon="format_underlined" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="heading-three" icon="looks_3" />
          <BlockButton format="heading-four" icon="looks_4" />
          <BlockButton format="heading-five" icon="looks_5" />
          <BlockButton format="heading-six" icon="looks_6" />
          <BlockButton format="ordered-list" icon="format_list_numbered" />
          <BlockButton format="unordered-list" icon="format_list_bulleted" />
          <BlockButton format="block-quote" icon="format_quote" />
          <AddLinkButton />
          <RemoveLinkButton />
          <ToggleEditableButtonButton />
          <AddBoxedContentButton />
          <AddInfoContentButton />
          <AddRefContentButton />
        </Toolbar>
        <Editable
          className={classes.editor}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          placeholder="Legg inn tekst her..."
          spellCheck
          autoFocus
        />
      </Slate>
    </div>
  );
};

const AddInfoContentButton = () => {
  const editor: CustomEditor = useSlate();
  const info = {
    type: 'inline-content',
    className: 'note',
    children: [{ text: 'Skriv inn tekst her..' }],
  };
  return (
    <Button
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        Transforms.insertNodes(editor, info);
      }}
    >
      <Icon>info</Icon>
    </Button>
  );
};

const AddRefContentButton = () => {
  const editor: CustomEditor = useSlate();
  const info = {
    type: 'inline-content',
    className: 'reference',
    children: [{ text: 'Skriv inn tekst her..' }],
  };
  return (
    <Button
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        Transforms.insertNodes(editor, info);
      }}
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="18" height="18" fill="#004c36" />
        <rect x="2.39286" y="3.39286" width="12.7857" height="10.7857" fill="#004c36" />
        <rect
          x="2.39286"
          y="3.39286"
          width="12.7857"
          height="10.7857"
          stroke="#aaa"
          strokeWidth="0.785714"
        />
        <path
          d="M3.47399 11.7857V7.15628H4.36971L4.44513 7.97656H4.48285C4.64628 7.67485 4.84428 7.44542 5.07685 7.28828C5.30942 7.12485 5.54828 7.04313 5.79342 7.04313C6.01342 7.04313 6.18942 7.07456 6.32142 7.13742L6.13285 8.08028C6.05113 8.05513 5.97571 8.03628 5.90656 8.02371C5.83742 8.01113 5.75256 8.00485 5.65199 8.00485C5.46971 8.00485 5.27799 8.07713 5.07685 8.22171C4.87571 8.35999 4.70285 8.60513 4.55828 8.95713V11.7857H3.47399ZM8.87921 11.8988C8.45179 11.8988 8.06521 11.8046 7.7195 11.616C7.37379 11.4211 7.10036 11.1446 6.89921 10.7863C6.69807 10.4217 6.5975 9.98485 6.5975 9.47571C6.5975 8.97285 6.69807 8.53913 6.89921 8.17456C7.10664 7.80999 7.37379 7.53028 7.70064 7.33542C8.0275 7.14056 8.37007 7.04313 8.72836 7.04313C9.1495 7.04313 9.5015 7.13742 9.78436 7.32599C10.0672 7.50828 10.2809 7.76599 10.4255 8.09913C10.5701 8.42599 10.6424 8.80628 10.6424 9.23999C10.6424 9.46628 10.6266 9.64228 10.5952 9.76799H7.6535C7.70379 10.1766 7.8515 10.494 8.09664 10.7203C8.34179 10.9466 8.64979 11.0597 9.02064 11.0597C9.22179 11.0597 9.40721 11.0314 9.57693 10.9748C9.75293 10.912 9.92579 10.8271 10.0955 10.7203L10.4632 11.3991C10.2432 11.5437 9.99807 11.6631 9.72779 11.7574C9.4575 11.8517 9.17464 11.8988 8.87921 11.8988ZM7.64407 9.03256H9.6995C9.6995 8.67428 9.62093 8.39456 9.46379 8.19342C9.30664 7.98599 9.07093 7.88228 8.75664 7.88228C8.48636 7.88228 8.24436 7.98285 8.03064 8.18399C7.82321 8.37885 7.69436 8.66171 7.64407 9.03256ZM11.8297 11.7857V8.01428H11.2074V7.20342L11.8297 7.15628V6.67542C11.8297 6.19142 11.9491 5.79542 12.188 5.48742C12.4268 5.17313 12.8197 5.01599 13.3666 5.01599C13.53 5.01599 13.6871 5.0317 13.838 5.06313C13.9888 5.09456 14.1146 5.13228 14.2151 5.17628L14.0077 5.98713C13.838 5.91171 13.6683 5.87399 13.4986 5.87399C13.1088 5.87399 12.914 6.13485 12.914 6.65656V7.15628H13.8191V8.01428H12.914V11.7857H11.8297Z"
          fill="#aaa"
        />
      </svg>
    </Button>
  );
};

const AddBoxedContentButton = () => {
  const editor: CustomEditor = useSlate();

  return (
    <Button
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        Transforms.insertNodes(editor, {
          type: 'boxed-content',
          className: 'boxed-content',
          children: [
            {
              type: 'label',
              attributes: {
                'data-type': 'label',
              },
              children: [
                {
                  text: 'OVERSKRIFT',
                },
              ],
            },
            {
              type: 'p',
              children: [
                {
                  text: 'Skriv inn tekst her..',
                },
              ],
            },
          ],
        });
      }}
    >
      <Icon>expand_more</Icon>
    </Button>
  );
};

const ToggleEditableButtonButton = () => {
  const editor = useSlate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        active={isButtonActive(editor)}
        onMouseDown={(event: Event) => {
          event.preventDefault();
          if (isButtonActive(editor)) {
            unwrapButton(editor);
          } else {
            setOpenModal(true);
          }
        }}
      >
        <Icon>agriculture</Icon>
      </Button>
      {openModal && (
        <CreateResourceStickerModal
          open={openModal}
          setOpen={setOpenModal}
          onSave={(resourceStickerData: ResourceStickerData) => {
            insertButton(editor, resourceStickerData);
          }}
        />
      )}
    </>
  );
};

const insertButton = (editor: CustomEditor, resourceStickerData: ResourceStickerData) => {
  if (editor.selection) {
    wrapButton(editor, resourceStickerData);
  }
};

const wrapButton = (editor: CustomEditor, resourceStickerData: ResourceStickerData) => {
  if (isButtonActive(editor)) {
    unwrapButton(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && SlateRange.isCollapsed(selection);
  const button: ButtonElement = {
    type: 'button',
    'data-uri': resourceStickerData.uri,
    'data-fragment-id': resourceStickerData.fragmentId,
    children: isCollapsed ? [{ text: 'sett inn tekst' }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, button);
  } else {
    Transforms.wrapNodes(editor, button, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const AddLinkButton = () => {
  const [openModal, setOpenModal] = useState(false);
  const editor: CustomEditor = useSlate();

  return (
    <>
      <Button
        active={isLinkActive(editor)}
        onMouseDown={(_event: MouseEvent) => setOpenModal(true)}
      >
        <Icon>link</Icon>
      </Button>

      {openModal && (
        <CreateLinkModal
          open={openModal}
          setOpen={setOpenModal}
          onSave={(url: string) => insertLink(editor, url)}
        />
      )}
    </>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(_event: MouseEvent) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Icon>link_off</Icon>
    </Button>
  );
};

const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && SlateRange.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const unwrapButton = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
  });
};

const isButtonActive = (editor: CustomEditor) => {
  const [button] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'button',
  });
  return !!button;
};

// Slate needs to be explicitly told that certain elements are inline.
const withLinks = (editor: CustomEditor) => {
  const { insertData, insertText, isInline, isSelectable } = editor;

  editor.isInline = (element: Descendant) => {
    switch (element.type) {
      case 'inline-content':
      case 'button':
      case 'link':
      case 'math':
        return true;
      default:
        return isInline(element);
    }
  };
  editor.isSelectable = (element: Descendant) => isSelectable(element);

  editor.insertText = (text: string) => {
    insertText(text);
  };

  editor.insertData = (data: Descendant) => {
    insertData(data);
  };

  return editor;
};

const LIST_TYPES = ['unordered-list', 'ordered-list'];

// check if the format is a valid CustomElement type, these are typically the block elements
const isValidCustomElementType = (format: string): format is CustomElement['type'] => {
  const validFormats: CustomElement['type'][] = [
    'paragraph',
    'heading-one',
    'heading-two',
    'heading-three',
    'heading-four',
    'heading-five',
    'heading-six',
    'unordered-list',
    'ordered-list',
    'list-item',
    'boxed-content',
    'box-title',
    'div',
    'span',
    'block-quote',
  ];
  return validFormats.includes(format);
};

const toggleBlock = (editor: CustomEditor, format: string) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);
  if (isValidCustomElementType(format)) {
    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });

    Transforms.setNodes(editor, { type: isActive ? 'p' : isList ? 'list-item' : format });

    if (!isActive && isList) {
      Transforms.wrapNodes(editor, { type: format, children: [] });
    }
  }
};

const toggleMark = (editor: CustomEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: CustomEditor, format: string) => {
  const { selection } = editor;
  if (!selection) return false;
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: CustomEditor, format: string) => {
  const marks = Editor.marks(editor);

  if (marks && Object.keys(marks).length) {
    return marks[format as keyof typeof marks] === true;
  } else return false;
};

const LinkComponent: FC<{ url: string; children: ReactNode }> = ({ url, children }) => {
  const selected = useSelected();
  return (
    <a href={url} style={{ boxShadow: selected ? '0 0 0 3px #ddd' : 'none' }}>
      {children}
    </a>
  );
};

const BlockQuoteComponent: FC<{ cite: string; children: ReactNode | ReactNode[] }> = ({
  cite,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.blockquoteWrapper}>
      <svg xmlns="http://www.w3.org/2000/svg" width="37" height="28" viewBox="0 0 37 28">
        <path
          fill="#370974"
          fillRule="evenodd"
          d="M13.043 25.488c-.944.554-1.97.977-3.076 1.27-1.107.293-2.214.44-3.32.44-1.856 0-3.386-.627-4.59-1.88C.852 24.063.25 22.265.25 19.921c0-1.79.301-3.589.903-5.396.603-1.806 1.49-3.564 2.661-5.273 1.172-1.71 2.62-3.345 4.346-4.907 1.725-1.563 3.695-2.979 5.908-4.248l2.002 2.978c-2.636 2.051-4.646 4.094-6.03 6.128-1.383 2.035-2.075 4.028-2.075 5.982 0 .716.13 1.302.39 1.757.26.456.627.83 1.1 1.123.471.293 1.016.53 1.635.708.618.18 1.27.35 1.953.513v6.201zm20.703 0c-.944.554-1.97.977-3.076 1.27-1.107.293-2.214.44-3.32.44-1.856 0-3.386-.627-4.59-1.88-1.205-1.254-1.807-3.052-1.807-5.396 0-1.79.301-3.589.903-5.396.603-1.806 1.49-3.564 2.662-5.273 1.171-1.71 2.62-3.345 4.345-4.907 1.726-1.563 3.695-2.979 5.908-4.248l2.002 2.978c-2.636 2.051-4.646 4.094-6.03 6.128-1.383 2.035-2.075 4.028-2.075 5.982 0 .716.13 1.302.39 1.757.261.456.627.83 1.1 1.123.471.293 1.016.53 1.635.708.618.18 1.27.35 1.953.513v6.201z"
        ></path>
      </svg>
      <blockquote cite={cite || ''}>{children}</blockquote>
    </div>
  );
};

const Element: FC<{ children: ReactNode; element: CustomElement }> = ({ children, element }) => {
  const classes = useStyles();

  switch (element.type) {
    case 'heading-one':
      return <h1>{children}</h1>;
    case 'heading-two':
      return <h2>{children}</h2>;
    case 'heading-three':
      return <h3>{children}</h3>;
    case 'heading-four':
      return <h4>{children}</h4>;
    case 'heading-five':
      return <h5>{children}</h5>;
    case 'heading-six':
      return <h6>{children}</h6>;
    case 'unordered-list':
      return <ul>{children}</ul>;
    case 'ordered-list':
      return <ol type="1">{children}</ol>;
    case 'list-item':
      return <li>{children}</li>;
    case 'inline-content':
    case 'boxed-content':
      return <BoxedContentWrapper type={element.className}>{children}</BoxedContentWrapper>;
    case 'button':
      return <ResourceStickerButton attributes={element}>{children}</ResourceStickerButton>;
    case 'box-title':
      return <div {...element.attributes}>{children}</div>;
    case 'paragraph':
      return <div data-type="section-wrapper">{children}</div>;
    case 'table':
      return <table className={classes.table}>{children}</table>;
    case 'tbody':
      return <tbody>{children}</tbody>;
    case 'thead':
      return <thead>{children}</thead>;
    case 'tr':
      return <tr>{children}</tr>;
    case 'td':
      return <td>{children}</td>;
    case 'th':
      return <th>{children}</th>;
    case 'caption':
      return <caption>{children}</caption>;
    case 'link':
      return <LinkComponent url={element.url}>{children}</LinkComponent>;
    case 'block-quote':
      return (
        <BlockQuoteComponent cite={element.attributes?.cite || ''}>{children} </BlockQuoteComponent>
      );
    case 'footer':
      return <footer>{children}</footer>;
    case 'math':
      return (
        <MathJaxContext
          version={3}
          config={{
            loader: { load: ['input/mml', 'output/chtml'] },
            mml: {},
          }}
        >
          <MathJax
            inline
            hideUntilTypeset="first"
            dangerouslySetInnerHTML={{ __html: element.serializedChildren }}
          />
        </MathJaxContext>
      );
    default:
      return <p>{children}</p>;
  }
};

const Leaf: FC<{ attributes: any; children: ReactNode; leaf: CustomText }> = ({
  attributes,
  children,
  leaf,
}): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton: FC<{ format: string; icon: string }> = ({ format, icon }): JSX.Element => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton: FC<{ format: string; icon: string }> = ({ format, icon }): JSX.Element => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export default RichTextEditor;
