import URI from 'urijs';
import { GET_MANY_REFERENCE, CREATE, fetchUtils } from 'react-admin';
import * as Types from './types';
import { juridikaHeaders } from './apiUtil';

export const createProvider = (urls: Types.BackendUrls) => {
  const BASE_URL = URI(urls.juridikaSubscriptionBackend)
    .segment('admin')
    .segment('v1')
    .segment('stripe')
    .toString();

  const convertDataProviderRequestToHTTP = (
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    switch (type) {
      case CREATE: {
        return {
          url: URI(BASE_URL)
            .segment('updatePrice')
            .query({
              productId: params.data.productId,
              pricing: params.data.pricingInterval,
              price: params.data.price,
              currency: params.data.currency,
              subscription: params.data.subscriptionType.toUpperCase(),
            })
            .toString(),
          options: {
            method: 'POST',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }

      case GET_MANY_REFERENCE: {
        return {
          url: URI(BASE_URL).segment('prices').query({ productId: params.id }).toString(),
          options: {
            method: 'GET',
            headers: new Headers(juridikaHeaders()),
          },
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  const convertHTTPResponseToDataProvider = (
    response: any,
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    const { json } = response;

    switch (type) {
      case CREATE: {
        return {
          data: json,
          total: 1,
        };
      }

      case GET_MANY_REFERENCE: {
        return {
          data: json,
          total: json.length,
        };
      }
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
  };

  const performDataProviderRequestViaHttp = (
    type: string,
    resource: string,
    params: Types.ReactAdminApiParams
  ) => {
    const { fetchJson } = fetchUtils;
    const { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);

    const response = fetchJson(url, options).then((response) =>
      convertHTTPResponseToDataProvider(response, type, resource, params)
    );

    return response;
  };

  return performDataProviderRequestViaHttp;
};
