import React, { FC } from 'react';
import {
  ArrayField,
  Datagrid,
  EditButton,
  FunctionField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PublishDocumentButtons from './PublishDocumentButtons/PublishDocumentButtons';
import DateTimeField from '../DateTimeField';

const useStyles = makeStyles({
  headerCell: {
    backgroundColor: '#ebf5f4',
  },
  contributorsList: {
    '& table thead th': { backgroundColor: '#ebf5f4' },
  },
});

const NIKEditionShow: FC = (props) => {
  const classes = useStyles();
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <ReferenceField
          source="publicationId"
          sortable={false}
          reference="nikPublications"
          label="Problemstilling"
        >
          <TextField source="title" />
        </ReferenceField>
        <DateTimeField source="publishedAt" sortable={false} emptyText="Ikke publisert" addLabel />
        <ArrayField source="contributorRelation" fullWidth className={classes.contributorsList}>
          <Datagrid>
            <ReferenceField
              source="contributorDetailId"
              reference="nikContributorDetails"
              label="Tittel"
            >
              <TextField source="title" />
            </ReferenceField>
            <ReferenceField source="contributorId" reference="contributors">
              <TextField source="fullname" />
            </ReferenceField>
            <ReferenceField
              source="contributorDetailId"
              reference="nikContributorDetails"
              label="Kort biografi"
            >
              <TextField source="shortBio" />
            </ReferenceField>
          </Datagrid>
        </ArrayField>
        <ReferenceManyField
          label="Liste over dokumenter i denne utgaven"
          reference="nikDocuments"
          target="edition_id"
          fullWidth
        >
          <Datagrid rowClick="show" classes={{ headerCell: classes.headerCell }}>
            <TextField source="id" sortable={false} />
            <TextField source="editorAccountId" sortable={false} />
            <DateTimeField source="createdAt" sortable={false} />
            <EditButton label="Rediger dokument" />
            <FunctionField
              label="Publisering"
              render={(record: any) => <PublishDocumentButtons record={record} />}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default NIKEditionShow;
