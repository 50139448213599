import * as React from 'react';
import * as ReactForm from 'react-final-form';
import * as hast from '@universitetsforlaget/hast';
import { DocumentSpec } from '../slate/documentSpec';
import { SlateInputContextProvider } from './SlateInput/SlateInputContextProvider';
import SlateInput from './SlateInput/SlateInput';
import { TextInputProps } from 'ra-ui-materialui';

interface SlateTextInputProps {
  documentSpec: DocumentSpec;
  singlelineStyle?: boolean;
  // A hack for when defaultValue does not work in react-admin.
  slateDefaultValue?: () => string | hast.HastElementNode;
}

interface ReactAdminFieldProps {
  source: string;
  disabled?: boolean;
}

const RenderComponent: React.FC<
  ReactForm.FieldRenderProps<hast.HastElementNode> & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  input: { value, onChange },
  slateDefaultValue,
  documentSpec,
  singlelineStyle,
  disabled,
}) => {
  return (
    <SlateInputContextProvider>
      <SlateInput
        readOnly={!!disabled}
        value={value || (slateDefaultValue ? slateDefaultValue() : '')}
        onChange={onChange}
        documentSpec={documentSpec}
      />
      {singlelineStyle && <div style={{ background: '#000', height: '1px', marginTop: '6px' }} />}
    </SlateInputContextProvider>
  );
};

const SlateTextInput: React.FC<TextInputProps & SlateTextInputProps> = ({
  source,
  documentSpec,
  slateDefaultValue,
  singlelineStyle,
  disabled,
}) => {
  return (
    <ReactForm.Field
      name={source}
      component={RenderComponent}
      documentSpec={documentSpec}
      slateDefaultValue={slateDefaultValue}
      singlelineStyle={singlelineStyle}
      disabled={disabled}
    />
  );
};

export default SlateTextInput;
