import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceField,
  TextField,
  ChipField,
  ShowProps,
  ArrayField,
  Datagrid,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { TextbookModule } from '../../dataProvider/textbookModulesProvider';

// General components
import DateTimeField from '../DateTimeField';
import { BreadcrumbTitle } from '../BreadcrumbTitle';

// Locals
import TextbookModuleDatagrid from './TextbookModuleDatagrid';
import TextbookModuleReferenceManyField from './TextbookModuleReferenceManyField';

const useStyles = makeStyles({
  showContent: {
    display: 'grid',
  },
  column1: {
    gridColumn: 1,
  },
  column2: {
    gridColumn: 2,
  },
  column12: {
    gridColumn: '1 / span 2',
  },
  column3: {
    gridColumn: 3,
  },
  column123: {
    gridColumn: '1 / span 3',
  },
});

const TextbookModuleShowLayout: React.FC<{ record?: TextbookModule }> = ({ record }) => {
  const classes = useStyles();
  return (
    <SimpleShowLayout className={classes.showContent}>
      <TextField source="id" className={classes.column1} />
      <TextField source="editionKey" className={classes.column1} />
      <TextField source="modulePathDisplayName" className={classes.column2} />
      <ReferenceField
        source="editionId"
        reference="textbookEditions"
        link="show"
        className={classes.column3}
      >
        <ChipField source="descriptiveName" />
      </ReferenceField>
      <TextField source="fullTitle" className={classes.column12} />
      <DateTimeField source="juridikaPublishedAt" className={classes.column3} addLabel />

      <TextField source="pageRange" className={classes.column1} />

      <ArrayField source="contributions" className={classes.column1}>
        <Datagrid>
          <ReferenceField source="contributorId" reference="contributors">
            <TextField source="fullname" />
          </ReferenceField>
          <TextField source="role" />
        </Datagrid>
      </ArrayField>

      {record && (
        <TextbookModuleReferenceManyField
          label="Undermoduler"
          reference="textbookModules"
          source="editionId"
          target="editionId"
          filter={{
            editionId: record.editionId,
            parentModulePath: record.modulePath.join('.'),
          }}
          perPage={1000}
          className={classes.column123}
        >
          <TextbookModuleDatagrid />
        </TextbookModuleReferenceManyField>
      )}
    </SimpleShowLayout>
  );
};

const TextbookModuleShow: React.FC<{ record?: TextbookModule } & ShowProps> = (props) => {
  return (
    <Show
      {...props}
      title={
        <BreadcrumbTitle
          resourceName="bokmodul"
          recordName={(record) => `${record.editionKey} (${record.fullTitle})`}
        />
      }
    >
      <TextbookModuleShowLayout />
    </Show>
  );
};

export default TextbookModuleShow;
