import React from 'react';
import { GET_LIST } from 'react-admin';

import currentUserReducers from '../state/currentUser/currentUserReducers';

import { createCommentarySaga } from '../state/commentary/commentarySagas';
import commentaryReducers from '../state/commentary/commentaryReducers';
import { createExpressionSaga } from '../state/expressions/expressionSagas';
import expressionReducers from '../state/expressions/expressionReducers';
import { createExcerptSaga } from '../state/excerpts/excerptSagas';
import { createCommentaryLinkingSagas } from '../state/commentaryLinking/commentaryLinkingSagas';
import commentaryLinkingReducer from '../state/commentaryLinking/commentaryLinkingReducers';

import { createPublishingWatcherSaga } from '../state/publishing/publishingSagas';
import publishingReducer from '../state/publishing/publishingReducer';

import staticFilesReducer from '../state/staticFiles/staticFilesReducer';
import { createStaticFilesUploadWatcherSaga } from '../state/staticFiles/staticFilesSagas';

import journalInjectorReducer from '../state/journalLinkInjector/injectorReducer';
import { createJournalInjectorWatcherSagas } from '../state/journalLinkInjector/injectorSagas';

import * as LinkedJournalsProvider from '../dataProvider/linkedJournalsProvider';
import liveDataObserver from '../dataProvider/observer/liveDataObserver';

import { createRootProvider } from '../dataProvider/rootProvider';
import * as ApiUrls from '../dataProvider/apiUrls';
import { createReactAdminDataProvider } from '../dataProvider/rootProvider';

const backendUrls = ApiUrls.backendUrls();
const rootProvider = createRootProvider(backendUrls);

export const JuridikaCmsContext = React.createContext({
  backendUrls: backendUrls,
  rootProvider: rootProvider,
  dataProvider: createReactAdminDataProvider(backendUrls),

  customReducers: {
    commentaries: commentaryReducers,
    publishing: publishingReducer,
    expressions: expressionReducers,
    currentUser: currentUserReducers,
    staticFiles: staticFilesReducer,
    journalInjector: journalInjectorReducer,
    commentariesLinking: commentaryLinkingReducer,
  },
  customSagas: [
    createCommentarySaga(backendUrls),
    createCommentaryLinkingSagas(backendUrls),
    createExpressionSaga(backendUrls),
    createExcerptSaga(),
    createPublishingWatcherSaga(rootProvider),
    createStaticFilesUploadWatcherSaga(backendUrls),
    createJournalInjectorWatcherSagas(backendUrls),
    liveDataObserver(
      LinkedJournalsProvider.createProvider(backendUrls),
      'linkedJournals',
      1000,
      (type, resource, params) => type === GET_LIST
    ),
  ],
});
